import { useState } from "react";
import { IUser } from "src/models/user";
import { IStudentBlock } from 'src/models/student-block';
import * as Yup from 'yup';
import {
    Paper,
    Box,
    Typography,
    Divider,
    CardContent,
    Grid,
    DialogActions,
    Button,
} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import useHaveRole from "src/hooks/useHaveRole";
import { RoleName } from "src/models/role";
import { StyledEditButton } from "src/features/evaluations/components/common/StyledComponents";
import EditIcon from '@mui/icons-material/Edit';
import InfoItem from 'src/features/profile/components/profile/InfoItem';
import { Formik } from 'formik';
import { useTranslation } from "react-i18next";
import { printUserName, studentIsInRedemption, studentIsOnHold } from "src/utils/userHelpers";
import useUpdateJuryDecision from "../hooks/useUpdateJuryDecision";
import { IJuryDecision } from "src/models/jury";
import JuryDecisionFormFields from "./JuryDecisionFormFields";
import Label from "src/components/Label";
import {
    StudentCertificationStatus,
} from "src/utils/const";
import { useQueryClient } from "react-query";
import { FETCH_STUDENT_BLOCKS_KEY } from "../utils/api";
import { FETCH_STUDENT_CERTIFICATION_KEY, FETCH_STUDENT_KEY } from "src/features/profile/utils/api";
import useAuth from "src/features/auth/hooks/useAuth";
import { resolveGetStudentRoute } from "src/features/profile/pages/Student";


const MAP_COLOR_TO_STUDENT_STATUS = {
    [StudentCertificationStatus.PartialDiploma]: 'warning',
    [StudentCertificationStatus.FullDiploma]: 'success',
    [StudentCertificationStatus.NoDiploma]: 'error',
};

interface IProps {
    title: string;
    missingTitle: string;
    studentId: string;
    studentBlocks: IStudentBlock[]
    jury: IUser | null;
    juryComment: string | null;
    juryDecision: string | null;
    readOnly: boolean,
}

const JuryDecisionSection: React.FC<IProps> = ({
    title,
    missingTitle,
    studentId,
    studentBlocks,
    jury,
    juryComment,
    juryDecision,
    readOnly
}) => {
    
    const queryClient = useQueryClient();

    const { user } = useAuth();
    
    const isJury = useHaveRole(RoleName.JuryPresident)

    const { t } = useTranslation();

    const [isEditing, setIsEditing] = useState<boolean>(false);

    const { updateJuryDecision, isUpdatingJuryDecision } = useUpdateJuryDecision();

    const juryPassedBlocks = () => {
        let passedBlocks = [];
        studentBlocks.map((block: any) => {
            if (block.juryDecision === 'passed') passedBlocks.push(block.id)
        })
        return passedBlocks;
    }

    const handleEdit = (data: IJuryDecision) => {
        updateJuryDecision(
            { studentId, data },
            {
                onSuccess: () => {
                    setIsEditing((prevState) => !prevState)
                    queryClient.invalidateQueries([FETCH_STUDENT_BLOCKS_KEY, studentId]);
                    queryClient.invalidateQueries([FETCH_STUDENT_CERTIFICATION_KEY, studentId]);
                    queryClient.invalidateQueries([ FETCH_STUDENT_KEY,  resolveGetStudentRoute(user,studentId)
                        
                    ])
                }
            }
        );
    };


    return (
        <>
            {jury ?
                <Paper elevation={3} sx={{ marginBottom: 2 }}>
                    <Box sx={{ margin: '24px 24px 10px 24px', position: 'relative' }}>
                        <Typography variant="h4" gutterBottom sx={{ fontWeight: 700 }}>
                            {title}
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            sx={{ fontWeight: 400, fontSize: '15px' }}
                        >
                            {t(`studentBlock.jury.juryDecisionSubtitle`)}
                        </Typography>
                        {!isEditing && isJury && <StyledEditButton
                            disabled={!!readOnly}
                            sx={{ top: 0, right: 0 }}
                            size="small"
                            onClick={() => setIsEditing((prevState) => !prevState)}
                            color="primary"
                        >
                            <EditIcon fontSize="small" />
                        </StyledEditButton>}
                    </Box>
                    <Divider />
                    <CardContent>
                        <Grid
                            container
                            sx={{
                                display: 'flex',
                                boxSizing: 'border-box',
                                flexFlow: 'flex-wrap',
                                width: '100%'
                            }}
                        >
                            <InfoItem
                                property={t(`evaluations.auditedBy.name`)}
                                value={printUserName(jury)}
                            />
                            <InfoItem
                                property={t(t(`evaluations.auditedBy.email`))}
                                value={jury?.email}
                            />
                            <InfoItem property={t(t(`studentBlock.jury.juryDecision`))}                    >
                                {juryDecision ?
                                    <Label color={MAP_COLOR_TO_STUDENT_STATUS[juryDecision]}>
                                        {t(`studentBlock.jury.juryDecisionOptions.${juryDecision}`)}
                                    </Label> :
                                    <Typography style={{ fontWeight: 'bold' }}>-</Typography>}
                            </InfoItem>
                            <InfoItem
                                property={t(`studentBlock.jury.juryDecisionComment`)}
                                value={juryComment}
                            />
                        </Grid>
                        {isEditing &&
                            <Grid item xs={12} sm={8} md={9} sx={{ marginLeft: 'auto' }}>
                                <Formik<IJuryDecision>
                                    initialValues={{
                                        juryComment: juryComment || '',
                                        juryDecision: juryDecision || '',
                                        passedStudentBlocks: juryPassedBlocks()
                                    }}
                                    onSubmit={handleEdit}
                                    validationSchema={Yup.object().shape({
                                        juryComment: Yup.string(),
                                        juryDecision: Yup.string(),
                                        passedStudentBlocks: Yup.array().of(Yup.string()).when("juryDecision", {
                                            is: 'partial_diploma',
                                            then: Yup.array().of(Yup.string())
                                                .min(1, t(t('studentBlock.jury.juryPartialDiplomaSelectBlocksValidationMessage')))
                                                .required(t(t('studentBlock.jury.juryPartialDiplomaSelectBlocksValidationMessage')))
                                        })
                                    })}
                                >
                                    {({ handleSubmit, }) => (
                                        <form onSubmit={handleSubmit}>
                                            <JuryDecisionFormFields studentBlocks={studentBlocks} isEditing={isEditing} />
                                            <DialogActions sx={{ padding: '8px 0' }}>
                                                <Button
                                                    color="secondary"
                                                    size="medium"
                                                    variant="outlined"
                                                    onClick={() => setIsEditing((prevState) => !prevState)}
                                                >
                                                    {t('common.buttons.cancel')}
                                                </Button>
                                                <LoadingButton
                                                    loading={isUpdatingJuryDecision}
                                                    sx={{
                                                        ml: 2
                                                    }}
                                                    variant="contained"
                                                    size="medium"
                                                    type="submit"
                                                >
                                                    {t('common.buttons.save')}
                                                </LoadingButton>
                                            </DialogActions>
                                        </form>
                                    )}
                                </Formik>
                            </Grid>}
                    </CardContent>
                </Paper>
                :
                <Paper elevation={3} sx={{ marginBottom: 2 }}>
                    <Box sx={{ margin: 3, position: 'relative' }}>
                        <Typography variant="h4" gutterBottom sx={{ fontWeight: 700 }}>
                            {missingTitle}
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            sx={{ fontWeight: 400, fontSize: '15px' }}
                        >
                            {t(`studentBlock.jury.noJuryAssignedSubtitle`)}
                        </Typography>
                        {!isEditing && isJury && <StyledEditButton
                            disabled={!!readOnly}
                            sx={{ top: 0, right: 0 }}
                            size="small"
                            onClick={() => setIsEditing((prevState) => !prevState)}
                            color="primary"
                        >
                            <EditIcon fontSize="small" />
                        </StyledEditButton>}
                    </Box>
                    {isEditing &&
                        <>
                            <Divider />
                            <CardContent>
                                {isEditing && isJury &&
                                    <Grid item xs={12} sm={8} md={9} sx={{ marginLeft: 'auto' }}>
                                        <Formik<IJuryDecision>
                                            initialValues={{
                                                juryComment: juryComment || '',
                                                juryDecision: juryDecision || '',
                                                passedStudentBlocks: juryPassedBlocks()
                                            }}
                                            onSubmit={handleEdit}
                                            validationSchema={Yup.object().shape({
                                                juryComment: Yup.string(),
                                                juryDecision: Yup.string(),
                                                passedStudentBlocks: Yup.array().of(Yup.string()).when("juryDecision", {
                                                    is: 'partial_diploma',
                                                    then: Yup.array().of(Yup.string())
                                                        .min(1, t(t('studentBlock.jury.juryPartialDiplomaSelectBlocksValidationMessage')))
                                                        .required(t(t('studentBlock.jury.juryPartialDiplomaSelectBlocksValidationMessage')))
                                                })
                                            })}
                                        >
                                            {({ handleSubmit }) => (
                                                <form onSubmit={handleSubmit}>
                                                    <JuryDecisionFormFields studentBlocks={studentBlocks} isEditing={isEditing} />
                                                    <DialogActions sx={{ padding: '8px 0' }}>
                                                        <Button
                                                            color="secondary"
                                                            size="medium"
                                                            variant="outlined"
                                                            onClick={() => setIsEditing((prevState) => !prevState)}
                                                        >
                                                            {t('common.buttons.cancel')}
                                                        </Button>
                                                        <LoadingButton
                                                            loading={isUpdatingJuryDecision}
                                                            sx={{
                                                                ml: 2
                                                            }}
                                                            variant="contained"
                                                            size="medium"
                                                            type="submit"
                                                        >
                                                            {t('common.buttons.save')}
                                                        </LoadingButton>
                                                    </DialogActions>
                                                </form>
                                            )}
                                        </Formik>
                                    </Grid>}
                            </CardContent></>}
                </Paper>}
        </>
    )
}

export default JuryDecisionSection;