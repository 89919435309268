import { AxiosResponse } from "axios";

export interface IApiFileDownload {
    data: Blob,
    file: IApiFile
}

export interface IApiFile {
    full: string,
    name: string,
    extension: string
}

export const downloadApiFileFromResponse = (response: AxiosResponse): IApiFileDownload => {
    const filename = decodeURIComponent(response.headers.contentDisposition.split('filename=')[1].split(';')[0]).replace(/['"]+/g, '')
    const [name, extension] = filename.split('.');

    let file = { full: filename, name: name, extension: extension };

    const url = window.URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', file.full);
    document.body.appendChild(link);
    link.click();

    return {
        data: response.data,
        file: file
    };
}

