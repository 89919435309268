import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { IJobRequiredKnowHow } from 'src/models/job-required-know-how';
import { IApiError } from '../../../../../utils/axios';
import {
  deleteJobRequiredKnowHow as deleteJobRequiredKnowHowApi,
  DELETE_JOB_REQUIRED_KNOW_HOW_KEY,
  FETCH_STUDENT_JOB_KEY
} from '../../../utils/api';

const useDeleteJobRequiredKnowHow = () => {
  const queryClient = useQueryClient();
  const [jobRequiredKnowHowToBeDeleted, setJobRequiredKnowHowToBeDeleted] =
    useState<IJobRequiredKnowHow>();

  const { enqueueSnackbar } = useSnackbar();

  const {
    mutate: deleteJobRequiredKnowHow,
    isLoading: isDeletingJobRequiredKnowHow
  } = useMutation(deleteJobRequiredKnowHowApi, {
    mutationKey: DELETE_JOB_REQUIRED_KNOW_HOW_KEY,
    onSuccess: () => {
      setJobRequiredKnowHowToBeDeleted(null);
      queryClient.invalidateQueries([FETCH_STUDENT_JOB_KEY]);
    },
    onError: (error: IApiError) => {
      enqueueSnackbar(error.message, {
        variant: 'error'
      });
    }
  });

  return {
    jobRequiredKnowHowToBeDeleted,
    setJobRequiredKnowHowToBeDeleted,
    deleteJobRequiredKnowHow,
    isDeletingJobRequiredKnowHow
  };
};

export default useDeleteJobRequiredKnowHow;
