import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { ICourse } from '../../../../models/course';
import { IApiError } from '../../../../utils/axios';
import {
  deleteCourseDocuments as deleteCourseDocumentApi,
  DELETE_COURSE_DOCUMENTS_KEY
} from '../../utils/api';
import { IExtendedCourseDocument } from '../../utils/types';

const useDeleteCourseDocument = (course: ICourse) => {
  const [documentToBeDeleted, setDocumentToBeDeleted] =
    useState<IExtendedCourseDocument>();

  const { enqueueSnackbar } = useSnackbar();

  const { mutate: deleteCourseDocument, isLoading: isDeletingCourseDocument } =
    useMutation(deleteCourseDocumentApi, {
      mutationKey: DELETE_COURSE_DOCUMENTS_KEY,
      onSuccess: () => {
        setDocumentToBeDeleted(null);
      },
      onError: (error: IApiError) => {
        enqueueSnackbar(error.message, {
          variant: 'error'
        });
      }
    });

  return {
    documentToBeDeleted,
    setDocumentToBeDeleted,
    deleteCourseDocument,
    isDeletingCourseDocument
  };
};

export default useDeleteCourseDocument;
