import { Grid } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import FileCard from './FileCard';
import PageLoader from '../../../../components/PageLoader';
import { CourseStatus, ICourse } from '../../../../models/course';
import useFetchCourseDocuments from '../../hooks/api/useFetchCourseDocuments';
import { SchoolCourseDocumentType } from '../../utils/types';
import useDeleteCourseDocument from '../../hooks/api/useDeleteCourseDocument';
import { Mode } from '../../../../utils/types';
import Confirm from '../../../../components/Confirm';
import useCan from '../../../../hooks/useCan';
import { Permissions } from '../../../../utils/const';
import RequestReview from './RequestReview';
import useRequestCourseDocumentsVerification from '../../hooks/api/useRequestVerification';
import useToggleVisibility from '../../../../hooks/useToggleVisibility';
import ConfirmDeleteDocument from '../common/ConfirmDeleteDocument';
import useDocumentAction from '../../hooks/document/useDocumentAction';
import useTaskOverdue from '../../../tasks/hooks/api/useTaskOverdue';
import { TaskCodes } from '../../../tasks/utils/types';
import useHaveRole from '../../../../hooks/useHaveRole';
import { RoleName } from '../../../../models/role';
import ApiError from '../../../../components/ApiError';

interface IProps {
  course: ICourse;
}

const DOCUMENTS_BASE_KEY = 'cohorts.assigned.documents';

const Documents: React.FC<IProps> = ({ course }) => {
  const { t } = useTranslation();

  const {
    isVisible: isVisibleRequestVerificationModal,
    toggle: toggleRequestVerificationModal
  } = useToggleVisibility(false);

  const isOrgAdmin = useHaveRole(RoleName.OrganisationAdmin);

  const canManageAssignedCohort = useCan(Permissions.ManageAssignedCohorts);

  const canUploadDocuments = useMemo(() => {
    if (
      [CourseStatus.Invited, CourseStatus.RejectedByCertifier].includes(
        course.status
      ) &&
      canManageAssignedCohort
    ) {
      return true;
    }
    return false;
  }, [course, canManageAssignedCohort]);

  const { isTaskOverdue, isTaskExpired, task } = useTaskOverdue(
    {
      code: TaskCodes.UploadCourseDocuments,
      meta: {
        schoolId: course.schoolId,
        cohortId: course.cohortId
      }
    },
    { enabled: canUploadDocuments && !isOrgAdmin }
  );

  const { documents, isFetchingDocuments, errorFetchingDocuments } =
    useFetchCourseDocuments(course.id);

  const {
    requestCourseDocumentsVerification,
    isRequestCourseDocumentsVerification
  } = useRequestCourseDocumentsVerification();

  const {
    deleteCourseDocument,
    documentToBeDeleted,
    setDocumentToBeDeleted,
    isDeletingCourseDocument
  } = useDeleteCourseDocument(course);

  const { localDocuments, handleChange, handleSuccessfullyDeleted } =
    useDocumentAction(documents);

  const canManageDocuments =
    (canUploadDocuments && !isTaskOverdue) || isOrgAdmin;

  if (isFetchingDocuments) {
    return <PageLoader />;
  }

  if (errorFetchingDocuments) {
    return <ApiError error={errorFetchingDocuments} />;
  }

  return (
    <Grid container rowSpacing={2} columnSpacing={8}>
      <Grid item xs={12} sx={{ mb: 2 }}>
        <RequestReview
          course={course}
          documents={localDocuments}
          onRequestVerification={toggleRequestVerificationModal}
          isTaskOverdue={isTaskOverdue}
          isTaskExpired={isTaskExpired}
          task={task}
        />
      </Grid>
      <Grid item xs={12} md={6} sx={{ mb: { xs: 3 } }}>
        <FileCard
          course={course}
          title={t(
            `${DOCUMENTS_BASE_KEY}.types.${SchoolCourseDocumentType.AnnualTimetables}`
          )}
          fileType={SchoolCourseDocumentType.AnnualTimetables}
          canManage={canManageDocuments}
          files={localDocuments[SchoolCourseDocumentType.AnnualTimetables]}
          mode={Mode.Edit}
          onChange={handleChange}
          onDelete={setDocumentToBeDeleted}
        />
      </Grid>
      <Grid item xs={12} md={6} sx={{ mb: { xs: 3 } }}>
        <FileCard
          course={course}
          title={t(
            `${DOCUMENTS_BASE_KEY}.types.${SchoolCourseDocumentType.TrainersCvs}`
          )}
          fileType={SchoolCourseDocumentType.TrainersCvs}
          canManage={canManageDocuments}
          files={localDocuments[SchoolCourseDocumentType.TrainersCvs]}
          mode={Mode.Edit}
          onChange={handleChange}
          onDelete={setDocumentToBeDeleted}
        />
      </Grid>
      <Grid item xs={12} md={6} sx={{ mt: { lg: '26px' }, mb: { xs: 3 } }}>
        <FileCard
          course={course}
          title={t(
            `${DOCUMENTS_BASE_KEY}.types.${SchoolCourseDocumentType.ProofOfCommunications}`
          )}
          canManage={canManageDocuments}
          fileType={SchoolCourseDocumentType.ProofOfCommunications}
          files={localDocuments[SchoolCourseDocumentType.ProofOfCommunications]}
          mode={Mode.Edit}
          onChange={handleChange}
          onDelete={setDocumentToBeDeleted}
        />
      </Grid>
      <Grid item xs={12} md={6} sx={{ mt: { lg: '26px' } }}>
        <FileCard
          course={course}
          title={t(
            `${DOCUMENTS_BASE_KEY}.types.${SchoolCourseDocumentType.SubmissionsOfTheQualiopi}`
          )}
          fileType={SchoolCourseDocumentType.SubmissionsOfTheQualiopi}
          canManage={canManageDocuments}
          files={
            localDocuments[SchoolCourseDocumentType.SubmissionsOfTheQualiopi]
          }
          mode={Mode.Edit}
          onChange={handleChange}
          onDelete={setDocumentToBeDeleted}
        />
      </Grid>
      <ConfirmDeleteDocument
        open={!!documentToBeDeleted}
        onClose={() => setDocumentToBeDeleted(null)}
        onConfirm={() =>
          deleteCourseDocument(
            {
              courseId: documentToBeDeleted.courseId,
              documentId: documentToBeDeleted.id
            },
            {
              onSuccess: () => {
                handleSuccessfullyDeleted(documentToBeDeleted);
              }
            }
          )
        }
        isConfirming={isDeletingCourseDocument}
        documentName={documentToBeDeleted?.name}
      />
      <Confirm
        title={t(`${DOCUMENTS_BASE_KEY}.requestVerification.title`)}
        onClose={() => toggleRequestVerificationModal()}
        onConfirm={() =>
          requestCourseDocumentsVerification(
            { courseId: course.id },
            {
              onSuccess: () => {
                toggleRequestVerificationModal();
              }
            }
          )
        }
        open={!!isVisibleRequestVerificationModal}
        isConfirming={isRequestCourseDocumentsVerification}
      >
        {t(`${DOCUMENTS_BASE_KEY}.requestVerification.body`)}
      </Confirm>
    </Grid>
  );
};

export default Documents;
