import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import LastNotCompletedTasksListView from '../components/common/LastNotCompletedTasksListView';
import useHaveRole from 'src/hooks/useHaveRole';
import { RoleName } from 'src/models/role';
import Tasks from '../../tasks/pages/Tasks';
import AssignedCohortCertificate from '../components/student-view/AssignedCohortCertificate';

const DASHBOARD_TRANSLATE_KEY = 'dashboard';

const Dashboard = () => {
  const { t } = useTranslation();

  const isStudent = useHaveRole(RoleName.Student);

  return (
    <React.Fragment>
      <Helmet>
        <title>{t(`${DASHBOARD_TRANSLATE_KEY}.title`)}</title>
      </Helmet>
      {isStudent ? (
        <React.Fragment>
          <LastNotCompletedTasksListView />
          <AssignedCohortCertificate />
        </React.Fragment>
      ) : (
        <Tasks />
      )}
    </React.Fragment>
  );
};

export default Dashboard;
