import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  Divider,
  ListItemText,
  Popover,
  Typography,
  styled,
  ListItem,
  List
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import { Link } from 'react-router-dom';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import useAuth from '../../../../features/auth/hooks/useAuth';
import { SettingsRoutes } from '../../../../features/settings/routes';
import useToggleVisibility from '../../../../hooks/useToggleVisibility';
import { ProfileRoutes } from 'src/features/profile/routes';
import { NavLink as RouterLink } from 'react-router-dom';
import { UserableType } from '../../../../models/user';
import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import { useQueryClient } from 'react-query';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding: ${theme.spacing(0, 1)};
        height: ${theme.header.height};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${theme.palette.secondary.light}
`
);

const USER_DROPDOWN_BASE_TRANSLATE_KEY = 'common.layout.userMenu';

function HeaderUserbox() {
  const { t }: { t: any } = useTranslation();

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const { user, logout } = useAuth();

  const ref = useRef<any>(null);
  const { isVisible, hide, show } = useToggleVisibility(false);

  const handleLogout = async (): Promise<void> => {
    try {
      queryClient.clear();
      hide();
      logout();
      navigate('/');
    } catch (err) { }
  };

  const shouldShowOrgSettings = user.userableType !== UserableType.Student;

  return (
    <>
      <UserBoxButton color="primary" ref={ref} onClick={show}>
        <Avatar
          sx={{ width: 30, height: 30 }}
          variant="rounded"
          alt={user?.firstName}
          src={user?.avatar} />
        <Box
          component="span"
          sx={{
            display: { xs: 'none', md: 'inline-block' }
          }}
        >
          <UserBoxText>
            <UserBoxLabel variant="body1">
              {user?.firstName} {user?.lastName}
            </UserBoxLabel>
            <UserBoxDescription variant="body2">
              {user?.userable?.position}
            </UserBoxDescription>
          </UserBoxText>
        </Box>
        <Box
          component="span"
          sx={{
            display: { xs: 'none', sm: 'flex', ml: 1 }
          }}
        >
          <ExpandMoreTwoToneIcon />
        </Box>
      </UserBoxButton>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={hide}
        open={isVisible}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuUserBox
          sx={{
            minWidth: 210
          }}
          display="flex"
        >
          <Avatar variant="rounded" alt={user?.firstName} src={user?.avatar} />
          <UserBoxText>
            <UserBoxLabel variant="body1">
              {user?.firstName} {user?.lastName}
            </UserBoxLabel>
            <UserBoxDescription variant="body2">
              {user?.userable?.position}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider
          sx={{
            mb: 0
          }}
        />
        <List
          sx={{
            p: 1
          }}
          component="nav"
        >
          <ListItem
            component={RouterLink}
            to={ProfileRoutes.Profile}
            onClick={() => hide()}
            button
          >
            <AccountBoxTwoToneIcon fontSize="small" />
            <ListItemText
              primary={t(`${USER_DROPDOWN_BASE_TRANSLATE_KEY}.profileSettings`)}
            />
          </ListItem>
          {shouldShowOrgSettings && (
            <ListItem
              button
              component={Link}
              to={SettingsRoutes.OrganizationSettings}
              onClick={() => hide()}
            >
              <CorporateFareIcon fontSize="small" />
              <ListItemText
                primary={t(
                  `${USER_DROPDOWN_BASE_TRANSLATE_KEY}.organizationSettings`
                )}
              />
            </ListItem>
          )}
        </List>
        <Divider />
        <Box m={1}>
          <Button color="primary" fullWidth onClick={handleLogout}>
            <LockOpenTwoToneIcon
              sx={{
                mr: 1
              }}
            />
            {t(`${USER_DROPDOWN_BASE_TRANSLATE_KEY}.signOut`)}
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
