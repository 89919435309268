import { ITableApiParams } from '../../../hooks/useTableParams';

export interface IFetchTaskParams extends ITableApiParams { }

export enum TaskCodes {
  InviteStudents = 'invite_students',
  UploadStudentDocuments = 'upload_student_documents',
  VerifyStudentDocuments = 'verify_student_documents',
  AssignTutorToStudent = 'assign_tutor_to_student',
  AddJobDescription = 'add_job_description',
  VerifyJobDescription = 'verify_job_description',
  UploadCourseDocuments = 'upload_course_documents',
  VerifyCourseDocuments = 'verify_course_documents',
  AssignCorrectors = 'assign_corrector_to_student_blocks',
  AssignJuryPresident = 'assign_jury_president',
  AssignJuryMember = 'assign_jury_member',
  UploadBlockDeliverables = 'upload_block_deliverables',
  UploadFinalExamDocument = 'upload_final_exam_document',
  RescheduleFinalExamDocument = 'reschedule_failed_final_exam',
  EnterFinalExamEvaluation = 'enter_final_exam_evaluation',
  AddEvaluation = 'add_evaluation',
  VerifyEvaluation = 'verify_evaluation',
  UploadStudentPassport = 'upload_student_passport',
  VerifyStudentPassport = 'verify_student_passport',
  AuditStudentCertification = 'audit_student_certification',
  JuryReachStudentCertificationDecision = 'jury_reach_student_certification_decision',
}

export const CERTIFIER_CAN_CREATE_TASKS = [
  TaskCodes.InviteStudents,
  TaskCodes.UploadStudentDocuments,
  TaskCodes.VerifyStudentDocuments,
  TaskCodes.AssignTutorToStudent,
  TaskCodes.AddJobDescription,
  TaskCodes.VerifyJobDescription,
  TaskCodes.UploadCourseDocuments,
  TaskCodes.VerifyCourseDocuments,
  TaskCodes.AssignCorrectors,
  TaskCodes.AssignJuryPresident,
  TaskCodes.AssignJuryMember,
  TaskCodes.UploadBlockDeliverables,
  TaskCodes.UploadFinalExamDocument,
  TaskCodes.AddEvaluation,
  TaskCodes.VerifyEvaluation
];

export const SCHOOL_CAN_CREATE_TASKS = [
  TaskCodes.InviteStudents,
  TaskCodes.UploadStudentDocuments,
  TaskCodes.VerifyStudentDocuments,
  TaskCodes.AssignTutorToStudent,
  TaskCodes.AddJobDescription,
  TaskCodes.VerifyJobDescription,
  TaskCodes.UploadCourseDocuments,
  TaskCodes.AssignCorrectors,
  TaskCodes.AssignJuryMember,
  TaskCodes.UploadBlockDeliverables
];

interface ITaskManualNotifications {
  name?: string;
  text?: string;
  sendOnEmail?: boolean;
  sendOnPlatform?: boolean;
}

interface IEditDeadline {
  dueDate: string | Date;
  daysToComplete: number | string;
}

export interface ITaskManual {
  name: string;
  description?: string;
  deadline?: IEditDeadline;
  code?: string;
  users?: string[];
}

export interface ICreateTaskManualForm {
  certificateId?: string;
  cohortId?: string;
  task?: ITaskManual;
  notification?: ITaskManualNotifications;
  role?: string;
}
