import { AlertTitle, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import RejectMessageInfo from 'src/features/cohorts/components/common/RejectMessageInfo';
import { IJob } from 'src/models/job';
import { StyledAlertWithAction } from '../RequestReview';

const STUDENT_JOB_TRANSLATE_KEY = 'profile.student.job';

interface IProps {
  job: IJob;
  onVerify(): void;
  readOnly: boolean;
}

const RejectedAlert: React.FC<IProps> = ({ job, onVerify, readOnly }) => {
  const { t } = useTranslation();
  return (
    <StyledAlertWithAction
      sx={{ border: '1px solid#ff5e7c;', alignItems: 'start' }}
      severity="error"
      action={
        <Button
          disabled={readOnly}
          color="primary"
          size="medium"
          onClick={onVerify}
          variant="contained"
          sx={{
            minWidth: '90px'
          }}
        >
          {t(`${STUDENT_JOB_TRANSLATE_KEY}.verify.button`)}
        </Button>
      }
    >
      <AlertTitle>
        <strong>
          {t(`${STUDENT_JOB_TRANSLATE_KEY}.reviewAlert.rejectedTitle`)}
        </strong>
      </AlertTitle>
      {t(`${STUDENT_JOB_TRANSLATE_KEY}.reviewAlert.rejectedText`)}
      <RejectMessageInfo statusLogs={job.statusLogs} />
    </StyledAlertWithAction>
  );
};

export default RejectedAlert;
