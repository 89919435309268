import React, { useState, ChangeEvent } from "react";
import { IApiError } from "src/utils/axios";
import { useSnackbar } from 'notistack';
import { useNavigate } from "react-router";
import { StudentRoutes } from "src/features/students/routes";
import { Table, TableHead, TableRow, TableCell, Checkbox, TableBody, Typography, Box, Grid, MenuItem, TextField, Button, Avatar } from "@mui/material";
import { t } from 'i18next';
import useFetchCohortStudentCertifications from "../../hooks/api/useFetchCohortStudentCertifications";
import useToggleVisibility from "src/hooks/useToggleVisibility";

import { ISchool } from "src/models/school";

import PageLoader from "src/components/PageLoader";
import ApiError from "src/components/ApiError";
import BulkAssignAuditor from "./BulkAssignAuditor";

import TableContainer from "src/components/Table/TableContainer";
import TableHeaderSortLabel from "src/components/Table/TableHeaderSortLabel";
import useTableParams from "src/hooks/useTableParams";
import TableActions from "src/components/Table/TableActions";

import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import useExportStudentCertificationReports from "../../hooks/api/useExportStudentCertificationReports";
import { useAssignAuditorsToStudentsInRedemption } from "../../hooks/api/useAssignAuditorsToStudents";
import Label from "src/components/Label";
import { areSameUsers, printUserName, studentIsInRedemption, studentIsOnHold } from 'src/utils/userHelpers';
import useAuth from "src/features/auth/hooks/useAuth";
import StudentBlocksPreview from "./StudentBlocksPreview";
import { StudentCertificationStatus, CertificationStatus, VerificationStatus } from "src/utils/const";
import { IStudentBlockBasicInfo } from "src/models/student-block";
import { IStudentCertification } from "src/models/student-certification";

const STUDENTS_PER_PAGE = 50;

export enum Columns {
    FirstName = 'student_users.first_name ',
    LastName = 'student_users.last_name',
    Email = 'student_users.email',
    School = 'student_users.school_short_name',
    Documents = 'student_users.student_status',
    JobDescription = 'jobDescription',
    Blocks = 'blocks',
    certificationPassport = 'student_certifications.passport_status',
    AssignedAuditor = 'auditor_users.email',
    AuditorRecommendation = 'student_certifications.auditor_recommendation',
    FinalValidation = 'student_certifications.jury_decision'
}

const MAP_COLOR_TO_DECISION_OPTION = {
    [StudentCertificationStatus.FullDiploma]: 'success',
    [StudentCertificationStatus.NoDiploma]: 'error',
    [StudentCertificationStatus.PartialDiploma]: 'warning',
    missing: 'secondary'
};

const MAP_COLOR_TO_DOCUMENT_STATUS = {
    [VerificationStatus.Draft]: 'secondary',
    [VerificationStatus.PendingVerification]: 'secondary',
    [VerificationStatus.Verified]: 'success',
    [VerificationStatus.Rejected]: 'error',
    missing: 'secondary'
};

interface IProps {
    cohortId: string,
    schools: ISchool[]
}

const CohortRedemption: React.FC<IProps> = ({ cohortId, schools }) => {

    const { user: auth } = useAuth();

    const { enqueueSnackbar } = useSnackbar();

    const {
        assignAuditorToStudents,
        isAssigningAuditorToStudents
    } = useAssignAuditorsToStudentsInRedemption();

    const {
        exportStudentCertificationReports,
        isExportingStudentCertificationReports
    } = useExportStudentCertificationReports();

    const navigate = useNavigate();

    const { onChangePage, onChangeSort, onChangeSearchedTerm, tableParams } = useTableParams({
        perPage: STUDENTS_PER_PAGE,
        filters: { inRedemption: 1 }
    });

    const [searchedTerm, setSearchedTerm] = useState<string>('');
    const onChangeSearch = (value) => {
        onChangePage(1);
        onChangeSearchedTerm(value);
        setSearchedTerm(value);
    };


    const {
        cohortStudentCertifications,
        isFirstFetchingCohortStudentCertifications,
        isFetchingCohortStudentCertifications,
        errorFetchingCohortStudentCertifications,
        refetchStudentCertifications
    } = useFetchCohortStudentCertifications(cohortId, tableParams)

    const [selectedItems, setSelectedItems] = useState<string[]>([]);

    const handleSelectAll = (event: ChangeEvent<HTMLInputElement>): void => {
        setSelectedItems(event.target.checked ?
            cohortStudentCertifications.data.filter((studentCertification) => {
                return !areActionsDisabled(studentCertification);
            }).map((student) => student.id) : []
        );
    };
    const handleSelectSingle = (event: ChangeEvent<HTMLInputElement>, id: string): void => {
        if (selectedItems.includes(id)) {
            setSelectedItems(selectedItems.filter(itemId => itemId !== id))
        } else {
            setSelectedItems([...selectedItems, id])
        }
    };


    const handleExportCSV = () => {
        exportStudentCertificationReports(cohortId)
    }

    const handleAssignAuditor = (values: any) => {
        if (isAssigningAuditorToStudents) {
            return;
        }

        assignAuditorToStudents(
            { cohortId, data: { auditorId: values.auditorId, studentCertifications: selectedItems } },
            {
                onSuccess: () => {
                    hideSelectAuditorModal()
                    refetchStudentCertifications()
                    setSelectedItems([])
                    enqueueSnackbar(t(`cohorts.certification.assignAuditorToStudentsSuccessSnackMessage`), {
                        variant: 'success'
                    });
                },
                onError: (error: IApiError) => {
                    hideSelectAuditorModal()
                    refetchStudentCertifications()
                    enqueueSnackbar(error.message, { variant: 'error' });

                }
            })
    }

    const navigateToStudent = (studentId: string) =>
        navigate(
            StudentRoutes.Student.replace(':studentId', studentId)
        )

    const {
        show: showSelectAuditorModal,
        hide: hideSelectAuditorModal,
        isVisible: isVisibleSelectAuditorModal,
    } = useToggleVisibility(false);

    const areActionsDisabled = (studentCertification: IStudentCertification) => {
        const isStudentOnHold = studentIsOnHold(studentCertification.student)
        const isJuryDecisionMade = !!studentCertification.redemptionJuriedAt
        return isStudentOnHold || isJuryDecisionMade
    }

    if (isFirstFetchingCohortStudentCertifications || isFetchingCohortStudentCertifications) {
        return <Box sx={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}><PageLoader /></Box>;
    }

    if (errorFetchingCohortStudentCertifications) {
        return <ApiError error={errorFetchingCohortStudentCertifications} />;
    }

    const compactStudentInfoView = true;

    return <>
        <TableContainer
            isFetching={isFetchingCohortStudentCertifications}
            onChangePage={onChangePage}
            total={cohortStudentCertifications.meta.total}
            currentPage={tableParams.page}
            perPage={tableParams.perPage}
        >
            <Grid container sx={{ mb: 2 }} spacing={2}>

                <Grid item sm={3} >
                    <TextField
                        size='medium'
                        margin='none'
                        sx={{ mt: '1px' }} // TODO Easy FIX  
                        fullWidth
                        placeholder={t(`common.input.placeholder.search`)}
                        onChange={(e) => { onChangeSearch(e.target.value); }}
                        variant="outlined"
                        value={searchedTerm}
                    />
                </Grid>
                <Grid
                    item
                    sm={9}
                    display="flex"
                    alignItems="center"
                    justifyContent="end"
                >
                    {/* <Button
                        variant="contained"
                        size="small"
                        sx={{ mr: 2 }}
                        onClick={handleExportCSV}
                        disabled={isExportingStudentCertificationReports}>
                        <DownloadIcon fontSize="small" sx={{ mr: 1 }} />
                        <Typography>
                            {t(`cohorts.certification.actions.exportCertificationCSV`)}
                        </Typography>
                    </Button> */}
                    <TableActions
                        variant='contained'
                        disabled={selectedItems.length < 1}
                        buttonPlaceholder={<>
                            <AddIcon fontSize="small" sx={{ mr: 1 }} />
                            <Typography fontWeight={600}>
                                {t(`common.buttons.actions`)}
                            </Typography>
                        </>}
                    >
                        <MenuItem sx={{ display: 'flex', justifyContent: 'space-between', width: 180 }} key='action-assign-to-user' onClick={showSelectAuditorModal}>
                            <span style={{ paddingRight: "5px" }} >{t('cohorts.certification.actions.bulkAssignAuditor')}</span>
                            <AddIcon fontSize="small" />
                        </MenuItem>
                    </TableActions>
                </Grid>
            </Grid>
            <Table >
                <TableHead>
                    <TableRow>
                        <TableCell
                            key="checkbox"
                            align="left"
                            size='small'
                            padding='none'
                        >
                            <Checkbox
                                checked={selectedItems.length > 0 && selectedItems.length === cohortStudentCertifications.data.length}
                                indeterminate={selectedItems.length > 0 && selectedItems.length < cohortStudentCertifications.data.length}
                                onChange={handleSelectAll}
                            />
                        </TableCell>
                        {!compactStudentInfoView && (<>
                            <TableCell key="firstName" align="left">
                                <TableHeaderSortLabel
                                    columnName={Columns.FirstName}
                                    sort={tableParams.sort}
                                    onChangeSort={onChangeSort}
                                >
                                    {t(`cohorts.certification.tableHeaders.firstName`)}
                                </TableHeaderSortLabel>
                            </TableCell>
                            <TableCell key="lastName" align="left" size='small'>
                                <TableHeaderSortLabel
                                    columnName={Columns.LastName}
                                    sort={tableParams.sort}
                                    onChangeSort={onChangeSort}
                                >
                                    {t(`cohorts.certification.tableHeaders.lastName`)}
                                </TableHeaderSortLabel>
                            </TableCell>
                            <TableCell key="email" align="left">
                                <TableHeaderSortLabel
                                    columnName={Columns.Email}
                                    sort={tableParams.sort}
                                    onChangeSort={onChangeSort}
                                >
                                    {t(`cohorts.certification.tableHeaders.email`)}
                                </TableHeaderSortLabel>
                            </TableCell>
                            <TableCell key="email" align="left">
                                <TableHeaderSortLabel
                                    columnName={Columns.Email}
                                    sort={tableParams.sort}
                                    onChangeSort={onChangeSort}
                                >
                                    {t(`cohorts.certification.tableHeaders.email`)}
                                </TableHeaderSortLabel>
                            </TableCell>
                        </>)}
                        {compactStudentInfoView && (
                            <TableCell key="email" align="left">
                                <TableHeaderSortLabel
                                    columnName={Columns.Email}
                                    sort={tableParams.sort}
                                    onChangeSort={onChangeSort}
                                >
                                    {t(`blocks.table.column.student`)}
                                </TableHeaderSortLabel>
                            </TableCell>
                        )}
                        <TableCell key="school" align="left">
                            <TableHeaderSortLabel
                                columnName={Columns.School}
                                sort={tableParams.sort}
                                onChangeSort={onChangeSort}
                            >
                                {t(`cohorts.certification.tableHeaders.school`)}
                            </TableHeaderSortLabel>
                        </TableCell>
                        <TableCell key="documents" align="left">
                            <TableHeaderSortLabel
                                columnName={Columns.Documents}
                                sort={tableParams.sort}
                                onChangeSort={onChangeSort}
                            >
                                {t(`cohorts.certification.tableHeaders.documents`)}
                            </TableHeaderSortLabel>
                        </TableCell>
                        <TableCell key="jobDescription" align="left">
                            {t(`cohorts.certification.tableHeaders.jobDescription`)}
                        </TableCell>
                        <TableCell key="blocks" align="left">
                            {t(`cohorts.certification.tableHeaders.blocks`)}
                        </TableCell>
                        <TableCell key="certificationPassport" align="left">
                            <TableHeaderSortLabel
                                columnName={Columns.certificationPassport}
                                sort={tableParams.sort}
                                onChangeSort={onChangeSort}
                            >
                                {t(`cohorts.certification.tableHeaders.certificationPassport`)}
                            </TableHeaderSortLabel>
                        </TableCell>
                        <TableCell key="auditor" align="left">
                            <TableHeaderSortLabel
                                columnName={Columns.AssignedAuditor}
                                sort={tableParams.sort}
                                onChangeSort={onChangeSort}
                            >
                                {t(`cohorts.certification.tableHeaders.assignedAuditor`)}
                            </TableHeaderSortLabel>
                        </TableCell>
                        <TableCell key="auditorRecommendation" align="left">
                            <TableHeaderSortLabel
                                columnName={Columns.AuditorRecommendation}
                                sort={tableParams.sort}
                                onChangeSort={onChangeSort}
                            >
                                {t(`cohorts.certification.tableHeaders.auditorRecommendation`)}
                            </TableHeaderSortLabel>
                        </TableCell>
                        <TableCell key="finalValidation" align="left">
                            <TableHeaderSortLabel
                                columnName={Columns.FinalValidation}
                                sort={tableParams.sort}
                                onChangeSort={onChangeSort}
                            >
                                {t(`cohorts.certification.tableHeaders.finalValidation`)}
                            </TableHeaderSortLabel>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {cohortStudentCertifications.data.map((studentCertification) => {
                        const isSelected = selectedItems.includes(studentCertification.id);
                        const school = schools.find(item => item.id === studentCertification.student.userable.schoolId);
                        const isStudentOnHold = studentIsOnHold(studentCertification.student)

                        const redemptionStudentBlocks = studentCertification.student.userable.studentBlocks.filter((block) => block.isFailedByJuryInRegularFlow);

                        const total = redemptionStudentBlocks.length;
                        const passed = redemptionStudentBlocks.filter((block: IStudentBlockBasicInfo) => {
                            return block.redemptionCertificationStatus === CertificationStatus.Passed
                        }).length;
                        const failed = redemptionStudentBlocks.filter((block: IStudentBlockBasicInfo) => {
                            return block.redemptionCertificationStatus === CertificationStatus.Failed
                        }).length;
                        const notCalculated = redemptionStudentBlocks.filter((block: IStudentBlockBasicInfo) => {
                            return block.redemptionCertificationStatus !== CertificationStatus.Passed &&
                                block.redemptionCertificationStatus !== CertificationStatus.Failed
                        }).length;


                        return (
                            <TableRow
                                hover={true}
                                key={studentCertification.id}
                                onClick={() => navigateToStudent(studentCertification.student.userable.id)}
                            >
                                <TableCell
                                    size='small'
                                    padding='none'
                                    onClick={(e) => e.stopPropagation()}>
                                    <Checkbox
                                        disabled={areActionsDisabled(studentCertification)}
                                        checked={isSelected}
                                        onChange={(event) => handleSelectSingle(event, studentCertification.id)}
                                        value={isSelected}
                                    />
                                </TableCell>
                                {!compactStudentInfoView && (<>
                                    <TableCell>
                                        <Typography>{studentCertification.student.firstName}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>{studentCertification.student.lastName}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>{studentCertification.student.email}</Typography>
                                    </TableCell>
                                </>)}
                                {compactStudentInfoView && (
                                    <TableCell>
                                        <Box display="flex">
                                            <Avatar sx={{ mr: 1 }} src={studentCertification.student.avatar} />
                                            <Box>
                                                <Typography noWrap color={isStudentOnHold ? 'error.main' : ''}>
                                                    {printUserName(studentCertification.student)}
                                                </Typography>
                                                <Typography noWrap color={isStudentOnHold ? 'error.main' : ''} variant="subtitle2">
                                                    {studentCertification.student.email}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </TableCell>)}
                                <TableCell>
                                    <Typography>{school.longName}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Label color={MAP_COLOR_TO_DOCUMENT_STATUS[studentCertification.student.userable.documentsStatus || 'missing'] as any}>
                                        {t(`cohorts.certification.certificationDocumentsStatuses.${studentCertification.student.userable.documentsStatus || 'missing'}`)}
                                    </Label>
                                </TableCell>
                                <TableCell>
                                    <Label color={MAP_COLOR_TO_DOCUMENT_STATUS[studentCertification.student.userable.job?.jobStatus || 'missing'] as any}>
                                        {t(`cohorts.certification.certificationDocumentsStatuses.${studentCertification.student.userable.job?.jobStatus || 'missing'}`)}
                                    </Label>
                                </TableCell>
                                <TableCell>
                                    <StudentBlocksPreview
                                        blocks={cohortStudentCertifications.meta.blocks}
                                        studentBlocks={redemptionStudentBlocks}
                                        resolveCertificationStatus={(b) => b.redemptionCertificationStatus}
                                        resolveJuryDecision={(b) => b.redemptionJuryDecision}
                                    >
                                        {notCalculated > 0 ?
                                            <Label color="secondary">
                                                {`-/${total}`}
                                            </Label> :
                                            <Label color={(passed === total && 'success') || (failed === total && 'error') || 'warning'} sx={{ whiteSpace: 'nowrap' }}>
                                                {`${passed}/${total}`}
                                            </Label>}
                                    </StudentBlocksPreview>
                                </TableCell>
                                <TableCell>
                                    <Label color={MAP_COLOR_TO_DOCUMENT_STATUS[studentCertification.redemptionPassportStatus || 'missing'] as any}>
                                        {t(`cohorts.certification.certificationDocumentsStatuses.${studentCertification.redemptionPassportStatus || 'missing'}`)}
                                    </Label>
                                </TableCell>
                                <TableCell>
                                    {studentCertification.redemptionAuditor ? <Grid>
                                        <Typography color={areSameUsers(auth, studentCertification.redemptionAuditor) && 'primary.main'}>
                                            {printUserName(studentCertification.redemptionAuditor)}
                                        </Typography>
                                        <Typography color={areSameUsers(auth, studentCertification.redemptionAuditor) && 'primary.main'}>
                                            {studentCertification.redemptionAuditor.email}
                                        </Typography>
                                    </Grid> : ''}
                                </TableCell>
                                <TableCell>
                                    {studentCertification.redemptionAuditor && studentCertification.redemptionAuditorRecommendation ?
                                        <Label color={MAP_COLOR_TO_DECISION_OPTION[studentCertification.redemptionAuditorRecommendation] as any}>
                                            {t(`cohorts.certification.certificationDecisionOptions.${studentCertification.redemptionAuditorRecommendation}`)}
                                        </Label> : ''}
                                </TableCell>
                                <TableCell>
                                    {studentCertification.redemptionJury && studentCertification.redemptionJuryDecision ?
                                        <Label color={MAP_COLOR_TO_DECISION_OPTION[studentCertification.redemptionJuryDecision] as any}>
                                            {t(`cohorts.certification.certificationDecisionOptions.${studentCertification.redemptionJuryDecision}`)}
                                        </Label> : ''}
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer >
        <BulkAssignAuditor
            isLoading={isAssigningAuditorToStudents}
            open={isVisibleSelectAuditorModal}
            onClose={hideSelectAuditorModal}
            onSubmit={handleAssignAuditor}
        />
    </>
}

export default CohortRedemption;