import { useSnackbar } from 'notistack';
import { useMutation, useQueryClient } from 'react-query';
import { IJobRequiredKnowHow } from 'src/models/job-required-know-how';
import { IApiError } from '../../../../../utils/axios';
import {
  addJobRequiredKnowHow as addJobRequiredKnowHowApi,
  ADD_JOB_REQUIRED_KNOW_HOW_KEY,
  FETCH_STUDENT_JOB_KEY
} from '../../../utils/api';

const useAddJobRequiredKnowHow = () => {
  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const {
    mutate: addJobRequiredKnowHow,
    isLoading: isAddingJobRequiredKnowHow
  } = useMutation(addJobRequiredKnowHowApi, {
    mutationKey: ADD_JOB_REQUIRED_KNOW_HOW_KEY,
    onSuccess: (data: IJobRequiredKnowHow) => {
      queryClient.invalidateQueries([FETCH_STUDENT_JOB_KEY]);
    },
    onError: (error: IApiError) => {
      enqueueSnackbar(error.message, {
        variant: 'error'
      });
    }
  });

  return {
    addJobRequiredKnowHow,
    isAddingJobRequiredKnowHow
  };
};

export default useAddJobRequiredKnowHow;
