import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { IFolder } from '../../../../../models/folder';
import { IApiError } from '../../../../../utils/axios';
import {
  deleteCertificateFolder as deleteCertificateFolderApi,
  DELETE_CERTIFICATE_FOLDER_KEY
} from '../../../utils/api';

const useDeleteCertificateFolder = () => {
  const [certificateFolderToBeDeleted, setCertificateFolderToBeDeleted] =
    useState<Partial<IFolder>>(null);

  const { enqueueSnackbar } = useSnackbar();

  const {
    mutate: deleteCertificateFolder,
    isLoading: isDeletingCertificateFolder
  } = useMutation(deleteCertificateFolderApi, {
    mutationKey: DELETE_CERTIFICATE_FOLDER_KEY,
    onSuccess: () => {
      setCertificateFolderToBeDeleted(null);
    },
    onError: (error: IApiError) => {
      enqueueSnackbar(error.message, {
        variant: 'error'
      });
    }
  });

  return {
    certificateFolderToBeDeleted,
    isDeletingCertificateFolder,
    setCertificateFolderToBeDeleted,
    deleteCertificateFolder
  };
};

export default useDeleteCertificateFolder;
