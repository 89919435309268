import { Box, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { ReactNode } from 'react';

import { isActive, isDescending } from '../../../hooks/useTableParams';
import { ISort } from '../../../utils/types';

interface IProps {
  columnName: string;
  sort: ISort;
  children: ReactNode;
  onChangeSort(columName: string): void;
}

const TableHeaderSortLabel: React.FC<IProps> = ({
  columnName,
  sort,
  onChangeSort,
  children
}) => {
  return (
    <TableSortLabel
      active={isActive(columnName, sort)}
      direction={isActive(columnName, sort) ? sort.order : undefined}
      onClick={() => onChangeSort(columnName)}
    >
      {children}
      {isActive(columnName, sort) ? (
        <Box component="span" sx={visuallyHidden}>
          {isDescending(columnName, sort)
            ? 'sorted descending'
            : 'sorted ascending'}
        </Box>
      ) : null}
    </TableSortLabel>
  );
};

export default TableHeaderSortLabel;
