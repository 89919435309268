import auth from '../../features/auth/translate/en';
import certificates from '../../features/certificates/translate/en';
import schools from '../../features/schools/translate/en';
import acl from '../../features/acl/translate/en';
import profile from 'src/features/profile/translate/en';
import companies from '../../features/companies/translate/en';
import settings from '../../features/settings/translate/en';
import cohorts from '../../features/cohorts/translate/en';
import students from 'src/features/students/translate/en';
import tasks from 'src/features/tasks/translate/en';
import dashboard from 'src/features/dashboard/translate/en';
import notifications from '../../features/notifications/translate/en';
import blocks from '../../features/blocks/translate/en';
import studentBlock from '../../features/student-blocks/translate/en';
import finalExams from '../../features/final-exams/translate/en';
import evaluations from 'src/features/evaluations/translate/en';
import companyEmployees from 'src/features/company-employees/translate/en'

const common = {
  title: 'Learning Certification Platform',
  validation: {
    email: 'The email provided should be a valid email address',
    required: 'The {{field}} field is required',
    requiredLabel: 'Required',
    min8Characters: 'The {{field}} must have at least 8 characters',
    max255Characters: 'The {{field}} must have maximum 255 characters',
    maxNumber:'{{field}} must be less than or equal to {{value}}',
    oneRoleIsRequired: 'User must have at least one role',
    numberType: 'The {{field}} must be type number',
    positiveNumberType: 'The {{field}} must be positive number',
    passwordField: 'password',
    passwordConfirmField: 'confirm password',
    emailField: 'email',
    phoneField: 'Format +123xxxxxxxxx or 00123xxxxxxxxx',
    addressField: 'address',
    zipCodeField: 'zip code',
    countryField: 'country',
    cityField: 'city',
    firstNameField: 'first name',
    lastNameField: 'last name',
    shortNameField: 'short name',
    yearOfCertification: 'year of certification',
    longNameField: 'long name',
    name: 'name',
    guardName: 'guard name',
    oldPasswordField: 'old password',
    newPasswordField: 'new password',
    newPasswordConfirmationField: 'confirm new password',
    cvField: 'cv',
    positionField: 'position',
    officePhoneField: 'office phone',
    nameField: 'name',
    companyRegistrationNoField: 'company registration number',
    date: 'The {{field}} field must be in date format',
    activityField: 'activity',
    autonomyLevelField: 'autonomy level',
    departmentMissionField: 'department mission',
    jobMissionField: 'job mission',
    daysField: 'days',
    certificate: 'certificate',
    cohort: 'cohort',
    taskName: 'task name',
    users: 'The {{field}} must have minimum 1 user selected',
    notificationName: 'notification name',
    notificationText: 'notification text',
    roles: 'role',
    companyIdField: 'company',
    tutorIdField: 'tutor',
    statusField: 'company status',
    noOfEmployees: 'Number of employees must be greater than or equal to 0',
    knowledgeField: 'knowledge',
    knowHowField: 'know how',
    contributionField: 'contribution',
    objectiveField: 'objective',
    description: 'description',
    assignTo: 'must have minimum 1 selected assigned to',
    verifyBy: 'must have minimum 1 selected verify by',
    visibleTo: 'must have minimum 1 selected visible to',
    linkedTo: 'linked to',
    competenceRequirement: 'competence requirement',
    juryPresidentId: 'juries president',
    juryPresidentDate: 'The date field is required & must be in date format',
    juryMembers: 'must have minimum 1 selected jury member',
    evaluationConfigurationField:
      'One evaluation configuration field is required',
    feedback: 'feedback',
    evaluation: 'evaluation',
    gridItem: 'evaluation grid item',
    evaluationConfigurationFieldOptions: 'The filed option is required',
    apiErrorMsg: {
      userVerification:
        'Invitation is expired or invalid, please contact support so they can resend it'
    },
    url: 'The {{field}} field must be url',
    learningPlatformField: 'learning platform url',
    duplicate: "Duplicated value"
  },
  layout: {
    languageSwitcher: 'Language Switcher',
    sidebar: {
      schools: 'Schools',
      school: 'School',
      certificates: 'Certificates',
      roles: 'Roles',
      companies: 'Companies',
      cohorts: 'Cohorts',
      assignedCohorts: 'Assigned Cohorts',
      assignedStudents: 'Assigned Students',
      tasks: 'Tasks',
      jobDescription: 'Job Description',
      home: 'Home {{meta}}',
      evaluations: 'Evaluations',
      finalExams: 'Final exams'
    },
    userMenu: {
      profileSettings: 'Profile settings',
      organizationSettings: 'Organization settings',
      profile: 'Profile',
      signOut: 'Sign out'
    },
    mailToSupport: 'Mail to support',
    requiredFieldsAlert: {
      title: 'Complete Personal Information',
      description: 'Please fill out all required personal information before accessing the platform.',
    }
  },
  buttons: {
    cancel: 'Cancel',
    save: 'Save',
    edit: 'Edit',
    delete: 'Delete',
    addCohort: 'Add Cohort',
    confirm: 'Confirm',
    add: 'Add',
    update: 'Update',
    actions: 'Actions',
    reOrder: 'Reorder',
    view: 'View',
    back: 'Back'
  },
  table: {
    emptyMessage: 'No data',
    actions: {
      edit: "Edit",
      delete: "Delete",
      resendInvitation: 'Resend invitations'
    }
  },
  fileTree: {
    createFirstFolder: 'Create first folder',
    createFolder: 'Create folder',
    emptyMessage: 'No files or folders here',
    tooltips: {
      deleteFile: 'Delete file',
      createFile: 'Create file',
      openFile: 'Open file',
      createFolder: 'Create folder',
      createSubFolder: 'Create sub folder',
      uploadFiles: 'Upload files',
      editFolder: 'Edit folder',
      deleteFolder: 'Delete folder',
      editRoles: 'Edit roles'
    },
    edit: {
      roleTitle: 'Edit roles'
    },
    roles: {
      label: 'Select roles...',
      placeholder: 'Select roles'
    },
    createFolderModal: {
      label: 'Folder name',
      placeholder: 'Enter folder name here',
      finishButton: 'Finish'
    }
  },
  dragAndDropDocument: {
    dropFilesToUpload: 'Drop the files to start uploading',
    cantUploadTheseFiles: 'You cannot upload these file types',
    uploaded: 'You have uploaded'
  },
  copy: {
    toBeCopied: 'Copy',
    copied: 'Copied'
  },
  status: {
    comingSoon: {
      titleStatus: 'Status - Coming Soon',
      title: 'Coming Soon',
      description: `We're working on implementing the last features before our launch!`,
      timesup: `Time's up!`,
      email: 'Enter your email address here...',
      notifyMe: 'Notify Me',
      message: `We'll email you once our website is launched!`
    },
    maintenance: {
      titleStatus: 'Status - Maintenance',
      title: 'The site is currently down for maintenance',
      description: 'We apologize for any inconveniences caused'
    },
    status404: {
      titleStatus: 'Status - 404',
      title: `The page you were looking for doesn't exist`,
      description: `It's on us, we moved the content to a different page. The search below should help!`,
      search: 'Search terms here...',
      or: 'OR',
      goto: 'Go to homepage'
    },
    status500: {
      titleStatus: 'Status - 500',
      title: `There was an error, please try again later`,
      description: `The server encountered an internal error and was not able to complete your request`,
      refreshNow: 'Refresh now',
      goBack: 'Go back'
    }
  },
  input: {
    placeholder: {
      search: "Search"
    }
  }
};

const enJSON = {
  common,
  auth,
  schools,
  acl,
  certificates,
  companies,
  settings,
  profile,
  cohorts,
  students,
  tasks,
  dashboard,
  notifications,
  blocks,
  studentBlock,
  finalExams,
  evaluations,
  companyEmployees
};

export default enJSON;
