import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import TableContainer from '../../../../components/Table/TableContainer';
import TableHeaderSortLabel from '../../../../components/Table/TableHeaderSortLabel';
import { ISort } from '../../../../utils/types';
import React, { useState } from 'react';
import { DateFormat, formatDate } from '../../../../utils/dates';
import { ITask } from 'src/models/task';
import Status from '../common/Status';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TaskDetailsModal from '../common/TaskDetailsModal';
import { printUserName } from 'src/utils/userHelpers';
import { TaskStatuses } from 'src/utils/const';
import UpdateIcon from '@mui/icons-material/Update';
import UpdateDisabledIcon from '@mui/icons-material/UpdateDisabled';
import { buildTaskStatusText } from '../../utils/buildTaskStatusText';

interface IProps {
  page: number;
  isFetching: boolean;
  total: number;
  tasks: ITask[];
  sort?: ISort;
  perPage: number;
  onChangePage(page: number): void;
  onChangeSort(sort: any): void;
  onUpdateStatus?(task: ITask): void;
}

const TASKS_TRANSLATE_KEY = 'tasks.listAll';

export enum Columns {
  Name = 'name',
  Status = 'status',
  AssignedBy = 'creation_user_id',
  CreatedAt = 'created_at',
  DueDate = 'due_date'
}

const TasksCreatedByMeTable: React.FC<IProps> = ({
  page,
  isFetching,
  total,
  tasks,
  sort,
  perPage,
  onChangePage,
  onChangeSort,
  onUpdateStatus
}) => {
  const { t } = useTranslation();

  const [taskInModal, setTaskInModal] = useState<ITask>(null);

  return (
    <>
      <TableContainer
        currentPage={page}
        isFetching={isFetching}
        onChangePage={onChangePage}
        total={total}
        perPage={perPage}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell key="name" align="left" width={400}>
                <TableHeaderSortLabel
                  columnName={Columns.Name}
                  sort={sort}
                  onChangeSort={onChangeSort}
                >
                  {t(`${TASKS_TRANSLATE_KEY}.table.columns.name`)}
                </TableHeaderSortLabel>
              </TableCell>
              <TableCell key="status" align="left">
                <TableHeaderSortLabel
                  columnName={Columns.Status}
                  sort={sort}
                  onChangeSort={onChangeSort}
                >
                  {t(`${TASKS_TRANSLATE_KEY}.table.columns.status`)}
                </TableHeaderSortLabel>
              </TableCell>
              <TableCell key="creationUserId" align="left">
                <TableHeaderSortLabel
                  columnName={Columns.AssignedBy}
                  sort={sort}
                  onChangeSort={onChangeSort}
                >
                  {t(`${TASKS_TRANSLATE_KEY}.table.columns.assignedTo`)}
                </TableHeaderSortLabel>
              </TableCell>
              <TableCell key="dueDate" align="left">
                <TableHeaderSortLabel
                  columnName={Columns.DueDate}
                  sort={sort}
                  onChangeSort={onChangeSort}
                >
                  {t(`${TASKS_TRANSLATE_KEY}.table.columns.dueDate`)}
                </TableHeaderSortLabel>
              </TableCell>
              <TableCell key="createdAt" align="left">
                <TableHeaderSortLabel
                  columnName={Columns.CreatedAt}
                  sort={sort}
                  onChangeSort={onChangeSort}
                >
                  {t(`${TASKS_TRANSLATE_KEY}.table.columns.createdAt`)}
                </TableHeaderSortLabel>
              </TableCell>
              <TableCell key="actions" align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {tasks?.map((task) => (
              <TableRow
                hover={false}
                key={task.id}
              >
                <TableCell align="left">
                  <Typography>{task.name}</Typography>
                </TableCell>
                <TableCell align="left">
                  <Status taskStatus={task.status} />
                </TableCell>
                <TableCell align="left">
                  <Typography>{printUserName(task.user)}</Typography>
                </TableCell>
                <TableCell align="left">
                  {formatDate(task.dueDate, DateFormat.DateAndTime)}
                </TableCell>
                <TableCell align="left">
                  {formatDate(task.createdAt, DateFormat.DateAndTime)}
                </TableCell>
                <TableCell align="right">
                  <>
                    <Tooltip
                      title={t(
                        `${TASKS_TRANSLATE_KEY}.table.actionTooltips.show`
                      )}
                      arrow
                    >
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          setTaskInModal(task);
                        }}
                        color="info"
                      >
                        <VisibilityIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    {task.status !== TaskStatuses.TerminatedBySystem && task.status !== TaskStatuses.Overdue && (
                      <Tooltip
                        title={
                          t(
                            `${TASKS_TRANSLATE_KEY}.table.actionTooltips.update`
                          ) +
                          ' ' +
                          buildTaskStatusText(task)
                        }
                        arrow
                      >
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            onUpdateStatus(task);
                          }}
                          color="primary"
                        >
                          {task.status !== TaskStatuses.Done ? (
                            <UpdateIcon fontSize="small" />
                          ) : (
                            <UpdateDisabledIcon fontSize="small" />
                          )}
                        </IconButton>
                      </Tooltip>
                    )}
                  </>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {taskInModal && (
        <TaskDetailsModal
          task={taskInModal}
          onClose={() => setTaskInModal(null)}
        />
      )}
    </>
  );
};

export default TasksCreatedByMeTable;
