const auth = {
  validation: {
    termsAndConditions: 'Vous devez accepter nos termes et conditions',
    wrongCredentials: "Informations d'identification erronées",
    confirmPassword: 'Les deux mots de passe doivent être identiques'
  },
  links: {
    signIn: "S'identifier",
    lostPassword: 'Mot de passe perdu?',
    termsAndConditions: 'termes et conditions',
    signInAgain: 'Voulez-vous réessayer de vous connecter?',
    continueToLogin: 'Continuer à vous connecter',
    newPassword: 'Envoyez moi un nouveau mot de passe',
    resetPassword: 'Réinitialiser le mot de passe',
    createAccount: 'Créer un compte'
  },
  label: {
    accept: "J'accepte le",
    email: 'Adresse e-mail',
    password: 'Mot de passe',
    confirmPassword: 'Confirmez le mot de passe',
    signIn:
      'Remplissez les champs ci-dessous pour vous connecter à votre compte.',
    recoverEmail:
      "Entrez l'e-mail utilisé pour l'inscription pour réinitialiser votre mot de passe.",
    recoverPassword: 'Récupérer mot de passe',
    resetInstruction:
      'Les instructions de réinitialisation du mot de passe ont été envoyées à votre adresse e-mail',
    checkEmail: "Vérifiez votre e-mail pour plus d'instructions",
    resetPassword: 'Réinitialiser le mot de passe',
    newPassword: 'Entrez le nouveau mot de passe.',
    signUp: 'Remplissez les champs ci-dessous pour créer votre compte',
    firstName: 'Prénom',
    lastName: 'Nom de famille'
  },
  error: {
    notFound: 'Utilisateur non trouvé.',
    mailNotSent:
      "Le courrier de réinitialisation du mot de passe n'a pas été envoyé",
    badCredentials: "Les informations d'identification invalides"
  },
  success: {
    resetPassword: 'Mot de passe réinitialisé avec succès.'
  }
};

export default auth;
