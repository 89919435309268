import { Paper, Typography, Divider, CardContent, MenuItem } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import { IUser } from 'src/models/user';
import useFetchTutor from 'src/features/profile/hooks/api/useFetchTutor';
import PageLoader from 'src/components/PageLoader';
import TutorDetails from './TutorDetails';
import PopupActions from 'src/components/Menu/PopupActions';
import AddIcon from '@mui/icons-material/Add';
import LoopIcon from '@mui/icons-material/Loop';
import { studentIsOnHold } from 'src/utils/userHelpers';
interface IProps {
  user: IUser;
  canManageTutor: Boolean;
  onCreateNewJob?(): void
  onReplaceTutorOnJob?(): void
}

const TutorSection: React.FC<IProps> = ({ user, canManageTutor, onCreateNewJob, onReplaceTutorOnJob }) => {
  const { t } = useTranslation();

  const { tutor, isFetchingTutor } = useFetchTutor(user.userable.id);

  if (isFetchingTutor) {
    return <PageLoader />;
  }

  return (
    <Paper
      elevation={1}
      square={false}
      sx={{ borderRadius: '8px', padding: 0, mt: 0 }}
    >
      <Box
        sx={{
          padding: {
            xl: '24px 32px',
            lg: '24px'
          },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          position: 'relative'
        }}
      >
        <Box>
          <Typography variant="h4" gutterBottom sx={{ fontWeight: 700 }}>
            {t(`profile.tutorInfo.tutorInfoSection.title`)}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ fontWeight: 400, fontSize: '15px' }}
          >
            {t(`profile.tutorInfo.tutorInfoSection.subtitle`)}
          </Typography>
        </Box>
        {canManageTutor && (
          <PopupActions disabled={studentIsOnHold(user)} renderIcon={() => <EditIcon fontSize="small" />}          >
            <MenuItem key='action-create-new-job' onClick={onCreateNewJob}>
              <span style={{ paddingRight: "5px" }} >{t(`profile.student.job.actions.createNewJob.title`)}</span>
              <AddIcon fontSize="small" />
            </MenuItem>
            <MenuItem key='action-replace-tutor-on-job' onClick={onReplaceTutorOnJob}>
              <span style={{ paddingRight: "5px" }} >{t(`profile.student.job.actions.replaceTutorOnJob.title`)}</span>
              <LoopIcon fontSize="small" />
            </MenuItem>
          </PopupActions>
        )}
      </Box>
      <Divider />
      <CardContent
        sx={{
          padding: { xl: '32px 32px 24px 32px', lg: '32px 24px 24px 24px' }
        }}
      >
        <TutorDetails tutor={tutor} />
      </CardContent>
    </Paper>
  );
};

export default TutorSection;
