import { Box, Button, IconButton, styled, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LoopIcon from '@mui/icons-material/Loop';

import useToggleVisibility from '../../hooks/useToggleVisibility';
import CreateEditFolderPopup from './CreateEditFolderPopup';
import { FileTreeMode } from './types';
import { useContext } from 'react';
import { DragAndDropContext } from 'src/contexts/DragAndDropContext';

const NoFilesOrFoldersMessage = styled(Box)(
  ({ theme }) => `
  padding: ${theme.spacing(3)} 0 ${theme.spacing(1)} 0;
`
);

interface IProps {
  onCreateFirstFolder(name: string): void;
  onReorder(): void;
  onSaveReorder(): void;
  onCancelReorder(): void;
  canManageTree: boolean;
  canReorderTree?: boolean;
  mode: FileTreeMode;
}

const FILE_TREE_TRANSLATE_KEY = 'common.fileTree';

const CreateFirstLevelFolder: React.FC<IProps> = ({
  onCreateFirstFolder,
  onReorder,
  onSaveReorder,
  onCancelReorder,
  canManageTree,
  canReorderTree,
  mode
}) => {
  const {
    enabled: enabledDragAndDrop,
    enable: enableReorderingView,
    disable: disableReorderingView,
    forceDisabled: forceDisabledDragAndDrop
  } = useContext(DragAndDropContext)

  const { t } = useTranslation();

  const isEditable = mode !== FileTreeMode.View;

  const { hide, isVisible, toggle } = useToggleVisibility(false);

  const handleCreate = (folderName: string) => {
    hide();
    onCreateFirstFolder(folderName);
  };

  const reorder = () => {
    onReorder()
    enableReorderingView()
  };

  const saveReorder = () => {
    onSaveReorder()
    disableReorderingView()
  };

  const cancelReorder = () => {
    onCancelReorder()
    disableReorderingView()
  };

  if (!isEditable || !canManageTree) {
    return null;
  }

  return (
    <NoFilesOrFoldersMessage sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
      {canManageTree && isEditable && !enabledDragAndDrop && (<>
        <Button color="secondary" size="small" variant="text" onClick={toggle}>
          {t(`${FILE_TREE_TRANSLATE_KEY}.createFolder`)}
        </Button>
        {canReorderTree &&
          <Button color="secondary" size="small" variant="text" onClick={reorder}>
            <LoopIcon fontSize="small" />
            {t(`common.buttons.reOrder`)}
          </Button>}
      </>)}
      {canManageTree && isEditable && enabledDragAndDrop && canReorderTree && (<>
        <Button color="secondary" size="small" variant="text" onClick={saveReorder}>
          {t(`common.buttons.save`)}
        </Button>
        <Button color="secondary" size="small" variant="text" onClick={cancelReorder}>
          {t(`common.buttons.cancel`)}
        </Button>
      </>
      )}
      <CreateEditFolderPopup
        isVisible={isVisible}
        onHide={hide}
        onFinish={handleCreate}
      />
    </NoFilesOrFoldersMessage>
  );
};

export default CreateFirstLevelFolder;
