import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { printOptionalInfoDetails } from '../../../../utils/print-details';

interface IProps {
  property: string;
  value?: string;
  children?: React.ReactNode;
}

const InfoItem: React.FC<IProps> = ({ property, value, children }) => {
  return (
    <React.Fragment>
      <Grid item xs={12} sm={4} md={3} style={{ textAlign: 'right' }}>
        <Box>
          <Typography
            sx={{
              pr: {
                xl: 3,
                lg: 1
              },
              pb: 2,
              fontSize: 14
            }}
          >
            {property}:
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={8} md={9}>
        {!children && (
          <Typography style={{ fontWeight: 'bold' }}>
            {printOptionalInfoDetails(value)}
          </Typography>
        )}
        {children}
      </Grid>
    </React.Fragment>
  );
};

export default InfoItem;
