import {
  Grid,
  Paper,
  Typography,
  Divider,
  CardContent,
  IconButton
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import { Mode } from 'src/utils/types';
import { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { IUser } from 'src/models/user';
import { useQueryClient } from 'react-query';
import { FETCH_SCHOOL_EMPLOYEE_KEY } from 'src/features/profile/utils/api';
import { FETCH_COMPANY_EMPLOYEE_KEY } from 'src/features/profile/utils/api';
import { FETCH_CERTIFIER_EMPLOYEE_KEY } from 'src/features/profile/utils/api';
import useUpdateUserRoles from '../../hooks/api/useUpdateUserRoles';
import RolesDetails from './RolesDetails';
import { UserableType } from 'src/models/user';
import useAuth from '../../../auth/hooks/useAuth';

const PROFILE_ROLES_TRANSLATE_KEY = 'profile.roles.rolesSection';

interface IProps {
  user: IUser;
  url: string;
  canManage: boolean;
  isProfile?: boolean;
}

const RolesSection: React.FC<IProps> = ({
  user,
  url,
  canManage,
  isProfile
}) => {
  const { updateProfile } = useAuth();
  const { t } = useTranslation();
  const [personalInfoLocalMode, setPersonalInfoLocalMode] = useState(Mode.View);
  const { isUpdatingUserRoles, updateUserRoles } = useUpdateUserRoles();

  const queryClient = useQueryClient();

  const getFetchingKey = () => {
    switch (user.userableType) {
      case UserableType.CertifierEmployee:
        return FETCH_CERTIFIER_EMPLOYEE_KEY;
      case UserableType.SchoolEmployee:
        return FETCH_SCHOOL_EMPLOYEE_KEY;
      case UserableType.CompanyEmployee:
        return FETCH_COMPANY_EMPLOYEE_KEY;
    }
  };
  const handleEdit = (data) => {
    updateUserRoles(
      { data, url },
      {
        onSuccess: (data) => {
          if (isProfile) {
            updateProfile(data);
          } else {
            const key = getFetchingKey();
            queryClient.invalidateQueries([key]);
          }

          setPersonalInfoLocalMode(Mode.View);
        }
      }
    );
  };
  return (
    <Grid item xs={12}>
      <Grid container spacing-xs={3} sx={{ width: '100%' }}>
        <Grid item xs={12}>
          <Paper
            elevation={1}
            square={false}
            sx={{ borderRadius: '8px', padding: 0 }}
          >
            <Box
              sx={{
                padding: '24px 32px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                position: 'relative'
              }}
            >
              <Box>
                <Typography
                  variant="h4"
                  gutterBottom
                  sx={{ fontWeight: 700, fontSize: '14px' }}
                >
                  {t(`${PROFILE_ROLES_TRANSLATE_KEY}.title`)}
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{ fontWeight: 400, fontSize: '15px' }}
                >
                  {t(`${PROFILE_ROLES_TRANSLATE_KEY}.subtitle`)}
                </Typography>
              </Box>
              {canManage && (
                <IconButton
                  size="small"
                  onClick={() => setPersonalInfoLocalMode(Mode.Edit)}
                  color="primary"
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              )}
            </Box>
            <Divider />
            <CardContent sx={{ padding: '32px', paddingBottom: '24px' }}>
              <RolesDetails
                handleEdit={handleEdit}
                personalInfoLocalMode={personalInfoLocalMode}
                isUpdatingPersonalInfo={isUpdatingUserRoles}
                setPersonalInfoLocalMode={setPersonalInfoLocalMode}
                user={user}
              />
            </CardContent>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RolesSection;
