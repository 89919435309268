import {
  Box,
  Card,
  Typography,
  Grid,
  Button,
  Avatar,
  styled,
  useTheme
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import Label from 'src/components/Label';
import { IBlock } from 'src/models/block';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CohortRoutes } from 'src/features/cohorts/routes';
import { IAssignedCohort } from '../utils/types';

const BLOCK_BASE_KEY = 'blocks';

const CardActions = styled(Box)(
  ({ theme }) => `
      position: absolute;
      right: ${theme.spacing(2)};
      top: ${theme.spacing(3)};
      z-index: 7;
    `
);

const StyledLabel = styled('div')(
  ({ theme }) => `
    margin-left: ${theme.spacing(3)};
    display:inline-block;
  `
);

interface IProps {
  block: IBlock;
  cohort: IAssignedCohort;
}

const Block: React.FC<IProps> = ({ block, cohort }) => {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();

  return (
    <Grid item xs={12} md={4}>
      <Card
        sx={{
          position: 'relative',
          p: 3
        }}
      >
        {!!block.optional && (
          <CardActions>
            <StyledLabel>
              <Label color="info">{t(`${BLOCK_BASE_KEY}.optional`)}</Label>
            </StyledLabel>
          </CardActions>
        )}
        <Box display={'flex'}>
          <Avatar
            sx={{
              mb: 3,
              width: 40,
              height: 40,
              background: `#223354`,
              color: `${theme.palette.info.contrastText}`
            }}
          >
            {block.name.match(/\b\w/g).join('')}
          </Avatar>
          <Typography
            variant="h3"
            sx={{
              m: 1,
              fontSize: `${theme.typography.pxToRem(16)}`
            }}
          >
            {block.name}
          </Typography>
        </Box>
        <Typography
          variant="subtitle2"
          sx={{
            mb: 3,
            fontSize: `${theme.typography.pxToRem(14)}`
          }}
        >
          {cohort.numberOfStudents > 0 ? (
            <>
              <strong>{cohort.numberOfStudents}</strong> {t(`${BLOCK_BASE_KEY}.numberOfStudents`)}
            </>
          ) :
            t(`${BLOCK_BASE_KEY}.noStudents`)
          }
        </Typography>
        <Button
          variant="outlined"
          fullWidth
          to={CohortRoutes.ViewCohortBlock.replace(
            ':cohortId',
            cohort.id
          ).replace(':blockId', block.id)}
          component={Link}
        >
          {t(`${BLOCK_BASE_KEY}.viewBlock`)}
        </Button>
      </Card>
    </Grid>
  );
};

export default Block;
