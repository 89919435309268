import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';
import { IApiError } from '../../../../../utils/axios';
import {
  UPDATE_CERTIFICATE_FOLDER_KEY,
  updateCertificateFolder as updateCertificateFolderApi
} from '../../../utils/api';

const useUpdateCertificateFolder = () => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    mutate: updateCertificateFolder,
    isLoading: isUpdatingCertificateFolder
  } = useMutation(updateCertificateFolderApi, {
    mutationKey: UPDATE_CERTIFICATE_FOLDER_KEY,
    onError: (error: IApiError) => {
      enqueueSnackbar(error.message, {
        variant: 'error'
      });
    }
  });

  return {
    updateCertificateFolder,
    isUpdatingCertificateFolder
  };
};

export default useUpdateCertificateFolder;
