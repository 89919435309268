import { ICompanyEmployee } from 'src/models/company-employee';
import { IJobIndex, IJob, IFrozenJob } from 'src/models/job';
import { IJobMission } from 'src/models/job-mission';
import { IFile } from 'src/models/file';
import { IRole } from 'src/models/role';
import { IUser } from 'src/models/user';
import { axiosInt } from '../../../utils/axios';
import {
  IEditUserAccountInfo,
  IUpdateSchoolEmployeePersonalInfo,
  IUpdateUserPassword,
  IUpdateCertifierPersonalInfo,
  IUpdateCompanyEmployeePersonalInfo,
  IUpdateStudentPersonalInfo,
  IAssignTutor,
  IUpdateStudentJob
} from './types';
import { IJobRequiredKnowledge } from 'src/models/job-required-knowledge';
import { IJobRequiredKnowHow } from 'src/models/job-required-know-how';
import { IJobExpectedContribution } from 'src/models/job-expected-contribution';
import { IEditStudentDocuments } from './types';
import { StudentFlow } from 'src/utils/const';

export const UPDATE_USER_ACCOUNT_INFO_KEY = 'updateUserAccountInfo';
export const updateUserAccountInfo = async (
  userData: Partial<IEditUserAccountInfo>,
  url: string
): Promise<IUser> => {
  const response = await axiosInt.put(url, userData);

  return response.data;
};

export const UPDATE_USER_PASSWORD_KEY = 'updateUserPassword';
export const updateUserPassword = async (
  userData: Partial<IUpdateUserPassword>
): Promise<IUser> => {
  const response = await axiosInt.post(`/profile/update-password`, userData);

  return response.data;
};

export const UPDATE_SCHOOL_EMPLOYEE_PERSONAL_INFO_KEY =
  'updateSchoolEmplyoeePersonalInfo';
export const updateSchoolEmployeePersonalInfo = async (
  employeeData: Partial<IUpdateSchoolEmployeePersonalInfo>,
  url: string
): Promise<IUser> => {
  const response = await axiosInt.put(url, employeeData);

  return response.data;
};

export const UPDATE_CERTIFIER_EMPLOYEE_PERSONAL_INFO_KEY =
  'updateCertifierEmployeePersonalInfo';
export const updateCertifierEmployeePersonalInfo = async (
  certifierData: Partial<IUpdateCertifierPersonalInfo>,
  url: string
): Promise<IUser> => {
  const response = await axiosInt.put(url, certifierData);

  return response.data;
};

export const FETCH_SCHOOL_EMPLOYEE_KEY = 'fetchSchoolEmployee';
export const fetchSchoolEmployee = async (
  id: string,
  schoolId: string
): Promise<IUser> => {
  const response = await axiosInt.get(`/schools/${schoolId}/employees/${id}`);

  return response.data;
};

export const UPLOAD_CV_KEY = 'uploadCv';
export const uploadCv = async (file: File): Promise<string> => {
  const formData = new FormData();
  formData.set('file', file);
  formData.set('file_name', file.name);

  const response = await axiosInt.post(
    '/certifier-employees/profile/upload-cv',
    formData
  );

  return response.data;
};

export const UPDATE_COMPANY_EMPLOYEE_PERSONAL_INFO_KEY =
  'updateCompanyEmployeePersonalInfo';
export const updateCompanyEmployeePersonalInfo = async (
  employeeData: Partial<IUpdateCompanyEmployeePersonalInfo>,
  url: string
): Promise<IUser> => {
  const response = await axiosInt.put(url, employeeData);

  return response.data;
};

export const FETCH_COMPANY_EMPLOYEE_KEY = 'fetchCompanyEmployee';
export const fetchCompanyEmployee = async (
  id: string,
  companyId: string
): Promise<IUser> => {
  const response = await axiosInt.get(
    `/companies/${companyId}/employees/${id}`
  );

  return response.data;
};

export const STORE_DOCUMENT_KEY = 'storeDocument';
export const storeDocument = async (data, url) => {
  const formData = new FormData();
  formData.set('url', data.url);
  formData.set('file_name', data.fileName);
  const response = await axiosInt.post(url, formData);

  return response.data;
};

export const FETCH_CERTIFIER_EMPLOYEE_KEY = 'fetchCertifierEmployee';
export const fetchCertifierEmployee = async (id: string): Promise<IUser> => {
  const response = await axiosInt.get(`/certifier-employees/${id}`);

  return response.data;
};

export const UPDATE_USER_ROLES_KEY = 'updateUserRoles';
export const updateUserRoles = async (
  roles: IRole[],
  url: string
): Promise<IUser> => {
  const response = await axiosInt.put(url, roles);

  return response.data;
};

export const UPDATE_STUDENT_PERSONAL_INFO_KEY = 'updateStudentPersonalInfo';
export const updateStudentPersonalInfo = async (
  studentData: Partial<IUpdateStudentPersonalInfo>,
  url: string
): Promise<IUser> => {
  const response = await axiosInt.put(url, studentData);

  return response.data;
};

export const FETCH_STUDENT_KEY = 'fetchStudent';
export const fetchStudent = async (url: string): Promise<IUser> => {
  const response = await axiosInt.get(url);

  return response.data;
};

export const UPDATE_STATUS_KEY = 'updateStatus';
export const updateStatus = async (
  status: string,
  url: string,
  message: string
): Promise<IUser> => {
  const response = await axiosInt.put(url, {
    studentStatus: status,
    message: message
  });

  return response.data;
};

export const ASSIGN_TUTOR_KEY = 'assignTutor';
export const assignTutor = async (data: IAssignTutor): Promise<IUser> => {
  const response = await axiosInt.post(
    `/students/${data.studentId}/jobs`,
    data
  );

  return response.data;
};

export const UPDATE_STUDENT_FLOW_KEY = 'updateStudentFlow';
export const updateStudentFlow = async (
  studentId: string,
  flow: StudentFlow,
  message: string
): Promise<IUser> => {
  const response = await axiosInt.put(`/students/${studentId}/flow`, {
    flow,
    message
  });

  return response.data;
};

export const FETCH_COMPANIES_KEY = 'fetchCompanies';
export const fetchCompanies = async (schoolId: string) => {
  const response = await axiosInt.get(`/school-companies/${schoolId}/`);

  return response.data;
};

export const FETCH_TUTORS_KEY = 'fetchTutors';
export const fetchTutors = async (
  schoolId: string
): Promise<IUser[]> => {
  const response = await axiosInt.get(`/school-companies/${schoolId}/tutors/`);

  return response.data;
};

export const UPDATE_STUDENT_JOB_KEY = 'updateStudentJob';
export const updateStudentJob = async (
  jobData: Partial<IAssignTutor>,
  studentId: string,
  jobId: string
): Promise<IJob> => {
  const response = await axiosInt.put(
    `/students/${studentId}/jobs/${jobId}`,
    jobData
  );

  return response.data;
};

export const ADD_JOB_MISSION_KEY = 'addJobMission';
export const addJobMission = async (
  jobMission: Partial<IJobMission>
): Promise<IJobMission> => {
  const response = await axiosInt.post(
    `/jobs/${jobMission.jobId}/job-missions`,
    jobMission
  );

  return response.data;
};

export const UPDATE_JOB_MISSION_KEY = 'updateJobMission';
export const updateJobMission = async (
  jobMission: Partial<IJobMission>
): Promise<IJobMission> => {
  const response = await axiosInt.put(
    `/jobs/${jobMission.jobId}/job-missions/${jobMission.id}`,
    jobMission
  );

  return response.data;
};

export const DELETE_JOB_MISSION_KEY = 'deleteJobMission';
export const deleteJobMission = async (
  jobMission: Partial<IJobMission>
): Promise<IJobMission> => {
  const response = await axiosInt.delete(
    `/jobs/${jobMission.jobId}/job-missions/${jobMission.id}`
  );
  return response.data;
};
export const UPLOAD_DOCUMENT_KEY = 'uploadDocument';
export const uploadDocument = async (
  file: File,
  url: string
): Promise<IFile> => {
  const formData = new FormData();
  formData.set('file', file);

  const response = await axiosInt.post(url, formData);
  return response.data;
};
export const UPDATE_TUTOR_KEY = 'updateTutor';
export const updateTutor = async (
  jobId: string,
  tutorId: string
): Promise<IUser> => {
  const response = await axiosInt.put(`/jobs/${jobId}`, tutorId);

  return response.data;
};

export const FETCH_STUDENT_JOB_KEY = 'fetchStudentJob';
export const fetchStudentJob = async (id: string): Promise<IJob> => {
  const response = await axiosInt.get(`/students/${id}/job`);

  return typeof response.data === 'object' && Object.keys(response.data).length === 0 ?
    null :
    response.data;
};

export const FETCH_STUDENT_JOBS_KEY = 'fetchStudentJobs';
export const fetchStudentJobs = async (id: string): Promise<IJobIndex[]> => {
  const response = await axiosInt.get(`/students/${id}/jobs`);
  return response.data;
};

export const FETCH_STUDENT_CERTIFICATION_KEY = 'fetchStudentCertification';
export const fetchStudentCertification = async (id: string): Promise<any> => {
  const response = await axiosInt.get(`/students/${id}/student-certification`);
  return response.data;
};

export const REQUEST_PASSPORT_VERIFICATION_KEY = 'requestPassportVerification';
export const requestPassportVerification = async (
  studentId: string
): Promise<any> => {
  const response = await axiosInt.put(`/students/${studentId}/student-certification/request-passport-verification`);
  return response.data;
};

export const VERIFY_PASSPORT_KEY = 'verifyPassport';
export const verifyPassport = async (
  studentId: string,
  data: any
): Promise<any> => {
  const response = await axiosInt.put(`students/${studentId}/student-certification/verify-passport`, data);
  return response.data;
};

export const FETCH_STUDENT_FROZEN_JOB_KEY = 'fetchStudentFrozenJob';
export const fetchStudentFrozenJob = async (studentId: string, jobId: string): Promise<IFrozenJob> => {
  const response = await axiosInt.get(`/students/${studentId}/jobs/${jobId}`);
  return response.data;
};

export const ADD_JOB_REQUIRED_KNOWLEDGE_KEY = 'addJobRequiredKnowledge';
export const addJobRequiredKnowledge = async (
  jobRequiredKnowledge: Partial<IJobRequiredKnowledge>
): Promise<IJobRequiredKnowledge> => {
  const response = await axiosInt.post(
    `/jobs/${jobRequiredKnowledge.jobId}/job-required-knowledge`,
    jobRequiredKnowledge
  );

  return response.data;
};

export const UPDATE_JOB_REQUIRED_KNOWLEDGE_KEY = 'updateJobRequiredKnowledge';
export const updateJobRequiredKnowledge = async (
  jobRequiredKnowledge: Partial<IJobRequiredKnowledge>
): Promise<IJobRequiredKnowledge> => {
  const response = await axiosInt.put(
    `/jobs/${jobRequiredKnowledge.jobId}/job-required-knowledge/${jobRequiredKnowledge.id}`,
    jobRequiredKnowledge
  );
  return response.data;
};
export const UPDATE_STUDENT_DOCUMENTS_INFO_KEY = 'updateStudentDocuments';
export const updateStudentDocuments = async (
  documentsData: Partial<IEditStudentDocuments>,
  url: string
): Promise<IUser> => {
  const response = await axiosInt.put(url, documentsData);
  return response.data;
};
export const FETCH_TUTOR_KEY = 'fetchTutor';
export const fetchTutor = async (id: string): Promise<IUser> => {
  const response = await axiosInt.get(`/students/${id}/tutor`);

  return response.data;
};

export const DELETE_JOB_REQUIRED_KNOWLEDGE_KEY = 'deleteJobRequiredKnowledge';
export const deleteJobRequiredKnowledge = async (
  jobRequiredKnowledge: Partial<IJobRequiredKnowledge>
): Promise<IJobRequiredKnowledge> => {
  const response = await axiosInt.delete(
    `/jobs/${jobRequiredKnowledge.jobId}/job-required-knowledge/${jobRequiredKnowledge.id}`
  );

  return response.data;
};

export const ADD_JOB_REQUIRED_KNOW_HOW_KEY = 'addJobRequiredKnowHow';
export const addJobRequiredKnowHow = async (
  jobRequiredKnowHow: Partial<IJobRequiredKnowHow>
): Promise<IJobRequiredKnowHow> => {
  const response = await axiosInt.post(
    `/jobs/${jobRequiredKnowHow.jobId}/job-required-know-how`,
    jobRequiredKnowHow
  );

  return response.data;
};

export const UPDATE_JOB_REQUIRED_KNOW_HOW_KEY = 'updateJobRequiredKnowHow';
export const updateJobRequiredKnowHow = async (
  jobRequiredKnowHow: Partial<IJobRequiredKnowHow>
): Promise<IJobRequiredKnowHow> => {
  const response = await axiosInt.put(
    `/jobs/${jobRequiredKnowHow.jobId}/job-required-know-how/${jobRequiredKnowHow.id}`,
    jobRequiredKnowHow
  );

  return response.data;
};

export const DELETE_JOB_REQUIRED_KNOW_HOW_KEY = 'deleteJobRequiredKnowHow';
export const deleteJobRequiredKnowHow = async (
  jobRequiredKnowHow: Partial<IJobRequiredKnowHow>
): Promise<IJobRequiredKnowHow> => {
  const response = await axiosInt.delete(
    `/jobs/${jobRequiredKnowHow.jobId}/job-required-know-how/${jobRequiredKnowHow.id}`
  );

  return response.data;
};

export const ADD_JOB_EXPECTED_CONTRIBUTION_KEY = 'addJobExpectedContribution';
export const addJobExpectedContribution = async (
  jobExpectedContribution: Partial<IJobExpectedContribution>
): Promise<IJobExpectedContribution> => {
  const response = await axiosInt.post(
    `/jobs/${jobExpectedContribution.jobId}/job-expected-contributions`,
    jobExpectedContribution
  );

  return response.data;
};

export const UPDATE_JOB_EXPECTED_CONTRIBUTION_KEY =
  'updateJobExpectedContribution';
export const updateJobExpectedContribution = async (
  jobExpectedContribution: Partial<IJobExpectedContribution>
): Promise<IJobExpectedContribution> => {
  const response = await axiosInt.put(
    `/jobs/${jobExpectedContribution.jobId}/job-expected-contributions/${jobExpectedContribution.id}`,
    jobExpectedContribution
  );

  return response.data;
};

export const DELETE_JOB_EXPECTED_CONTRIBUTION_KEY =
  'deleteJobExpectedContribution';
export const deleteJobExpectedContribution = async (
  jobExpectedContribution: Partial<IJobExpectedContribution>
): Promise<IJobExpectedContribution> => {
  const response = await axiosInt.delete(
    `/jobs/${jobExpectedContribution.jobId}/job-expected-contributions/${jobExpectedContribution.id}`
  );

  return response.data;
};

export const UPDATE_JOB_STATUS_KEY = 'updateJobStatus';
export const updateJobStatus = async (
  status: string,
  studentId: string,
  jobId: string,
  message: string
): Promise<IJob> => {
  const response = await axiosInt.put(
    `/students/${studentId}/jobs/${jobId}/update-verification-status`,
    {
      status,
      message
    }
  );

  return response.data;
};
export const FETCH_FILE_KEY = 'fetchFile';
export const fetchFile = async (id: string): Promise<IFile> => {
  const response = await axiosInt.get(`/files/${id}`);
  return response.data;
};

export const REQUEST_JOB_DESCRIPTION_VERIFICATION_KEY =
  'requestJobDescriptionVerification';
export const requestJobDescriptionVerification = async ({
  jobId
}): Promise<IJob> => {
  const response = await axiosInt.put(`/jobs/${jobId}/request-verification`);
  return response.data;
};
