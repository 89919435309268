
import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';
import { IApiError } from '../../../../../utils/axios';

import {
  reorderCertificateFileTree as reorderCertificateFileTreeApi,
  REORDER_CERTIFICATE_FILE_TREE_KEY
} from '../../../utils/api';

const useReorderCertificateFileTree = () => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    mutate: reorderCertificateFileTree,
    isLoading: isReorderingCertificateFileTree
  } = useMutation(reorderCertificateFileTreeApi, {
    mutationKey: REORDER_CERTIFICATE_FILE_TREE_KEY,
    onError: (error: IApiError) => {
      enqueueSnackbar(error.message, {
        variant: 'error'
      });
    }
  });

  return {
    reorderCertificateFileTree,
    isReorderingCertificateFileTree
  };
};

export default useReorderCertificateFileTree;
