import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { ArrayFormBoxListActions } from '../../../../../../components/ArrayFormBox';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { IJobRequiredKnowledge } from 'src/models/job-required-knowledge';
import { JobStatuses } from 'src/utils/const';
import {
  StyledArrayFormBoxListItem,
  StyledReactQuill
} from '../JobMissions/JobMissionsListItem';

interface IProps {
  jobRequiredKnowledge: IJobRequiredKnowledge;
  index: number;
  onEdit(index: number, jobRequiredKnowledge: IJobRequiredKnowledge): void;
  onDelete(index: number, jobRequiredKnowledge: IJobRequiredKnowledge): void;
  jobStatus: JobStatuses;
  canManage: boolean;
}

const JobRequiredKnowledgeListItem: React.FC<IProps> = ({
  jobRequiredKnowledge,
  index,
  onDelete,
  onEdit,
  jobStatus,
  canManage
}) => {
  const { t } = useTranslation();

  return (
    <StyledArrayFormBoxListItem key={index}>
      <Grid
        container
        sx={{
          display: 'flex',
          flexFlow: 'nowrap',
          justifyContent: 'space-between'
        }}
      >
        <Grid xs={10} item>
          <Box>
            <StyledReactQuill>
              <ReactQuill
                value={jobRequiredKnowledge.knowledge}
                readOnly
                theme={'bubble'}
              />
            </StyledReactQuill>
          </Box>
        </Grid>
        {jobStatus !== JobStatuses.VerifiedBySchool && canManage && (
          <Grid item>
            <ArrayFormBoxListActions
              onDelete={() => onDelete(index, jobRequiredKnowledge)}
              onEdit={() => {
                onEdit(index, jobRequiredKnowledge);
              }}
            />
          </Grid>
        )}
      </Grid>
    </StyledArrayFormBoxListItem>
  );
};

export default JobRequiredKnowledgeListItem;
