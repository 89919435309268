import { useRoutes } from 'react-router-dom';
import router from 'src/router';

import { SnackbarProvider } from 'notistack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { CssBaseline } from '@mui/material';
import React from 'react';

import useAuth from './features/auth/hooks/useAuth';
import ThemeProvider from './theme/context/ThemeProvider';
import AppInit from './components/AppInit';
import ErrorBoundary from './components/ErrorBoundary';

import './i18n/i18n';

function App() {
  const content = useRoutes(router);
  const auth = useAuth();

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SnackbarProvider
          maxSnack={6}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <CssBaseline />
          <ErrorBoundary>
            <React.Fragment>
              {auth.isInitialized ? content : <AppInit />}
            </React.Fragment>
          </ErrorBoundary>
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
