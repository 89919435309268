import { useQuery } from 'react-query';
import { ICourse } from '../../../../models/course';
import { IApiError } from '../../../../utils/axios';
import {
  fetchCourseByCohortAndSchool,
  FETCH_COURSE_BY_COHORT_AND_SCHOOL_KEY
} from '../../utils/api';

const useFetchCourseByCohortAndSchool = (
  cohortId: string,
  schoolId: string
) => {
  const {
    isLoading: isFetchingCourse,
    error: errorFetchingCourse,
    data: course
  } = useQuery<ICourse, IApiError>(
    [FETCH_COURSE_BY_COHORT_AND_SCHOOL_KEY, schoolId, cohortId],
    () => fetchCourseByCohortAndSchool(cohortId, schoolId)
  );

  return {
    isFetchingCourse,
    errorFetchingCourse,
    course
  };
};

export default useFetchCourseByCohortAndSchool;
