import { Box, Button, Collapse, Typography } from '@mui/material';
import MessageIcon from '@mui/icons-material/Message';
import { useTranslation } from 'react-i18next';
import useToggleVisibility from 'src/hooks/useToggleVisibility';
import { IStatusLog } from 'src/models/status-logs';

interface IProps {
  statusLogs: IStatusLog[];
}

const DOCUMENTS_BASE_KEY = 'cohorts.assigned.documents';

const RejectMessageInfo: React.FC<IProps> = ({ statusLogs }) => {
  const { t } = useTranslation();

  const { isVisible, toggle } = useToggleVisibility(false);

  const filterStatusLogs = statusLogs.filter((log) => log.message !== null);

  if (filterStatusLogs?.length !== 0) {
    return (
      <Box sx={{ mt: 2 }}>
        <Button
          variant="outlined"
          onClick={toggle}
          size="small"
          startIcon={<MessageIcon />}
        >
          {t(`${DOCUMENTS_BASE_KEY}.reviewAlert.rejectedMessageReason`)}
        </Button>
        <Collapse in={isVisible} sx={{ mt: 1 }}>
          {filterStatusLogs.map((log) => (
            <Typography
              key={log.id}
              paragraph={true}
              sx={{ fontSize: '14px', fontStyle: 'italic', mb: 1 }}
            >
              {log.message}
            </Typography>
          ))}
        </Collapse>
      </Box>
    );
  }
  return null;
};

export default RejectMessageInfo;
