import { Box, Typography } from '@mui/material';
import React from 'react';
import { printOptionalInfoDetails } from '../../utils/print-details';

interface IProps {
  property: string;
  value?: string | number;
  children?: React.ReactNode;
  direction?: 'column' | 'row';
}

const CertificateInfo: React.FC<IProps> = ({
  property,
  value,
  children,
  direction
}) => {
  return (
    <Box sx={{ pb: 2, display: 'flex', flexDirection: { xs: direction } }}>
      <Box sx={{ minWidth: { md: '165px' } }}>
        <Typography
          sx={{ pb: 0, fontSize: 14, mr: 1, textAlign: { md: 'right' } }}
        >
          {property}:
        </Typography>
      </Box>

      <Box>
        {!children && (
          <Typography style={{ fontWeight: 'bold' }}>
            {printOptionalInfoDetails(value)}
          </Typography>
        )}
        {children}
      </Box>
    </Box>
  );
};

CertificateInfo.defaultProps = {
  direction: 'row'
};

export default CertificateInfo;
