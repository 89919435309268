import { useSnackbar } from 'notistack';
import { useMutation, useQueryClient } from 'react-query';
import { IJobMission } from 'src/models/job-mission';
import { IApiError } from '../../../../../utils/axios';
import {
  FETCH_STUDENT_JOB_KEY,
  updateJobMission as updateJobMissionApi,
  UPDATE_JOB_MISSION_KEY
} from '../../../utils/api';

const useUpdateJobMission = () => {
  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const { mutate: updateJobMission, isLoading: isUpdatingJobMission } =
    useMutation(updateJobMissionApi, {
      mutationKey: UPDATE_JOB_MISSION_KEY,
      onSuccess: (data: IJobMission) => {
        queryClient.invalidateQueries([FETCH_STUDENT_JOB_KEY]);
      },
      onError: (error: IApiError) => {
        enqueueSnackbar(error.message, {
          variant: 'error'
        });
      }
    });

  return {
    updateJobMission,
    isUpdatingJobMission
  };
};

export default useUpdateJobMission;
