import { Box, List, styled } from '@mui/material';
import SidebarMenuItem from './item';
import { useTranslation } from 'react-i18next';
import useCan from 'src/hooks/useCan';
import { SchoolRoutes } from 'src/features/schools/routes';
import { Permissions } from '../../../../utils/const';
import { AclRoutes } from '../../../../features/acl/routes';
import { CertificateRoutes } from 'src/features/certificates/routes';
import { CompanyRoutes } from 'src/features/companies/routes';
import useAuth from 'src/features/auth/hooks/useAuth';
import { UserableType } from 'src/models/user';
import { CohortRoutes } from 'src/features/cohorts/routes';
import { StudentRoutes } from 'src/features/students/routes';
import { TaskRoutes } from '../../../../features/tasks/routes';
import useHaveRole from '../../../../hooks/useHaveRole';
import { RoleName } from '../../../../models/role';
import useFetchStudentJob from '../../../../features/profile/hooks/api/job/useFetchStudentJob';
import * as userType from '../../../../../src/utils/userTypes';
import SchoolLayout from 'src/layouts/UserLayouts/SchoolLayout';
import CertifierLayout from 'src/layouts/UserLayouts/CertifierLayout';
import { MenuWrapper, SubMenuWrapper } from 'src/layouts/components/common';
import StudentLayout from 'src/layouts/UserLayouts/StudentLayout';
// import { FinalExamRoutes } from '../../../../features/final-exams/routes';



const SIDEBAR_BASE_KEY = 'common.layout.sidebar';

function SidebarMenu() {
  const { t }: { t: any } = useTranslation();
  const canReadCertifications = useCan(Permissions.ReadCertifications);
  const canReadSchools = useCan(Permissions.ReadSchools);
  const canManageRoles = useCan(Permissions.ManageRoles);
  const canReadCompanies = useCan(Permissions.ReadCompanies);
  const canReadCohorts = useCan(Permissions.ReadCohorts);
  const canReadAssignedCohorts = useCan(Permissions.ReadAssignedCohorts);

  const { user } = useAuth();

  const isOrgAdmin = useHaveRole(RoleName.OrganisationAdmin);
  const isTutor = useHaveRole(RoleName.Tutor);
  const isStudent = useHaveRole(RoleName.Student);
  const isJuryPresident = useHaveRole(RoleName.JuryPresident);
  const isJuryMember = useHaveRole(RoleName.JuryMember);

  const { job } = useFetchStudentJob(user.userableId, {
    enabled: isStudent
  });

  if (userType.isCertifierEmployee(user)) {
    return <CertifierLayout />
  }

  if (userType.isSchoolEmployee(user)) {
    return <SchoolLayout />
  }

  if (userType.isStudent(user)) {
    return <StudentLayout />
  }

  return (
    <>
      {/* example menu items: */}
      <MenuWrapper>
        <List component="div">
          <SubMenuWrapper>
            <List component="div">
              {/* <SidebarMenuItem
                active={true}
                open={false}
                name={'item name'}
                link={'item-link'}
              >
                <SubMenuWrapper>
                  <List component="div">
                    <SidebarMenuItem
                      active={true}
                      name={'item 2'}
                      link={'item-link'}
                      badge={'item.badge'}
                      badgeTooltip={'item.badgeTooltip'}
                    />
                    <SidebarMenuItem
                      active={true}
                      name={'item 3'}
                      link={'item-link'}
                      badge={'item.badge'}
                      badgeTooltip={'item.badgeTooltip'}
                    />
                  </List>
                </SubMenuWrapper>
              </SidebarMenuItem> */}

              <SidebarMenuItem
                active={true}
                open={true}
                name={t(`${SIDEBAR_BASE_KEY}.tasks`)}
                link={TaskRoutes.Tasks}
              />

              {canReadCertifications && (
                <SidebarMenuItem
                  active={true}
                  open={true}
                  name={t(`${SIDEBAR_BASE_KEY}.certificates`)}
                  link={CertificateRoutes.ListAll}
                />
              )}
              {canReadSchools && (
                <SidebarMenuItem
                  active={true}
                  open={true}
                  name={t(`${SIDEBAR_BASE_KEY}.schools`)}
                  link={SchoolRoutes.ListAll}
                />
              )}
              {canManageRoles && (
                <SidebarMenuItem
                  active={true}
                  open={true}
                  name={t(`${SIDEBAR_BASE_KEY}.roles`)}
                  link={AclRoutes.ListAllRoles}
                />
              )}
              {user.userableType !== UserableType.CertifierEmployee &&
                canReadCompanies && (
                  <SidebarMenuItem
                    active={true}
                    open={true}
                    name={t(`${SIDEBAR_BASE_KEY}.companies`)}
                    link={CompanyRoutes.ListAll}
                  />
                )}
              {canReadCohorts && (
                <SidebarMenuItem
                  active={true}
                  open={true}
                  name={t(`${SIDEBAR_BASE_KEY}.cohorts`)}
                  link={CohortRoutes.ListAll}
                />
              )}
              {canReadAssignedCohorts && !isOrgAdmin && (
                <SidebarMenuItem
                  active={true}
                  open={true}
                  name={t(`${SIDEBAR_BASE_KEY}.assignedCohorts`)}
                  link={CohortRoutes.ListAssigned}
                />
              )}
              {isTutor && (
                <SidebarMenuItem
                  active={true}
                  open={true}
                  name={t(`${SIDEBAR_BASE_KEY}.assignedStudents`)}
                  link={StudentRoutes.Students}
                />
              )}

              {isStudent && (
                <>
                  {job && (
                    <SidebarMenuItem
                      active={true}
                      open={true}
                      name={t(`${SIDEBAR_BASE_KEY}.jobDescription`)}
                      link={StudentRoutes.Job}
                    />
                  )}
                  <SidebarMenuItem
                    active={true}
                    open={true}
                    name={t(`${SIDEBAR_BASE_KEY}.school`)}
                    link={StudentRoutes.School}
                  />
                </>
              )}
              {/* {(isJuryMember || isJuryPresident) && (
                <SidebarMenuItem
                  active={true}
                  open={true}
                  name={t(`${SIDEBAR_BASE_KEY}.finalExams`)}
                  link={FinalExamRoutes.FinalExams}
                />
              )} */}
            </List>
          </SubMenuWrapper>
        </List>
      </MenuWrapper>
    </>
  );
}

export default SidebarMenu;
