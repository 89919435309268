import { useQuery } from 'react-query';
import { ITask } from 'src/models/task';
import {
  ITableParams,
  mapToApiCallParams
} from '../../../../hooks/useTableParams';
import { IApiError } from '../../../../utils/axios';
import { IPaginateData } from '../../../../utils/types';
import {
  fetchTasksCreatedByMe,
  FETCH_TASKS_CREATED_BY_ME_KEY
} from '../../utils/api';

const useFetchTasksCreatedByMe = (params: ITableParams, canManageManualTasks: boolean) => {
  const {
    isLoading: isFirstFetchingTasksCreatedByMe,
    error: errorFetchingTasksCreatedByMe,
    data: tasksCreatedByMeData,
    isFetching: isFetchingTasksCreatedByMe,
    isPreviousData: isPreviousTasksCreatedByMe
  } = useQuery<IPaginateData<ITask>, IApiError>(
    [FETCH_TASKS_CREATED_BY_ME_KEY, params],
    () => fetchTasksCreatedByMe(mapToApiCallParams(params)),
    {
      keepPreviousData: true,
      enabled: canManageManualTasks
    }
  );

  return {
    isFirstFetchingTasksCreatedByMe,
    errorFetchingTasksCreatedByMe,
    tasksCreatedByMeData,
    isFetchingTasksCreatedByMe,
    isPreviousTasksCreatedByMe
  };
};

export default useFetchTasksCreatedByMe;
