import { FC, useState, createContext, useEffect } from 'react';

type SidebarContext = {
  sidebarToggle: any;
  toggleSidebar: () => void;
  closeSidebar: () => void;
  sideMenuToggle: any;
  toggleSideMenu: () => void;
  closeSideMenu: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SidebarContext = createContext<SidebarContext>(
  {} as SidebarContext
);

export const SidebarProvider: FC = ({ children }) => {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };
  const closeSidebar = () => {
    setSidebarToggle(false);
  };

  const sideMenuToggleStored = localStorage.getItem('sideMenuToggle');
  const [sideMenuToggle, setSideMenuToggle] = useState(
    (sideMenuToggleStored && sideMenuToggleStored === 'false') ? false : true
  );
  const toggleSideMenu = () => {
    setSideMenuToggle(!sideMenuToggle);
  };
  const closeSideMenu = () => {
    setSideMenuToggle(false);
  };
  useEffect(() => {
    localStorage.setItem('sideMenuToggle', sideMenuToggle ? 'true' : 'false')
  }, [sideMenuToggle])

  return (
    <SidebarContext.Provider
      value={{
        sidebarToggle,
        toggleSidebar,
        closeSidebar,
        sideMenuToggle,
        toggleSideMenu,
        closeSideMenu,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
