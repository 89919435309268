import { ITableApiParams } from 'src/hooks/useTableParams';
import { IBaseModel } from './base-model';
import { ICertifier } from './certifier';

export interface IFetchJuriesParams extends ITableApiParams { }

export enum JuryType {
  President = 'president',
  Member = 'member'
}
export interface IJury extends IBaseModel {
  type: JuryType;
  jury: ICertifier;
  juryId?: string;
}

export interface IAssignJury {
  juryPresidentId: string;
  date: string | Date;
}

export interface IAssignJuryMember {
  juryMembers: any[];
}

export interface IJuryDecision {
  juryComment?: string;
  juryDecision: string;
  passedStudentBlocks?: string[];
}

/** @deprecated */
export enum JuryDecisionStatus {
  Null = '',
  FullDiploma = 'full_diploma',
  PartialDiploma = 'partial_diploma',
  NoDiploma = 'no_diploma'
}
