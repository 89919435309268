import { StudentCertificationStatus, CertificationStatus } from "src/utils/const";

const studentBlock = {
  htmlTitle: 'Blocs | {{name}}',
  pageTitle: 'Bloc étudiant',
  noDeliverables: 'Pas de livrable pour ce bloc',
  deliverable: {
    upload: 'Téléverser',
    noFiles: 'Aucun fichier'
  },
  submitDeliverables: {
    modalTitle: "Confirmer l'envoi du livrable",
    modalBody: "Confirmer l'envoi du livrable pour correction ?",
    button: 'Envoyer'
  },
  uploadEvaluation: "Téléverser l'évaluation",
  evaluation: {
    upload: 'Téléverser'
  },
  openFile: 'Ouvrir le fichier',
  submitEvaluation: {
    modalTitle: "Confirmer l'envoi de l'évaluation",
    modalBody: "Confirmer l'envoi de l'évaluation du livrable ?",
    button: 'Envoyer'
  },
  deleteFile: 'Supprimer le fichier',
  studentBlockCertificationStatsTitle: 'Statut de certification du bloc étudiant',
  studentBlockRedemptionCertificationStatsTitle: 'Statut de certification du bloc de rattrapage',
  blockCertificationStatusLabel: 'Statut du bloc',
  studentBlockAuditStatusesLabel: 'Recommandation de l’analyste',
  auditorCommentLabel: 'Commentaire de l’analyste',
  studentBlockEvaluationCertificationStatuses: {
    [CertificationStatus.Failed]: 'Non-acquis',
    [CertificationStatus.Passed]: 'Acquis',
    missing: 'Manquant',
  },
  studentBlockCertificationStatuses: {
    [CertificationStatus.Failed]: 'Non-acquis',
    [CertificationStatus.Passed]: 'Acquis',
    missing: 'Non calculé',
  },
  studentBlockAuditStatuses: {
    [CertificationStatus.Failed]: 'Non-acquis',
    [CertificationStatus.Passed]: 'Acquis',
    missing: 'Recommandation en attente',
  },
  auditor: {
    auditorRecommendationTitle: `Recommandation de l'analyste`,
    auditorRedemptionRecommendationTitle: 'Recommandation de l’auditeur de rattrapage',
    auditorRecommendationSubtitle: `Voir la recommandation de l'analyste`,
    noAuditorAssignedTitle: 'Auditeur non désigné',
    noAuditorForRedemptionAssignedTitle: 'Auditeur de rattrapage non assigné',
    noAuditorAssignedSubtitle: `Des informations supplémentaires seront affichées lorsque l'auditeur sera désigné.`,
    auditorRecommendation: `Recommandation de l'analyste`,
    auditorRecommendationComment: `Commentaire de l'analyste`,
    auditorRecommendationOptionsLabel: 'Explication de la recommandation',
    auditorRecommendationOptions: {
      [StudentCertificationStatus.FullDiploma]: 'Certification totale',
      [StudentCertificationStatus.PartialDiploma]: 'Attestation partielle',
      [StudentCertificationStatus.NoDiploma]: 'Echec de la certification',
      missing: 'Recommandation en attente',
    },
    auditorRecommendationExplanationLabel: 'Explication de la recommandation'
  },
  jury: {
    juryDecisionTitle: 'Décision du jury',
    juryRedemptionDecisionTitle: 'Décision du jury de rattrapage',
    juryDecisionSubtitle: 'Voir la décision du jury',
    noJuryAssignedTitle: 'Jury non désigné',
    noJuryForRedemptionAssignedTitle: 'Jury de rattrapage non assigné',
    noJuryAssignedSubtitle: `Des informations complémentaires seront affichées lors de l'attribution du jury.`,
    juryDecision: 'Décision du jury',
    juryDecisionComment: 'Explication de la décision',
    juryDecisionOptionsLabel: 'Options de décision',
    juryDecisionOptions: {
      [StudentCertificationStatus.FullDiploma]: 'Certification totale',
      [StudentCertificationStatus.PartialDiploma]: 'Attestation partielle',
      [StudentCertificationStatus.NoDiploma]: 'Echec de la certification',
      missing: 'Décision en attente',
    },
    juryDecisionExplanationLabel: 'Explication de la décision'
  }
};

export default studentBlock;
