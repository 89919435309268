import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  open: boolean;
  title: string;
  children: string | ReactNode;
  isConfirming?: boolean;
  onClose(): void;
  onConfirm(): void;
}

const BUTTONS_TRANSLATE_KEY = 'common.buttons';

const Confirm: React.FC<IProps> = ({
  open,
  title,
  children,
  isConfirming,
  onClose,
  onConfirm
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle style={{ fontSize: 18 }}>{title}</DialogTitle>
      <DialogContent sx={{ width: 400 }}>
        <DialogContentText>{children}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ paddingBottom: 2 }}>
        <Button
          color="secondary"
          size="small"
          variant="outlined"
          onClick={onClose}
        >
          {t(`${BUTTONS_TRANSLATE_KEY}.cancel`)}
        </Button>
        <LoadingButton
          loading={isConfirming}
          sx={{
            mr: 2
          }}
          variant="contained"
          size="small"
          onClick={onConfirm}
        >
          {t(`${BUTTONS_TRANSLATE_KEY}.confirm`)}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default Confirm;
