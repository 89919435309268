import { useQuery } from 'react-query';
import { ICohort } from 'src/models/cohort';
import { IApiError } from '../../../../utils/axios';
import { fetchCohort, FETCH_COHORT_KEY } from '../../utils/api';

const useFetchCohort = (id: string) => {
  const {
    isLoading: isFetchingCohort,
    error: errorFetchingCohort,
    data: cohort
  } = useQuery<ICohort, IApiError>([FETCH_COHORT_KEY, id], () =>
    fetchCohort(id)
  );

  return {
    isFetchingCohort,
    errorFetchingCohort,
    cohort
  };
};

export default useFetchCohort;
