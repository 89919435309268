import {
  Grid,
  Paper,
  Typography,
  Divider,
  CardContent,
  IconButton
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import { Mode } from 'src/utils/types';
import { useState } from 'react';
import useUpdateSchoolEmployeePersonalInfo from 'src/features/profile/hooks/api/useUpdateSchoolEmployeePersonalInfo';
import SchoolEmployeePersonalInfoDetails from './SchoolEmployeePersonalInfoDetails';
import EditIcon from '@mui/icons-material/Edit';
import { IUser } from 'src/models/user';
import useAuth from 'src/features/auth/hooks/useAuth';
import { useQueryClient } from 'react-query';
import { FETCH_SCHOOL_EMPLOYEE_KEY } from 'src/features/profile/utils/api';

const PROFILE_PERSONAL_INFO_TRANSLATE_KEY =
  'profile.professionalInfo.professionalInfoSection';

interface IProps {
  user: IUser;
  url: string;
  storeCvUrl: string;
  isProfile: boolean;
  canManage: boolean;
}

const SchoolEmployeePersonalInfoSection: React.FC<IProps> = ({
  user,
  url,
  storeCvUrl,
  isProfile,
  canManage
}) => {
  const { t } = useTranslation();
  const [personalInfoLocalMode, setPersonalInfoLocalMode] = useState(Mode.View);
  const {
    isUpdatingSchoolEmployeePersonalInfo,
    updateSchoolEmployeePersonalInfo
  } = useUpdateSchoolEmployeePersonalInfo();

  const { updateProfile } = useAuth();
  const queryClient = useQueryClient();

  const handleEdit = (data) => {
    updateSchoolEmployeePersonalInfo(
      { data, url },
      {
        onSuccess: (data) => {
          if (isProfile) {
            updateProfile(data);
          } else {
            queryClient.invalidateQueries([FETCH_SCHOOL_EMPLOYEE_KEY]);
          }
          setPersonalInfoLocalMode(Mode.View);
        }
      }
    );
  };
  return (
    <Grid item xs={12}>
      <Grid container spacing-xs={3} sx={{ width: '100%' }}>
        <Grid item xs={12}>
          <Paper
            elevation={1}
            square={false}
            sx={{ borderRadius: '8px', padding: 0 }}
          >
            <Box
              sx={{
                padding: '24px 32px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                position: 'relative'
              }}
            >
              <Box>
                <Typography
                  variant="h4"
                  gutterBottom
                  sx={{ fontWeight: 700, fontSize: '14px' }}
                >
                  {t(`${PROFILE_PERSONAL_INFO_TRANSLATE_KEY}.title`)}
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{ fontWeight: 400, fontSize: '15px' }}
                >
                  {t(`${PROFILE_PERSONAL_INFO_TRANSLATE_KEY}.subtitle`)}
                </Typography>
              </Box>
              {canManage && (
                <IconButton
                  size="small"
                  onClick={() => setPersonalInfoLocalMode(Mode.Edit)}
                  color="primary"
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              )}
            </Box>
            <Divider />
            <CardContent sx={{ padding: '32px', paddingBottom: '24px' }}>
              <SchoolEmployeePersonalInfoDetails
                handleEdit={handleEdit}
                personalInfoLocalMode={personalInfoLocalMode}
                isUpdatingPersonalInfo={isUpdatingSchoolEmployeePersonalInfo}
                setPersonalInfoLocalMode={setPersonalInfoLocalMode}
                user={user}
                storeCvUrl={storeCvUrl}
              />
            </CardContent>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SchoolEmployeePersonalInfoSection;
