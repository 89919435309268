import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { ITask } from '../../../../models/task';
import { DateFormat, formatDate } from '../../../../utils/dates';
import { printUserName } from 'src/utils/userHelpers';
import Status from './Status';

interface IProps {
  task: ITask;
}

const TASKS_TRANSLATE_KEY = 'tasks.listAll';

const TaskDetails: React.FC<IProps> = ({ task }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography pb={1}>
        <strong>{t(`${TASKS_TRANSLATE_KEY}.table.columns.name`)}:</strong>{' '}
        {task?.name}
      </Typography>
      <Typography pb={1}>
        <strong>
          {t(`${TASKS_TRANSLATE_KEY}.table.columns.description`)}:
        </strong>{' '}
        {task.description ? task.description : '-'}
      </Typography>
      <Typography pb={1}>
        <strong>{t(`${TASKS_TRANSLATE_KEY}.table.columns.status`)}:</strong>{' '}
        <Status taskStatus={task?.status} />
      </Typography>
      <Typography pb={1}>
        <strong>{t(`${TASKS_TRANSLATE_KEY}.table.columns.dueDate`)}:</strong>{' '}
        {formatDate(task?.dueDate, DateFormat.DateAndTime)}
      </Typography>
      <Typography pb={1}>
        <strong>{t(`${TASKS_TRANSLATE_KEY}.table.columns.assignedBy`)}:</strong>{' '}
        {printUserName(task.creationUser)}
      </Typography>
      <Typography pb={1}>
        <strong>{t(`${TASKS_TRANSLATE_KEY}.table.columns.assignedTo`)}:</strong>{' '}
        {printUserName(task.user)}
      </Typography>
      <Typography>
        <strong>{t(`${TASKS_TRANSLATE_KEY}.table.columns.doneBy`)}:</strong>{' '}
        {printUserName(task.actionUser)}
      </Typography>
    </Box>
  );
};

export default TaskDetails;
