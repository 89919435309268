import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { IJobRequiredKnowledge } from 'src/models/job-required-knowledge';
import { IApiError } from '../../../../../utils/axios';
import {
  deleteJobRequiredKnowledge as deleteJobRequiredKnowledgeApi,
  DELETE_JOB_REQUIRED_KNOWLEDGE_KEY,
  FETCH_STUDENT_JOB_KEY
} from '../../../utils/api';

const useDeleteJobRequiredKnowledge = () => {
  const queryClient = useQueryClient();
  const [jobRequiredKnowledgeToBeDeleted, setJobRequiredKnowledgeToBeDeleted] =
    useState<IJobRequiredKnowledge>();

  const { enqueueSnackbar } = useSnackbar();

  const {
    mutate: deleteJobRequiredKnowledge,
    isLoading: isDeletingJobRequiredKnowledge
  } = useMutation(deleteJobRequiredKnowledgeApi, {
    mutationKey: DELETE_JOB_REQUIRED_KNOWLEDGE_KEY,
    onSuccess: () => {
      setJobRequiredKnowledgeToBeDeleted(null);
      queryClient.invalidateQueries([FETCH_STUDENT_JOB_KEY]);
    },
    onError: (error: IApiError) => {
      enqueueSnackbar(error.message, {
        variant: 'error'
      });
    }
  });

  return {
    jobRequiredKnowledgeToBeDeleted,
    setJobRequiredKnowledgeToBeDeleted,
    deleteJobRequiredKnowledge,
    isDeletingJobRequiredKnowledge
  };
};

export default useDeleteJobRequiredKnowledge;
