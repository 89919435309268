import { IBaseModel } from './base-model';
import { IStatusLog } from './status-logs';

export enum CourseStatus {
  Invited = 'invited',
  PendingCertifierVerification = 'pending_certifier_verification',
  RejectedByCertifier = 'rejected_by_certifier',
  Enrolled = 'enrolled'
}
export interface ICreateRejectMessageForm {
  message: string;
}
export interface ICourse extends IBaseModel {
  schoolId: string;
  cohortId: string;
  status: CourseStatus;
  statusLogs: IStatusLog[];
}
