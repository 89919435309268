import { Alert, AlertTitle, Button, styled, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import RejectMessageInfo from '../../../../cohorts/components/common/RejectMessageInfo';
import { IJob } from '../../../../../models/job';
import { JobStatuses } from 'src/utils/const';
import useAuth from 'src/features/auth/hooks/useAuth';
import { IUser, UserableType } from '../../../../../models/user';
import { studentIsOnHold } from 'src/utils/userHelpers';


interface IProps {
  job?: IJob;
  student: IUser;
  onRequestVerification(): void;
}

const JOB_DESCRIPTION_BASE_KEY = 'profile.student.job';

const EnrolledAlert = () => {
  const { t } = useTranslation();

  return (
    <Alert severity="success" sx={{ border: '1px solid #89d964' }}>
      <AlertTitle>
        <strong>
          {t(`${JOB_DESCRIPTION_BASE_KEY}.reviewAlert.successfullyVerifiedTitle`)}
        </strong>
      </AlertTitle>
      {t(`${JOB_DESCRIPTION_BASE_KEY}.reviewAlert.successfullyVerifiedText`)}
    </Alert>
  );
};

const MissingRequirementsAlert = () => {
  const { t } = useTranslation();

  return (
    <Alert severity="info" sx={{ border: '1px solid#33c2ff;' }}>
      <AlertTitle>
        <strong>
          {t(`${JOB_DESCRIPTION_BASE_KEY}.reviewAlert.missingMinimalRequirementsTitle`)}
        </strong>
      </AlertTitle>
      {t(`${JOB_DESCRIPTION_BASE_KEY}.reviewAlert.missingMinimalRequirementsText`)}
    </Alert>
  );
};

const PendingVerificationAlert = () => {
  const { t } = useTranslation();

  return (
    <Alert severity="info" sx={{ border: '1px solid#33c2ff;' }}>
      <AlertTitle>
        <strong>
          {t(`${JOB_DESCRIPTION_BASE_KEY}.reviewAlert.pendingVerificationTitle`)}
        </strong>
      </AlertTitle>
      {t(`${JOB_DESCRIPTION_BASE_KEY}.reviewAlert.pendingVerificationText`)}
    </Alert>
  );
};

const RejectedBySchoolAlert: React.FC<IProps> = ({ job, onRequestVerification, student }) => {
  const { t } = useTranslation();
  const { user } = useAuth();

  return (
    <Alert
      severity="error"
      sx={{ border: '1px solid#ff5e7c;' }}
      action={
        job && job.studentId === user.userable.id && (
          <Tooltip
            title={t(
              `${JOB_DESCRIPTION_BASE_KEY}.reviewAlert.requestVerificationButtonDisabledTooltip`
            )}
          >
            <Button
              disabled={studentIsOnHold(student)}
              sx={{
                ml: 2,
                minWidth: '190px'
              }}
              variant="contained"
              size="medium"
              onClick={onRequestVerification}
            >
              {t(`${JOB_DESCRIPTION_BASE_KEY}.reviewAlert.requestVerificationButton`)}
            </Button>
          </Tooltip>
        )
      }
    >
      <AlertTitle>
        <strong>{t(`${JOB_DESCRIPTION_BASE_KEY}.reviewAlert.rejectedTitle`)}</strong>
      </AlertTitle>
      {t(`${JOB_DESCRIPTION_BASE_KEY}.reviewAlert.rejectedText`)}
      <RejectMessageInfo statusLogs={job.statusLogs} />
    </Alert>
  );
};

const SendForVerificationAlert: React.FC<IProps> = ({
  job,
  onRequestVerification,
  student
}) => {
  const { t } = useTranslation();
  const { user } = useAuth();

  return (
    <StyledAlertWithAction
      sx={{ border: '1px solid#33c2ff;' }}
      severity="info"
      action={
        job && job.studentId === user.userable.id && (
          <Tooltip
            title={t(
              `${JOB_DESCRIPTION_BASE_KEY}.reviewAlert.requestVerificationButtonDisabledTooltip`
            )}
          >
            <Button
              disabled={studentIsOnHold(student)}
              sx={{
                ml: 2
              }}
              variant="contained"
              size="medium"
              onClick={onRequestVerification}
            >
              {t(`${JOB_DESCRIPTION_BASE_KEY}.reviewAlert.requestVerificationButton`)}
            </Button>
          </Tooltip>
        )
      }
    >
      <AlertTitle>
        <strong>
          {t(`${JOB_DESCRIPTION_BASE_KEY}.reviewAlert.requestVerificationTitle`)}
        </strong>
      </AlertTitle>
      {t(`${JOB_DESCRIPTION_BASE_KEY}.reviewAlert.requestVerificationText`)}
    </StyledAlertWithAction>
  );
};

export const StyledAlertWithAction = styled(Alert)`
  .MuiAlert-action {
    align-items: center;
  }
`;

const RequestReview: React.FC<IProps> = ({
  job,
  onRequestVerification,
  student
}) => {

  if (job.status === JobStatuses.VerifiedBySchool) {
    return <EnrolledAlert />;
  }

  if (job.status === JobStatuses.SubmittedByStudent) {
    return <PendingVerificationAlert />;
  }

  if ((job?.jobMissions?.length ?? 0) < 1) {
    return <MissingRequirementsAlert />;
  }

  if (job.status === JobStatuses.NotVerified) {
    return (
      <SendForVerificationAlert
        job={job}
        onRequestVerification={onRequestVerification}
        student={student}
      />
    );
  }

  if (job.status === JobStatuses.Rejected) {
    return (
      <RejectedBySchoolAlert
        job={job}
        onRequestVerification={onRequestVerification}
        student={student}
      />
    );
  }

  return null;
};

export default RequestReview;
