import { IAddress } from '../../../models/address';
import { ISchool } from '../../../models/school';
import { ISchoolEmployee } from '../../../models/school-employee';
import { IUser } from '../../../models/user';
import { axiosInt } from '../../../utils/axios';
import { IPaginateData } from '../../../utils/types';
import { downloadApiFileFromResponse } from 'src/utils/apiFileDownload';
import {
  ICreateEmployee,
  ICreateSchoolForm,
  IFetchSchoolParams
} from './types';

export const FETCH_SCHOOLS_KEY = 'fetchSchools';
export const fetchSchools = async (
  params: IFetchSchoolParams
): Promise<IPaginateData<ISchool>> => {
  const response = await axiosInt.get('/schools', {
    params
  });
  return response.data;
};

export const FETCH_ALL_SCHOOLS_KEY = 'fetchAllSchools';
export const fetchAllSchools = async (): Promise<ISchool[]> => {
  const response = await axiosInt.get(`/all-schools`);
  return response.data;
};

export const FETCH_SCHOOL_KEY = 'fetchSchool';
export const fetchSchool = async (id: string): Promise<ISchool> => {
  const response = await axiosInt.get(`/schools/${id}`);
  return response.data;
};

export const CREATE_SCHOOL_KEY = 'createSchool';
export const createSchool = async (data: ICreateSchoolForm): Promise<void> => {
  const response = await axiosInt.post('/schools', data);

  return response.data;
};

export const DELETE_SCHOOL_KEY = 'deleteSchool';
export const deleteSchool = async (id: string): Promise<void> => {
  const response = await axiosInt.delete(`/schools/${id}`);

  return response.data;
};

export const EXPORT_SCHOOL_LICENSES_KEY = 'exportSchoolLicenses';
export const exportSchoolLicenses = async (id: string): Promise<void> => {
  const response = await axiosInt.get(`/schools/${id}/licenses-report`, { responseType: 'blob' });
  downloadApiFileFromResponse(response);
};

export const UPDATE_SCHOOL_KEY = 'updateSchool';
export const updateSchool = async (
  schoolData: Partial<ISchool>
): Promise<ISchool> => {
  const response = await axiosInt.put(`/schools/${schoolData.id}`, schoolData);

  return response.data;
};

export const ADD_SCHOOL_ADDRESS_KEY = 'addSchoolAddress';
export const addSchoolAddress = async (
  address: Partial<IAddress>
): Promise<IAddress> => {
  const response = await axiosInt.post(
    `/schools/${address.modelId}/addresses`,
    address
  );

  return response.data;
};

export const DELETE_SCHOOL_ADDRESS_KEY = 'deleteSchoolAddress';
export const deleteSchoolAddress = async (
  address: Partial<IAddress>
): Promise<IAddress> => {
  const response = await axiosInt.delete(
    `/schools/${address.modelId}/addresses/${address.id}`
  );

  return response.data;
};

export const UPDATE_SCHOOL_ADDRESS_KEY = 'updateSchoolAddress';
export const updateSchoolAddress = async (
  address: Partial<IAddress>
): Promise<IAddress> => {
  const response = await axiosInt.put(
    `/schools/${address.modelId}/addresses/${address.id}`,
    address
  );

  return response.data;
};

export const INVITE_SCHOOL_EMPLOYEE_KEY = 'inviteSchoolEmployee';
export const inviteSchoolEmployee = async (
  employee: ICreateEmployee
): Promise<ISchoolEmployee> => {
  const response = await axiosInt.post(
    `/schools/${employee.schoolId}/employees`,
    employee
  );

  return response.data;
};

export const FETCH_SCHOOL_EMPLOYEES_KEY = 'fetchSchoolEmployees';
export const fetchSchoolEmployees = async (
  schoolId: string
): Promise<ISchoolEmployee[]> => {
  const response = await axiosInt.get(`/schools/${schoolId}/employees`);

  return response.data;
};

export const DELETE_SCHOOL_EMPLOYEE_KEY = 'deleteSchoolEmployee';
export const deleteSchoolEmployee = async (
  user: ICreateEmployee
): Promise<void> => {
  const response = await axiosInt.delete(
    `/schools/${user.schoolId}/employees/${user.id}`
  );

  return response.data;
};

export const UPDATE_SCHOOL_EMPLOYEE_KEY = 'updateSchoolEmployee';
export const updateSchoolEmployee = async (
  employee: ICreateEmployee
): Promise<IUser> => {
  const response = await axiosInt.put(
    `/schools/${employee.schoolId}/employees/${employee.id}`,
    employee
  );

  return response.data;
};

export const RESEND_INVITATIONS_SCHOOL_EMPLOYEE_KEY =
  'resendInvitationsSchoolEmployee';
export const resendInvitationsSchoolEmployee = async (
  user: ICreateEmployee
): Promise<void> => {
  const response = await axiosInt.put(
    `/schools/${user.schoolId}/employees/${user.id}/resend-invitation`
  );

  return response.data;
};
