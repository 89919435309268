import { IBaseModel } from './base-model';
import { IPermission } from './permission';

export enum EntityType {
  SchoolEmployee = `App\\Models\\SchoolEmployee`,
  CertifierEmployee = `App\\Models\\CertifierEmployee`,
  CompanyEmployee = `App\\Models\\CompanyEmployee`,
  Student = `App\\Models\\Student`,
  All = 'all'
}

export interface IRole extends IBaseModel {
  name: string;
  guardName: string;
  entityType: EntityType;
  permissions: IPermission[];
}

export enum RoleName {
  JuryPresident = 'Jury President',
  JuryMember = 'Jury Member',
  Student = 'Student',
  CompanyHr = 'Company HR',
  Tutor = 'Tutor',
  CompanyAdmin = 'Company Admin',
  SchoolCorrector = 'School Corrector',
  SchoolReferent = 'School Referent',
  SchoolAdmin = 'School Admin',
  CertifierCorrector = 'Certifier Corrector',
  CertifierAdmin = 'Certifier Admin',
  OrganisationAdmin = 'Organisation Admin',
  CertifierAuditor = 'Certifier Auditor',
  DoubleCorrecteur = 'Double Correcteur'
}
