import { useQuery } from 'react-query';
import { IEvaluation } from 'src/models/evaluation';
import { IApiError } from 'src/utils/axios';
import {
  fetchStudentEvaluations,
  FETCH_STUDENT_EVALUATIONS_KEY
} from '../../utils/api';

const useFetchStudentEvaluations = (studentId: string) => {
  const {
    isLoading: isFetchingStudentEvaluations,
    error: errorFetchingStudentEvaluations,
    data: studentEvaluations,
    refetch: refetch
  } = useQuery<IEvaluation[], IApiError>(
    [FETCH_STUDENT_EVALUATIONS_KEY, studentId],
    () => fetchStudentEvaluations(studentId)
  );

  return {
    isFetchingStudentEvaluations,
    errorFetchingStudentEvaluations,
    studentEvaluations,
    refetch
  };
};

export default useFetchStudentEvaluations;
