import { useSearchParams } from 'react-router-dom';
import { Mode } from '../utils/types';

const useMode = (defaultMode: Mode, replaceUrl?: boolean) => {
  const [searchParams, setSearchParams] = useSearchParams({
    mode: defaultMode
  });

  const mode = searchParams.get('mode') as Mode;

  const handleSetMode = (newMode: Mode) => {
    const current = {};
    searchParams.forEach((value, key) => {
      current[key] = value;
    });
    setSearchParams(
      replaceUrl ? { mode: newMode } : { ...current, mode: newMode },
      { replace: true }
    );
  };

  return {
    mode,
    setMode: handleSetMode
  };
};

export default useMode;
