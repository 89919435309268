import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ApiError from 'src/components/ApiError';
import Confirm from 'src/components/Confirm';
import { Tabs } from 'src/features/profile/pages/Student';
import { StudentRoutes } from 'src/features/students/routes';
import { IEvaluation } from 'src/models/evaluation';
import { Mode } from 'src/utils/types';
import PageLoader from '../../../components/PageLoader';
import { IUser } from '../../../models/user';
import StudentEvaluationsTable from '../components/student-evaluations/StudentEvaluationsTable';
import useDeleteStudentEvaluation from '../hooks/api/useDeleteStudentEvaluation';
import useFetchStudentEvaluations from '../hooks/api/useFetchStudentEvaluations';


const StudentEvaluations: React.FC<{
  student: IUser,
  viewingSelfEvaluations?: boolean
}> = ({
  student,
  viewingSelfEvaluations = false
}) => {

    const navigate = useNavigate();
    const { t } = useTranslation();

    const { studentEvaluations: evaluations, isFetchingStudentEvaluations, errorFetchingStudentEvaluations, refetch: refetchEvaluations } =
      useFetchStudentEvaluations(student.userableId);

    const {
      evaluationDeleted,
      setEvaluationDeleted,
      deleteEvaluation,
      isDeletingEvaluation
    } = useDeleteStudentEvaluation(student.userableId);

    const [searchParams] = useSearchParams();
    const [evaluationConfigurationFilterId, setEvaluationConfigurationFilterId] = useState(searchParams.get('evaluationConfigurationFilterId'))

    const handleOpenEvaluation = (evaluation: IEvaluation, mode: Mode) => {
      navigate(
        StudentRoutes.ViewStudentEvaluation
          .replace(':studentId', student.userableId)
          .replace(':evaluationId', evaluation.id)
        + `?mode=${mode}`
      );
    };

    const handleDeleteEvaluation = () => {
      deleteEvaluation(evaluationDeleted, {
        onSuccess: () => {
          refetchEvaluations()
        }
      });
    };

    const changeEvaluationConfigurationFilterId = (id) => {
      navigate(
        window.location.href
          .replace(window.location.origin, '')
          .replace(`&evaluationConfigurationFilterId=${evaluationConfigurationFilterId}`, id ? `&evaluationConfigurationFilterId=${id}` : '')
      )
      setEvaluationConfigurationFilterId(id)
    }


    if (isFetchingStudentEvaluations) {
      return <></>;
    }

    if (errorFetchingStudentEvaluations) {
      return <ApiError error={errorFetchingStudentEvaluations} />;
    }

    return (<>
      <StudentEvaluationsTable
        studentEvaluations={evaluations}
        student={student}
        evaluationConfigurationFilterId={evaluationConfigurationFilterId}
        viewingSelfEvaluations={viewingSelfEvaluations}
        evaluationConfigurationFilterChanged={changeEvaluationConfigurationFilterId}
        onDeleteEvaluation={setEvaluationDeleted}
        onOpenEvaluation={handleOpenEvaluation}
      />
      <Confirm
        title={t(`evaluations.listAll.delete.modalTitle`)}
        onClose={() => setEvaluationDeleted(null)}
        onConfirm={handleDeleteEvaluation}
        open={!!evaluationDeleted}
        isConfirming={isDeletingEvaluation}
      >
        {t(`evaluations.listAll.delete.modalBody`)}
      </Confirm>
    </>);
  };

export default StudentEvaluations;
