import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import { useTranslation } from 'react-i18next';
import { IStudent } from 'src/models/student';

const ACTIONS_TRANSLATE_KEY = 'acl.actions';

interface IStudentsTableActions {
    student: IStudent
    canDelete: boolean
    onDelete(): void
    canResendInvitations: boolean
    onResendInvitations(): void
    canEdit: boolean
    onEdit(): void
}

export default function StudentsTableActions({
    student,
    canDelete,
    onDelete,
    canResendInvitations,
    onResendInvitations,
    canEdit,
    onEdit
}: IStudentsTableActions) {

    const { t } = useTranslation();

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    return (
        <React.Fragment >
            <ButtonGroup variant="text" ref={anchorRef} aria-label="split button" onClick={(e) => e.stopPropagation()}            >
                <Button
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={(e) => setOpen((prevOpen) => !prevOpen)}
                >
                    <MoreHorizIcon fontSize='small' color='primary' />
                </Button>
            </ButtonGroup>
            <Popper
                style={{
                    zIndex: 10,
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                onClick={(e) => e.stopPropagation()}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem sx={{ padding: '10px' }} >
                                    <MenuItem
                                        key={'action-edit'}
                                        disabled={!canEdit}
                                        onClick={(e) => {
                                            handleClose(e);
                                            onEdit()
                                        }}
                                    >
                                        <span style={{ paddingRight: "5px" }} >{t(`${ACTIONS_TRANSLATE_KEY}.edit`)}</span>
                                        <EditIcon fontSize="small" />
                                    </MenuItem>
                                    <MenuItem
                                        key={'action-delete'}
                                        disabled={!canDelete}
                                        onClick={(e) => {
                                            handleClose(e);
                                            onDelete()
                                        }}
                                    >
                                        <span style={{ paddingRight: "5px" }} >{t(`${ACTIONS_TRANSLATE_KEY}.delete`)}</span>
                                        <DeleteIcon fontSize="small" />
                                    </MenuItem>
                                    <MenuItem
                                        key={'action-resend'}
                                        disabled={!canResendInvitations}
                                        onClick={(e) => {
                                            handleClose(e);
                                            onResendInvitations()
                                        }}
                                    >
                                        <span style={{ paddingRight: "5px" }} >{t(`${ACTIONS_TRANSLATE_KEY}.resend`)}</span>
                                        <PublishedWithChangesIcon fontSize="small" />
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
            {open && <div
                onClick={(e) => {
                    e.stopPropagation()
                    handleClose(e)
                }}
                id="close-on-click-div"
                style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 9, cursor: 'auto' }}
            />}
        </React.Fragment>
    );
}
