import { IRole, RoleName } from 'src/models/role';
import { t } from 'i18next';
import { IAssignToRole, IEvaluationConfigurationField } from 'src/models/evaluation-configuration';
import Roles from 'src/features/acl/pages/Roles';


export interface IAssignedToRoleWithIdentifier extends IAssignToRole {
    identifier: string;
    identifierTranslated: string;
}

/**
 * Attach identifier and identifierTranslated props to roles
 * 
 * @param roles 
 * @returns 
 */
export const getAssignToRolesWithIdentifiers = (roles: IAssignToRole[]): IAssignedToRoleWithIdentifier[] => {
    return roles.map((role) => {
        return {
            ...role,
            identifier: getAssignToRoleIdentifier(role),
            identifierTranslated: getAssignToRoleIdentifier(role, true)
        }
    });
}

/**
 * Get identifier for a specific role
 * 
 * @param role 
 * @param translate 
 * @returns
 */
export const getAssignToRoleIdentifier = (role: Partial<IAssignToRole>, translate: boolean = false): string => {
    if (translate) {
        return role.description?.trim() ?
            t(`acl.listAll.name.${role.name}`) + ' | ' + role.description.trim() :
            t(`acl.listAll.name.${role.name}`);
    }

    return role.description?.trim() ?
        role.name + ' | ' + role.description.trim() :
        role.name;
};

/**
 * Get role from identifier string
 * 
 * @param identifier  
 * @returns
 */
export const getRoleFromAssignToRoleIdentifier = (identifier: string): Partial<IAssignToRole> | null => {
    const roleNames = Object.values(RoleName) as string[];

    if (roleNames.includes(identifier)) {
        return { name: identifier }
    }

    if (identifier.includes(' | ')) {
        const [name, description] = identifier.split(' | ')
        if (roleNames.includes(name)) {
            return { name, description }
        }
    }

    return null;
}

/**
 * Get an array of identifiers for roles
 * 
 * @param roles 
 * @param translate 
 * @returns 
 */
export const getAssignToRoleIdentifiers = (roles: IAssignToRole[], translate: boolean = false): string[] => {
    return roles.map(role => getAssignToRoleIdentifier(role, translate))
};

/**
 * Get print string of translated identifiers for roles
 * 
 * @param roles 
 * @returns 
 */
export const printAssignToRoleIdentifiers = (roles: IAssignToRole[]) => {
    return getAssignToRoleIdentifiers(roles, true).join(', ')
}



/**
 * Order EvaluationConfigurationFields
 * 
 * @param a 
 * @param b 
 * @returns 
 */
export const orderFields = (a: IEvaluationConfigurationField, b: IEvaluationConfigurationField) => a.order - b.order;
