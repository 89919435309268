import { ITask } from 'src/models/task';
import { TaskStatuses } from 'src/utils/const';

export const buildTaskStatusText = (task?: ITask) => {
  if (task?.status === TaskStatuses.ToDo) {
    return TaskStatuses.Done;
  }
  if (task?.status === TaskStatuses.Done) {
    return 'to do';
  }
};
