import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Confirm from 'src/components/Confirm';

const DOCUMENTS_BASE_KEY = 'cohorts.assigned.documents';

interface IProps {
  open: boolean;
  isConfirming: boolean;
  documentName: string;
  onClose(): void;
  onConfirm(): void;
}

const ConfirmDeleteDocument: React.FC<IProps> = ({
  open,
  isConfirming,
  documentName,
  onClose,
  onConfirm
}) => {
  const { t } = useTranslation();
  return (
    <Confirm
      title={t(`${DOCUMENTS_BASE_KEY}.delete.title`)}
      onClose={onClose}
      onConfirm={onConfirm}
      open={open}
      isConfirming={isConfirming}
    >
      {documentName && (
        <Trans i18nKey={`${DOCUMENTS_BASE_KEY}.delete.body`}>
          Are you sure you want to delete
          <strong>{{ name: documentName }} </strong>
          from documents list?
        </Trans>
      )}
    </Confirm>
  );
};

export default ConfirmDeleteDocument;
