import { useEffect, useState } from 'react';
import {
  IDocumentUpload,
  IExtendedCourseDocument,
  ILocalDocuments,
  SchoolCourseDocumentType
} from '../../utils/types';
import groupBy from 'lodash/groupBy';

const useDocumentAction = (documents: IExtendedCourseDocument[]) => {
  const [localDocuments, setLocalDocuments] = useState<ILocalDocuments>({
    [SchoolCourseDocumentType.AnnualTimetables]: [],
    [SchoolCourseDocumentType.TrainersCvs]: [],
    [SchoolCourseDocumentType.ProofOfCommunications]: [],
    [SchoolCourseDocumentType.SubmissionsOfTheQualiopi]: []
  });

  const handleChange = (
    type: SchoolCourseDocumentType,
    files: IDocumentUpload[]
  ) => {
    setLocalDocuments((allFiles) => ({
      ...allFiles,
      [type]: files
    }));
  };

  const handleSuccessfullyDeleted = (file: IExtendedCourseDocument) => {
    setLocalDocuments((local) => ({
      ...local,
      [file.type]: local[file.type].filter((item) => item.id !== file.id)
    }));
  };

  useEffect(() => {
    if (documents?.length) {
      setLocalDocuments((local) => ({
        ...local,
        ...groupBy(documents, 'type')
      }));
    }
  }, [documents]);

  return {
    localDocuments,
    setLocalDocuments,
    handleChange,
    handleSuccessfullyDeleted
  };
};

export default useDocumentAction;
