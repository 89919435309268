import LoadingButton from '@mui/lab/LoadingButton';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Typography
} from '@mui/material';
import { Formik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ISchool } from 'src/models/school';
import { Mode } from 'src/utils/types';
import { IAddSchool } from '../../utils/types';

interface IProps {
  page: Mode;
  open: boolean;
  allSchools: ISchool[];
  schools: Partial<ISchool>[];
  isSubmitting?: boolean;
  onSubmit(value: Partial<ISchool>[]): void;
  onClose(): void;
}

const SCHOOLS_BASE_KEY = 'cohorts.common.schools';

const CreateEditSchoolModal: React.FC<IProps> = ({
  page,
  open,
  schools,
  isSubmitting,
  onClose,
  onSubmit,
  allSchools
}) => {
  const { t } = useTranslation();

  const [foundSchools, setFoundSchools] = useState(allSchools);
  const [searchTermSchool, setSearchTermSchool] = useState([]);

  const filterSchools = (e) => {
    const value = e.target.value;
    if (value !== '') {
      const results = allSchools.filter((school) => {
        return school.longName.toLowerCase().startsWith(value.toLowerCase());
      });
      setFoundSchools(results);
    } else {
      setFoundSchools(allSchools);
    }
    setSearchTermSchool(value);
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle
        sx={{
          p: 3
        }}
      >
        <Typography variant="h4" gutterBottom>
          {t(`${SCHOOLS_BASE_KEY}.modalTitle`)}
        </Typography>
        <Typography variant="subtitle2">
          {t(`${SCHOOLS_BASE_KEY}.modalSubtitle`)}
        </Typography>
      </DialogTitle>
      <Formik<IAddSchool>
        initialValues={{ schools: schools || [] }}
        onSubmit={(values) => {
          onSubmit(values.schools);
        }}
      >
        {({ handleSubmit, values, setFieldValue }) => (
          <form onSubmit={handleSubmit} noValidate>
            <DialogContent
              dividers
              sx={{
                p: 3
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label={t(`${SCHOOLS_BASE_KEY}.searchPlaceholder`)}
                    type="search"
                    value={searchTermSchool}
                    onChange={filterSchools}
                  />
                  <List
                    style={{
                      maxHeight: 200,
                      minHeight: 200,
                      overflow: 'auto',
                      width: '100%',
                      padding: '20px 0 0 0'
                    }}
                  >
                    {foundSchools.map((school, index) => (
                      <ListItem
                        key={index}
                        disabled={
                          page == Mode.View &&
                          !!schools.find((item) => item.id === school.id)
                        }
                        sx={{ mb: '20px' }}
                        secondaryAction={
                          <Checkbox
                            edge="end"
                            onChange={(
                              _: React.ChangeEvent<HTMLInputElement>,
                              checked: boolean
                            ) => {
                              if (checked) {
                                setFieldValue('schools', [
                                  ...values.schools,
                                  school
                                ]);
                              } else {
                                setFieldValue(
                                  'schools',
                                  values.schools.filter(
                                    (item) => item.id !== school.id
                                  )
                                );
                              }
                            }}
                            checked={
                              !!values.schools.find(
                                (item) => item.id === school.id
                              )
                            }
                            inputProps={{
                              'aria-labelledby': `checkbox-list-secondary-label-${school.id}`
                            }}
                            disabled={
                              page == Mode.View &&
                              !!schools.find((item) => item.id === school.id)
                            }
                            sx={{
                              backgroundColor: 'transparent'
                            }}
                          />
                        }
                        disablePadding
                      >
                        <Box
                          sx={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          <ListItemAvatar>
                            <Avatar alt={school.longName} src={school.avatar} />
                          </ListItemAvatar>
                          <ListItemText
                            id={school.id}
                            primary={school.longName}
                          />
                        </Box>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
              <DialogActions sx={{ mt: 2 }}>
                <Button
                  color="secondary"
                  size="medium"
                  variant="outlined"
                  onClick={onClose}
                >
                  {t('common.buttons.cancel')}
                </Button>
                <LoadingButton
                  variant="contained"
                  size="medium"
                  type="submit"
                  loading={isSubmitting}
                >
                  {t('common.buttons.save')}
                </LoadingButton>
              </DialogActions>
            </DialogContent>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default CreateEditSchoolModal;
