import {
  Alert,
  Stack,
  Box,
  Button,
  AlertTitle,
  Typography,
  styled
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { ITask } from '../../../../models/task';
import { IUser } from '../../../../models/user';
import TaskDeadlineAlert from '../../../tasks/components/common/TaskDeadlineAlert';
import { studentIsOnHold } from 'src/utils/userHelpers';

interface IProps {
  student: IUser;
  isTaskOverdue: boolean;
  task: ITask;
  handleOpenModal(): void;
}

const AlertContent = styled(Box)(
  () => `
  white-space: nowrap;
`
);

const STUDENT_TRANSLATE_BASE_KEY = 'profile.student';

const AssignTutorToStudentAlert: React.FC<IProps> = ({
  student,
  isTaskOverdue,
  task,
  handleOpenModal
}) => {
  const { t } = useTranslation();

  if (isTaskOverdue) {
    return (
      <Stack sx={{ mb: 2 }} spacing={2}>
        <TaskDeadlineAlert task={task} />
      </Stack>
    );
  }

  return (
    <Stack sx={{ width: '100%', mt: 1, mb: 2 }} spacing={2}>
      <Alert
        severity="info"
        sx={{
          border: '1px solid#33c2ff'
        }}
        action={
          <AlertContent
            sx={{
              display: 'flex',
              alignItems: 'center',
              height: '100%',
              mt: '-4px'
            }}
          >
            <Box>
              <Button
                disabled={studentIsOnHold(student)}
                variant="contained"
                size="medium"
                color="error"
                sx={{ minWidth: '160px' }}
                onClick={() => {
                  handleOpenModal();
                }}
              >
                {t(`${STUDENT_TRANSLATE_BASE_KEY}.assignTutor.modalButton`)}
              </Button>
            </Box>
          </AlertContent>
        }
      >
        <AlertTitle>
          <strong>
            {t(`${STUDENT_TRANSLATE_BASE_KEY}.pendingForJob.alertTitle`)}
          </strong>
        </AlertTitle>
        <Typography>
          <Trans
            i18nKey={`${STUDENT_TRANSLATE_BASE_KEY}.pendingForJob.alertSubtitle`}
          >
            Student
            <strong>
              {{ firstName: student.firstName }}
              {{ lastName: student.lastName }}
            </strong>
            is wating for a job!
          </Trans>
        </Typography>
      </Alert>
    </Stack>
  );
};

export default AssignTutorToStudentAlert;
