import { Button, MenuList, Paper, Popper } from "@mui/material";
import React, { ReactElement } from "react";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

function PopupActions({ children, renderIcon = null, disabled = false }: { children: any, renderIcon?: () => ReactElement, disabled?: boolean }) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    };
    const handleClose = (e) => {
        e.stopPropagation();
        setAnchorEl(null);
    };

    return (<>
        <Button disabled={disabled} size="small" onClick={handleOpen} >
            {renderIcon ? renderIcon() : <MoreHorizIcon fontSize='small' color='primary' />}
        </Button>
        <Popper
            open={open}
            anchorEl={anchorEl}
            style={{ zIndex: 11 }}
            onClick={handleClose}
            placement='bottom-start'
        >
            <Paper>
                <MenuList id="split-button-menu" autoFocusItem sx={{ padding: '10px' }} >
                    {children}
                </MenuList>
            </Paper>
        </Popper>
        {open && <div onClick={handleClose}
            style={{
                position: 'fixed',
                zIndex: 10,
                top: 0,
                right: 0,
                height: '100%',
                width: '100%',
                cursor: 'default'
            }}
        />}
    </>);
}

export default PopupActions;
