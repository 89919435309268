import { StudentCertificationStatus, CertificationStatus } from "src/utils/const";

const studentBlock = {
  htmlTitle: 'Blocks | {{name}}',
  pageTitle: 'Student block',
  noDeliverables: 'No deliverables for this block',
  deliverable: {
    upload: 'Upload',
    noFiles: 'No files uploaded yet'
  },
  submitDeliverables: {
    modalTitle: 'Confirm submitting deliverables',
    modalBody: 'Are you sure you want to submit this block deliverables?',
    button: 'Submit'
  },
  uploadEvaluation: 'Upload evaluation',
  evaluation: {
    upload: 'Upload'
  },
  openFile: 'Open file',
  submitEvaluation: {
    modalTitle: 'Confirm submitting evaluation',
    modalBody: 'Are you sure you want to submit this block evaluation?',
    button: 'Submit'
  },
  deleteFile: 'Delete file',
  studentBlockCertificationStatsTitle: 'Student block certification status',
  studentBlockRedemptionCertificationStatsTitle: 'Student block redemption certification status',
  blockCertificationStatusLabel: 'Certification Status',
  auditorCommentLabel: 'Auditor comment',
  studentBlockAuditStatusesLabel: 'Auditor recommendation',
  studentBlockEvaluationCertificationStatuses: {
    [CertificationStatus.Failed]: 'Failed',
    [CertificationStatus.Passed]: 'Passed',
    missing: 'Missing',
  },
  studentBlockCertificationStatuses: {
    [CertificationStatus.Failed]: 'Failed',
    [CertificationStatus.Passed]: 'Passed',
    missing: 'Not Calculated',
  },
  studentBlockAuditStatuses: {
    [CertificationStatus.Failed]: 'Failed',
    [CertificationStatus.Passed]: 'Passed',
    missing: 'Recommendation Pending',
  },
  studentBlockJuryStatuses: {
    [CertificationStatus.Failed]: 'Failed',
    [CertificationStatus.Passed]: 'Passed',
    missing: 'No decision',
  },
  auditor: {
    auditorRecommendationTitle: 'Auditor recommendation',
    auditorRedemptionRecommendationTitle: 'Auditor redemption recommendation',
    auditorRecommendationSubtitle: 'View auditor recommendation',
    noAuditorAssignedTitle: 'Auditor not assigned',
    noAuditorForRedemptionAssignedTitle: 'Auditor for redemption not assigned',
    noAuditorAssignedSubtitle: 'Additional information will be shown when auditor is assigned.',
    auditorRecommendation: 'Auditor recommendation',
    auditorRecommendationComment: 'Recommendation explanation',
    auditorRecommendationOptionsLabel: 'Recommendation options',
    auditorRecommendationOptions: {
      [StudentCertificationStatus.FullDiploma]: 'Full Diploma',
      [StudentCertificationStatus.PartialDiploma]: 'Partial Diploma',
      [StudentCertificationStatus.NoDiploma]: 'No Diploma',
      missing: 'Recommendation Pending',
    },
    auditorRecommendationExplanationLabel: 'Recommendation explanation'
  },
  jury: {
    juryDecisionTitle: 'Jury decision',
    juryRedemptionDecisionTitle: 'Jury redemption decision',
    juryDecisionSubtitle: 'View jury decision',
    noJuryAssignedTitle: 'Jury not assigned',
    noJuryForRedemptionAssignedTitle: 'Jury for redemption not assigned',
    noJuryAssignedSubtitle: 'Additional information will be shown when jury is assigned.',
    juryDecision: 'Jury Decision',
    juryDecisionComment: 'Decision explanation',
    juryDecisionOptionsLabel: 'Decision options',
    juryDecisionOptions: {
      [StudentCertificationStatus.FullDiploma]: 'Full Diploma',
      [StudentCertificationStatus.PartialDiploma]: 'Partial Diploma',
      [StudentCertificationStatus.NoDiploma]: 'No Diploma',
      missing: 'Decision Pending',
    },
    juryDecisionExplanationLabel: 'Decision explanation',
    juryPartialDiplomaSelectBlocksLabel: 'Select blocks',
    juryPartialDiplomaSelectBlocksValidationMessage: 'At least one block must be selected!'
  }

};

export default studentBlock;
