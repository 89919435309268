export const BASE_URL = process.env.REACT_APP_BASE_URL as string;

export const DEFAULT_LANGUAGE = 'en';
export const AUTH_TOKEN = 'authToken';

export enum Permissions {
  ManageCertifications = 'manage.certifications',
  OverrideCertification = 'override.certification',
  ReadCertifications = 'read.certifications',
  ManageCertifierStaff = 'manage.certifier.staff',
  ReadCertifierStaff = 'read.certifier.staff',
  ManageCohorts = 'manage.cohorts',
  ReadCohorts = 'read.cohorts',
  ReadAssignedCohorts = 'read.assigned-cohorts',
  ReadAssignedCertifications = 'read.assigned-certifications',
  ManageAssignedCohorts = 'manage.assigned-cohorts',
  ManageCompanies = 'manage.companies',
  ReadCompanies = 'read.companies',
  ReadCompany = 'read.company',
  ManageCompany = 'manage.company',
  ManageCompanyDocuments = 'manage.company.documents',
  ReadCompanyDocuments = 'read.company.documents',
  ManageCompanyStaff = 'manage.company.staff',
  ReadCompanyStaff = 'read.company.staff',
  ManageCompanyTasks = 'manage.company.tasks',
  ReadCompanyTasks = 'read.company.tasks',
  ManageLanguages = 'manage.languages',
  ManageRoles = 'manage.roles',
  ManageSchoolDocuments = 'manage.school.documents',
  ReadSchoolDocuments = 'read.school.documents',
  ReadSchool = 'read.school',
  ManageSchool = 'manage.school',
  ManageSchoolStaff = 'manage.school.staff',
  ReadSchoolStaff = 'read.school.staff',
  ManageSchoolTasks = 'manage.school.tasks',
  ReadSchoolTasks = 'read.school.tasks',
  ManageSchools = 'manage.schools',
  ReadSchools = 'read.schools',
  ManageStudentDocuments = 'manage.student.documents',
  ReadStudentDocuments = 'read.student.documents',
  ManageStudentTasks = 'manage.student.tasks',
  ReadStudentTasks = 'read.student.tasks',
  ManageStudents = 'manage.students',
  ReadStudents = 'read.students',
  ManageUsers = 'manage.users',
  ReadUsers = 'read.users',
  ManageFinalExamEvaluation = 'manage.final-exam-evaluation',
  StudentActions = 'student.actions',
  ReadJob = 'read.job',
  ManageJob = 'manage.job',
  ManageJobs = 'manage.jobs',
  ReadJury = 'read.jury',
  ManageJuryPresident = 'manage.jury-president',
  ManageJuryMember = 'manage.jury-members',
  ReadStudentBlocks = 'read.student-blocks',
  ManageStudentBlocks = 'manage.student-blocks',
  OverrideStudentBlocks = 'override.student-blocks',
  ManageManualTasks = 'manage.manual-tasks',
  ManageEvaluationVerification = 'manage.evaluation-verification',
  ManageEvaluations = 'manage.evaluations',
  OverrideEvaluation = 'override.evaluation',
  OverrideJob = 'override.job',
  ExportReports = 'export.reports'
}

export const MAP_PERMISSIONS_TYPE_TO_TEXT = {
  [Permissions.ManageCertifications]: 'Manage Certifications',
  [Permissions.ReadCertifications]: 'Read Certifications',
  [Permissions.ManageCertifierStaff]: 'Manage Certifier Staff',
  [Permissions.ReadCertifierStaff]: 'Read Certifier Staff',
  [Permissions.ManageCohorts]: 'Manage Cohorts',
  [Permissions.ReadCohorts]: 'Read Cohorts',
  [Permissions.ReadAssignedCohorts]: 'Read Assigned Cohorts',
  [Permissions.ReadAssignedCertifications]: 'Read Assigned Certifications',
  [Permissions.ManageAssignedCohorts]: 'Manage Assigned Cohorts',
  [Permissions.ManageCompanies]: 'Manage Companies',
  [Permissions.ReadCompanies]: 'Read Companies',
  [Permissions.ReadCompany]: 'Read Company',
  [Permissions.ManageCompany]: 'Manage Company',
  [Permissions.ManageCompanyDocuments]: 'Manage Company Documents',
  [Permissions.ReadCompanyDocuments]: 'Read Company Documents',
  [Permissions.ManageCompanyStaff]: 'Manage Company Staff',
  [Permissions.ReadCompanyStaff]: 'Read Company Staff',
  [Permissions.ManageCompanyTasks]: 'Manage Company Tasks',
  [Permissions.ReadCompanyTasks]: 'Read Company Tasks',
  [Permissions.ManageLanguages]: 'Manage Languages',
  [Permissions.ManageRoles]: 'Manage Roles',
  [Permissions.ManageSchoolDocuments]: 'Manage School Documents',
  [Permissions.ReadSchoolDocuments]: 'Read School Documents',
  [Permissions.ReadSchool]: 'Read School',
  [Permissions.ManageSchool]: 'Manage School',
  [Permissions.ManageSchoolStaff]: 'Manage School Staff',
  [Permissions.ReadSchoolStaff]: 'Read School Staff',
  [Permissions.ManageSchoolTasks]: 'Manage School Tasks',
  [Permissions.ReadSchoolTasks]: 'Read School Tasks',
  [Permissions.ManageSchools]: 'Manage Schools',
  [Permissions.ReadSchools]: 'Read Schools',
  [Permissions.ManageStudentDocuments]: 'Manage Student Documents',
  [Permissions.ReadStudentDocuments]: 'Read Student Documents',
  [Permissions.ManageStudentTasks]: 'Manage Student Tasks',
  [Permissions.ReadStudentTasks]: 'Read Student Tasks',
  [Permissions.ManageStudents]: 'Manage Students',
  [Permissions.ReadStudents]: 'Read Students',
  [Permissions.ManageUsers]: 'Manage Users',
  [Permissions.ReadUsers]: 'Read Users',
  [Permissions.ManageFinalExamEvaluation]: 'Evaluate Final Exam',
  [Permissions.StudentActions]: 'Student Actions',
  [Permissions.ReadJob]: 'Read Job',
  [Permissions.ManageJob]: 'Manage Job',
  [Permissions.ManageJobs]: 'Manage Jobs',
  [Permissions.ReadJury]: 'Read Jury',
  [Permissions.ManageJuryPresident]: 'Manage Jury President',
  [Permissions.ManageJuryMember]: 'Manage Jury Members',
  [Permissions.ReadStudentBlocks]: 'Read Student Blocks',
  [Permissions.OverrideStudentBlocks]: 'Override Student Blocks',
  [Permissions.ManageStudentBlocks]: 'Manage Student Blocks',
  [Permissions.ManageManualTasks]: 'Manage Manual Tasks',
  [Permissions.ManageEvaluationVerification]: 'Manage Evaluation Verification',
  [Permissions.ManageEvaluations]: 'Manage Evaluation',
  [Permissions.OverrideEvaluation]: 'Override Job',
};

export enum ProfileUpdateUrls {
  StoreCvCertifierEmployee = '/certifier-employees/profile/store-cv',
  StoreCvSchoolEmployee = '/school-employees/profile/store-cv',
  StoreCvCompanyEmployee = '/company-employees/profile/store-cv',
  SchoolEmployee = '/school-employees/profile',
  CompanyEmployee = '/company-employees/profile',
  Certifier = '/certifiers/profile',
  StudentJobUpdate = '/students/job',
  CertifierEmployee = '/certifier-employees/profile',
  StoreCvStudent = '/students/profile/store-cv',
  Student = '/student/profile',
  StudentUploadDocument = '/student/profile/documents',
  StudentDeleteDocument = '/student/profile/documents',
  StudentRequestVerification = '/student/profile/documents/request-verification',
  StudentJob = '/student/job'
}

export enum Civility {
  Mr = 'Mr',
  Mrs = 'Mrs'
}

export enum Gender {
  Male = 'male',
  Female = 'female',
  Other = 'other'
}

export enum ContractTypes {
  FirstDegreeCourse = 'first_degree_course',
  VocationalContract = 'vocational_contract',
  ApprenticeshipContract = 'apprenticeship_contract',
  AccreditationForWorkExperience = 'accreditation_for_work_experience'
}

export enum LastDiplomaLevel {
  Level_5 = 'level_5',
  Level_6 = 'level_6',
  Level_7 = 'level_7',
  Level_8 = 'level_8',
}

export enum StudentStatuses {
  Invited = 'invited',
  PendingSchoolVerification = 'pending_school_verification',
  RejectedBySchool = 'rejected_by_school',
  Verified = 'verified'
}

export enum StudentFlow {
  Regular = 'regular',
  OnHold = 'on_hold',
  Redemption = 'redemption',
}

export enum VerificationStatus {
  NotAdded = 'notAdded', /** @todo used ? */
  Draft = 'draft',
  PendingVerification = 'pending_verification',
  Verified = 'verified',
  Rejected = 'rejected'
}

export enum StudentCertificationStatus {
  FullDiploma = 'full_diploma',
  PartialDiploma = 'partial_diploma',
  NoDiploma = 'no_diploma',
}

export enum CertificationStatus {
  Passed = 'passed',
  Failed = 'failed',
}

export const MAP_COLOR_TO_USER_STATUS = {
  invited: 'warning',
  active: 'primary'
};

export enum JobStatuses {
  NotVerified = 'not_verified',
  SubmittedByStudent = 'submitted_by_student',
  VerifiedBySchool = 'verified_by_school',
  Rejected = 'rejected'
}

export enum JobMissionAutonomyLevels {
  DecideAlone = 'decide_alone',
  DecideAfterInfo = 'decide_after_info',
  DecideAfterApproval = 'decide_after_approval',
  Execute = 'execute'
}

export enum NotificationUserTypes {
  UserTaskAssignedTo = 'user_task_assigned_to',
  OtherUsers = 'other_users',
  EventMentionedUsers = 'event_mentioned_users'
}

export enum NotificationTimeTypes {
  ReminderNotifications = 'reminder_notifications',
  ImmediatelySendNotifications = 'immediately_send_notifications'
}

export enum NotificationTriggerTypes {
  Event = 'event',
  Task = 'task'
}

export enum TaskStatuses {
  Overdue = 'overdue',
  ToDo = 'to_do',
  InProgress = 'in_progress',
  Done = 'done',
  TerminatedBySystem = 'terminated_by_system'
}

export const MagicWords = {
  cohortShortName: 'cohort-short-name',
  cohortLongName: 'cohort-long-name',
  certificateLongName: 'certificate-long-name',
  certificateShortName: 'certificate-short-name',
  dueDate: 'due-date'
};

export enum Event { }

export enum TaskType {
  Automatically = 'automatically',
  Manually = 'manually'
}
