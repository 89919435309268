import React from "react";
import Popover from '@mui/material/Popover';
import { List, ListItem, Typography, MenuItem, Divider } from "@mui/material";
import { CertificationStatus } from "src/utils/const";
import Label from "src/components/Label";
import { useTranslation } from "react-i18next";
import { IStudentBlockBasicInfo } from "src/models/student-block";
import { IBlock } from "src/models/block";

const MAP_COLOR_TO_BLOCKS_STATUS = {
    [CertificationStatus.Passed]: 'success',
    [CertificationStatus.Failed]: 'error',
    missing: 'secondary'
};

const StudentBlocksPreview: React.FC<{
    studentBlocks: IStudentBlockBasicInfo[],
    blocks: IBlock[],
    resolveCertificationStatus?: (studentBlock: IStudentBlockBasicInfo) => CertificationStatus | null;
    resolveJuryDecision?: (studentBlock: IStudentBlockBasicInfo) => CertificationStatus | null;
}> = ({ blocks, studentBlocks, resolveCertificationStatus, resolveJuryDecision, children }) => {

    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    const resolveStatus: (studentBlock: IStudentBlockBasicInfo) => CertificationStatus | null =
        resolveCertificationStatus ??
        function (studentBlock) {
            return studentBlock.certificationStatus
        };

    const resolveDecision: (studentBlock: IStudentBlockBasicInfo) => CertificationStatus | null =
        resolveJuryDecision ??
        function (studentBlock) {
            return studentBlock.juryDecision
        };

    return (
        <div
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            onMouseLeave={handlePopoverClose}
            onMouseEnter={handlePopoverOpen}>
            {children}
            <Popover
                sx={{ pointerEvents: 'none', }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <List dense={true} >
                    {studentBlocks.map((studentBlock, index) => {
                        const status = resolveStatus(studentBlock)
                        const decision = resolveDecision(studentBlock)
                        return (
                            <ListItem key={index} disableGutters>
                                <MenuItem
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        marginBottom: 1,
                                        borderRadius: 1,
                                        width: 'fit-content',
                                        '& svg': {
                                            m: 1.5,
                                        },
                                        '& hr': {
                                            mx: 0.5,
                                        },
                                    }}
                                >
                                    <Typography sx={{ mr: 1 }}>{blocks.find(blk => blk.id === studentBlock.blockId).name || ''}</Typography>
                                    <Label color={MAP_COLOR_TO_BLOCKS_STATUS[status || 'missing'] as any}>
                                        {t(`studentBlock.studentBlockAuditStatuses.${status || 'missing'}`)}
                                    </Label>
                                    {decision && <>
                                        <Divider orientation="vertical" variant="middle" flexItem />
                                        <span>{t(`studentBlock.jury.juryDecisionTitle`) + ':'} </span>
                                        <Label sx={{ ml: 1 }} color={MAP_COLOR_TO_BLOCKS_STATUS[decision] as any}>
                                            {t(`studentBlock.studentBlockAuditStatuses.${decision}`)}
                                        </Label></>
                                    }
                                </MenuItem>
                            </ListItem>
                        )
                    })}
                </List >
            </Popover>
        </div>)
}

export default StudentBlocksPreview;