import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { IJobMission } from 'src/models/job-mission';
import { IApiError } from '../../../../../utils/axios';
import {
  deleteJobMission as deleteJobMissionApi,
  DELETE_JOB_MISSION_KEY,
  FETCH_STUDENT_JOB_KEY
} from '../../../utils/api';

const useDeleteJobMission = () => {
  const queryClient = useQueryClient();
  const [jobMissionToBeDeleted, setJobMissionToBeDeleted] =
    useState<IJobMission>();

  const { enqueueSnackbar } = useSnackbar();

  const { mutate: deleteJobMission, isLoading: isDeletingJobMission } =
    useMutation(deleteJobMissionApi, {
      mutationKey: DELETE_JOB_MISSION_KEY,
      onSuccess: () => {
        setJobMissionToBeDeleted(null);
        queryClient.invalidateQueries([FETCH_STUDENT_JOB_KEY]);
      },
      onError: (error: IApiError) => {
        enqueueSnackbar(error.message, {
          variant: 'error'
        });
      }
    });

  return {
    jobMissionToBeDeleted,
    setJobMissionToBeDeleted,
    deleteJobMission,
    isDeletingJobMission
  };
};

export default useDeleteJobMission;
