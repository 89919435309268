import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { ICohortTaskConfiguration } from '../../../../models/cohort-task-configuration';
import { IApiError } from '../../../../utils/axios';
import {
  triggerTask as triggerTaskApi,
  TRIGGER_TASK_KEY
} from '../../utils/api';

const TRIGGER_TASK_TRANSLATE_KEY = 'cohorts.common.tasks.trigger';

const useTriggerTask = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [triggeringTaskIds, setTriggeringTaskIds] = useState([]);

  const { mutate: triggerTask, isLoading: isTriggeringTask } = useMutation(
    triggerTaskApi,
    {
      mutationKey: [TRIGGER_TASK_KEY],
      onSuccess: () => {
        enqueueSnackbar(t(`${TRIGGER_TASK_TRANSLATE_KEY}.successMessage`), {
          variant: 'success'
        });
      },
      onError: (error: IApiError) => {
        enqueueSnackbar(error.message, {
          variant: 'error'
        });
      }
    }
  );

  const handleTriggerTask = (task: Partial<ICohortTaskConfiguration>) => {
    setTriggeringTaskIds((current) => [...current, task.id]);
    triggerTask(task, {
      onSuccess: () => resetTriggeringTaskId(task.id),
      onError: () => resetTriggeringTaskId(task.id)
    });
  };

  const resetTriggeringTaskId = (id: string) => {
    setTriggeringTaskIds((current) => current.filter((i) => i !== id));
  };

  const isTriggering = (id: string) => triggeringTaskIds.includes(id);

  return {
    triggerTask: handleTriggerTask,
    isTriggering,
    isTriggeringTask
  };
};

export default useTriggerTask;
