const companyEmployees = {
    companyEmployee: {
        title: "Employé de l'entreprise"
    },
    companyEmployees: {
        title: "Employés de l'entreprise"
    },
    schoolCompaniesEmployees: {
        title: "Employés des entreprises",
        table: {
            columns: {
                firstName: 'Prénom',
                lastName: 'Nom de famille',
                email: 'Email',
                status: 'Statut',
                company: 'Entreprise',
                createdAt: 'Créé le',
                actions: 'Actions'
            }
        }
    },
    deletePopup: {
        modalTitle: `Supprimer un employé de l'entreprise`,
        modalBody: `Confirmer la suppression de {{email}} employé de l'entreprise ?`,
        successMessage: `Confirmation de suppression employé de l'entreprise`,
        // errorMessage: `Erreur lors de la suppression employé de l'entreprise`,
    },
    resendInvitationPopup: {
        modalTitle: 'Renvoyer les invitations',
        modalBody: 'Voulez-vous vraiment renvoyer des invitation pour {{email}}?',
        successMessage: 'Renvoyer le lien des invitations envoyé avec succès!'
    }
}

export default companyEmployees;