import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { IFile } from '../../../../../models/file';
import { IApiError } from '../../../../../utils/axios';
import {
  deleteCertificateFile as deleteCertificateFileApi,
  DELETE_CERTIFICATE_FILE_KEY
} from '../../../utils/api';

const useDeleteCertificateFile = () => {
  const [certificateFileToBeDeleted, setCertificateFileToBeDeleted] =
    useState<Partial<IFile>>(null);

  const { enqueueSnackbar } = useSnackbar();

  const {
    mutate: deleteCertificateFile,
    isLoading: isDeletingCertificateFile
  } = useMutation(deleteCertificateFileApi, {
    mutationKey: DELETE_CERTIFICATE_FILE_KEY,
    onSuccess: () => {
      setCertificateFileToBeDeleted(null);
    },
    onError: (error: IApiError) => {
      enqueueSnackbar(error.message, {
        variant: 'error'
      });
    }
  });

  return {
    certificateFileToBeDeleted,
    isDeletingCertificateFile,
    setCertificateFileToBeDeleted,
    deleteCertificateFile
  };
};

export default useDeleteCertificateFile;
