import {
  Box,
  Button,
  Grid,
  IconButton,
  styled,
  Typography
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import * as Yup from 'yup';
import { Mode } from '../../../../utils/types';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import CertificateBasicInfoFields, {
  Locales
} from '../create-certificate/CertificateBasicInfoFields';
import LoadingButton from '@mui/lab/LoadingButton';
import { ICertificate } from 'src/models/certificate';
import useUpdateCertificate from '../../hooks/api/certificate/useUpdateCertificate';
import { ICreateUpdateCertificateForm } from '../../utils/types';
import CertificateInfo from '../../../../components/CertificateInfo';
import Status from '../common/Status';
import ReactQuill from 'react-quill';
import { StyledReactQuillWrapper } from 'src/features/cohorts/components/common/StyledComponents';
import useMode from '../../../../hooks/useMode';
import {
  ImagePlaceholder,
  PreviewImage,
  WrapperPreviewImage
} from 'src/components/DragAndDropAvatar';

const VALIDATION_TRANSLATE_KEY = 'common.validation';
const CERTIFICATE_VALIDATION_TRANSLATE_KEY = 'certificates.common.validation';
const FORM_BASE_KEY = 'certificates.common.certificateForm';

const StyledEditButton = styled(IconButton)(
  ({ theme }) => `
  position: absolute;
  right: 0;
  top: ${theme.spacing(2)};
  `
);

interface IProps {
  certificate: ICertificate;
  canManageCertificates: boolean;
}

interface IEditCertificateBasicInfo {
  shortName: string;
  longName: string;
  description?: string;
  yearOfCertification: number;
  isPublished: boolean;
  rncpCode: string;
  rncpLevel: string;
  rncpLevelEurope: string;
  rncpLogo: string | null;
  locale: string;
}

const CertificateBasicInfo: React.FC<IProps> = ({
  certificate,
  canManageCertificates
}) => {
  const { mode, setMode } = useMode(Mode.View);

  const { isUpdatingCertificate, updateCertificate } = useUpdateCertificate();

  const handleEdit = (data: ICreateUpdateCertificateForm) => {
    updateCertificate(
      { ...data, id: certificate.id },
      { onSuccess: () => setMode(Mode.View) }
    );
  };

  const { t } = useTranslation();

  if (mode === Mode.View) {
    return (
      <Grid container spacing={2} sx={{ position: 'relative' }}>
        <Grid item sm={12}>
          <Typography variant="h3" component="h3" sx={{ mb: 0 }}>
            {certificate.shortName}
          </Typography>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            {certificate.longName}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          {certificate.rncpLogo ? (
            <WrapperPreviewImage>
              <PreviewImage
                alt="certificate image"
                src={certificate.rncpLogo}
              />
            </WrapperPreviewImage>
          ) : (
            <ImagePlaceholder />
          )}
        </Grid>
        <Grid item xs={12} sm={6} sx={{ paddingLeft: 4 }}>
          <CertificateInfo
            property={t(`${FORM_BASE_KEY}.yearOfCertification.label`)}
            value={certificate.yearOfCertification}
          />
          <CertificateInfo
            property={t(`${FORM_BASE_KEY}.rncpCode.label`)}
            value={certificate.rncpCode}
          />
          <CertificateInfo
            property={t(`${FORM_BASE_KEY}.rncpLevel.label`)}
            value={certificate.rncpLevel}
          />
          <CertificateInfo
            property={t(`${FORM_BASE_KEY}.rncpLevelEurope.label`)}
            value={certificate.rncpLevelEurope}
          />
          <CertificateInfo
            property={t(`${FORM_BASE_KEY}.locale.label`)}
            value={certificate.locale}
          />
          <CertificateInfo property={t(`${FORM_BASE_KEY}.status.label`)}>
            <Status isPublished={certificate.isPublished} />
          </CertificateInfo>
        </Grid>
        <Grid item xs={12} sm={12} sx={{ paddingLeft: 4 }}>
          <CertificateInfo
            property={t(`${FORM_BASE_KEY}.description.label`)}
            direction="row"
          >
            <StyledReactQuillWrapper>
              <ReactQuill
                value={certificate.description}
                readOnly={true}
                theme={'bubble'}
              />
            </StyledReactQuillWrapper>
          </CertificateInfo>
        </Grid>
        {canManageCertificates && (
          <StyledEditButton
            size="small"
            onClick={() => setMode(Mode.Edit)}
            color="primary"
          >
            <EditIcon fontSize="small" />
          </StyledEditButton>
        )}
      </Grid>
    );
  }

  return (
    <Formik<IEditCertificateBasicInfo>
      initialValues={{
        shortName: certificate.shortName,
        longName: certificate.longName,
        description: certificate.description || '',
        yearOfCertification: certificate.yearOfCertification,
        isPublished: certificate.isPublished,
        rncpCode: certificate.rncpCode,
        rncpLevel: certificate.rncpLevel,
        rncpLevelEurope: certificate.rncpLevelEurope,
        rncpLogo: certificate.rncpLogo,
        locale: certificate.locale
      }}
      onSubmit={handleEdit}
      validationSchema={Yup.object().shape({
        longName: Yup.string().required(
          t(`${VALIDATION_TRANSLATE_KEY}.required`, {
            field: t(`${CERTIFICATE_VALIDATION_TRANSLATE_KEY}.longNameField`)
          })
        ),
        shortName: Yup.string().required(
          t(`${VALIDATION_TRANSLATE_KEY}.required`, {
            field: t(`${CERTIFICATE_VALIDATION_TRANSLATE_KEY}.shortNameField`)
          })
        ),
        yearOfCertification: Yup.number()
          .min(new Date().getFullYear() - 20, 'min')
          .max(new Date().getFullYear(), 'min')
          .required(
            t(`${VALIDATION_TRANSLATE_KEY}.required`, {
              field: t(
                `${CERTIFICATE_VALIDATION_TRANSLATE_KEY}.yearOfCertificationField`
              )
            })
          ),
        rncpCode: Yup.string().required(
          t(`${VALIDATION_TRANSLATE_KEY}.required`, {
            field: t(`${CERTIFICATE_VALIDATION_TRANSLATE_KEY}.rncpCodeField`)
          })
        ),
        rncpLevel: Yup.string().required(
          t(`${VALIDATION_TRANSLATE_KEY}.required`, {
            field: t(`${CERTIFICATE_VALIDATION_TRANSLATE_KEY}.rncpLevelField`)
          })
        ),
        rncpLevelEurope: Yup.string().required(
          t(`${VALIDATION_TRANSLATE_KEY}.required`, {
            field: t(
              `${CERTIFICATE_VALIDATION_TRANSLATE_KEY}.rncpLevelEuropeField`
            )
          })
        ),
        rncpLogo: Yup.string().nullable(),
        description: Yup.string(),
        isPublished: Yup.boolean(),
        locale: Yup.string().required(
          t(`${VALIDATION_TRANSLATE_KEY}.required`, {
            field: t(`${CERTIFICATE_VALIDATION_TRANSLATE_KEY}.localeField`)
          })
        )
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <CertificateBasicInfoFields />
          <Box display="flex" justifyContent="flex-end" mt={3}>
            <Button
              color="secondary"
              size="medium"
              variant="outlined"
              onClick={() => setMode(Mode.View)}
            >
              {t('common.buttons.cancel')}
            </Button>
            <LoadingButton
              loading={isUpdatingCertificate}
              sx={{
                ml: 2
              }}
              variant="contained"
              size="medium"
              type="submit"
            >
              {t('common.buttons.save')}
            </LoadingButton>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default CertificateBasicInfo;
