import { useQuery } from 'react-query';
import { ITask } from 'src/models/task';
import {
  ITableParams,
  mapToApiCallParams
} from '../../../../hooks/useTableParams';
import { IApiError } from '../../../../utils/axios';
import { IPaginateData } from '../../../../utils/types';
import { fetchTasks, FETCH_TASKS_KEY } from '../../utils/api';

const useFetchTasks = (params: ITableParams) => {
  const {
    isLoading: isFirstFetchingTasks,
    error: errorFetchingTasks,
    data: tasksData,
    isFetching: isFetchingTasks,
    isPreviousData: isPreviousTasks
  } = useQuery<IPaginateData<ITask>, IApiError>(
    [FETCH_TASKS_KEY, params],
    () => fetchTasks(mapToApiCallParams(params)),
    {
      keepPreviousData: true
    }
  );

  return {
    isFirstFetchingTasks,
    errorFetchingTasks,
    tasksData,
    isFetchingTasks,
    isPreviousTasks
  };
};

export default useFetchTasks;
