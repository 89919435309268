import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { IApiError } from '../../../../utils/axios';
import {
  updateUserRoles as updateUserRolesApi,
  UPDATE_USER_ROLES_KEY
} from '../../utils/api';

const UPDATE_USER_ROLES_TRANSLATE_KEY = 'profile.roles.update';

const useUpdateUserRoles = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: updateUserRoles, isLoading: isUpdatingUserRoles } =
    useMutation(({ data, url }: any) => updateUserRolesApi(data, url), {
      mutationKey: UPDATE_USER_ROLES_KEY,
      onSuccess: (data) => {
        enqueueSnackbar(
          t(`${UPDATE_USER_ROLES_TRANSLATE_KEY}.successMessage`),
          {
            variant: 'success'
          }
        );
      },
      onError: (error: IApiError) => {
        enqueueSnackbar(error.message, {
          variant: 'error'
        });
      }
    });

  return {
    updateUserRoles,
    isUpdatingUserRoles
  };
};

export default useUpdateUserRoles;
