import {
  Grid,
  Paper,
  Typography,
  Divider,
  CardContent,
  IconButton
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import { Mode } from 'src/utils/types';
import { useState } from 'react';
import AccountInfoDetails from './AccountInfoDetails';
import useUpdateAccountInfo from '../../hooks/api/useUpdateAccountInfo';
import { IUser, UserableType } from 'src/models/user';
import useAuth from 'src/features/auth/hooks/useAuth';
import { useQueryClient } from 'react-query';
import {
  FETCH_SCHOOL_EMPLOYEE_KEY,
  FETCH_CERTIFIER_EMPLOYEE_KEY,
  FETCH_COMPANY_EMPLOYEE_KEY,
  FETCH_STUDENT_KEY
} from '../../utils/api';
import { studentIsOnHold } from 'src/utils/userHelpers';

const PROFILE_ACCOUNT_INFO_TRANSLATE_KEY = 'profile.accountInfo';

interface IProps {
  user: IUser;
  url: string;
  isProfile: boolean;
  canManage: boolean;
  mode?: Mode;
}
const AccountInfoSection: React.FC<IProps> = ({
  user,
  url,
  isProfile,
  canManage,
  mode
}) => {
  const { t } = useTranslation();
  const [accountDetailsLocalMode, setAccountDetailsLocalMode] = useState(
    mode ?? Mode.View
  );
  const { isUpdatingAccountInfo, updateAccountInfo } = useUpdateAccountInfo();

  const { updateProfile } = useAuth();
  const queryClient = useQueryClient();

  const getFetchingKey = () => {
    switch (user.userableType) {
      case UserableType.CertifierEmployee:
        return FETCH_CERTIFIER_EMPLOYEE_KEY;
      case UserableType.SchoolEmployee:
        return FETCH_SCHOOL_EMPLOYEE_KEY;
      case UserableType.CompanyEmployee:
        return FETCH_COMPANY_EMPLOYEE_KEY;
      case UserableType.Student:
        return FETCH_STUDENT_KEY;
    }
  };

  const handleEdit = (data) => {
    updateAccountInfo(
      { data, url },
      {
        onSuccess: (data: any) => {
          if (isProfile) {
            updateProfile(data);
          } else {
            const key = getFetchingKey();
            queryClient.invalidateQueries([key]);
          }
          setAccountDetailsLocalMode(Mode.View);
        }
      }
    );
  };
  return (
    <Grid container spacing-xs={3} sx={{ mb: 3 }}>
      <Grid item xs={12}>
        <Paper
          elevation={1}
          square={false}
          sx={{ borderRadius: '8px', padding: 0 }}
        >
          <Box
            sx={{
              padding: '24px 32px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              position: 'relative'
            }}
          >
            <Box>
              <Typography
                variant="h4"
                gutterBottom
                sx={{ fontWeight: 700, fontSize: '14px' }}
              >
                {t(`${PROFILE_ACCOUNT_INFO_TRANSLATE_KEY}.title`)}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{ fontWeight: 400, fontSize: '15px' }}
              >
                {t(`${PROFILE_ACCOUNT_INFO_TRANSLATE_KEY}.subtitle`)}
              </Typography>
            </Box>
            {canManage && (
              <IconButton
                disabled={user.userableType === UserableType.Student && studentIsOnHold(user)}
                size="small"
                onClick={() => setAccountDetailsLocalMode(Mode.Edit)}
                color="primary"
              >
                <EditIcon fontSize="small" />
              </IconButton>
            )}
          </Box>
          <Divider />
          <CardContent sx={{ padding: '32px', paddingBottom: '24px' }}>
            <AccountInfoDetails
              handleEdit={handleEdit}
              accountDetailsLocalMode={accountDetailsLocalMode}
              isUpdatingAccountInfo={isUpdatingAccountInfo}
              setAccountDetailsLocalMode={setAccountDetailsLocalMode}
              user={user}
            />
          </CardContent>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default AccountInfoSection;
