import { EntityType, RoleName } from 'src/models/role';
import {
  MAP_PERMISSIONS_TYPE_TO_TEXT,
  Permissions
} from '../../../utils/const';

const acl = {
  common: {
    roleForm: {
      name: {
        label: 'Nom du rôle',
        placeholder: 'Saisir le nom du rôle ici...'
      },
      entityType: {
        label: 'Entity type',
        placeholder: 'Write entity type here..'
      },
      permissions: {
        label: 'Permissions',
        placeholder: 'Sélectionner les permissions ici...'
      }
    },
    validation: {
      name: 'Nom du rôle',
      guardName: 'Nom de garde'
    }
  },
  listAll: {
    htmlTitle: 'Rôles',
    title: 'Rôles',
    subtitle: 'Afficher tous les rôles',
    addNewRole: 'Ajouter un nouveau rôle',
    table: {
      role: 'RÔLE',
      createdAt: 'CRÉÉ LE',
      entityType: 'CATÉGORIE ENTITÉ',
      permissions: 'PERMISSIONS',
      actionTooltips: {
        edit: 'Modifier',
        delete: 'Supprimer'
      }
    },
    name: {
      [RoleName.JuryPresident]: 'Président de jury',
      [RoleName.JuryMember]: 'Membre de jury',
      [RoleName.Student]: 'Etudiant',
      [RoleName.CompanyHr]: 'RH entreprise',
      [RoleName.Tutor]: 'Tuteur',
      [RoleName.CompanyAdmin]: 'Admin entreprise',
      [RoleName.SchoolCorrector]: 'Correcteur (école)',
      [RoleName.SchoolReferent]: 'Référent école',
      [RoleName.SchoolAdmin]: 'Admin école',
      [RoleName.CertifierCorrector]: 'Correcteur (certificateur)',
      [RoleName.CertifierAdmin]: 'Admin certificateur',
      [RoleName.OrganisationAdmin]: 'Super Admin',
      [RoleName.CertifierAuditor]: 'Certifier Auditor',
      evaluationAssignedTo: "L'évaluation des utilisateurs est attribuée à"
    },
    entityType: {
      [EntityType.SchoolEmployee]: `Employé de l'école`,
      [EntityType.CertifierEmployee]: 'Employé certificateur',
      [EntityType.CompanyEmployee]: 'Employé entreprise',
      [EntityType.Student]: 'Étudiant',
      [EntityType.All]: 'Tout'
    },
    permissions: {
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ManageCertifications]]:
        'Gérer les certifications',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ReadCertifications]]:
        'Lire les certifications',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ManageCertifierStaff]]:
        'Gérer le personnel de certification',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ReadCertifierStaff]]:
        'Lire le personnel du certificateur',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ManageCohorts]]:
        'Gérer les cohortes',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ReadCohorts]]:
        'Lire les cohortes',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ReadAssignedCohorts]]:
        'Lire les cohortes assignées',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ReadAssignedCertifications]]:
        'Lire les certifications attribuées',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ManageAssignedCohorts]]:
        'Gérer les cohortes assignées',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ManageCompanies]]:
        'Gérer les entreprises',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ReadCompanies]]:
        'Lire les entreprises',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ReadCompany]]:
        'Lire la société',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[
        Permissions.ManageCompany
      ]]: `Gérer l'entreprise`,
      [MAP_PERMISSIONS_TYPE_TO_TEXT[
        Permissions.ManageCompanyDocuments
      ]]: `Gérer les documents de l'entreprise`,
      [MAP_PERMISSIONS_TYPE_TO_TEXT[
        Permissions.ReadCompanyDocuments
      ]]: `Lire les documents de l'entreprise`,
      [MAP_PERMISSIONS_TYPE_TO_TEXT[
        Permissions.ManageCompanyStaff
      ]]: `Gérer le personnel de l'entreprise`,
      [MAP_PERMISSIONS_TYPE_TO_TEXT[
        Permissions.ReadCompanyStaff
      ]]: `Lire le personnel de l'entreprise`,
      [MAP_PERMISSIONS_TYPE_TO_TEXT[
        Permissions.ManageCompanyTasks
      ]]: `Gérer les tâches de l'entreprise`,
      [MAP_PERMISSIONS_TYPE_TO_TEXT[
        Permissions.ReadCompanyTasks
      ]]: `Lire les tâches de l'entreprise`,
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ManageLanguages]]:
        'Gérer les langues',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ManageRoles]]:
        'Gérer les rôles',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ManageSchoolDocuments]]:
        'Gérer les documents scolaires',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ReadSchoolDocuments]]:
        'Lire les documents scolaires',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ReadSchool]]: `Lire l'école`,
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ManageSchool]]: `Gérer l'école`,
      [MAP_PERMISSIONS_TYPE_TO_TEXT[
        Permissions.ManageSchoolStaff
      ]]: `Gérer le personnel de l'école`,
      [MAP_PERMISSIONS_TYPE_TO_TEXT[
        Permissions.ReadSchoolStaff
      ]]: `Lire le personnel de l'école`,
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ManageSchoolTasks]]:
        'Gérer les tâches scolaires',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ReadSchoolTasks]]:
        'Lire les tâches scolaires',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ManageSchools]]:
        'Gérer les écoles',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ReadSchools]]:
        'Lire les écoles',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ManageStudentDocuments]]:
        'Gérer les documents des étudiants',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ReadStudentDocuments]]:
        'Lire les documents des étudiants',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ManageStudentTasks]]:
        'Gérer les tâches des étudiants',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ReadStudentTasks]]:
        'Lire les tâches des étudiants',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ManageStudents]]:
        'Gérer les étudiants',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ReadStudents]]:
        'Lire les étudiants',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ManageUsers]]:
        'Gérer les utilisateurs',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ReadUsers]]:
        'Lire les utilisateurs',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[
        Permissions.ManageFinalExamEvaluation
      ]]: `Évaluer l'examen final`,
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.StudentActions]]:
        'Actions étudiantes',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ReadJob]]: 'Lire le travail',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ManageJob]]: 'Gérer le travail',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ManageJobs]]:
        'Gérer les travaux',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ReadJury]]: 'Lire Jury',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ManageJuryPresident]]:
        'Gérer le président du jury',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ManageJuryMember]]:
        'Gérer les membres du jury',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ReadStudentBlocks]]:
        'Lire les blocs étudiants',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ManageStudentBlocks]]:
        'Gérer les blocs étudiants',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ManageManualTasks]]:
        'Gérer les tâches manuelles',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[
        Permissions.ManageEvaluationVerification
      ]]: `Gérer la vérification de l'évaluation`
    },
    delete: {
      successMessage: 'Confirmation de suppression rôle',
      errorMessage: 'Erreur lors de la suppression rôle',
      modalTitle: 'Supprimer rôle',
      modalBody:
        'Confirmer la suppression de <1>{{name}}</1> de la liste des rôles?'
    },
    update: {
      successMessage: 'Confirmationd de mise à jour rôle',
      errorMessage: 'Erreur lors de la mise à jour du rôle',
      modalTitle: 'Mise à jour du rôle'
    },
    create: {
      successMessage: 'Mise à jour rôle réalisée',
      errorMessage: 'Erreur lors de la création du rôle',
      modalTitle: 'Créer un rôle'
    }
  },
  fileTree: {
    folderRoleChanged: 'Modification répertoire réalisée',
    fileRoleChanged: 'Modification fichier réalisée'
  },
  actions: {
    delete: 'Effacer',
    edit: 'Éditer',
    resend: 'Renvoyer les invitations'
  }
};

export default acl;
