import { Box, useTheme, Typography, Divider, Button } from '@mui/material';
import Scrollbar from 'src/components/Scrollbar';
import Timeline from '@mui/lab/Timeline';
import { useTranslation } from 'react-i18next';
import ArrowForwardTwoToneIcon from '@mui/icons-material/ArrowForwardTwoTone';

import { INotification } from 'src/models/notification';
import PageLoader from 'src/components/PageLoader';
import { TaskRoutes } from '../../tasks/routes';
import { useNavigate } from 'react-router';
import useUpdateNotification from '../hooks/api/useUpdateNotification';
import React from 'react';
import useFetchNotifications from '../hooks/api/useFetchNotifications';
import ApiError from '../../../components/ApiError';
import NotificationItem from './NotificationItem';
import useAuth from '../../auth/hooks/useAuth';
import urlBuilder from '../utils/urlBuilder';
import { NotificationUserTypes } from '../../../utils/const';
import { buildRedirectUrl } from '../../tasks/utils/taskBuilders';

interface IProps {
  hide(): void;
}

const TimelineNotifications: React.FC<IProps> = ({ hide }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const navigate = useNavigate();

  const { user } = useAuth();

  const {
    errorFetchingNotifications,
    fetchMoreNotifications,
    hasMoreNotifications,
    isFetchingNotifications,
    notificationsData
  } = useFetchNotifications();

  const { updateNotification } = useUpdateNotification();

  const handleNavigate = (notification: INotification) => {
    updateNotification({ ...notification, isRead: true });
    if (notification.taskId) {
      navigate(buildRedirectUrl(notification.task, user).href);
    } else {
      navigate(urlBuilder(notification, user).href);
    }
    hide();
  };

  if (isFetchingNotifications) {
    return (
      <Box height={200}>
        <PageLoader />
      </Box>
    );
  }

  if (errorFetchingNotifications) {
    return <ApiError error={errorFetchingNotifications} />;
  }

  return (
    <>
      <Box
        sx={{
          height: 220
        }}
      >
        <Scrollbar autoHide={false}>
          {notificationsData.pages.length > 0 && (
            <Timeline
              sx={{
                px: 2,
                py: 1,
                m: 2
              }}
            >
              {notificationsData.pages.map((pages, index) => (
                <React.Fragment key={`page-${index}`}>
                  {pages.meta.total === 0 && (
                    <Typography display="flex" justifyContent="center">
                      {t('notifications.noNotifications')}
                    </Typography>
                  )}
                  {pages.data.map((notification) => (
                    <NotificationItem
                      notification={notification}
                      onClick={handleNavigate}
                      key={notification.id}
                    />
                  ))}
                </React.Fragment>
              ))}
            </Timeline>
          )}
        </Scrollbar>
      </Box>
      {hasMoreNotifications && (
        <React.Fragment>
          <Divider />
          <Box
            sx={{
              textAlign: 'center',
              pt: 2,
              pb: 2,
              pl: 4,
              pr: 4
            }}
          >
            <Button
              onClick={() => fetchMoreNotifications()}
              variant="outlined"
              sx={{
                px: 2,
                py: 0.5,
                width: '100%',
                '.MuiSvgIcon-root': {
                  color: `${theme.colors.primary.main}`,
                  transition: `${theme.transitions.create(['color'])}`
                }
              }}
              endIcon={<ArrowForwardTwoToneIcon />}
              color="primary"
            >
              {t('notifications.seeMore')}
            </Button>
          </Box>
        </React.Fragment>
      )}
    </>
  );
};

export default TimelineNotifications;
