import { Card, styled, Typography } from '@mui/material';
import { v4 } from 'uuid';
import { useMemo } from 'react';

import Folder from './Folder';
import EmptyFileTreeMessage from './EmptyFileTreeMessage';
import { FileTreeMode, IFile, IFolder, TreeItemType } from './types';
import CreateFirstLevelFolder from './CreateFirstLevelFolder';
import { DragAndDropProvider } from 'src/contexts/DragAndDropContext';
import { moveItem, moveItemToFolder } from './utils';

const StyledHeader = styled(Typography)(
  ({ theme }) => `
  padding: ${theme.spacing(3)};
  border-bottom: 1px solid ${theme.colors.alpha.black[30]};
`
);

const StyledCard = styled(Card)(
  ({ theme }) => `
  padding-bottom: ${theme.spacing(2)};
`
);

interface IProps {
  title: string;
  mode: FileTreeMode;
  treeData: IFolder[];
  canManageTree: boolean;
  // **** This callback is used when mode is create and it is called
  // **** every time something changed in the file tree structure
  onEditTree?(newTree: IFolder[]): void;
  // ****
  // **** Those callbacks are used when mode is edit and
  // **** we call be on every item change
  onCreateFolder?(folder: IFolder): void;
  onEditFolder?(folder: IFolder): void;
  onDeleteFolder?(folder: IFolder): void;
  onFileUploaded?(file: Partial<IFile>): void;
  onDeleteFile?(file: IFile): void;
  onEditFolderRoles?(folder: IFolder): void;
  onEditFileRole?(file: IFile): void;
  // ****
  onReorderingTree?(): void;
  onCancelReorderingTree?(): void;
  onReorderTree?(): void;
  disableReordering: boolean
}

const FileTree: React.FC<IProps> = ({
  title,
  mode,
  treeData,
  canManageTree,
  onEditTree,
  onCreateFolder,
  onEditFolder,
  onDeleteFolder,
  onFileUploaded,
  onDeleteFile,
  onEditFolderRoles,
  onEditFileRole,
  onReorderingTree,
  onCancelReorderingTree,
  onReorderTree,
  disableReordering
}) => {
  const { isEdit, isCreate } = useMemo(() => {
    return {
      isEdit: mode === FileTreeMode.Edit,
      isCreate: mode === FileTreeMode.Create
    };
  }, [mode]);

  const handleChangeFolderStructure = (folder: IFolder) => {
    const mapFunction = (item: IFolder) => {
      if (folder.uuid === item.uuid) {
        return folder;
      }
      if (item.children?.length) {
        return {
          ...item,
          children: item.children.map(mapFunction)
        };
      }

      return item;
    };

    const newTree = treeData.map(mapFunction);

    onEditTree(newTree);
  };

  const handleEditFolder = (folder: IFolder) => {
    // If mode is create we call edit tree every time something changed
    if (isCreate) {
      handleChangeFolderStructure(folder);
    }
    // If mode is edit and callback exists we call callback
    if (isEdit && onEditFolder) {
      onEditFolder(folder);
    }
  };

  const handleCreateFirstLevelFolder = (name: string) => {
    const folder = {
      name,
      children: [],
      type: TreeItemType.Folder,
      uuid: v4(),
      roles: []
    };

    // If mode is create we call edit tree every time something changed
    if (isCreate) {
      onEditTree([...treeData, folder]);
    }

    // If mode is edit callback exists we call callback
    if (isEdit && onCreateFolder) {
      onCreateFolder(folder);
    }
  };

  const handleDeleteFolder = (folderToBeDeleted: IFolder) => {
    const filterFolder = (folder: IFolder) => {
      if (folder.uuid === folderToBeDeleted.uuid) {
        return false;
      }
      if (folder.children.length) {
        return (folder.children = folder.children.filter(filterFolder));
      }

      return true;
    };

    // If mode is create we call edit tree every time something changed
    if (isCreate) {
      onEditTree(treeData.filter(filterFolder));
    }

    // If callback exists we call callback
    if (isEdit && onDeleteFolder) {
      onDeleteFolder(folderToBeDeleted);
    }
  };

  const handleCreateFolder = (name: string, parentFolder: IFolder) => {
    const folder = {
      name,
      children: [],
      type: TreeItemType.Folder,
      uuid: v4(),
      roles: []
    };
    // If mode is create we call edit tree every time something changed
    if (isCreate) {
      handleChangeFolderStructure({
        ...parentFolder,
        children: [...parentFolder.children, folder]
      });
    }
    // If mode is edit and callback exists we call callback
    if (isEdit && onCreateFolder) {
      onCreateFolder({ ...folder, parentFolderId: parentFolder.id });
    }
  };

  const handleDeleteFile = (file: IFile, folder: IFolder) => {
    const fileAlreadyCreatedOnBe = !!file.id;

    // If mode is create we call edit tree every time something changed
    if (isCreate || !fileAlreadyCreatedOnBe) {
      handleChangeFolderStructure({
        ...folder,
        children: folder.children.filter((item) => item.uuid !== file.uuid)
      });
    }
    // If mode is edit and callback exists we call callback for deleting file
    if (isEdit && fileAlreadyCreatedOnBe && onDeleteFile) {
      onDeleteFile(file);
    }
  };

  const handleChangeFolderRoles = (folder: IFolder) => {
    if (isCreate) {
      // Create update locally
      handleChangeFolderStructure(folder);
    } else if (onEditFolderRoles) {
      // Edit mode update on api
      onEditFolderRoles(folder);
    }
  };

  const handleChangeFileRoles = (file: IFile, folder: IFolder) => {
    if (isCreate) {
      // Create update locally
      handleChangeFolderStructure({
        ...folder,
        children: folder.children.map((item) => {
          return item.uuid === file.uuid ? file : item;
        })
      });
    } else if (onEditFileRole) {
      // Edit mode update on api
      onEditFileRole(file);
    }
  };

  const handleDropped = (item: IFolder | IFile, droppedOn: { uuid: string, dropType: "pushToEmptyFolder" | "default" }) => {
    if (droppedOn.dropType === 'default') {
      onEditTree(moveItem(treeData, item, droppedOn.uuid));
    } else if (droppedOn.dropType === 'pushToEmptyFolder') {
      onEditTree(moveItemToFolder(treeData, item, droppedOn.uuid));
    }
  }

  return (
    <StyledCard>
      <DragAndDropProvider onDropped={handleDropped} forceDisabled={disableReordering}>
        <StyledHeader variant="h4">{title}</StyledHeader>
        {treeData.length === 0 && (
          <EmptyFileTreeMessage
            onCreateFirstFolder={handleCreateFirstLevelFolder}
            canManageTree={canManageTree}
            mode={mode}
          />
        )}
        {treeData.map((item: IFolder) => (
          <Folder
            key={item.uuid}
            folder={item as IFolder}
            mode={mode}
            onDelete={handleDeleteFolder}
            onChangeFolderStructure={handleChangeFolderStructure}
            canManageTree={canManageTree}
            onEdit={handleEditFolder}
            onCreate={handleCreateFolder}
            onFileUploaded={onFileUploaded}
            onDeleteFile={handleDeleteFile}
            onChangeFolderRoles={handleChangeFolderRoles}
            onChangeFileRoles={handleChangeFileRoles}
          />
        ))}
        {treeData.length !== 0 && (
          <CreateFirstLevelFolder
            onCreateFirstFolder={handleCreateFirstLevelFolder}
            onReorder={onReorderingTree}
            onCancelReorder={onCancelReorderingTree}
            onSaveReorder={onReorderTree}
            canManageTree={canManageTree}
            canReorderTree={!disableReordering}
            mode={mode}
          />
        )}
      </DragAndDropProvider>
    </StyledCard>
  );
};

export default FileTree;
