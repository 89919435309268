import useTableParams from 'src/hooks/useTableParams';
import PageHeader from '../components/tasks/PageHeader';
import TasksTable from '../components/tasks/TasksTable';
import useFetchTasks from '../hooks/api/useFetchTasks';
import PageLoader from 'src/components/PageLoader';
import ApiError from 'src/components/ApiError';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import useTab from 'src/hooks/useTab';
import TabList from '@mui/lab/TabList';
import { Box, Tab } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { Permissions, TaskStatuses } from '../../../utils/const';
import TasksCreatedByMeTable from '../components/tasks/TasksCreatedByMeTable';
import useFetchTasksCreatedByMe from '../hooks/api/useFetchTasksCreatedByMe';
import Confirm from 'src/components/Confirm';
import useUpdateTaskStatus from '../hooks/api/useUpdateTaskStatus';
import useToggleVisibility from 'src/hooks/useToggleVisibility';
import { ITask } from 'src/models/task';
import { buildTaskStatusText } from '../utils/buildTaskStatusText';
import useCan from 'src/hooks/useCan';

const TASKS_TRANSLATE_KEY = 'tasks.listAll';

export enum Tabs {
  Mine = 'Mine',
  CreatedByMe = 'CreatedByMe'
}

const TASKS_PER_PAGE = 20;

const Tasks = () => {
  const { t } = useTranslation();

  const { tab, setTab } = useTab(Tabs.Mine);

  const handleTabChange = (_: React.SyntheticEvent, newValue: Tabs) => {
    setTab(newValue);
  };

  const { onChangePage, onChangeSort, tableParams } = useTableParams({
    perPage: TASKS_PER_PAGE
  });

  const {
    onChangePage: onChangePageCreatedByMe,
    onChangeSort: onChangeSortCreatedByMe,
    tableParams: tableParamsCreatedByMe
  } = useTableParams({
    perPage: TASKS_PER_PAGE
  });

  const canManageManualTask = useCan(Permissions.ManageManualTasks);

  const {
    isFirstFetchingTasks,
    isFetchingTasks,
    errorFetchingTasks,
    tasksData
  } = useFetchTasks(tableParams);

  const {
    isFirstFetchingTasksCreatedByMe,
    isFetchingTasksCreatedByMe,
    errorFetchingTasksCreatedByMe,
    tasksCreatedByMeData
  } = useFetchTasksCreatedByMe(tableParamsCreatedByMe, canManageManualTask);

  const {
    isVisible: isVisibleTaskStatusModal,
    show: showConfirmTaskStatusModal,
    hide: hideConfirmTaskStatusModal
  } = useToggleVisibility(false);

  const {
    updateTaskStatus,
    isUpdatingTaskStatus,
    taskUpdateStatus,
    setTaskUpdateStatus
  } = useUpdateTaskStatus();

  const handleUpdateStatus = (task: ITask) => {
    setTaskUpdateStatus(task);
    showConfirmTaskStatusModal();
  };

  const handleUpdateStatusConfirm = () => {
    updateTaskStatus(
      taskUpdateStatus.status === TaskStatuses.ToDo
        ? TaskStatuses.Done
        : TaskStatuses.ToDo,
      {
        onSuccess: () => {
          hideConfirmTaskStatusModal();
        }
      }
    );
  };

  if (isFirstFetchingTasks || isFirstFetchingTasksCreatedByMe) {
    return <PageLoader />;
  }

  if (errorFetchingTasks || errorFetchingTasksCreatedByMe) {
    return <ApiError error={errorFetchingTasks} />;
  }

  return (
    <>
      <Helmet>
        <title>{t(`${TASKS_TRANSLATE_KEY}.title`)}</title>
      </Helmet>
      <PageHeader canCreateTaskManual={canManageManualTask} />
      <TabContext value={tab}>
        <Box sx={{ mt: 3 }}>
          {canManageManualTask && (
            <TabList onChange={handleTabChange} sx={{ mr: 1, mb: 1 }}>
              <Tab
                label={t(`${TASKS_TRANSLATE_KEY}.tab.${Tabs.Mine}`)}
                value={Tabs.Mine}
              />
              <Tab
                label={t(`${TASKS_TRANSLATE_KEY}.tab.${Tabs.CreatedByMe}`)}
                value={Tabs.CreatedByMe}
              />
            </TabList>
          )}
        </Box>
        <Box sx={{ mt: -2 }}>
          <TabPanel value={Tabs.Mine} sx={{ padding: 0 }}>
            <TasksTable
              isFetching={isFetchingTasks}
              onChangePage={onChangePage}
              onChangeSort={onChangeSort}
              page={tableParams.page}
              sort={tableParams.sort}
              perPage={tableParams.perPage}
              tasks={tasksData.data}
              total={tasksData.meta.total}
              onUpdateStatus={handleUpdateStatus}
            />
          </TabPanel>
          {canManageManualTask && (
            <TabPanel value={Tabs.CreatedByMe} sx={{ padding: 0 }}>
              <TasksCreatedByMeTable
                isFetching={isFetchingTasksCreatedByMe}
                onChangePage={onChangePageCreatedByMe}
                onChangeSort={onChangeSortCreatedByMe}
                page={tableParamsCreatedByMe.page}
                sort={tableParamsCreatedByMe.sort}
                perPage={tableParamsCreatedByMe.perPage}
                tasks={tasksCreatedByMeData.data}
                total={tasksCreatedByMeData.meta.total}
                onUpdateStatus={handleUpdateStatus}
              />
            </TabPanel>
          )}
          <Confirm
            title={'Update status'}
            onClose={hideConfirmTaskStatusModal}
            onConfirm={handleUpdateStatusConfirm}
            open={isVisibleTaskStatusModal}
            isConfirming={isUpdatingTaskStatus}
          >
            Are you sure update task status to{' '}
            <strong>{buildTaskStatusText(taskUpdateStatus)}</strong>?
          </Confirm>
        </Box>
      </TabContext>
    </>
  );
};

export default Tasks;
