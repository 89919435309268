import { UserStatus } from 'src/models/user';
import { JobMissionAutonomyLevels, JobStatuses, StudentStatuses, ContractTypes, Gender, LastDiplomaLevel } from 'src/utils/const';
import { StudentStatusExtended } from '../../students/components/common/StudentStatus';

const profile = {
  htmlTitle: 'Profile',
  title: 'Profile',
  subtitle: 'View and manage your personal data',
  professionalInfo: {
    sectionTitle: 'Personal details',
    sectionSubtitle: 'Manage personal details',
    edit: 'Edit',
    professionalInfoSection: {
      title: 'Professional details',
      subtitle: 'Manage professional details'
    },
    cv: 'CV',
    position: 'Position',
    officePhone: 'Office phone',
    viewCv: 'View CV',
    directLine: 'Direct line',
    dateOfBirth: 'Date of birth',
    placeOfBirth: 'Place of birth',
    postalCodeOfBirth: 'Postal code of birth place',
    countryOfBirth: "Country of birth",
    addressOfResidence: "Address of residence",
    placeOfResidence: "Place of residence",
    postalCodeOfResidence: "Postal code of residence",
    countryOfResidence: "Country of residence",
    lastDiploma: "Last diploma",
    levelOfLastDiploma: "Level of last diploma",
    contractType: 'Type of contract',
    group: 'Group',
    studentStatus: 'Status',
    studentStatuses: {
      [StudentStatuses.Invited]: 'Invited',
      [StudentStatuses.PendingSchoolVerification]:
        'Pending school verification',
      [StudentStatuses.RejectedBySchool]: 'Rejected',
      [StudentStatuses.Verified]: 'Verified'
    },
    contractTypeOptions: {
      [ContractTypes.FirstDegreeCourse]: 'First-degree course',
      [ContractTypes.VocationalContract]: 'Vocational contract',
      [ContractTypes.ApprenticeshipContract]: 'Apprenticeship contract',
      [ContractTypes.AccreditationForWorkExperience]: 'Accreditation for work experience or lifelong learning'
    },
    levelOfLastDiplomaOptions: {
      [LastDiplomaLevel.Level_5]: "Niveau 5",
      [LastDiplomaLevel.Level_6]: "Niveau 6",
      [LastDiplomaLevel.Level_7]: "Niveau 7",
      [LastDiplomaLevel.Level_8]: "Niveau 8"
    },
    studentStatusesExtended: {
      [StudentStatusExtended.Invited]: 'Invited',
      [StudentStatusExtended.InvitationAccepted]: 'Invitation accepted',
      [StudentStatusExtended.PendingSchoolVerification]: 'Pending verification',
      [StudentStatusExtended.RejectedBySchool]: 'Rejected',
      [StudentStatusExtended.Verified]: 'Verified'
    },
    update: {
      successMessage: 'Personal details updated successfully!'
    }
  },
  accountInfo: {
    title: 'Personal details',
    subtitle: 'Manage personal details',
    edit: 'Edit',
    firstName: 'First name',
    lastName: 'Last name',
    middleNameOne: 'Middle name one',
    middleNameTwo: 'Middle name two',
    email: 'Professional e-mail',
    secondaryEmail: 'Personal e-mail',
    update: {
      successMessage: 'Account details updated successfully!'
    },
    civility: 'Civility',
    gender: 'Gender',
    genderOptions: {
      [Gender.Male]: 'Male',
      [Gender.Female]: 'Female',
      [Gender.Other]: 'Other',
    },
    phone: 'Phone',
    roles: 'Roles'
  },
  security: {
    sectionTitle: 'Security',
    sectionSubtitle: 'Change your security preferences below',
    securitySection: {
      title: 'Change Password',
      subtitle: 'You can change your password here',
      edit: 'Change password'
    },
    update: {
      successMessage: 'Password updated successfully!'
    }
  },
  common: {
    personalInfoForm: {
      cv: {
        label: 'CV',
        placeholder: 'Click here to upload your CV',
        placeholderReupload: 'Click here to re-upload your CV',
        success: 'Successfully uploaded CV.',
        uploaded: 'You have uploaded'
      },
      position: {
        label: 'Position',
        placeholder: 'Write your position here'
      },
      officePhone: {
        label: 'Office phone',
        placeholder: 'Write your office phone here'
      },
      directLine: {
        label: 'Direct line',
        placeholder: 'Write direct line here'
      },
      dateOfBirth: {
        label: 'Date of birth',
        placeholder: 'Pick your date of birth here'
      },
      placeOfBirth: {
        label: 'Place of birth',
        placeholder: 'Write your place of birth here'
      },
      postalCodeOfBirth: {
        label: "Postal code of birth place",
        placeholder: "Write your postal code of birth place here"
      },
      countryOfBirth: {
        label: "Country of birth",
        placeholder: "Write your country of birth here"
      },
      addressOfResidence: {
        label: "Address of residence",
        placeholder: "Write your address of residence here"
      },
      placeOfResidence: {
        label: "Place of residence",
        placeholder: "Write your place of residence here"
      },
      postalCodeOfResidence: {
        label: "Postal code of residence",
        placeholder: "Write your postal code of residence here"
      },
      countryOfResidence: {
        label: "Country of residence",
        placeholder: "Write your country of residence here"
      },
      lastDiploma: {
        label: "Last diploma",
        placeholder: "Write your last diploma here"
      },
      levelOfLastDiploma: {
        label: "Level of last diploma",
        placeholder: "Write your level of last diploma here"
      },
      contractType: {
        label: 'Type of contract',
        placeholder: 'Write your type of contract here'
      },
      group: {
        label: 'Group',
        placeholder: 'Write group here'
      }
    },
    accountDetailsForm: {
      firstName: {
        label: 'First name',
        placeholder: 'Write your first name here'
      },
      lastName: {
        label: 'Last name',
        placeholder: 'Write your last name here'
      },
      middleNameOne: {
        label: 'Middle Name One',
        placeholder: 'Write your middle name here'
      },
      middleNameTwo: {
        label: 'Middle Name Two',
        placeholder: 'Write your middle name here'
      },
      civility: {
        label: 'Civility',
        placeholder: 'Write your civility here'
      },
      gender: {
        label: 'Gender',
        placeholder: 'Write your gender here'
      },
      email: {
        label: 'Email',
        placeholder: 'Write your email here'
      },
      secondaryEmail: {
        label: 'Personal e-mail',
        placeholder: 'Write your personal email here'
      },
      phone: {
        label: 'Phone',
        placeholder: '+123xxxxxxxxx or 00123xxxxxxxxx'
      },
      avatar: {
        label: 'Avatar',
        placeholder: 'Upload your avatar here'
      }
    },
    roles: {
      roles: {
        label: 'Roles',
        placeholder: 'Select roles'
      }
    },
    securityForm: {
      oldPassword: {
        label: 'Old password',
        placeholder: 'Write your old password here'
      },
      newPassword: {
        label: 'New password',
        placeholder: 'Write your new password here'
      },
      newPasswordConfirmation: {
        label: 'Confirm new password',
        placeholder: 'Confirm your new password here'
      }
    },
    studentJob: {
      name: {
        label: 'Name',
        placeholder: 'Write job name here'
      },
      departmentMission: {
        label: 'Department mission',
        placeholder: 'Write department mission here'
      },
      jobMission: {
        label: 'Job mission',
        placeholder: 'Write job mission here'
      },
      jobRequiredKnowledge: {
        label: 'Job required knowledge',
        placeholder: 'Write job required knowledge here'
      },
      jobRequiredKnowHow: {
        label: 'Job required know how',
        placeholder: 'Write job required know how here'
      },
      jobExpectedContributions: {
        label: 'Job expected contributions',
        placeholder: 'Write job expected contributions here'
      },
      delete: {
        jobMissions: {
          modalTitle: 'Delete mission',
          modalBody:
            'Are you sure you want to delete <1>{{name}}</1> from job missions?'
        },
        jobRequiredKnowledge: {
          modalTitle: 'Delete job required knowledge',
          modalBody:
            'Are you sure you want to delete <1>{{name}}</1> from job required knowledge?'
        },
        jobRequiredKnowHow: {
          modalTitle: 'Delete job required know how',
          modalBody:
            'Are you sure you want to delete <1>{{name}}</1> from job required know how?'
        },
        jobExpectedContributions: {
          modalTitle: 'Delete job expected contribution',
          modalBody:
            'Are you sure you want to delete <1>{{name}}</1> from job expected contribution?'
        }
      }
    },
    jobMissions: {
      boxTitle: 'Job missions',
      addJobMission: 'Add job mission',
      modalTitle: 'Job mission',
      modalSubtitle: 'Manage job mission',
      form: {
        jobMissionName: {
          label: 'Name',
          placeholder: 'Write mission name here'
        },
        activity: {
          label: 'Activity',
          placeholder: 'Write activity here'
        },
        autonomyLevel: {
          label: 'Autonomy level',
          placeholder: 'Write autonomy level here'
        }
      },
      noJobMissionAdded: 'No job mission added',
      autonomyLevels: {
        [JobMissionAutonomyLevels.DecideAlone]: 'Decide alone',
        [JobMissionAutonomyLevels.DecideAfterInfo]: 'Decide after info',
        [JobMissionAutonomyLevels.DecideAfterApproval]: 'Decide after approval',
        [JobMissionAutonomyLevels.Execute]: 'Execute'
      }
    },
    jobRequiredKnowledge: {
      boxTitle: 'Job required knowledge',
      addJobRequiredKnowledge: 'Add job required knowledge',
      modalTitle: 'Job required knowledge',
      modalSubtitle: 'Manage job required knowledge',
      form: {
        knowledge: {
          label: 'Knowledge',
          placeholder: 'Write required knowledge here'
        }
      },
      noJobRequiredKnowledgeAdded: 'No job required knowledge added'
    },
    jobRequiredKnowHow: {
      boxTitle: 'Job required know how',
      addJobRequiredKnowHow: 'Add job required know how',
      modalTitle: 'Job required know how',
      modalSubtitle: 'Manage job required know how',
      form: {
        knowHow: {
          label: 'Know how',
          placeholder: 'Write required know how here'
        }
      },
      noJobRequiredKnowHowAdded: 'No job required know how added'
    },
    jobExpectedContributions: {
      boxTitle: 'Job expected contribution',
      addJobRequiredKnowHow: 'Add job expected contribution',
      modalTitle: 'Job expected contribution',
      modalSubtitle: 'Manage job expected contribution',
      form: {
        contribution: {
          label: 'Contribution',
          placeholder: 'Write contribution here'
        },
        objective: {
          label: 'Objective',
          placeholder: 'Write objective here'
        }
      },
      noJobExpectedContributionsAdded: 'No job expected contributions added'
    }
  },
  schoolEmployee: {
    htmlTitle: 'School employee | {{name}}',
    title: '{{name}} - Profile',
    subtitle: 'View and manage school employee data'
  },
  companyEmployee: {
    htmlTitle: 'Company employee | {{name}}',
    title: '{{name}} - Profile',
    subtitle: 'View and manage company employee data'
  },
  certifier: {
    htmlTitle: 'Certifier | {{name}}',
    title: '{{name}} - Profile',
    subtitle: 'View and manage certifier data'
  },
  roles: {
    rolesSection: {
      title: 'User roles',
      subtitle: 'Manage user roles'
    },
    roles: 'Roles',
    update: {
      successMessage: 'Roles updated successfully!'
    }
  },
  student: {
    htmlTitle: 'Student | {{name}}',
    title: '{{name}} - Profile',
    subtitle: 'View and manage student data',
    backToList: 'Go back to all students',
    backToCertificationList: 'Go back to main Professional expertise report',
    verifyStudent: 'Verify',
    rejectStudent: 'Reject',
    modalTitle: 'Student status',
    modalBody: 'Are you sure you want to update <1>{{name}}</1> status?',
    assignTutor: {
      modalButton: 'Assign tutor',
      modalTitle: 'Assign tutor',
      successMessage: 'Tutor assigned successfully!',
      createNewNoticeTitle: "Notice",
      createNewNoticeMessage: "Job description already exists. This action will create new empty job description while the current will be frozen."
    },
    assignTutorFields: {
      companies: 'Companies',
      tutors: 'Tutors'
    },
    blocks: {
      tabTitle: 'Blocks'
    },
    evaluationConfigurations: {
      tabTitle: 'Evaluation Grids'
    },
    flow: {
      regular: 'Regular',
      regularSubtitle: 'Student is in regular flow',
      onHold: 'On Hold',
      onHoldSubtitle: 'Student is on hold',
      removeOnHold: 'Remove On Hold',
      redemption: 'Redemption',
      inRedemption: 'In Redemption',
      inRedemptionSubtitle: 'Student is in redemption'
    },
    certificationPassport: {
      tabTitle: 'Certification Passport',
      certificationPassportDropzoneTitle: "Upload certification passport here",
      noPassportUploaded: "No certification passport uploaded!",
      certificationPassportAlert: {
        waitingCertificationPassportTitle: 'Pending documents to be uploaded',
        waitingCertificationPassportText:
          'Upload certification passport and request verification',
        waitingVerificationCertificationPassportTitle: 'Pending verification',
        waitingVerificationCertificationPassportText:
          'Pending certification passport verification by school referent',
        requestVerification: 'Request verification',
        requestVerificationModalTitle: 'Request certification passport verification',
        requestVerificationModalText:
          'Are you sure you want to request verification?',
        requestedTitle: 'Pending verification',
        requestedText:
          'Certification Passport is sent for verification, you will be notified once it has been verified',
        requestToastSuccess: 'Sent for verification',
        verifyModalTitle: 'Verify certification passport',
        verifyModalDescription: 'Are you sure you want to verify the document?',
        rejectedTitle: 'Certification passport rejected',
        rejectedDescription: 'Something is wrong with certificationPassport and school referent rejected them',
        rejectModalTitle: 'Reject certification passport',
        rejectModalDescription: 'Send explanation message.'
      }
    },
    job: {
      title: 'Job description',
      subtitle: "Manage student's job description",
      name: 'Name',
      departmentMission: 'Department mission',
      jobMission: 'Job mission',
      jobRequiredKnowledge: 'Job required knowledge',
      jobRequiredKnowHow: 'Job required know how',
      jobExpectedContribution: 'Job expected contribution',
      update: {
        successMessage: 'Job updated successfully!'
      },
      modalTitle: 'Update job status',
      verify: {
        button: 'Verify'
      },
      reject: {
        button: 'Reject'
      },
      jobStatus: 'Status',
      statuses: {
        [JobStatuses.NotVerified]: 'Not verified',
        [JobStatuses.Rejected]: 'Rejected',
        [JobStatuses.SubmittedByStudent]: 'Submitted by student',
        [JobStatuses.VerifiedBySchool]: 'Verified by school'
      },
      reviewAlert: {
        requestVerificationTitle: 'Request job verification',
        requestVerificationText: 'Request job verification by school referent',
        requestVerificationButton: 'Send request',
        requestVerificationButtonDisabledTooltip: 'Send request',
        pendingVerificationTitle: 'Pending verification',
        pendingVerificationText: 'Pending job verification by school referent',
        missingMinimalRequirementsTitle: 'Fill out job description',
        missingMinimalRequirementsText: `Please fill out job description's missions at least before submitting it for verification`,
        verifyTitle: 'Job description verification',
        successfullyVerifiedTitle: 'Job description verified',
        successfullyVerifiedText: 'School referent verified job description',
        rejectedTitle: 'Reject job description',
        rejectedText:
          'Something is wrong with job description and school referent rejected them'
      },
      requestVerification: {
        title: 'Request job verification',
        body: 'Request job verification by school referent'
      },
      deadline: {
        school: {
          expiredText:
            'Your deadline for task "Verify job description" has expired.'
        },
        company: {
          expiredText:
            'Your deadline for task "Add job description" has expired.'
        }
      },
      actions: {
        createNewJob: {
          title: 'Create new job description',
          successMessage: 'Tutor assigned successfully!'
        },
        replaceTutorOnJob: {
          title: 'Replace tutor on job description',
          successMessage: 'Tutor replaced successfully!'
        },
        verifyJob: {
          title: 'Verify job description',
          noticeMessage: 'This action is irreversible, job description will be verified and frozen.'
        },
        rejectJob: {
          title: 'Reject job description',
          subtitle: 'Send explanation message.',
          forceRejectDefaultMessage: 'Job was left in draft state.',
        }
      },
      frozenJobsTable: {
        columns: {
          company: "Company",
          tutorName: "Tutor",
          tutorEmail: "Email",
          status: "Status",
          frozenAt: "Frozen at",
          createdAt: 'Created at',
        }
      }
    },
    pendingVerification: {
      alertTitle: 'Pending verification',
      alertSubtitle:
        'Student <1>{{firstName}} {{lastName}}</1> has uploaded documents and is awaiting verification!'
    },
    pendingForJob: {
      alertTitle: 'Pending for tutor assign',
      alertSubtitle:
        'Student <1>{{firstName}} {{lastName}}</1> is waiting for a job!'
    },
    uploadDocuments: {
      title: 'Documents',
      subtitle: "Manage student's documents",
      cvDropzoneTitle: 'Upload CV here',
      diplomaDropzoneTitle: 'Upload diploma here',
      cv: 'CV',
      diploma: 'Diploma',
      noCvUploaded: 'No CV uploaded!',
      noDiplomaUploaded: 'No diploma uploaded!',
      update: {
        successMessage: 'Documents saved successfully!'
      },
      notAllowed: 'Editing documents is not allowed!',
      reviewAlert: {
        rejectedTitle: 'Documents are rejected',
        rejectedText:
          'Something is wrong with documents(cv & diploma) and school admin rejected them',
        rejectedMessageReason: 'Show message from the school',
        verifiedTitle: 'Documents are verified',
        verifiedText: 'School referent verified documents'
      },
      deadline: {
        docs: {
          expiredText:
            'Your deadline for task "Upload Student Documents" has expired.'
        },
        docsVerify: {
          expiredText:
            'Your deadline for task "Verify Student Documents" has expired.'
        },
        tutor: {
          expiredText:
            'Your deadline for task "Assign tutor to the student" has expired.'
        }
      },
      documentsAlert: {
        waitingDocumentsTitle: 'Pending documents to be uploaded',
        waitingDocumentsText:
          'Upload necessary documents and request verification by school',
        waitingOrUploadDocumentsTextSchool:
          'Upload necessary documents and request verification',
        waitingDocumentsTextSchool:
          'Waiting documents to be uploaded and verified',
        requestVerification: 'Request verification',
        requestVerificationModalTitle: 'Request document verification',
        requestVerificationModalText:
          'Are you sure you want to request verification?',
        requestedTitle: 'Pending school verification',
        requestedText:
          'Documents are sent for verification, you will be notified once school verify them'
      }
    },
    evaluations: {
      tabTitle: 'Evaluation Dashboard'
    },
    finalExam: {
      title: 'Final exam',
      finalExampAlerts: {
        info: {
          title: 'Final Exam',
          description:
            'This student does not have an assigned jury president for final exam.'
        },
        success: {
          title: 'Final Exam',
          description: 'The final exam is scheduled for'
        },
        actions: {
          button: 'Assign Jury President'
        }
      },
      finalExamAlertsMember: {
        info: {
          title: 'Assign Jury Member',
          description: 'Assign jury members to this student final exam'
        },
        actions: {
          button: 'Assign Jury Member'
        }
      },
      type: {
        president: 'Jury President',
        member: 'Jury Member'
      },
      table: {
        columns: {
          name: 'Name',
          type: 'Type'
        },
        delete: {
          successMessage: 'Jury successfully deleted',
          errorMessage: 'Error while deleting jury',
          modalTitle: 'Delete jury',
          modalBody: 'Are you sure you want to delete?'
        },
        assignedJury: {
          modalTitle: 'Assigned Jury President'
        },
        assignedJuryMember: {
          modalTitle: 'Assigned Jury Member'
        }
      },
      assignedJury: {
        name: {
          label: 'Juries president'
        },
        date: {
          label: 'Date'
        },
        successMessage: 'Jury president assigned successfully!',
        errorMessage:
          'The president of the jury is not available at this time.',
        noOptions: 'No options'
      },
      assignedJuryMember: {
        name: {
          label: 'Juries members'
        },
        successMessage: 'Jury member assigned successfully!',
        noOptions: 'No options'
      },
      juriesTable: 'Juries'
    }
  },
  tutorInfo: {
    tutorInfoSection: {
      title: 'Tutor',
      subtitle: 'View assigned tutor information',
      tutorNotFound: 'Tutor not found'
    },
    firstName: 'First name',
    lastName: 'Last name',
    email: 'E-mail',
    position: 'Position',
    officePhone: 'Office phone',
    directLine: 'Direct line'
  },
  userStatus: {
    [UserStatus.Invited]: "Invited",
    [UserStatus.Active]: "Active"
  }
};

export default profile;
