import { useSnackbar } from 'notistack';

import { useMutation, useQueryClient } from 'react-query';
import { IApiError } from '../../../../utils/axios';
import {
    ASSIGN_AUDITOR_TO_STUDENTS_KEY,
    FETCH_COHORT_STUDENT_CERTIFICATIONS_KEY,
    assignAuditorToStudents as assignAuditorToStudentsApi,

    ASSIGN_AUDITOR_TO_STUDENTS_IN_REDEMPTION_KEY,
    assignAuditorToStudentsInRedemption as assignAuditorToStudentsInRedemptionApi
} from '../../utils/api';

export interface IAsignAuditor {
    cohortId: string,
    data: {
        auditorId: string,
        studentCertifications: string[]
    }
}

export const useAssignAuditorsToStudents = () => {

    const {
        mutate: assignAuditorToStudents,
        isLoading: isAssigningAuditorToStudents
    } = useMutation(({ cohortId, data }: IAsignAuditor) => assignAuditorToStudentsApi({ cohortId, data }), {
        mutationKey: ASSIGN_AUDITOR_TO_STUDENTS_KEY,
    });

    return {
        assignAuditorToStudents,
        isAssigningAuditorToStudents
    };
};

export const useAssignAuditorsToStudentsInRedemption = () => {

    const {
        mutate: assignAuditorToStudents,
        isLoading: isAssigningAuditorToStudents
    } = useMutation(({ cohortId, data }: IAsignAuditor) => assignAuditorToStudentsInRedemptionApi({ cohortId, data }), {
        mutationKey: ASSIGN_AUDITOR_TO_STUDENTS_IN_REDEMPTION_KEY,
    });

    return {
        assignAuditorToStudents,
        isAssigningAuditorToStudents
    };
};

