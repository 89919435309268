import { Stack } from '@mui/material';
import useHaveRole from 'src/hooks/useHaveRole';
import { RoleName } from 'src/models/role';
import useCan from '../../../../../../hooks/useCan';
import { IUser } from '../../../../../../models/user';
import { Permissions } from '../../../../../../utils/const';
import Confirm from 'src/components/Confirm';
import {
  Alert,
  Button,
  AlertTitle,
  Box,
  styled,
  Typography
} from '@mui/material';
import { t } from 'i18next';
import { AlertBorderSettings, AlertType } from 'src/utils/styles';
import useToggleVisibility from 'src/hooks/useToggleVisibility';
import useRequestPassportVerification from '../../../../hooks/api/certificationPassport/useRequestPassportVerification';
import usePassportVerification from 'src/features/profile/hooks/api/certificationPassport/useVerifyPassport';
import RejectConfirm from 'src/features/cohorts/components/common/RejectConfirm';
import RejectMessageInfo from 'src/features/cohorts/components/common/RejectMessageInfo';
import { studentIsInRedemption, studentIsOnHold } from 'src/utils/userHelpers';
import { VerificationStatus } from 'src/utils/const'
import { IStudentCertification } from 'src/models/student-certification';

const AlertContent = styled(Box)(
  () => `
      white-space: nowrap;
    `
);

const STUDENT_TRANSLATE_BASE_KEY = 'profile.student.certificationPassport';

interface IProps {
  user: IUser;
  certification: IStudentCertification;
  canRequest: boolean;
}

const StudentPasswordCertificationStatusAlert: React.FC<IProps> = ({
  user,
  certification,
  canRequest
}) => {

  const studentId = user.userableId;

  const isStudent = useHaveRole(RoleName.Student);
  const isOrgAdmin = useHaveRole(RoleName.OrganisationAdmin)
  // const isCertAdmin = useHaveRole(RoleName.CertifierAdmin)
  const canManageStudents = useCan(Permissions.ManageStudents);

  const isStudentOnHold = studentIsOnHold(user);
  const isStudentInRedemption = studentIsInRedemption(user);
  const passportStatus = !isStudentInRedemption ? certification.passportStatus : certification.redemptionPassportStatus;

  const { requestPassportVerification, isRequestingPassportVerification } = useRequestPassportVerification(studentId);

  const { verifyPassport, isVerifyingPassport } = usePassportVerification();

  const handleVerifyPassport = () => {
    const data = {
      'status': VerificationStatus.Verified,
      'message': ''
    }
    verifyPassport({ studentId, data });
    toggleVerifyModal();
  }

  const handleRejectPassport = (props: any) => {
    const data = {
      'status': VerificationStatus.Rejected,
      'message': props.message
    }
    verifyPassport({ studentId, data });
    toggleRejectModal();
  }

  const {
    isVisible: isVisibleRequestVerificationModal,
    toggle: toggleRequestVerificationModal
  } = useToggleVisibility(false);

  const {
    isVisible: isVisibleVerify,
    toggle: toggleVerifyModal
  } = useToggleVisibility(false);

  const {
    isVisible: isVisibleReject,
    toggle: toggleRejectModal
  } = useToggleVisibility(false);

  if (isStudent && (passportStatus === VerificationStatus.Draft || passportStatus === VerificationStatus.PendingVerification)) {
    return (
      <>
        <Alert
          severity="info"
          sx={{
            border: AlertBorderSettings[AlertType.Info],
            mb: 2
          }}
          action={
            isStudent && passportStatus === VerificationStatus.Draft && canRequest && (
              <Button
                disabled={isStudentOnHold}
                color="primary"
                variant="contained"
                sx={{ m: 1 }}
                onClick={toggleRequestVerificationModal}
              >
                {t(
                  'profile.student.certificationPassport.certificationPassportAlert.requestVerification'
                )}
              </Button>
            )
          }
        >
          <AlertTitle>
            <strong>
              {t(passportStatus === VerificationStatus.Draft ?
                'profile.student.certificationPassport.certificationPassportAlert.waitingCertificationPassportTitle' :
                'profile.student.certificationPassport.certificationPassportAlert.waitingVerificationCertificationPassportTitle'
              )}
            </strong>
          </AlertTitle>
          {t(passportStatus === VerificationStatus.Draft ?
            'profile.student.certificationPassport.certificationPassportAlert.waitingCertificationPassportText' :
            'profile.student.certificationPassport.certificationPassportAlert.waitingVerificationCertificationPassportText'
          )}
        </Alert>
        <Confirm
          title={t(
            `${STUDENT_TRANSLATE_BASE_KEY}.certificationPassportAlert.requestVerificationModalTitle`
          )}
          onClose={() => toggleRequestVerificationModal()}
          onConfirm={() => { requestPassportVerification(); toggleRequestVerificationModal() }}
          open={isVisibleRequestVerificationModal}
          isConfirming={isRequestingPassportVerification}
        >
          {t(
            `${STUDENT_TRANSLATE_BASE_KEY}.certificationPassportAlert.requestVerificationModalText`
          )}
        </Confirm>
      </>
    );
  }

  if (canManageStudents &&
    (passportStatus === VerificationStatus.PendingVerification
      ||
      passportStatus === VerificationStatus.Draft)
  ) {
    return (
      <>
        <Stack sx={{ width: '100%', mt: 1 }} spacing={2}>
          <Alert
            severity="info"
            sx={{
              border: '1px solid#33c2ff',
              mb: 2
            }}
            action={
              canManageStudents && (
                <AlertContent>
                  <Button
                    disabled={isStudentOnHold}
                    color="error"
                    variant="contained"
                    sx={{ m: 1 }}
                    onClick={toggleRejectModal}
                  >
                    {t(`profile.student.rejectStudent`)}
                  </Button>
                  <Button
                    disabled={isStudentOnHold}
                    color="primary"
                    variant="contained"
                    sx={{ m: 1 }}
                    onClick={toggleVerifyModal}
                  >
                    {t(`profile.student.verifyStudent`)}
                  </Button>
                </AlertContent>
              )
            }
          >
            <AlertTitle>
              <strong>
                {t(`profile.student.certificationPassport.certificationPassportAlert.waitingVerificationCertificationPassportTitle`)}
              </strong>
            </AlertTitle>
            <Typography>
              {t(`profile.student.certificationPassport.certificationPassportAlert.waitingVerificationCertificationPassportText`)}
            </Typography>
          </Alert>
        </Stack>
        <Confirm
          title={t(`profile.student.certificationPassport.certificationPassportAlert.verifyModalTitle`)}
          onClose={() => toggleVerifyModal()}
          onConfirm={handleVerifyPassport}
          open={isVisibleVerify}
          isConfirming={isVerifyingPassport}
        >
          {t(
            `profile.student.certificationPassport.certificationPassportAlert.verifyModalDescription`
          )}
        </Confirm >
        <RejectConfirm
          title={t('profile.student.certificationPassport.certificationPassportAlert.rejectModalTitle')}
          description={t('profile.student.certificationPassport.certificationPassportAlert.rejectModalDescription')}
          open={isVisibleReject}
          onClose={toggleRejectModal}
          isConfirming={isVerifyingPassport}
          onConfirm={handleRejectPassport}
          defaultMessage=''
        />
      </>
    );
  }

  if (passportStatus === VerificationStatus.Verified) {
    return (
      <>
        <Alert
          severity="success"
          sx={{
            border: '1px solid #89d964',
            mb: 2
          }}
          action={
            isOrgAdmin && canManageStudents && (
              <Button
                disabled={isStudentOnHold}
                color="error"
                variant="contained"
                sx={{ m: 1 }}
                onClick={toggleRejectModal}
              >
                {t(`profile.student.rejectStudent`)}
              </Button>
            )
          }
        >
          <AlertTitle>
            <strong>
              {t(
                `profile.student.uploadDocuments.reviewAlert.verifiedTitle`
              )}
            </strong>
          </AlertTitle>
          {t(
            `profile.student.uploadDocuments.reviewAlert.verifiedText`
          )}
        </Alert>
        <RejectConfirm
          title={t('profile.student.certificationPassport.certificationPassportAlert.rejectModalTitle')}
          description={t('profile.student.certificationPassport.certificationPassportAlert.rejectModalDescription')}
          open={isVisibleReject}
          onClose={toggleRejectModal}
          isConfirming={isVerifyingPassport}
          onConfirm={handleRejectPassport}
          defaultMessage=''
        />
      </>
    );
  }

  if (passportStatus === VerificationStatus.Rejected) {
    return (
      <>
        <Alert
          severity="error"
          sx={{
            border: '1px solid#ff5e7c;',
            mb: 2
          }}
          action={

            canManageStudents ? <Button
              disabled={isStudentOnHold}
              color="primary"
              variant="contained"
              sx={{ m: 1 }}
              onClick={toggleVerifyModal}
            >
              {t(`profile.student.verifyStudent`)}
            </Button> :
              isStudent && passportStatus === VerificationStatus.Rejected && canRequest &&
              <Button
                disabled={isStudentOnHold}
                color="primary"
                variant="contained"
                sx={{ m: 1 }}
                onClick={toggleRequestVerificationModal}
              >
                {t(
                  'profile.student.certificationPassport.certificationPassportAlert.requestVerification'
                )}
              </Button>
          }
        >
          <AlertTitle>
            <strong>
              {t(
                `profile.student.certificationPassport.certificationPassportAlert.rejectedTitle`
              )}
            </strong>
          </AlertTitle>
          {t(
            `profile.student.certificationPassport.certificationPassportAlert.rejectedDescription`
          )}
          <RejectMessageInfo statusLogs={certification.statusLogs} />
        </Alert>
        <Confirm
          title={t(`profile.student.certificationPassport.certificationPassportAlert.verifyModalTitle`)}
          onClose={() => toggleVerifyModal()}
          onConfirm={handleVerifyPassport}
          open={isVisibleVerify}
          isConfirming={isVerifyingPassport}
        >
          {t(
            'profile.student.certificationPassport.certificationPassportAlert.verifyModalDescription'
          )}
        </Confirm >
        <Confirm
          title={t(
            `${STUDENT_TRANSLATE_BASE_KEY}.certificationPassportAlert.requestVerificationModalTitle`
          )}
          onClose={() => toggleRequestVerificationModal()}
          onConfirm={() => { requestPassportVerification(); toggleRequestVerificationModal() }}
          open={isVisibleRequestVerificationModal}
          isConfirming={isRequestingPassportVerification}
        >
          {t(
            `${STUDENT_TRANSLATE_BASE_KEY}.certificationPassportAlert.requestVerificationModalText`
          )}
        </Confirm>
      </>
    );
  }
  return null;

};

export default StudentPasswordCertificationStatusAlert;
