import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import LoadingButton from '@mui/lab/LoadingButton';
import CSVReader, { IFileInfo } from 'react-csv-reader';
import UploadIcon from '@mui/icons-material/Upload';
import React from 'react';
import { IInviteStudent } from '../../utils/types';
import { ICertificate } from 'src/models/certificate';
import { DateFormat, formatDate } from 'src/utils/dates';

const STUDENTS_BASE_KEY = 'cohorts.students.listAll.importStudentsModal';

const PREVIEW_LIMIT = 50;

enum Columns {
  FirstName = 'first_name',
  LastName = 'last_name',
  Email = 'email',
  DateOfBirth = 'date_of_birth',
  PlaceOfBirth = 'place_of_birth',
  Group = 'group',
}

interface IProps {
  open: boolean;
  isSubmitting?: boolean;
  fileInfo: IFileInfo;
  certificate?: Partial<ICertificate>;
  data: IInviteStudent[];
  onImportStudents(data: Array<Array<any>>, fileInfo: IFileInfo): void;
  onClose(): void;
  onSubmit(data): void;
}

const ImportStudentsModal: React.FC<IProps> = ({
  open,
  isSubmitting,
  fileInfo,
  certificate,
  data,
  onImportStudents,
  onClose,
  onSubmit
}) => {
  const { t, i18n } = useTranslation();

  const papaparseOptions = {
    header: false,
    dynamicTyping: true,
    skipEmptyLines: true
  };

  const existImportStudentsData = data?.length !== 0;
  const existMoreImportStudentsData = data?.length > PREVIEW_LIMIT;

  const renderCSVFileForLanguage = () => {
    return i18n.resolvedLanguage === 'fr' ?
      'https://lcp-certification.s3.eu-west-3.amazonaws.com/example-files/ExempleCSV-Etudiant.csv' :
      'https://lcp-certification.s3.eu-west-3.amazonaws.com/example-files/ExampleCSV-Student.csv';
  };

  const formatLongTableData = (text: string, maxLength: number) => {
    return text.length > maxLength ?
      text.slice(0, maxLength - 2) + '..' :
      text;
  }

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle
        sx={{
          p: 3
        }}
      >
        <Typography variant="h4" gutterBottom>
          {t(`${STUDENTS_BASE_KEY}.modalTitle`)}
        </Typography>
        <Typography variant="subtitle2">
          {t(`${STUDENTS_BASE_KEY}.modalSubtitle`)}
        </Typography>
      </DialogTitle>
      <DialogContent
        dividers
        sx={{
          p: 3
        }}
      >
        {existImportStudentsData && (
          <Alert severity="warning" sx={{ mb: 2 }}>
            {t(`${STUDENTS_BASE_KEY}.infoMessage`, {
              numberOfStudents: data?.length
            })}
          </Alert>
        )}
        <Typography mb={2} fontStyle="italic">
          {t(`${STUDENTS_BASE_KEY}.download.text1`)}{' '}
          <Link
            href={renderCSVFileForLanguage()}
            target={'_blank'}
            fontWeight={700}
          >
            {t(`${STUDENTS_BASE_KEY}.download.file`)}
          </Link>{' '}
          {t(`${STUDENTS_BASE_KEY}.download.text2`)}
        </Typography>

        <CsvWrapper>
          {fileInfo?.name ? (
            <Typography paragraph={true} display="flex" alignItems="center">
              <UploadIcon sx={{ mr: 1 }} />
              {fileInfo?.name}
            </Typography>
          ) : (
            <Typography paragraph={true} display="flex" alignItems="center">
              <UploadIcon sx={{ mr: 1 }} />
              {t(`${STUDENTS_BASE_KEY}.uploadButton`)}
            </Typography>
          )}
          <CSVReader
            onFileLoaded={onImportStudents}
            parserOptions={papaparseOptions}
          />
        </CsvWrapper>
        <>
          <Table sx={{ mt: 2 }}>
            <TableHead>
              <TableRow>
                <TableCell key={Columns.FirstName} align="left">
                  {t(`${STUDENTS_BASE_KEY}.table.columns.firstName`)}
                </TableCell>
                <TableCell key={Columns.LastName} align="left">
                  {t(`${STUDENTS_BASE_KEY}.table.columns.lastName`)}
                </TableCell>
                <TableCell key={Columns.Email} align="left">
                  {t(`${STUDENTS_BASE_KEY}.table.columns.email`)}
                </TableCell>
                <TableCell key={Columns.DateOfBirth} align="left">
                  {t(`${STUDENTS_BASE_KEY}.table.columns.dateOfBirth`)}
                </TableCell>
                <TableCell key={Columns.PlaceOfBirth} align="left">
                  {t(`${STUDENTS_BASE_KEY}.table.columns.placeOfBirth`)}
                </TableCell>
                <TableCell key={Columns.Group} align="left">
                  {t(`${STUDENTS_BASE_KEY}.table.columns.group`)}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.slice(0, PREVIEW_LIMIT).map((student, index) => (
                <TableRow hover={false} key={'student-' + index}>
                  <TableCell align="left">{student.firstName ? formatLongTableData(student.firstName, 15) : ''}</TableCell>
                  <TableCell align="left">{student.lastName ? formatLongTableData(student.lastName, 15) : ''}</TableCell>
                  <TableCell align="left">{student.email ? formatLongTableData(student.email, 20) : ''}</TableCell>
                  <TableCell align="left">{student.dateOfBirth ? formatDate(student.dateOfBirth, DateFormat.Date) : ''}</TableCell>
                  <TableCell align="left">{student.placeOfBirth ? formatLongTableData(student.placeOfBirth, 15) : ''}</TableCell>
                  <TableCell align="left">{student.group ? formatLongTableData(student.group, 15) : ''}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {existMoreImportStudentsData && (
            <Typography
              sx={{
                textAlign: 'right',
                fontStyle: 'italic',
                mt: 1
              }}
            >
              {t(`${STUDENTS_BASE_KEY}.table.loadInfo.text1`)}{' '}
              <Box component="span" sx={{ fontWeight: 700, color: '#5569ff' }}>
                {data?.length - PREVIEW_LIMIT}
              </Box>{' '}
              {t(`${STUDENTS_BASE_KEY}.table.loadInfo.text2`)}
            </Typography>
          )}
        </>

        <DialogActions sx={{ padding: '8px 0', mt: 2 }}>
          <Button
            color="secondary"
            size="medium"
            variant="outlined"
            onClick={onClose}
          >
            {t('common.buttons.cancel')}
          </Button>
          <LoadingButton
            variant="contained"
            size="medium"
            onClick={onSubmit}
            loading={isSubmitting}
          >
            {t('common.buttons.save')}
          </LoadingButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

const CsvWrapper = styled('div')`
  position: relative;
  display: flex;
  align-items: center;
  border: 1px dashed #5569ff;
  border-radius: 10px;
  padding: 16px;
  transition: border-color 0.3s;
  &:hover {
    border-color: #ccc;
  }
  svg {
    color: #5569ff;
  }
  p {
    color: #5569ff;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 0;
  }
  .csv-reader-input {
    opacity: 0;
    input {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      &:hover {
        cursor: pointer;
      }
    }
  }
`;

export default ImportStudentsModal;
