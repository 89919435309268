import {
  IconButton,
  alpha,
  Tooltip,
  styled,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const IconButtonWrapper = styled<any>(IconButton)(
  ({ theme }) => `
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${theme.spacing(4)};
  height: ${theme.spacing(4)};
  border-radius: ${theme.general.borderRadiusLg};
  margin-right: ${theme.spacing(1)};
`
);

function MailToSupport() {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <Tooltip arrow title={t('common.layout.mailToSupport')}>
          <IconButtonWrapper
            color="secondary"
            sx={{
              background: alpha(theme.colors.secondary.dark, 0.1),
              transition: `${theme.transitions.create(['background'])}`,
              color: theme.colors.primary.main,

              '&:hover': {
                background: alpha(theme.colors.primary.main, 0.2)
              }
            }}
            as="a"
            href="mailto:support-lcp@skillogs.com"
          >
            <HelpOutlineIcon />
          </IconButtonWrapper>
      </Tooltip>
    </>
  );
}

export default MailToSupport;
