import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';

const PageLoader = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%'
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress size={64} disableShrink thickness={3} />
    </Box>
  );
};

export default PageLoader;
