import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography
} from '@mui/material';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { IInviteStudent, IStudentInviteParams } from '../../utils/types';
import { Mode } from '../../../../utils/types';
import LoadingButton from '@mui/lab/LoadingButton';

const STUDENTS_BASE_KEY = 'cohorts.common.students';
const VALIDATION_TRANSLATE_KEY = 'common.validation';

interface IProps {
  open: boolean;
  mode?: Mode;
  isSubmitting?: boolean;
  onClose(): void;
  onSubmit(value: IStudentInviteParams): void;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const InviteStudentModal: React.FC<IProps> = ({
  mode,
  open,
  isSubmitting,
  onClose,
  onSubmit
}) => {
  const { t } = useTranslation();

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle
        sx={{
          p: 3
        }}
      >
        <Typography variant="h4" gutterBottom>
          {t(`${STUDENTS_BASE_KEY}.modalTitle`)}
        </Typography>
        <Typography variant="subtitle2">
          {t(`${STUDENTS_BASE_KEY}.modalSubtitle`)}
        </Typography>
      </DialogTitle>
      <Formik<IInviteStudent>
        initialValues={{
          email: '',
          firstName: '',
          lastName: ''
        }}
        onSubmit={onSubmit}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .required(
              t(`${VALIDATION_TRANSLATE_KEY}.required`, {
                field: t(`${VALIDATION_TRANSLATE_KEY}.emailField`)
              })
            )
            .email(t(`${VALIDATION_TRANSLATE_KEY}.email`)),
          firstName: Yup.string()
            .required(
              t(`${VALIDATION_TRANSLATE_KEY}.required`, {
                field: t(`${VALIDATION_TRANSLATE_KEY}.firstNameField`)
              })
            )
            .max(
              255,
              t(`${VALIDATION_TRANSLATE_KEY}.max255Characters`, {
                field: t(`${VALIDATION_TRANSLATE_KEY}.firstNameField`)
              })
            ),
          lastName: Yup.string()
            .required(
              t(`${VALIDATION_TRANSLATE_KEY}.required`, {
                field: t(`${VALIDATION_TRANSLATE_KEY}.lastNameField`)
              })
            )
            .max(
              255,
              t(`${VALIDATION_TRANSLATE_KEY}.max255Characters`, {
                field: t(`${VALIDATION_TRANSLATE_KEY}.lastNameField`)
              })
            )
        })}
      >
        {({
          handleSubmit: handleStudentSubmit,
          handleBlur: handleStudentBlur,
          handleChange: handleStudentChange,
          touched: touchedStudent,
          errors: errorsStudent,
          values: studentValues
        }) => (
          <form noValidate onSubmit={handleStudentSubmit}>
            <DialogContent
              dividers
              sx={{
                p: 3
              }}
            >
              <TextField
                required
                error={Boolean(touchedStudent.email && errorsStudent.email)}
                fullWidth
                margin="normal"
                helperText={touchedStudent.email && errorsStudent.email}
                label={t(`${STUDENTS_BASE_KEY}.form.email.label`)}
                placeholder={t(`${STUDENTS_BASE_KEY}.form.email.placeholder`)}
                name="email"
                onBlur={handleStudentBlur}
                onChange={handleStudentChange}
                value={studentValues.email}
                variant="outlined"
                sx={{ mt: 0 }}
              />
              <TextField
                required
                error={Boolean(
                  touchedStudent.firstName && errorsStudent.firstName
                )}
                fullWidth
                margin="normal"
                helperText={touchedStudent.firstName && errorsStudent.firstName}
                label={t(`${STUDENTS_BASE_KEY}.form.firstName.label`)}
                placeholder={t(
                  `${STUDENTS_BASE_KEY}.form.firstName.placeholder`
                )}
                name="firstName"
                onBlur={handleStudentBlur}
                onChange={handleStudentChange}
                value={studentValues.firstName}
                variant="outlined"
              />
              <TextField
                required
                error={Boolean(
                  touchedStudent.lastName && errorsStudent.lastName
                )}
                fullWidth
                margin="normal"
                helperText={touchedStudent.lastName && errorsStudent.lastName}
                label={t(`${STUDENTS_BASE_KEY}.form.lastName.label`)}
                placeholder={t(
                  `${STUDENTS_BASE_KEY}.form.lastName.placeholder`
                )}
                name="lastName"
                onBlur={handleStudentBlur}
                onChange={handleStudentChange}
                value={studentValues.lastName}
                variant="outlined"
              />
              <DialogActions sx={{ padding: '8px 0' }}>
                <Button
                  color="secondary"
                  size="medium"
                  variant="outlined"
                  onClick={onClose}
                >
                  {t('common.buttons.cancel')}
                </Button>
                <LoadingButton
                  variant="contained"
                  size="medium"
                  type="submit"
                  loading={isSubmitting}
                >
                  {t('common.buttons.save')}
                </LoadingButton>
              </DialogActions>
            </DialogContent>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default InviteStudentModal;
