import { useQuery } from 'react-query';
import { IApiError } from '../../../../utils/axios';
import { ITableParams, mapToApiCallParams } from 'src/hooks/useTableParams';
import { fetchCohortStudentCertifications, FETCH_COHORT_STUDENT_CERTIFICATIONS_KEY } from '../../utils/api';
import { IStudentCertification } from 'src/models/student-certification';
import { IBlock } from 'src/models/block';
import { IPaginateData } from 'src/utils/types';


const useFetchCohortStudentCertifications = (id: string, params: ITableParams) => {
    const {
        isLoading: isFirstFetchingCohortStudentCertifications,
        error: errorFetchingCohortStudentCertifications,
        data: cohortStudentCertifications,
        isFetching: isFetchingCohortStudentCertifications,
        isPreviousData: isPreviousStudentCertifications,
        refetch: refetchStudentCertifications
    } = useQuery<IPaginateData<IStudentCertification, { blocks: IBlock[] }>, IApiError>([FETCH_COHORT_STUDENT_CERTIFICATIONS_KEY, id, params], () =>
        fetchCohortStudentCertifications(id, mapToApiCallParams(params)),
        { keepPreviousData: true }
    );

    return {
        isFirstFetchingCohortStudentCertifications,
        errorFetchingCohortStudentCertifications,
        cohortStudentCertifications,
        isFetchingCohortStudentCertifications,
        isPreviousStudentCertifications,
        refetchStudentCertifications
    };
};

export default useFetchCohortStudentCertifications;