export enum AlertType {
  Success = 'success',
  Error = 'error',
  Info = 'info'
}

export const AlertBorderSettings = {
  [AlertType.Error]: '1px solid #ff5e7c;',
  [AlertType.Success]: '1px solid #89d964;',
  [AlertType.Info]: '1px solid #33c2ff;'
};
