import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IApiError } from '../../../../utils/axios';
import { FETCH_USER_KEY } from '../../../auth/utils/api';
import {
  updateStudentDocuments as updateStudentDocumentsApi,
  UPDATE_STUDENT_DOCUMENTS_INFO_KEY
} from '../../utils/api';

const UPDATE_STUDENT_DOCUMENTS_TRANSLATE_KEY =
  'profile.student.uploadDocuments.update';

const useUpdateStudentDocuments = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { mutate: updateDocuments, isLoading: isUpdatingDocuments } =
    useMutation(({ data, url }: any) => updateStudentDocumentsApi(data, url), {
      mutationKey: UPDATE_STUDENT_DOCUMENTS_INFO_KEY,
      onSuccess: (data) => {
        queryClient.invalidateQueries(FETCH_USER_KEY);
        enqueueSnackbar(
          t(`${UPDATE_STUDENT_DOCUMENTS_TRANSLATE_KEY}.successMessage`),
          {
            variant: 'success'
          }
        );
      },
      onError: (error: IApiError) => {
        enqueueSnackbar(t(error.message), {
          variant: 'error'
        });
      }
    });

  return {
    updateDocuments,
    isUpdatingDocuments
  };
};

export default useUpdateStudentDocuments;
