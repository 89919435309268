import { IconButton, styled, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ReactNode } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import { useTranslation } from 'react-i18next';

const ACTIONS_TRANSLATE_KEY = 'acl.actions';

const FormCard = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'error'
})<{ error?: boolean }>(
  ({ theme, error }) => `
  border-radius: 2px;
  border: 1px solid ${
    error ? theme.colors.error.main : theme.colors.alpha.black[30]
  };
  margin-top: ${theme.spacing(3)};
`
);

const HeaderWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'error'
})<{ error?: boolean }>(
  ({ theme, error }) => `
  padding: ${theme.spacing(2)};
  background-color: ${
    error ? theme.colors.error.lighter : theme.colors.alpha.black[10]
  };
  color: ${error ? theme.colors.error.main : theme.colors.alpha.black[50]};
`
);

interface IProps {
  title: string;
  children: ReactNode | ReactNode[];
  error?: boolean;
}

const ArrayFormBox: React.FC<IProps> = ({ title, children, error }) => {
  return (
    <FormCard error={error}>
      <HeaderWrapper error={error}>
        <Typography color={error ? 'error' : undefined}>{title}</Typography>
      </HeaderWrapper>
      {children}
    </FormCard>
  );
};

const ArrayFormBoxList = styled('ul')`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const ArrayFormBoxListItem = styled('li')(
  ({ theme }) => `
  padding: ${theme.spacing(2)};
  border-bottom: 1px solid ${theme.colors.alpha.black[30]};
  &:last-child {
    border-bottom: none;
  }
`
);

const ArrayFormBoxFooter = styled('div')(
  ({ theme }) => `
    display: flex;
   padding: ${theme.spacing(2)};
   border-top: 1px solid ${theme.colors.alpha.black[30]};
`
);

interface IPropsActions {
  onEdit?(): void;
  onDelete?(): void;
  onResendInvitations?(): void;
  canEdit?: boolean;
  canDelete?: boolean;
  canResendInvitations?: boolean;
}

const ArrayFormBoxListActions: React.FC<IPropsActions> = ({
  onEdit,
  onDelete,
  onResendInvitations,
  canEdit,
  canDelete,
  canResendInvitations
}) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {canResendInvitations && (
        <Tooltip title={t(`${ACTIONS_TRANSLATE_KEY}.resend`)} arrow>
          <IconButton
            aria-label="edit"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              onResendInvitations();
            }}
            color="primary"
          >
            <PublishedWithChangesIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      )}
      {canEdit && (
        <Tooltip title={t(`${ACTIONS_TRANSLATE_KEY}.edit`)} arrow>
          <IconButton
            aria-label="edit"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              onEdit();
            }}
            color="primary"
          >
            <EditIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      )}
      {canDelete && (
        <Tooltip title={t(`${ACTIONS_TRANSLATE_KEY}.delete`)} arrow>
          <IconButton
            aria-label="delete"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              onDelete();
            }}
            color="error"
          >
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};

ArrayFormBoxListActions.defaultProps = {
  canEdit: true,
  canDelete: true
};

export {
  ArrayFormBox,
  ArrayFormBoxList,
  ArrayFormBoxListItem,
  ArrayFormBoxFooter,
  ArrayFormBoxListActions
};
