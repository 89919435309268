import { ISchool } from 'src/models/school';
import { FileUploadingStatus } from '../../../components/FileTree/types';
import { ITableApiParams } from '../../../hooks/useTableParams';
import { IBaseModel } from '../../../models/base-model';
import { ICohortTaskConfiguration } from '../../../models/cohort-task-configuration';

export interface IFetchCohortParams extends ITableApiParams { }

export interface IFormCohortTask extends ICohortTaskConfiguration {
  code?: string;
}

export interface ICreateCohortForm {
  certificateId: string;
  name: string;
  shortName: string;
  avatar: string;
  description: string;
  yearOfCertification?: number;
  schools: Array<Partial<ISchool>>;
  tasks: Array<Partial<IFormCohortTask>>;
}

export interface IAddSchool {
  schools: Partial<ISchool>[];
}

export interface ISchoolCohortDelete {
  cohortId: string;
  schoolId: string;
}

export interface IAssignedCohortReport {
  cohortId: string;
  schoolId: string;
}

export interface IInviteStudent {
  id?: string;
  email: string;
  firstName: string;
  lastName: string;
  studentStatus?: string;
  dateOfBirth?: Date;
  placeOfBirth?: string;
  group?: string;
}

export interface IAssignTutorToStudent {
  tutorEmail: string;
  studentEmail: string;
}

export interface IStudentInviteParams extends IInviteStudent {
  cohortId: string;
  schoolId: string;
}

export enum SchoolCourseDocumentType {
  AnnualTimetables = 'annual_timetable',
  TrainersCvs = 'trainers_cv',
  ProofOfCommunications = 'proof_of_communication',
  SubmissionsOfTheQualiopi = 'submissions_of_the_qualiopi'
}

export interface IExtendedCourseDocument extends IBaseModel {
  courseId: string;
  fileId: string;
  id: string;
  name: string;
  type: SchoolCourseDocumentType;
}

export interface IDocumentUpload extends Partial<IExtendedCourseDocument> {
  uuid: string;
  status: FileUploadingStatus;
}

export interface ILocalDocuments {
  [SchoolCourseDocumentType.AnnualTimetables]: IDocumentUpload[];
  [SchoolCourseDocumentType.TrainersCvs]: IDocumentUpload[];
  [SchoolCourseDocumentType.ProofOfCommunications]: IDocumentUpload[];
  [SchoolCourseDocumentType.SubmissionsOfTheQualiopi]: IDocumentUpload[];
}

export enum TriggerType {
  Event = 'event',
  Manually = 'manually',
  Date = 'date',
}


export const MAP_TRIGGER_TYPE_TO_TEXT = {
  [TriggerType.Event]: 'Event',
  [TriggerType.Manually]: 'Manually',
  [TriggerType.Date]: 'Date',
}