import { Alert, Button, AlertTitle } from '@mui/material';
import { t } from 'i18next';

import { AlertBorderSettings, AlertType } from '../../../../../utils/styles';
import { IUser } from 'src/models/user';
import { studentIsOnHold } from 'src/utils/userHelpers';

const STUDENT_TRANSLATE_BASE_KEY = 'profile.student';

interface IProps {
  student: IUser;
  isTaskExpired: boolean;
  isOrgAdmin: boolean;
  canManage: boolean;
  canRequestVerification: boolean;
  toggleRequestVerificationModal(): void;
}

const Invited: React.FC<IProps> = ({
  student,
  isTaskExpired,
  isOrgAdmin,
  canManage,
  canRequestVerification,
  toggleRequestVerificationModal
}) => {
  return (
    <Alert
      severity="info"
      sx={{
        border: AlertBorderSettings[AlertType.Info],
        mb: 2
      }}
      action={
        ((!isTaskExpired && canManage) || isOrgAdmin) &&
        canRequestVerification && (
          <Button
            disabled={studentIsOnHold(student)}
            color="primary"
            variant="contained"
            sx={{ m: 1 }}
            onClick={toggleRequestVerificationModal}
          >
            {t(
              `${STUDENT_TRANSLATE_BASE_KEY}.uploadDocuments.documentsAlert.requestVerification`
            )}
          </Button>
        )
      }
    >
      <AlertTitle>
        <strong>
          {t(
            `${STUDENT_TRANSLATE_BASE_KEY}.uploadDocuments.documentsAlert.waitingDocumentsTitle`
          )}
        </strong>
      </AlertTitle>
      {t(
        `${STUDENT_TRANSLATE_BASE_KEY}.uploadDocuments.documentsAlert.${
          canManage
            ? 'waitingOrUploadDocumentsTextSchool'
            : 'waitingDocumentsTextSchool'
        }`
      )}
    </Alert>
  );
};

export default Invited;
