export enum FileTreeMode {
  View = 'view',
  Edit = 'edit',
  Create = 'create'
}

export enum FileUploadingStatus {
  ToBeUploaded = 'toBeUploaded',
  Uploading = 'uploading',
  Success = 'success',
  Error = 'error'
}

export enum FolderCreateStatus {
  Success = 'success',
  Error = 'error'
}

export enum TreeItemType {
  File = 'file',
  Folder = 'folder'
}

export interface IFile {
  id?: string;
  type: TreeItemType;
  uuid: string;
  name: string;
  url?: string;
  folderId?: string;
  status?: FileUploadingStatus;
  roles?: string[];
  [prop: string]: any;
  position?: number;
}

export interface IFolder {
  id?: string;
  type: TreeItemType;
  uuid: string;
  name: string;
  children: Array<IFolder | IFile>;
  parentFolderId?: string;
  roles?: string[];
  position?: number;
}
