import {
  Paper,
  Typography,
  Divider,
  CardContent,
  IconButton,
  Grid,
  Alert
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import { Mode } from 'src/utils/types';
import { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import StudentJobDetails from './StudentJobDetails';
import { IUser, UserableType } from 'src/models/user';
import useUpdateStudentJob from 'src/features/profile/hooks/api/job/useUpdateStudentJob';
import { IUpdateStudentJob } from 'src/features/profile/utils/types';
import JobMissions from './JobMissions/JobMissions';
import PageLoader from 'src/components/PageLoader';
import { IJob } from 'src/models/job';
import JobRequiredKnowledge from './JobRequiredKnowledge/JobRequiredKnowledge';
import JobRequiredKnowHow from './JobRequiredKnowHow/JobRequiredKnowHow';
import JobExpectedContribution from './JobExpectedContribution/JobExpectedContribution';
import useAuth from 'src/features/auth/hooks/useAuth';
import { JobStatuses, Permissions } from 'src/utils/const';
import useToggleVisibility from 'src/hooks/useToggleVisibility';
import Confirm from 'src/components/Confirm';
import RequestReview from './RequestReview';
import useRequestJobDescriptionVerification from 'src/features/profile/hooks/api/job/useRequestJobDescriptionVerification';
import ReviewJobDescription from './ReviewJobDescription';
import useTaskOverdue from 'src/features/tasks/hooks/api/useTaskOverdue';
import TaskDeadlineAlert from 'src/features/tasks/components/common/TaskDeadlineAlert';
import { TaskCodes } from '../../../../tasks/utils/types';
import useHaveRole from '../../../../../hooks/useHaveRole';
import { RoleName } from '../../../../../models/role';
import useCan from '../../../../../hooks/useCan';
import useUpdateJobStatus from '../../../hooks/api/job/useUpdateJobStatus';
import RejectConfirmJobDescription from './RejectConfirmJobDescription';
import { ICreateRejectMessageForm } from 'src/models/course';
import { formatStatus } from 'src/features/profile/utils/formatStatus';

const PROFILE_PERSONAL_INFO_TRANSLATE_KEY = 'profile.student.job';

interface IProps {
  student: IUser;
  job: IJob;
  isFetchingStudentJob: boolean;
}

const StudentJobSection: React.FC<IProps> = ({
  student,
  job,
  isFetchingStudentJob
}) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [studentJobLocalMode, setStudentJobLocalMode] = useState(Mode.View);

  const canManageJob = useCan(Permissions.ManageJob);
  const canOverrideJob = useCan(Permissions.OverrideJob)

  const isOrgAdmin = useHaveRole(RoleName.OrganisationAdmin);

  const isTutorOrStudent = [
    UserableType.Student,
    UserableType.CompanyEmployee
  ].includes(user.userableType);

  const isSchoolUser = user.userableType === UserableType.SchoolEmployee;

  const { updateStudentJob, isUpdatingStudentJob } = useUpdateStudentJob(
    student.userableId,
    job.id
  );

  const [status, setStatus] = useState<JobStatuses>();

  const { updateJobStatus, isUpdatingJobStatus } = useUpdateJobStatus(
    student.userableId,
    job.id
  );

  const {
    isTaskOverdue: isAddJobDescTaskOverdue,
    isTaskExpired: isAddJobDescExpired,
    task: addJobDescTask,
    isFetchingTask: isFetchingJobDescTask
  } = useTaskOverdue(
    {
      code: TaskCodes.AddJobDescription,
      meta: {
        studentId: student.userableId
      }
    },
    {
      enabled: canManageJob
    }
  );

  const {
    isTaskOverdue: isVerifyJobDescTaskOverdue,
    isTaskExpired: isVerifyJobDescTaskExpired,
    task: verifyJobDescTask
  } = useTaskOverdue(
    {
      code: TaskCodes.VerifyJobDescription,
      meta: {
        studentId: student.userableId
      }
    },
    {
      enabled: isSchoolUser && canManageJob
    }
  );

  const handleEdit = (data: IUpdateStudentJob) => {
    updateStudentJob(
      { data },
      {
        onSuccess: () => {
          setStudentJobLocalMode(Mode.View);
        }
      }
    );
  };

  const handleUpdateStatus = (data?: ICreateRejectMessageForm) => {
    updateJobStatus(
      data ? { status: status, message: data.message } : { status: status },
      { onSuccess: () => setStatus(null) }
    );
  };

  const {
    isVisible: isVisibleRequestVerificationModal,
    toggle: toggleRequestVerificationModal
  } = useToggleVisibility(false);

  const {
    requestJobDescriptionVerification,
    isRequestingJobDescriptionVerification
  } = useRequestJobDescriptionVerification();

  // Job can verify school user before task for verification expired or org user
  const canVerifyJob =
    (canManageJob && isSchoolUser && !isVerifyJobDescTaskExpired) || isOrgAdmin;

  // Job can add tutor or student before add job desc expired
  // or school user before verification task expired
  // or org admin
  const canAddJob =
    (isTutorOrStudent && canManageJob && !isAddJobDescExpired) || canVerifyJob;

  // Req verification can be done by student tutor
  const canReqVerification =
    isTutorOrStudent && canManageJob && !isAddJobDescExpired;

  if (isFetchingStudentJob) {
    return <PageLoader />;
  }

  return (
    <Grid container spacing-xs={3}>
      <Grid item xs={12} mb={2}>
        {canVerifyJob && (
          <ReviewJobDescription
            canVerifyWithoutStatusCheck={canOverrideJob}
            job={job}
            student={student}
            onReject={() => setStatus(JobStatuses.Rejected)}
            onVerify={() => setStatus(JobStatuses.VerifiedBySchool)}
          />
        )}
        {isVerifyJobDescTaskOverdue && (
          <TaskDeadlineAlert task={verifyJobDescTask} />
        )}
        {isAddJobDescTaskOverdue && isTutorOrStudent && (
          <TaskDeadlineAlert task={addJobDescTask} />
        )}

        {canReqVerification && !isFetchingJobDescTask && (
          <RequestReview
            job={job}
            student={student}
            onRequestVerification={toggleRequestVerificationModal}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <Paper
          elevation={1}
          square={false}
          sx={{ borderRadius: '8px', padding: 0 }}
        >
          <Box
            sx={{
              padding: '24px 32px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              position: 'relative'
            }}
          >
            <Box>
              <Typography
                variant="h4"
                gutterBottom
                sx={{ fontWeight: 700, fontSize: '14px' }}
              >
                {t(`${PROFILE_PERSONAL_INFO_TRANSLATE_KEY}.title`)}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{ fontWeight: 400, fontSize: '15px' }}
              >
                {t(`${PROFILE_PERSONAL_INFO_TRANSLATE_KEY}.subtitle`)}
              </Typography>
            </Box>
            <Box sx={{ textAlign: 'right' }}>
              <Typography
                variant="h4"
                gutterBottom
                sx={{ fontWeight: 500, fontSize: '14px' }}
              >
                {t(`${PROFILE_PERSONAL_INFO_TRANSLATE_KEY}.jobStatus`)}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{ fontWeight: 400, fontSize: '15px' }}
              >
                {t(`${PROFILE_PERSONAL_INFO_TRANSLATE_KEY}.statuses.${job.status}`)}
              </Typography>
            </Box>

          </Box>
          <Divider />
          <CardContent sx={{ padding: '32px', paddingBottom: '24px' }}>
            {/* <StudentJobDetails
              personalInfoLocalMode={studentJobLocalMode}
              setPersonalInfoLocalMode={setStudentJobLocalMode}
              user={user}
              isUpdatingPersonalInfo={isUpdatingStudentJob}
              handleEdit={handleEdit}
              job={job}
            /> */}
            <JobMissions job={job} student={student} canManage={canAddJob} />
            <JobRequiredKnowledge job={job} student={student} canManage={canAddJob} />
            <JobRequiredKnowHow job={job} student={student} canManage={canAddJob} />
            {/* <JobExpectedContribution job={job} canManage={canAddJob} /> */}
            <RejectConfirmJobDescription
              title={t('profile.student.job.actions.rejectJob.title')}
              description={t(`profile.student.job.actions.rejectJob.subtitle`)}
              open={status === JobStatuses.Rejected}
              isConfirming={isUpdatingJobStatus}
              onClose={() => setStatus(null)}
              onConfirm={handleUpdateStatus}
              defaultMessage={job.status === JobStatuses.NotVerified ? t(`profile.student.job.actions.rejectJob.forceRejectDefaultMessage`) : ''}
            />
            <Confirm
              title={t(`profile.student.job.actions.verifyJob.title`)}
              onClose={() => setStatus(null)}
              onConfirm={() => handleUpdateStatus()}
              open={status === JobStatuses.VerifiedBySchool}
              isConfirming={isUpdatingJobStatus}
            >
              <Alert severity="warning" sx={{ mt: '15px' }}>
                {t(`profile.student.job.actions.verifyJob.noticeMessage`)}
              </Alert>
            </Confirm>
          </CardContent>
        </Paper>
      </Grid>
      <Confirm
        title={t(
          `${PROFILE_PERSONAL_INFO_TRANSLATE_KEY}.requestVerification.title`
        )}
        onClose={() => toggleRequestVerificationModal()}
        onConfirm={() =>
          requestJobDescriptionVerification(
            { jobId: job.id },
            {
              onSuccess: () => {
                toggleRequestVerificationModal();
              }
            }
          )
        }
        open={!!isVisibleRequestVerificationModal}
        isConfirming={isRequestingJobDescriptionVerification}
      >
        {t(`${PROFILE_PERSONAL_INFO_TRANSLATE_KEY}.requestVerification.body`)}
      </Confirm>
    </Grid>
  );
};

export default StudentJobSection;
