import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { IApiError } from '../../../../utils/axios';
import {
  uploadCv as uploadCvApi,
  UPLOAD_CV_KEY
} from '../../utils/api';

const UPLOAD_CV_TRANSLATE_KEY = 'profile.professionalInfo.uploadCv';

const useUploadCv = () => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

  const { mutate: uploadCv, isLoading: isUploadingCv } = useMutation(
    (file:File) => uploadCvApi(file),
    {
      mutationKey: UPLOAD_CV_KEY,
      onError: (error: IApiError) => {
        enqueueSnackbar(error.message, {
          variant: 'error'
        });
      }
    }
  );

  return {
    uploadCv,
    isUploadingCv
  };
};

export default useUploadCv;
