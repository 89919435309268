import axios from 'axios';
import applyCaseMiddleware from 'axios-case-converter';
import i18n from 'src/i18n/i18n';

export const axiosInt = applyCaseMiddleware(
  axios.create({
    baseURL: localStorage.getItem('apiUrl'),
    headers: {
      Accept: 'application/json'
    }
  })
);

axiosInt.interceptors.response.use(
  (response) => {
    // TODO
    // if (typeof response.data === 'object' && Object.keys(response.data).length === 0) {
    //   response.data = null;
    // }
    return response
  },
  (error) => {
    return Promise.reject({
      status: error?.response?.status,
      message: error?.response?.data?.message || 'There is an error!',
      data: error?.response?.data
    });
  }
);

axiosInt.interceptors.request.use(
  (config) => {
    config.headers['Accept-Language'] = i18n.resolvedLanguage;
    return config
  }
);

export interface IApiError {
  message: string;
  status: number;
  data?: any;
}
