import { Tabs } from '../pages/Tasks';
import { TaskCodes } from '../utils/types';

const tasks = {
  common: {
    pageHeader: {
      title: 'Créer une tâche manuelle',
      subtitle: 'Saisir les champs ci-dessous pour créer une nouvelle tâche'
    },
    api: {
      success: 'Tâche manuelle créée avec succès'
    },
    taskCreateManualForm: {
      certificate: {
        label: 'Certificat',
        placeholder: 'Sélectionner le certificat'
      },
      cohort: {
        label: 'Cohorte',
        placeholder: 'Sélectionner la cohorte'
      },
      task: {
        title: 'Info Tâche',
        predefined: {
          label: 'Tâches prédéfinies',
          placeholder: 'Sélectionner les tâches prédéfinies'
        },
        name: {
          label: 'Libellé de la tâche',
          placeholder: 'Saisir le libellé...'
        },
        description: {
          label: 'Description de la tâche',
          placeholder: 'Saisir la description...'
        }
      },
      notifications: {
        title: 'Info notification',
        name: {
          label: 'Libellé de la notification',
          placeholder: 'Saisir le libellé ici...'
        },
        text: {
          label: 'Corps de texte de la notification',
          placeholder: 'Saisir le texte ici...'
        },
        sendOnEmail: {
          label: 'Envoi par e-mail',
          placeholder: 'Envoi par e-mail'
        },
        sendOnPlatform: {
          label: 'Envoi in-app',
          placeholder: 'Envoi in-app'
        }
      },
      roleUsers: {
        title: 'Info Utilisateur',
        role: {
          label: 'Rôles',
          placeholder: ''
        },
        search: {
          placeholder: 'Chercher par nom...'
        },
        table: {
          col1: 'Nom'
        }
      }
    },
    taskPredefined: {
      [TaskCodes.InviteStudents]: 'Inviter des étudiants',
      [TaskCodes.UploadStudentDocuments]: 'Téléverser les documents étudiants',
      [TaskCodes.VerifyStudentDocuments]: 'Valider les documents étudiants',
      [TaskCodes.AssignTutorToStudent]: 'Affecter un tuteur à un étudiant',
      [TaskCodes.AddJobDescription]: 'Ajouter une fiche de poste',
      [TaskCodes.VerifyJobDescription]: 'Valider la fiche de poste',
      [TaskCodes.UploadCourseDocuments]: 'Téléverser les documents école',
      [TaskCodes.VerifyCourseDocuments]: 'Valider les documents école',
      [TaskCodes.AssignCorrectors]: 'Affecter un correcteur',
      [TaskCodes.AssignJuryPresident]: 'Affecter un président de jury',
      [TaskCodes.AssignJuryMember]: 'Affecter un membre de jury',
      [TaskCodes.UploadBlockDeliverables]: 'Téléverser un livrable de bloc'
    }
  },
  listAll: {
    title: 'Tâches',
    subtitle: 'Liste des tâches affectées',
    taskLatest: 'Dernières tâches',
    taskDetails: 'Détails sur la tâche',
    taskComplete: 'Finaliser la tâche',
    taskOverdue: 'Voir les détails',
    createManualTask: 'Créer une tâche manuellement',
    daysLeft: 'jours restants',
    tab: {
      [Tabs.CreatedByMe]: 'Mes tâches créées',
      [Tabs.Mine]: 'Mes tâches'
    },
    table: {
      columns: {
        name: 'Libellé de la tâche',
        status: 'Statut',
        school: 'Ecole',
        cohort: 'Cohorte',
        assignedBy: 'Affecté par',
        assignedTo: 'Affecté à',
        doneBy: 'Réalisé par',
        createdAt: 'Créée le',
        dueDate: 'Deadline',
        description: 'Description'
      },
      actionTooltips: {
        view: 'Aller à la tâche',
        show: 'Montrer les détails',
        update: 'Mettre à jour le statut'
      },
      status: {
        overdue: 'En retard',
        toDo: 'A faire',
        inProgress: 'En cours',
        done: 'Fait',
        terminatedBySystem: 'Finalisé par le système'
      }
    }
  },
  noStudentsFound: 'Aucun étudiant trouvé!',
  view: {
    title: 'Tâche',
    subtitle: 'Détails',
    backToList: 'Retour aux tâches'
  },
  deadline: {
    deadlineExpiredTitle: 'Deadline dépassée',
    deadlineExpiredText: 'La deadline pour cette tâche est dépassée.',
    alertText:
      'La deadline pour la tâche "{{taskName}}" est dépassée depuis le {{dueDate}}.'
  }
};

export default tasks;
