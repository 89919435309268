import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import ApiError from '../../../components/ApiError';
import PageLoader from '../../../components/PageLoader';
import { Mode } from '../../../utils/types';
import { ContentBox, ContentCard } from '../components/common/StyledComponents';
import PageHeader from '../components/assigned-cohort/PageHeader';
import CohortBasicInfo from '../components/cohort/CohortBasicInfo';
import useAuth from 'src/features/auth/hooks/useAuth';
import useFetchAssignedCohort from '../hooks/api/useFetchAssignedCohort';
import CertificateBasicInfo from 'src/features/certificates/components/certificate/CertificateBasicInfo';
import useCan from 'src/hooks/useCan';
import { Permissions } from '../../../utils/const';
import useFetchSchool from 'src/features/schools/hooks/api/useFetchSchool';
import Students from '../components/assigned-cohort/Students';
import { Box } from '@mui/system';
import { Grid, Tab } from '@mui/material';
import TabPanel from '@mui/lab/TabPanel';
import React from 'react';
import TabList from '@mui/lab/TabList';
import TabContext from '@mui/lab/TabContext';
import Documents from '../components/assigned-cohort/Documents';
import useFetchCourseByCohortAndSchool from '../hooks/api/useFetchCourseByCohortAndSchool';
import CertificateAcademicKit from 'src/features/certificates/components/certificate/CertificateAcademicKit';
import useTab from '../../../hooks/useTab';
import Blocks from 'src/features/blocks/components/Blocks';

const VIEW_COHORT_TRANSLATE_KEY = 'cohorts.assigned.view';

export const TAB_PANEL_CSS = { padding: 0 };

export enum Tabs {
  Cohort = 'Cohort',
  Certificate = 'Certificate',
  Students = 'Students',
  Documents = 'Documents',
  Blocks = 'Blocks'
}

const AssignedCohort = () => {
  const { id } = useParams();

  const { t } = useTranslation();

  const { user } = useAuth();

  const { tab, setTab } = useTab(Tabs.Cohort);

  const canReadAssignedCohorts = useCan(Permissions.ReadAssignedCohorts);
  const manageStudents = useCan(Permissions.ManageStudents);
  const readStudents = useCan(Permissions.ReadStudents);
  const canReadAssignedCertifications = useCan(
    Permissions.ReadAssignedCertifications
  );
  const manageJobs = useCan(Permissions.ManageJobs);

  const { cohort, isFetchingCohort, errorFetchingCohort } =
    useFetchAssignedCohort(user.userable.schoolId, id);

  const { school, isFetchingSchool, errorFetchingSchool } = useFetchSchool(
    user.userable.schoolId
  );

  const { course, isFetchingCourse, errorFetchingCourse } =
    useFetchCourseByCohortAndSchool(id, user.userable.schoolId);

  const handleTabChange = (_: React.SyntheticEvent, newValue: Tabs) => {
    setTab(newValue);
  };

  if (isFetchingCohort || isFetchingSchool || isFetchingCourse) {
    return <PageLoader />;
  }

  if (errorFetchingCohort || errorFetchingSchool || errorFetchingCourse) {
    return (
      <ApiError
        error={
          errorFetchingCohort || errorFetchingSchool || errorFetchingCourse
        }
      />
    );
  }

  return (
    <div>
      <Helmet>
        <title>
          {t(`${VIEW_COHORT_TRANSLATE_KEY}.htmlTitle`, {
            name: cohort.name
          })}
        </title>
      </Helmet>
      <PageHeader cohort={cohort} />
      <ContentBox>
        <TabContext value={tab}>
          <Box>
            <TabList onChange={handleTabChange} sx={{ mr: 1, mb: 1 }}>
              {canReadAssignedCohorts && (
                <Tab
                  label={t(`${VIEW_COHORT_TRANSLATE_KEY}.cohortDetails`)}
                  value={Tabs.Cohort}
                />
              )}
              {/* {canReadAssignedCertifications && (
                <Tab
                  label={t(`${VIEW_COHORT_TRANSLATE_KEY}.certificateDetails`)}
                  value={Tabs.Certificate}
                />
              )} */}
              {readStudents && (
                <Tab
                  label={t(`${VIEW_COHORT_TRANSLATE_KEY}.students`)}
                  value={Tabs.Students}
                />
              )}
              {canReadAssignedCohorts && (
                <Tab
                  label={t(`${VIEW_COHORT_TRANSLATE_KEY}.documents`)}
                  value={Tabs.Documents}
                />
              )}
              {canReadAssignedCertifications && (
                <Tab
                  label={t(`${VIEW_COHORT_TRANSLATE_KEY}.blocks`)}
                  value={Tabs.Blocks}
                />
              )}
            </TabList>
          </Box>
          <TabPanel value={Tabs.Cohort} style={TAB_PANEL_CSS}>
            {canReadAssignedCohorts && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ContentCard>
                    <CohortBasicInfo
                      cohort={cohort}
                      canManageCohort={false}
                      mode={Mode.View}
                    />
                  </ContentCard>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <ContentCard>
                    <CertificateBasicInfo
                      certificate={cohort.certificate}
                      canManageCertificates={false}
                    />
                  </ContentCard>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box sx={{ mt: '-24px' }}>
                    <CertificateAcademicKit
                      certificate={cohort.certificate}
                      canManageCertificates={false}
                    />
                  </Box>
                </Grid>
              </Grid>
            )}
          </TabPanel>
          {/* {canReadAssignedCertifications && (
            <TabPanel value={Tabs.Certificate} sx={TAB_PANEL_CSS}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8}>
                  <ContentCard>
                    <CertificateBasicInfo
                      certificate={cohort.certificate}
                      canManageCertificates={false}
                    />
                  </ContentCard>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box sx={{ mt: '-24px' }}>
                    <CertificateAcademicKit
                      certificate={cohort.certificate}
                      canManageCertificates={false}
                    />
                  </Box>
                </Grid>
              </Grid>
            </TabPanel>
          )} */}
          {readStudents && (
            <TabPanel value={Tabs.Students} sx={TAB_PANEL_CSS}>
              <Students
                cohortId={cohort.id}
                schoolId={school.id}
                canManageStudents={manageStudents}
                canAssignTutorsToStudents={manageJobs}
                certificate={cohort.certificate}
              />
            </TabPanel>
          )}
          {canReadAssignedCohorts && (
            <TabPanel value={Tabs.Documents} style={TAB_PANEL_CSS}>
              <Documents course={course} />
            </TabPanel>
          )}
          {canReadAssignedCertifications && (
            <TabPanel value={Tabs.Blocks} style={TAB_PANEL_CSS}>
              <Blocks blocks={cohort.certificate.blocks} cohort={cohort} />
            </TabPanel>
          )}
        </TabContext>
      </ContentBox>
    </div>
  );
};

export default AssignedCohort;
