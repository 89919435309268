import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { IApiError } from '../../../../../utils/axios';
import {
  updateStudentJob as updateStudentJobApi,
  UPDATE_STUDENT_JOB_KEY,
  FETCH_STUDENT_JOB_KEY
} from '../../../utils/api';

const UPDATE_STUDENT_JOB_TRANSLATE_KEY = 'profile.student.job.update';

const useUpdateStudentJob = (studentId: string, jobId: string) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { mutate: updateStudentJob, isLoading: isUpdatingStudentJob } =
    useMutation(
      ({ data }: any) => updateStudentJobApi(data, studentId, jobId),
      {
        mutationKey: UPDATE_STUDENT_JOB_KEY,
        onSuccess: () => {
          enqueueSnackbar(
            t(`${UPDATE_STUDENT_JOB_TRANSLATE_KEY}.successMessage`),
            {
              variant: 'success'
            }
          );
        },
        onError: (error: IApiError) => {
          enqueueSnackbar(error.message, {
            variant: 'error'
          });
        }
      }
    );

  return {
    updateStudentJob,
    isUpdatingStudentJob
  };
};

export default useUpdateStudentJob;
