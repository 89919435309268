import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { IUser } from 'src/models/user';
import InfoItem from '../InfoItem';
import ApiError from '../../../../../components/ApiError';

const TUTOR_INFO_TRANSLATE_KEY = 'profile.tutorInfo';

interface IProps {
  tutor?: IUser;
}

const TutorDetails: React.FC<IProps> = ({ tutor }) => {
  const { t } = useTranslation();

  if (!tutor) {
    return (
      <ApiError
        error={{
          message: t(
            `${TUTOR_INFO_TRANSLATE_KEY}.tutorInfoSection.tutorNotFound`
          ),
          status: 404
        }}
      />
    );
  }

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        boxSizing: 'border-box',
        flexFlow: 'flex-wrap',
        width: '100%'
      }}
    >
      <InfoItem
        property={t(`${TUTOR_INFO_TRANSLATE_KEY}.firstName`)}
        value={tutor.firstName}
      />
      <InfoItem
        property={t(t(`${TUTOR_INFO_TRANSLATE_KEY}.lastName`))}
        value={tutor.lastName}
      />
      <InfoItem
        property={t(t(`${TUTOR_INFO_TRANSLATE_KEY}.email`))}
        value={tutor.email}
      />
      <InfoItem
        property={t(t(`${TUTOR_INFO_TRANSLATE_KEY}.position`))}
        value={tutor.userable.position}
      />
      <InfoItem
        property={t(t(`${TUTOR_INFO_TRANSLATE_KEY}.officePhone`))}
        value={tutor.userable.officePhone}
      />
      <InfoItem
        property={t(t(`${TUTOR_INFO_TRANSLATE_KEY}.directLine`))}
        value={tutor.userable.directLine}
      />
    </Grid>
  );
};

export default TutorDetails;
