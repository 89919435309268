import { Box } from '@mui/material';
import LanguageSwitcher from './LanguageSwitcher';
import HeaderNotifications from 'src/features/notifications/components/HeaderNotifications';
import MailToSupport from './MailToSupport';

function HeaderButtons() {
  return (
    <Box display="flex">
      <MailToSupport />
      <HeaderNotifications />
      <LanguageSwitcher />
    </Box>
  );
}

export default HeaderButtons;
