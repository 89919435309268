import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  TextField,
  Button,
  Box,
  Grid,
  IconButton,
  Tooltip
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import TableContainer from '../../../../components/Table/TableContainer';
import TableHeaderSortLabel from '../../../../components/Table/TableHeaderSortLabel';
import { ISort, Mode } from '../../../../utils/types';
import React from 'react';
import { DateFormat, formatDate } from '../../../../utils/dates';
import { IStudent } from 'src/models/student';
import { StudentRoutes } from 'src/features/students/routes';
import { printUserName } from 'src/utils/userHelpers';
import AddIcon from '@mui/icons-material/Add';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import DeleteIcon from '@mui/icons-material/Delete';
import { UserStatus } from 'src/models/user';
import StudentStatus from '../../../students/components/common/StudentStatus';
import StudentsTableActions from '../common/StudentsTableActions';
import { ITableStudent } from 'src/models/student-table-model';
import { isSchoolEmployee } from 'src/utils/userTypes';
import useAuth from 'src/features/auth/hooks/useAuth';

interface IProps {
  page: number;
  isFetching: boolean;
  total: number;
  students: ITableStudent[];
  sort?: ISort;
  perPage: number;
  searchedTerm: string;
  onChangePage(page: number): void;
  onChangeSort(sort: any): void;
  onChangeSearch(value: string): void;
  canManage: boolean;
  canAddStudent: boolean;
  canAssignTutorsToStudents: boolean;
  onAddStudent(): void;
  onImportStudents(): void;
  onAssignTutorsToStudents(): void;
  onResendInvitations?(index: string, student: IStudent): void;
  onDeleteStudent?(student: IStudent): void;
}

const LIST_STUDENTS_TRANSLATE_KEY = 'cohorts.students.listAll';
const ACTIONS_TRANSLATE_KEY = 'acl.actions';

export enum Columns {
  Name = 'users.first_name',
  LastName = 'users.last_name',
  Email = 'users.email',
  Group = 'students.group',
  Tutor = 'tutors.last_name',
  Status = 'students.student_status',
  CreatedAt = 'users.created_at',
  Actions = 'actions'
}

const StudentsTable: React.FC<IProps> = ({
  page,
  isFetching,
  total,
  students,
  sort,
  perPage,
  searchedTerm,
  onChangePage,
  onChangeSort,
  onChangeSearch,
  canManage,
  canAddStudent,
  canAssignTutorsToStudents,
  onAddStudent,
  onImportStudents,
  onAssignTutorsToStudents,
  onResendInvitations,
  onDeleteStudent
}) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { user } = useAuth();
  const canSeeTutorsOnTable = true; // isSchoolEmployee(user);

  return (
    <TableContainer
      currentPage={page}
      isFetching={isFetching}
      onChangePage={onChangePage}
      total={total}
      perPage={perPage}
    >
      <Grid container sx={{ mb: '20px' }} spacing={2}>
        <Grid item sm={3} >
          <TextField
            size='medium'
            margin='none'
            sx={{ mt: '1px' }} // TODO Easy FIX  
            fullWidth
            placeholder={t(`${LIST_STUDENTS_TRANSLATE_KEY}.search.placeholder`)}
            onChange={(e) => {
              onChangeSearch(e.target.value);
            }}
            variant="outlined"
            value={searchedTerm}
          />
        </Grid>
        <Grid
          item
          sm={9}
          display="flex"
          alignItems="center"
          justifyContent="end"
        >
          {canManage && canAssignTutorsToStudents && (
            <Button
              variant="contained"
              size="medium"
              onClick={onAssignTutorsToStudents}
              sx={{ mr: 2 }}
            >
              <AddIcon fontSize="small" />
              {t(`${LIST_STUDENTS_TRANSLATE_KEY}.assignTutorsToStudents`)}
            </Button>
          )}
          {canManage && canAddStudent && (<>
            <Button
              variant="contained"
              size="medium"
              onClick={onImportStudents}
              sx={{ mr: 2 }}
            >
              <AddIcon fontSize="small" />
              {t(`${LIST_STUDENTS_TRANSLATE_KEY}.importStudents`)}
            </Button>
            <Button variant="contained" size="medium" onClick={onAddStudent}>
              <AddIcon fontSize="small" />
              {t(`${LIST_STUDENTS_TRANSLATE_KEY}.addStudent`)}
            </Button>
          </>)}
        </Grid>
      </Grid>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell key="name" align="left">
              <TableHeaderSortLabel
                columnName={Columns.Name}
                sort={sort}
                onChangeSort={onChangeSort}
              >
                {t(`${LIST_STUDENTS_TRANSLATE_KEY}.table.columns.studentFirstName`)}
              </TableHeaderSortLabel>
            </TableCell>
            <TableCell key="lastName" align="left">
              <TableHeaderSortLabel
                columnName={Columns.LastName}
                sort={sort}
                onChangeSort={onChangeSort}
              >
                {t(`${LIST_STUDENTS_TRANSLATE_KEY}.table.columns.studentLastName`)}
              </TableHeaderSortLabel>
            </TableCell>
            <TableCell key="email" align="left">
              <TableHeaderSortLabel
                columnName={Columns.Email}
                sort={sort}
                onChangeSort={onChangeSort}
              >
                {t(`${LIST_STUDENTS_TRANSLATE_KEY}.table.columns.studentEmail`)}
              </TableHeaderSortLabel>
            </TableCell>
            <TableCell key="group" align="left">
              <TableHeaderSortLabel
                columnName={Columns.Group}
                sort={sort}
                onChangeSort={onChangeSort}
              >
                {t(`${LIST_STUDENTS_TRANSLATE_KEY}.table.columns.studentGroup`)}
              </TableHeaderSortLabel>
            </TableCell>
            {canSeeTutorsOnTable &&
              <TableCell key="tutor" align="left">
                <TableHeaderSortLabel
                  columnName={Columns.Tutor}
                  sort={sort}
                  onChangeSort={onChangeSort}
                >
                  {t(`${LIST_STUDENTS_TRANSLATE_KEY}.table.columns.studentTutor`)}
                </TableHeaderSortLabel>
              </TableCell>}
            <TableCell key="status" align="left">
              <TableHeaderSortLabel
                columnName={Columns.Status}
                sort={sort}
                onChangeSort={onChangeSort}
              >
                {t(
                  `${LIST_STUDENTS_TRANSLATE_KEY}.table.columns.studentStatus`
                )}
              </TableHeaderSortLabel>
            </TableCell>
            <TableCell key="createdAt" align="left">
              <TableHeaderSortLabel
                columnName={Columns.CreatedAt}
                sort={sort}
                onChangeSort={onChangeSort}
              >
                {t(`${LIST_STUDENTS_TRANSLATE_KEY}.table.columns.createdAt`)}
              </TableHeaderSortLabel>
            </TableCell>
            <TableCell key="actions" align="right" />
          </TableRow>
        </TableHead>
        <TableBody>
          {students.map((student) => (
            <TableRow
              hover={true}
              key={student.id}
              onClick={() =>
                navigate(
                  StudentRoutes.Student.replace(':studentId', student.id)
                )
              }
            >
              <TableCell align="left">
                <Typography>{student.user.firstName}</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>{student.user.lastName}</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>{student.user.email}</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>{student.group}</Typography>
              </TableCell>
              {canSeeTutorsOnTable &&
                <TableCell align="left">
                  <Typography>{printUserName(student.tutorUser)}</Typography>
                </TableCell>}
              <TableCell align="left">
                <StudentStatus
                  studentStatus={student?.studentStatus}
                  userStatus={student?.user?.status}
                />
              </TableCell>
              <TableCell align="left" >
                {formatDate(student.createdAt, DateFormat.DateAndTime)}
              </TableCell>
              <TableCell align="right">
                <StudentsTableActions
                  student={student}
                  canDelete={canManage}
                  onDelete={() => onDeleteStudent(student)}
                  canResendInvitations={canManage && student.user?.status === UserStatus.Invited}
                  onResendInvitations={() => onResendInvitations(student.id, student)}
                  canEdit={canManage}
                  onEdit={() => navigate(`${StudentRoutes.Student.replace(
                    ':studentId',
                    student.id
                  )}?mode=${Mode.Edit}`)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default StudentsTable;
