import { useRef } from 'react';
import {
  alpha,
  Badge,
  Box,
  Divider,
  IconButton,
  Popover,
  useTheme,
  styled,
  Tooltip
} from '@mui/material';
import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import { useTranslation } from 'react-i18next';
import Notifications from './Notifications';
import InfoNotifications from './InfoNotifications';
import useFetchNumberOfUnreadNotifications from '../hooks/api/useFetchNumberOfUnreadNotifications';
import useToggleVisibility from '../../../hooks/useToggleVisibility';

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
  width: ${theme.spacing(4)};
  height: ${theme.spacing(4)};
  border-radius: ${theme.general.borderRadiusLg};
`
);

const HeaderNotifications = () => {
  const ref = useRef(null);

  const {
    isVisible: isNotificationBarVisible,
    hide: hideNotificationBar,
    show: showNotificationBar
  } = useToggleVisibility(false);

  const { numberOfUnreadNotifications, isFetchingNumberOfUnreadNotifications } =
    useFetchNumberOfUnreadNotifications();

  const { t } = useTranslation();
  const theme = useTheme();

  if (isFetchingNumberOfUnreadNotifications) {
    return null;
  }

  return (
    <>
      <Tooltip arrow title={t('Notifications')}>
        <Badge
          variant="dot"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          sx={{
            '.MuiBadge-badge': {
              background:
                numberOfUnreadNotifications > 0
                  ? theme.colors.success.main
                  : 'inherit',
              animation: 'pulse 1s infinite',
              transition: `${theme.transitions.create(['all'])}`
            }
          }}
        >
          <IconButtonWrapper
            sx={{
              background: alpha(theme.colors.primary.main, 0.1),
              transition: `${theme.transitions.create(['background'])}`,
              color: theme.colors.primary.main,

              '&:hover': {
                background: alpha(theme.colors.primary.main, 0.2)
              }
            }}
            color="primary"
            ref={ref}
            onClick={showNotificationBar}
          >
            <NotificationsActiveTwoToneIcon fontSize="small" />
          </IconButtonWrapper>
        </Badge>
      </Tooltip>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={hideNotificationBar}
        open={isNotificationBarVisible}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box minWidth={440} maxWidth={440} p={2}>
          <InfoNotifications
            numberOfUnreadNotifications={numberOfUnreadNotifications}
          />
        </Box>
        <Divider />
        <Notifications hide={hideNotificationBar} />
      </Popover>
    </>
  );
};

export default HeaderNotifications;
