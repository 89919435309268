import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DeleteIcon from '@mui/icons-material/Delete';
import RuleIcon from '@mui/icons-material/Rule';
import {
  Box,
  CircularProgress,
  IconButton,
  styled,
  Tooltip,
  Typography
} from '@mui/material';

import { FileTreeMode, FileUploadingStatus, IFile } from './types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useFetchFileUrl from './useFetchFileUrl';
import useToggleVisibility from 'src/hooks/useToggleVisibility';
import CreateEditRolesPopup from './CreateEditRolesPopup';
import { useContext } from 'react';
import { DragAndDropContext } from 'src/contexts/DragAndDropContext';
interface IProps {
  file: IFile;
  mode: FileTreeMode;
  canManage: boolean;
  onDelete(file: IFile): void;
  onEditRole?(file: IFile): void;
}

const FileBox = styled(Box)<{ error: boolean, dragAndDropView: boolean }>(
  ({ theme, error, dragAndDropView }) => `
  display:flex;
  justify-content: space-between;
  padding: ${theme.spacing(1)};
  position: relative;
  color: ${error ? theme.colors.error.main : 'inherit'};
  z-index: 1;
  &:hover {
    background-color: ${dragAndDropView ? theme.colors.alpha.black[10] : 'none'};
    cursor: ${dragAndDropView ? 'pointer' : 'default'};
  }
`
);

const UploadingProgress = styled(CircularProgress)(
  ({ theme }) => `
position: absolute;
top: ${theme.spacing(1.5)};
right: ${theme.spacing(1.5)};
`
);

const FILE_TREE_TOOLTIPS_TRANSLATE_KEY = 'common.fileTree.tooltips';

const File: React.FC<IProps> = ({
  file,
  mode,
  canManage,
  onDelete,
  onEditRole
}) => {

  const {
    enabled: enabledDragAndDrop,
    onDragStart,
    onDragEnter,
    onDragLeave,
    onDragEnd,
    onDrag,
    onDrop,
    onDragOver,
  } = useContext(DragAndDropContext);

  const { fetchFileUrl } = useFetchFileUrl();

  const isEditable = mode !== FileTreeMode.View;
  const isUploading = useMemo(() => {
    return [
      FileUploadingStatus.Uploading,
      FileUploadingStatus.ToBeUploaded
    ].includes(file.status);
  }, [file]);
  const isError = file.status === FileUploadingStatus.Error;

  const { t } = useTranslation();

  const {
    hide: hideFileRolesPopup,
    isVisible: isVisibleFileRolesPopup,
    toggle: toggleFileRolesPopup
  } = useToggleVisibility(false);

  const handleRedirectToFile = (file: IFile) => {
    fetchFileUrl(file.id, {
      onSuccess: ({ url }) => {
        window.open(url, '_blank');
      }
    });
  };

  const handleEditRoleOnFile = (roles: string[]) => {
    if (onEditRole) {
      onEditRole({ ...file, roles });
      hideFileRolesPopup();
    }
  };

  return (
    <FileBox
      display="flex"
      justifyContent="space-between"
      error={isError}
      dragAndDropView={enabledDragAndDrop}
      draggable={enabledDragAndDrop}
      onDragStart={(e) => onDragStart(e, file)}
      onDrag={onDrag}
      onDragEnd={onDragEnd}
      onDragOver={onDragOver}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDrop={(e) => onDrop(e, { uuid: file.uuid, dropType: 'default' })}
    >
      <Box display="inline-flex" alignItems="center" >
        <InsertDriveFileIcon />
        <Typography sx={{ ml: 0.5 }}>{file.name}</Typography>
      </Box>
      <Box display="flex" visibility={enabledDragAndDrop ? 'hidden' : 'unset'}>
        {file.id && (
          <Tooltip title={t(`${FILE_TREE_TOOLTIPS_TRANSLATE_KEY}.openFile`)}>
            <IconButton size="small" onClick={() => handleRedirectToFile(file)}>
              <OpenInNewIcon
                fontSize="small"
                color={isError ? 'error' : 'inherit'}
              />
            </IconButton>
          </Tooltip>
        )}
        {canManage && isEditable && !isUploading && (
          <>
            {onEditRole && (
              <Tooltip
                title={t(`${FILE_TREE_TOOLTIPS_TRANSLATE_KEY}.editRoles`)}
              >
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleFileRolesPopup();
                  }}
                >
                  <RuleIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip
              title={t(`${FILE_TREE_TOOLTIPS_TRANSLATE_KEY}.deleteFile`)}
            >
              <IconButton size="small" onClick={() => onDelete(file)}>
                <DeleteIcon
                  fontSize="small"
                  color={isError ? 'error' : 'inherit'}
                />
              </IconButton>
            </Tooltip>
          </>
        )}
      </Box>
      {isUploading && !enabledDragAndDrop && (
        <UploadingProgress size={16} disableShrink thickness={3} />
      )}
      <CreateEditRolesPopup
        isVisible={isVisibleFileRolesPopup}
        onHide={hideFileRolesPopup}
        onFinish={handleEditRoleOnFile}
        fileRoles={file.roles}
      />
    </FileBox>
  );
};

export default File;
