import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { IApiError } from '../../../../utils/axios';
import {
  importJobs as importJobsApi,
  IMPORT_JOBS_KEY
} from '../../utils/api';

const STUDENTS_BASE_KEY = 'cohorts.students.listAll.assignTutorsToStudentsModal';

const useAssignTutorsToStudents = ({ schoolId, cohortId }) => {
  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const { mutate: assignTutorsToStudents, isLoading: isAssignTutorsToStudents } = useMutation(
    importJobsApi,
    {
      mutationKey: IMPORT_JOBS_KEY,
      onSuccess: () => {
        enqueueSnackbar(t(`${STUDENTS_BASE_KEY}.successMessage`), {
          variant: 'success'
        });
      },
      onError: (error: IApiError) => {
        const usersThatAreMissing = error?.data?.errors?.missingEmails;
        let errorMessage = t(`${STUDENTS_BASE_KEY}.invalidData`);

        if (!!usersThatAreMissing) {
          const filterUsersThatAreMissing = usersThatAreMissing
            .slice(0, 9)
            .map((item) => item)
            .join(', ');

          const numberOfUsersThatAreMissing = usersThatAreMissing.length;

          errorMessage =
            numberOfUsersThatAreMissing +
            t(`${STUDENTS_BASE_KEY}.errorMessage`) +
            filterUsersThatAreMissing;
        }

        enqueueSnackbar(errorMessage, {
          variant: 'error'
        });
      }
    }
  );

  return {
    assignTutorsToStudents,
    isAssignTutorsToStudents
  };
};

export default useAssignTutorsToStudents;
