import { IUser } from "src/models/user";


export const areSameUsers = (user: IUser, compareUser: IUser) =>
    user.id === compareUser.id

export const printUserName = (user?: IUser): string => {
    if (user?.firstName && user?.lastName) {
        return `${user.firstName} ${user.lastName}`;
    }

    return '';
};

export const userHasAnyRole = (user: IUser, roles: string[]): boolean => {
    if (!Boolean(user) || !Boolean(roles)) return false
    return Boolean(user.roles?.find(userRole => roles.includes(userRole.name)));
}

export const userHasAnyPermission = (user: IUser, permissions: string[]): boolean => {
    if (!Boolean(user) || !Boolean(permissions)) return false
    return Boolean(user.permissions?.find(userPermission => permissions.includes(userPermission.name)));
}



export const studentIsOnHold = (studentUser: IUser) => {
    return !!studentUser.userable.onHoldAt
}

export const studentIsInRedemption = (studentUser: IUser) => {
    return !!studentUser.userable.redemptionStartedAt
}

export const studentIsInRegularFlow = (studentUser: IUser) => {
    return !studentIsInRedemption(studentUser)
}

export const studentFlow = (studentUser: IUser) => {
    return studentUser.userable.flow
}