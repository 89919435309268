import { useQuery } from 'react-query';
import { IUser } from 'src/models/user';
import { IApiError } from '../../../../utils/axios';
import { fetchTutors, FETCH_TUTORS_KEY } from '../../utils/api';

const useFetchTutors = (schoolId: string, options?: any) => {
  const {
    isLoading: isFetchingTutors,
    error: errorFetchingTutors,
    data: tutors
  } = useQuery<IUser[], IApiError>(
    [FETCH_TUTORS_KEY, schoolId],
    () => fetchTutors(schoolId),
    options
  );

  return {
    fetchTutors,
    isFetchingTutors,
    errorFetchingTutors,
    tutors
  };
};

export default useFetchTutors;
