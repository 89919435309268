import { useEffect, useState } from 'react';
import groupBy from 'lodash/groupBy';
import { FileUploadingStatus } from '../components/FileTree/types';

export interface IDocumentUploadMeta {
  uuid?: string;
  id?: string;
  status?: FileUploadingStatus;
  type?: string;
}

export type DocumentUpload<T> = IDocumentUploadMeta & T;

export interface ILocalDocuments<T> {
  [type: string]: DocumentUpload<T>[];
}

export interface ILocalDocumentRequired {
  type: string;
  id: string;
}

const useDocumentAction = <T extends ILocalDocumentRequired>(
  documents: T[],
  types: string[]
) => {
  const [localDocuments, setLocalDocuments] = useState<ILocalDocuments<T>>(
    () => {
      return types.reduce((prevValue, currentType) => {
        prevValue[currentType] = [];
        return prevValue;
      }, {});
    }
  );

  const handleChange = (type: string, files: DocumentUpload<T>[]) => {
    setLocalDocuments((allFiles) => ({
      ...allFiles,
      [type]: files
    }));
  };

  const handleSuccessfullyDeleted = (file: T) => {
    setLocalDocuments((localDocuments) => ({
      ...localDocuments,
      [file.type]: localDocuments[file.type].filter(
        (item) => item.id !== file.id
      )
    }));
  };

  useEffect(() => {
    if (documents?.length) {
      setLocalDocuments((localDocuments) => ({
        ...localDocuments,
        ...groupBy(documents, 'type')
      }));
    }
  }, [documents]);

  return {
    localDocuments,
    setLocalDocuments,
    handleChange,
    handleSuccessfullyDeleted
  };
};

export default useDocumentAction;
