import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { ITask, ITaskMeta } from '../../../../models/task';
import { IApiError } from '../../../../utils/axios';
import { TaskStatuses } from '../../../../utils/const';
import { isBeforeNow } from '../../../../utils/dates';
import {
  fetchTaskByCodeAndMeta,
  FETCH_TASK_BY_CODE_AND_META_KEY
} from '../../utils/api';
import { TaskCodes } from '../../utils/types';

export interface IFetchTaskOverdueResponse {
  overdue: boolean;
}

export interface IFetchTaskOverdueParams {
  code: TaskCodes | string;
  meta: ITaskMeta;
}

const useTaskOverdue = (params: IFetchTaskOverdueParams, options?: any) => {
  const {
    isLoading: isFetchingTask,
    error: errorFetchingTask,
    data: task
  } = useQuery<ITask, IApiError>(
    [FETCH_TASK_BY_CODE_AND_META_KEY, params],
    () => fetchTaskByCodeAndMeta(params),
    options
  );

  const { isTaskOverdue, isTaskExpired } = useMemo(() => {
    if (task) {
      return {
        isTaskOverdue: task?.status === TaskStatuses.Overdue,
        isTaskExpired: isBeforeNow(task.dueDate)
      };
    }
    return {
      isTaskOverdue: undefined,
      isTaskExpired: undefined
    };
  }, [task]);

  return {
    isFetchingTask,
    isTaskOverdue,
    isTaskExpired,
    errorFetchingTask,
    task
  };
};

export default useTaskOverdue;
