import { ICertificate } from 'src/models/certificate';
import { IStudent } from 'src/models/student';
import { ITask } from 'src/models/task';
import { IUser } from 'src/models/user';
import { axiosInt } from 'src/utils/axios';
import { IPaginateData } from 'src/utils/types';
import { ICreateTaskManualForm, IFetchTaskParams } from './types';

export const FETCH_TASKS_KEY = 'fetchTasks';
export const fetchTasks = async (
  params: IFetchTaskParams
): Promise<IPaginateData<ITask>> => {
  const response = await axiosInt.get(`/tasks`, {
    params
  });
  return response.data;
};

export const FETCH_TASK_KEY = 'fetchTask';
export const fetchTask = async (id: string): Promise<ITask> => {
  const response = await axiosInt.get(`/tasks/${id}`);
  return response.data;
};

export const FETCH_TASK_BY_CODE_AND_META_KEY = 'fetchTaskByCodeAndMeta';
export const fetchTaskByCodeAndMeta = async (params: any) => {
  const response = await axiosInt.get('/tasks/find-by-code-and-meta', {
    params
  });
  return response.data;
};

export const FETCH_TASKS_CREATED_BY_ME_KEY = 'fetchTasksCreatedByMe';
export const fetchTasksCreatedByMe = async (
  params: IFetchTaskParams
): Promise<IPaginateData<ITask>> => {
  const response = await axiosInt.get('/tasks/created-by-me', {
    params
  });
  return response.data;
};

export const UPDATE_TASK_STATUS_KEY = 'updateTaskStatus';
export const updateTaskStatus = async (
  taskId: string,
  status: string
): Promise<ITask> => {
  const response = await axiosInt.put(`/tasks/${taskId}/status`, {
    status: status
  });

  return response.data;
};

export const FETCH_USER_CERTIFICATES_KEY = 'fetchUserCertificates';
export const fetchUserCertificates = async (): Promise<ICertificate[]> => {
  const response = await axiosInt.get('/user-certificates');
  return response.data;
};

export const FETCH_USERS_COHORT_KEY = 'fetchUsersCohort';
export const fetchUsersCohort = async (
  cohortId: string,
  roleId: string
): Promise<IUser[]> => {
  const response = await axiosInt.get(
    `/cohorts/${cohortId}/roles/${roleId}/users`
  );
  return response.data;
};

export const CREATE_TASK_MANUAL_KEY = 'createTaskManual';
export const createTaskManual = async (
  data: ICreateTaskManualForm
): Promise<ITask> => {
  const response = await axiosInt.post(`/tasks`, data);
  return response.data;
};

export const FETCH_WHERE_JURY_UNASSIGNED_KEY = 'fetchWhereJuryUnassigned';
export const fetchWhereJuryUnassigned = async ({
  cohortId,
  schoolId,
  juryType
}): Promise<IStudent> => {
  let url = ``;
  if (schoolId !== 'undefined') {
    url = `/get-student-where-jury-unassigned?cohort_id=${cohortId}&school_id=${schoolId}&jury_type=${juryType}`;
  } else {
    url = `/get-student-where-jury-unassigned?cohort_id=${cohortId}&jury_type=${juryType}`;
  }
  const response = await axiosInt.get(url);
  return response.data;
};
