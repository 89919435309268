import { Typography } from '@mui/material';

const PageTitle = ({ color = '', children }) => {
  return (
    <Typography variant="h2" gutterBottom component="h2" color={color}>
      {children}
    </Typography>
  );
};

export default PageTitle;
