import { Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IApiError } from '../../utils/axios';

interface IProps {
  error: IApiError;
  [prop: string]: any;
}

const ApiError: React.FC<IProps> = ({ error, ...props }) => {
const { t } = useTranslation();
  return (
    <Alert severity="error" {...props}>
      {t(error.message)}
    </Alert>
  );
};

export default ApiError;
