import {
    Box,
    Checkbox,
    FormControl,
    Grid,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@mui/material';
import { useFormikContext, ErrorMessage } from 'formik';
import { useTranslation } from 'react-i18next';
import { IJuryDecision } from 'src/models/jury';
import { IStudentBlock } from 'src/models/student-block';
import SelectChip from 'src/features/evaluations/components/common/SelectChip';
import { StudentCertificationStatus } from 'src/utils/const';

enum GlobalRecommendationStatusMissing {
    Missing = 'missing'
}

const JuryDecisionFormFields: React.FC<{ studentBlocks?: IStudentBlock[], isEditing: boolean }> = ({ studentBlocks, isEditing }) => {

    const { t } = useTranslation();
    const {
        touched,
        errors,
        handleBlur,
        handleChange,
        values,
    } = useFormikContext<IJuryDecision>();

    return (
        <div>
            <Grid container spacing={2} sx={{ mt: 3 }}>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel id="juryDecisionLabel">{t('studentBlock.jury.juryDecisionOptionsLabel')}</InputLabel>
                        <Select
                            sx={{ mb: 2 }}
                            labelId="juryDecisionLabel"
                            id="juryDecision"
                            value={values.juryDecision}
                            error={Boolean(touched.juryDecision && errors.juryDecision)}
                            fullWidth
                            label={t('studentBlock.jury.juryDecisionOptionsLabel')}
                            placeholder={t('studentBlock.jury.juryDecisionOptionsLabel')}
                            onChange={handleChange}
                            autoWidth
                            onBlur={handleBlur}
                            variant="outlined"
                            name="juryDecision"
                        >
                            <MenuItem
                                value={StudentCertificationStatus.FullDiploma}>
                                {t(`studentBlock.jury.juryDecisionOptions.${StudentCertificationStatus.FullDiploma}`)}
                            </MenuItem>
                            <MenuItem
                                value={StudentCertificationStatus.PartialDiploma}>
                                {t(`studentBlock.jury.juryDecisionOptions.${StudentCertificationStatus.PartialDiploma}`)}
                            </MenuItem>
                            <MenuItem
                                value={StudentCertificationStatus.NoDiploma}>
                                {t(`studentBlock.jury.juryDecisionOptions.${StudentCertificationStatus.NoDiploma}`)}
                            </MenuItem>
                        </Select>

                    </FormControl>
                    {values.juryDecision === 'partial_diploma' && isEditing &&
                        <FormControl fullWidth>
                            <InputLabel id="passedStudentBlocksLabel">{t('studentBlock.jury.juryPartialDiplomaSelectBlocksLabel')}</InputLabel>
                            <Select
                                sx={{ mb: 2, overflow: "hidden" }}
                                labelId="passedStudentBlocksLabel"
                                id="passedStudentBlocksLabel"
                                value={values.passedStudentBlocks}
                                error={Boolean(touched.passedStudentBlocks && errors.passedStudentBlocks)}
                                label={t('studentBlock.jury.juryDecisionOptionsLabel')}
                                placeholder={t('studentBlock.jury.juryDecisionOptionsLabel')}
                                onChange={handleChange}
                                multiple
                                autoWidth
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value, index) => (
                                            <SelectChip
                                                value={value}
                                                name="passedStudentBlocks"
                                                key={`visibleTo-${value}-${index}`}
                                                label={studentBlocks.filter(block => block.id === value)[0].block.name}
                                            />
                                        ))}
                                    </Box>
                                )}
                                onBlur={handleBlur}
                                variant="outlined"
                                name="passedStudentBlocks"
                            >
                                {studentBlocks.map((block: any) => (
                                    <MenuItem
                                        key={block.id}
                                        value={block.id}>
                                        <Checkbox checked={values.passedStudentBlocks.includes(block.id)} />
                                        <ListItemText primary={block.block.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                            <ErrorMessage
                                name='passedStudentBlocks'
                                render={msg =>
                                    <Typography color="error" sx={{ marginLeft: 2, fontWeight: 'bold' }}>
                                        {msg}
                                    </Typography>} />
                        </FormControl>
                    }
                    <TextField
                        sx={{ mt: 0 }}
                        error={Boolean(touched.juryComment && errors.juryComment)}
                        fullWidth
                        margin="normal"
                        helperText={touched.juryComment && errors.juryComment}
                        label={'Decision explanation'}
                        placeholder={'Decision explanation'}
                        name="juryComment"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.juryComment}
                        variant="outlined"
                        multiline
                        minRows={4}
                        maxRows={4}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

export default JuryDecisionFormFields;