import { List } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CompanyRoutes } from 'src/features/companies/routes';
import { CohortRoutes } from 'src/features/cohorts/routes';
import { TaskRoutes } from 'src/features/tasks/routes';
import SidebarMenuItem from '../AccentSidebarLayout/Sidebar/SidebarMenu/item';
import { SchoolCompaniesEmployeesRoutesPaths } from 'src/features/company-employees/route';
import { MenuWrapper, SubMenuWrapper } from '../components/common';
import useCan from 'src/hooks/useCan';
import { Permissions } from 'src/utils/const';

function SchoolLayout() {
  const { t } = useTranslation();

  const canReadCompanyStaff = useCan(Permissions.ReadCompanyStaff)
  
  return (<>
    <MenuWrapper>
      <SubMenuWrapper>
        <List component="div">
          <SidebarMenuItem
            active={true}
            open={true}
            name={t(`common.layout.sidebar.tasks`)}
            link={TaskRoutes.Tasks}
          />
          <SidebarMenuItem
            active={true}
            open={true}
            name={t(`common.layout.sidebar.companies`)}
            link={CompanyRoutes.ListAll}
          />
          {canReadCompanyStaff &&
            <SidebarMenuItem
              active={true}
              open={true}
              name={t(`companyEmployees.schoolCompaniesEmployees.title`)}
              link={SchoolCompaniesEmployeesRoutesPaths.ListAll}
            />}
          <SidebarMenuItem
            active={true}
            open={true}
            name={t(`common.layout.sidebar.assignedCohorts`)}
            link={CohortRoutes.ListAssigned}
          />
        </List>
      </SubMenuWrapper>
    </MenuWrapper>
  </>);
}

export default SchoolLayout;