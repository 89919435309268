import React, { useState } from 'react';
import { ISort, Order } from '../utils/types';
import { debounce } from 'lodash';

export const TABLE_ROWS_PER_PAGE = 20;

export interface ITableParams {
  page: number;
  perPage: number;
  sort: ISort;
  filters?: any;
  search?: string;
}

export interface ITableApiParams {
  page: number;
  per_page: number;
  order_by?: string;
  ordering?: Order;
  search?: string;
  [key: string]: any
}

export const isAscending = (columnName: string, sort?: ISort) => {
  return sort?.fieldName === columnName && sort?.order === Order.Asc;
};

export const isDescending = (columnName: string, sort?: ISort) => {
  return sort?.fieldName === columnName && sort?.order === Order.Asc;
};

export const isActive = (columnName: string, sort?: ISort) => {
  return sort?.fieldName === columnName;
};

export const mapToApiCallParams = (tableParams: Partial<ITableParams>) => {
  const params: ITableApiParams = {
    page: tableParams.page || 1,
    per_page: tableParams.perPage || TABLE_ROWS_PER_PAGE,
    search: tableParams.search || ''
  };
  if (tableParams.sort) {
    params.order_by = tableParams.sort.fieldName;
    params.ordering = tableParams.sort.order;
  }

  if (tableParams.filters) {
    Object.keys(tableParams.filters).forEach(function (key) {
      params[key] = tableParams.filters[key];
    });
  }

  return params;
};

const useTableParams = (initialParams: Partial<ITableParams> = {}) => {
  const [tableParams, setTableParams] = useState<ITableParams>({
    search: '',
    page: 1,
    perPage: TABLE_ROWS_PER_PAGE,
    sort: null,
    filters: {},
    ...initialParams
  });

  const handleChangePage = (page: number) => {
    setTableParams((prev) => ({ ...prev, page }));
  };

  const handleChangeSort = (columnName: string) => {
    let newSort: ISort = { fieldName: columnName, order: Order.Asc };
    if (isActive(columnName, tableParams.sort)) {
      newSort = isAscending(columnName, tableParams.sort)
        ? { fieldName: columnName, order: Order.Desc }
        : null;
    }
    setTableParams((prev) => ({ ...prev, sort: newSort }));
  };

  const handleChangeFilters = (filters: any) => {
    setTableParams((prev) => ({ ...prev, filters }));
  };

  const handleChangeSearchedTerm = React.useMemo(
    () =>
      debounce(
        (searchText) =>
          setTableParams((prev) => ({ ...prev, search: searchText })),
        1000
      ),
    []
  );

  return {
    tableParams,
    onChangePage: handleChangePage,
    onChangeSort: handleChangeSort,
    onChangeFilters: handleChangeFilters,
    onChangeSearchedTerm: handleChangeSearchedTerm
  };
};

export default useTableParams;
