import { Grid, TextField, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { IUpdateStudentPersonalInfo } from '../../../utils/types';
import DatePicker from '@mui/lab/DatePicker';
import { DateFormat } from 'src/utils/dates';
import { ContractTypes, LastDiplomaLevel } from 'src/utils/const';

const FORM_BASE_KEY = 'profile.common.personalInfoForm';

const StudentPersonalInfoFields = () => {
  const {
    setFieldTouched,
    setFieldValue,
    touched,
    errors,
    handleBlur,
    handleChange,
    values,
    initialValues
  } = useFormikContext<IUpdateStudentPersonalInfo>();

  const { t } = useTranslation();

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <TextField
            error={Boolean(touched.group && errors.group)}
            fullWidth
            margin="normal"
            helperText={touched.group && errors.group}
            label={t(`${FORM_BASE_KEY}.group.label`)}
            placeholder={t(`${FORM_BASE_KEY}.group.placeholder`)}
            name="group"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.group}
            variant="outlined"
          />


          <DatePicker
            inputFormat={DateFormat.Date}
            label={t(`${FORM_BASE_KEY}.dateOfBirth.label`)}
            onChange={(value) => { setFieldValue('dateOfBirth', value === '-' ? '' : value, true) }}
            value={values.dateOfBirth}
            renderInput={(params) => (
              <TextField
                disabled
                {...params}
                error={Boolean(touched.dateOfBirth && errors.dateOfBirth)}
                fullWidth
                margin="normal"
                helperText={touched.dateOfBirth && errors.dateOfBirth}
                label={t(`${FORM_BASE_KEY}.dateOfBirth.label`)}
                placeholder={t(`${FORM_BASE_KEY}.dateOfBirth.placeholder`)}
                name="dateOfBirth"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.dateOfBirth}
                variant="outlined"
              />
            )}
          />
          <TextField
            error={Boolean(touched.placeOfBirth && errors.placeOfBirth)}
            fullWidth
            margin="normal"
            helperText={touched.placeOfBirth && errors.placeOfBirth}
            label={t(`${FORM_BASE_KEY}.placeOfBirth.label`)}
            placeholder={t(`${FORM_BASE_KEY}.placeOfBirth.placeholder`)}
            name="placeOfBirth"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.placeOfBirth}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.postalCodeOfBirth && errors.postalCodeOfBirth)}
            fullWidth
            margin="normal"
            helperText={touched.postalCodeOfBirth && errors.postalCodeOfBirth}
            label={t(`${FORM_BASE_KEY}.postalCodeOfBirth.label`)}
            placeholder={t(`${FORM_BASE_KEY}.postalCodeOfBirth.placeholder`)}
            name="postalCodeOfBirth"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.postalCodeOfBirth}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.countryOfBirth && errors.countryOfBirth)}
            fullWidth
            margin="normal"
            helperText={touched.countryOfBirth && errors.countryOfBirth}
            label={t(`${FORM_BASE_KEY}.countryOfBirth.label`)}
            placeholder={t(`${FORM_BASE_KEY}.countryOfBirth.placeholder`)}
            name="countryOfBirth"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.countryOfBirth}
            variant="outlined"
          />



          <TextField
            error={Boolean(touched.addressOfResidence && errors.addressOfResidence)}
            fullWidth
            margin="normal"
            helperText={touched.addressOfResidence && errors.addressOfResidence}
            label={t(`${FORM_BASE_KEY}.addressOfResidence.label`)}
            placeholder={t(`${FORM_BASE_KEY}.addressOfResidence.placeholder`)}
            name="addressOfResidence"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.addressOfResidence}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.placeOfResidence && errors.placeOfResidence)}
            fullWidth
            margin="normal"
            helperText={touched.placeOfResidence && errors.placeOfResidence}
            label={t(`${FORM_BASE_KEY}.placeOfResidence.label`)}
            placeholder={t(`${FORM_BASE_KEY}.placeOfResidence.placeholder`)}
            name="placeOfResidence"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.placeOfResidence}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.postalCodeOfResidence && errors.postalCodeOfResidence)}
            fullWidth
            margin="normal"
            helperText={touched.postalCodeOfResidence && errors.postalCodeOfResidence}
            label={t(`${FORM_BASE_KEY}.postalCodeOfResidence.label`)}
            placeholder={t(`${FORM_BASE_KEY}.postalCodeOfResidence.placeholder`)}
            name="postalCodeOfResidence"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.postalCodeOfResidence}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.countryOfResidence && errors.countryOfResidence)}
            fullWidth
            margin="normal"
            helperText={touched.countryOfResidence && errors.countryOfResidence}
            label={t(`${FORM_BASE_KEY}.countryOfResidence.label`)}
            placeholder={t(`${FORM_BASE_KEY}.countryOfResidence.placeholder`)}
            name="countryOfResidence"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.countryOfResidence}
            variant="outlined"
          />


          <FormControl fullWidth margin="normal">
            <InputLabel id="contract-type-select-label">
              {t(`${FORM_BASE_KEY}.contractType.label`)}
            </InputLabel>
            <Select
              labelId='contract-type-select-label'
              error={Boolean(touched.contractType && errors.contractType)}
              fullWidth
              label={t(`${FORM_BASE_KEY}.contractType.label`)}
              placeholder={t(`${FORM_BASE_KEY}.contractType.placeholder`)}
              name="contractType"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.contractType}
              variant="outlined"
            >
              <MenuItem key={'empty'} value="">-</MenuItem>
              {Object.entries(ContractTypes).map(([key, value]) => (
                <MenuItem key={key} value={value}>{t(`profile.professionalInfo.contractTypeOptions.${value}`)} </MenuItem>
              ))}
            </Select>
            <FormHelperText error>
              {touched.contractType && errors.contractType}
            </FormHelperText>
          </FormControl>
          <TextField
            error={Boolean(touched.lastDiploma && errors.lastDiploma)}
            fullWidth
            margin="normal"
            helperText={touched.lastDiploma && errors.lastDiploma}
            label={t(`${FORM_BASE_KEY}.lastDiploma.label`)}
            placeholder={t(`${FORM_BASE_KEY}.lastDiploma.placeholder`)}
            name="lastDiploma"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.lastDiploma}
            variant="outlined"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel id="level-of-last-diploma-select-label">
              {t(`${FORM_BASE_KEY}.levelOfLastDiploma.label`)}
            </InputLabel>
            <Select
              labelId='level-of-last-diploma-select-label'
              error={Boolean(touched.levelOfLastDiploma && errors.levelOfLastDiploma)}
              fullWidth
              label={t(`${FORM_BASE_KEY}.levelOfLastDiploma.label`)}
              placeholder={t(`${FORM_BASE_KEY}.levelOfLastDiploma.placeholder`)}
              name="levelOfLastDiploma"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.levelOfLastDiploma}
              variant="outlined"
            >
              <MenuItem key={'empty'} value="">-</MenuItem>
              {Object.entries(LastDiplomaLevel).map(([key, value]) => (
                <MenuItem key={key} value={value}> {t(`profile.professionalInfo.levelOfLastDiplomaOptions.${value}`)} </MenuItem>
              ))}
            </Select>
            <FormHelperText error>
              {touched.levelOfLastDiploma && errors.levelOfLastDiploma}
            </FormHelperText>
          </FormControl>

        </Grid>
      </Grid>
    </div>
  );
};

export default StudentPersonalInfoFields;
