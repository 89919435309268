import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { IApiError } from '../../../../utils/axios';
import {
  assignTutor as assignTutorApi,
  ASSIGN_TUTOR_KEY
} from '../../utils/api';

const ASSIGN_TUTOR_TRANSLATE_KEY = 'profile.student.assignTutor';

const useAssignTutor = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: assignTutor, isLoading: isAssigningTutor } = useMutation(
    assignTutorApi,
    {
      mutationKey: ASSIGN_TUTOR_KEY,
      onSuccess: () => {
        enqueueSnackbar(t(`${ASSIGN_TUTOR_TRANSLATE_KEY}.successMessage`), {
          variant: 'success'
        });
      },
      onError: (error: IApiError) => {
        enqueueSnackbar(error.message, {
          variant: 'error'
        });
      }
    }
  );

  return {
    assignTutor,
    isAssigningTutor
  };
};

export default useAssignTutor;
