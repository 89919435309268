import { useMemo } from 'react';
import useAuth from 'src/features/auth/hooks/useAuth';

const useCan = (permission?: string, checks?: () => boolean) => {

  const { user } = useAuth()
  
  const { permissions } = user
  const allowed = useMemo(() => {
    if (!permissions) return false;

    let isAllowed = true;
    if (permission) {
      isAllowed = isAllowed && !!permissions.find((p) => p.name === permission);
    }
    if (checks) {
      isAllowed = isAllowed && checks();
    }
    return isAllowed;
  }, [permission, permissions, checks]);

  return allowed;
};

export default useCan;
