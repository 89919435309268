import {
  Box,
  Card,
  CircularProgress,
  styled,
  TableContainer as MuiTableContainer,
  Pagination,
  Typography
} from '@mui/material';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { TABLE_ROWS_PER_PAGE } from '../../../hooks/useTableParams';

const TableCard = styled(Card)(
  ({ theme }) => `
    margin-top: ${theme.spacing(3)};
    padding: ${theme.spacing(3)};
    position: relative;
    .MuiPagination-ul {
      justify-content: center;
      margin-top: ${theme.spacing(3)};
    }
    .MuiTableCell-head {
      padding-top: ${theme.spacing(1)};
      padding-bottom: ${theme.spacing(1)};
    }
`
);

const TableLoader = () => {
  return (
    <Box
      sx={{
        position: 'absolute',
        left: 'calc(50% - 16px)',
        top: '40%'
      }}
    >
      <CircularProgress size={32} thickness={3} />
    </Box>
  );
};

const EmptyTableMessage = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
`;

interface IProps {
  children: ReactNode;
  isFetching: boolean;
  total?: number;
  currentPage?: number;
  showPagination?: boolean;
  perPage?: number;
  emptyMessage?: string;
  onChangePage?(page: number): void;
}

const TableContainer: React.FC<IProps> = ({
  children,
  isFetching,
  total,
  currentPage,
  showPagination,
  perPage,
  emptyMessage,
  onChangePage
}) => {
  const { t } = useTranslation();

  const message = emptyMessage || t('common.table.emptyMessage');

  const isEmpty = total === 0;

  return (
    <TableCard>
      {isFetching && <TableLoader />}
      <MuiTableContainer>{children}</MuiTableContainer>
      {isEmpty && (
        <EmptyTableMessage>
          <Typography>{message}</Typography>
        </EmptyTableMessage>
      )}
      {showPagination && !isEmpty && (
        <Pagination
          count={Math.ceil(total / (perPage || TABLE_ROWS_PER_PAGE))}
          page={currentPage}
          onChange={(_, page) => {
            if (onChangePage) {
              onChangePage(page);
            }
          }}
        />
      )}
    </TableCard>
  );
};

TableContainer.defaultProps = {
  showPagination: true
};

export default TableContainer;
