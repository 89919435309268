import { lazy } from 'react';
import SuspenseRouteLoader from '../../components/SuspenseRouteLoader';

// const CompanyEmployees = SuspenseRouteLoader(lazy(() => import('../pages/CompanyEmployees')));

export const CompanyEmployeesRoutePaths = {
  ListAll: '/company-employees',
};

export const CompanyEmployeesRoutes = [
  // {
  //   path: CompanyEmployeesRoutePaths.ListAll,
  //   element: <CompanyEmployees />
  // }
];

const SchoolCompaniesEmployees = SuspenseRouteLoader(lazy(() => import('./pages/SchoolCompaniesEmployees')));

export const SchoolCompaniesEmployeesRoutesPaths = {
  ListAll: '/companies-employees'
};

export const SchoolCompaniesEmployeesRoutes = [
  {
    path: SchoolCompaniesEmployeesRoutesPaths.ListAll,
    element: <SchoolCompaniesEmployees />
  }
];
