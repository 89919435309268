import { axiosInt } from '../../../utils/axios';
import { INotification } from 'src/models/notification';
import { ITableApiParams } from 'src/hooks/useTableParams';
import { IPaginateData } from 'src/utils/types';

export const FETCH_NOTIFICATIONS = 'fetchNotifications';
export const fetchNotifications = async (
  params?: Partial<ITableApiParams>
): Promise<IPaginateData<INotification>> => {
  const response = await axiosInt.get(`/notifications`, { params });
  return response.data;
};

export const UPDATE_NOTIFICATION = 'updateNotification';
export const updateNotification = async (
  notification: INotification
): Promise<INotification> => {
  const response = await axiosInt.put(`/notifications/${notification.id}`, {
    isRead: notification.isRead
  });
  return response.data;
};

export const FETCH_NUMBER_OF_UNREAD_NOTIFICATIONS =
  'fetchNumberOfUnreadNotifications';
export const fetchNumberOfUnreadNotifications = async (): Promise<number> => {
  const response = await axiosInt.get(`/notifications/unread-notifications`);
  return response.data;
};

export const MARK_ALL_READ = 'markAllRead';
export const markAllRead = async (): Promise<INotification> => {
  const response = await axiosInt.post(`/notifications/mark-all-read`);
  return response.data;
};

export const FETCH_NOTIFICATION = 'fetchNotification';
export const fetchNotification = async (id: string): Promise<INotification> => {
  const response = await axiosInt.get(`/notifications/${id}`);
  return response.data;
};
