import { useQuery } from 'react-query';
import {
  ITableParams,
  mapToApiCallParams
} from '../../../../hooks/useTableParams';
import { IBaseEvaluationConfiguration } from '../../../../models/evaluation-configuration';
import { IApiError } from '../../../../utils/axios';
import { IPaginateData } from '../../../../utils/types';
import {
  fetchStudentEvaluationConfigurations,
  FETCH_STUDENT_EVALUATION_CONFIGURATIONS_KEY
} from '../../utils/api';

const useFetchStudentEvaluationConfigurations = (
  studentId: string,
  params: ITableParams,
  options?: any
) => {
  const {
    isLoading: isFirstFetchingStudentEvaluationConfigurations,
    error: errorFetchingStudentEvaluationConfigurations,
    data: studentEvaluationConfigurationsData,
    isFetching: isFetchingStudentEvaluationConfigurations,
    isPreviousData: isPreviousStudentEvaluationConfigurations,
    refetch: refetchStudentEvaluationConfigurations
  } = useQuery<IPaginateData<IBaseEvaluationConfiguration>, IApiError>(
    [FETCH_STUDENT_EVALUATION_CONFIGURATIONS_KEY, studentId, params],
    () => fetchStudentEvaluationConfigurations(studentId, mapToApiCallParams(params)),
    {
      ...(options || {}),
      keepPreviousData: true
    }
  );

  return {
    isFirstFetchingStudentEvaluationConfigurations,
    errorFetchingStudentEvaluationConfigurations,
    studentEvaluationConfigurationsData,
    isFetchingStudentEvaluationConfigurations,
    isPreviousStudentEvaluationConfigurations,
    refetchStudentEvaluationConfigurations
  };
};

export default useFetchStudentEvaluationConfigurations;
