import {
  Grid,
  Paper,
  Typography,
  Divider,
  CardContent,
  IconButton
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import { Mode } from 'src/utils/types';
import { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { IUser } from 'src/models/user';
import { useQueryClient } from 'react-query';
import { FETCH_STUDENT_KEY } from 'src/features/profile/utils/api';
import useAuth from 'src/features/auth/hooks/useAuth';
import useUpdateStudentPersonalInfo from 'src/features/profile/hooks/api/useUpdateStudentPersonalInfo';
import StudentPersonalInfoDetails from './StudentPersonalInfoDetails';
import { IUpdateStudentPersonalInfo } from 'src/features/profile/utils/types';
import { DateFormat, formatDate } from 'src/utils/dates';
import { studentIsOnHold } from 'src/utils/userHelpers';

const PROFILE_PERSONAL_INFO_TRANSLATE_KEY =
  'profile.professionalInfo.professionalInfoSection';

interface IProps {
  user: IUser;
  url: string;
  isProfile: boolean;
  canManage: boolean;
  mode?: Mode
}

const StudentPersonalInfoSection: React.FC<IProps> = ({
  user,
  url,
  isProfile,
  canManage,
  mode
}) => {
  const { t } = useTranslation();
  const [personalInfoLocalMode, setPersonalInfoLocalMode] = useState(mode ?? Mode.View);
  const { isUpdatingStudentPersonalInfo, updateStudentPersonalInfo } =
    useUpdateStudentPersonalInfo();

  const { updateProfile } = useAuth();
  const queryClient = useQueryClient();

  const handleEdit = (data: IUpdateStudentPersonalInfo) => {

    data.dateOfBirth = !data.dateOfBirth ? null : formatDate(data.dateOfBirth, DateFormat.DateDb);
    updateStudentPersonalInfo(
      { data, url },
      {
        onSuccess: (data) => {
          if (isProfile) {
            updateProfile(data);
          } else {
            queryClient.invalidateQueries([FETCH_STUDENT_KEY]);
          }
          setPersonalInfoLocalMode(Mode.View);
        }
      }
    );
  };
  return (
    <Grid container spacing-xs={3} sx={{ mb: 3 }}>
      <Grid item xs={12}>
        <Paper
          elevation={1}
          square={false}
          sx={{ borderRadius: '8px', padding: 0 }}
        >
          <Box
            sx={{
              padding: '24px 32px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              position: 'relative'
            }}
          >
            <Box>
              <Typography
                variant="h4"
                gutterBottom
                sx={{ fontWeight: 700, fontSize: '14px' }}
              >
                {t(`${PROFILE_PERSONAL_INFO_TRANSLATE_KEY}.title`)}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{ fontWeight: 400, fontSize: '15px' }}
              >
                {t(`${PROFILE_PERSONAL_INFO_TRANSLATE_KEY}.subtitle`)}
              </Typography>
            </Box>
            {canManage && (
              <IconButton
                disabled={studentIsOnHold(user)}
                size="small"
                onClick={() => setPersonalInfoLocalMode(Mode.Edit)}
                color="primary"
              >
                <EditIcon fontSize="small" />
              </IconButton>
            )}
          </Box>
          <Divider />
          <CardContent sx={{ padding: '32px', paddingBottom: '24px' }}>
            <StudentPersonalInfoDetails
              handleEdit={handleEdit}
              personalInfoLocalMode={personalInfoLocalMode}
              isUpdatingPersonalInfo={isUpdatingStudentPersonalInfo}
              setPersonalInfoLocalMode={setPersonalInfoLocalMode}
              user={user}
            />
          </CardContent>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default StudentPersonalInfoSection;
