import { Alert, Box, FormControl, Grid, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { IUpdateCertifierPersonalInfo } from '../../../utils/types';
import { Button } from '@mui/material';
import useUploadCv from 'src/features/profile/hooks/api/useUploadCv';
import useStoreDocument from 'src/features/profile/hooks/api/useStoreDocument';
import { IFile } from 'src/models/file';
import { useState } from 'react';
import { useSnackbar } from 'notistack';

const FORM_BASE_KEY = 'profile.common.personalInfoForm';

interface IProps {
  storeCvUrl: string;
}
const CertifierEmployeePersonalInfoFields: React.FC<IProps> = (storeCvUrl) => {
  const {
    setFieldTouched,
    setFieldValue,
    touched,
    errors,
    handleBlur,
    handleChange,
    values
  } = useFormikContext<IUpdateCertifierPersonalInfo>();

  const { t } = useTranslation();

  const { uploadCv } = useUploadCv();
  const { storeDocument } = useStoreDocument();

  const [documentInfo, setDocumentInfo] = useState<IFile>();
  const { enqueueSnackbar } = useSnackbar();

  const setCvField = (url: string) => {
    setFieldValue('cv', url);
    setFieldTouched('cv');
  };
  const handleFileUpload = (event) => {
    uploadCv(event.currentTarget.files[0], {
      onSuccess: (data) => {
        handleStoreCv(data, storeCvUrl.storeCvUrl);
      }
    });
  };
  const handleStoreCv = (data, url) => {
    storeDocument(
      { data, url },
      {
        onSuccess: (data) => {
          setCvField(data.id);
          setDocumentInfo(data);
          enqueueSnackbar(t(`${FORM_BASE_KEY}.cv.success`), {
            variant: 'success'
          });
        }
      }
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <FormControl
          sx={{
            mb: 2
          }}
        >
          <Box>
            <input
              type="file"
              hidden
              id="upload-file"
              onChange={handleFileUpload}
            />
            <label htmlFor="upload-file">
              <Button variant="outlined" component="span">
                {values.cv === ''
                  ? t(`${FORM_BASE_KEY}.cv.placeholder`)
                  : t(`${FORM_BASE_KEY}.cv.placeholderReupload`)}
              </Button>
            </label>
          </Box>
          {documentInfo && (
            <Alert
              sx={{
                py: 0,
                mt: 2
              }}
              severity="success"
            >
              {t(`${FORM_BASE_KEY}.cv.uploaded`)}{' '}
              <strong>{documentInfo?.name}</strong>!
            </Alert>
          )}
        </FormControl>
        <TextField
          error={Boolean(touched.position && errors.position)}
          fullWidth
          margin="normal"
          helperText={touched.position && errors.position}
          label={t(`${FORM_BASE_KEY}.position.label`)}
          placeholder={t(`${FORM_BASE_KEY}.position.placeholder`)}
          name="position"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.position}
          variant="outlined"
        />
        <TextField
          error={Boolean(touched.officePhone && errors.officePhone)}
          fullWidth
          margin="normal"
          helperText={touched.officePhone && errors.officePhone}
          label={t(`${FORM_BASE_KEY}.officePhone.label`)}
          placeholder={t(`${FORM_BASE_KEY}.officePhone.placeholder`)}
          name="officePhone"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.officePhone}
          variant="outlined"
        />
      </Grid>
    </Grid>
  );
};

export default CertifierEmployeePersonalInfoFields;
