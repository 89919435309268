import {
    Box,
    CardContent,
    Divider,
    Grid,
    Paper,
    Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Label from "src/components/Label";
import InfoItem from "src/features/profile/components/profile/InfoItem";
import { StudentCertificationStatus } from "src/utils/const";

const MAP_COLOR_TO_STUDENT_STATUS = {
    [StudentCertificationStatus.PartialDiploma]: 'warning',
    [StudentCertificationStatus.FullDiploma]: 'success',
    [StudentCertificationStatus.NoDiploma]: 'error',
};

const StudentJuryDecisionSection = ({ title, juryDecision, juryComment }) => {
    const { t } = useTranslation();
    return (
        <Paper elevation={3} sx={{ marginBottom: 2 }}>
            <Box sx={{ margin: '24px 24px 10px 24px', position: 'relative' }}>
                <Typography variant="h4" gutterBottom sx={{ fontWeight: 700 }}>
                    {title}
                </Typography>
            </Box>
            <Divider />
            <CardContent>
                <Grid
                    container
                    sx={{
                        display: 'flex',
                        boxSizing: 'border-box',
                        flexFlow: 'flex-wrap',
                        width: '100%'
                    }}
                >
                    <InfoItem property={t(t(`studentBlock.jury.juryDecision`))}                    >
                        {juryDecision ?
                            <Label color={MAP_COLOR_TO_STUDENT_STATUS[juryDecision]}>
                                {t(`studentBlock.jury.juryDecisionOptions.${juryDecision}`)}
                            </Label> :
                            <Typography style={{ fontWeight: 'bold' }}>-</Typography>}
                    </InfoItem>
                    <InfoItem
                        property={t(`studentBlock.jury.juryDecisionComment`)}
                        value={juryComment}
                    />
                </Grid>

            </CardContent>
        </Paper>
    )
}

export default StudentJuryDecisionSection;