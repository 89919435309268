import { Chip } from '@mui/material';
import { useFormikContext } from 'formik';

interface IProps {
  value: string;
  name: string;
  label: string;
}

const SelectChip: React.FC<IProps> = ({ value, name, label }) => {
  const { setFieldValue, values, setFieldTouched } = useFormikContext();

  return (
    <Chip
    sx={{overflow:'hidden'}}
      onMouseDown={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      label={label}
      onDelete={() => {
        setFieldValue(
          name,
          values[name].filter((v) => v !== value)
        );
        setTimeout(() => setFieldTouched(name), 500);
      }}
    />
  );
};

export default SelectChip;
