import {
  SchoolCourseDocumentType,
  TriggerType
} from '../utils/types';
import {
  StudentCertificationStatus,
  CertificationStatus,
  VerificationStatus
} from "src/utils/const";

const cohorts = {
  common: {
    tabs: {
      cohortInfoTab: 'Cohort info',
      tasksTab: 'Tasks',
      schoolsTab: 'Schools',
      certificationTab: 'Main Professional expertise report',
      redemptionTab: 'Redemption Professional expertise report'
    },
    cohortForm: {
      certificate: {
        label: 'Certificate',
        placeholder: 'Select certificate'
      },
      name: {
        label: 'Cohort Name',
        placeholder: 'Write cohort name here...'
      },
      shortName: {
        label: 'Cohort Abbreviation',
        placeholder: 'Write cohort abbrevation here...'
      },
      description: {
        label: 'Description',
        placeholder: 'Write cohort description here...'
      },
      avatar: {
        label: 'Cohort logo',
        placeholder: 'Upload cohort logo'
      },
      yearOfCertification: {
        label: 'Year of certification',
        placeholder: 'Write year of certification'
      }
    },
    tasks: {
      boxTitle: 'CERTIFICATE TASKS',
      noTaskAdded: 'No added tasks',
      addTask: 'Add new cohort task',
      rowLabels: {
        name: 'Task name',
        deadline: 'Due date / Days to complete',
        trigger: 'Trigger'
      },
      deadline: {
        title: 'Edit task configuration',
        subtitle1: 'Edit <1>{{name}}</1> configuration',
        tabs: {
          tab1: 'Fixed',
          tab2: 'Relative',
          tab3: 'Trigger'
        },
        form: {
          date: {
            label: 'Due date',
            placeholder: ''
          },
          days: {
            label: 'Days to complete',
            placeholder: 'Enter days to complete'
          },
          triggerDate: {
            label: 'Trigger date',
            placeholder: ''
          }
        },
        triggerType: {
          label: 'Trigger type',
          placeholder: ''
        },
        trigger: {
          subtitle: 'Trigger'
        },
        dueDate: {
          subtitle: 'Due date'
        }
      },
      trigger: {
        successMessage: 'Task triggered successfully!'
      },
      triggerButton: 'Trigger',
      taskAlreadyTriggered:
        "Task is already triggered, only users that don't have task will get the new one.",
      triggerTypes: {
        [TriggerType.Date]: 'Date',
        [TriggerType.Event]: 'Event',
        [TriggerType.Manually]: 'Manually'
      }
    },
    schools: {
      boxTitle: 'SCHOOLS IN COHORT',
      addSchools: 'Add schools',
      noSchoolsInvited: 'No schools invited',
      modalTitle: 'Invite schools',
      modalSubtitle: 'Select the schools to which you want to assign a cohort',
      delete: {
        modalTitle: 'Delete school',
        modalBody:
          'Are you sure you want to delete <1>{{name}}</1> from school list?'
      },
      searchPlaceholder: 'Search schools'
    },
    students: {
      boxTitle: 'Students',
      addStudent: 'Add new student',
      noStudentsInvited: 'No students invited',
      modalTitle: 'Invite student',
      modalSubtitle: 'Fill in fields below to invite student to the cohort',
      form: {
        email: {
          label: 'Email',
          placeholder: 'Write email here'
        },
        firstName: {
          label: 'First Name',
          placeholder: 'Write first name here'
        },
        lastName: {
          label: 'Last Name',
          placeholder: 'Write last name here'
        }
      }
    }
  },
  assigned: {
    listAll: {
      title: 'Assigned Cohorts',
      subtitle: 'List of cohorts assigned to the school'
    },
    view: {
      htmlTitle: 'Cohorts | {{name}}',
      subtitle: 'Cohort information details',
      title: 'Cohort {{ name }}',
      cohortDetails: 'Details',
      certificateDetails: 'Certificate details',
      backToList: 'Go back to all assigned cohorts',
      students: 'Students',
      documents: 'Documents',
      blocks: 'Blocks'
    },
    documents: {
      delete: {
        title: 'Delete document',
        body: 'Are you sure you want to delete <1>{{name}}</1> from documents list?'
      },
      types: {
        [SchoolCourseDocumentType.AnnualTimetables]: 'Annual timetable',
        [SchoolCourseDocumentType.ProofOfCommunications]:
          'Proof of communication',
        [SchoolCourseDocumentType.SubmissionsOfTheQualiopi]:
          'Submission of the QUALIOPI',
        [SchoolCourseDocumentType.TrainersCvs]: "Trainers' CVs"
      },
      dragAndDropFiles: 'Drag & drop documents here',
      reviewAlert: {
        successfullyVerifiedTitle: 'Documents are verified',
        successfullyVerifiedText:
          'Documents are verified and school is now enrolled into cohort',
        requestVerificationTitle: 'Pending documents upload',
        requestVerificationText:
          'Upload at least one document per section and request verification by certifier',
        pendingVerificationTitle: 'Pending document verification',
        pendingVerificationText:
          'Documents are sent for verification, you will be notified once certifier verify them',
        rejectedTitle: 'Documents are rejected',
        rejectedText:
          'Something is wrong with documents and certifier rejected them',
        rejectedMessageReason: 'Show message from info',
        requestVerificationButton: 'Request Verification',
        requestVerificationButtonDisabledTooltip:
          'Request documents verification by certifier'
      },
      requestVerification: {
        title: 'Request verification',
        body: 'Are you sure you want to request documents verification?'
      },
      deadline: {
        uploadDocs: {
          expiredText: 'Deadline for task {{taskName}} has expired.'
        },
        uploadDocsVerify: {
          expiredText:
            'Dour deadline for task "Verify Course Documents" has expired.'
        }
      }
    }
  },
  listAll: {
    htmlTitle: 'Cohorts',
    title: 'Cohorts',
    subtitle: 'List of all cohorts',
    startNewCohort: 'Start new cohort',
    table: {
      columns: {
        cohort: 'COHORT',
        createdAt: 'CREATED AT'
      },
      actionTooltips: {
        edit: 'Edit',
        delete: 'Delete',
        exportLicenseReports: 'Export licenses',
        exportStudentBlockDeliverablesStatusReport: 'Export student block deliverables status report'
      }
    },
    delete: {
      successMessage: 'Cohort successfully deleted',
      errorMessage: 'Error while deleting cohort',
      modalTitle: 'Delete cohort',
      modalBody:
        'Are you sure you want to delete <1>{{name}}</1> from cohorts list?'
    },
    search: {
      label: 'Search',
      placeholder: 'Search'
    },
    export: {
      label: 'Export licenses'
    }
  },
  create: {
    htmlTitle: 'Start a new cohort',
    title: 'Start a new cohort',
    subtitle: 'Fill in fields below to start a new cohort',
    backToList: 'Go back to all cohorts'
  },
  view: {
    htmlTitle: 'Cohorts | {{name}}',
    subtitle: 'Cohort information details',
    title: 'Cohort information',
    backToList: 'Go back to all cohorts'
  },
  cohortSchool: {
    title: 'School Cohort Information',
    subtitle: 'School information assigned to the cohort',
    reject: {
      title: 'Reject documents',
      body: 'Are you sure you want to reject documents?',
      button: 'Reject',
      message: {
        label: 'Message',
        placeholder: 'Write the reason why the documentation is rejected',
        placeholderJob: 'Write the reason why the job description is rejected'
      }
    },
    verify: {
      title: 'Verify documents',
      body: 'Are you sure you want to verify documents?',
      button: 'Verify'
    },
    reviewAlert: {
      successfullyVerifiedTitle: 'Documents verified',
      successfullyVerifiedText:
        'School documents are verified by your organization',
      rejectedTitle: 'Documents rejected',
      rejectedText: 'School documents are rejected by your organization',
      rejectedMessageReason: 'Show message info',
      pendingVerificationTitle: 'Pending documents verification',
      pendingVerificationText: 'School submitted documents for review'
    },
    schoolInfoTab: 'School Info'
  },
  students: {
    listAll: {
      table: {
        columns: {
          studentName: 'Name',
          studentFirstName: 'First Name',
          studentLastName: 'Last Name',
          studentEmail: 'Email',
          studentStatus: 'Status',
          studentGroup: 'Group',
          studentTutor: 'Tutor',
          createdAt: 'Created at',
          actions: 'Actions'
        }
      },
      search: {
        label: 'Search',
        placeholder: 'Search'
      },
      addStudent: 'Add student',
      importStudents: 'Import from CSV',
      importStudentsModal: {
        modalTitle: 'Import students from CSV',
        modalSubtitle: 'Please upload CSV file to import students data',
        uploadButton: `Upload CSV & Drag'n'drop or click to add from computer`,
        download: {
          text1: '* Please download the sample',
          text2: 'and enter the real data',
          file: 'CSV-FILE'
        },
        infoMessage: `Saving {{numberOfStudents}} students may take a while, once you click save
        you can safely navigate away.`,
        successMessage:
          'Student import from CSV file has been successfully submitted for processing.',
        errorMessage: ' E-mails have already been used. Some of them are: ',
        invalidData: 'Data provided is in invalid format.',
        invalidCsvData: 'Invalid CSV. Please download and use example file',
        table: {
          columns: {
            firstName: 'First name',
            lastName: 'Last name',
            email: 'E-mail',
            dateOfBirth: 'Date of birth',
            placeOfBirth: 'Place of birth',
            group: 'Group'
          },
          loadInfo: {
            text1: '...and',
            text2: 'more students successfuly loaded.'
          }
        }
      },
      assignTutorsToStudents: 'Student/Tutor CSV',
      assignTutorsToStudentsModal: {
        modalTitle: 'Import students/tutors from CSV',
        modalSubtitle: 'Please upload CSV file to import students/tutors data',
        uploadButton: `Upload CSV & Drag'n'drop or click to add from computer`,
        download: {
          text1: '* Please download the sample',
          text2: 'and enter the real data',
          file: 'CSV-FILE'
        },
        infoMessage: `Saving {{numberOfRecords}} students/tutors may take a while, once you click save
        you can safely navigate away.`,
        successMessage:
          'Student import from CSV file has been successfully submitted for processing.',
        errorMessage: 'E-mails do not exist. Some of them are: ',
        invalidData: 'Data provided is in invalid format.',
        invalidCsvData: 'Invalid CSV. Please download and use example file',
        table: {
          columns: {
            tutorEmail: 'Tutor Email',
            studentEmail: 'Student Email',
          },
          loadInfo: {
            text1: '...and',
            text2: 'more students/tutors successfuly loaded.'
          }
        }
      },
      deleteStudent: {
        modalTitle: 'Delete student',
        modalBody: 'Are you sure you want to delete <1>{{email}}</1> student ?',
        successMessage: 'Student successfully deleted',
        errorMessage: 'Error while deleting student',
      }
    }
  },
  certification: {
    tableHeaders: {
      firstName: 'First name',
      lastName: 'Last name',
      email: 'Email',
      school: 'School',
      documents: 'Documents',
      blocks: 'Blocks',
      jobDescription: 'Job description',
      certificationPassport: 'Certification passport',
      assignedAuditor: 'Auditor',
      auditorRecommendation: 'Auditor recommendation',
      finalValidation: 'Final validation'
    },
    assignAuditorToStudentsSuccessSnackMessage: 'Auditors assigned successfully!',
    actions: {
      bulkAssignAuditor: 'Assign auditor',
      exportCertificationCSV: 'Export CSV'
    },
    assignAuditorModal: {
      assignAuditorModalTitle: 'Assign Auditor',
      noAuditorsFoundMessage: 'Auditors missing!'
    },
    certificationDocumentsStatuses: {
      [VerificationStatus.Rejected]: 'Rejected',
      [VerificationStatus.Verified]: 'Verified',
      [VerificationStatus.PendingVerification]: 'Pending',
      [VerificationStatus.Draft]: 'Draft',
      missing: 'Missing',
    },
    certificationDecisionOptions: {
      [StudentCertificationStatus.FullDiploma]: 'Full Diploma',
      [StudentCertificationStatus.PartialDiploma]: 'Partial Diploma',
      [StudentCertificationStatus.NoDiploma]: 'No Diploma',
      pending: 'Pending'
    },
    certificationBlocksStatuses: {
      [CertificationStatus.Passed]: 'Passed all',
      [CertificationStatus.Failed]: 'Failed all',
      notCalculated: 'Not Calculated',
      placeholder: 'Passed'
    },
  }
};

export default cohorts;
