import { useQuery } from 'react-query';
import { ITableParams, mapToApiCallParams } from 'src/hooks/useTableParams';
import { IStudent } from 'src/models/student';
import { IPaginateData } from 'src/utils/types';
import { IApiError } from '../../../../utils/axios';
import {
  fetchCohortSchoolStudents,
  FETCH_COHORT_SCHOOL_STUDENTS_KEY
} from '../../utils/api';

const useFetchCohortSchoolStudents = (cohortId: string, schoolId: string, params: ITableParams, options?: any) => {
  const {
    isLoading: isFirstFetchingStudents,
    error: errorFetchingStudents,
    data: studentsData,
    isFetching: isFetchingStudents,
    isPreviousData: isPreviousStudents
  } = useQuery<IPaginateData<IStudent>, IApiError>(
    [FETCH_COHORT_SCHOOL_STUDENTS_KEY, cohortId, schoolId, params],
    () => fetchCohortSchoolStudents(cohortId, schoolId, mapToApiCallParams(params)),
    {
      ...(options || {}),
      keepPreviousData: true
    }
  );

  return {
    isFirstFetchingStudents,
    errorFetchingStudents,
    studentsData,
    isFetchingStudents,
    isPreviousStudents
  };
};

export default useFetchCohortSchoolStudents;
