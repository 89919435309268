import { lazy } from 'react';

import Guest from '../../components/Guest';
import SuspenseRouteLoader from '../../components/SuspenseRouteLoader';

const Login = SuspenseRouteLoader(
  lazy(() => import('./pages/Login'))
);

const RecoverPassword = SuspenseRouteLoader(
  lazy(() => import('./pages/RecoverPassword'))
);

const ResetPassword = SuspenseRouteLoader(
  lazy(() => import('./pages/ResetPassword'))
);

const Register = SuspenseRouteLoader(
  lazy(() => import('./pages/Register'))
);

const routes = [
  {
    path: 'login',
    element: (
      <Guest>
        <Login />
      </Guest>
    )
  },
  {
    path: 'recover-password',
    element: (
      <Guest>
        <RecoverPassword />
      </Guest>
    )
  },
  {
    path: 'reset-password',
    element: (
      <Guest>
        <ResetPassword />
      </Guest>
    )
  },
  {
    path: 'register',
    element: (
      <Guest>
        <Register />
      </Guest>
    )
  }
];

export default routes;
