import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';
import { axiosInt, IApiError } from '../../utils/axios';

export const FETCH_FILE_URL_KEY = 'fetchFileUrl';
export const fetchFileUrlApi = async (id: string): Promise<{ url: string }> => {
  const response = await axiosInt.get(`/files/${id}/private-file-url`);

  return response.data;
};

const useFetchFileUrl = () => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    mutate: fetchFileUrl,
    isLoading: isFetchingFileUrl,
    error: errorFetchingFileUrl
  } = useMutation(fetchFileUrlApi, {
    mutationKey: FETCH_FILE_URL_KEY,
    onError: (error: IApiError) => {
      enqueueSnackbar(error.message, {
        variant: 'error'
      });
    }
  });

  return {
    isFetchingFileUrl,
    errorFetchingFileUrl,
    fetchFileUrl
  };
};

export default useFetchFileUrl;
