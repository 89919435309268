import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { ICohort } from 'src/models/cohort';
import { IApiError } from '../../../../utils/axios';
import {
  deleteCohortSchool as deleteCohortSchoolApi,
  DELETE_COHORT_SCHOOL_KEY,
  FETCH_COHORT_KEY
} from '../../utils/api';

const useDeleteCohortSchool = (cohort: ICohort) => {
  const queryClient = useQueryClient();
  const [schoolToBeDeleted, setSchoolToBeDeleted] = useState<any>();

  const { enqueueSnackbar } = useSnackbar();

  const { mutate: deleteCohortSchool, isLoading: isDeletingCohortSchool } =
    useMutation(deleteCohortSchoolApi, {
      mutationKey: DELETE_COHORT_SCHOOL_KEY,
      onSuccess: () => {
        setSchoolToBeDeleted(null);
        queryClient.invalidateQueries([FETCH_COHORT_KEY, cohort.id]);
      },
      onError: (error: IApiError) => {
        enqueueSnackbar(error.message, {
          variant: 'error'
        });
      }
    });

  return {
    schoolToBeDeleted,
    setSchoolToBeDeleted,
    deleteCohortSchool,
    isDeletingCohortSchool
  };
};

export default useDeleteCohortSchool;
