import { Alert, AlertTitle, Button } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { ITask } from '../../../../models/task';
import { IUser } from '../../../../models/user';
import { StudentStatuses } from '../../../../utils/const';
import { AlertBorderSettings, AlertType } from '../../../../utils/styles';
import RejectMessageInfo from '../../../cohorts/components/common/RejectMessageInfo';
import TaskDeadlineAlert from '../../../tasks/components/common/TaskDeadlineAlert';

interface IProps {
  student: IUser;
  isTaskOverdue: boolean;
  isTaskExpired: boolean;
  task: ITask;
  canRequestVerification: boolean;
  onRequestVerification(): void;
}

const StudentDocumentsStatusAlert: React.FC<IProps> = ({
  student,
  isTaskOverdue,
  task,
  canRequestVerification,
  isTaskExpired,
  onRequestVerification
}) => {
  const { t } = useTranslation();

  if (isTaskOverdue) {
    return (
      <Box sx={{ mt: 2, mb: 2 }}>
        <TaskDeadlineAlert task={task} />
      </Box>
    );
  }

  if (student.userable.studentStatus === StudentStatuses.Invited) {
    return (
      <Alert
        severity="info"
        sx={{
          border: AlertBorderSettings[AlertType.Info],
          mb: 2
        }}
        action={
          canRequestVerification && (
            <Button
              color="primary"
              variant="contained"
              onClick={onRequestVerification}
            >
              {t(
                `profile.student.uploadDocuments.documentsAlert.requestVerification`
              )}
            </Button>
          )
        }
      >
        <Box display="flex" justifyContent="space-between">
          <Box>
            <AlertTitle>
              <strong>
                {t(
                  `profile.student.uploadDocuments.documentsAlert.waitingDocumentsTitle`
                )}
              </strong>
            </AlertTitle>
            {t(
              `profile.student.uploadDocuments.documentsAlert.waitingDocumentsText`
            )}
          </Box>
        </Box>
      </Alert>
    );
  }

  if (
    student.userable.studentStatus === StudentStatuses.PendingSchoolVerification
  ) {
    return (
      <Alert
        severity="info"
        sx={{ border: AlertBorderSettings[AlertType.Info], mb: 2 }}
      >
        <AlertTitle>
          <strong>
            {t(`profile.student.uploadDocuments.documentsAlert.requestedTitle`)}
          </strong>
        </AlertTitle>
        {t(`profile.student.uploadDocuments.documentsAlert.requestedText`)}
      </Alert>
    );
  }

  if (student.userable.studentStatus === StudentStatuses.RejectedBySchool) {
    return (
      <Alert
        severity="error"
        sx={{ border: AlertBorderSettings[AlertType.Error], mb: 2 }}
        action={
          !isTaskExpired &&
          canRequestVerification && (
            <Button
              variant="outlined"
              color="primary"
              onClick={onRequestVerification}
            >
              {t(
                `profile.student.uploadDocuments.documentsAlert.requestVerification`
              )}
            </Button>
          )
        }
      >
        <AlertTitle>
          <strong>
            {t(`profile.student.uploadDocuments.reviewAlert.rejectedTitle`)}
          </strong>
        </AlertTitle>
        {t(`profile.student.uploadDocuments.reviewAlert.rejectedText`)}
        <RejectMessageInfo statusLogs={student.userable.statusLogs.filter((log) => log.statusLabel === null)} />
      </Alert>
    );
  }

  if (student.userable.studentStatus === StudentStatuses.Verified) {
    return (
      <Alert
        severity="success"
        sx={{ border: AlertBorderSettings[AlertType.Success], mb: 2 }}
      >
        <AlertTitle>
          <strong>
            {t(`profile.student.uploadDocuments.reviewAlert.verifiedTitle`)}
          </strong>
        </AlertTitle>
        {t(`profile.student.uploadDocuments.reviewAlert.verifiedText`)}
      </Alert>
    );
  }

  return null;
};

export default StudentDocumentsStatusAlert;
