import { RouteObject } from 'react-router';
import Authenticated from 'src/components/Authenticated';
import AccentSidebarLayout from 'src/layouts/AccentSidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';
import authRoutes from '../features/auth/routes';
import baseRoutes from '../features/status/routes';
import certificateRoutes from '../features/certificates/routes';
import schoolRoutes from '../features/schools/routes';
import settingsRoutes from '../features/settings/routes';
import roleRoutes from '../features/acl/routes';
import profileRoutes from '../features/profile/routes';
import CompanyRoutes from '../features/companies/routes';
import CohortRoutes from '../features/cohorts/routes';
import CertifierEmployeeRoutes from '../features/certifier-employees/routes';
import StudentRoutes from '../features/students/routes';
import TaskRoutes from '../features/tasks/routes';
import Dashboard from '../features/dashboard/pages/Dashboard';
import notificationRoutes from '../features/notifications/routes';
// import finalExamRoutes from '../features/final-exams/routes';
// import EvaluationRoutes from '../features/evaluations/routes';
import { SchoolCompaniesEmployeesRoutes } from '../features/company-employees/route';
import Impersonate from 'src/features/auth/pages/Impersonate';

const router: Partial<RouteObject>[] = [
  // Auth
  {
    path: '*',
    children: authRoutes
  },
  // Status
  {
    path: 'impersonate/:url',
    element: <Impersonate />
  },
  {
    path: '*',
    element: <BaseLayout />,
    children: baseRoutes
  },
  // App routes
  {
    path: '/',
    element: (
      <Authenticated>
        <AccentSidebarLayout />
      </Authenticated>
    ),
    children: [
      // App routes go here
      {
        path: '/certificates',
        children: certificateRoutes
      },
      {
        path: '/schools',
        children: schoolRoutes
      },
      {
        path: '/settings',
        children: settingsRoutes
      },
      {
        path: '/roles',
        children: roleRoutes
      },
      {
        path: '/profile',
        children: profileRoutes
      },
      {
        path: '/companies',
        children: CompanyRoutes
      },
      {
        path: '/companies-employees',
        children: SchoolCompaniesEmployeesRoutes
      },
      {
        path: '/cohorts',
        children: CohortRoutes
      },
      {
        path: '/certifier-employees',
        children: CertifierEmployeeRoutes
      },
      {
        path: '/students',
        children: StudentRoutes
      },
      {
        path: '/tasks',
        children: TaskRoutes
      },
      {
        path: '/notifications',
        children: notificationRoutes
      },
      // {
      //   path: '/final-exams',
      //   children: finalExamRoutes
      // },
      // {
      //   path: '/evaluations',
      //   children: EvaluationRoutes
      // },
      {
        path: '/',
        element: <Dashboard />
      }
    ]
  }
];

export default router;
