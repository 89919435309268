import { useTranslation } from 'react-i18next';
import { Grid, Button } from '@mui/material';
import { Box } from '@mui/system';
import { Mode } from 'src/utils/types';
import { Formik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import * as Yup from 'yup';
import { IEditUserAccountInfo } from '../../utils/types';
import AccountDetailsFields from './AccountDetailsFields';
import { IUser } from 'src/models/user';
import InfoItem from './InfoItem';
import {
  isStudent,
  isSchoolEmployee,
  isCertifierEmployee,
  isCompanyEmployee
} from 'src/utils/userTypes';
import useCan from 'src/hooks/useCan';
import { Permissions } from 'src/utils/const';

import {
  ImagePlaceholder,
  PreviewImage,
  WrapperPreviewImage
} from 'src/components/DragAndDropAvatar';
import useAuth from 'src/features/auth/hooks/useAuth';

const PROFILE_ACCOUNT_INFO_TRANSLATE_KEY = 'profile.accountInfo';
const VALIDATION_TRANSLATE_KEY = 'common.validation';

interface IProps {
  handleEdit(data: IEditUserAccountInfo): void;
  accountDetailsLocalMode: Mode;
  isUpdatingAccountInfo: boolean;
  setAccountDetailsLocalMode(Mode: Mode): void;
  user: IUser;
}

const AccountInfoDetails: React.FC<IProps> = ({
  handleEdit,
  accountDetailsLocalMode,
  isUpdatingAccountInfo,
  setAccountDetailsLocalMode,
  user
}) => {
  const { t } = useTranslation();
  const { user: auth } = useAuth()
  const canManageStudents = useCan(Permissions.ManageStudents);
  const canManageSchoolEmployees = useCan(Permissions.ManageSchoolStaff);
  const canManageCompanyEmployees = useCan(Permissions.ManageCompanyStaff);
  const canManageCertifierEmployees = useCan(Permissions.ManageCertifierStaff);

  const isStudentAccount = isStudent(user);
  const isAuthStudent = isStudent(auth);

  const canEditEmail = ((): boolean => {
    if (isStudentAccount) {
      return !!canManageStudents;
    } else if (isSchoolEmployee(user)) {
      return !!canManageSchoolEmployees;
    } else if (isCompanyEmployee(user)) {
      return !!canManageCompanyEmployees;
    } else if (isCertifierEmployee(user)) {
      // return !!canManageCertifierEmployees
    }
    return false;
  })();

  if (accountDetailsLocalMode === Mode.View) {
    return (
      <Grid container>
        <Grid xs={12} sm={4} item>
          {user?.avatar ? (
            <WrapperPreviewImage>
              <PreviewImage alt="school image" src={user?.avatar} />
            </WrapperPreviewImage>
          ) : (
            <ImagePlaceholder />
          )}
        </Grid>
        <Grid xs={12} sm={8} item>
          <Box
            sx={{
              display: 'flex',
              boxSizing: 'border-box',
              flexWrap: 'wrap'
            }}
          >
            <InfoItem
              property={t(`${PROFILE_ACCOUNT_INFO_TRANSLATE_KEY}.firstName`)}
              value={user.firstName}
            />
            <InfoItem
              property={t(`${PROFILE_ACCOUNT_INFO_TRANSLATE_KEY}.lastName`)}
              value={user.lastName}
            />
            <InfoItem
              property={t(`${PROFILE_ACCOUNT_INFO_TRANSLATE_KEY}.email`)}
              value={user.email}
            />
            <InfoItem
              property={t(`${PROFILE_ACCOUNT_INFO_TRANSLATE_KEY}.civility`)}
              value={user.civility}
            />
            <InfoItem
              property={t(`${PROFILE_ACCOUNT_INFO_TRANSLATE_KEY}.phone`)}
              value={user.phone}
            />
            {isStudentAccount && (<>
              <InfoItem
                property={t(`${PROFILE_ACCOUNT_INFO_TRANSLATE_KEY}.middleNameOne`)}
                value={user.middleNameOne}
              />
              <InfoItem
                property={t(`${PROFILE_ACCOUNT_INFO_TRANSLATE_KEY}.middleNameTwo`)}
                value={user.middleNameTwo}
              />
              <InfoItem
                property={t(`${PROFILE_ACCOUNT_INFO_TRANSLATE_KEY}.secondaryEmail`)}
                value={user.secondaryEmail}
              />
              <InfoItem
                property={t(`${PROFILE_ACCOUNT_INFO_TRANSLATE_KEY}.gender`)}
                value={user.gender ? t(`${PROFILE_ACCOUNT_INFO_TRANSLATE_KEY}.genderOptions.${user.gender}`) : null}
              />
            </>)}
          </Box>
        </Grid>
      </Grid>
    );
  }

  return (
    <div>
      <Formik<IEditUserAccountInfo>
        initialValues={{
          avatar: user.avatar ?? '',
          firstName: user.firstName ?? '',
          lastName: user.lastName ?? '',
          email: user.email ?? '',
          civility: user.civility ?? '',
          phone: user.phone ?? '',
          middleNameOne: isStudentAccount ? (user.middleNameOne ?? '') : undefined,
          middleNameTwo: isStudentAccount ? (user.middleNameTwo ?? '') : undefined,
          secondaryEmail: isStudentAccount ? (user.secondaryEmail ?? '') : undefined,
          gender: isStudentAccount ? (user.gender ?? '') : undefined
        }}
        onSubmit={handleEdit}
        validationSchema={Yup.object().shape({
          firstName: Yup.string().required(t(`${VALIDATION_TRANSLATE_KEY}.required`, { field: t(`${VALIDATION_TRANSLATE_KEY}.firstNameField`) })),
          lastName: Yup.string().required(t(`${VALIDATION_TRANSLATE_KEY}.required`, { field: t(`${VALIDATION_TRANSLATE_KEY}.lastNameField`) })),
          email: canEditEmail ? Yup.string().required(t(`${VALIDATION_TRANSLATE_KEY}.required`, { field: t(`${VALIDATION_TRANSLATE_KEY}.emailField`) })) : undefined,
          phone: Yup.string().optional().matches(/^(\+|00)[1-9]\d{1,14}$/, t(`${VALIDATION_TRANSLATE_KEY}.phoneField`)),

          ...(isAuthStudent ? {
            phone: Yup.string().required().matches(/^(\+|00)[1-9]\d{1,14}$/, t(`${VALIDATION_TRANSLATE_KEY}.phoneField`)),
            civility: Yup.string().required(t(`${VALIDATION_TRANSLATE_KEY}.requiredLabel`)),
            secondaryEmail: Yup.string().required(t(`${VALIDATION_TRANSLATE_KEY}.requiredLabel`)),
            gender: Yup.string().required(t(`${VALIDATION_TRANSLATE_KEY}.requiredLabel`)),
          } : {})
        })}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <AccountDetailsFields isStudentAccount={isStudentAccount} canEditEmail={canEditEmail} />
            <Box display="flex" justifyContent="flex-end" mt={3}>
              <Button
                color="secondary"
                size="medium"
                variant="outlined"
                onClick={() => setAccountDetailsLocalMode(Mode.View)}
              >
                {t('common.buttons.cancel')}
              </Button>
              <LoadingButton
                loading={isUpdatingAccountInfo}
                sx={{
                  ml: 2
                }}
                variant="contained"
                size="medium"
                type="submit"
              >
                {t('common.buttons.save')}
              </LoadingButton>
            </Box>
          </form>
        )}
      </Formik>
    </div >
  );
};

export default AccountInfoDetails;
