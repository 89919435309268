import { useQuery } from 'react-query';
import { ICertificate } from 'src/models/certificate';
import { IApiError } from '../../../../../utils/axios';
import {
  fetchPublishedCertificates,
  FETCH_PUBLISHED_CERTIFICATES_KEY
} from '../../../utils/api';

const useFetchPublishedCertificates = () => {
  const {
    error: errorFetchingCertificates,
    data: certificates,
    isFetching: isFetchingCertificates
  } = useQuery<ICertificate[], IApiError>(
    [FETCH_PUBLISHED_CERTIFICATES_KEY],
    () => fetchPublishedCertificates()
  );

  return {
    errorFetchingCertificates,
    certificates,
    isFetchingCertificates
  };
};

export default useFetchPublishedCertificates;
