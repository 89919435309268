import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PageSubtitle from '../../../../components/PageSubtitle';
import PageTitle from '../../../../components/PageTitle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';
import { CohortRoutes } from 'src/features/cohorts/routes';
import { IUser } from 'src/models/user';
import useAuth from 'src/features/auth/hooks/useAuth';
import { isSchoolEmployee, isCertifierEmployee, isCompanyEmployee } from 'src/utils/userTypes';
import { useNavigate } from 'react-router-dom';
import { StudentRoutes } from '../../routes';
import { studentIsInRedemption, studentIsOnHold } from 'src/utils/userHelpers';
import { Tabs as CohortTabs } from 'src/features/cohorts/pages/Cohort'
const STUDENT_TRANSLATE_BASE_KEY = 'profile.student';

const PageHeader: React.FC<{ name: string, student: IUser }> = ({ name, student }) => {
  const { t } = useTranslation();

  const navigate = useNavigate()

  const { user } = useAuth();

  const resolveBackUrl = () => {

    if (isSchoolEmployee(user)) {
      return CohortRoutes.ViewAssigned
        .replace(':id', student.userable.cohortId)
        + '?tab=Students'
    }

    if (isCertifierEmployee(user)) {
      return CohortRoutes.View
        .replace(':id', student.userable.cohortId)
        + `?tab=${studentIsInRedemption(student) ? CohortTabs.Redemption : CohortTabs.Certification}`
    }

    if (isCompanyEmployee(user)) {
      return StudentRoutes.Students;
    }

    return '/';
  }

  const isStudentOnHold = studentIsOnHold(student);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Box sx={{ mb: 4, width: '100%' }}>
        <PageTitle color={isStudentOnHold ? 'error.main' : ''}>
          {t(`${STUDENT_TRANSLATE_BASE_KEY}.title`, { name })}
        </PageTitle>
        <PageSubtitle>
          {t(`${STUDENT_TRANSLATE_BASE_KEY}.subtitle`)}
        </PageSubtitle>
      </Box>
      {/* {student.userable && resolveBackUrl() && */}
      <Box>
        <Button
          variant="contained"
          size="medium"
          to={resolveBackUrl()}
          // onClick={() => navigate(-1)}
          component={Link}
          sx={{ mb: { xs: '10px' } }}
        >
          <ArrowBackIcon />
          {isCertifierEmployee(user) ? t(`${STUDENT_TRANSLATE_BASE_KEY}.backToCertificationList`) : t(`${STUDENT_TRANSLATE_BASE_KEY}.backToList`)}
        </Button>
      </Box>
      {/* } */}
    </Box>
  );
};

export default PageHeader;
