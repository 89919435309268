import {
  Box,
  Collapse,
  IconButton,
  styled,
  Tooltip,
  Typography
} from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import RuleIcon from '@mui/icons-material/Rule';

import { FileTreeMode, IFile, IFolder, TreeItemType } from './types';
import useToggleVisibility from '../../hooks/useToggleVisibility';
import File from './File';
import CreateEditFolderPopup from './CreateEditFolderPopup';
import useFileUpload from './useFileUpload';
import { useTranslation } from 'react-i18next';
import CreateEditRolesPopup from './CreateEditRolesPopup';
import { useContext, useState } from 'react';
import { DragAndDropContext } from 'src/contexts/DragAndDropContext';

const Name = styled('div')(
  ({ theme }) => `
  padding: ${theme.spacing(1)};
  display:flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    background-color: ${theme.colors.alpha.black[10]};
    cursor: pointer;
  }
`
);

const EmptyFolder = styled('div')(
  ({ theme }) => `
  padding: ${theme.spacing(1)};
  display:flex;
  align-items: center;
  justify-content: space-between;
`
);

const FolderBox = styled(Box)(
//   ({ theme }) => `
//   border-bottom: 1px solid ${theme.colors.alpha.black[30]};
//   &:last-child {
//     border-bottom: none;
//   }
// `
);

const Data = styled(Box)(
  ({ theme }) => `
  padding-left: ${theme.spacing(1.5)};
`
);

const ButtonIconCopy = styled('div')(
  ({ theme }) => `
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: ${theme.spacing(0.5)};
    border-radius: ${theme.spacing(1)};
    &:hover {
      background: ${theme.colors.alpha.black[10]};
    }
`
);

const FILE_TREE_TOOLTIPS_TRANSLATE_KEY = 'common.fileTree.tooltips';

interface IProps {
  folder: IFolder;
  mode: FileTreeMode;
  canManageTree: boolean;
  onDelete(uuid: IFolder): void;
  onChangeFolderStructure(newFolder: IFolder): void;
  onEdit(folder: IFolder): void;
  onCreate(name: string, parentFolder: IFolder): void;
  onFileUploaded(file: Partial<IFile>): void;
  onDeleteFile(file: IFile, folder: IFolder): void;
  onChangeFolderRoles(folder: IFolder): void;
  onChangeFileRoles(file: IFile, folder: IFolder): void;
}

const Folder: React.FC<IProps> = ({
  folder,
  mode,
  canManageTree,
  onCreate,
  onDelete,
  onChangeFolderStructure,
  onEdit,
  onFileUploaded,
  onDeleteFile,
  onChangeFolderRoles,
  onChangeFileRoles
}) => {

  const {
    enabled: enabledDragAndDrop,
    onDragStart,
    onDragEnter,
    onDragLeave,
    onDragEnd,
    onDrag,
    onDrop,
    onDragOver,
    getDragging
  } = useContext(DragAndDropContext);

  const { t } = useTranslation();
  const isEditable = mode !== FileTreeMode.View;

  const [acceptedFiles, setAcceptedFiles] = useState([]);
  useFileUpload({
    acceptedFiles,
    onChange: onChangeFolderStructure,
    onFileUploaded,
    folder
  });

  const { isVisible: isVisibleFolderStructure, toggle: toggleFolderStructure, hide: hideFolderStructure } =
    useToggleVisibility(false);

  const {
    hide: hideCreateFolderPopup,
    isVisible: isVisibleCreateFolderPopup,
    toggle: toggleCreateFolderPopup
  } = useToggleVisibility(false);

  const {
    hide: hideEditFolderPopup,
    isVisible: isVisibleEditFolderPopup,
    toggle: toggleEditFolderPopup
  } = useToggleVisibility(false);

  const {
    hide: hideFolderRolesPopup,
    isVisible: isVisibleFolderRolesPopup,
    toggle: toggleFolderRolesPopup
  } = useToggleVisibility(false);

  const handleCreateFolder = (name: string) => {
    onCreate(name, folder);
    hideCreateFolderPopup();
  };

  const handleEditFolder = (name: string) => {
    onEdit({
      ...folder,
      name
    });
    hideEditFolderPopup();
  };

  const handleEditRoleOnFolder = (roles: string[]) => {
    onChangeFolderRoles({
      ...folder,
      roles
    });
    hideFolderRolesPopup();
  };

  const handleDeleteFile = (file: IFile) => {
    onDeleteFile(file, folder);
  };

  const handleFileUpload = (event) => {
    setAcceptedFiles([...event.target.files])
    event.target.value = null;
  }

  return (
    <FolderBox>
      <Name
        onClick={toggleFolderStructure}
        draggable={enabledDragAndDrop}
        onDragStart={(e) => {
          hideFolderStructure()
          onDragStart(e, folder)
        }}
        onDrag={onDrag}
        onDragEnd={onDragEnd}
        onDragOver={(e) => { onDragOver(e, false === (getDragging().type === TreeItemType.File && !folder.parentFolderId)) }}
        onDragEnter={(e) => { onDragEnter(e, false === (getDragging().type === TreeItemType.File && !folder.parentFolderId)) }}
        onDragLeave={onDragLeave}
        onDrop={(e) => onDrop(e, { uuid: folder.uuid, dropType: 'default' })}
      >
        <Box display="inline-flex" alignItems="center">
          <FolderIcon />
          <Typography sx={{ ml: 0.5 }}>{folder.name}</Typography>
        </Box>
        {isEditable && canManageTree && (
          <Box display="flex" visibility={enabledDragAndDrop ? 'hidden' : 'unset'}>
            <Tooltip title={t(`${FILE_TREE_TOOLTIPS_TRANSLATE_KEY}.editRoles`)}>
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleFolderRolesPopup();
                }}
              >
                <RuleIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={t(`${FILE_TREE_TOOLTIPS_TRANSLATE_KEY}.uploadFiles`)}
            >
              <IconButton
                size="small"
                component="label"
                onClick={(e) => { e.stopPropagation(); }}
              >
                <input type="file" multiple hidden onChange={handleFileUpload} />
                <FileUploadIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={t(`${FILE_TREE_TOOLTIPS_TRANSLATE_KEY}.editFolder`)}
            >
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleEditFolderPopup();
                }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={t(`${FILE_TREE_TOOLTIPS_TRANSLATE_KEY}.createSubFolder`)}
            >
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleCreateFolderPopup();
                }}
              >
                <AddCircleOutlineIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={t(`${FILE_TREE_TOOLTIPS_TRANSLATE_KEY}.deleteFolder`)}
            >
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete(folder);
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Name>

      <Collapse in={isVisibleFolderStructure}>
        <Data>
          {!(folder.children?.length > 0) && (
            <EmptyFolder
              onDragStart={(e) => onDragStart(e, folder)}
              onDrag={onDrag}
              onDragEnd={onDragEnd}
              onDragOver={onDragOver}
              onDragEnter={onDragEnter}
              onDragLeave={onDragLeave}
              onDrop={(e) => onDrop(e, { uuid: folder.uuid, dropType: 'pushToEmptyFolder' })}
            >
              <Typography sx={{ ml: 0.5 }}>{t('common.fileTree.emptyMessage')}</Typography>
            </EmptyFolder>
          )}
          {folder.children?.map((item) =>
            item.type === TreeItemType.Folder ? (
              <Folder
                folder={item as IFolder}
                mode={mode}
                onDelete={onDelete}
                onChangeFolderStructure={onChangeFolderStructure}
                key={item.uuid}
                canManageTree={canManageTree}
                onEdit={onEdit}
                onCreate={onCreate}
                onFileUploaded={onFileUploaded}
                onDeleteFile={onDeleteFile}
                onChangeFolderRoles={onChangeFolderRoles}
                onChangeFileRoles={onChangeFileRoles}
              />
            ) : (
              <File
                file={item as IFile}
                mode={mode}
                onDelete={handleDeleteFile}
                key={item.uuid}
                canManage={canManageTree}
                onEditRole={(file) => onChangeFileRoles(file, folder)}
              />
            )
          )}
        </Data>
      </Collapse>

      <CreateEditFolderPopup
        isVisible={isVisibleCreateFolderPopup}
        onHide={hideCreateFolderPopup}
        onFinish={handleCreateFolder}
      />
      <CreateEditFolderPopup
        isVisible={isVisibleEditFolderPopup}
        onHide={hideEditFolderPopup}
        onFinish={handleEditFolder}
        folderName={folder.name}
      />
      <CreateEditRolesPopup
        isVisible={isVisibleFolderRolesPopup}
        onHide={hideFolderRolesPopup}
        onFinish={handleEditRoleOnFolder}
        fileRoles={folder.roles}
      />
    </FolderBox>
  );
};

export default Folder;
