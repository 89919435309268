import {
  Alert,
  Button,
  AlertTitle,
  Typography,
  Stack,
  Box,
  styled
} from '@mui/material';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { IUser } from 'src/models/user';
import { StudentStatuses } from 'src/utils/const';
import { studentIsOnHold } from 'src/utils/userHelpers';

const STUDENT_TRANSLATE_BASE_KEY = 'profile.student';

const AlertContent = styled(Box)(
  () => `
      white-space: nowrap;
    `
);

interface IProps {
  student: IUser;
  canManage: boolean;
  setStatus(status: StudentStatuses): void;
}

const PendingSchoolVerification: React.FC<IProps> = ({
  student,
  setStatus,
  canManage
}) => {

  const readOnly = studentIsOnHold(student);
  
  return (
    <Stack sx={{ width: '100%', mt: 1 }} spacing={2}>
      <Alert
        severity="info"
        sx={{
          border: '1px solid#33c2ff',
          mb: 2
        }}
        action={
          canManage && (
            <AlertContent>
              <Button
                disabled={readOnly}
                color="error"
                variant="contained"
                sx={{ m: 1 }}
                onClick={() => {
                  setStatus(StudentStatuses.RejectedBySchool);
                }}
              >
                {t(`${STUDENT_TRANSLATE_BASE_KEY}.rejectStudent`)}
              </Button>
              <Button
                disabled={readOnly}
                color="primary"
                variant="contained"
                sx={{ m: 1 }}
                onClick={() => {
                  setStatus(StudentStatuses.Verified);
                }}
              >
                {t(`${STUDENT_TRANSLATE_BASE_KEY}.verifyStudent`)}
              </Button>
            </AlertContent>
          )
        }
      >
        <AlertTitle>
          <strong>
            {t(`${STUDENT_TRANSLATE_BASE_KEY}.pendingVerification.alertTitle`)}
          </strong>
        </AlertTitle>
        <Typography>
          <Trans
            i18nKey={`${STUDENT_TRANSLATE_BASE_KEY}.pendingVerification.alertSubtitle`}
          >
            Student
            <strong>
              {{ firstName: student.firstName }}
              {{ lastName: student.lastName }}
            </strong>
            has uploaded documents and is awaiting verification!
          </Trans>
        </Typography>
      </Alert>
    </Stack>
  );
};

export default PendingSchoolVerification;
