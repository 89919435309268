import { Box } from "@mui/system";
import React, { MouseEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, FormControl, Grid, Menu, OutlinedInput, Select, TextField, Typography } from "@mui/material";
import { Formik, useFormikContext } from "formik";
import AddIcon from '@mui/icons-material/Add';
import { use } from "i18next";
import { IRole } from "src/models/role";
import { IAssignToRole, IBaseEvaluationConfiguration } from "src/models/evaluation-configuration";
import * as Yup from 'yup';
import { IUser } from "src/models/user";
import { useParams } from "react-router";
import useFetchStudentRelatedUsersWithRole from "../../hooks/api/useFetchStudentRelatedUsersWithRole";
import { ICreateEvaluation } from "../../utils/types";
import { getAssignToRolesWithIdentifiers } from "../../utils/evaluationConfigurationsHelpers";
import { printUserName } from "src/utils/userHelpers";


const CreateEvaluationModal: React.FC<{
  evaluationConfiguration: IBaseEvaluationConfiguration;
  onClose(): void;
  onSubmit(values: ICreateEvaluation): void;
}> = ({
  evaluationConfiguration,
  onClose,
  onSubmit
}) => {
    const { t } = useTranslation();

    const { studentId } = useParams();
    const [role, setRole] = React.useState<IAssignToRole | null>(null)

    const { data: users, refetch } = useFetchStudentRelatedUsersWithRole(studentId, role?.id);

    useEffect(() => {
      if (!!role) { refetch(); }
    }, [role]);

    return (
      <Dialog open={true} onClose={onClose} fullWidth maxWidth="sm" >
        <DialogTitle>{t('evaluations.studentEvaluationConfigurations.createEvaluationForm.title')}</DialogTitle>
        <DialogContent>
          <Formik<ICreateEvaluation>
            initialValues={{ evaluationConfigurationId: evaluationConfiguration.id, assignToIdentifier: '', userId: '' }}
            onSubmit={onSubmit}
            validationSchema={Yup.object().shape({
              assignToIdentifier: Yup.string().required(t(`common.validation.required`, {
                field: t(`evaluations.studentEvaluationConfigurations.createEvaluationForm.inputLabels.assignToIdentifier`)
              })),
              userId: Yup.string().required(t(`common.validation.required`, {
                field: t(`evaluations.studentEvaluationConfigurations.createEvaluationForm.inputLabels.userId`)
              }))
            })}
          >
            {({
              errors,
              values,
              handleChange,
              handleSubmit,
              isSubmitting
            }) => (
              <form onSubmit={handleSubmit}>
                <DialogContent sx={{ overflowY: 'visible' }}>
                  <FormControl fullWidth sx={{ marginTop: '10px' }}>
                    <InputLabel id="select-evaluation-configuration-label">{
                      t('evaluations.studentEvaluationConfigurations.createEvaluationForm.inputLabels.evaluationConfigurationId')
                    }</InputLabel>
                    <Select
                      labelId="select-evaluation-configuration-label"
                      id="select-evaluation-configuration"
                      name="evaluationConfigurationId"
                      value={values.evaluationConfigurationId}
                      label={t('evaluations.studentEvaluationConfigurations.createEvaluationForm.inputLabels.evaluationConfigurationId')}
                      disabled={true}
                    >
                      <MenuItem key={evaluationConfiguration.id} value={evaluationConfiguration.id} >
                        {evaluationConfiguration.name}
                      </MenuItem>
                    </Select>
                    {errors.evaluationConfigurationId && <div>{errors.evaluationConfigurationId}</div>}
                  </FormControl>
                  <FormControl fullWidth sx={{ marginTop: '20px' }}>
                    <InputLabel id="select-role-identifier-label">{t('evaluations.studentEvaluationConfigurations.createEvaluationForm.inputLabels.assignToIdentifier')}</InputLabel>
                    <Select
                      labelId="select-role-identifier-label"
                      id="select-role-identifier"
                      name="assignToIdentifier"
                      value={values.assignToIdentifier}
                      label={t('evaluations.studentEvaluationConfigurations.createEvaluationForm.inputLabels.assignToIdentifier')}
                      onChange={(e) => {
                        handleChange(e)
                        setRole(evaluationConfiguration.assignTo.find((role) => {
                          return role.name + (role.description ? ` | ${role.description}` : '') === e.target.value
                        }))
                      }}
                    >
                      {getAssignToRolesWithIdentifiers(evaluationConfiguration.assignTo).map((role, index) => (
                        <MenuItem key={index} value={role.identifier} >
                          {role.identifierTranslated}
                        </MenuItem>
                      ))}

                    </Select>
                    {errors.assignToIdentifier && <div>{errors.assignToIdentifier}</div>}
                  </FormControl>
                  {values.assignToIdentifier && (
                    <FormControl fullWidth sx={{ marginTop: '20px' }}>
                      <InputLabel id="select-user-label">{t('evaluations.studentEvaluationConfigurations.createEvaluationForm.inputLabels.userId')}</InputLabel>
                      <Select
                        labelId="select-user-label"
                        id="select-user"
                        name="userId"
                        value={values.userId}
                        label={t('evaluations.studentEvaluationConfigurations.createEvaluationForm.inputLabels.userId')}
                        onChange={handleChange}
                      >
                        {users?.map((user, index) => (
                          <MenuItem key={index} value={user.id} >
                            {printUserName(user)}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.userId && <div>{errors.userId}</div>}
                    </FormControl>
                  )}
                </DialogContent>
                <DialogActions sx={{ paddingBottom: 2 }}>
                  <Button
                    color="secondary"
                    size="small"
                    variant="outlined"
                    onClick={onClose}
                  >
                    {t(`common.buttons.cancel`)}
                  </Button>
                  <Button
                    color="secondary"
                    size="small"
                    variant="outlined"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {t(`common.buttons.confirm`)}
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>);
  }


export default CreateEvaluationModal;

