import { Box, Grid, Paper, Typography } from '@mui/material';
import { IStudentBlock } from 'src/models/student-block';
import { IUser } from 'src/models/user';
import StudentBlock from './StudentBlock';
import GlobalAuditorRecommendationSection from '../GlobalAuditorRecommendationSection';
import JuryDecisionSection from '../JuryDecisionSection';
import StudentJuryDecisionSection from '../StudentJuryDecisionSection';
import useAuth from 'src/features/auth/hooks/useAuth';
import { isStudent, isCertifierEmployee } from 'src/utils/userTypes';
import { studentIsInRedemption, studentIsOnHold } from 'src/utils/userHelpers';
import { IStudentCertification } from 'src/models/student-certification';
import { useTranslation } from 'react-i18next';
interface IProps {
  studentBlocks: IStudentBlock[];
  studentId?: string;
  student?: IUser;
  certification?: IStudentCertification
}

const StudentBlocks: React.FC<IProps> = ({ studentBlocks, certification, student, studentId }) => {

  const { user: auth } = useAuth()
  const { t } = useTranslation();

  const isAuthStudent = isStudent(auth);
  const isAuthCertifier = isCertifierEmployee(auth);

  const resolveStudentUser = (): IUser => {
    return (!isAuthStudent ? student : auth)
  }

  const isStudentOnHold = studentIsOnHold(resolveStudentUser());
  const isStudentInRedemption = studentIsInRedemption(resolveStudentUser());

  return (
    <Grid container spacing={2} direction={{ xs: 'column-reverse', xl: 'row' }}>
      <Grid item xs={12} xl={8}>
        <Grid container spacing={2}>
          {studentBlocks.map((studentBlock) => (
            <StudentBlock studentBlock={studentBlock} key={studentBlock.id} />
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12} xl={4} sx={{ overflow: 'hidden' }}>
        {isAuthCertifier && isStudentInRedemption && (certification.redemptionAuditor ?
          <GlobalAuditorRecommendationSection
            title={t(`studentBlock.auditor.auditorRedemptionRecommendationTitle`)}
            studentId={student.userable.id}
            auditor={certification.redemptionAuditor}
            auditorComment={certification.redemptionAuditorComment}
            auditorRecommendation={certification.redemptionAuditorRecommendation}
            readOnly={isStudentOnHold || !certification.redemptionAuditor}
          /> :
          <MissingAuditorBox
            title={t(`studentBlock.auditor.noAuditorForRedemptionAssignedTitle`)}
            subTitle={t(`studentBlock.auditor.noAuditorAssignedSubtitle`)}
          />
        )}
        {isAuthCertifier && isStudentInRedemption &&
          <JuryDecisionSection
            title={t(`studentBlock.jury.juryRedemptionDecisionTitle`)}
            missingTitle={t(`studentBlock.jury.noJuryForRedemptionAssignedTitle`)}
            studentId={student.userable.id}
            studentBlocks={studentBlocks.filter((block) => block.isFailedByJuryInRegularFlow)}
            jury={certification.redemptionJury}
            juryComment={certification.redemptionJuryComment}
            juryDecision={certification.redemptionJuryDecision}
            readOnly={isStudentOnHold}
          />
        }
        {isAuthCertifier && (certification.auditor ?
          <GlobalAuditorRecommendationSection
            title={t(`studentBlock.auditor.auditorRecommendationTitle`)}
            studentId={student.userable.id}
            auditor={certification.auditor}
            auditorComment={certification.auditorComment}
            auditorRecommendation={certification.auditorRecommendation}
            readOnly={isStudentOnHold || isStudentInRedemption}
          /> :
          <MissingAuditorBox
            title={t(`studentBlock.auditor.noAuditorAssignedTitle`)}
            subTitle={t(`studentBlock.auditor.noAuditorAssignedSubtitle`)}
          />
        )}
        {isAuthCertifier &&
          <JuryDecisionSection
            title={t(`studentBlock.jury.juryDecisionTitle`)}
            missingTitle={t(`studentBlock.jury.noJuryAssignedTitle`)}
            studentId={student.userable.id}
            studentBlocks={studentBlocks}
            jury={certification.jury}
            juryComment={certification.juryComment}
            juryDecision={certification.juryDecision}
            readOnly={isStudentOnHold || isStudentInRedemption}
          />
        }
        {!isAuthCertifier && isStudentInRedemption &&
          <StudentJuryDecisionSection
            title={t(`studentBlock.jury.juryRedemptionDecisionTitle`)}
            juryDecision={certification?.redemptionJuryDecision}
            juryComment={certification?.redemptionJuryComment}
          />
        }
        {!isAuthCertifier &&
          <StudentJuryDecisionSection
            title={t(`studentBlock.jury.juryDecisionTitle`)}
            juryDecision={certification?.juryDecision}
            juryComment={certification?.juryComment}
          />
        }
      </Grid>
    </Grid>
  );
};

export default StudentBlocks;


const MissingAuditorBox = ({ title, subTitle }) => {
  return (
    <Paper elevation={3} sx={{ marginBottom: 2 }}>
      <Box sx={{ margin: 3, position: 'relative' }}>
        <Typography variant="h4" gutterBottom sx={{ fontWeight: 700 }}>
          {title}

        </Typography>
        <Typography
          variant="subtitle2"
          sx={{ fontWeight: 400, fontSize: '15px' }}
        >
          {subTitle}

        </Typography>
      </Box>
    </Paper>)
}
