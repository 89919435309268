import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { IApiError } from 'src/utils/axios';
import {
  deleteStudentEvaluation as deleteStudentEvaluationApi,
  DELETE_STUDENT_EVALUATION_KEY
} from '../../utils/api';

const EVALUATIONS_TRANSLATE_KEY = 'evaluations.listAll';

const useDeleteStudentEvaluation = (studentId: string) => {
  const { t } = useTranslation();
  const [evaluationDeleted, setEvaluationDeleted] = useState<any>();

  const { enqueueSnackbar } = useSnackbar();

  const { mutate: deleteEvaluation, isLoading: isDeletingEvaluation } =
    useMutation(
      () => deleteStudentEvaluationApi(studentId, evaluationDeleted.id),
      {
        mutationKey: DELETE_STUDENT_EVALUATION_KEY,
        onSuccess: () => {
          setEvaluationDeleted(null);
          enqueueSnackbar(t(`${EVALUATIONS_TRANSLATE_KEY}.delete.successMessage`), {
            variant: 'success'
          });
        },
        onError: (error: IApiError) => {
          enqueueSnackbar(error.message, {
            variant: 'error'
          });
        }
      }
    );

  return {
    evaluationDeleted,
    setEvaluationDeleted,
    deleteEvaluation,
    isDeletingEvaluation
  };
};

export default useDeleteStudentEvaluation;
