import { IUser } from '../../../models/user';
import { axiosInt } from '../../../utils/axios';
import {
  ICreateAccountData,
  IRequestPasswordRecoveryData,
  IResetPasswordData
} from './types';

export const FETCH_USER_KEY = 'fetchUser';
export const fetchUser = async (): Promise<IUser> => {
  const response = await axiosInt.get('/profile');

  return response.data;
};

export const LOGIN_KEY = 'login';
export const login = async ({
  email,
  password
}: {
  email: string;
  password: string;
}): Promise<{ accessToken: string }> => {
  const response = await axiosInt.post<{ accessToken: string }>('/user/login', {
    email,
    password
  });

  return response.data;
};

export const REQUEST_PASSWORD_RECOVERY_KEY = 'requestPasswordRecovery';
export const requestPasswordRecovery = async (
  data: IRequestPasswordRecoveryData
): Promise<void> => {
  const response = await axiosInt.post('/user/forgot-password', data);

  return response.data;
};

export const RESET_PASSWORD_KEY = 'resetPassword';
export const resetPassword = async (
  data: IResetPasswordData
): Promise<{ message: string }> => {
  const response = await axiosInt.post('/user/reset-password', data);

  return response.data;
};

export const CREATE_ACCOUNT_KEY = 'createAccount';
export const createAccount = async (
  data: ICreateAccountData
): Promise<{ accessToken: string }> => {
  const response = await axiosInt.post('/user/accept-invitation', data);

  return response.data;
};
