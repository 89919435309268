import { useTranslation } from 'react-i18next';
import Label from '../../../../components/Label';
import { VerificationStatus } from 'src/utils/const';

interface IProps {
  status: VerificationStatus;
}

const MAP_STATUS_TO_COLOR = {
  [VerificationStatus.Verified]: 'success',
  [VerificationStatus.Rejected]: 'error',
  [VerificationStatus.NotAdded]: 'warning',
  [VerificationStatus.PendingVerification]: 'info',
  [VerificationStatus.Draft]: 'info'
};

const STUDENT_EVALUATIONS_TABLE_TRANSLATE_KEY =
  'evaluations.studentEvaluations.status';

const Status: React.FC<IProps> = ({ status }) => {
  const { t } = useTranslation();

  return (
    <Label color={MAP_STATUS_TO_COLOR[status] as any}>
      {t(`${STUDENT_EVALUATIONS_TABLE_TRANSLATE_KEY}.${status}`)}
    </Label>
  );
};

export default Status;
