import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';
import { IApiError } from '../../../../../utils/axios';

import {
  createCertificateFolder as createCertificateFolderApi,
  CREATE_CERTIFICATE_FOLDER_KEY
} from '../../../utils/api';

const useCreateCertificateFolder = () => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    mutate: createCertificateFolder,
    isLoading: isCreatingCertificateFolder
  } = useMutation(createCertificateFolderApi, {
    mutationKey: CREATE_CERTIFICATE_FOLDER_KEY,
    onError: (error: IApiError) => {
      enqueueSnackbar(error.message, {
        variant: 'error'
      });
    }
  });

  return {
    createCertificateFolder,
    isCreatingCertificateFolder
  };
};

export default useCreateCertificateFolder;
