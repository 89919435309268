const notifications = {
  notifications: 'Notifications',
  timelineNotifications: {
    markAllAsRead: 'Tout marquer comme lu'
  },
  seeMore: 'Vue détaillée',
  noNotifications: 'Pas de notification'
};

export default notifications;
