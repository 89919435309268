import { useQuery } from 'react-query';
import { IUser } from 'src/models/user';
import { IApiError } from '../../../../utils/axios';
import {
  fetchStudent,
  FETCH_STUDENT_KEY
} from '../../utils/api';

const useFetchStudent = (
  url: string,
  options?: any
) => {
  const {
    isLoading: isFetchingStudent,
    error: errorFetchingStudent,
    data: student
  } = useQuery<IUser, IApiError>(
    [FETCH_STUDENT_KEY, url],
    () => fetchStudent(url),
    options
  );

  return {
    isFetchingStudent,
    errorFetchingStudent,
    student
  };
};

export default useFetchStudent;
