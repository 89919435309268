
import { useSnackbar } from 'notistack';
import { useMutation, useQueryClient } from 'react-query';
import { IApiError } from 'src/utils/axios';
import {
    UPDATE_JURY_DECISION_KEY,
    FETCH_STUDENT_BLOCKS_KEY,
    updateJuryDecision as updateJuryDecisionApi
} from '../utils/api';
import { FETCH_STUDENT_CERTIFICATION_KEY } from 'src/features/profile/utils/api';

const useUpdateJuryDecision = () => {
    const queryClient = useQueryClient();

    const { enqueueSnackbar } = useSnackbar();

    const {
        mutate: updateJuryDecision,
        isLoading: isUpdatingJuryDecision
    } = useMutation(
        ({ studentId, data }: any) =>
            updateJuryDecisionApi(studentId, data),
        {
            mutationKey: UPDATE_JURY_DECISION_KEY,
            onSuccess: () => {
                queryClient.invalidateQueries([FETCH_STUDENT_BLOCKS_KEY]);
                queryClient.invalidateQueries([FETCH_STUDENT_CERTIFICATION_KEY]);
            },
            onError: (error: IApiError) => {
                enqueueSnackbar(error.message, {
                    variant: 'error'
                });
            }
        }
    );

    return {
        updateJuryDecision,
        isUpdatingJuryDecision
    };
};

export default useUpdateJuryDecision;
