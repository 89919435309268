import {
  Avatar,
  Button,
  Typography,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  MenuItem,
  Grid,
  Divider
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import TableActions from 'src/components/Table/TableActions';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import React from 'react';
import useToggleVisibility from '../../../../hooks/useToggleVisibility';
import { Mode } from '../../../../utils/types';
import CreateEditSchoolModal from './CreateEditSchoolModal';
import { ISchool } from 'src/models/school';
import { useNavigate, useParams } from 'react-router-dom';
import { CohortRoutes } from '../../routes';

interface IProps {
  allSchools: ISchool[];
  schools: Partial<ISchool>[];
  canManageSchools: boolean;
  page?: Mode;
  onSaveSchool: any;
  onDeleteSchool(index: number, school: Partial<ISchool>): void;
}

const SchoolList: React.FC<IProps> = ({
  page,
  schools,
  canManageSchools,
  onDeleteSchool,
  allSchools,
  onSaveSchool
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    isVisible: isVisibleModal,
    show: showModal,
    hide: hideModal
  } = useToggleVisibility(false);

  const closeModal = () => {
    hideModal();
  };

  const handleOnClick = (schoolId: string) => {
    if (!!id) {
      navigate(
        CohortRoutes.ViewCohortSchool.replace(':cohortId', id).replace(
          ':schoolId',
          schoolId
        )
      );
    }
  };

  const handleSubmitSchool = (schools: Partial<ISchool>[]) => {
    onSaveSchool(schools);
    closeModal();
  };

  return (
    <>
      <Card sx={{ p: 3 }}>
        {schools.length === 0 && (
          <>
            <CardContent>
              <Typography>
                {t(`cohorts.common.schools.noSchoolsInvited`)}
              </Typography>
            </CardContent>
            <Divider />
          </>
        )}

        <Table>
          <TableHead>
            <TableRow>
              <TableCell key="name" align="left">
                {t(`cohorts.common.schools.boxTitle`)}
              </TableCell>
              <TableCell key="actions" align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {schools.map((school, index) => (
              <TableRow
                hover={true}
                key={school.id}
              >
                <TableCell align="left" sx={{ display: 'flex', alignItems: 'center' }} onClick={() => handleOnClick(school.id)}>
                  <Avatar alt={school.longName} src={school.avatar} sx={{ mr: 2 }} />
                  <Typography>{school.longName}</Typography>
                </TableCell>
                <TableCell align="right">
                  <TableActions>
                    <MenuItem
                      key={'action-edit'}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 1,
                        minWidth: '120px'
                      }}
                      disabled={!canManageSchools}
                      onClick={() => onDeleteSchool(index, school)}
                    >
                      <Typography style={{ paddingRight: '14px' }}>
                        {t(
                          `acl.actions.delete`
                        )}
                      </Typography>
                      <DeleteIcon fontSize="small" />
                    </MenuItem>

                  </TableActions>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Grid container>
          <Grid
            item
            sm={12}
            display="flex"
            alignItems="center"
            justifyContent="end"
            sx={{ mt: 3 }}
          >
            {canManageSchools && (<>
              <Button
                variant="contained"
                size="medium"
                onClick={showModal}
                sx={{ mb: { xs: '10px' } }}
              >
                <AddIcon sx={{ mr: 1 }} />
                {t(`cohorts.common.schools.addSchools`)}
              </Button>
            </>
            )}
          </Grid>
        </Grid>
      </Card >
      <CreateEditSchoolModal
        page={page}
        allSchools={allSchools}
        open={isVisibleModal}
        schools={schools}
        onClose={closeModal}
        onSubmit={handleSubmitSchool}
      />
    </>
  );
};

export default SchoolList;
