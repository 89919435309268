import { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  Paper,
  Box,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent
} from '@mui/material';
import { IJobIndex } from 'src/models/job';
import { t } from 'i18next';
import { DateFormat, formatDate } from 'src/utils/dates';
import { printUserName } from 'src/utils/userHelpers';
import useToggleVisibility from 'src/hooks/useToggleVisibility';
import useFetchStudentFrozenJob from 'src/features/profile/hooks/api/job/useFetchStudentFrozenJob';
import {
  ArrayFormBoxJob,
  ArrayFormBoxFooter,
  ArrayFormBoxList
} from 'src/components/ArrayFormBoxJob';

import {
  ArrayFormBoxListActions,
  ArrayFormBoxListItem
} from './../../../../../components/ArrayFormBox';

import InfoItem from '../InfoItem';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { JobStatuses } from 'src/utils/const';

export const StyledArrayFormBoxListItem = styled(ArrayFormBoxListItem)(
  ({ theme }) => `
      display: flex;
      justify-content: space-between;
      padding: ${theme.spacing(2, 4)};
      border-bottom: 1px solid ${theme.colors.alpha.black[10]};
  `
);

export const StyledReactQuill = styled('div')`
  .ql-editor {
    padding: 5px 0 0 0;
  }
  .ql-container {
    font-size: 14px;
  }
  .ql-editor.ql-blank::before {
    font-style: normal;
  }
  .ql-container.ql-disabled .ql-tooltip,
  .ql-clipboard {
    display: none;
  }
`;

export const StyledReactQuillWithSpace = styled('div')`
  .ql-editor {
    padding: 16px;
  }
  .ql-container {
    font-size: 14px;
  }
  .ql-editor.ql-blank::before {
    font-style: normal;
  }
  .ql-container.ql-disabled .ql-tooltip,
  .ql-clipboard {
    display: none;
  }
`;

const JOB_MISSIONS_BASE_KEY = 'profile.common.jobMissions';
const JOB_KNOWLEDGE_BASE_KEY = 'profile.common.jobRequiredKnowledge';
const JOB_KNOW_HOW_BASE_KEY = 'profile.common.jobRequiredKnowHow';

const NoJobDetailsAdded = styled(Typography)(
  ({ theme }) => `
      padding: ${theme.spacing(2.5, 4)};
    `
);

function JobsTable({ jobs }: { jobs: IJobIndex[] }) {
  const [studentId, setStudentId] = useState('');

  const [jobId, setJobId] = useState('');

  const [previewedJob, setPreviewedJob] = useState(null);

  const {
    isVisible: isVisibleFrozenJobModal,
    show: showAssignModal,
    hide: hideAssignModal
  } = useToggleVisibility(false);

  const handleCloseFrozenJobModal = () => {
    setStudentId('');
    setJobId('');
    setPreviewedJob(null);
    hideAssignModal();
  };

  const {
    frozenJob,
    isFetchingStudentFrozenJob,
    refetch: refetchFrozenJob
  } = useFetchStudentFrozenJob(studentId, jobId, {
    enabled: isVisibleFrozenJobModal
  });

  const handleClickFrozenJob = (studentId: string, jobId: string, job: {}) => {
    setStudentId(studentId);
    setJobId(jobId);
    setPreviewedJob(job);
    showAssignModal();
  };
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>
              {t('profile.student.job.frozenJobsTable.columns.company')}
            </TableCell>
            <TableCell>
              {t('profile.student.job.frozenJobsTable.columns.tutorName')}
            </TableCell>
            <TableCell>
              {t('profile.student.job.frozenJobsTable.columns.tutorEmail')}
            </TableCell>
            <TableCell>
              {t('profile.student.job.frozenJobsTable.columns.status')}
            </TableCell>
            <TableCell>
              {t('profile.student.job.frozenJobsTable.columns.frozenAt')}
            </TableCell>
            <TableCell>
              {t('profile.student.job.frozenJobsTable.columns.createdAt')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {jobs
            .filter((job) => !!job.frozenAt || !!job.deletedAt)
            .sort(
              (a, b) =>
                new Date(b.frozenAt).getTime() - new Date(a.frozenAt).getTime()
            )
            .map((job, index) => (
              <TableRow
                hover={true}
                key={index}
                sx={
                  !!job.deletedAt
                    ? { backgroundColor: 'rgba(255, 242, 244, 1)' }
                    : {}
                }
                onClick={() => handleClickFrozenJob(job.studentId, job.id, job)}
              >
                <TableCell align="left"> {job.company?.name} </TableCell>
                <TableCell align="left">{printUserName(job.tutor)}</TableCell>
                <TableCell align="left">{job.tutor?.email}</TableCell>
                <TableCell align="left">
                  {t(`profile.student.job.statuses.${job.status}`)}
                </TableCell>
                <TableCell align="left">
                  {formatDate(job.frozenAt, DateFormat.DateAndTime)}
                </TableCell>
                <TableCell align="left">
                  {formatDate(job.createdAt, DateFormat.DateAndTime)}{' '}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <Dialog
        maxWidth="lg"
        open={isVisibleFrozenJobModal && !!frozenJob}
        onClose={handleCloseFrozenJobModal}
        sx={{ padding: 10, paddingBottom: 10, marginBottom: 5 }}
      >
        {!!frozenJob && (
          <DialogContent sx={{ paddingBottom: 10 }}>
            <Grid
              container
              sx={{
                marginY: 5,
                width: '50%'
              }}
            >
              <InfoItem
                property={t(
                  'profile.student.job.frozenJobsTable.columns.company'
                )}
                value={previewedJob.company?.name}
              />
              <InfoItem
                property={t(
                  'profile.student.job.frozenJobsTable.columns.tutorName'
                )}
                value={printUserName(previewedJob.tutor)}
              />
              <InfoItem
                property={t(
                  'profile.student.job.frozenJobsTable.columns.tutorEmail'
                )}
                value={previewedJob.tutor?.email}
              />
              <InfoItem
                property={t(
                  'profile.student.job.frozenJobsTable.columns.status'
                )}
                // value={previewedJob.status}
                value={t(`profile.student.job.statuses.${previewedJob.status}`)}
              />
              <InfoItem
                property={t(
                  'profile.student.job.frozenJobsTable.columns.frozenAt'
                )}
                value={formatDate(
                  previewedJob.frozenAt,
                  DateFormat.DateAndTime
                )}
              />
              <InfoItem
                property={t(
                  'profile.student.job.frozenJobsTable.columns.createdAt'
                )}
                value={formatDate(
                  previewedJob.createdAt,
                  DateFormat.DateAndTime
                )}
              />
            </Grid>
            <ArrayFormBoxJob title={t(`${JOB_MISSIONS_BASE_KEY}.boxTitle`)}>
              {frozenJob?.jobMissions.length === 0 && (
                <NoJobDetailsAdded variant="subtitle2">
                  {t(`${JOB_MISSIONS_BASE_KEY}.noJobMissionAdded`)}
                </NoJobDetailsAdded>
              )}
              <ArrayFormBoxList>
                {frozenJob?.jobMissions?.map((jobMission, index) => (
                  <StyledArrayFormBoxListItem key={index}>
                    <Grid
                      container
                      sx={{
                        display: 'flex',
                        flexFlow: 'nowrap',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Grid xs={8} item>
                        <Box>
                          <Typography style={{ fontWeight: 'bold' }}>
                            {jobMission.name}
                          </Typography>
                          <StyledReactQuill>
                            <ReactQuill
                              value={jobMission.activity}
                              readOnly
                              theme={'bubble'}
                            />
                          </StyledReactQuill>
                        </Box>
                      </Grid>
                      <Grid xs={2} item>
                        <Typography>
                          {t(
                            `${JOB_MISSIONS_BASE_KEY}.autonomyLevels.${jobMission.autonomyLevel}`
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </StyledArrayFormBoxListItem>
                ))}
              </ArrayFormBoxList>
            </ArrayFormBoxJob>
            <ArrayFormBoxJob title={t(`${JOB_KNOWLEDGE_BASE_KEY}.boxTitle`)}>
              {frozenJob?.jobRequiredKnowledge?.length === 0 && (
                <NoJobDetailsAdded variant="subtitle2">
                  {t(`${JOB_KNOWLEDGE_BASE_KEY}.noJobRequiredKnowledgeAdded`)}
                </NoJobDetailsAdded>
              )}
              <ArrayFormBoxList>
                {frozenJob.jobRequiredKnowledge?.map((knowledge, index) => (
                  <StyledArrayFormBoxListItem key={index}>
                    <Grid
                      container
                      sx={{
                        display: 'flex',
                        flexFlow: 'nowrap',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Grid xs={10} item>
                        <Box>
                          <StyledReactQuill>
                            <ReactQuill
                              value={knowledge.knowledge}
                              readOnly
                              theme={'bubble'}
                            />
                          </StyledReactQuill>
                        </Box>
                      </Grid>
                    </Grid>
                  </StyledArrayFormBoxListItem>
                ))}
              </ArrayFormBoxList>
            </ArrayFormBoxJob>
            <ArrayFormBoxJob title={t(`${JOB_KNOW_HOW_BASE_KEY}.boxTitle`)}>
              {frozenJob.jobRequiredKnowHow?.length === 0 && (
                <NoJobDetailsAdded variant="subtitle2">
                  {t(`${JOB_KNOW_HOW_BASE_KEY}.noJobRequiredKnowHowAdded`)}
                </NoJobDetailsAdded>
              )}
              <ArrayFormBoxList>
                {frozenJob.jobRequiredKnowHow?.map((knowHow, index) => (
                  <StyledArrayFormBoxListItem key={index}>
                    <Grid
                      container
                      sx={{
                        display: 'flex',
                        flexFlow: 'nowrap',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Grid xs={10} item>
                        <Box>
                          <StyledReactQuill>
                            <ReactQuill
                              value={knowHow.knowHow}
                              readOnly
                              theme={'bubble'}
                            />
                          </StyledReactQuill>
                        </Box>
                      </Grid>
                    </Grid>
                  </StyledArrayFormBoxListItem>
                ))}
              </ArrayFormBoxList>
            </ArrayFormBoxJob>
          </DialogContent>
        )}
      </Dialog>
    </TableContainer>
  );
}

export default JobsTable;
