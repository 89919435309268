import { IAddress } from './address';
import { IBaseModel } from './base-model';
import { ICompanyEmployee } from './company-employee';

export enum CompanyStatus {
  Active = 'active',
  Inactive = 'inactive'
}

export interface ICompany extends IBaseModel {
  id: string;
  schoolId: string;
  name: string;
  shortName: string;
  longName: string;
  companyRegistrationNo: string;
  status: CompanyStatus;
  industry: string;
  type: string;
  capital: string;
  capitalType: string;
  noOfEmployees: string;
  companyLogo: string;
  addresses?: IAddress[];
  employees: ICompanyEmployee[];
}
