import { lazy } from 'react';
import SuspenseRouteLoader from '../../components/SuspenseRouteLoader';

const Companies = SuspenseRouteLoader(lazy(() => import('./pages/Companies')));
const CreateCompany = SuspenseRouteLoader(
  lazy(() => import('./pages/CreateCompany'))
);
const Company = SuspenseRouteLoader(lazy(() => import('./pages/Company')));
const CompanyEmployee = SuspenseRouteLoader(
  lazy(() => import('../profile/pages/CompanyEmployee'))
);

export const CompanyRoutes = {
  ListAll: '/companies',
  Create: '/companies/create',
  View: '/companies/:id/view',
  CompanyEmployee: '/companies/:companyId/employees/:employeeId'
};

const routes = [
  {
    path: CompanyRoutes.ListAll,
    element: <Companies />
  },
  {
    path: CompanyRoutes.Create,
    element: <CreateCompany />
  },
  {
    path: CompanyRoutes.View,
    element: <Company />
  },
  {
    path: CompanyRoutes.CompanyEmployee,
    element: <CompanyEmployee />
  }
];

export default routes;
