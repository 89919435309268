import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { IJob } from 'src/models/job';
import Confirm from 'src/components/Confirm';
import { IJobRequiredKnowledge } from 'src/models/job-required-knowledge';
import JobRequiredKnowledgeList from './JobRequiredKnowledgeList';
import useAddJobRequiredKnowledge from '../../../../hooks/api/job/useAddJobRequiredKnowledge';
import useUpdateJobRequiredKnowledge from '../../../../hooks/api/job/useUpdateJobRequiredKnowledge';
import useDeleteJobRequiredKnowledge from '../../../../hooks/api/job/useDeleteJobRequiredKnowledge';
import { IUser } from 'src/models/user';
import { studentIsOnHold } from 'src/utils/userHelpers';

interface IProps {
  job: IJob;
  canManage: boolean;
  student: IUser;
}

const JOB_REQUIRED_KNOWLEDGE_BASE_KEY =
  'profile.common.studentJob.delete.jobRequiredKnowledge';

const JobRequiredKnowledge: React.FC<IProps> = ({ job, canManage, student }) => {
  const { t } = useTranslation();

  const { addJobRequiredKnowledge, isAddingJobRequiredKnowledge } =
    useAddJobRequiredKnowledge();

  const { updateJobRequiredKnowledge, isUpdatingJobRequiredKnowledge } =
    useUpdateJobRequiredKnowledge();

  const {
    deleteJobRequiredKnowledge,
    jobRequiredKnowledgeToBeDeleted,
    setJobRequiredKnowledgeToBeDeleted,
    isDeletingJobRequiredKnowledge
  } = useDeleteJobRequiredKnowledge();

  const handleRemoveJobRequiredKnowledge = (
    _: number,
    jobRequiredKnowledge: IJobRequiredKnowledge
  ) => {
    setJobRequiredKnowledgeToBeDeleted(jobRequiredKnowledge);
  };

  const handleEditJobRequiredKnowledge = (
    _: number,
    newjobRequiredKnowledge: IJobRequiredKnowledge
  ) => {
    updateJobRequiredKnowledge(newjobRequiredKnowledge);
  };

  const handleAddJobRequiredKnowledge = (
    jobRequiredKnowledge: IJobRequiredKnowledge
  ) => {
    addJobRequiredKnowledge({ ...jobRequiredKnowledge, jobId: job.id });
  };

  return (
    <React.Fragment>
      <JobRequiredKnowledgeList
        jobRequiredKnowledge={job?.jobRequiredKnowledge}
        onAddJobRequiredKnowledge={handleAddJobRequiredKnowledge}
        onEditJobRequiredKnowledge={handleEditJobRequiredKnowledge}
        onRemoveJobRequiredKnowledge={handleRemoveJobRequiredKnowledge}
        isAddingJobRequiredKnowledge={isAddingJobRequiredKnowledge}
        isEditingJobRequiredKnowledge={isUpdatingJobRequiredKnowledge}
        jobStatus={job?.status}
        canManage={canManage}
        readOnly={studentIsOnHold(student)}
      />
      <Confirm
        title={t(`${JOB_REQUIRED_KNOWLEDGE_BASE_KEY}.modalTitle`)}
        onClose={() => setJobRequiredKnowledgeToBeDeleted(null)}
        onConfirm={() =>
          deleteJobRequiredKnowledge(jobRequiredKnowledgeToBeDeleted)
        }
        open={!!jobRequiredKnowledgeToBeDeleted}
        isConfirming={isDeletingJobRequiredKnowledge}
      >
        {jobRequiredKnowledgeToBeDeleted && (
          <Trans i18nKey={`${JOB_REQUIRED_KNOWLEDGE_BASE_KEY}.modalBody`}>
            Are you sure you want to delete
            <strong>{' required knowledge '}</strong>
            from address list?
          </Trans>
        )}
      </Confirm>
    </React.Fragment>
  );
};

export default JobRequiredKnowledge;
