import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';
import { IApiError } from '../../../../utils/axios';
import {
  storeDocument as storeDocumentApi,
  STORE_DOCUMENT_KEY
} from '../../utils/api';


const useStoreDocument = () => {
    const { enqueueSnackbar } = useSnackbar();

  const { mutate: storeDocument, isLoading: isStoringDocument } = useMutation(
    ({data, url}:any) => storeDocumentApi(data, url),
    {
      mutationKey: STORE_DOCUMENT_KEY,
      onError: (error: IApiError) => {
        enqueueSnackbar(error.message, {
          variant: 'error'
        });
      }
    }
  );

  return {
    storeDocument,
    isStoringDocument
  };
};

export default useStoreDocument;
