import { useQuery } from 'react-query';
import { ICertificate } from 'src/models/certificate';
import { ISchool } from 'src/models/school';
import { IApiError } from '../../../../utils/axios';
import { fetchAllSchools, FETCH_ALL_SCHOOLS_KEY } from '../../utils/api';

const useFetchAllSchools = () => {
  const {
    error: errorFetchingSchools,
    data: schools,
    isFetching: isFetchingSchools,
  } = useQuery<ISchool[], IApiError>([FETCH_ALL_SCHOOLS_KEY], () => 
    fetchAllSchools()
  );

  return {
    errorFetchingSchools,
    schools,
    isFetchingSchools
  };
};

export default useFetchAllSchools;
