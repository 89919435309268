import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { axiosInt } from '../../utils/axios';

const BaseUrlResolver = ({ children }) => {
  const [baseUrl, setBaseUrl] = useState(() => localStorage.getItem('apiUrl'));

  useEffect(() => {
    if (!baseUrl) {
      // const subdomain = window.location.hostname.split('.')[0];
      const BASE_URL = process.env.REACT_APP_BASE_API_URL as string;

      axios
        // .get(`${BASE_URL}urls/${subdomain}`)
        .post(`${BASE_URL}urls`, { domain: window.location.host })
        .then(function (response) {
          localStorage.setItem('apiUrl', response.data.url);
          axiosInt.defaults.baseURL = response.data.url;
          setBaseUrl(response.data.url);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [baseUrl]);

  if (!baseUrl) return null;

  return <React.Fragment>{children}</React.Fragment>;
};

export default BaseUrlResolver;
