import { FC, ReactNode } from 'react';
import { Box, useTheme } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { SideMenu, SideMobileMenu } from './Sidebar';
import Header from './Header/index';
import FillOutRequiredFieldsAlert from '../components/FillOutRequiredFieldsAlert';

interface AccentSidebarLayoutProps {
  children?: ReactNode;
}

const AccentSidebarLayout: FC<AccentSidebarLayoutProps> = () => {
  const theme = useTheme();

  return (
    <Box id='PageWrapper'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      }}>
      <Header id='PageHeader' sx={{ width: '100%', height: theme.header.height }} />
      <Box id='PageContent' sx={{ width: '100%', height: `calc(100% - ${theme.header.height})`, display: 'flex' }} >
        <SideMenu sx={{ height: '100%', flexShrink: 0, display: { xs: 'none', lg: 'inline-block' } }} />
        <SideMobileMenu sx={{ height: '100%', flexShrink: 0, display: { xs: 'inline-block', lg: 'none' } }} />
        <Box
          id='PageContent'
          sx={{ flexGrow: '1', display: 'flex', flexDirection: 'column', overflowX: 'clip', overflowY: 'scroll' }}        >
          <Box flexGrow={1}
            sx={{
              padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
              [theme.breakpoints.up('md')]: {
                padding: `${theme.spacing(3)} ${theme.spacing(4)}`
              }
            }}>
            <FillOutRequiredFieldsAlert />
            <Outlet />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AccentSidebarLayout;
