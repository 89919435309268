import { FC, ReactNode, createContext, useEffect, useReducer } from 'react';
import { IUser, UserableType } from '../../../models/user';
import PropTypes from 'prop-types';
import { fetchUser, login as loginApi } from '../utils/api';
import { axiosInt } from '../../../utils/axios';
import {
  JWT_AUTH_METHOD,
  INITIALIZE_ACTION,
  LOGIN_ACTION,
  LOGOUT_ACTION,
  UPDATE_ACTION
} from '../utils/constants';
import { AuthMethods, INITIALIZE, LOGIN, LOGOUT, UPDATE } from '../utils/types';
import useFetchStudentInfo from '../../students/hooks/api/useFetchStudentInfo';

interface AuthState {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: IUser | null;
}

interface AuthContextValue extends AuthState {
  method: AuthMethods;
  login: (email: string, password: string) => Promise<void>;
  loginSuccess: (token: string) => Promise<void>;
  logout: () => void;
  updateProfile: (data: IUser) => void;
}

// Reducer action types
type InitializeAction = {
  type: INITIALIZE;
  payload: {
    isAuthenticated: boolean;
    user: IUser | null;
  };
};

type LoginAction = {
  type: LOGIN;
  payload: {
    user: IUser;
  };
};

type LogoutAction = {
  type: LOGOUT;
};

type UpdateAction = {
  type: UPDATE;
  payload: {
    user: IUser;
  };
};

type Action = InitializeAction | LoginAction | LogoutAction | UpdateAction;

// Reducer actions
const initUserAction = (user: IUser | null): InitializeAction => {
  return {
    type: INITIALIZE_ACTION,
    payload: {
      isAuthenticated: !!user,
      user
    }
  };
};

const loginUserAction = (user: IUser): LoginAction => {
  return {
    type: LOGIN_ACTION,
    payload: {
      user
    }
  };
};

const logoutAction = (): LogoutAction => {
  return {
    type: LOGOUT_ACTION
  };
};

const updateAction = (user: IUser): UpdateAction => {
  return {
    type: UPDATE_ACTION,
    payload: {
      user
    }
  };
};

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const setSession = (accessToken: string | null): void => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axiosInt.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axiosInt.defaults.headers.common.Authorization;
  }
};

// Reducer handler
const handlers: Record<
  string,
  (state: AuthState, action: Action) => AuthState
> = {
  INITIALIZE: (state: AuthState, action: InitializeAction): AuthState => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state: AuthState, action: LoginAction): AuthState => {
    const { user } = action.payload;
    return {
      ...state,
      user,
      isAuthenticated: true
    };
  },
  LOGOUT: (state: AuthState): AuthState => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  UPDATE: (state: AuthState, action: UpdateAction): AuthState => {
    const { user } = action.payload;
    return {
      ...state,
      user: { ...state.user, ...user }
    };
  }
};

const reducer = (state: AuthState, action: Action): AuthState =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

// Initialize auth context
const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: JWT_AUTH_METHOD,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  updateProfile: () => Promise.resolve(),
  loginSuccess: () => Promise.resolve()
});

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken) {
          setSession(accessToken);
          const user = await fetchUser();
          dispatch(initUserAction(user));
        } else {
          dispatch(initUserAction(null));
        }
      } catch (err) {
        setSession(null);
        dispatch(initUserAction(null));
      }
    };

    initialize();
  }, []);

  useFetchStudentInfo({
    enabled: state.user?.userableType === UserableType.Student
  });

  const loginSuccess = async (token: string) => {
    setSession(token);
    const user = await fetchUser();
    dispatch(loginUserAction(user));
  };

  const login = async (email: string, password: string): Promise<void> => {
    const { accessToken } = await loginApi({ email, password });
    loginSuccess(accessToken);
  };

  const logout = async (): Promise<void> => {
    setSession(null);
    dispatch(logoutAction());
  };

  const updateProfile = (data: IUser): void => {
    dispatch(updateAction(data));
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: JWT_AUTH_METHOD,
        login,
        loginSuccess,
        logout,
        updateProfile
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
