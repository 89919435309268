const companyEmployees = {
    companyEmployee: {
        title: "Company Employee"
    },
    companyEmployees: {
        title: "Company Employees"
    },
    schoolCompaniesEmployees: {
        title: "Companies Employees",
        table: {
            columns: {
                firstName: 'First Name',
                lastName: 'Last Name',
                email: 'Email',
                status: 'Status',
                company: 'Company',
                createdAt: 'Created at',
                actions: 'Actions'
            }
        }
    },
    deletePopup: {
        modalTitle: 'Delete company employee',
        modalBody: 'Are you sure you want to delete {{email}} company employee ?',
        successMessage: 'Company employee successfully deleted',
        // errorMessage: 'Error while deleting company employee',
    },
    resendInvitationPopup: {
        modalTitle: 'Resend invitations',
        modalBody: 'Are you sure you want to resend invitation to {{email}} ?',
        successMessage: 'Resend invitations link successfully sent!'
    }
}

export default companyEmployees;