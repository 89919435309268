import { lazy } from 'react';
import SuspenseRouteLoader from '../../components/SuspenseRouteLoader';

const Tasks = SuspenseRouteLoader(lazy(() => import('./pages/Tasks')));
const Task = SuspenseRouteLoader(lazy(() => import('./pages/Task')));
const CreateTaskManual = SuspenseRouteLoader(
  lazy(() => import('./pages/CreateTaskManual'))
);
const RedirectToStudent = SuspenseRouteLoader(
  lazy(() => import('./pages/RedirectToStudent'))
);

export const TaskRoutes = {
  Task: '/tasks/:id',
  Tasks: '/tasks',
  CreateTaskManual: '/tasks/create-task-manual',
  RedirectToStudent: '/tasks/redirect-to-student/:cohortId/:schoolId/:juryType'
};

const routes = [
  {
    path: TaskRoutes.Task,
    element: <Task />
  },
  {
    path: TaskRoutes.Tasks,
    element: <Tasks />
  },
  {
    path: TaskRoutes.CreateTaskManual,
    element: <CreateTaskManual />
  },
  {
    path: TaskRoutes.RedirectToStudent,
    element: <RedirectToStudent />
  }
];

export default routes;
