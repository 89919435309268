import { useSnackbar } from 'notistack';
import { useMutation, useQueryClient } from 'react-query';
import { IApiError } from '../../../../utils/axios';
import {
  FETCH_NOTIFICATIONS,
  FETCH_NUMBER_OF_UNREAD_NOTIFICATIONS,
  markAllRead as markAllReadApi,
  MARK_ALL_READ
} from '../../utils/api';

const useMarkAllRead = () => {
  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const { mutate: markAllRead, isLoading: isMarkingAllRead } =
    useMutation(markAllReadApi, {
      mutationKey: MARK_ALL_READ,
      onSuccess: () => {
        queryClient.invalidateQueries([FETCH_NOTIFICATIONS]);
        queryClient.invalidateQueries([FETCH_NUMBER_OF_UNREAD_NOTIFICATIONS]);
      },
      onError: (error: IApiError) => {
        enqueueSnackbar(error.message, {
          variant: 'error'
        });
      }
    });

  return {
    isMarkingAllRead,
    markAllRead
  };
};

export default useMarkAllRead;
