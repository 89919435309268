import { useSnackbar } from 'notistack';
import { useMutation, useQueryClient } from 'react-query';
import { IApiError } from '../../../../utils/axios';
import {
  FETCH_COHORT_SCHOOL_STUDENTS_KEY,
  inviteStudent as inviteStudentApi,
  INVITE_STUDENT_KEY
} from '../../utils/api';

const useInviteStudent = ({ schoolId, cohortId }) => {
  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const { mutate: inviteStudent, isLoading: isInvitingStudent } = useMutation(
    inviteStudentApi,
    {
      mutationKey: INVITE_STUDENT_KEY,
      onSuccess: () => {
        queryClient.invalidateQueries([
          FETCH_COHORT_SCHOOL_STUDENTS_KEY,
          cohortId,
          schoolId
        ]);
      },
      onError: (error: IApiError) => {
        enqueueSnackbar(error.message, {
          variant: 'error'
        });
      }
    }
  );

  return {
    inviteStudent,
    isInvitingStudent
  };
};

export default useInviteStudent;
