import { lazy } from 'react';
import SuspenseRouteLoader from '../../components/SuspenseRouteLoader';

const Student = SuspenseRouteLoader(
  lazy(() => import('../profile/pages/Student'))
);

const Students = SuspenseRouteLoader(
  lazy(() => import('./pages/company-view/TutorAssignedStudents'))
);

const AssignedSchool = SuspenseRouteLoader(
  lazy(() => import('./pages/student-view/AssignedSchool'))
);

const JobDescription = SuspenseRouteLoader(
  lazy(() => import('./pages/student-view/JobDescription'))
);

const StudentBlock = SuspenseRouteLoader(
  lazy(() => import('../student-blocks/pages/Block'))
);

const FinalExamDocument = SuspenseRouteLoader(
  lazy(() => import('./pages/common/FinalExamDocument'))
);

const ViewStudentEvaluation = SuspenseRouteLoader(
  lazy(() => import('../evaluations/pages/ViewStudentEvaluation'))
);

const ViewStudentSelfEvaluations = SuspenseRouteLoader(
  lazy(() => import('../evaluations/pages/ViewStudentSelfEvaluations'))
);

export const StudentRoutes = {
  Student: '/students/:studentId',
  Students: '/students',
  School: '/students/school/assigned',
  Job: '/students/job-description',
  Evaluations: '/students/evaluations',
  Block: '/students/:studentId/blocks/:blockId',
  StudentFinalExamDocument: '/students/:studentId/final-exam-document',
  ViewStudentEvaluation: '/students/:studentId/evaluations/:evaluationId/view'
};

const routes = [
  {
    path: StudentRoutes.Student,
    element: <Student />
  },
  {
    path: StudentRoutes.Students,
    element: <Students />
  },
  {
    path: StudentRoutes.School,
    element: <AssignedSchool />
  },
  {
    path: StudentRoutes.Job,
    element: <JobDescription />
  },
  {
    path: StudentRoutes.Block,
    element: <StudentBlock />
  },
  {
    path: StudentRoutes.ViewStudentEvaluation,
    element: <ViewStudentEvaluation />
  },
  {
    path: StudentRoutes.Evaluations,
    element: <ViewStudentSelfEvaluations />
  },
  
  // {
  //   path: StudentRoutes.StudentFinalExamDocument,
  //   element: <FinalExamDocument />
  // }
  // {
  //   path: StudentRoutes.Evaluations,
  //   element: <Evaluations />
  // }
];

export default routes;
