import { Alert, AlertTitle, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const STUDENT_JOB_TRANSLATE_KEY = 'profile.student.job';

interface IProps {
  onReject(): void;
  readOnly: boolean;
}

const EnrolledAlert: React.FC<IProps> = ({ onReject , readOnly}) => {
  const { t } = useTranslation();

  return (
    <Alert
      severity="success"
      sx={{ border: '1px solid #89d964' }}
      // action={
      //   <Button
      //     disabled={readOnly}
      //     color="error"
      //     size="medium"
      //     onClick={onReject}
      //     variant="contained"
      //     sx={{
      //       mt: 1,
      //       minWidth: '90px'
      //     }}
      //   >
      //     {t(`${STUDENT_JOB_TRANSLATE_KEY}.reject.button`)}
      //   </Button>
      // }
    >
      <AlertTitle>
        <strong>
          {t(
            `${STUDENT_JOB_TRANSLATE_KEY}.reviewAlert.successfullyVerifiedTitle`
          )}
        </strong>
      </AlertTitle>
      {t(`${STUDENT_JOB_TRANSLATE_KEY}.reviewAlert.successfullyVerifiedText`)}
    </Alert>
  );
};

export default EnrolledAlert;
