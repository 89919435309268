import {
  Box,
  Button,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { useNavigate } from 'react-router';
import TableContainer from '../../../../components/Table/TableContainer';
import { IEvaluation } from '../../../../models/evaluation';
import { IUser } from '../../../../models/user';
import { Mode } from '../../../../utils/types';
import Status from './Status';
import { useTranslation } from 'react-i18next';
import TableActions from 'src/components/Table/TableActions';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import useAuth from 'src/features/auth/hooks/useAuth';
import CheckIcon from '@mui/icons-material/Check';
import React from 'react';
import { canEvaluationBeVerified, canUserDeleteEvaluations, canUserFillEvaluation, canUserVerifyEvaluation, canUserViewEvaluation, isVerified } from 'src/features/evaluations/utils/evaluationsHelpers';
import { StudentRoutes } from 'src/features/students/routes';
import DeleteIcon from '@mui/icons-material/Delete';

import { getAssignToRoleIdentifier, getRoleFromAssignToRoleIdentifier } from '../../utils/evaluationConfigurationsHelpers';
import { areSameUsers, printUserName, studentIsInRedemption, studentIsOnHold } from 'src/utils/userHelpers';
import { isCompanyEmployee, isStudent } from 'src/utils/userTypes';

const StudentEvaluationsTable: React.FC<{
  studentEvaluations: IEvaluation[];
  student: IUser;
  evaluationConfigurationFilterId: string
  evaluationConfigurationFilterChanged?(id: string): void,
  onDeleteEvaluation?(evaluation: IEvaluation): void,
  onOpenEvaluation?(evaluation: IEvaluation, mode: Mode): void,
  viewingSelfEvaluations: boolean
}> = ({
  studentEvaluations,
  student,
  evaluationConfigurationFilterId,
  viewingSelfEvaluations,
  evaluationConfigurationFilterChanged,
  onDeleteEvaluation,
  onOpenEvaluation
}) => {

    const { user: auth } = useAuth();
    const { t } = useTranslation();

    const showVerifiedBy = (!isStudent(auth) && !isCompanyEmployee(auth));

    const isStudentOnHold = studentIsOnHold(student);
    const isStudentInRedemption = studentIsInRedemption(student);

    return (
      <TableContainer
        isFetching={false}
        perPage={100}
        total={studentEvaluations.length}
      >
        {evaluationConfigurationFilterId &&
          <Button
            sx={{ position: 'relative', top: '0', left: '0', mb: '10px' }}
            onClick={() => evaluationConfigurationFilterChanged(null)}
          >
            {t(`evaluations.studentEvaluations.table.newActions.viewAll`)}
          </Button>
        }
        <Table>
          <TableHead>
            <TableRow>
              <TableCell key="name" align="left">
                {t(`evaluations.studentEvaluations.table.newColumns.name`)}
              </TableCell>
              <TableCell key="linkedTo" align="left">
                {t(`evaluations.studentEvaluations.table.newColumns.linkedTo`)}
              </TableCell>
              <TableCell key="assignedTo" align="left">
                {t(`evaluations.studentEvaluations.table.newColumns.assignedTo`)}
              </TableCell>
              <TableCell key="assignedToIdentifier" align="left">
                {t(`evaluations.studentEvaluations.table.newColumns.assignedToIdentifier`)}
              </TableCell>
              {showVerifiedBy &&
                <TableCell key="verifiedBy" align="left">
                  {t(`evaluations.studentEvaluations.table.newColumns.verifiedBy`)}
                </TableCell>
              }
              <TableCell key="isRdemption" align="left">
                {t(`evaluations.studentEvaluations.table.newColumns.redemption`)}
              </TableCell>
              <TableCell key="status" align="left">
                {t(`evaluations.studentEvaluations.table.newColumns.status`)}
              </TableCell>
              <TableCell key="actions" align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {studentEvaluations
              .filter((evaluation) => Boolean(evaluationConfigurationFilterId) ? (evaluationConfigurationFilterId === evaluation.evaluationConfigurationId) : true)
              .filter((evaluation) => {
                return isStudentInRedemption ? true : !evaluation.evaluationConfiguration.isRedemption
              })
              .map((evaluation) => {
                const canUserView = canUserViewEvaluation(auth, evaluation);
                const canUserDelete = canUserDeleteEvaluations(auth);
                const canUserFill = canUserFillEvaluation(auth, evaluation);
                const canUserVerify = canUserVerifyEvaluation(auth, evaluation);
                const canBeVerified = canEvaluationBeVerified(evaluation);
                const verified = isVerified(evaluation);

                const isRedemptionEvaluationConfiguration = evaluation.evaluationConfiguration.isRedemption;
                const disableActions = isStudentOnHold || (isStudentInRedemption != isRedemptionEvaluationConfiguration)

                return (<TableRow
                  hover={true}
                  key={evaluation.id}
                  onClick={() => { onOpenEvaluation(evaluation, Mode.View) }}
                >
                  <TableCell align="left">{evaluation.evaluationConfiguration.name}</TableCell>
                  <TableCell align="left">{evaluation.evaluationConfiguration?.linkedTo?.name}</TableCell>
                  <TableCell align="left">
                    <Box>
                      <Typography color={areSameUsers(auth, evaluation.assignedTo) && 'primary.main'} noWrap variant="body1">
                        {printUserName(evaluation.assignedTo)}
                      </Typography>
                      <Typography color={areSameUsers(auth, evaluation.assignedTo) && 'primary.main'} noWrap variant="subtitle2">
                        {evaluation.assignedTo.email}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell align="left">{getAssignToRoleIdentifier(getRoleFromAssignToRoleIdentifier(evaluation.assignToIdentifier), true)}</TableCell>
                  {showVerifiedBy &&
                    <TableCell align="left">
                      {evaluation.verifiedBy && (
                        <Box>
                          <Typography color={areSameUsers(auth, evaluation.verifiedBy) && 'primary.main'} noWrap variant="body1">
                            {printUserName(evaluation.verifiedBy)}
                          </Typography>
                          <Typography color={areSameUsers(auth, evaluation.verifiedBy) && 'primary.main'} noWrap variant="subtitle2">
                            {evaluation.verifiedBy.email}
                          </Typography>
                        </Box>
                      )}
                    </TableCell>
                  }
                  <TableCell align="left">
                    <Typography> 
                      {
                        evaluation.evaluationConfiguration.isRedemption 
                          ? <CheckIcon/>
                          : <></>
                      }
                    </Typography>
                  </TableCell>
                  <TableCell align="left"> <Status status={evaluation.status} /> </TableCell>
                  <TableCell align="right">
                    <TableActions>
                      <MenuItem
                        disabled={!canUserView}
                        key='action-view-evaluation'
                        onClick={(e) => {
                          e.stopPropagation()
                          onOpenEvaluation(evaluation, Mode.View)
                        }}>
                        <span style={{ paddingRight: "5px" }} >{t(`evaluations.studentEvaluations.table.newActions.viewEvaluation`)}</span>
                        <VisibilityIcon fontSize="small" />
                      </MenuItem>
                      {canUserFill &&
                        <MenuItem
                          disabled={!canUserFill || disableActions}
                          key='action-fill-out-evaluation'
                          onClick={(e) => {
                            e.stopPropagation()
                            onOpenEvaluation(evaluation, Mode.Edit)
                          }}>
                          <span style={{ paddingRight: "5px" }} >{t(`evaluations.studentEvaluations.table.newActions.fillEvaluation`)}</span>
                          <EditIcon fontSize="small" />
                        </MenuItem>
                      }
                      {canUserVerify &&
                        <MenuItem
                          disabled={!canBeVerified || disableActions}
                          key='action-verify-evaluation'
                          onClick={(e) => {
                            e.stopPropagation()
                            onOpenEvaluation(evaluation, Mode.View)
                          }}>
                          <span style={{ paddingRight: "5px" }} >{t(`evaluations.studentEvaluations.table.newActions.verifyEvaluation`)}</span>
                          <CheckIcon fontSize="small" />
                        </MenuItem>
                      }
                      {canUserDelete &&
                        <MenuItem
                          disabled={verified || disableActions}
                          key='action-delete-evaluation'
                          onClick={(e) => {
                            e.stopPropagation()
                            onDeleteEvaluation(evaluation)
                          }}>
                          <span style={{ paddingRight: "5px" }} >{t(`evaluations.studentEvaluations.table.newActions.deleteEvaluation`)}</span>
                          <DeleteIcon fontSize="small" color='error' />
                        </MenuItem>
                      }
                    </TableActions>
                  </TableCell>
                </TableRow>)
              })}
          </TableBody>
        </Table>
      </TableContainer >
    );
  };

export default StudentEvaluationsTable;
