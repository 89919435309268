import { t } from 'i18next';
import { IRole } from '../../../models/role';

export const getSelectedItemsNameInit = (data) => {
  const itemName = data?.map((item) => item.name);
  return itemName;
};

export const getSelectedItemsNamePrint = (data) => {
  const itemName = data?.map((item) => item.name).join(', ');

  return itemName;
};

export const getRolesNamePrint = (roles: Partial<IRole>[]) => {
  return roles.map((item) => t(`acl.listAll.name.${item.name}`)).join(', ');
};