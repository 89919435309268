import { useTranslation } from 'react-i18next';
import { Grid, Button } from '@mui/material';
import { Box } from '@mui/system';
import { Mode } from 'src/utils/types';
import { Formik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import * as Yup from 'yup';
import { IUser } from 'src/models/user';
import InfoItem from './InfoItem';
import { IUpdateUserRoles } from '../../utils/types';
import RolesFields from './RolesFields';
import { getRolesNamePrint } from '../../../evaluations/utils/getSelectedItemsName';

const PROFILE_ROLES_TRANSLATE_KEY = 'profile.roles';
const VALIDATION_TRANSLATE_KEY = 'common.validation';

interface IProps {
  handleEdit(data: IUpdateUserRoles): void;
  personalInfoLocalMode: Mode;
  isUpdatingPersonalInfo: boolean;
  setPersonalInfoLocalMode(Mode: Mode): void;
  user: IUser;
}

const RolesDetails: React.FC<IProps> = ({
  handleEdit,
  personalInfoLocalMode,
  isUpdatingPersonalInfo,
  setPersonalInfoLocalMode,
  user
}) => {
  const { t } = useTranslation();

  const roleNames = getRolesNamePrint(user.roles);
  const userRoles = user.roles.map((role) => role.name);

  if (personalInfoLocalMode === Mode.View) {
    return (
      <Grid
        container
        sx={{
          display: 'flex',
          boxSizing: 'border-box',
          flexFlow: 'flex-wrap',
          width: '100%'
        }}
      >
        <InfoItem
          property={t(`${PROFILE_ROLES_TRANSLATE_KEY}.roles`)}
          value={roleNames}
        />
      </Grid>
    );
  }

  return (
    <div>
      <Formik<IUpdateUserRoles>
        initialValues={{
          roles: userRoles
        }}
        onSubmit={handleEdit}
        validationSchema={Yup.object().shape({
          roles: Yup.array().min(
            1,
            t(`${VALIDATION_TRANSLATE_KEY}.oneRoleIsRequired`)
          )
        })}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <RolesFields entityType={user.userableType} />
            <Box display="flex" justifyContent="flex-end" mt={3}>
              <Button
                color="secondary"
                size="medium"
                variant="outlined"
                onClick={() => setPersonalInfoLocalMode(Mode.View)}
              >
                {t('common.buttons.cancel')}
              </Button>
              <LoadingButton
                loading={isUpdatingPersonalInfo}
                sx={{
                  ml: 2
                }}
                variant="contained"
                size="medium"
                type="submit"
              >
                {t('common.buttons.save')}
              </LoadingButton>
            </Box>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default RolesDetails;
