import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';
import UpdateIcon from '@mui/icons-material/Update';
import UpdateDisabledIcon from '@mui/icons-material/UpdateDisabled';
import { useTranslation } from 'react-i18next';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { useNavigate } from 'react-router-dom';
import TableContainer from '../../../../components/Table/TableContainer';
import TableHeaderSortLabel from '../../../../components/Table/TableHeaderSortLabel';
import { ISort } from '../../../../utils/types';
import React, { useState } from 'react';
import { TaskRoutes } from '../../routes';
import { DateFormat, formatDate } from '../../../../utils/dates';
import { ITask } from 'src/models/task';
import Status from '../common/Status';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TaskDetailsModal from '../common/TaskDetailsModal';
import { buildRedirectUrl } from '../../utils/taskBuilders';
import { TaskStatuses, TaskType } from '../../../../utils/const';
import useAuth from '../../../auth/hooks/useAuth';
import { printUserName } from 'src/utils/userHelpers';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import { shouldShowTaskDaysLeft } from '../../utils/shouldShowTaskDaysLeft';
import { buildTaskStatusText } from '../../utils/buildTaskStatusText';

interface IProps {
  page: number;
  isFetching: boolean;
  total: number;
  tasks: ITask[];
  sort?: ISort;
  perPage: number;
  onChangePage(page: number): void;
  onChangeSort(sort: any): void;
  onUpdateStatus?(task: ITask): void;
}

const TASKS_TRANSLATE_KEY = 'tasks.listAll';

export enum Columns {
  Name = 'name',
  Status = 'status',
  AssignedBy = 'creation_user_id',
  CreatedAt = 'created_at',
  DueDate = 'due_date',
  School = 'school',
  Cohort = 'cohort'
}

const TasksTable: React.FC<IProps> = ({
  page,
  isFetching,
  total,
  tasks,
  sort,
  perPage,
  onChangePage,
  onChangeSort,
  onUpdateStatus
}) => {
  const { t } = useTranslation();

  const { user } = useAuth();

  const navigate = useNavigate();

  const [taskInModal, setTaskInModal] = useState<ITask>(null);

  const handleRedirectToTask = (task: ITask) => {
    if ([TaskStatuses.Done, TaskStatuses.Overdue].includes(task.status)) {
      return;
    }

    return navigate(buildRedirectUrl(task, user).href);
  };

  return (
    <>
      <TableContainer
        currentPage={page}
        isFetching={isFetching}
        onChangePage={onChangePage}
        total={total}
        perPage={perPage}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell key="name" align="left" width={400}>
                <TableHeaderSortLabel
                  columnName={Columns.Name}
                  sort={sort}
                  onChangeSort={onChangeSort}
                >
                  {t(`${TASKS_TRANSLATE_KEY}.table.columns.name`)}
                </TableHeaderSortLabel>
              </TableCell>
              <TableCell key="school" align="left">
                {t(`${TASKS_TRANSLATE_KEY}.table.columns.school`)}
              </TableCell>
              <TableCell key="cohort" align="left">
                {t(`${TASKS_TRANSLATE_KEY}.table.columns.cohort`)}
              </TableCell>
              <TableCell key="status" align="left">
                <TableHeaderSortLabel
                  columnName={Columns.Status}
                  sort={sort}
                  onChangeSort={onChangeSort}
                >
                  {t(`${TASKS_TRANSLATE_KEY}.table.columns.status`)}
                </TableHeaderSortLabel>
              </TableCell>
              <TableCell key="creationUserId" align="left">
                <TableHeaderSortLabel
                  columnName={Columns.AssignedBy}
                  sort={sort}
                  onChangeSort={onChangeSort}
                >
                  {t(`${TASKS_TRANSLATE_KEY}.table.columns.assignedBy`)}
                </TableHeaderSortLabel>
              </TableCell>
              <TableCell key="dueDate" align="left">
                <TableHeaderSortLabel
                  columnName={Columns.DueDate}
                  sort={sort}
                  onChangeSort={onChangeSort}
                >
                  {t(`${TASKS_TRANSLATE_KEY}.table.columns.dueDate`)}
                </TableHeaderSortLabel>
              </TableCell>
              <TableCell key="createdAt" align="left">
                <TableHeaderSortLabel
                  columnName={Columns.CreatedAt}
                  sort={sort}
                  onChangeSort={onChangeSort}
                >
                  {t(`${TASKS_TRANSLATE_KEY}.table.columns.createdAt`)}
                </TableHeaderSortLabel>
              </TableCell>
              <TableCell key="actions" align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {tasks.map((task) => (
              <TableRow
                hover={true}
                key={task.id}
                onClick={() => {
                  navigate(TaskRoutes.Task.replace(':id', task.id));
                }}
              >
                <TableCell align="left">
                  <Typography>{task.name}</Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography>{task?.school?.shortName}</Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography>{task?.cohort?.shortName}</Typography>
                </TableCell>
                <TableCell align="left">
                  <Status taskStatus={task.status} />
                </TableCell>
                <TableCell align="left">
                  <Typography>{printUserName(task.creationUser)}</Typography>
                </TableCell>
                <TableCell align="left" width={170}>
                  {task.dueDate && (
                    <>
                      {task.status === TaskStatuses.ToDo &&
                        shouldShowTaskDaysLeft(task.dueDate, 15) && (
                          <Typography fontSize={14} color="error">
                            {differenceInCalendarDays(
                              new Date(task.dueDate),
                              new Date()
                            )}{' '}
                            {t(`${TASKS_TRANSLATE_KEY}.daysLeft`)}
                          </Typography>
                        )}
                      <Typography fontSize={12}>
                        {formatDate(task.dueDate, DateFormat.DateAndTime)}
                      </Typography>
                    </>
                  )}
                </TableCell>
                <TableCell align="left">
                  {formatDate(task.createdAt, DateFormat.DateAndTime)}
                </TableCell>
                <TableCell align="right" width={100}>
                  <React.Fragment>
                    {task.status !== TaskStatuses.TerminatedBySystem &&
                      task.status !== TaskStatuses.Done && (
                        <Tooltip
                          title={t(
                            `${TASKS_TRANSLATE_KEY}.table.actionTooltips.view`
                          )}
                          arrow
                        >
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleRedirectToTask(task);
                            }}
                            color="primary"
                          >
                            <AssignmentTurnedInIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      )}
                    <Tooltip
                      title={t(
                        `${TASKS_TRANSLATE_KEY}.table.actionTooltips.show`
                      )}
                      arrow
                    >
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          setTaskInModal(task);
                        }}
                        color="info"
                      >
                        <VisibilityIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    {task.status !== TaskStatuses.TerminatedBySystem &&
                      task.status !== TaskStatuses.Overdue &&
                      task.type === TaskType.Manually && (
                        <Tooltip
                          title={
                            t(
                              `${TASKS_TRANSLATE_KEY}.table.actionTooltips.update`
                            ) +
                            ' ' +
                            buildTaskStatusText(task)
                          }
                          arrow
                        >
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              onUpdateStatus(task);
                            }}
                            color="primary"
                          >
                            {task.status !== TaskStatuses.Done ? (
                              <UpdateIcon fontSize="small" />
                            ) : (
                              <UpdateDisabledIcon fontSize="small" />
                            )}
                          </IconButton>
                        </Tooltip>
                      )}
                  </React.Fragment>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {taskInModal && (
        <TaskDetailsModal
          task={taskInModal}
          onClose={() => setTaskInModal(null)}
          onRedirectToTask={handleRedirectToTask}
        />
      )}
    </>
  );
};

export default TasksTable;
