import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Permissions } from 'src/utils/const';
import PageLoader from '../../../../components/PageLoader';
import { Box } from '@mui/system';
import { Alert, AlertTitle, Grid, Tab } from '@mui/material';
import TabPanel from '@mui/lab/TabPanel';
import React from 'react';
import TabList from '@mui/lab/TabList';
import TabContext from '@mui/lab/TabContext';
import useTab from '../../../../hooks/useTab';
import {
  ContentBox,
  ContentCard
} from 'src/features/cohorts/components/common/StyledComponents';
import CohortCertificateBasicInfo from '../../../students/components/cohort-certificate/CohortCertificateBasicInfo';
import CertificateAcademicKit from 'src/features/certificates/components/certificate/CertificateAcademicKit';
import useFetchStudentInfo from '../../../students/hooks/api/useFetchStudentInfo';
import ApiError from '../../../../components/ApiError';
import StudentBlocks from 'src/features/student-blocks/components/blocks-list/StudentBlocks';
import { ProfileUpdateUrls } from 'src/utils/const';
import useFetchStudentBlocks from 'src/features/student-blocks/hooks/useFetchStudentBlocks';
// import FinalExamTab from './FinalExamTab';
import StudentEvaluations from '../../../evaluations/pages/StudentEvaluations';
import useAuth from 'src/features/auth/hooks/useAuth';
import StudentCertificationPassportTab from 'src/features/profile/components/profile/Student/StudentCertificationPassportTab/StudentCertificationPassportTab';
import useCan from 'src/hooks/useCan';
import useFetchStudentCertification from 'src/features/profile/hooks/api/useFetchStudentCertification';
import StudentPasswordCertificationStatusAlert from 'src/features/profile/components/profile/Student/StudentCertificationPassportTab/StudentPasswordCertificationStatusAlert';

const STUDENT_BASE_KEY = 'students.cohortCertificate';

export const TAB_PANEL_CSS = { padding: 0 };

export enum Tabs {
  CohortCertificate = 'CohortCertificate',
  Blocks = 'Blocks',
  Evaluations = 'Evaluations',
  CertificationPassport = 'CertificationPassport'
  // FinalExam = 'FinalExam'
}

const AssignedCohortCertificate = () => {
  const { t } = useTranslation();

  const { user } = useAuth();

  const { tab, setTab } = useTab(Tabs.CohortCertificate);

  const canManage = useCan(Permissions.StudentActions);

  const [canRequestPassportVerification, setCanRequestPassportVerification] = useState<boolean>()

  const handleTabChange = (_: React.SyntheticEvent, newValue: Tabs) => {
    setTab(newValue);
  };

  const { studentInfo, isFetchingStudentInfo, errorFetchingStudentInfo } =
    useFetchStudentInfo();

  const { studentBlocks, isFetchingStudentBlocks, errorFetchingStudentBlocks } =
    useFetchStudentBlocks(studentInfo?.user.userableId);

  const { studentCertification, isFetchingStudentCertification } = useFetchStudentCertification(studentInfo?.user.userableId)

  const error = errorFetchingStudentBlocks || errorFetchingStudentInfo;

  if (isFetchingStudentInfo || isFetchingStudentBlocks) {
    return <PageLoader />;
  }

  if (error) {
    return <ApiError error={error} />;
  }

  if (!studentInfo.certificate || !studentInfo.cohort) {
    return (
      <Alert severity="error">
        <AlertTitle> {t('students.missingInfo.title')}</AlertTitle>
        {t('students.missingInfo.text')}
      </Alert>
    );
  }

  return (
    <ContentBox>
      <TabContext value={tab}>
        <Box>
          <TabList onChange={handleTabChange} sx={{ mr: 1, mb: 1 }}>
            <Tab
              label={'Online Marketing 2022'}
              value={Tabs.CohortCertificate}
            />
            <Tab
              label={t(`${STUDENT_BASE_KEY}.tabs.evaluations`)}
              value={Tabs.Evaluations}
            />
            <Tab
              label={t(`${STUDENT_BASE_KEY}.tabs.certificationPassport`)}
              value={Tabs.CertificationPassport}
            />
            <Tab
              label={t(`${STUDENT_BASE_KEY}.tabs.blocks`)}
              value={Tabs.Blocks}
            />
            {/* <Tab
              label={t(`${STUDENT_BASE_KEY}.tabs.finalExam`)}
              value={Tabs.FinalExam}
            /> */}
          </TabList>
        </Box>
        <TabPanel value={Tabs.CohortCertificate} style={TAB_PANEL_CSS}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8}>
              <CohortCertificateBasicInfo
                cohort={studentInfo?.cohort}
                certificate={studentInfo?.certificate}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box sx={{ mt: '-24px' }}>
                {studentInfo?.certificate && (
                  <CertificateAcademicKit
                    certificate={studentInfo?.certificate}
                    canManageCertificates={false}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={Tabs.Blocks} sx={TAB_PANEL_CSS}>
          <ContentCard>
            <StudentBlocks studentBlocks={studentBlocks} certification={studentCertification}/>
          </ContentCard>
        </TabPanel>
        <TabPanel value={Tabs.Evaluations} sx={TAB_PANEL_CSS}>
          <StudentEvaluations student={studentInfo.user} viewingSelfEvaluations={true} />
        </TabPanel>
        <TabPanel value={Tabs.CertificationPassport} sx={TAB_PANEL_CSS}>
          {!isFetchingStudentCertification && (
            <>
              <StudentPasswordCertificationStatusAlert
                user={studentInfo.user}
                certification={studentCertification}
                canRequest={canRequestPassportVerification}
              />
              <StudentCertificationPassportTab
                user={user}
                certification={studentCertification}
                uploadUrl={ProfileUpdateUrls.StudentUploadDocument}
                deleteUrl={ProfileUpdateUrls.StudentDeleteDocument}
                setCanRequestPassportVerification={setCanRequestPassportVerification}
              />
            </>
          )}
        </TabPanel>
        {/* <TabPanel value={Tabs.FinalExam} sx={TAB_PANEL_CSS}>
          <FinalExamTab studentInfo={studentInfo} />
        </TabPanel> */}
      </TabContext>
    </ContentBox>
  );
};

export default AssignedCohortCertificate;
