import { ReactNode,  useRef, useState } from 'react';
import { DragEvent, createContext } from 'react';
import './DragAndDrop.css'

export interface DragAndDropContextValue {
    enabled: boolean
    forceDisabled: boolean
    enable(): void
    disable(): void
    onDragStart(e: DragEvent, target?: any): void,
    onDragEnd(e: DragEvent): void
    onDrag(e: DragEvent): void,
    onDragEnter(e: DragEvent, allowDrop?: boolean): void,
    onDragLeave(e: DragEvent): void,
    onDragOver(e: DragEvent, allowDrop?: boolean): void
    onDrop(e: DragEvent, target?: any): void
    getDragging(): any
}

function throwError() {
    throw new Error("Can't use context, DragAndDropProvider is missing")
}

export const DragAndDropContext = createContext<DragAndDropContextValue>({
    enabled: false,
    forceDisabled: false,
    enable() { throwError() },
    disable() { throwError() },
    onDragStart() { throwError() },
    onDragEnter() { throwError() },
    onDrag() { throwError() },
    onDragLeave() { throwError() },
    onDragEnd() { throwError() },
    onDrop() { throwError() },
    onDragOver() { throwError() },
    getDragging() { throwError() },
});


export interface DragAndDropProviderProps {
    children: ReactNode,
    onEnabled?(): void
    onDisabled?(): void
    onDropped?(dragged: any, droppedOn?: any): void,
    forceDisabled?: boolean
}

export const DragAndDropProvider = ({
    children,
    onEnabled,
    onDisabled,
    onDropped,
    forceDisabled = false,
}: DragAndDropProviderProps) => {

    const [state, setState] = useState({ enabled: false })

    const draggingTarget = useRef(null);
    const dragging = useRef(null);

    /**
     * 
     * @returns 
     */
    function isDisabled() {
        return forceDisabled || !state.enabled
    }

    /**
     * 
     */
    function resetRefs() {
        dragging.current = null
        draggingTarget.current = null
    }

    /**
     * 
     */
    function enable() {
        setState((prevState) => ({ ...prevState, enabled: true }))

        if (onEnabled) {
            onEnabled()
        }
    }

    /**
     * 
     */
    function disable() {
        setState((prevState) => ({ ...prevState, enabled: false }))

        if (onDisabled) {
            onDisabled()
        }
    }

    /**
     * 
     * @param e 
     * @param target 
     * @returns 
     */
    function onDragStart(e, target) {
        if (isDisabled()) return;

        (e.target as Element).classList.add('dragging')
        dragging.current = target ?? e.target;
    }

    /**
     * 
     * @param e 
     * @returns 
     */
    function onDragEnd(e) {
        if (isDisabled()) return;

        (e.target as Element).classList.remove('dragging')
        resetRefs()
    }

    /**
     * 
     * @param e 
     * @returns 
     */
    function onDrag(e) {
        if (isDisabled()) return;

        draggingTarget.current = { ...e }
    }

    /**
     * 
     * @param e 
     * @returns 
     */
    function onDragOver(e, allowDrop = true) {
        if (isDisabled()) return;

        // var rect = e.currentTarget.getBoundingClientRect();
        // const portentFromTop = (e.clientY - rect.top) * 100 / rect.height
        // if (portentFromTop <= 20) { // TOP
        // } else if (portentFromTop >= 80) { // BOTTOM
        // } else { } // MIDDLE

        if (!draggingTarget.current.target.isSameNode(e.currentTarget) && allowDrop) {
            e.preventDefault()
        }
    }

    /**
     * 
     * @param e 
     * @returns 
     */
    function onDragEnter(e, allowDrop = true) {
        if (isDisabled()) return;

        if (!draggingTarget.current.target.isSameNode(e.currentTarget) && allowDrop) {
            (e.target as Element).classList.add('drop-area-active')
        }
    }

    /**
     * 
     * @param e 
     * @returns 
     */
    function onDragLeave(e) {
        if (isDisabled()) return;

        (e.target as Element).classList.remove('drop-area-active')
    }

    /**
     * 
     * @param e 
     * @param target 
     * @returns 
     */
    function onDrop(e, target) {
        if (isDisabled()) return;

        (e.target as Element).classList.remove('drop-area-active')
        if (onDropped) {
            onDropped(dragging.current, target ?? e.target)
        }
        resetRefs()
    }

    return (
        <DragAndDropContext.Provider value={{
            // State
            enabled: !isDisabled(),
            forceDisabled,
            enable,
            disable,

            // Drag element
            onDragStart,
            onDragEnd,
            onDrag,

            // Drop area
            onDragOver,
            onDragEnter,
            onDragLeave,
            onDrop,

            // Refs
            getDragging: () => dragging.current
        }}>
            {children}
        </DragAndDropContext.Provider>
    );
};


