import DatePicker from '@mui/lab/DatePicker';
import LoadingButton from '@mui/lab/LoadingButton';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
  Tab,
  TextField,
  Typography,
  InputLabel,
  OutlinedInput,
  MenuItem,
  Select,
  FormControl
} from '@mui/material';
import { Formik } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import { createDueDate, createTriggerDate } from '../../../../utils/dates';
import { TriggerType } from '../../utils/types';

const TASK_BASE_KEY = 'cohorts.common.tasks.deadline';

interface IEditDeadline {
  dueDate: string;
  daysToComplete: number;
  type: DeadlineType;
  triggerDate: string;
  triggerType: TriggerType;
}

export enum DeadlineType {
  Fixed = 'fixed',
  Relative = 'relative'
}

const StyledTab = styled(TabPanel)(
  ({ theme }) => `
  padding: 0;
  padding-top: ${theme.spacing(2)};
  .MuiSvgIcon-root {
    color: rgba(34, 51, 84, 0.7);
  }
`
);

const EditTaskDeadlineModal = ({ open, onClose, onSubmit, task }) => {
  const { t } = useTranslation();

  const handleSubmit = (data: IEditDeadline) => {
    onSubmit({
      ...task,
      dueDate: data.type === DeadlineType.Relative ? null : data.dueDate,
      daysToComplete:
        data.type === DeadlineType.Relative ? data.daysToComplete : null,
      triggerType: data.triggerType,
      triggerDate:
        data.triggerType === TriggerType.Date ? data.triggerDate : null
    });
  };
  const triggerTypes = Object.entries(TriggerType).map(([key, value]) => ({
    key,
    value
  }));

  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={onClose}>
      <DialogTitle
        sx={{
          p: 3
        }}
      >
        <Typography variant="h4" gutterBottom>
          {t(`${TASK_BASE_KEY}.title`)}
        </Typography>
        <Typography variant="subtitle2">
          <Trans i18nKey={`${TASK_BASE_KEY}.subtitle1`}>
            Edit
            <strong>{{ name: task?.name }}</strong> deadline
          </Trans>
        </Typography>
      </DialogTitle>
      <Formik<IEditDeadline>
        initialValues={{
          dueDate: task?.dueDate || null,
          daysToComplete: task?.daysToComplete || '',
          type: task?.daysToComplete
            ? DeadlineType.Relative
            : DeadlineType.Fixed,
          triggerDate: task?.triggerDate || null,
          triggerType: task?.triggerType || TriggerType.Event
        }}
        onSubmit={handleSubmit}
      >
        {({
          handleSubmit,
          values,
          setFieldValue,
          touched,
          errors,
          handleBlur,
          handleChange
        }) => (
          <form onSubmit={handleSubmit} noValidate>
            <DialogContent
              dividers
              sx={{
                p: 3
              }}
            >
              <Typography variant="h4" sx={{ mb: 3 }}>
                {t(`${TASK_BASE_KEY}.trigger.subtitle`)}
              </Typography>
              <FormControl sx={{ width: '100%' }}>
                <InputLabel id="select-label">
                  {t(`${TASK_BASE_KEY}.triggerType.label`)}
                </InputLabel>
                <Select
                  sx={{ mb: 2 }}
                  error={Boolean(touched.triggerType && errors.triggerType)}
                  name="triggerType"
                  labelId={t(`${TASK_BASE_KEY}.triggerType.label`)}
                  value={values.triggerType}
                  onChange={handleChange}
                  variant="outlined"
                  input={
                    <OutlinedInput
                      label={t(`${TASK_BASE_KEY}.triggerType.label`)}
                    />
                  }
                >
                  {triggerTypes?.map((trigger, index) => (
                    <MenuItem key={index} value={trigger.value}>
                      {trigger.key}{' '}
                      {trigger.value === TriggerType.Event &&
                        task?.event &&
                        `(${t(`certificates.events.${task?.event}`)})`}
                    </MenuItem>
                  ))}
                </Select>
                {values.triggerType === TriggerType.Date && (
                  <DatePicker
                    label={t(`${TASK_BASE_KEY}.form.triggerDate.label`)}
                    value={values.triggerDate}
                    onChange={(triggerDate) =>
                      setFieldValue(
                        'triggerDate',
                        createTriggerDate(triggerDate)
                      )
                    }
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                    className="triggerDatePicker"
                  />
                )}
              </FormControl>
              <Typography variant="h4" sx={{ my: 3 }}>
                {t(`${TASK_BASE_KEY}.dueDate.subtitle`)}
              </Typography>
              <TabContext value={values.type}>
                <TabList
                  onChange={(_: React.SyntheticEvent, newValue: DeadlineType) =>
                    setFieldValue('type', newValue)
                  }
                  sx={{ mt: 2 }}
                >
                  <Tab
                    label={t(`${TASK_BASE_KEY}.tabs.tab1`)}
                    value={DeadlineType.Fixed}
                  />
                  <Tab
                    label={t(`${TASK_BASE_KEY}.tabs.tab2`)}
                    value={DeadlineType.Relative}
                  />
                </TabList>
                <StyledTab value={DeadlineType.Fixed}>
                  <DatePicker
                    label={t(`${TASK_BASE_KEY}.form.date.label`)}
                    value={values.dueDate}
                    onChange={(dueDate) => {
                      setFieldValue('dueDate', createDueDate(dueDate));
                    }}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                    className="dueDatePicker"
                  />
                </StyledTab>
                <StyledTab value={DeadlineType.Relative}>
                  <TextField
                    error={Boolean(
                      touched.daysToComplete && errors.daysToComplete
                    )}
                    fullWidth
                    margin="normal"
                    helperText={touched.daysToComplete && errors.daysToComplete}
                    label={t(`${TASK_BASE_KEY}.form.days.label`)}
                    placeholder={t(`${TASK_BASE_KEY}.form.days.placeholder`)}
                    name="daysToComplete"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.daysToComplete}
                    variant="outlined"
                    type="number"
                    sx={{ mt: 0, mb: 0 }}
                  />
                </StyledTab>
              </TabContext>
              <DialogActions sx={{ mt: 2, padding: '8px 0' }}>
                <Button
                  color="secondary"
                  size="medium"
                  variant="outlined"
                  onClick={onClose}
                >
                  {t('common.buttons.cancel')}
                </Button>
                <LoadingButton variant="contained" size="medium" type="submit">
                  {t('common.buttons.save')}
                </LoadingButton>
              </DialogActions>
            </DialogContent>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default EditTaskDeadlineModal;
