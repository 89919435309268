import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import useFetchAllCertificates from 'src/features/certificates/hooks/api/certificate/useFetchPublishedCertificates';
import { Mode } from 'src/utils/types';
import DragAndDropAvatar from '../../../../components/DragAndDropAvatar';
import { ICreateCohortForm } from '../../utils/types';
import { EditorWrapper } from '../common/StyledComponents';
import generateArrayOfYears from 'src/utils/yearsArray';

const FORM_BASE_KEY = 'cohorts.common.cohortForm';

interface IProps {
  mode?: Mode;
}

const CohortBasicInfoFields: React.FC<IProps> = ({ mode }) => {
  const {
    setFieldTouched,
    setFieldValue,
    touched,
    errors,
    handleBlur,
    handleChange,
    values,
    initialValues
  } = useFormikContext<ICreateCohortForm>();

  const handleFileUploaded = (url: string) => {
    setFieldValue('avatar', url);
    setFieldTouched('avatar');
  };

  const { t } = useTranslation();

  const { certificates } = useFetchAllCertificates();

  const years = generateArrayOfYears(20);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {mode == Mode.Create && (
            <FormControl fullWidth>
              <InputLabel id="mutiple-select-label">
                {t(`${FORM_BASE_KEY}.certificate.label`)}
              </InputLabel>
              <Select
                error={Boolean(touched.certificateId && errors.certificateId)}
                fullWidth
                label={t(`${FORM_BASE_KEY}.certificate.label`)}
                placeholder={t(`${FORM_BASE_KEY}.certificate.placeholder`)}
                value={values.certificateId}
                onBlur={handleBlur}
                onChange={handleChange}
                variant="outlined"
                name="certificateId"
              >
                {certificates?.map((certificate) => (
                  <MenuItem key={certificate.id} value={certificate.id}>
                    {certificate.longName}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error>
                {touched.certificateId && errors.certificateId}
              </FormHelperText>
            </FormControl>
          )}

          <TextField
            error={Boolean(touched.name && errors.name)}
            fullWidth
            margin="normal"
            helperText={touched.name && errors.name}
            label={t(`${FORM_BASE_KEY}.name.label`)}
            placeholder={t(`${FORM_BASE_KEY}.name.placeholder`)}
            name="name"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.name}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.shortName && errors.shortName)}
            fullWidth
            margin="normal"
            helperText={touched.shortName && errors.shortName}
            label={t(`${FORM_BASE_KEY}.shortName.label`)}
            placeholder={t(`${FORM_BASE_KEY}.shortName.placeholder`)}
            name="shortName"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.shortName}
            variant="outlined"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel id="year-of-certification-select-label">
              {t(`${FORM_BASE_KEY}.yearOfCertification.label`)}
            </InputLabel>
            <Select
              error={Boolean(touched.yearOfCertification && errors.yearOfCertification)}
              labelId="year-of-certification-select-label"
              label={t(`${FORM_BASE_KEY}.yearOfCertification.label`)}
              placeholder={t(`${FORM_BASE_KEY}.yearOfCertification.placeholder`)}
              name="yearOfCertification"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.yearOfCertification ?? ''}
              variant="outlined"
            >
              {years.map((year) => (
                <MenuItem value={year} key={year}>{year}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <DragAndDropAvatar
            placeholder={t(`${FORM_BASE_KEY}.avatar.placeholder`)}
            uploadingUrl="/images"
            onFileUploaded={handleFileUploaded}
            initialAvatarUrl={initialValues.avatar}
          />
        </Grid>

        <Grid item xs={12} sm={8}>
          <EditorWrapper>
            <Field name="description">
              {({ field }) => (
                <ReactQuill
                  value={field.value}
                  onChange={field.onChange(field.name)}
                  style={{
                    height: '138px'
                  }}
                  placeholder={t(`${FORM_BASE_KEY}.description.placeholder`)}
                />
              )}
            </Field>
          </EditorWrapper>
        </Grid>
      </Grid>
    </div>
  );
};

export default CohortBasicInfoFields;
