import { useSnackbar } from 'notistack';
import { useMutation, useQueryClient } from 'react-query';
import { IApiError } from '../../../../utils/axios';
import {
  FETCH_COHORT_KEY,
  updateCohort as updateCohortApi,
  UPDATE_COHORT_KEY
} from '../../utils/api';

const useUpdateCohort = () => {
  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const { mutate: updateCohort, isLoading: isUpdatingCohort } = useMutation(
    updateCohortApi,
    {
      mutationKey: UPDATE_COHORT_KEY,
      onSuccess: ({ id }) => {
        queryClient.invalidateQueries([FETCH_COHORT_KEY, id]);
      },
      onError: (error: IApiError) => {
        enqueueSnackbar(error.message, {
          variant: 'error'
        });
      }
    }
  );

  return {
    updateCohort,
    isUpdatingCohort
  };
};

export default useUpdateCohort;
