import { IBaseModel } from './base-model';
import { IFile } from './file';

export enum StudentDocumentType {
  Cv = 'cv',
  Diploma = 'diploma',
  Waiver = 'waiver',
  EvaluationTest = 'evaluation_test',
  CertificationPassport = 'passport',
  RedemptionCertificationPassport = 'redemption_passport'
}

export interface IStudentDocument extends IBaseModel {
  studentId: string;
  type: StudentDocumentType;
  file?: IFile;
}
