import { IBaseModel } from './base-model';
import { IFile } from './file';
import { IStudent } from './student';

export enum FinalExamStatus {
  Created = 'created',
  Pending = 'pending',
  Completed = 'completed',
  Passed = 'passed',
  Failed = 'failed'
}

export interface IFinalExam extends IBaseModel {
  studentId: string;
  date?: string;
  student?: IStudent;
  status: FinalExamStatus;
  file: IFile;
}
