import { useEffect, useState } from 'react';
import { IFolder } from '../../models/folder';
import {
  addNewFileToTree,
  addNewFolderToTree,
  deleteFileOrFolderFromTree,
  editFile,
  editFolderFromTree,
  mapApiFolderToTreeFolder,
  orderTree,
  setFileError
} from './utils';
import { IFolder as ITreeFolder } from './types';
import { IFile } from '../../models/file';

const useFileTreeData = (folders: IFolder[]) => {
  const [fileTree, setFileTree] = useState<ITreeFolder[]>(orderTree(folders.map(mapApiFolderToTreeFolder)));
  const [fileTreeCopy, setFileTreeCopy] = useState<ITreeFolder[] | undefined>();

  // TODO: check if this has some errors
  useEffect(() => {
    setFileTree(orderTree(folders.map(mapApiFolderToTreeFolder)));
  }, [folders]);

  const onDeleteFile = (id: string) => {
    setFileTree((tree) => deleteFileOrFolderFromTree(tree, id));
  };

  const onDeleteFolder = (id: string) => {
    setFileTree((tree) => deleteFileOrFolderFromTree(tree, id));
  };

  const onCreateFolder = (folder: IFolder) => {
    setFileTree((tree) => orderTree(addNewFolderToTree(tree, folder)));
  };

  const onEditFolder = (folder: IFolder) => {
    setFileTree((tree) => editFolderFromTree(tree, folder));
  };

  const onCreateFile = (file: IFile, treeFileToBeUpdated: Partial<IFile>) => {
    setFileTree((tree) => orderTree(addNewFileToTree(tree, file, treeFileToBeUpdated)));
  };

  const onCreateFileError = (file: Partial<IFile>) => {
    setFileTree((tree) => setFileError(tree, file));
  };

  const onEditFile = (file: Partial<IFile>) => {
    setFileTree((tree) => editFile(tree, file));
  };

  const onReorderingTree = () => {
    setFileTreeCopy(fileTree);
  };

  const onCancelReorderingTree = () => {
    setFileTreeCopy(undefined);
    setFileTree(fileTreeCopy);
  };

  const onReorderTree = () => {
    setFileTreeCopy(undefined);
  };

  return {
    fileTree,
    onDeleteFile,
    onDeleteFolder,
    onCreateFolder,
    onEditFolder,
    onCreateFile,
    onCreateFileError,
    setFileTree,
    onEditFile,
    onReorderingTree,
    onCancelReorderingTree,
    onReorderTree
  };
};

export default useFileTreeData;
