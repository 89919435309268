import {
  SchoolCourseDocumentType,
  TriggerType
} from '../utils/types';
import {
  StudentCertificationStatus,
  CertificationStatus,
  VerificationStatus
} from "src/utils/const";

const cohorts = {
  common: {
    tabs: {
      cohortInfoTab: 'Résumé',
      tasksTab: 'Tâches',
      schoolsTab: 'Écoles',
      certificationTab: `Dossier d’expertise professionnelle`,
      redemptionTab: `Dossier d’expertise professionnelle de rattrapage`
    },
    cohortForm: {
      certificate: {
        label: 'Certificat',
        placeholder: 'Selectionner le certificat'
      },
      name: {
        label: 'Nom de la cohorte',
        placeholder: 'Saisir le nom de la cohorte ici...'
      },
      shortName: {
        label: 'Diminutif Cohorte',
        placeholder: "Saisir l'abbréviation ici..."
      },
      description: {
        label: 'Description',
        placeholder: 'Saisir la description de la cohorte ici...'
      },
      avatar: {
        label: 'Logo de la cohorte',
        placeholder: 'Téléverer le logo'
      },
      yearOfCertification: {
        label: "Année de certification",
        placeholder: "Écrivez-ici votre année de certification"
      },
    },
    tasks: {
      boxTitle: 'TÂCHES DU CERTIFICAT',
      noTaskAdded: 'Aucune tâche',
      addTask: 'Ajouter une nouvelle tâche',
      rowLabels: {
        name: 'Nom de la tâche',
        deadline: 'Deadline / Jours nécessaires',
        trigger: 'Déclencheur'
      },
      deadline: {
        title: 'Paramètres tâches',
        subtitle1: 'Modifier <1>{{name}}</1> les paramètres',
        tabs: {
          tab1: 'Fixe',
          tab2: 'Relative',
          tab3: 'Déclencheur'
        },
        form: {
          date: {
            label: 'Deadline',
            placeholder: ''
          },
          days: {
            label: 'Jour(s) pour compléter',
            placeholder: 'Saisir le nombre de jours'
          },
          triggerDate: {
            label: 'Date de déclenchement',
            placeholder: ''
          }
        },
        triggerType: {
          label: 'Type de déclencheur',
          placeholder: ''
        },
        trigger: {
          subtitle: 'Déclencheur'
        },
        dueDate: {
          subtitle: 'Deadline'
        }
      },
      trigger: {
        successMessage: 'Succès du déclencheur de tâche'
      },
      triggerButton: 'Déclencheur',
      taskAlreadyTriggered:
        "La tâche est déjà déclenchée, seuls les utilisateurs n'ayant pas déclenché cette tâche auront accès à ce déclencheur.",
      triggerTypes: {
        [TriggerType.Date]: 'Date',
        [TriggerType.Event]: 'Événement',
        [TriggerType.Manually]: 'Manuellement'
      }
    },
    schools: {
      boxTitle: 'ÉCOLES DE LA COHORTE',
      addSchools: 'Ajouter une école',
      noSchoolsInvited: 'Aucune école',
      modalTitle: 'Inviter une école',
      modalSubtitle: 'Sélectionner les écoles à affecter à une cohorte',
      delete: {
        modalTitle: "Supprimer l'école",
        modalBody:
          "Confirmer la suppression de l'école <1>{{name}}</1> de la liste des écoles ?"
      },
      searchPlaceholder: 'Rechercher des écoles'
    },
    students: {
      boxTitle: 'Étudiants',
      addStudent: 'Ajouter un nouvel étudiant',
      noStudentsInvited: 'Aucun étudiant',
      modalTitle: 'Inviter un étudiant',
      modalSubtitle:
        'Saisir les champs ci-dessous pour inviter un étudiant dans la cohorte',
      form: {
        email: {
          label: 'E-mail',
          placeholder: "Saisir l'e-mail ici"
        },
        firstName: {
          label: 'Prénom',
          placeholder: 'Saisir le prénom ici'
        },
        lastName: {
          label: 'Nom de famille',
          placeholder: 'Saisir le nom de famille ici'
        }
      }
    }
  },
  assigned: {
    listAll: {
      title: 'Cohortes affectées',
      subtitle: "Liste des cohortes affectées à l'école"
    },
    view: {
      htmlTitle: 'Cohortes | {{name}}',
      subtitle: 'Détails de la cohorte',
      title: 'Cohorte {{ name }}',
      cohortDetails: 'Détails',
      certificateDetails: 'Détails du certificat',
      backToList: 'Retour aux cohortes affectées',
      students: 'Étudiants',
      documents: 'Documents',
      blocks: 'Blocs'
    },
    documents: {
      delete: {
        title: 'Supprimer le document',
        body: 'Confirmation de la suppression de <1>{{name}}</1> de la liste des documents ?'
      },
      types: {
        [SchoolCourseDocumentType.AnnualTimetables]: `Calendrier d’alternance/stage annuel`,
        [SchoolCourseDocumentType.ProofOfCommunications]:
          'Preuve de communication',
        [SchoolCourseDocumentType.SubmissionsOfTheQualiopi]:
          'Certification QUALIOPI',
        [SchoolCourseDocumentType.TrainersCvs]: 'CV des formateurs'
      },
      dragAndDropFiles: 'Drag & drop des documents ici',
      reviewAlert: {
        successfullyVerifiedTitle: 'Les documents sont validés',
        successfullyVerifiedText:
          "Les documents sont validés et l'école est désormais incluse dans la cohorte",
        requestVerificationTitle: 'Téléversement des documents',
        requestVerificationText:
          "Il faut téléverser au moins un document par section et soumettre l'ensemble au certificateur",
        pendingVerificationTitle: 'Vérification des documents en cours',
        pendingVerificationText:
          "Les documents ont été soumis pour validation, vous serez notifiés lorsqu'ils auront été approuvés par le certificateur",
        rejectedTitle: 'Les documents sont rejetés',
        rejectedText:
          'Les documents envoyés sont non conformes et le certificateur les a rejetés',
        rejectedMessageReason: 'Voir le message du certificateur',
        requestVerificationButton: 'Soumettre les documents',
        requestVerificationButtonDisabledTooltip:
          'Soumettre les documents pour validation'
      },
      requestVerification: {
        title: 'Soumettre les documents',
        body: 'Confirmer la demande de validation ?'
      },
      deadline: {
        uploadDocs: {
          expiredText: 'La deadline pour la tâche {{taskName}} est atteinte.'
        },
        uploadDocsVerify: {
          expiredText:
            'La deadline pour la tâche "Verify Course Documents" est atteinte.'
        }
      }
    }
  },
  listAll: {
    htmlTitle: 'Cohortes',
    title: 'Cohortes',
    subtitle: 'Liste des cohortes',
    startNewCohort: 'Créer une nouvelle cohorte',
    table: {
      columns: {
        cohort: 'COHORTE',
        createdAt: 'CRÉÉE LE'
      },
      actionTooltips: {
        edit: 'Modifier',
        delete: 'Supprimer',
        exportLicenseReports: 'Exporter les statistiques',
        exportStudentBlockDeliverablesStatusReport: 'Exporter le rapport d’état des livrables du bloc étudiant'
      }
    },
    delete: {
      successMessage: 'Confirmation de la suppression cohorte',
      errorMessage: 'Erreur lors de la suppression cohorte',
      modalTitle: 'Supprimer la cohorte',
      modalBody:
        'Confirmer la suppression de la cohorte <1>{{name}}</1> de la liste des cohortes ?'
    },
    search: {
      label: 'Rechercher',
      placeholder: 'Rechercher'
    },
    export: {
      label: 'Exporter les statistiques'
    }
  },
  create: {
    htmlTitle: 'Créer une nouvelle cohorte',
    title: 'Créer une nouvelle cohorte',
    subtitle: 'Saisir les champs ci-dessous pour créer une nouvelle cohorte',
    backToList: 'Retour aux cohortes'
  },
  view: {
    htmlTitle: 'Cohortes | {{name}}',
    subtitle: 'Détails de la cohorte',
    title: 'Information cohorte',
    backToList: 'Retour aux cohortes'
  },
  cohortSchool: {
    title: 'Information école liée à la cohorte',
    subtitle: 'Information école affectée à la cohorte',
    reject: {
      title: 'Rejeter les documents',
      body: 'Confirmer le rejet des documents ?',
      button: 'Rejeter',
      message: {
        label: 'Message',
        placeholder: 'Justifier le rejet des documents',
        placeholderJob: 'Expliquer pourquoi la fiche de poste est rejetée'
      }
    },
    verify: {
      title: 'Valider les documents',
      body: 'Confirmer la validation des documents ?',
      button: 'Valider'
    },
    reviewAlert: {
      successfullyVerifiedTitle: 'Documents validés',
      successfullyVerifiedText:
        "Les documents de l'école ont été validés par le certificateur",
      rejectedTitle: 'Documents rejetés',
      rejectedText:
        "Les documents de l'école ont été rejetés par le certificateur",
      rejectedMessageReason: "Voir le message d'information",
      pendingVerificationTitle: 'Validation des documents en cours',
      pendingVerificationText: "L'école a soumis les documents pour validation"
    },
    schoolInfoTab: 'Info école'
  },
  students: {
    listAll: {
      table: {
        columns: {
          studentName: 'Nom',
          studentFirstName: 'Prénom',
          studentLastName: 'Nom de famille',
          studentEmail: 'E-mail',
          studentStatus: 'Statut',
          studentGroup: 'Groupe',
          studentTutor: 'Tuteur',
          createdAt: 'Créé le',
          actions: 'Actions'
        }
      },
      search: {
        label: 'Rechercher',
        placeholder: 'Rechercher'
      },
      addStudent: 'Ajouter un étudiant',
      importStudents: 'Import CSV',
      importStudentsModal: {
        modalTitle: 'Importer des étudiants depuis CSV',
        modalSubtitle:
          'Veuillez télécharger le fichier CSV pour importer les données des étudiants',
        uploadButton: `Télécharger CSV et glisser-déposer ou cliquer pour télécharger`,
        download: {
          text1: `* Veuillez télécharger l'exemple de`,
          text2: 'et entrer les données réelles',
          file: 'CSV-FICHIER'
        },
        infoMessage: `Enregistrer {{numberOfStudents}} étudiants peut prendre un certain temps, une fois que vous avez cliqué sur Enregistrer, vous pouvez naviguer en toute sécurité.`,
        successMessage: `L'importation d'étudiants à partir d'un fichier CSV a été soumise avec succès pour traitement.`,
        errorMessage: ` E-mails ont déjà été utilisés. Certains d'entre eux sont: `,
        invalidData: 'Les données fournies sont dans un format invalide.',
        invalidCsvData: `CSV non valide. Veuillez télécharger et utiliser le fichier d'exemple`,
        table: {
          columns: {
            firstName: 'Prénom',
            lastName: 'Nom de famille',
            email: 'E-mail',
            dateOfBirth: 'Date de naissance',
            placeOfBirth: 'Lieu de naissance',
            group: 'Groupe'
          },
          loadInfo: {
            text1: '...et',
            text2: `plus d'étudiants chargés avec succès.`
          }
        }
      },
      assignTutorsToStudents: 'Etudiant/Tuteur CSV',
      assignTutorsToStudentsModal: {
        modalTitle: 'Importer des étudiants/tuteur depuis CSV',
        modalSubtitle:
          'Veuillez télécharger le fichier CSV pour importer les données des étudiants/tuteur',
        uploadButton: `Télécharger CSV et glisser-déposer ou cliquer pour télécharger`,
        download: {
          text1: `* Veuillez télécharger l'exemple de`,
          text2: 'et entrer les données réelles',
          file: 'CSV-FICHIER'
        },
        infoMessage: `Enregistrer {{numberOfRecords}} étudiants/tuteur peut prendre un certain temps, une fois que vous avez cliqué sur Enregistrer, vous pouvez naviguer en toute sécurité.`,
        successMessage: `L'importation d'étudiants/tuteur à partir d'un fichier CSV a été soumise avec succès pour traitement.`,
        errorMessage: `Les e-mails n'existent pas. Certains d'entre eux sont: `,
        invalidData: 'Les données fournies sont dans un format invalide.',
        invalidCsvData: `CSV non valide. Veuillez télécharger et utiliser le fichier d'exemple`,
        table: {
          columns: {
            tutorEmail: 'Tuteur Email',
            studentEmail: 'Etudiant Email',
          },
          loadInfo: {
            text1: '...et',
            text2: `plus d'étudiants/tuteur chargés avec succès.`
          }
        }
      },
      deleteStudent: {
        modalTitle: `Supprimer l'étudiant`,
        modalBody: `Confirmer la suppression de <1>{{email}}</1> l'étudiant ?`,
        successMessage: 'Confirmation de suppression étudiant',
        errorMessage: 'Erreur lors de la suppression étudiant',
      }
    }
  },
  certification: {
    tableHeaders: {
      firstName: 'Prénom',
      lastName: 'Nom',
      email: 'Email',
      assignedAuditor: 'Analyste',
      school: `École`,
      documents: 'Documents',
      jobDescription: 'Fiche de poste',
      blocks: 'Blocs',
      certificationPassport: 'Passeport et CV à jour',
      finalValidation: 'Validation finale'
    },
    assignAuditorToStudentsSuccessSnackMessage: 'Les auditeurs ont été affectés avec succès !',
    actions: {
      bulkAssignAuditor: 'Assigner un analyste',
      exportCertificationCSV: 'exporter CSV'
    },
    assignAuditorModal: {
      assignAuditorModalTitle: 'Affecter un analyste',
      noAuditorsFoundMessage: `Les analyste manquent à l'appel !`
    },
    certificationDocumentsStatuses: {
      [VerificationStatus.Rejected]: 'Rejeté',
      [VerificationStatus.Verified]: 'Vérifié',
      [VerificationStatus.PendingVerification]: 'Vérification en attente',
      [VerificationStatus.Draft]: 'Brouillon',
      missing: 'Manquant',
    },
    certificationDecisionOptions: {
      [StudentCertificationStatus.FullDiploma]: 'Certification totale',
      [StudentCertificationStatus.PartialDiploma]: 'Attestation partielle',
      [StudentCertificationStatus.NoDiploma]: 'Echec de la certification',
      pending: 'En attente'
    },
    certificationBlocksStatuses: {
      [CertificationStatus.Passed]: 'Acquis',
      [CertificationStatus.Failed]: 'Tous échoués',
      notCalculated: 'Non calculé',
      placeholder: 'Acquis'
    },
  }
};

export default cohorts;