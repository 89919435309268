import { Events } from '../../../utils/events';

const certificates = {
  common: {
    menu: {
      title: 'Certificates'
    },
    validation: {
      longNameField: 'Certificate Name',
      shortNameField: 'Certificate Abbreviation',
      yearOfCertificationField: 'Year of accreditation',
      rncpCodeField: 'RNCP Code',
      rncpLevelField: 'RNCP Level',
      rncpLevelEuropeField: 'RNCP Level Europe',
      localeField: 'Language',
      descriptionField: 'Certificate description',
      blockField: 'One block is required',
      daysField: 'Days',
      certificateTaskConfigurationIdField: 'Task',
      textField: 'Text',
      eventField: 'Event',
      blocks: {
        nameField: 'Block name',
        deliverableField: 'Deliverable',
        markField: 'Mark',
        deliverables: {
          nameField: 'Deliverable name'
        },
        marks: {
          nameField: 'Mark name'
        }
      },
      nameField: 'Name'
    },
    certificateForm: {
      longName: {
        label: 'Certificate name',
        placeholder: 'Write certificate name here...'
      },
      shortName: {
        label: 'Certificate Abbreviation',
        placeholder: 'Write certificate abbreviation here...'
      },
      yearOfCertification: {
        label: 'Year of accreditation'
      },
      rncpCode: {
        label: 'RNCP Code',
        placeholder: 'Write RNCP Code...'
      },
      rncpLevel: {
        label: 'RNCP Level',
        placeholder: 'Write RNCP Level...'
      },
      rncpLevelEurope: {
        label: 'RNCP Level Europe',
        placeholder: 'Write RNCP Level Europe...'
      },
      locale: {
        label: 'Language',
        placeholder: 'Select certificate language...'
      },
      description: {
        label: 'Certificate description',
        placeholder: 'Write certificate description...'
      },
      avatar: {
        label: 'Certificate Logo',
        placeholder: 'Upload certificate logo'
      },
      isPublished: {
        label: 'Publish certificate'
      },
      status: {
        label: 'Status'
      },
      blocks: {
        boxTitle: 'BLOCKS',
        modalTitle: 'Add Block',
        modalSubtitle: 'Fill in fields below to add block',
        cancel: 'Cancel',
        addBlock: 'Add block',
        noBlockAdded: 'No block added',
        form: {
          optional: {
            label: 'Optional'
          },
          name: {
            label: 'Block Name',
            placeholder: 'Write block name here...'
          }
        }
      },
      save: 'Save certificate'
    },
    blocks: {
      delete: {
        modalTitle: 'Delete block',
        modalBody:
          'Are you sure you want to delete <1>{{name}}</1> from block list?'
      }
    },
    notificationForm: {
      name: {
        label: 'Name',
        placeholder: 'Write notification name here...'
      },
      description: {
        label: 'Description',
        placeholder: 'Write notification description here...'
      },
      text: {
        label: 'Text',
        placeholder: 'Write notification text here...'
      },
      sendToRoles: {
        label: 'Send to roles',
        placeholder: 'Choose which roles should receive notification...'
      },
      sendOnEmail: {
        label: 'Send on e-mail',
        placeholder: 'Send on e-mail'
      },
      sendOnPlatform: {
        label: 'Send on platform',
        placeholder: 'Send on platform'
      },
      event: {
        label: 'Event',
        placeholder: 'Select event'
      },
      timeTypes: {
        reminderNotifications: 'Reminder notifications',
        immediatelySendNotifications: 'Immediately send notifications'
      },
      userTypes: {
        userTaskAssignedTo: 'Send to user task is assigned to',
        otherUsers: 'Send to other users',
        notifiableUsers: 'Event mentioned users'
      },
      triggerTypes: {
        event: 'Event',
        task: 'Task'
      },
      days: {
        label: 'Days',
        placeholder: 'Select number of days'
      },
      task: {
        label: 'Task',
        placeholder: 'Select task'
      }
    }
  },
  listAll: {
    htmlTitle: 'Certificates',
    title: 'Certificates',
    subtitle: 'These are you active certificates',
    createNewCertificate: 'Create new certificate',
    table: {
      columns: {
        certificate: 'CERTIFICATE',
        cohorts: 'COHORTS',
        status: 'STATUS',
        createdAt: 'CREATED AT'
      },
      status: {
        published: 'Published',
        unlisted: 'Unlisted'
      },
      actionTooltips: {
        edit: 'Edit',
        delete: 'Delete',
        configureTasks: 'Configure tasks',
        exportStudentBlockDeliverablesStatusReport: 'Export student block deliverables status report',
      }
    },
    delete: {
      successMessage: 'Certificate successfully deleted',
      errorMessage: 'Error while deleting certificate',
      modalTitle: 'Delete certificate',
      modalBody:
        'Are you sure you want to delete <1>{{name}}</1> from certificates list?'
    }
  },
  create: {
    htmlTitle: 'Create new certificate',
    title: 'Create new certificate',
    subtitle: 'Fill in fields below to add a new certificate',
    backToList: 'Go back to all certificates'
  },
  view: {
    htmlTitle: 'Certificates | {{name}}',
    title: 'Certificate information',
    subtitle: 'Certificate information details',
    backToList: 'Go back to all certificates',
    tabs: {
      info: 'Info',
      tasks: 'Tasks',
      notifications: 'Notifications',
      evaluationConfigurations: 'Evaluation Grids'
    }
  },
  blockView: {
    htmlTitle: 'Certificates | Blocks | {{name}}',
    subtitle: 'Certificate block information details',
    backToCertificate: 'Go back to certificate',
    deliverables: {
      boxTitle: "BLOCK'S DELIVERABLES",
      noDeliverableAdded: 'No deliverables added',
      addDeliverable: 'Add deliverable',
      add: {
        modalTitle: 'Add Block Deliverable',
        modalSubtitle: 'Fill in fields below to add deliverable'
      },
      edit: {
        modalTitle: 'Edit Block Deliverable',
        modalSubtitle: 'Fill in fields below to edit deliverable'
      },
      form: {
        name: {
          label: 'Deliverable name',
          placeholder: 'New deliverable name...'
        },
        description: {
          label: 'Deliverable description',
          placeholder: 'New deliverable description...'
        }
      },
      delete: {
        modalTitle: 'Delete deliverable',
        modalBody:
          'Are you sure you want to delete <1>{{name}}</1> from block deliverables list?'
      }
    },
    marks: {
      boxTitle: "BLOCK'S MARKS",
      noMarkAdded: 'No mark added',
      addMark: 'Add mark',
      add: {
        modalTitle: 'Add Mark',
        modalSubtitle: 'Fill in fields below to add mark'
      },
      edit: {
        modalTitle: 'Edit Block Mark',
        modalSubtitle: 'Fill in fields below to edit mark'
      },
      delete: {
        modalTitle: 'Delete mark',
        modalBody:
          'Are you sure you want to delete <1>{{name}}</1> from block marks list?'
      },
      form: {
        name: {
          label: 'Mark name',
          placeholder: 'New mark...'
        }
      }
    }
  },
  academicKit: {
    title: 'Academic Kit',
    deleteFolder: {
      modalTitle: 'Delete folder',
      modalBody:
        'Are you sure you want to delete folder <1>{{name}}</1> from academic kit list?'
    },
    deleteFile: {
      modalTitle: 'Delete file',
      modalBody:
        'Are you sure you want to delete file <1>{{name}}</1> from academic kit list?'
    }
  },
  blockDocuments: {
    title: 'Block documents',
    deleteFolder: {
      modalTitle: 'Delete folder',
      modalBody:
        'Are you sure you want to delete folder <1>{{name}}</1> from block documents?'
    },
    deleteFile: {
      modalTitle: 'Delete file',
      modalBody:
        'Are you sure you want to delete file <1>{{name}}</1> from block documents?'
    }
  },
  tasks: {
    table: {
      columns: {
        name: 'Name'
      },
      actions: {
        edit: 'Edit Task'
      }
    },
    basicInfo: {
      description: 'Description',
      roles: 'Roles'
    },
    form: {
      name: {
        label: 'Task name',
        placeholder: 'Write task name...'
      },
      description: {
        label: 'Task description',
        placeholder: 'Write task description...'
      },
      roles: {
        label: 'Assignee Roles:',
        placeholder: ''
      }
    }
  },
  task: {
    htmlTitle: 'Certificates | Tasks | {{name}}',
    title: 'Task information',
    subtitle: 'Task information details',
    backToCertificateTasks: 'Go back to certificate tasks',
    notificationPartTitle: 'Notification information',
    notificationPartSubtitle: 'Notification information details',
    createNewNotification: 'Add new notification',
    addMagicWords: 'Add "magic" words that will be replaced with real data:'
  },
  notifications: {
    modal: {
      create: {
        modalTitle: 'Add notification',
        modalSubtitle:
          'Fill in the fields bellow to add a new notification for the task'
      },
      update: {
        modalTitle: 'Update notification',
        modalSubtitle: 'Manage your notification'
      },
      delete: {
        modalTitle: 'Delete notification',
        modalBody:
          'Are you sure you want to delete <1>{{name}}</1> from notifications list?'
      }
    },
    table: {
      columns: {
        name: 'Name',
        trigger: 'Trigger',
        task: 'Task',
        event: 'Event'
      }
    }
  },
  events: {
    [Events.CorrectorAssignedToStudentBlocks]:
      'Corrector assigned to student block',
    [Events.CourseDocumentsRejected]: 'Course documents rejected',
    [Events.CourseDocumentsUploaded]: 'Course documents uploaded',
    [Events.CourseDocumentsVerified]: 'Course documents verified',
    [Events.JobDescriptionRejected]: 'Job description rejected',
    [Events.JobDescriptionVerificationRequested]:
      'Job description verification requested',
    [Events.JobDescriptionVerified]: 'Job description verified',
    [Events.SchoolInvitedToCohort]: 'School invited to the cohort',
    [Events.SchoolRemovedFromCohort]: 'School removed from cohort',
    [Events.StudentAcceptedInvitation]: 'Student accepted invitation',
    [Events.StudentDocumentsRejected]: 'Student documents rejected',
    [Events.StudentDocumentsUploaded]: 'Student documents uploaded',
    [Events.StudentDocumentsVerified]: 'Student documents verified',
    [Events.StudentInvitedToCohort]: 'Student invited to the cohort',
    [Events.TutorAssignedToStudent]: 'Tutor assigned to student',
    [Events.JuryPresidentAssigned]: 'Jury president assigned',
    [Events.JuryPresidentDeleted]: 'Jury president deleted',
    [Events.JuryMemberAssigned]: 'Jury member assigned',
    [Events.JuryMemberDeleted]: 'Jury member deleted',
    [Events.UploadedFinalExamDocument]: 'Uploaded final exam document',
    [Events.FinalExamCompleted]: 'Final exam completed',
    [Events.FinalExamPassed]: 'Final exam passed',
    [Events.FinalExamFailed]: 'Final exam failed',
    [Events.FinalExamMovedToPending]: 'Final exam student file ready',
    [Events.EvaluationCreated]: 'Evaluation created',
    [Events.EvaluationRejected]: 'Evaluation rejected',
    [Events.EvaluationVerified]: 'Evaluation verified',
    [Events.StudentBlockSubmitted]: 'Student submitted block deliverables',
    [Events.StudentPassportVerificationRequested]: 'Student passport verification requested',
    [Events.StudentPassportRejected]: 'Student passport rejected',
    [Events.StudentPassportVerified]: 'Student passport verified',
    [Events.AuditorAssignedToStudentCertification]: 'Auditor assigned to student certification',
    [Events.JuryAssignedToStudentCertification]: 'Jury assigned to student certification',
    [Events.AuditorAuditedStudentCertification]: 'Auditor audited student certification',
    [Events.JuryReachedStudentCertificationDecision]: 'Jury reached student certification decision',
  }
};

export default certificates;
