import { IStudent } from 'src/models/student';
import { axiosInt } from '../../../utils/axios';
import { IStudentDocument } from '../../../models/student-document';

export const FETCH_TUTORS_STUDENTS_KEY = 'fetchTutorsStudents';
export const fetchTutorsStudents = async (): Promise<IStudent[]> => {
  const response = await axiosInt.get(`/assigned-students`);

  return response.data;
};

export const FETCH_STUDENT_INFO_KEY = 'fetchStudentInfo';
export const fetchStudentInfo = async (): Promise<any> => {
  const response = await axiosInt.get(`/student/information`);

  return response.data;
};

export const UPLOAD_FINAL_EXAM_DOCUMENT_KEY = 'uploadFinalExamDocument';
export const uploadFinalExamDocument = async (
  studentId: string,
  finalExamId: string,
  file
): Promise<IStudent> => {
  const formData = new FormData();
  formData.set('file', file);
  formData.set('finalExamId', finalExamId);

  const response = await axiosInt.post(
    `/students/${studentId}/upload-final-exam-document`,
    formData
  );

  return response.data;
};

export const DELETE_STUDENT_DOCUMENT_KEY = 'deleteStudentDocument';
export const deleteStudentDocument = async (
  url: string
): Promise<IStudentDocument> => {
  const response = await axiosInt.delete(url);
  return response.data;
};
