import { useQuery } from 'react-query';
import { ICohortTaskConfiguration } from '../../../../../models/cohort-task-configuration';
import { IApiError } from '../../../../../utils/axios';
import {
  fetchCohortTasksConfigurations,
  FETCH_COHORT_TASKS_CONFIGURATIONS_KEY
} from '../../../utils/api';

const useFetchCohortTasksConfigurations = (cohortId: string, options?: any) => {
  const {
    isLoading: isFetchingCohortTasksConfigurations,
    error: errorFetchingCohortTasksConfigurations,
    data: cohortTasksConfigurations
  } = useQuery<ICohortTaskConfiguration[], IApiError>(
    [FETCH_COHORT_TASKS_CONFIGURATIONS_KEY, cohortId],
    () => fetchCohortTasksConfigurations(cohortId),
    options
  );

  return {
    isFetchingCohortTasksConfigurations,
    errorFetchingCohortTasksConfigurations,
    cohortTasksConfigurations
  };
};

export default useFetchCohortTasksConfigurations;
