import {
  FileUploadingStatus,
  TreeItemType,
  IFile as ITreeFile,
  IFolder as ITreeFolder
} from './types';
import { IFile } from '../../models/file';
import { IFolder } from '../../models/folder';

export const mapApiFileToTreeFile = (file: IFile): ITreeFile => {
  return {
    type: TreeItemType.File,
    name: file.name,
    id: file.id,
    uuid: file.id,
    url: file.url,
    roles: file.roles,
    position: file.position,
    folderId: file.folderId
  };
};

export const mapApiFolderToTreeFolder = (folder: IFolder): ITreeFolder => {
  return {
    name: folder.name,
    id: folder.id,
    uuid: folder.id,
    type: TreeItemType.Folder,
    children: [
      ...folder.files.map(mapApiFileToTreeFile),
      ...folder.folders.map(mapApiFolderToTreeFolder)
    ],
    roles: folder.roles,
    position: folder.position,
    parentFolderId: folder.parentFolderId
  };
};

export const addNewFolderToTree = (
  fileTree: ITreeFolder[],
  folder: IFolder
) => {
  const mappedFolder = mapApiFolderToTreeFolder(folder);

  if (!folder.parentFolderId) {
    return [...fileTree, mappedFolder];
  }

  const mapper = (treeFolder: ITreeFolder) => {
    // If tree folder is parent of newly added folder
    // Add that folder to tree folder children
    if (treeFolder.id === folder.parentFolderId) {
      return {
        ...treeFolder,
        children: [...treeFolder.children, mappedFolder]
      };
    }
    // If tree folder does not have children => just return it
    if (!treeFolder.children?.length) {
      return treeFolder;
    }
    // If tree folder has children => do recursion
    if (treeFolder.children?.length) {
      return {
        ...treeFolder,
        children: treeFolder.children.map(mapper)
      };
    }

    return treeFolder;
  };

  return fileTree.map(mapper);
};

export const deleteFileOrFolderFromTree = (
  fileTree: ITreeFolder[],
  itemId: string
) => {
  const filter = (fileOrFolder: ITreeFolder) => {
    // If item uuid is === to given id filter out
    if (fileOrFolder.uuid === itemId) {
      return false;
    }
    if (fileOrFolder.children?.length) {
      return (fileOrFolder.children = fileOrFolder.children.filter(filter));
    }

    return true;
  };

  return fileTree.filter(filter);
};

export const editFolderFromTree = (
  fileTree: ITreeFolder[],
  folder: IFolder
) => {
  const mapper = (fileTreeFolder: ITreeFolder) => {
    if (fileTreeFolder.id === folder.id) {
      return { ...fileTreeFolder, name: folder.name, roles: folder.roles };
    }
    if (fileTreeFolder.children?.length) {
      return {
        ...fileTreeFolder,
        children: fileTreeFolder.children.map(mapper)
      };
    }

    return fileTreeFolder;
  };

  return fileTree.map(mapper);
};

export const addNewFileToTree = (
  fileTree: ITreeFolder[],
  file: IFile,
  fileFromTreeToBeReplaced: Partial<IFile>
) => {
  const mappedFiled = mapApiFileToTreeFile(file);

  const mapper = (fileTreeItem: ITreeFolder | ITreeFile) => {
    // If current item is same uuid replace with api file
    if (fileTreeItem.uuid === fileFromTreeToBeReplaced.id) {
      return mappedFiled;
    }
    // If children exists do recursion
    if ((fileTreeItem as ITreeFolder).children?.length) {
      return {
        ...fileTreeItem,
        children: (fileTreeItem as ITreeFolder).children.map(mapper)
      };
    }

    return fileTreeItem;
  };

  return fileTree.map(mapper);
};

export const setFileError = (
  folders: ITreeFolder[],
  file: Partial<IFile>
): ITreeFolder[] => {
  const mapFolder = (folder: ITreeFolder) => {
    return {
      ...folder,
      children: folder.children.map((item) => {
        if (item.uuid === file.id) {
          return { ...item, status: FileUploadingStatus.Error };
        }
        if (item.type === TreeItemType.Folder) {
          return mapFolder(item as ITreeFolder);
        }
        return item;
      })
    };
  };

  return folders.map(mapFolder);
};

export const editFile = (
  folders: ITreeFolder[],
  file: Partial<IFile>
): ITreeFolder[] => {
  const mapFolder = (folder: ITreeFolder) => {
    return {
      ...folder,
      children: folder.children.map((item) => {
        if (item.uuid === file.id) {
          return { ...item, ...file };
        }
        if (item.type === TreeItemType.Folder) {
          return mapFolder(item as ITreeFolder);
        }
        return item;
      })
    };
  };

  return folders.map(mapFolder);
};

export const checkIfErrorExists = (folders: ITreeFolder[]) => {
  const errorExists = folders.find((folder) =>
    checkIfFileWithStatusesExists(folder, [FileUploadingStatus.Error])
  );

  return !!errorExists;
};

export const checkIfFileWithStatusesExists = (
  folder: ITreeFolder,
  statuses: FileUploadingStatus[]
) => {
  return folder.children.find((item) => {
    if (item.type === TreeItemType.File) {
      return statuses.includes((item as ITreeFile).status);
    } else {
      return checkIfFileWithStatusesExists(item as ITreeFolder, statuses);
    }
  });
};

export const orderTree = (
  tree: ITreeFolder[]
) => {

  const singleSorter = (a: ITreeFolder) => {
    if (a.children?.length > 0) {
      a = {
        ...a,
        children: a.children.length > 1 ?
          a.children.sort(sorter) :
          a.children.map(singleSorter)
      };
    };
    return a;
  }

  const sorter = (a: ITreeFolder, b: ITreeFolder) => {
    if (a.children?.length > 0) {
      a = {
        ...a,
        children: a.children.length > 1 ?
          a.children.sort(sorter) :
          a.children.map(singleSorter)
      };
    }

    if (b.children?.length > 0) {
      b = {
        ...b,
        children: b.children.length > 1 ?
          b.children.sort(sorter) :
          b.children.map(singleSorter)
      };
    }

    if (a.type !== b.type) {
      // return b.type === TreeItemType.Folder ? 1 : -1
    }

    return (a.position - b.position)
  }

  return tree.length > 1 ? tree.sort(sorter) : tree.map(singleSorter)
};

export const moveItem = (
  tree: ITreeFolder[],
  item: ITreeFolder | ITreeFile,
  moveToLocation: string,
) => {
  let removed = false;
  let pushed = false;

  const removeAndPush = (
    items: (ITreeFolder | ITreeFile)[],
    movingItem: ITreeFolder | ITreeFile,
    locationUuid: string,
    parentFolder: ITreeFolder = null,
  ) => {
    const currentFolderItems = [...items];

    // Remove item
    const remove_index = removed ? -1 : currentFolderItems.findIndex(i => i.uuid === movingItem.uuid)
    if (remove_index >= 0) {
      currentFolderItems.splice(remove_index, 1)
      removed = true;
    }

    // Push item
    const push_location_index = pushed ? -1 : currentFolderItems.findIndex(i => i.uuid === locationUuid)
    if (push_location_index >= 0) {

      const movingItemUpdated = movingItem.type === TreeItemType.Folder ? {
        ...movingItem,
        position: currentFolderItems[push_location_index].position,
        parentFolderId: parentFolder?.uuid ?? null
      } : {
        ...movingItem,
        position: currentFolderItems[push_location_index].position,
        folderId: parentFolder?.uuid ?? null
      }

      /**
       * Move item is placed bellow location item when 
       * 1. Location item is last in the list
       * 2. Location item is on same level but bellow
       */
      const push_location_original_index = items.findIndex(i => i.uuid === locationUuid)
      const move_to_index =
        ((push_location_original_index + 1) === items.length) || (remove_index >= 0 && remove_index < push_location_original_index) ?
          push_location_index + 1 :
          push_location_index

      currentFolderItems.splice(move_to_index, 0, movingItemUpdated)
      pushed = true;
    }

    // Continue recursion if needed
    return removed && pushed ?
      currentFolderItems :
      currentFolderItems.map(item => {
        if (!(removed && pushed) && item.children?.length >= 0) {
          return {
            ...item,
            children: removeAndPush(item.children, movingItem, locationUuid, item as ITreeFolder)
          }
        }
        return item;
      })
  }

  const updatedTree = removeAndPush(tree, item, moveToLocation);
  if (removed && pushed) {
    return orderTree(updatedTree)
  }

  throw new Error("MoveItem failed");
}

export const moveItemToFolder = (
  tree: ITreeFolder[],
  item: ITreeFolder | ITreeFile,
  moveToLocation: string,
) => {

  let removed = false;
  let pushed = false;

  const removeAndPushToFolder = (
    items: (ITreeFolder | ITreeFile)[],
    movingItem: ITreeFolder | ITreeFile,
    locationUuid: string,
    parentFolder: ITreeFolder = null,
  ) => {
    const currentFolderItems = [...items];

    // Remove item
    const remove_index = removed ? -1 : currentFolderItems.findIndex(i => i.uuid === movingItem.uuid)
    if (remove_index >= 0) {
      currentFolderItems.splice(remove_index, 1)
      removed = true;
    }

    // Push item to parent
    if (!pushed && parentFolder?.uuid === locationUuid) {
      const updatedMovingItem = movingItem.type === TreeItemType.Folder ? {
        ...movingItem,
        position: parentFolder.children?.length || 1,
        parentFolderId: parentFolder.uuid
      } : {
        ...movingItem,
        position: parentFolder.children?.length || 1,
        folderId: parentFolder.uuid
      }

      currentFolderItems.push(updatedMovingItem)
      pushed = true;
    }

    // Continue recursion if needed
    return removed && pushed ?
      currentFolderItems :
      currentFolderItems.map(item => {
        if (!(removed && pushed) && item.children?.length >= 0) {
          return {
            ...item,
            children: removeAndPushToFolder(item.children, movingItem, locationUuid, item as ITreeFolder)
          }
        }
        return item;
      })
  }

  const updatedTree = removeAndPushToFolder(tree, item, moveToLocation);
  if (removed && pushed) {
    return orderTree(updatedTree)
  }

  throw new Error("moveItemToFolder failed");
}

export const prepareTreeForApiReorder = (folders: ITreeFolder[]): Partial<IFolder>[] => {
  const mapTreeFileToApiFile = (file: ITreeFile): Partial<IFile> => ({
    id: file.uuid,
    position: file.position,
    folderId: file.folderId
  });

  const mapTreeFolderToApiFolder = (folder: ITreeFolder): Partial<IFolder> => {
    return {
      id: folder.uuid,
      files: folder.children.filter(i => i.type === TreeItemType.File).map(mapTreeFileToApiFile) as IFile[],
      folders: folder.children.filter(i => i.type === TreeItemType.Folder).map(mapTreeFolderToApiFolder) as IFolder[],
      position: folder.position,
      parentFolderId: folder.parentFolderId
    }
  };

  const updatePositions = (folder: ITreeFolder, index): ITreeFolder => ({
    ...folder,
    children: folder.children?.map(updatePositions),
    position: index + 1,
  });

  return folders.map(updatePositions).map(mapTreeFolderToApiFolder);
};