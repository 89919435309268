import ReactDOM from 'react-dom';
import 'src/utils/chart';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import * as Sentry from '@sentry/react';

import 'nprogress/nprogress.css';

import App from 'src/App';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';
import { AuthProvider } from './features/auth/context/JWTAuthContext';
import ScrollTop from 'src/hooks/useScrollTop';
import BaseUrlResolver from './components/BaseUrlResolver';

const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: 0, refetchOnWindowFocus: false } }
});

// Turn on sentry only for production
if (process.env.REACT_APP_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN
  });
}

ReactDOM.render(
  <HelmetProvider>
    <SidebarProvider>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <BaseUrlResolver>
            <ScrollTop />
            <AuthProvider>
              <App />
            </AuthProvider>
            {process.env.REACT_APP_ENV === 'local' && (
              <ReactQueryDevtools initialIsOpen={false} />
            )}
          </BaseUrlResolver>
        </QueryClientProvider>
      </BrowserRouter>
    </SidebarProvider>
  </HelmetProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
