import { useQuery } from 'react-query';
import { IUser } from 'src/models/user';
import { IApiError } from '../../../../utils/axios';
import {
  fetchTutor,
  FETCH_TUTOR_KEY
} from '../../utils/api';

const useFetchTutor = (
  id: string,
) => {
  const {
    isLoading: isFetchingTutor,
    error: errorFetchingTutor,
    data: tutor
  } = useQuery<IUser, IApiError>(
    [FETCH_TUTOR_KEY, id],
    () => fetchTutor(id)
  );

  return {
    isFetchingTutor,
    errorFetchingTutor,
    tutor
  };
};

export default useFetchTutor;
