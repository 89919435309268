import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';
import { IApiError } from '../../../../utils/axios';
import {
  updateStatus as updateStatusApi,
  UPDATE_STATUS_KEY
} from '../../utils/api';

const useUpdateStatus = (url: string) => {
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: updateStatus, isLoading: isUpdatingStatus } = useMutation(
    ({ status, message }: any) => updateStatusApi(status, url, message),
    {
      mutationKey: UPDATE_STATUS_KEY,
      onError: (error: IApiError) => {
        enqueueSnackbar(error.message, {
          variant: 'error'
        });
      }
    }
  );

  return {
    updateStatus,
    isUpdatingStatus
  };
};

export default useUpdateStatus;
