const students = {
  table: {
    columns: {
      name: 'Students',
      email: 'E-mail',
      status: 'Status'
    }
  },
  assigned: {
    listAll: {
      htmlTitle: 'Assigned students',
      title: 'Assigned students',
      subtitle: 'List of all assigned students'
    }
  },
  cohortCertificate: {
    tabs: {
      blocks: 'Blocks',
      evaluations: 'Evaluations',
      finalExam: 'Final Exam',
      certificationPassport:'Certification Passport'
    },
    labels: {
      certificate: 'Certificate',
      code: 'RCNP Code',
      level: 'RCNP Level',
      blocks: 'Number of blocks',
      description: 'Description'
    }
  },
  finalExam: {
    finalExamDocument: 'Final exam document',
    finalExamDocumentNotUploaded: 'Final exam document not uploaded yet',
    finalExamPreparedDocument: 'Final exam document',
    finalExamNotPreparedYet: 'Final exam document not prepared yet'
  },
  school: {
    schoolNotFound:
      'School not found, please contact administrator for more information.',
    labels: {
      shortName: 'Short Name',
      address: 'Address',
      about: 'About'
    }
  },
  job: {
    title: 'Job Description'
  },
  documents: {
    cv: 'CV',
    diploma: 'Diploma',
    evaluationTest: 'Evaluation Test (Optional)',
    waiver: 'Waiver',
    passport: 'Certification Passport',
    redemptionPassport: 'Redemption Certification Passport'
  },
  evaluations: {
    blockEvaluations: 'Block evaluations',
    selfEvaluation: 'Self evaluation',
    tutorEvaluation: 'Tutor evaluation',
    title: 'Evaluations',
    file: 'File',
    type: 'Type',
    uploadedBy: 'Uploaded by',
    block: 'Block',
    status: 'Status',
    actions: 'Actions',
    verifyActions: 'Verify',
    verify: {
      title: 'Verify evaluation',
      body: 'Are you sure you want to verify this evaluation?'
    },
    reject: {
      title: 'Reject evaluation',
      body: 'Are you sure you want to reject this evaluation?'
    },
    showMessages: 'Show messages',
    messagesModalTitle: 'Rejection reasons',
    messagesModalSubtitle: 'View reasons your evaluation was rejected',
    rejectionReason: 'Rejection reason',
    notUploaded: 'Not uploaded'
  },
  missingInfo: {
    title: 'Missing information',
    text: 'School or cohort not found, please contact administrator for more information.'
  },
  handle: {
    notAllowed: 'Access to student not allowed!',
  },
};

export default students;
