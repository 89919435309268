const companies = {
  common: {
    companyForm: {
      name: {
        label: "Nom de l'entreprise",
        placeholder: "Saisir ici le nom de l'entreprise..."
      },
      shortName: {
        label: 'Diminutif nom entreprise',
        placeholder: 'Saisir le diminutif ici...'
      },
      longName: {
        label: "Nom complet de l'entreprise",
        placeholder: "Saisir ici le nom complet de l'entreprise..."
      },
      companyRegistrationNo: {
        label: "Numéro SIRET de l'entreprise",
        placeholder: 'Saisir le SIRET ici...'
      },
      status: {
        label: "Statut de l'entreprise",
        placeholder: 'Sélectionner le statut...'
      },
      industry: {
        label: "Activité principale de l'entreprise",
        placeholder: "Saisir ici l'activité de l'entreprise..."
      },
      type: {
        label: "Forme de l'entreprise",
        placeholder: "Saisir la forme de l'entreprise ici..."
      },
      capital: {
        label: "Capital de l'entreprise",
        placeholder: 'Saisir ici le montant du capital social...'
      },
      capitalType: {
        label: 'Type de capital',
        placeholder: 'Saisir ici le type de capital...'
      },
      noOfEmployees: {
        label: 'Nombre de salariés',
        placeholder: 'Saisir ici le nombre de salariés...'
      },
      companyLogo: {
        label: "Logo de l'entreprise",
        placeholder: 'Téléverser le logo'
      },
      companyStatus: {
        active: 'Actif',
        inActive: 'Inactif'
      }
    },
    addresses: {
      boxTitle: "ADRESSES DE L'ENTREPRISE",
      noAddressAdded: 'Aucune adresse saisie',
      addAddress: 'Ajouter une adresse',
      mainAddress: 'Siège social',
      modalTitle: 'Ajouter une adresse',
      modalSubtitle: 'Saisir les champs ci-dessous pour ajouter une adresse',
      form: {
        address: {
          label: 'Adresse',
          placeholder: "Saisir l'adresse ici"
        },
        city: {
          label: 'Ville',
          placeholder: 'Saisir la ville ici'
        },
        zipCode: {
          label: 'Code postal',
          placeholder: 'Saisir le code postal ici'
        },
        country: {
          label: 'Pays',
          placeholder: 'Sélectionner le Pays'
        },
        isMain: {
          label: 'Siège social'
        }
      }
    },
    staff: {
      boxTitle: "PERSONNEL DE L'ENTREPRISE",
      noStaffInvited: 'Aucun collaborateur invité',
      addStaff: 'Inviter un collaborateur',
      form: {
        email: {
          label: 'E-mail',
          placeholder: "Saisir l'e-mail ici"
        },
        firstName: {
          label: 'Prénom',
          placeholder: 'Saisir le prénom ici'
        },
        lastName: {
          label: 'Nom de famille',
          placeholder: 'Saisir le nom de famille ici'
        },
        roles: {
          label: 'Les rôles',
          placeholder: 'Sélectionner les rôles'
        }
      },
      modalTitle: 'Collaborateur',
      modalSubtitle: 'Saisies des informations',
      delete: {
        modalTitle: 'Supprimer le compte collaborateur',
        modalBody:
          'Confirmer la suppression du compte de <1>{{name}}</1> de la liste des collaborateurs ?'
      }
    }
  },
  listAll: {
    htmlTitle: 'Entreprise',
    title: 'Entreprise',
    subtitle: 'Liste des entreprises',
    addNewCompany: 'Ajouter une nouvelle entreprise',
    table: {
      columns: {
        company: 'ENTREPRISE',
        createdAt: 'CRÉÉE LE',
        status: 'STATUT',
        companyEmployeeCount: `Nombre d'employés`
      },
      actionTooltips: {
        edit: 'Modifier',
        delete: 'Supprimer'
      },
      status: {
        active: 'Actif',
        inActive: 'Inactif'
      }
    },
    delete: {
      modalTitle: "Supprimer l'entreprise",
      modalBody:
        'Confirmation de la suppression de <1>{{name}}</1> de la liste des entreprises ?',
      successMessage: 'Entreprise supprimée avec succès',
      errorMessage: `Erreur lors de la suppression de l'entreprise`
    },
    importCompaniesWithEmployees: 'Import CSV',
    importCompaniesWithEmployeesModal: {
      modalTitle: 'Importer des tuteurs depuis CSV',
      modalSubtitle:
        'Veuillez télécharger le fichier CSV pour importer les données des tuteurs',
      uploadButton: `Télécharger CSV et glisser-déposer ou cliquer pour télécharger`,
      download: {
        text1: `* Veuillez télécharger l'exemple de`,
        text2: 'et entrer les données réelles',
        file: 'CSV-FICHIER'
      },
      infoMessage: `Enregistrer {{numberOfEmployees}} tuteurs peut prendre un certain temps, une fois que vous avez cliqué sur Enregistrer, vous pouvez naviguer en toute sécurité.`,
      successMessage: `L'importation d'tuteurs à partir d'un fichier CSV a été soumise avec succès pour traitement.`,
      errorMessage: ` E-mails ont déjà été utilisés. Certains d'entre eux sont: `,
      invalidData: 'Les données fournies sont dans un format invalide.',
      invalidCsvData: `CSV non valide. Veuillez télécharger et utiliser le fichier d'exemple`,
      table: {
        columns: {
          firstName: 'Prénom',
          lastName: 'Nom de famille',
          email: 'E-mail',
          companyName: "Nom de l'entreprise",
        },
        loadInfo: {
          text1: '...et',
          text2: `plus d'tuteurs chargés avec succès.`
        }
      }
    },
    search: {
      label: 'Rechercher',
      placeholder: 'Rechercher'
    },
  },
  create: {
    htmlTitle: 'Ajouter une nouvelle entreprise',
    title: 'Ajouter une nouvelle entreprise',
    subtitle: 'Saisir les champs ci-dessous pour créer une nouvelle entreprise',
    backToList: 'Retour à la liste des entreprises'
  },
  view: {
    htmlTitle: 'Entreprise | {{name}}',
    subtitle: "Détail de l'entreprise",
    title: 'Informations entreprise',
    backToList: 'Retour à la liste des entreprises'
  },
  ownView: {
    htmlTitle: 'Paramètres certificateur',
    title: 'Paramètres certificateur',
    subtitle: 'Modification des paramètres certificateur'
  }
};

export default companies;
