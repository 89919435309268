import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { IApiError } from '../../../../../utils/axios';
import {
  UPDATE_FOLDER_ROLES_KEY,
  updateFolderRoles as updateFolderRolesApi
} from '../../../utils/api';

const useUpdateFolderRoles = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { mutate: updateFolderRoles, isLoading: isUpdatingFolderRoles } =
    useMutation(updateFolderRolesApi, {
      mutationKey: UPDATE_FOLDER_ROLES_KEY,
      onError: (error: IApiError) => {
        enqueueSnackbar(error.message, {
          variant: 'error'
        });
      },
      onSuccess: () => {
        enqueueSnackbar(t('acl.fileTree.folderRoleChanged'), {
          variant: 'success'
        });
      }
    });

  return {
    updateFolderRoles,
    isUpdatingFolderRoles
  };
};

export default useUpdateFolderRoles;
