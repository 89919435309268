import { Box, Button, Dialog, styled, TextField } from '@mui/material';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

interface IProps {
  isVisible: boolean;
  folderName?: string;
  onHide(): void;
  onFinish(folderName: string): void;
}

const StyledBox = styled(Box)(
  ({ theme }) => `
  padding: ${theme.spacing(3)};
  width: 350px;
`
);

const FILE_TREE_TRANSLATE_KEY = 'common.fileTree';

const CreateEditFolderPopup: React.FC<IProps> = ({
  isVisible,
  folderName,
  onHide,
  onFinish
}) => {
  const { t } = useTranslation();

  return (
    <Dialog disableScrollLock onClose={onHide} open={isVisible}>
      <Formik<{ name: string }>
        initialValues={{ name: folderName || '' }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required(
            t('common.validation.required', {
              field: t('common.validation.nameField')
            })
          )
        })}
        onSubmit={({ name }) => onFinish(name)}
      >
        {({
          touched,
          errors,
          values,
          handleBlur,
          handleChange,
          handleSubmit
        }) => (
          <StyledBox>
            <form onSubmit={handleSubmit} noValidate>
              <TextField
                autoFocus
                required
                error={Boolean(touched.name && errors.name)}
                fullWidth
                margin="normal"
                helperText={touched.name && errors.name}
                label={t(`${FILE_TREE_TRANSLATE_KEY}.createFolderModal.label`)}
                placeholder={t(
                  `${FILE_TREE_TRANSLATE_KEY}.createFolderModal.placeholder`
                )}
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                variant="outlined"
                sx={{ mt: 0 }}
              />
              <Box display="flex" justifyContent="flex-end">
                <Button variant="contained" size="medium" type="submit">
                  {t(
                    `${FILE_TREE_TRANSLATE_KEY}.createFolderModal.finishButton`
                  )}
                </Button>
              </Box>
            </form>
          </StyledBox>
        )}
      </Formik>
    </Dialog>
  );
};

export default CreateEditFolderPopup;
