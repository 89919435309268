import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import React, { useMemo, useState } from 'react';
import useToggleVisibility from '../../../../../../hooks/useToggleVisibility';
import { IJobRequiredKnowledge } from 'src/models/job-required-knowledge';
import JobRequiredKnowledgeListItem from './JobRequiredKnowledgeListItem';
import CreateEditJobRequiredKnowledgeModal from './CreateEditJobRequiredKnowledgeModal';
import { JobStatuses } from 'src/utils/const';
import {
  NoJobDetailsAdded,
  StyledArrayFormBoxFooter
} from '../JobMissions/JobMissionsList';
import {
  ArrayFormBoxJob,
  ArrayFormBoxList
} from 'src/components/ArrayFormBoxJob';

const JOB_KNOWLEDGE_BASE_KEY = 'profile.common.jobRequiredKnowledge';

interface IProps {
  jobRequiredKnowledge: IJobRequiredKnowledge[];
  isAddingJobRequiredKnowledge?: boolean;
  isEditingJobRequiredKnowledge?: boolean;
  onRemoveJobRequiredKnowledge(
    index: number,
    jobRequiredKnowledge: IJobRequiredKnowledge
  ): void;
  onEditJobRequiredKnowledge(
    index: number,
    jobRequiredKnowledge: IJobRequiredKnowledge
  ): void;
  onAddJobRequiredKnowledge(jobRequiredKnowledge: IJobRequiredKnowledge): void;
  jobStatus: JobStatuses;
  canManage: boolean;
  readOnly: boolean;
}

const JobRequiredKnowledgeList: React.FC<IProps> = ({
  jobRequiredKnowledge,
  isAddingJobRequiredKnowledge,
  isEditingJobRequiredKnowledge,
  onRemoveJobRequiredKnowledge,
  onEditJobRequiredKnowledge,
  onAddJobRequiredKnowledge,
  jobStatus,
  canManage,
  readOnly
}) => {
  const { t } = useTranslation();

  const [
    jobRequiredKnowledgeIndexInModal,
    setJobRequiredKnowledgeIndexInModal
  ] = useState(null);
  const {
    isVisible: isVisibleModal,
    show: showModal,
    hide: hideModal
  } = useToggleVisibility(false);

  const jobRequiredKnowledgeInModal = useMemo(() => {
    if (jobRequiredKnowledgeIndexInModal === null) return null;

    return jobRequiredKnowledge[jobRequiredKnowledgeIndexInModal];
  }, [jobRequiredKnowledgeIndexInModal]);

  const closeModal = () => {
    hideModal();
    setJobRequiredKnowledgeIndexInModal(null);
  };

  const handleSubmitJobRequiredKnowledge = (
    newjobRequiredKnowledge: IJobRequiredKnowledge
  ) => {
    if (jobRequiredKnowledgeInModal) {
      onEditJobRequiredKnowledge(jobRequiredKnowledgeIndexInModal, {
        ...jobRequiredKnowledgeInModal,
        ...newjobRequiredKnowledge
      });
    } else {
      onAddJobRequiredKnowledge(newjobRequiredKnowledge);
    }
    closeModal();
  };

  const handleStartEdit = (index: number) => {
    setJobRequiredKnowledgeIndexInModal(index);
    showModal();
  };

  return (
    <ArrayFormBoxJob title={t(`${JOB_KNOWLEDGE_BASE_KEY}.boxTitle`)}>
      {jobRequiredKnowledge?.length === 0 && (
        <NoJobDetailsAdded variant="subtitle2">
          {t(`${JOB_KNOWLEDGE_BASE_KEY}.noJobRequiredKnowledgeAdded`)}
        </NoJobDetailsAdded>
      )}
      <ArrayFormBoxList>
        {jobRequiredKnowledge?.map((knowledge, index) => (
          <JobRequiredKnowledgeListItem
            key={index}
            jobRequiredKnowledge={knowledge}
            index={index}
            onDelete={onRemoveJobRequiredKnowledge}
            onEdit={handleStartEdit}
            jobStatus={jobStatus}
            canManage={canManage}
          />
        ))}
      </ArrayFormBoxList>

      {jobStatus !== JobStatuses.VerifiedBySchool && canManage && (
        <StyledArrayFormBoxFooter>
          <Button variant="outlined" size="small" onClick={showModal} disabled={readOnly}>
            <AddIcon fontSize="small" />
            {t(`${JOB_KNOWLEDGE_BASE_KEY}.addJobRequiredKnowledge`)}
          </Button>
        </StyledArrayFormBoxFooter>
      )}

      <CreateEditJobRequiredKnowledgeModal
        open={isVisibleModal}
        jobRequiredKnowledge={jobRequiredKnowledgeInModal}
        onClose={closeModal}
        onSubmit={handleSubmitJobRequiredKnowledge}
        isSubmitting={
          isEditingJobRequiredKnowledge || isAddingJobRequiredKnowledge
        }
      />
    </ArrayFormBoxJob>
  );
};

export default JobRequiredKnowledgeList;
