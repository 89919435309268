import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { IApiError } from 'src/utils/axios';
import {
    verifyPassport as verifyPassportApi,
    REQUEST_PASSPORT_VERIFICATION_KEY
} from 'src/features/profile/utils/api';
import { FETCH_STUDENT_CERTIFICATION_KEY } from 'src/features/profile/utils/api';

const usePassportVerification = () => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient()

    const {
        mutate: verifyPassport,
        isLoading: isVerifyingPassport
    } = useMutation(({ studentId, data }: any) => verifyPassportApi(studentId, data),
        {
            mutationKey: REQUEST_PASSPORT_VERIFICATION_KEY,
            onSuccess: () => {
                queryClient.invalidateQueries(FETCH_STUDENT_CERTIFICATION_KEY);
                enqueueSnackbar(
                    t(`profile.student.certificationPassport.certificationPassportAlert.requestToastSuccess`),
                    {
                        variant: 'success'
                    }
                );
            },
            onError: (error: IApiError) => {
                enqueueSnackbar(
                    error.message, {
                    variant: 'error'
                }
                );
            }
        }
    );

    return {
        verifyPassport,
        isVerifyingPassport
    };
};

export default usePassportVerification;