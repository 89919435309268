import { useMutation } from 'react-query';
import {
    EXPORT_STUDENT_CERTIFICATIONS_KEY,
    exportStudentCertificationReports as exportStudentCertificationReportsApi
} from '../../utils/api';
import { useSnackbar } from 'notistack';
import { IApiError } from 'src/utils/axios';

const useExportStudentCertificationReports = () => {

    const { enqueueSnackbar } = useSnackbar();

    const { mutate: exportStudentCertificationReports, isLoading: isExportingStudentCertificationReports } = useMutation(
        exportStudentCertificationReportsApi,
        {
            mutationKey: EXPORT_STUDENT_CERTIFICATIONS_KEY,
            onError: (error: IApiError) => {
                enqueueSnackbar(error.message, {
                    variant: 'error'
                });
            }
        }
    );

    return {
        exportStudentCertificationReports,
        isExportingStudentCertificationReports
    };
};

export default useExportStudentCertificationReports;