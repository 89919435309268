import { useQuery } from 'react-query';
import { IJobIndex, IJob } from 'src/models/job';
import { IApiError } from '../../../../../utils/axios';
import { fetchStudentJobs, FETCH_STUDENT_JOBS_KEY } from '../../../utils/api';

const useFetchStudentJobs = (studentId: string, options?: any) => {
  const {
    isLoading: isFetchingJobs,
    error: errorFetchingJobs,
    data: jobs
  } = useQuery<IJobIndex[], IApiError>(
    [FETCH_STUDENT_JOBS_KEY, studentId],
    () => fetchStudentJobs(studentId),
    options
  );

  return {
    isFetchingJobs,
    errorFetchingJobs,
    jobs
  };
};

export default useFetchStudentJobs;
