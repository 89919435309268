import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import React, { useMemo, useState } from 'react';
import useToggleVisibility from '../../../../../../hooks/useToggleVisibility';
import JobRequiredKnowHowListItem from './JobRequiredKnowHowListItem';
import CreateEditJobRequiredKnowHowModal from './CreateEditJobRequiredKnowHowModal';
import { IJobRequiredKnowHow } from 'src/models/job-required-know-how';
import { JobStatuses } from 'src/utils/const';
import {
  NoJobDetailsAdded,
  StyledArrayFormBoxFooter
} from '../JobMissions/JobMissionsList';
import {
  ArrayFormBoxJob,
  ArrayFormBoxList
} from 'src/components/ArrayFormBoxJob';

const JOB_KNOW_HOW_BASE_KEY = 'profile.common.jobRequiredKnowHow';

interface IProps {
  jobRequiredKnowHow: IJobRequiredKnowHow[];
  isAddingJobRequiredKnowHow?: boolean;
  isEditingJobRequiredKnowHow?: boolean;
  onRemoveJobRequiredKnowHow(
    index: number,
    jobRequiredKnowHow: IJobRequiredKnowHow
  ): void;
  onEditJobRequiredKnowHow(
    index: number,
    jobRequiredKnowHow: IJobRequiredKnowHow
  ): void;
  onAddJobRequiredKnowHow(jobRequiredKnowHow: IJobRequiredKnowHow): void;
  jobStatus: JobStatuses;
  canManage: boolean;
  readOnly: boolean;
}

const JobRequiredKnowHowList: React.FC<IProps> = ({
  jobRequiredKnowHow,
  isAddingJobRequiredKnowHow,
  isEditingJobRequiredKnowHow,
  onRemoveJobRequiredKnowHow,
  onEditJobRequiredKnowHow,
  onAddJobRequiredKnowHow,
  jobStatus,
  canManage,
  readOnly
}) => {
  const { t } = useTranslation();

  const [jobRequiredKnowHowIndexInModal, setJobRequiredKnowHowIndexInModal] =
    useState(null);
  const {
    isVisible: isVisibleModal,
    show: showModal,
    hide: hideModal
  } = useToggleVisibility(false);

  const jobRequiredKnowHowInModal = useMemo(() => {
    if (jobRequiredKnowHowIndexInModal === null) return null;

    return jobRequiredKnowHow[jobRequiredKnowHowIndexInModal];
  }, [jobRequiredKnowHowIndexInModal]);

  const closeModal = () => {
    hideModal();
    setJobRequiredKnowHowIndexInModal(null);
  };

  const handleSubmitJobRequiredKnowHow = (
    newjobRequiredKnowHow: IJobRequiredKnowHow
  ) => {
    if (jobRequiredKnowHowInModal) {
      onEditJobRequiredKnowHow(jobRequiredKnowHowIndexInModal, {
        ...jobRequiredKnowHowInModal,
        ...newjobRequiredKnowHow
      });
    } else {
      onAddJobRequiredKnowHow(newjobRequiredKnowHow);
    }
    closeModal();
  };

  const handleStartEdit = (index: number) => {
    setJobRequiredKnowHowIndexInModal(index);
    showModal();
  };

  return (
    <ArrayFormBoxJob title={t(`${JOB_KNOW_HOW_BASE_KEY}.boxTitle`)}>
      {jobRequiredKnowHow?.length === 0 && (
        <NoJobDetailsAdded variant="subtitle2">
          {t(`${JOB_KNOW_HOW_BASE_KEY}.noJobRequiredKnowHowAdded`)}
        </NoJobDetailsAdded>
      )}
      <ArrayFormBoxList>
        {jobRequiredKnowHow?.map((knowHow, index) => (
          <JobRequiredKnowHowListItem
            key={index}
            jobRequiredKnowHow={knowHow}
            index={index}
            onDelete={onRemoveJobRequiredKnowHow}
            onEdit={handleStartEdit}
            jobStatus={jobStatus}
            canManage={canManage}
          />
        ))}
      </ArrayFormBoxList>

      {jobStatus !== JobStatuses.VerifiedBySchool && canManage && (
        <StyledArrayFormBoxFooter>
          <Button variant="outlined" size="small" onClick={showModal} disabled={readOnly}>
            <AddIcon fontSize="small" />
            {t(`${JOB_KNOW_HOW_BASE_KEY}.addJobRequiredKnowHow`)}
          </Button>
        </StyledArrayFormBoxFooter>
      )}
      <CreateEditJobRequiredKnowHowModal
        open={isVisibleModal}
        jobRequiredKnowHow={jobRequiredKnowHowInModal}
        onClose={closeModal}
        onSubmit={handleSubmitJobRequiredKnowHow}
        isSubmitting={isEditingJobRequiredKnowHow || isAddingJobRequiredKnowHow}
      />
    </ArrayFormBoxJob>
  );
};

export default JobRequiredKnowHowList;
