import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  TextField
} from '@mui/material';
import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { ICreateRejectMessageForm } from 'src/models/course';
import LoadingButton from '@mui/lab/LoadingButton';

interface IProps {
  title: string;
  description: string | React.ReactElement;
  open: boolean;
  isConfirming: boolean;
  onClose(): void;
  onConfirm(data: ICreateRejectMessageForm): void;
  defaultMessage?: string
}

const VALIDATION_TRANSLATE_KEY = 'common.validation';
const COHORT_SCHOOL_TRANSLATE_KEY = 'cohorts.cohortSchool';
const BUTTONS_TRANSLATE_KEY = 'common.buttons';

const StudentFlowConfirm: React.FC<IProps> = ({
  title,
  description,
  open,
  isConfirming,
  onClose,
  onConfirm,
  defaultMessage = ''
}) => {
  const { t } = useTranslation();

  const handleConfirm = (data: ICreateRejectMessageForm) => {
    onConfirm(data);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle style={{ fontSize: 18 }}>{title}</DialogTitle>
      <DialogContent sx={{ width: 400 }}>
        <DialogContentText> {description}</DialogContentText>
        <Formik<{ message: string }>
          initialValues={{ message: defaultMessage }}
          onSubmit={handleConfirm}
          validationSchema={Yup.object().shape({
            message: Yup.string().optional()
          })}
        >
          {({
            handleSubmit: handleMessageSubmit,
            handleBlur: handleMessageBlur,
            handleChange: handleMessageChange,
            touched: touchedMessage,
            errors: errorsMessage,
            values: MessageValues
          }) => (
            <form onSubmit={handleMessageSubmit}>
              <FormControl fullWidth margin="normal">
                <TextField
                  error={Boolean(
                    touchedMessage.message && errorsMessage.message
                  )}
                  fullWidth
                  margin="normal"
                  helperText={touchedMessage.message && errorsMessage.message}
                  label={t(
                    `${COHORT_SCHOOL_TRANSLATE_KEY}.reject.message.label`
                  )}
                  placeholder={t(
                    `${COHORT_SCHOOL_TRANSLATE_KEY}.reject.message.placeholder`
                  )}
                  name="message"
                  onBlur={handleMessageBlur}
                  onChange={handleMessageChange}
                  value={MessageValues.message}
                  variant="outlined"
                  sx={{ mt: 0 }}
                  multiline
                  rows={3}
                />
              </FormControl>
              <DialogActions sx={{ padding: '8px 0' }}>
                <Button
                  color="secondary"
                  size="small"
                  variant="outlined"
                  onClick={onClose}
                >
                  {t(`${BUTTONS_TRANSLATE_KEY}.cancel`)}
                </Button>
                <LoadingButton
                  loading={isConfirming}
                  variant="contained"
                  size="small"
                  type="submit"
                >
                  {t(`${BUTTONS_TRANSLATE_KEY}.confirm`)}
                </LoadingButton>
              </DialogActions>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default StudentFlowConfirm;
