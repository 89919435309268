import useHaveRole from '../../../../../../hooks/useHaveRole';
import useToggleVisibility from '../../../../../../hooks/useToggleVisibility';
import { RoleName } from '../../../../../../models/role';
import { IUser } from '../../../../../../models/user';
import { StudentStatuses } from '../../../../../../utils/const';
import useDocumentAction from '../../../../../../hooks/useDocumentActions';
import {
  IStudentDocument,
  StudentDocumentType
} from '../../../../../../models/student-document';
import { Grid } from '@mui/material';
import FileCard from './FileCard';
import { Mode } from '../../../../../../utils/types';
import { useTranslation } from 'react-i18next';
import useDeleteStudentDocument from '../../../../../students/hooks/api/useDeleteStudentDocument';
import ConfirmDeleteDocument from '../../../../../cohorts/components/common/ConfirmDeleteDocument';
import { useEffect } from 'react';
import { studentIsOnHold } from 'src/utils/userHelpers';

interface IProps {
  user: IUser;
  requestVerificationUrl: string;
  uploadUrl: string;
  deleteUrl: string;
  isProfile: boolean;
  canManage: boolean;
  canRequestVerification?: boolean;
  setCanRequestVerification?(can: boolean): void;
}

const STUDENT_DOCUMENTS_TRANSLATE_KEY = 'students.documents';

const StudentMultiDocumentsSection: React.FC<IProps> = ({
  user,
  requestVerificationUrl,
  uploadUrl,
  isProfile,
  canManage,
  deleteUrl,
  canRequestVerification,
  setCanRequestVerification
}) => {
  const { t } = useTranslation();

  const isOrgAdmin = useHaveRole(RoleName.OrganisationAdmin);

  const isEditable = !studentIsOnHold(user) && (
    (user.userable.studentStatus !== StudentStatuses.Verified && canManage) ||
    isOrgAdmin);

  const { documents } = user.userable;

  const { localDocuments, handleChange, handleSuccessfullyDeleted } =
    useDocumentAction<IStudentDocument>(
      documents,
      Object.values(StudentDocumentType)
    );

  useEffect(() => {
    const canRequest = !!(
      localDocuments[StudentDocumentType.Cv][0]?.id &&
      localDocuments[StudentDocumentType.Diploma][0]?.id
    );

    if (canRequestVerification !== canRequest && setCanRequestVerification) {
      setCanRequestVerification(canRequest);
    }
  }, [localDocuments, canRequestVerification]);

  const {
    deleteDocument,
    documentToBeDeleted,
    isDeletingDocument,
    setDocumentToBeDeleted
  } = useDeleteStudentDocument();

  return (
    <Grid container rowSpacing={2} columnSpacing={8}>
      <Grid item xs={12} md={6} sx={{ mb: { xs: 3 } }}>
        <FileCard
          title={t(`${STUDENT_DOCUMENTS_TRANSLATE_KEY}.cv`)}
          fileType={StudentDocumentType.Cv}
          canManage={isEditable}
          files={localDocuments[StudentDocumentType.Cv]}
          mode={Mode.Edit}
          onChange={handleChange}
          onDelete={setDocumentToBeDeleted}
          url={uploadUrl}
          isMultiple={false}
        />
      </Grid>
      <Grid item xs={12} md={6} sx={{ mb: { xs: 3 } }}>
        <FileCard
          title={t(`${STUDENT_DOCUMENTS_TRANSLATE_KEY}.diploma`)}
          fileType={StudentDocumentType.Diploma}
          canManage={isEditable}
          files={localDocuments[StudentDocumentType.Diploma]}
          mode={Mode.Edit}
          onChange={handleChange}
          onDelete={setDocumentToBeDeleted}
          url={uploadUrl}
          isMultiple={false}
        />
      </Grid>
      <Grid item xs={12} md={6} sx={{ mt: { lg: '26px' }, mb: { xs: 3 } }}>
        <FileCard
          title={t(`${STUDENT_DOCUMENTS_TRANSLATE_KEY}.evaluationTest`)}
          canManage={isEditable}
          fileType={StudentDocumentType.EvaluationTest}
          files={localDocuments[StudentDocumentType.EvaluationTest]}
          mode={Mode.Edit}
          onChange={handleChange}
          onDelete={setDocumentToBeDeleted}
          url={uploadUrl}
          isMultiple={true}
        />
      </Grid>
      <Grid item xs={12} md={6} sx={{ mt: { lg: '26px' } }}>
        <FileCard
          title={t(`${STUDENT_DOCUMENTS_TRANSLATE_KEY}.waiver`)}
          fileType={StudentDocumentType.Waiver}
          canManage={isEditable}
          files={localDocuments[StudentDocumentType.Waiver]}
          mode={Mode.Edit}
          onChange={handleChange}
          onDelete={setDocumentToBeDeleted}
          url={uploadUrl}
          isMultiple={true}
        />
      </Grid>
      <ConfirmDeleteDocument
        open={!!documentToBeDeleted}
        onClose={() => setDocumentToBeDeleted(null)}
        onConfirm={() =>
          deleteDocument(`${deleteUrl}/${documentToBeDeleted.id}`, {
            onSuccess: () => {
              handleSuccessfullyDeleted(documentToBeDeleted);
            }
          })
        }
        isConfirming={isDeletingDocument}
        documentName={documentToBeDeleted?.file?.name}
      />
    </Grid>
  );
};

export default StudentMultiDocumentsSection;
