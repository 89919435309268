import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { IApiError } from '../../../../utils/axios';
import {
  updateUserAccountInfo as updateAccountInfoApi,
  UPDATE_USER_ACCOUNT_INFO_KEY
} from '../../utils/api';

const UPDATE_ACCOUNT_INFO_TRANSLATE_KEY = 'profile.accountInfo.update';

const useUpdateAccountInfo = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: updateAccountInfo, isLoading: isUpdatingAccountInfo } =
    useMutation(({ data, url }: any) => updateAccountInfoApi(data, url), {
      mutationKey: UPDATE_USER_ACCOUNT_INFO_KEY,
      onSuccess: (data) => {
        enqueueSnackbar(
          t(`${UPDATE_ACCOUNT_INFO_TRANSLATE_KEY}.successMessage`),
          {
            variant: 'success'
          }
        );
      },
      onError: (error: IApiError) => {
        enqueueSnackbar(error.message, {
          variant: 'error'
        });
      }
    });

  return {
    updateAccountInfo,
    isUpdatingAccountInfo
  };
};

export default useUpdateAccountInfo;
