import useAuth from "src/features/auth/hooks/useAuth";
import { useLocation, useNavigate } from 'react-router-dom';
import useToggleVisibility from "src/hooks/useToggleVisibility";
import React, { useEffect } from "react";
import { isStudent } from "src/utils/userTypes";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ProfileRoutes } from "src/features/profile/routes";
import { Tabs } from "src/features/profile/pages/Profile";
import { useTranslation } from "react-i18next";
import { Mode } from "src/utils/types";


export default function FillOutRequiredFieldsAlert() {
    const { t } = useTranslation();
    const { user: auth } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const { isVisible, show, hide } = useToggleVisibility(false);

    const editURL = `${ProfileRoutes.Profile}?tab=${Tabs.UserInfo}&mode=${Mode.Edit}`;

    useEffect(() => {
        if (!isStudent(auth)) return

        const requiredFields = {
            firstName: !!auth.firstName,
            lastName: !!auth.lastName,
            email: !!auth.email,
            civility: !!auth.civility,
            phone: !!auth.phone,
            secondaryEmail: !!auth.secondaryEmail,
            gender: !!auth.gender,

            group: !!auth.userable.group,

            dateOfBirth: !!auth.userable.dateOfBirth,
            placeOfBirth: !!auth.userable.placeOfBirth,
            postalCodeOfBirth: !!auth.userable.postalCodeOfBirth,
            countryOfBirth: !!auth.userable.countryOfBirth,

            addressOfResidence: !!auth.userable.addressOfResidence,
            placeOfResidence: !!auth.userable.placeOfResidence,
            postalCodeOfResidence: !!auth.userable.postalCodeOfResidence,
            countryOfResidence: !!auth.userable.countryOfResidence,

            contractType: !!auth.userable.contractType,
            lastDiploma: !!auth.userable.lastDiploma,
            levelOfLastDiploma: !!auth.userable.levelOfLastDiploma,
        }

        const isOnEditURL = (location.pathname + location.search) === editURL
        const hasMissingFields = Object.entries(requiredFields).filter(([key, value]) => !value).length > 0;

        if (!isOnEditURL && hasMissingFields) {
            show()
        } else {
            hide()
        }
    }, [auth, location]);


    return (
        <React.Fragment>
            <Dialog
                open={isVisible}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t('common.layout.requiredFieldsAlert.title')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('common.layout.requiredFieldsAlert.description')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => navigate(editURL)}>
                        {t('common.layout.userMenu.profileSettings')}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}