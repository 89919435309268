import { FC, ReactNode } from 'react';
import { Box, styled } from '@mui/material';

const PageTitle = styled(Box)(`
  padding: 0;
  display: flex;
  justify-content:space-between;
`);

interface PageTitleWrapperProps {
  children: ReactNode;
}

const PageTitleWrapper: FC<PageTitleWrapperProps> = ({ children }) => {
  return (
    <PageTitle
      className="MuiPageTitle-wrapper"
      sx={{ flexDirection: { xs: 'column-reverse', md: 'row' } }}
    >
      {children}
    </PageTitle>
  );
};

export default PageTitleWrapper;
