const auth = {
  validation: {
    termsAndConditions: 'You must agree to our terms and conditions',
    wrongCredentials: 'Wrong credentials',
    confirmPassword: 'Both password need to be the same'
  },
  links: {
    signIn: 'Sign In',
    lostPassword: 'Lost Password?',
    termsAndConditions: 'terms and conditions',
    signInAgain: 'Want to try to sign in again?',
    continueToLogin: 'Continue to login',
    newPassword: 'Send me a new password',
    resetPassword: 'Reset Password',
    createAccount: 'Create account'
  },
  label: {
    accept: 'I accept the',
    email: 'Email address',
    password: 'Password',
    confirmPassword: 'Confirm Password',
    signIn: 'Fill in the fields below to sign into your account.',
    recoverEmail:
      'Enter the email used for registration to reset your password.',
    recoverPassword: 'Recover Password',
    resetInstruction:
      'The password reset instructions have been sent to your email',
    checkEmail: 'Check your email for further instructions',
    resetPassword: 'Reset Password',
    newPassword: 'Enter the new password.',
    signUp: 'Fill in the fields below to sign up for your account',
    firstName: 'First name',
    lastName: 'Last name'
  },
  error: {
    notFound: 'User not found.',
    mailNotSent: 'Reset Password mail was not sent.',
    badCredentials: 'Invalid Credentials'
  },
  success: {
    resetPassword: 'Successfully reseted password.'
  }
};

export default auth;
