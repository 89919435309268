import { useSnackbar } from 'notistack';
import { useMutation, useQueryClient } from 'react-query';
import { IApiError } from '../../../../utils/axios';
import {
  FETCH_NOTIFICATIONS,
  FETCH_NUMBER_OF_UNREAD_NOTIFICATIONS,
  updateNotification as updateNotificationApi,
  UPDATE_NOTIFICATION
} from '../../utils/api';

const useUpdateNotification = () => {
  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const { mutate: updateNotification, isLoading: isUpdatingNotification } =
    useMutation(updateNotificationApi, {
      mutationKey: UPDATE_NOTIFICATION,
      onSuccess: ({ id }) => {
        queryClient.invalidateQueries([FETCH_NOTIFICATIONS]);
        queryClient.invalidateQueries([FETCH_NUMBER_OF_UNREAD_NOTIFICATIONS]);
      },
      onError: (error: IApiError) => {
        enqueueSnackbar(error.message, {
          variant: 'error'
        });
      }
    });

  return {
    isUpdatingNotification,
    updateNotification
  };
};

export default useUpdateNotification;
