import { Card, Stack, styled, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import File from '../../../../../../components/FileTree/File';
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone';
import React from 'react';

import { useTranslation } from 'react-i18next';
import {
  IStudentDocument,
  StudentDocumentType
} from '../../../../../../models/student-document';
import { Mode } from '../../../../../../utils/types';
import useFileUpload from '../../../../../../hooks/useFileUpload';
import { DocumentUpload } from '../../../../../../hooks/useDocumentActions';

const StyledHeader = styled(Typography)(
  ({ theme }) => `
  padding: ${theme.spacing(3)};
  border-bottom: 1px solid ${theme.colors.alpha.black[30]};
`
);

const StyledInputUpload = styled('input')`
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
`;

interface IProps {
  title: string;
  canManage: boolean;
  files: DocumentUpload<IStudentDocument>[];
  mode: Mode;
  fileType: StudentDocumentType;
  url: string;
  isMultiple: boolean;
  onChange?(
    type: StudentDocumentType,
    files: DocumentUpload<IStudentDocument>[]
  ): void;
  onDelete?(file: DocumentUpload<IStudentDocument>): void;
}

const FileCard: React.FC<IProps> = ({
  title,
  canManage,
  mode,
  files,
  fileType,
  url,
  isMultiple,
  onChange,
  onDelete
}) => {
  const { t } = useTranslation();

  const handleChange = (newFiles: DocumentUpload<IStudentDocument>[]) => {
    const filesThatDoNotExists = newFiles.filter(
      (file) => !files.find((f) => f.uuid === file.uuid)
    );

    const newFilesFormatted = [
      ...filesThatDoNotExists,
      ...files.map((file) => {
        const updateFile = newFiles.find((f) => f.uuid === file.uuid);
        if (updateFile) {
          return updateFile;
        }
        return file;
      })
    ];

    if (onChange) {
      onChange(fileType, newFilesFormatted);
    }
  };

  const handleDelete = (file: DocumentUpload<IStudentDocument>) => {
    if (file.id && onDelete) {
      onDelete(file);
    } else if (onChange) {
      onChange(
        fileType,
        files.filter((f) => f.uuid !== file.uuid)
      );
    }
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxSize: 50 * 1024 * 1024,
    multiple: isMultiple
  });

  useFileUpload({
    acceptedFiles,
    onChange: handleChange,
    url,
    data: { type: fileType }
  });

  return (
    <Card>
      <StyledHeader variant="h5">{title}</StyledHeader>
      <Stack
        sx={{
          position: 'relative',
          padding: '24px 16px'
        }}
      >
        {files?.length > 0 &&
          files.map((file) => (
            <File
              canManage={canManage}
              file={
                {
                  ...file,
                  id: file.file.id,
                  documentId: file.id,
                  name: file.file.name
                } as any
              }
              mode={mode as any}
              onDelete={(file: any) =>
                handleDelete({
                  ...file,
                  id: file.documentId,
                  fileId: file.id
                } as any)
              }
              key={file.uuid || file.id}
            />
          ))}
        {canManage && (isMultiple || files?.length === 0) && (
          <Stack
            {...getRootProps()}
            flexDirection="column"
            alignItems={'center'}
            sx={{ p: '24px' }}
          >
            <StyledInputUpload
              {...getInputProps()}
              style={{ display: 'block' }}
            />
            <CloudUploadTwoToneIcon sx={{ color: '#5569ff', fontSize: 24 }} />
            <Typography fontSize={12}>
              {t('cohorts.assigned.documents.dragAndDropFiles')}
            </Typography>
          </Stack>
        )}
      </Stack>
    </Card>
  );
};

export default FileCard;
