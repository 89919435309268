import { useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router';
import { axiosInt } from 'src/utils/axios';
import { Box } from '@mui/material';
import Logo from 'src/components/LogoSign';

const Impersonate = () => {
  const { url } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const decoded = atob(url);
    axios
      .post(decoded, {})
      .then((response: any) => {
        if (response) {
          localStorage.setItem('accessToken', response.data.access_token);
          axiosInt.defaults.headers.common.Authorization = `Bearer ${response.data.access_token}`;
          navigate('/');
          window.location.reload();
        }
      })
      .catch(() => {
        navigate('/login');
      });
  }, [url]);

  return (
    <Box
      sx={{
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        width: '100%',
        height: '100%'
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Logo />
    </Box>
  );
};

export default Impersonate;
