import { ICertificate } from 'src/models/certificate';
import { ICertificateNotificationConfiguration } from 'src/models/certificate-notification-configuration';
import { IBlock } from '../../../models/block';
import { IDeliverable } from '../../../models/deliverable';
import { IFile } from '../../../models/file';
import { IFolder } from '../../../models/folder';
import { IMark } from '../../../models/mark';
import { ICertificateTaskConfiguration } from '../../../models/certificate-task-configuration';
import { axiosInt } from '../../../utils/axios';
import {
  ICreateUpdateCertificateForm,
  ICreateUpdateCertificateNotificationConfiguration,
  IFetchCertificateParams
} from './types';
import { IFetchNotificationConfigurationParams } from '../hooks/api/notification/useFetchNotificationConfigurations';
import { IPaginateData } from '../../../utils/types';
import { downloadApiFileFromResponse } from 'src/utils/apiFileDownload';

export const FETCH_CERTIFICATES_KEY = 'fetchCertificates';
export const fetchCertificates = async (
  params: IFetchCertificateParams
): Promise<any> => {
  const response = await axiosInt.get('/certificates', { params });

  return response.data;
};

export const FETCH_PUBLISHED_CERTIFICATES_KEY = 'fetchPublishedCertificates';
export const fetchPublishedCertificates = async (): Promise<ICertificate[]> => {
  const response = await axiosInt.get('/published-certificates');
  return response.data;
};

export const FETCH_CERTIFICATE_KEY = 'fetchCertificate';
export const fetchCertificate = async (id: string): Promise<ICertificate> => {
  const response = await axiosInt.get(`/certificates/${id}`);

  return response.data;
};

export const CREATE_CERTIFICATE_KEY = 'createCertificate';
export const createCertificate = async (
  data: ICreateUpdateCertificateForm
): Promise<ICertificate> => {
  const response = await axiosInt.post('/certificates', data);

  return response.data;
};

export const UPDATE_CERTIFICATE_KEY = 'updateCertificate';
export const updateCertificate = async (
  data: ICreateUpdateCertificateForm
): Promise<ICertificate> => {
  const response = await axiosInt.put(`/certificates/${data.id}`, data);
  return response.data;
};

export const DELETE_CERTIFICATE_KEY = 'deleteCertificate';
export const deleteCertificate = async (id: string): Promise<void> => {
  const response = await axiosInt.delete(`/certificates/${id}`);

  return response.data;
};

export const CREATE_CERTIFICATE_FOLDER_KEY = 'createCertificateFolder';
export const createCertificateFolder = async (
  data: Partial<IFolder>
): Promise<IFolder> => {
  const response = await axiosInt.post(
    `/certificates/${data.modelId}/folders`,
    data
  );

  return response.data;
};

export const UPDATE_CERTIFICATE_FOLDER_KEY = 'updateCertificateFolder';
export const updateCertificateFolder = async (
  data: Partial<IFolder>
): Promise<IFolder> => {
  const response = await axiosInt.put(
    `/certificates/${data.modelId}/folders/${data.id}`,
    data
  );

  return response.data;
};

export const DELETE_CERTIFICATE_FOLDER_KEY = 'deleteCertificateFolder';
export const deleteCertificateFolder = async (
  data: Partial<IFolder>
): Promise<IFolder> => {
  const response = await axiosInt.delete(
    `/certificates/${data.modelId}/folders/${data.id}`
  );

  return response.data;
};

export const CREATE_CERTIFICATE_FILE_KEY = 'createCertificateFile';
export const createCertificateFile = async (
  data: Partial<IFile>
): Promise<IFile> => {
  const response = await axiosInt.post(
    `/certificates/${data.modelId}/files`,
    data
  );

  return response.data;
};

export const DELETE_CERTIFICATE_FILE_KEY = 'deleteCertificateFile';
export const deleteCertificateFile = async (
  data: Partial<IFile>
): Promise<IFile> => {
  const response = await axiosInt.delete(
    `/certificates/${data.modelId}/files/${data.id}`
  );

  return response.data;
};

export const FETCH_CERTIFICATE_BLOCK_KEY = 'fetchCertificateBlock';
export const fetchCertificateBlock = async ({
  certificateId,
  blockId
}): Promise<IBlock> => {
  const response = await axiosInt.get(
    `/certificates/${certificateId}/blocks/${blockId}`
  );

  return response.data;
};

export const CREATE_CERTIFICATE_BLOCK_KEY = 'createCertificateBlock';
export const createCertificateBlock = async (data: IBlock): Promise<IBlock> => {
  const response = await axiosInt.post(
    `/certificates/${data.certificateId}/blocks`,
    data
  );

  return response.data;
};

export const DELETE_CERTIFICATE_BLOCK_KEY = 'deleteCertificateBlock';
export const deleteCertificateBlock = async ({
  certificateId,
  id
}: IBlock): Promise<void> => {
  const response = await axiosInt.delete(
    `/certificates/${certificateId}/blocks/${id}`
  );

  return response.data;
};

export const UPDATE_CERTIFICATE_BLOCK_KEY = 'updateCertificateBlock';
export const updateCertificateBlock = async (data: IBlock): Promise<IBlock> => {
  const response = await axiosInt.put(
    `/certificates/${data.certificateId}/blocks/${data.id}`,
    data
  );

  return response.data;
};

export const CREATE_BLOCK_DELIVERABLE_KEY = 'createBlockDeliverable';
export const createBlockDeliverable = async (
  data: Partial<IDeliverable>
): Promise<IDeliverable> => {
  const response = await axiosInt.post(
    `blocks/${data.blockId}/deliverables`,
    data
  );

  return response.data;
};

export const DELETE_BLOCK_DELIVERABLE_KEY = 'deleteBlockDeliverable';
export const deleteBlockDeliverable = async ({
  blockId,
  id
}: IDeliverable): Promise<void> => {
  const response = await axiosInt.delete(
    `blocks/${blockId}/deliverables/${id}`
  );

  return response.data;
};

export const UPDATE_BLOCK_DELIVERABLE_KEY = 'updateBlockDeliverable';
export const updateBlockDeliverable = async (
  data: IDeliverable
): Promise<IDeliverable> => {
  const response = await axiosInt.put(
    `blocks/${data.blockId}/deliverables/${data.id}`,
    data
  );

  return response.data;
};

export const EXPORT_CERTIFICATE_STUDENT_BLOCK_DELIVERABLES_STATUS_REPORT_KEY = 'exportCertificateStudentBlockDeliverablesStatusReport';
export const exportCertificateStudentBlockDeliverablesStatusReport = async (id: string): Promise<void> => {
  const response = await axiosInt.get(`/certificates/${id}/student-block-deliverables-status-report`, { responseType: 'blob' });
  downloadApiFileFromResponse(response);
};

export const CREATE_BLOCK_MARK_KEY = 'createBlockMark';
export const createBlockMark = async (data: Partial<IMark>): Promise<IMark> => {
  const response = await axiosInt.post(`blocks/${data.blockId}/marks`, data);

  return response.data;
};

export const DELETE_BLOCK_MARK_KEY = 'deleteBlockMark';
export const deleteBlockMark = async ({
  blockId,
  id
}: IDeliverable): Promise<void> => {
  const response = await axiosInt.delete(`blocks/${blockId}/marks/${id}`);

  return response.data;
};

export const UPDATE_BLOCK_MARK_KEY = 'updateBlockMark';
export const updateBlockMark = async (data: IMark): Promise<IMark> => {
  const response = await axiosInt.put(
    `/blocks/${data.blockId}/marks/${data.id}`,
    data
  );

  return response.data;
};

export const CREATE_BLOCK_FOLDER_KEY = 'createBlockFolder';
export const createBlockFolder = async (
  data: Partial<IFolder>
): Promise<IFolder> => {
  const response = await axiosInt.post(`/blocks/${data.modelId}/folders`, data);

  return response.data;
};

export const UPDATE_BLOCK_FOLDER_KEY = 'updateBlockFolder';
export const updateBlockFolder = async (
  data: Partial<IFolder>
): Promise<IFolder> => {
  const response = await axiosInt.put(
    `/blocks/${data.modelId}/folders/${data.id}`,
    data
  );

  return response.data;
};

export const DELETE_BLOCK_FOLDER_KEY = 'deleteBlockFolder';
export const deleteBlockFolder = async (
  data: Partial<IFolder>
): Promise<IFolder> => {
  const response = await axiosInt.delete(
    `/blocks/${data.modelId}/folders/${data.id}`
  );

  return response.data;
};

export const CREATE_BLOCK_FILE_KEY = 'createBlockFile';
export const createBlockFile = async (data: Partial<IFile>): Promise<IFile> => {
  const response = await axiosInt.post(`/blocks/${data.modelId}/files`, data);

  return response.data;
};

export const DELETE_BLOCK_FILE_KEY = 'deleteBlockFile';
export const deleteBlockFile = async (data: Partial<IFile>): Promise<IFile> => {
  const response = await axiosInt.delete(
    `/blocks/${data.modelId}/files/${data.id}`
  );

  return response.data;
};

// Task configuration start
export const FETCH_CERTIFICATE_TASKS_CONFIGURATIONS_KEY =
  'fetchCertificateTasksConfigurations';
export const fetchCertificateTasksConfigurations = async (
  certificateId: string
): Promise<ICertificateTaskConfiguration[]> => {
  const response = await axiosInt.get(
    `/certificates/${certificateId}/task-configurations`
  );

  return response.data;
};

export const FETCH_CERTIFICATE_TASK_CONFIGURATION_KEY =
  'fetchCertificateTaskConfiguration';
export const fetchCertificateTaskConfiguration = async ({
  certificateId,
  taskId
}): Promise<ICertificateTaskConfiguration> => {
  const response = await axiosInt.get(
    `/certificates/${certificateId}/task-configurations/${taskId}`
  );

  return response.data;
};

export const UPDATE_CERTIFICATE_TASK_CONFIGURATION_KEY =
  'updateCertificateTaskConfiguration';
export const updateCertificateTaskConfiguration = async ({
  certificateId,
  taskId,
  data
}): Promise<ICertificateTaskConfiguration> => {
  const response = await axiosInt.put(
    `/certificates/${certificateId}/task-configurations/${taskId}`,
    data
  );
  return response.data;
};
// Task configuration end

// Notification configuration start
export const CREATE_NOTIFICATION_CONFIGURATION_KEY =
  'createNotificationConfiguration';
export const createNotificationConfiguration = async (
  data: ICreateUpdateCertificateNotificationConfiguration,
  certificateId: string
): Promise<ICertificateNotificationConfiguration> => {
  const response = await axiosInt.post(
    `/certificates/${certificateId}/notification-configurations`,
    data
  );

  return response.data;
};

export const FETCH_NOTIFICATION_CONFIGURATION_KEY =
  'fetchNotificationConfiguration';
export const fetchNotificationConfiguration = async (
  allParams: any
): Promise<IPaginateData<ICertificateNotificationConfiguration>> => {
  const { certificateId, ...params } = allParams;
  const response = await axiosInt.get(
    `/certificates/${certificateId}/notification-configurations`,
    { params }
  );

  return response.data;
};

export const DELETE_NOTIFICATION_CONFIGURATION_KEY =
  'deleteNotificationConfiguration';
export const deleteNotificationConfiguration = async ({
  certificateId,
  id
}: ICertificateNotificationConfiguration): Promise<void> => {
  const response = await axiosInt.delete(
    `/certificates/${certificateId}/notification-configurations/${id}`
  );

  return response.data;
};

export const UPDATE_NOTIFICATION_CONFIGURATION_KEY =
  'updateNotificationConfiguration';
export const updateNotificationConfiguration = async (
  notification: ICreateUpdateCertificateNotificationConfiguration,
  certificateId: string
): Promise<ICertificateNotificationConfiguration> => {
  const response = await axiosInt.put(
    `/certificates/${certificateId}/notification-configurations/${notification.id}`,
    notification
  );

  return response.data;
};
// Notification configuration end

export const REORDER_CERTIFICATE_FILE_TREE_KEY = 'reorderCertificateFileTree';
export const reorderCertificateFileTree = async ({
  certificateId,
  data
}): Promise<Boolean> => {
  const response = await axiosInt.put(
    `/certificates/${certificateId}/folders-reorder`,
    data
  );

  return response.data;
};

export const REORDER_BLOCK_DOCUMENTS_FILE_TREE_KEY = 'reorderBlockDocumentsFileTree';
export const reorderBlockDocumentsFileTree = async ({
  blockId,
  data
}): Promise<Boolean> => {
  const response = await axiosInt.put(
    `/blocks/${blockId}/folders-reorder`,
    data
  );

  return response.data;
};


