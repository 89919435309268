import { FinalExamStatus } from '../../../models/final-exam';

const en = {
  common: {
    status: {
      [FinalExamStatus.Completed]: 'Completed',
      [FinalExamStatus.Created]: 'Created',
      [FinalExamStatus.Failed]: 'Failed',
      [FinalExamStatus.Passed]: 'Passed',
      [FinalExamStatus.Pending]: 'Pending'
    }
  },
  all: {
    title: 'Final exams',
    subtitle: 'Final exams info',
    htmlTitle: 'Final exams',
    table: {
      columns: {
        studentName: 'Student name',
        date: 'Exam date',
        status: 'Status',
        createdAt: 'Created At'
      }
    }
  },
  view: {
    htmlTitle: 'Final exams | {{name}}',
    title: 'Final exam - {{name}}',
    subtitle: 'Final exams info'
  },
  evaluation: {
    cardTitle: 'Final exam evaluation',
    submitEvaluation: 'Submit evaluation',
    status: {
      passed: 'Passed',
      failed: 'Failed',
      label: 'Status'
    },
    cardStudentTitle: 'Final exam',
    dateAt: 'at'
  },
  status: {
    passedTitle: 'Final exam passed at: {{date}}',
    passedDescription: 'Passed exam description',
    failedTitle: 'Final exam failed at: {{date}}',
    failedDescriptionWithDate: 'New final exam is scheduled for {{date}}',
    failedDescriptionWithoutDate: 'New final exam date will be announced soon'
  },
  tab: {
    completedFinalExams: 'Completed final exams',
    scheduledFinalExams: 'Scheduled final exam',
    pendingFinalExam: 'Pending final exam'
  }
};

export default en;
