import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { IJob } from 'src/models/job';
import Confirm from 'src/components/Confirm';
import { IJobMission } from 'src/models/job-mission';
import JobMissionsList from './JobMissionsList';
import useAddJobMission from '../../../../hooks/api/job/useAddJobMission';
import useUpdateJobMission from '../../../../hooks/api/job/useUpdateJobMission';
import useDeleteJobMission from '../../../../hooks/api/job/useDeleteJobMission';
import { IUser } from 'src/models/user';
import { studentIsOnHold } from 'src/utils/userHelpers';

interface IProps {
  job: IJob;
  canManage: boolean;
  student: IUser;
}

const JOB_MISSIONS_BASE_KEY = 'profile.common.studentJob.delete.jobMissions';

const JobMissions: React.FC<IProps> = ({ job, canManage, student }) => {
  const { t } = useTranslation();

  const { addJobMission, isAddingJobMission } = useAddJobMission();

  const { updateJobMission, isUpdatingJobMission } = useUpdateJobMission();

  const {
    deleteJobMission,
    jobMissionToBeDeleted,
    setJobMissionToBeDeleted,
    isDeletingJobMission
  } = useDeleteJobMission();

  const handleRemoveJobMission = (_: number, jobMission: IJobMission) => {
    setJobMissionToBeDeleted(jobMission);
  };

  const handleEditJobMission = (_: number, newJobMission: IJobMission) => {
    updateJobMission(newJobMission);
  };

  const handleAddJobMission = (jobMission: IJobMission) => {
    addJobMission({ ...jobMission, jobId: job.id });
  };

  return (
    <React.Fragment>
      <JobMissionsList
        jobMissions={job?.jobMissions}
        onAddJobMission={handleAddJobMission}
        onEditJobMission={handleEditJobMission}
        onRemoveJobMission={handleRemoveJobMission}
        isAddingJobMission={isAddingJobMission}
        isEditingJobMission={isUpdatingJobMission}
        jobStatus={job?.status}
        canManage={canManage}
        readOnly={studentIsOnHold(student)}
      />
      <Confirm
        title={t(`${JOB_MISSIONS_BASE_KEY}.modalTitle`)}
        onClose={() => setJobMissionToBeDeleted(null)}
        onConfirm={() => deleteJobMission(jobMissionToBeDeleted)}
        open={!!jobMissionToBeDeleted}
        isConfirming={isDeletingJobMission}
      >
        {jobMissionToBeDeleted && (
          <Trans i18nKey={`${JOB_MISSIONS_BASE_KEY}.modalBody`}>
            Are you sure you want to delete
            <strong>
              {{
                name: ` ${jobMissionToBeDeleted.name} `
              }}
            </strong>
            from job missions list?
          </Trans>
        )}
      </Confirm>
    </React.Fragment>
  );
};

export default JobMissions;
