import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { IApiError } from '../../../../utils/axios';
import {
  FETCH_COHORT_SCHOOL_STUDENTS_KEY,
  importStudents as importStudentsApi,
  IMPORT_STUDENTS_KEY
} from '../../utils/api';

const STUDENTS_BASE_KEY = 'cohorts.students.listAll.importStudentsModal';

const useImportStudents = ({ schoolId, cohortId }) => {
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const refetchStudents = () => {
    setTimeout(() => {
      queryClient.invalidateQueries([
        FETCH_COHORT_SCHOOL_STUDENTS_KEY,
        cohortId,
        schoolId
      ]);
    }, 5000);
  };

  const { mutate: importStudents, isLoading: isImportStudents } = useMutation(
    importStudentsApi,
    {
      mutationKey: IMPORT_STUDENTS_KEY,
      onSuccess: () => {
        refetchStudents();
        enqueueSnackbar(t(`${STUDENTS_BASE_KEY}.successMessage`), {
          variant: 'success'
        });
      },
      onError: (error: IApiError) => {
        const studentsForAlreadyUsed = error?.data?.errors?.students;
        let errorMessage = t(`${STUDENTS_BASE_KEY}.invalidData`);

        if (!!studentsForAlreadyUsed) {
          const filterStudentsForAlreadyUsed = studentsForAlreadyUsed
            .slice(0, 9)
            .map((item) => item)
            .join(', ');

          const numberOfStudentsForAlreadyUsed = studentsForAlreadyUsed.length;

          errorMessage =
            numberOfStudentsForAlreadyUsed +
            t(`${STUDENTS_BASE_KEY}.errorMessage`) +
            filterStudentsForAlreadyUsed;
        }

        enqueueSnackbar(errorMessage, {
          variant: 'error'
        });
      }
    }
  );

  return {
    importStudents,
    isImportStudents
  };
};

export default useImportStudents;
