import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Tooltip,
  Typography,
  MenuItem
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import TableContainer from '../../../../components/Table/TableContainer';
import TableHeaderSortLabel from '../../../../components/Table/TableHeaderSortLabel';
import { ISort } from '../../../../utils/types';
import React from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { IBaseEvaluationConfiguration } from '../../../../models/evaluation-configuration';
import TableActions from 'src/components/Table/TableActions';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CreateEvaluationModal from '../common/CreateEvaluationModal';
import { ICreateEvaluation } from '../../utils/types';
import { printAssignToRoleIdentifiers } from '../../utils/evaluationConfigurationsHelpers';
import { IUser } from 'src/models/user';
import { studentIsInRedemption, studentIsOnHold } from 'src/utils/userHelpers';


interface IProps {
  student: IUser;
  page: number;
  isFetching: boolean;
  total: number;
  evaluationConfigurations: IBaseEvaluationConfiguration[];
  sort?: ISort;
  perPage: number;
  onChangePage(page: number): void;
  onChangeSort(sort: any): void;
  onCreateStudentEvaluation(data: ICreateEvaluation): void;
  onViewStudentEvaluations(evaluationConfiguration: IBaseEvaluationConfiguration): void;
}

export enum Columns {
  Name = 'name',
  AssignedTo = 'assigned_to',
  LinkedTo = 'linked_to',
}

const StudentEvaluationConfigurationsTable: React.FC<IProps> = ({
  student,
  page,
  isFetching,
  total,
  evaluationConfigurations,
  sort,
  perPage,
  onChangePage,
  onChangeSort,
  onCreateStudentEvaluation,
  onViewStudentEvaluations
}) => {
  const { t } = useTranslation();

  /** Create evaluation from evaluation configuration */
  const [evaluationConfiguration, setEvaluationConfiguration] = React.useState<IBaseEvaluationConfiguration>(null);

  const isStudentOnHold = studentIsOnHold(student);
  const isStudentInRedemption = studentIsInRedemption(student);

  return (<>
    <TableContainer
      currentPage={page}
      isFetching={isFetching}
      onChangePage={onChangePage}
      total={total}
      perPage={perPage}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell key="name" align="left">
              <TableHeaderSortLabel
                columnName={Columns.Name}
                sort={sort}
                onChangeSort={onChangeSort}
              >
                {t(`evaluations.listAll.table.columns.name`)}
              </TableHeaderSortLabel>
            </TableCell>
            <TableCell key="linkedTo" align="left" >
              {t(`evaluations.listAll.table.columns.linkedTo`)}
            </TableCell>
            <TableCell key="assignedTo" align="left" >
              {t(`evaluations.listAll.table.columns.assignTo`)}
            </TableCell>
            <TableCell key="evaluationsCount" align="left" >
              {t(`evaluations.listAll.table.columns.evaluationsCount`)}
            </TableCell>
            <TableCell key="isRdemption" align="left">
              {t(`evaluations.studentEvaluations.table.columns.redemption`)}
            </TableCell>
            <TableCell key="actions" align="right" />
          </TableRow>
        </TableHead>
        <TableBody>
          {evaluationConfigurations?.filter((grid) => {
            return isStudentInRedemption ? true : !grid.isRedemption
          }).map((grid) => {

            const isRedemptionEvaluationConfiguration = grid.isRedemption;
            const disableActions = isStudentOnHold || (isStudentInRedemption != isRedemptionEvaluationConfiguration)

            return (
              <TableRow
                hover={true}
                key={grid.id}
                onClick={() => onViewStudentEvaluations(grid)}
              >
                <TableCell align="left">
                  <Typography>{grid.name}</Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography> {grid.linkedTo?.name || ''} </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography>
                    {printAssignToRoleIdentifiers(grid.assignTo)}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography>{grid.evaluationsCount}</Typography>
                </TableCell>
                <TableCell align="left">
                  {
                    grid.isRedemption
                      ? <CheckIcon />
                      : <></>
                  }
                </TableCell>
                <TableCell align="right" >
                  <TableActions>
                    <MenuItem key='action-assign-to-user' disabled={disableActions}
                      onClick={(e) => {
                        e.stopPropagation()
                        setEvaluationConfiguration(grid)
                      }}>
                      <span style={{ paddingRight: "5px" }} >{t(`evaluations.studentEvaluationConfigurations.actions.createEvaluation`)}</span>
                      <AddIcon fontSize="small" />
                    </MenuItem>
                    <MenuItem key='action-view-evaluations'
                      onClick={(e) => {
                        e.stopPropagation()
                        onViewStudentEvaluations(grid)
                      }}>
                      <span style={{ paddingRight: "5px" }} >{t(`evaluations.studentEvaluationConfigurations.actions.viewEvaluations`)}</span>
                      <VisibilityIcon fontSize="small" />
                    </MenuItem>
                  </TableActions>
                </TableCell>
              </TableRow>);
          })}
        </TableBody>
      </Table>
    </TableContainer>
    {evaluationConfiguration &&
      <CreateEvaluationModal
        evaluationConfiguration={evaluationConfiguration}
        onClose={() => setEvaluationConfiguration(null)}
        onSubmit={(values) => {
          setEvaluationConfiguration(null)
          onCreateStudentEvaluation(values)
        }}
      />
    }
  </>);
};

export default StudentEvaluationConfigurationsTable;
