import { useEffect, useState } from 'react';
import { v4 } from 'uuid';

import { axiosInt } from '../../utils/axios';
import { FileUploadingStatus, IFile, IFolder, TreeItemType } from './types';

export const uploadFileApi = async (file: File, url: string) => {
  const formData = new FormData();
  formData.set('file', file);

  const response = await axiosInt.post(url, formData);
  return response.data;
};

export interface IFileToBeUploaded {
  file: File;
  status: FileUploadingStatus;
  uuid: string;
  url?: string;
}

const useFileUpload = ({
  acceptedFiles,
  folder,
  onChange,
  onFileUploaded
}: {
  acceptedFiles: File[];
  folder: IFolder;
  onChange(folder: IFolder): void;
  onFileUploaded(file: Partial<IFile>): void;
}) => {
  const [filesToBeUploaded, setFilesToBeUploaded] = useState<
    IFileToBeUploaded[]
  >([]);

  useEffect(() => {
    const filesToBeUploaded = acceptedFiles.map((file) => ({
      file,
      uuid: v4(),
      status: FileUploadingStatus.ToBeUploaded
    }));

    if (!filesToBeUploaded.length) {
      return;
    }

    const createItemsForTree = () => {
      const firstFolderIndex = folder.children.findIndex(
        (item) => item.type === TreeItemType.Folder
      );

      if (firstFolderIndex === -1) {
        return [
          ...folder.children,
          ...filesToBeUploaded.map((item) => ({
            name: item.file.name,
            type: TreeItemType.File,
            uuid: item.uuid,
            status: FileUploadingStatus.ToBeUploaded
          }))
        ];
      }

      const firstPart = folder.children.slice(0, firstFolderIndex);
      const secondPart = folder.children.slice(
        firstFolderIndex,
        folder.children.length
      );
      return [
        ...firstPart,
        ...filesToBeUploaded.map((item) => ({
          name: item.file.name,
          type: TreeItemType.File,
          uuid: item.uuid,
          status: FileUploadingStatus.ToBeUploaded
        })),
        ...secondPart
      ];
    };

    setFilesToBeUploaded(filesToBeUploaded);

    onChange({
      ...folder,
      children: createItemsForTree()
    });
  }, [acceptedFiles]);

  useEffect(() => {
    const waitingFiles = filesToBeUploaded.filter(
      (file) => file.status === FileUploadingStatus.ToBeUploaded
    );

    if (waitingFiles.length) {
      waitingFiles.forEach((file) => {
        uploadFileApi(file.file, '/files')
          .then((data) => {
            if (onFileUploaded) {
              onFileUploaded({
                name: file.file.name,
                url: data.url,
                folderId: folder.id,
                type: file.file.type as any,
                id: file.uuid
              });
            }

            setFilesToBeUploaded((files) =>
              files.map((item) =>
                item.uuid === file.uuid
                  ? {
                      ...item,
                      status: FileUploadingStatus.Success,
                      url: data.url
                    }
                  : item
              )
            );
          })
          .catch(() => {
            setFilesToBeUploaded((files) =>
              files.map((item) =>
                item.uuid === file.uuid
                  ? { ...item, status: FileUploadingStatus.Error }
                  : item
              )
            );
          });
      });

      setFilesToBeUploaded((files) =>
        files.map((f) =>
          f.status === FileUploadingStatus.ToBeUploaded
            ? { ...f, status: FileUploadingStatus.Uploading }
            : f
        )
      );
    }
  }, [filesToBeUploaded]);

  useEffect(() => {
    const actionFiles = filesToBeUploaded.filter((file) =>
      [
        FileUploadingStatus.Uploading,
        FileUploadingStatus.Error,
        FileUploadingStatus.Success
      ].includes(file.status)
    );

    if (actionFiles.length) {
      onChange({
        ...folder,
        children: folder.children.map((item) => {
          const fileToBeUpdated = actionFiles.find(
            (file) => file.uuid === item.uuid
          );
          if (fileToBeUpdated) {
            return {
              ...item,
              status: fileToBeUpdated.status,
              url: fileToBeUpdated.url
            };
          }
          return item;
        })
      });
    }
  }, [filesToBeUploaded]);
};

export default useFileUpload;
