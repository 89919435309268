import { IFile } from 'src/models/file';
import { IFolder } from 'src/models/folder';
import { ITableApiParams } from '../../../hooks/useTableParams';
import { IPermission } from '../../../models/permission';
import { IRole } from '../../../models/role';
import { axiosInt } from '../../../utils/axios';
import { IPaginateData } from '../../../utils/types';
import { ICreateUpdateRoleForm } from './types';

export const FETCH_ROLES_KEY = 'fetchRoles';
export const fetchRoles = async (
  params: Partial<ITableApiParams>
): Promise<IPaginateData<IRole>> => {
  const response = await axiosInt.get(`/roles`, { params });

  return response.data;
};

export const FETCH_ALL_ROLES_KEY = 'fetchAllRoles';
export const fetchAllRoles = async (): Promise<IRole[]> => {
  const response = await axiosInt.get('/all-roles');
  return response.data;
};

export const CREATE_ROLES_KEY = 'createRole';
export const createRole = async (
  data: ICreateUpdateRoleForm
): Promise<void> => {
  const response = await axiosInt.post('/roles', data);

  return response.data;
};

export const DELETE_ROLE_KEY = 'deleteRole';
export const deleteRole = async (id: string): Promise<void> => {
  const response = await axiosInt.delete(`/roles/${id}`);

  return response.data;
};

export const UPDATE_ROLE_KEY = 'updateRole';
export const updateRole = async (
  roleData: Partial<ICreateUpdateRoleForm>
): Promise<IRole> => {
  const response = await axiosInt.put(`/roles/${roleData.id}`, roleData);

  return response.data;
};

export const FETCH_PERMISSIONS_KEY = 'fetchPermissions';
export const fetchPermissions = async (): Promise<IPermission[]> => {
  const response = await axiosInt.get('/permissions');
  return response.data;
};

export const UPDATE_FOLDER_ROLES_KEY = 'updateFolderRoles';
export const updateFolderRoles = async (
  data: Partial<IFolder>
): Promise<IFolder> => {
  const response = await axiosInt.put(
    `/folders/${data.id}/roles`,
    {roles: data.roles}
  );

  return response.data;
};

export const UPDATE_FILE_ROLES_KEY = 'updateFileRoles';
export const updateFileRoles = async (
  data: Partial<IFile>
): Promise<IFile> => {
  const response = await axiosInt.put(
    `/files/${data.id}/roles`,
    {roles: data.roles}
  );

  return response.data;
};