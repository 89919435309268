import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText
} from '@mui/material';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { IEditUserAccountInfo } from '../../utils/types';
import DragAndDropAvatar from 'src/components/DragAndDropAvatar';
import { Civility, Gender } from 'src/utils/const';

const FORM_BASE_KEY = 'profile.common.accountDetailsForm';

const AccountDetailsFields = ({ isStudentAccount, canEditEmail }: { isStudentAccount: boolean, canEditEmail: boolean }) => {
  const {
    setFieldTouched,
    setFieldValue,
    touched,
    errors,
    handleBlur,
    handleChange,
    values,
    initialValues
  } = useFormikContext<IEditUserAccountInfo>();

  const { t } = useTranslation();

  const handleFileUploaded = (url: string) => {
    setFieldValue('avatar', url);
    setFieldTouched('avatar');
  };
  const civility = Object.entries(Civility).map(([key, value]) => ({
    key,
    value
  }));
  const gender = Object.entries(Gender).map(([key, value]) => ({
    key,
    value
  }));

  return (
    <div>
      <Grid container sx={{ display: 'flex', flexFlow: 'nowrap' }}>
        <Grid item xs={6} sx={{ mr: 4 }}>
          <DragAndDropAvatar
            placeholder={t(`${FORM_BASE_KEY}.avatar.placeholder`)}
            uploadingUrl="/images"
            onFileUploaded={handleFileUploaded}
            initialAvatarUrl={initialValues.avatar}
          />
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <TextField
              error={Boolean(touched.firstName && errors.firstName)}
              fullWidth
              margin="normal"
              helperText={touched.firstName && errors.firstName}
              label={t(`${FORM_BASE_KEY}.firstName.label`)}
              placeholder={t(`${FORM_BASE_KEY}.firstName.placeholder`)}
              name="firstName"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.firstName}
              variant="outlined"
              sx={{ mt: 0 }}
            />
            <TextField
              error={Boolean(touched.lastName && errors.lastName)}
              fullWidth
              margin="normal"
              helperText={touched.lastName && errors.lastName}
              label={t(`${FORM_BASE_KEY}.lastName.label`)}
              placeholder={t(`${FORM_BASE_KEY}.lastName.placeholder`)}
              name="lastName"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.lastName}
              variant="outlined"
            />
            <TextField
              disabled={!canEditEmail}
              error={Boolean(touched.email && errors.email)}
              fullWidth
              margin="normal"
              helperText={touched.email && errors.email}
              label={t(`${FORM_BASE_KEY}.email.label`)}
              placeholder={t(`${FORM_BASE_KEY}.email.placeholder`)}
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="email"
              value={values.email}
              variant="outlined"
            />
            <FormControl fullWidth margin="normal">
              <InputLabel id="mutiple-select-label">
                {t(`${FORM_BASE_KEY}.civility.label`)}
              </InputLabel>
              <Select
                error={Boolean(touched.civility && errors.civility)}
                fullWidth
                label={t(`${FORM_BASE_KEY}.civility.label`)}
                placeholder={t(`${FORM_BASE_KEY}.civility.placeholder`)}
                name="civility"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.civility}
                variant="outlined"
              >
                <MenuItem key={'empty'} value="">-</MenuItem>
                {civility?.map((type, index) => (
                  <MenuItem key={index} value={type.value}>
                    {type.key}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error>
                {touched.civility && errors.civility}
              </FormHelperText>
            </FormControl>
            <TextField
              error={Boolean(touched.phone && errors.phone)}
              fullWidth
              margin="normal"
              helperText={touched.phone && errors.phone}
              label={t(`${FORM_BASE_KEY}.phone.label`)}
              placeholder={t(`${FORM_BASE_KEY}.phone.placeholder`)}
              name="phone"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.phone}
              variant="outlined"
            />
            {isStudentAccount && (<>
              <TextField
                error={Boolean(touched.middleNameOne && errors.middleNameOne)}
                fullWidth
                margin="normal"
                helperText={touched.middleNameOne && errors.middleNameOne}
                label={t(`${FORM_BASE_KEY}.middleNameOne.label`)}
                placeholder={t(`${FORM_BASE_KEY}.middleNameOne.placeholder`)}
                name="middleNameOne"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.middleNameOne}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.middleNameTwo && errors.middleNameTwo)}
                fullWidth
                margin="normal"
                helperText={touched.middleNameTwo && errors.middleNameTwo}
                label={t(`${FORM_BASE_KEY}.middleNameTwo.label`)}
                placeholder={t(`${FORM_BASE_KEY}.middleNameTwo.placeholder`)}
                name="middleNameTwo"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.middleNameTwo}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.secondaryEmail && errors.secondaryEmail)}
                fullWidth
                margin="normal"
                helperText={touched.secondaryEmail && errors.secondaryEmail}
                label={t(`${FORM_BASE_KEY}.secondaryEmail.label`)}
                placeholder={t(`${FORM_BASE_KEY}.secondaryEmail.placeholder`)}
                name="secondaryEmail"
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                value={values.secondaryEmail}
                variant="outlined"
              />
              <FormControl fullWidth margin="normal">
                <InputLabel id="gender-select-label">
                  {t(`${FORM_BASE_KEY}.gender.label`)}
                </InputLabel>
                <Select
                  error={Boolean(touched.gender && errors.gender)}
                  fullWidth
                  labelId='gender-select-label'
                  label={t(`${FORM_BASE_KEY}.gender.label`)}
                  placeholder={t(`${FORM_BASE_KEY}.gender.placeholder`)}
                  name="gender"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.gender}
                  variant="outlined"
                >
                  <MenuItem key={'empty'} value="">-</MenuItem>
                  {gender?.map((type, index) => (
                    <MenuItem key={index} value={type.value}>
                      {t(`profile.accountInfo.genderOptions.${type.value}`)}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error>
                  {touched.gender && errors.gender}
                </FormHelperText>
              </FormControl>
            </>)}

          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default AccountDetailsFields;
