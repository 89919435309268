import { IBaseModel } from './base-model';

export interface IRoleAssign {
  id: string;
  name: string;
}

export interface IAssignToRole extends IRoleAssign {
  description?: string;
}

export enum ItemStatus {
  Competence = 'Competence',
  Criteria = 'Criteria',
}

export enum AnswerType {
  TextAnswer = 'Text answer',
  MultiOptions = 'Multi options'
}

export interface IEvaluationConfigurationFieldsOption {
  id: string;
  text: string;
}

export interface IEvaluationConfigurationField extends IBaseModel {
  gridItem: string;
  itemStatus: ItemStatus;
  answerType: AnswerType;
  options?: IEvaluationConfigurationFieldsOption[];
  isRequired: boolean;
  order: number;
}

export interface IOrderEvaluationConfigurationField {
  id: string;
  order: number;
}

export interface ILinkedToItem {
  id: string;
  name: string;
}

export interface IBaseEvaluationConfiguration extends IBaseModel {
  certificateId: string;
  name: string;
  description?: string;
  assignTo: IAssignToRole[];
  verifyBy: IRoleAssign[];
  visibleTo: IRoleAssign[];
  linkedTo?: ILinkedToItem;
  isRedemption: boolean;
  evaluationsCount: number | null
}

export interface IEvaluationConfiguration extends IBaseEvaluationConfiguration {
  fields: IEvaluationConfigurationField[];
  competenceRequirement: string
}