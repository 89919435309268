import { useQuery } from 'react-query';
import { IJob } from 'src/models/job';
import { IApiError } from '../../../../../utils/axios';
import { fetchStudentJob, FETCH_STUDENT_JOB_KEY } from '../../../utils/api';

const useFetchStudentJob = (studentId: string, options?: any) => {
  const {
    isLoading: isFetchingStudentJob,
    error: errorFetchingStudentJob,
    data: job
  } = useQuery<IJob, IApiError>(
    [FETCH_STUDENT_JOB_KEY, studentId],
    () => fetchStudentJob(studentId),
    options
  );

  return {
    isFetchingStudentJob,
    errorFetchingStudentJob,
    job
  };
};

export default useFetchStudentJob;
