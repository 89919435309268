import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import PageSubtitle from '../../../../components/PageSubtitle';
import PageTitle from '../../../../components/PageTitle';
import PageTitleWrapper from '../../../../components/PageTitleWrapper';
import { CohortRoutes } from '../../routes';
import { ICohort } from 'src/models/cohort';

const VIEW_COHORT_TRANSLATE_KEY = 'cohorts.view';

interface IProps {
  cohort: ICohort;
}

const PageHeader: React.FC<IProps> = ({ cohort }) => {
  const { t } = useTranslation();

  return (
    <PageTitleWrapper>
      <div>
        <PageTitle>{t(`${VIEW_COHORT_TRANSLATE_KEY}.title`)}</PageTitle>
        <PageSubtitle>
          {t(`${VIEW_COHORT_TRANSLATE_KEY}.subtitle`)}
        </PageSubtitle>
      </div>
      <div>
        <Button
          variant="contained"
          size="medium"
          to={CohortRoutes.ListAll}
          component={Link}
          sx={{ mb: { xs: '10px' } }}
        >
          <ArrowBackIcon />
          {t(`${VIEW_COHORT_TRANSLATE_KEY}.backToList`)}
        </Button>
      </div>
    </PageTitleWrapper>
  );
};

export default PageHeader;
