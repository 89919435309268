import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Box,
  Chip,
  OutlinedInput
} from '@mui/material';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { IUpdateUserRoles } from '../../utils/types';
import useFetchAllRoles from 'src/features/acl/hooks/api/useFetchAllRoles';
import { useMemo } from 'react';
import { EntityType } from 'src/models/role';

const FORM_BASE_KEY = 'profile.common.roles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

interface IProps {
  entityType: string;
}

const RolesFields: React.FC<IProps> = ({ entityType }) => {
  const { touched, errors, handleBlur, handleChange, values } =
    useFormikContext<IUpdateUserRoles>();

  const { t } = useTranslation();

  const { roles, isFetchingRoles } = useFetchAllRoles();

  const entityRoles = useMemo(() => {
    if (!roles) {
      return [];
    }
    return roles.filter((role) =>
      [entityType, EntityType.All].includes(role.entityType)
    );
  }, [roles]);

  return (
    <Grid container sx={{ display: 'flex', flexFlow: 'nowrap' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <FormControl
            sx={{ width: '100%', mt: 1.5 }}
            error={Boolean(touched.roles && errors.roles)}
          >
            <InputLabel id="select-roles-label">
              {t(`${FORM_BASE_KEY}.roles.label`)}
            </InputLabel>
            <Select
              labelId="select-roles-label"
              id="select-roles"
              name="roles"
              multiple
              value={values.roles}
              onBlur={handleBlur}
              onChange={handleChange}
              input={
                <OutlinedInput
                  id="select-multiple-chip"
                  label={t(`${FORM_BASE_KEY}.roles.label`)}
                />
              }
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value, index) => (
                    <Chip key={index} label={t(`acl.listAll.name.${value}`)} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {entityRoles.map((role) => (
                <MenuItem key={role.id} value={role.name}>
                  {t(`acl.listAll.name.${role.name}`)}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error>
              {touched.roles && errors.roles}
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RolesFields;
