const schools = {
  common: {
    schoolForm: {
      shortName: {
        label: 'School Short Name',
        placeholder: 'Write school short name here..'
      },
      longName: {
        label: 'School Long Name',
        placeholder: 'Write school long name here..'
      },
      avatar: {
        label: 'School Logo',
        placeholder: 'Upload school logo'
      },
      learningPlatformUrl: {
        label: 'Learning Platform Url',
        placeholder: 'Write learning platform url here...',
      },
    },
    addresses: {
      boxTitle: "SCHOOL'S ADDRESSES",
      addAddress: 'Add address',
      noAddressAdded: 'No address added',
      modalTitle: 'Add school address',
      modalSubtitle: 'Fill in fields below to add school address',
      mainAddress: 'Headquarter office',
      form: {
        address: {
          label: 'Address',
          placeholder: 'Write address here'
        },
        city: {
          label: 'City',
          placeholder: 'Write city here'
        },
        zipCode: {
          label: 'Zip code',
          placeholder: 'Write zip code here'
        },
        country: {
          label: 'Country',
          placeholder: 'Write country code here'
        },
        department: {
          label: 'Department',
          placeholder: 'Write department code here'
        },
        region: {
          label: 'Region',
          placeholder: 'Write region code here'
        },
        isMain: {
          label: 'This is the main address'
        }
      },
      delete: {
        modalTitle: 'Delete address',
        modalBody:
          'Are you sure you want to delete <1>{{name}}</1> from address list?'
      }
    },
    staff: {
      boxTitle: "SCHOOL'S STAFF",
      addStaff: 'Invite school staff',
      noStaffInvited: 'No staff invited',
      modalTitle: 'Invite school staff',
      modalSubtitle: 'Fill in fields below to invite school staff',
      form: {
        email: {
          label: 'Email',
          placeholder: 'Write email here'
        },
        firstName: {
          label: 'First Name',
          placeholder: 'Write first name here'
        },
        lastName: {
          label: 'Last Name',
          placeholder: 'Write last name here'
        },
        roles: {
          label: 'Roles',
          placeholder: 'Select roles'
        }
      },
      delete: {
        modalTitle: 'Delete employee',
        modalBody:
          'Are you sure you want to delete <1>{{name}}</1> from employee list?'
      }
    }
  },
  listAll: {
    htmlTitle: 'Schools',
    title: 'Schools',
    subtitle: 'List all schools',
    addNewSchool: 'Add new school',
    table: {
      columns: {
        school: 'SCHOOL',
        schoolEmployeesCount: 'Employees Count',
        createdAt: 'CREATED AT'
      },
      actionTooltips: {
        edit: 'Edit',
        delete: 'Delete',
        exportLicenseReports: 'Export licenses'
      }
    },
    delete: {
      successMessage: 'School successfully deleted',
      errorMessage: 'Error while deleting school',
      modalTitle: 'Delete school',
      modalBody:
        'Are you sure you want to delete <1>{{name}}</1> from schools list?'
    },
    search: {
      label: 'Search',
      placeholder: 'Search'
    }
  },
  create: {
    htmlTitle: 'Add new school',
    title: 'Add new school',
    subtitle: 'Fill in fields below to add a new school',
    backToList: 'Go back to all schools'
  },
  view: {
    htmlTitle: 'Schools | {{name}}',
    subtitle: 'School information details',
    title: 'School information',
    backToList: 'Go back to all schools',
    schoolDetails: 'Details',
    documents: 'Documents',
    students: 'Students'
  },
  ownView: {
    htmlTitle: 'Organization settings',
    title: 'Organization settings',
    subtitle: 'See and edit organization settings'
  }
};

export default schools;
