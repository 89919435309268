import { useQuery } from 'react-query';
import { IRole } from '../../../../models/role';
import { IApiError } from '../../../../utils/axios';
import { fetchAllRoles, FETCH_ALL_ROLES_KEY } from '../../utils/api';

const useFetchAllRoles = () => {
  const {
    error: errorFetchingRoles,
    data: roles,
    isFetching: isFetchingRoles
  } = useQuery<IRole[], IApiError>([FETCH_ALL_ROLES_KEY], () =>
    fetchAllRoles()
  );

  return {
    errorFetchingRoles,
    roles,
    isFetchingRoles
  };
};

export default useFetchAllRoles;
