const schools = {
  common: {
    schoolForm: {
      shortName: {
        label: "Nom abrégé de l'école",
        placeholder: "Écrivez le nom abrégé de l'école ici.."
      },
      longName: {
        label: "Nom long de l'école",
        placeholder: "Écrivez le nom complet de l'école ici.."
      },
      avatar: {
        label: "Logo de l'école",
        placeholder: "Télécharger le logo de l'école"
      },
      learningPlatformUrl: {
        label: "URL de la plateforme d'apprentissage",
        placeholder: "Écrivez l'URL de la plate-forme d'apprentissage ici...",
      },
    },
    addresses: {
      boxTitle: "ADRESSES DE L'ÉCOLE",
      addAddress: "Ajoutez l'adresse",
      noAddressAdded: 'Aucune adresse ajoutée',
      modalTitle: "Ajouter l'adresse de l'école",
      modalSubtitle:
        "Remplissez les champs ci-dessous pour ajouter l'adresse de l'école",
      mainAddress: 'Siège social',
      form: {
        address: {
          label: 'Adresse',
          placeholder: "Ecrire l'adresse ici"
        },
        city: {
          label: 'Ville',
          placeholder: 'Écrivez la ville ici'
        },
        zipCode: {
          label: 'Code postal',
          placeholder: 'Écrivez le code postal ici'
        },
        country: {
          label: 'Pays',
          placeholder: 'Écrivez le code du pays ici'
        },
        department: {
          label: 'Département',
          placeholder: 'Écrivez le code du département ici'
        },
        region: {
          label: 'Région',
          placeholder: 'Écrivez ici le code de la région'
        },
        isMain: {
          label: "C'est l'adresse principale"
        }
      },
      delete: {
        modalTitle: "Supprimer l'adresse",
        modalBody:
          "Êtes-vous sûr de vouloir supprimer <1>{{name}}</1> de la liste d'adresses?"
      }
    },
    staff: {
      boxTitle: "PERSONNEL DE L'ÉCOLE",
      addStaff: "Inviter le personnel de l'école",
      noStaffInvited: 'Aucun membre du personnel invité',
      modalTitle: "Inviter le personnel de l'école",
      modalSubtitle:
        "Remplissez les champs ci-dessous pour inviter le personnel de l'école",
      form: {
        email: {
          label: 'E-mail',
          placeholder: 'Ecrire un email ici'
        },
        firstName: {
          label: 'Prénom',
          placeholder: 'Écrivez le prénom ici'
        },
        lastName: {
          label: 'Nom de famille',
          placeholder: 'Écrivez ici le nom de famille'
        },
        roles: {
          label: 'Rôles',
          placeholder: 'Sélectionnez les rôles'
        }
      },
      delete: {
        modalTitle: "Supprimer l'employé",
        modalBody:
          'Êtes-vous sûr de vouloir supprimer <1>{{name}}</1> de la liste des employés?'
      }
    }
  },
  listAll: {
    htmlTitle: 'Écoles',
    title: 'Écoles',
    subtitle: 'Lister toutes les écoles',
    addNewSchool: 'Ajouter une nouvelle école',
    table: {
      columns: {
        school: "L'ÉCOLE",
        createdAt: 'CRÉÉ À',
        schoolEmployeesCount: `Nombre d'employés`
      },
      actionTooltips: {
        edit: 'Éditer',
        delete: 'Effacer',
        exportLicenseReports: 'Exporter les statistiques'
      }
    },
    delete: {
      successMessage: 'École supprimée avec succès',
      errorMessage: "Erreur lors de la suppression de l'école",
      modalTitle: "Supprimer l'école",
      modalBody:
        'Voulez-vous vraiment supprimer <1>{{name}}</1> de la liste des écoles?'
    },
    search: {
      label: 'Rechercher',
      placeholder: 'Rechercher'
    },
  },
  create: {
    htmlTitle: 'Ajouter une nouvelle école',
    title: 'Ajouter une nouvelle école',
    subtitle:
      'Remplissez les champs ci-dessous pour ajouter une nouvelle école',
    backToList: 'Retournez dans toutes les écoles'
  },
  view: {
    htmlTitle: 'Écoles | {{name}}',
    subtitle: "Détails des informations sur l'école",
    title: "Informations sur l'école",
    backToList: 'Retournez dans toutes les écoles',
    schoolDetails: `Détails`,
    documents: 'Documents',
    students: 'Etudiants'
  },
  ownView: {
    htmlTitle: "Paramètres de l'organisation",
    title: "Paramètres de l'organisation",
    subtitle: "Afficher et modifier les paramètres de l'organisation"
  }
};

export default schools;
