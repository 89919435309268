import React, { useState } from 'react';
import PageLoader from 'src/components/PageLoader';
import TaskDeadlineAlert from 'src/features/tasks/components/common/TaskDeadlineAlert';
import useTaskOverdue from 'src/features/tasks/hooks/api/useTaskOverdue';
import useTableParams from 'src/hooks/useTableParams';
import { Order } from 'src/utils/types';
import ApiError from '../../../../components/ApiError';
import useHaveRole from '../../../../hooks/useHaveRole';
import { RoleName } from '../../../../models/role';
import { TaskCodes } from '../../../tasks/utils/types';
import useFetchCohortSchoolStudents from '../../hooks/api/useFetchCohortSchoolStudents';
import useInviteStudent from '../../hooks/api/useInviteStudent';
import { IAssignTutorToStudent, IInviteStudent } from '../../utils/types';
import StudentsTable, { Columns } from './StudentsTable';
import useToggleVisibility from 'src/hooks/useToggleVisibility';
import InviteStudentModal from '../common/InviteStudentModal';
import useResendInvitationsStudent from '../../hooks/api/useResendInvitationsStudent';
import Confirm from 'src/components/Confirm';
import { Trans, useTranslation } from 'react-i18next';
import ImportStudentsModal from '../common/ImportStudentsModal';
import { IFileInfo } from 'react-csv-reader';
import useImportStudents from '../../hooks/api/useImportStudents';
import { ICertificate } from '../../../../models/certificate';
import { DateFormat, formatDate, parseCsvImportDates } from 'src/utils/dates';
import AssignTutorsToStudentsModal from '../common/AssignTutorsToStudentsModal';
import useAssignTutorsToStudents from '../../hooks/api/useAssignTutorsToStudents';
import { IStudent } from 'src/models/student';
import useDeleteStudent from '../../hooks/api/useDeleteStudent';
import { useSnackbar } from 'notistack';
import { printUserName } from 'src/utils/userHelpers';

const RESEND_INVITATATIONS_ORGANIZATION_STAFF_BASE_KEY =
  'settings.common.staff.resendInvitations';

const LIST_STUDENTS_TRANSLATE_KEY = 'cohorts.students.listAll';

const STUDENTS_PER_PAGE = 20;

interface IProps {
  cohortId: string;
  schoolId: string;
  canManageStudents: boolean;
  certificate: ICertificate;
  canAssignTutorsToStudents?: boolean
}

const Students: React.FC<IProps> = ({
  cohortId,
  schoolId,
  canManageStudents,
  certificate,
  canAssignTutorsToStudents = false
}) => {
  const { t } = useTranslation();

  const [searchedTerm, setSearchedTerm] = useState('');
  const isOrgAdmin = useHaveRole(RoleName.OrganisationAdmin);

  const [fileInfo, setFileInfo] = React.useState<IFileInfo>();
  const [dataStudentsFromCSV, setDataStudentsFromCSV] = React.useState<
    IInviteStudent[]
  >([]);

  const [fileTwoInfo, setFileTwoInfo] = React.useState<IFileInfo>();
  const [dataAssignTutorsToStudentsFromCSV, setDataAssignTutorsToStudentsFromCSV] = React.useState<
    IAssignTutorToStudent[]
  >([]);

  const { inviteStudent, isInvitingStudent } = useInviteStudent({
    schoolId: schoolId,
    cohortId: cohortId
  });

  const { importStudents, isImportStudents } = useImportStudents({
    schoolId: schoolId,
    cohortId: cohortId
  });

  const { assignTutorsToStudents, isAssignTutorsToStudents } = useAssignTutorsToStudents({
    schoolId: schoolId,
    cohortId: cohortId
  });

  const {
    isVisible: isVisibleModal,
    show: showModal,
    hide: hideModal
  } = useToggleVisibility(false);

  const {
    isVisible: isVisibleModalForImportStudents,
    show: showModalForImportStudents,
    hide: hideModalForImportStudents
  } = useToggleVisibility(false);

  const {
    isVisible: isVisibleModalForAssignTutorsToStudents,
    show: showModalForAssignTutorsToStudents,
    hide: hideModalForAssignTutorsToStudents
  } = useToggleVisibility(false);

  const { onChangePage, onChangeSort, onChangeSearchedTerm, tableParams } =
    useTableParams({
      perPage: STUDENTS_PER_PAGE,
      sort: {
        fieldName: Columns.CreatedAt,
        order: Order.Desc
      }
    });

  const {
    studentsData,
    isFetchingStudents,
    errorFetchingStudents,
    isFirstFetchingStudents
  } = useFetchCohortSchoolStudents(cohortId, schoolId, tableParams);

  const {
    resendInvitationsStudent,
    isResendInvitationsStudent,
    studentToResendInvitations,
    setStudentToResendInvitations
  } = useResendInvitationsStudent();

  const {
    deleteStudent,
    deletingStudent,
    isDeletingStudent,
    setDeleteStudent
  } = useDeleteStudent(cohortId, schoolId);

  const handleInviteStudent = (student: IInviteStudent) => {
    inviteStudent({ ...student, schoolId, cohortId });
  };

  const handleResendInvitations = (_: string, student: any) => {
    setStudentToResendInvitations(student);
  };

  const handleDeleteStudent = (student: IStudent) => {
    setDeleteStudent({ student, fetchParams: tableParams })
  };

  const handleSubmitStudent = (student: IInviteStudent) => {
    handleInviteStudent(student);
    hideModal();
  };

  const { enqueueSnackbar } = useSnackbar();

  const handleImportStudents = (
    data: Array<Array<any>>,
    fileInfo: IFileInfo
  ) => {
    setFileInfo(fileInfo);

    const columns = {};
    data.shift().forEach((name, index) => columns[name] = index);

    const [
      first_name_col,
      last_name_col,
      email_col,
      date_of_birth_col,
      place_of_birth_col,
      group_col
    ] = [
        columns['prenom'] ?? columns['first_name'] ?? undefined,
        columns['nom_de_famille'] ?? columns['last_name'] ?? undefined,
        columns['email'] ?? undefined,
        columns['date_de_naissance'] ?? columns['date_of_birth'] ?? undefined,
        columns['lieu_de_naissance'] ?? columns['place_of_birth'] ?? undefined,
        columns['groupe'] ?? columns['group'] ?? undefined,
      ];

    if (first_name_col === undefined || last_name_col === undefined || email_col === undefined) {
      enqueueSnackbar(t(`${LIST_STUDENTS_TRANSLATE_KEY}.importStudentsModal.invalidCsvData`), {
        variant: 'error',
      });
    }

    setDataStudentsFromCSV(
      data.map((item) => {
        return {
          firstName: item[first_name_col],
          lastName: item[last_name_col],
          email: item[email_col],
          dateOfBirth: date_of_birth_col ? parseCsvImportDates(item[date_of_birth_col]) : undefined,
          placeOfBirth: place_of_birth_col ? item[place_of_birth_col] : undefined,
          group: group_col ? item[group_col] : undefined
        };
      })
    );
  };

  const handleImportStudentsSubmit = () => {
    const students = dataStudentsFromCSV.map((student) => {
      return student.dateOfBirth === undefined ?
        { ...student } :
        {
          ...student,
          dateOfBirth: student.dateOfBirth ? formatDate(student.dateOfBirth, DateFormat.DateDb) : null
        }
    });

    importStudents(
      { schoolId, cohortId, students },
      {
        onSuccess: () => {
          hideModalForImportStudents();
        }
      }
    );
  };


  const handleAssignTutorsToStudents = (
    data: Array<Array<any>>,
    fileInfo: IFileInfo
  ) => {
    setFileTwoInfo(fileInfo);

    const columns = {};
    data.shift().forEach((name, index) => columns[name] = index);

    const [
      tutor_email,
      student_email
    ] = [
        columns['tuteur_email'] ?? columns['tutor_email'],
        columns['etudiant_email'] ?? columns['student_email']
      ];

    if (tutor_email === undefined || student_email === undefined) {
      enqueueSnackbar(t(`${LIST_STUDENTS_TRANSLATE_KEY}.assignTutorsToStudentsModal.invalidCsvData`), {
        variant: 'error',
      });
    }

    setDataAssignTutorsToStudentsFromCSV(
      data.map((item) => {
        return {
          tutorEmail: item[tutor_email],
          studentEmail: item[student_email],
        };
      })
    );
  };

  const handleAssignTutorsToStudentsSubmit = () => {
    assignTutorsToStudents(
      { schoolId, cohortId, data: { jobs: dataAssignTutorsToStudentsFromCSV } },
      {
        onSuccess: () => {
          hideModalForAssignTutorsToStudents();
        }
      }
    );
  };


  const onChangeSearch = (value) => {
    onChangePage(1);
    onChangeSearchedTerm(value);
    setSearchedTerm(value);
  };

  const { isTaskOverdue, isTaskExpired, task } = useTaskOverdue(
    {
      code: TaskCodes.InviteStudents,
      meta: {
        schoolId,
        cohortId
      }
    },
    { enabled: canManageStudents && !isOrgAdmin }
  );

  if (isFirstFetchingStudents) {
    return <PageLoader />;
  }

  if (errorFetchingStudents) {
    return <ApiError error={errorFetchingStudents} />;
  }

  return (
    <>
      {isTaskOverdue && <TaskDeadlineAlert task={task} />}
      <StudentsTable
        isFetching={isFetchingStudents}
        onChangePage={onChangePage}
        onChangeSort={onChangeSort}
        page={tableParams.page}
        sort={tableParams.sort}
        perPage={tableParams.perPage}
        students={studentsData.data}
        total={studentsData.meta.total}
        onChangeSearch={onChangeSearch}
        searchedTerm={searchedTerm}
        canManage={canManageStudents}
        canAddStudent={isTaskExpired ? false : canManageStudents}
        canAssignTutorsToStudents={canAssignTutorsToStudents}
        onAddStudent={showModal}
        onImportStudents={() => {
          showModalForImportStudents();
          setFileInfo(undefined);
          setDataStudentsFromCSV([]);
        }}
        onAssignTutorsToStudents={() => {
          showModalForAssignTutorsToStudents();
          setFileTwoInfo(undefined);
          setDataAssignTutorsToStudentsFromCSV([]);
        }}
        onResendInvitations={handleResendInvitations}
        onDeleteStudent={handleDeleteStudent}
      />
      <InviteStudentModal
        onClose={hideModal}
        open={isVisibleModal}
        onSubmit={handleSubmitStudent}
        isSubmitting={isInvitingStudent}
      />
      <ImportStudentsModal
        onClose={hideModalForImportStudents}
        open={isVisibleModalForImportStudents}
        fileInfo={fileInfo}
        certificate={certificate}
        data={dataStudentsFromCSV}
        onImportStudents={handleImportStudents}
        onSubmit={handleImportStudentsSubmit}
        isSubmitting={isImportStudents}
      />
      {canAssignTutorsToStudents && (
        <AssignTutorsToStudentsModal
          onClose={hideModalForAssignTutorsToStudents}
          open={isVisibleModalForAssignTutorsToStudents}
          fileInfo={fileTwoInfo}
          data={dataAssignTutorsToStudentsFromCSV}
          onAssignTutorsToStudents={handleAssignTutorsToStudents}
          onSubmit={handleAssignTutorsToStudentsSubmit}
          isSubmitting={isAssignTutorsToStudents}
        />
      )}
      <Confirm
        title={t(
          `${RESEND_INVITATATIONS_ORGANIZATION_STAFF_BASE_KEY}.modalTitle`
        )}
        onClose={() => setStudentToResendInvitations(null)}
        onConfirm={() => resendInvitationsStudent(studentToResendInvitations)}
        open={!!studentToResendInvitations}
        isConfirming={isResendInvitationsStudent}
      >
        {studentToResendInvitations && (
          <Trans
            i18nKey={`${RESEND_INVITATATIONS_ORGANIZATION_STAFF_BASE_KEY}.modalBody`}
          >
            Are you sure you want to resend invitations for
            <strong>
              {{
                name: printUserName(studentToResendInvitations?.user)
              }}
            </strong>
            ?
          </Trans>
        )}
      </Confirm>
      <Confirm
        title={t(`${LIST_STUDENTS_TRANSLATE_KEY}.deleteStudent.modalTitle`)}
        onClose={() => setDeleteStudent({})}
        onConfirm={() => deleteStudent()}
        open={!!deletingStudent}
        isConfirming={isDeletingStudent}
      >
        <Trans i18nKey={`${LIST_STUDENTS_TRANSLATE_KEY}.deleteStudent.modalBody`}>
          Are you sure you want to delete
          <strong>
            {{ email: deletingStudent?.user.email }}
          </strong>
          ?
        </Trans>
      </Confirm>
    </>
  );
};

export default Students;
