import {
  Grid,
  Paper,
  Typography,
  Divider,
  CardContent,
  IconButton
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import { Mode } from 'src/utils/types';
import { useState } from 'react';
import CertifierPersonalInfoDetails from './CertifierEmployeePersonalInfoDetails';
import EditIcon from '@mui/icons-material/Edit';
import useUpdateCertifierEmployeePersonalInfo from 'src/features/profile/hooks/api/useUpdateCertifierEmployeePersonalInfo';
import { IUser } from 'src/models/user';
import { useQueryClient } from 'react-query';
import { FETCH_CERTIFIER_EMPLOYEE_KEY } from 'src/features/profile/utils/api';
import useAuth from 'src/features/auth/hooks/useAuth';

const PROFILE_PERSONAL_INFO_TRANSLATE_KEY =
  'profile.professionalInfo.professionalInfoSection';

interface IProps {
  user: IUser;
  url: string;
  storeCvUrl: string;
  isProfile: boolean;
}

const CertifierEmployeePersonalInfoSection: React.FC<IProps> = ({
  user,
  url,
  storeCvUrl,
  isProfile
}) => {
  const { t } = useTranslation();
  const [personalInfoLocalMode, setPersonalInfoLocalMode] = useState(Mode.View);
  const { isUpdatingCertifierPersonalInfo, updateCertifierPersonalInfo } =
  useUpdateCertifierEmployeePersonalInfo();

  const { updateProfile } = useAuth();
  const queryClient = useQueryClient();

  const handleEdit = (data) => {
    updateCertifierPersonalInfo(
      { data, url },
      {
        onSuccess: (data) => {
          if (isProfile){
            updateProfile(data)
          } else {
            queryClient.invalidateQueries([FETCH_CERTIFIER_EMPLOYEE_KEY]);
          }
          setPersonalInfoLocalMode(Mode.View);
        }
      }
    );
  };
  return (
    <Grid container spacing-xs={3}>
      <Grid item xs={12}>
        <Paper
          elevation={1}
          square={false}
          sx={{ borderRadius: '8px', padding: 0 }}
        >
          <Box
            sx={{
              padding: '24px 32px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              position: 'relative'
            }}
          >
            <Box>
              <Typography
                variant="h4"
                gutterBottom
                sx={{ fontWeight: 700, fontSize: '14px' }}
              >
                {t(`${PROFILE_PERSONAL_INFO_TRANSLATE_KEY}.title`)}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{ fontWeight: 400, fontSize: '15px' }}
              >
                {t(`${PROFILE_PERSONAL_INFO_TRANSLATE_KEY}.subtitle`)}
              </Typography>
            </Box>
            <IconButton
              size="small"
              onClick={() => setPersonalInfoLocalMode(Mode.Edit)}
              color="primary"
            >
              <EditIcon fontSize="small" />
            </IconButton>
          </Box>
          <Divider />
          <CardContent sx={{ padding: '32px', paddingBottom: '24px' }}>
            <CertifierPersonalInfoDetails
              handleEdit={handleEdit}
              personalInfoLocalMode={personalInfoLocalMode}
              isUpdatingPersonalInfo={isUpdatingCertifierPersonalInfo}
              setPersonalInfoLocalMode={setPersonalInfoLocalMode}
              user={user}
              storeCvUrl={storeCvUrl}
            />
          </CardContent>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default CertifierEmployeePersonalInfoSection;
