import { IEvaluation } from "src/models/evaluation";
import { IUser } from "src/models/user";
import { RoleName } from 'src/models/role';
import { userHasAnyRole, userHasAnyPermission } from "src/utils/userHelpers";
import { Permissions, VerificationStatus } from "src/utils/const";


export const canUserCreateEvaluations = (user: IUser): boolean => {
    return userHasAnyPermission(user, [
        Permissions.ManageEvaluations
    ]);
};

export const canUserOverrideEvaluation = (user: IUser): boolean => {
    return userHasAnyPermission(user, [
        Permissions.OverrideEvaluation
    ]);
};

export const canUserDeleteEvaluations = (user: IUser): boolean => {
    return canUserCreateEvaluations(user)
};


export const canUserViewEvaluation = (user: IUser, evaluation: IEvaluation): boolean => {
    if (!Boolean(user) || !Boolean(evaluation)) return false
    return (
        canUserCreateEvaluations(user) ||
        canUserFillEvaluation(user, evaluation) ||
        canUserVerifyEvaluation(user, evaluation) ||
        (isVerified(evaluation) && userHasAnyRole(user, evaluation.evaluationConfiguration.visibleTo?.map(r => r.name)))
    )
};

export const canUserFillEvaluation = (user: IUser, evaluation: IEvaluation): boolean => {
    if (!Boolean(user) || !Boolean(evaluation)) return false
    return (
        !isVerified(evaluation) &&
        (evaluation.assignedTo.id === user.id || canUserOverrideEvaluation(user))
    )
};

export const canUserRequestEvaluationVerification = (user: IUser, evaluation: IEvaluation): boolean => {
    if (!Boolean(user) || !Boolean(evaluation)) return false
    return (
        canUserFillEvaluation(user, evaluation) &&
        areRequiredFieldsFilled(evaluation) &&
        canEvaluationBeSentForVerification(evaluation)
    )
}

export const canUserVerifyEvaluation = (user: IUser, evaluation: IEvaluation): boolean => {
    if (!Boolean(user) || !Boolean(evaluation)) return false
    return (
        !isVerified(evaluation) &&
        (userHasAnyRole(user, evaluation.evaluationConfiguration.verifyBy?.map(r => r.name)) || canUserOverrideEvaluation(user))
    )
};

export const isVerified = (evaluation: IEvaluation): boolean => {
    if (!Boolean(evaluation)) return false
    return (
        evaluation.verifiedAt !== null
    )
}

export const canEvaluationBeSentForVerification = (evaluation: IEvaluation): boolean => {
    if (!Boolean(evaluation)) return false
    return (
        !isVerified(evaluation) &&
        [VerificationStatus.Draft, VerificationStatus.Rejected].includes(evaluation.status)
    )
}

export const canEvaluationBeVerified = (evaluation: IEvaluation): boolean => {
    if (!Boolean(evaluation)) return false
    return (
        !isVerified(evaluation) &&
        [VerificationStatus.PendingVerification, VerificationStatus.Rejected].includes(evaluation.status)
    )
}

export const canEvaluationBeRejected = (evaluation: IEvaluation): boolean => {
    if (!Boolean(evaluation)) return false
    return (
        !isVerified(evaluation) &&
        [VerificationStatus.PendingVerification].includes(evaluation.status)
    )
}

export const areRequiredFieldsFilled = (evaluation: IEvaluation): boolean => {
    if (!Boolean(evaluation)) return false

    /** For filled student evaluation fields get corresponding evaluation configuration fields ID's */
    let filled = evaluation.fields
        .filter(f => Boolean(f.evaluation) && Boolean(f.feedback))
        .map(f => f.evaluationConfigurationFieldId);

    return (
        evaluation.evaluationConfiguration.fields
            .filter((configField) =>
                configField.isRequired &&
                false === filled.includes(configField.id)
            ).length === 0
    )
}

export const areAllFieldsFilled = (evaluation: IEvaluation): boolean => {
    if (!Boolean(evaluation)) return false

    /** For filled student evaluation fields get corresponding evaluation configuration fields ID's */
    let filled = evaluation.fields
        .filter(f => Boolean(f.evaluation) && Boolean(f.feedback))
        .map(f => f.evaluationConfigurationFieldId);

    return (
        evaluation.evaluationConfiguration.fields
            .filter((configField) =>
                false === filled.includes(configField.id)
            ).length === 0
    )
}
