import { ITask } from 'src/models/task';
import { TaskCodes } from './types';
import { IUser } from '../../../models/user';
import UrlBuilder, { IUrl } from '../../../utils/UrlBuilder';
import { TaskRoutes } from '../routes';
import { JuryType } from 'src/models/jury';

export const buildRedirectUrl = (task: ITask, user: IUser): IUrl => {
  const urlBuilder = new UrlBuilder(user);

  if (!task.code) {
    return { href: TaskRoutes.Task.replace(':id', task.id) };
  }

  switch (task.code) {
    case TaskCodes.UploadStudentDocuments:
    case TaskCodes.VerifyStudentDocuments:
      return urlBuilder.buildStudentDocumentsRoute({
        studentId: task.meta.studentId
      });

    case TaskCodes.UploadStudentPassport:
    case TaskCodes.VerifyStudentPassport:
      return urlBuilder.buildStudentCertificationPassportRoute({
        studentId: task.meta.studentId
      });

    case TaskCodes.AuditStudentCertification:
    case TaskCodes.JuryReachStudentCertificationDecision: {
      return urlBuilder.buildStudentCertificationAuditJuryRoute({
        studentId: task.meta.studentId
      });
    }

    case TaskCodes.UploadCourseDocuments:
    case TaskCodes.VerifyCourseDocuments:
      return urlBuilder.buildCourseDocumentsRoute({
        schoolId: task.meta.schoolId,
        cohortId: task.meta.cohortId
      });

    case TaskCodes.InviteStudents:
      return urlBuilder.buildStudentsRoute({
        schoolId: task.meta.schoolId,
        cohortId: task.meta.cohortId
      });

    case TaskCodes.AssignTutorToStudent:
      return urlBuilder.buildStudentRoute({ studentId: task.meta.studentId });

    case TaskCodes.VerifyJobDescription:
    case TaskCodes.AddJobDescription:
      return urlBuilder.buildStudentJobRoute({
        studentId: task.meta.studentId
      });

    case TaskCodes.AssignCorrectors: {
      return urlBuilder.buildCohortBlocksRoute({
        schoolId: task.meta.schoolId,
        cohortId: task.meta.cohortId
      });
    }

    case `${TaskCodes.UploadBlockDeliverables}_${task.meta?.blockId}`: {
      return urlBuilder.buildStudentBlockRoute({
        studentId: task.meta.studentId,
        studentBlockId: task.meta.studentBlockId
      });
    }

    case `${TaskCodes.AddEvaluation}_${task.meta?.evaluationConfigurationId}`:
    case `${TaskCodes.VerifyEvaluation}_${task.meta?.evaluationConfigurationId}`: {
      return urlBuilder.buildStudentEvaluation({
        studentId: task.meta.studentId,
        evaluationId: task.meta.evaluationId
      });
    }

    case TaskCodes.RescheduleFinalExamDocument:
    case TaskCodes.UploadFinalExamDocument:
    case TaskCodes.EnterFinalExamEvaluation: {
      return urlBuilder.buildStudentFinalExamRoute({
        studentId: task.meta.studentId
      });
    }

    case TaskCodes.AssignJuryMember: {
      return {
        href: `${TaskRoutes.RedirectToStudent.replace(
          ':cohortId',
          task.meta.cohortId
        )
          .replace(':schoolId', task.meta.schoolId)
          .replace(':juryType', JuryType.Member)}`
      };
    }
    case TaskCodes.AssignJuryPresident: {
      return {
        href: `${TaskRoutes.RedirectToStudent.replace(
          ':cohortId',
          task.meta.cohortId
        )
          .replace(':schoolId', task.meta.schoolId)
          .replace(':juryType', JuryType.President)}`
      };
    }

    default:
      return { href: urlBuilder.setDashboardRoute().get() };
  }
};
