import React from 'react';
import Status500 from '../../features/status/pages/Status500';

interface IProps {
  children:
    | React.ReactChild
    | React.ReactChildren
    | React.ReactElement
    | React.ReactElement[];
}

interface IState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <Status500 />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
