import { UserStatus } from 'src/models/user';

const settings = {
  common: {
    organizationForm: {
      name: {
        label: 'Nom du certificateur',
        placeholder: 'Saisir le nom du certificateur ici..'
      }
    },
    staff: {
      boxTitle: 'STAFF Certificateur',
      addStaff: 'Inviter un collaborateur',
      noStaffInvited: 'Aucun collaborateur invité',
      modalTitle: 'Inviter un collaborateur',
      modalSubtitle:
        'Saisir les champs ci-dessous pour inviter un nouveau collaborateur',
      form: {
        email: {
          label: 'E-mail',
          placeholder: "Saisir l'e-mail ici"
        },
        firstName: {
          label: 'Prénom',
          placeholder: 'Saisir le prénom ici'
        },
        lastName: {
          label: 'Nom de famille',
          placeholder: 'Saisir le nom de famille ici'
        },
        roles: {
          label: 'Rôles',
          placeholder: 'Sélectionner les rôles'
        }
      },
      delete: {
        modalTitle: 'Supprimer le compte',
        modalBody:
          'Confirmation de la suppression de <1>{{name}}</1> de la liste des collaborateurs ?'
      },
      userStatus: {
        [UserStatus.Active]: 'Actif',
        [UserStatus.Invited]: 'Invité'
      },
      resendInvitations: {
        modalTitle: 'Renvoyer les invitations',
        modalBody:
          'Voulez-vous vraiment renvoyer des invitations pour <1>{{name}}</1>?',
        successMessage: 'Renvoyer le lien des invitations envoyé avec succès!'
      }
    }
  },
  ownView: {
    htmlTitle: 'Paramètres certificateur',
    title: 'Paramètres certificateur',
    subtitle: 'Modification des paramètres'
  }
};

export default settings;
