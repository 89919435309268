import {
  styled,
  Typography,
  useTheme,
  Tooltip,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Card,
  MenuItem
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import EditTaskDeadlineModal from './EditTaskDeadlineModal';
import { ICohortTaskConfiguration } from '../../../../models/cohort-task-configuration';
import { DateFormat, formatDate } from '../../../../utils/dates';
import useTriggerTask from '../../hooks/api/useTriggerTask';
import { TriggerType } from '../../utils/types';
import TableActions from 'src/components/Table/TableActions'
import TouchAppIcon from '@mui/icons-material/TouchApp';

const TASKS_BASE_KEY = 'cohorts.common.tasks';

const NoTaskAdded = styled(Typography)(
  ({ theme }) => `
  padding: ${theme.spacing(2)};
`
);

const Deadline = ({ dueDate, daysToComplete }) => {
  if (dueDate) {
    return <span>{formatDate(dueDate, DateFormat.DateAndTime)}</span>;
  }
  if (daysToComplete) {
    return <span>{daysToComplete} days</span>;
  }
  return <span>-</span>;
};

interface IProps {
  tasks: Partial<ICohortTaskConfiguration>[];
  canManage: boolean;
  onChangeTask(task: Partial<ICohortTaskConfiguration>): void;
  isFetching: boolean
}

const TaskList: React.FC<IProps> = ({ tasks, onChangeTask, canManage, isFetching }) => {
  const { t } = useTranslation();

  const [editTask, setEditTask] =
    useState<Partial<ICohortTaskConfiguration>>(null);

  const theme = useTheme();

  const handleChangeTask = (newTask: Partial<ICohortTaskConfiguration>) => {
    onChangeTask(newTask);
    setEditTask(null);
  };

  const { triggerTask, isTriggering } = useTriggerTask();

  return (
    <>
      <Card sx={{ p: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell key="name" align="left">
                {t(`${TASKS_BASE_KEY}.rowLabels.name`)}
              </TableCell>
              <TableCell key="deadline" align="left">
                {t(`${TASKS_BASE_KEY}.rowLabels.deadline`)}
              </TableCell>
              <TableCell key="createdAt" align="left">
                {t(`${TASKS_BASE_KEY}.rowLabels.trigger`)}
              </TableCell>
              <TableCell key="actions" align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {tasks.map((task, index) => (
              <TableRow
                hover={true}
                key={index}
              >
                <TableCell align="left">
                  <Typography>{task?.certificateTaskConfiguration?.name || task?.name}</Typography>
                </TableCell>
                <TableCell align="left">
                  <Deadline
                    dueDate={task.dueDate}
                    daysToComplete={task.daysToComplete}
                  />
                </TableCell>
                <TableCell align="left" width={200}>
                  <Typography>
                    {t(`${TASKS_BASE_KEY}.triggerTypes.${task.triggerType}`)}
                    {task.triggerType === TriggerType.Date && (
                      <span style={{ marginLeft: theme.spacing(2) }}>
                        {formatDate(task.triggerDate, DateFormat.DateAndTime)}
                      </span>
                    )}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <TableActions>
                    <MenuItem
                      key={'action-edit'}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 1,
                        minWidth: '120px'
                      }}
                      disabled={!canManage}
                      onClick={() => setEditTask(task)}
                    >
                      <Typography style={{ paddingRight: '14px' }}>
                        {t(
                          `cohorts.listAll.table.actionTooltips.edit`
                        )}
                      </Typography>
                      <EditIcon fontSize="small" />
                    </MenuItem>
                    {task?.triggerType === TriggerType.Manually && task?.id &&
                      <MenuItem
                        key={'action-trigger'}
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          marginBottom: 1,
                          minWidth: '120px'
                        }}
                        onClick={() => triggerTask(task)}
                        disabled={isTriggering(task.id)}
                      >
                        <Typography style={{ paddingRight: '14px' }}>
                          {t(
                            `${TASKS_BASE_KEY}.triggerButton`
                          )}
                        </Typography>
                        {task.triggered && <Tooltip
                          title={t(`${TASKS_BASE_KEY}.taskAlreadyTriggered`)}
                          arrow
                        >
                          <TouchAppIcon fontSize="small" />
                        </Tooltip>}
                        {!task.triggered && <TouchAppIcon fontSize="small" />}
                      </MenuItem>}
                  </TableActions>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
      <EditTaskDeadlineModal
        onClose={() => setEditTask(null)}
        open={!!editTask}
        onSubmit={handleChangeTask}
        task={editTask}
      />
    </>
  );
};

export default TaskList;
