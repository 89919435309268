import { lazy } from 'react';
import SuspenseRouteLoader from '../../components/SuspenseRouteLoader';

const Profile = SuspenseRouteLoader(lazy(() => import('./pages/Profile')));

export const ProfileRoutes = {
  Profile: '/profile'
};

const routes = [
  {
    path: ProfileRoutes.Profile,
    element: <Profile />
  }
];

export default routes;
