import { AlertTitle, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { StyledAlertWithAction } from "../RequestReview";

const STUDENT_JOB_TRANSLATE_KEY = 'profile.student.job';

const PendingVerificationAlert = ({
  onReject,
  onVerify,
  canVerifyWithoutStatusCheck,
  readOnly
}) => {
  const { t } = useTranslation();

  return (
    <StyledAlertWithAction
      sx={{ border: '1px solid#33c2ff;' }}
      severity="info"
      action={
        <>
          <Button
            disabled={readOnly}
            color="error"
            size="medium"
            onClick={onReject}
            variant="contained"
            sx={{
              ml: 2,
              minWidth: '90px'
            }}
          >
            {t(`${STUDENT_JOB_TRANSLATE_KEY}.reject.button`)}
          </Button>
          <Button
            disabled={readOnly}
            color="primary"
            size="medium"
            onClick={onVerify}
            variant="contained"
            sx={{
              ml: 2,
              minWidth: '90px'
            }}
          >
            {t(`${STUDENT_JOB_TRANSLATE_KEY}.verify.button`)}
          </Button>
        </>
      }
    >
      <AlertTitle>
        <strong>
          {!canVerifyWithoutStatusCheck
            ? t(
              `${STUDENT_JOB_TRANSLATE_KEY}.reviewAlert.pendingVerificationTitle`
            )
            : t(`${STUDENT_JOB_TRANSLATE_KEY}.reviewAlert.verifyTitle`)}
        </strong>
      </AlertTitle>
      {!canVerifyWithoutStatusCheck &&
        t(`${STUDENT_JOB_TRANSLATE_KEY}.reviewAlert.pendingVerificationText`)}
    </StyledAlertWithAction>
  );
};

export default PendingVerificationAlert;