import {
  Card,
  Box,
  Avatar,
  Typography,
  styled,
  Button,
  Tooltip,
  useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import CampaignIcon from '@mui/icons-material/Campaign';
import TimerIcon from '@mui/icons-material/Timer';

import { TaskRoutes } from '../../../tasks/routes';
import { DateFormat, formatDate } from '../../../../utils/dates';
import { ITask } from 'src/models/task';
import { TaskStatuses } from '../../../../utils/const';

interface IProps {
  tasks?: ITask[];
}

const TASKS_TRANSLATE_KEY = 'tasks.listAll';
const TASKS_DASHBOARD_KEY = 'dashboard.tasks';

const CardAreaWrapper = styled(Card)(
  ({ theme }) => `
    padding: ${theme.spacing(2.5, 4)};
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `
);

const isTaskOverdue = (task: ITask) => task.status === TaskStatuses.Overdue;

const TasksList: React.FC<IProps> = ({ tasks }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const theme = useTheme();

  const handleRedirectToTask = (task: ITask) => {
    navigate(TaskRoutes.Task.replace(':id', task.id));
  };

  if (tasks.length === 0) {
    return (
      <Typography
        variant="subtitle2"
        color="primary"
        fontWeight={700}
        sx={{
          fontSize: '20px',
          color: '#242E6F'
        }}
      >
        {t(`${TASKS_DASHBOARD_KEY}.noTasks`)}
      </Typography>
    );
  }

  return (
    <>
      {tasks?.slice(0, 3).map((task) => (
        <Card
          sx={{ mb: 2 }}
          key={task.id}
          onClick={() => navigate(TaskRoutes.Task.replace(':id', task.id))}
        >
          <CardAreaWrapper>
            <Box display="flex" alignItems="center">
              <Avatar
                sx={{
                  width: 44,
                  height: 44,
                  background: isTaskOverdue(task)
                    ? theme.colors.error.lighter
                    : theme.colors.success.lighter,
                  borderRadius: '6px'
                }}
              >
                <CampaignIcon
                  fontSize="medium"
                  sx={{
                    color: isTaskOverdue(task)
                      ? theme.colors.error.main
                      : theme.colors.success.main
                  }}
                />
              </Avatar>
              <Box ml={3}>
                <Typography
                  variant="subtitle2"
                  color="primary"
                  fontWeight={700}
                  sx={{
                    fontSize: '20px',
                    color: '#242E6F'
                  }}
                >
                  {task.name}
                </Typography>
                {task.description && (
                  <Typography
                    sx={{
                      fontSize: '20px',
                      lineHeight: '18px',
                      color: `rgba(34, 51, 84, 0.5)`,
                      maxWidth: '370px',
                      mt: 1
                    }}
                  >
                    {task.description}
                  </Typography>
                )}
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              {task.dueDate && (
                <Tooltip
                  title={t(`${TASKS_TRANSLATE_KEY}.table.columns.dueDate`)}
                  arrow
                >
                  <Box
                    color="text.secondary"
                    sx={{
                      opacity: 0.7,
                      display: 'flex',
                      alignItems: 'center'
                    }}
                    mr={3}
                  >
                    <TimerIcon />
                    <Typography textTransform={'uppercase'} ml={1}>
                      {formatDate(task.dueDate, DateFormat.DateAndTime)}
                    </Typography>
                  </Box>
                </Tooltip>
              )}
              <Button
                size="medium"
                type="button"
                variant="outlined"
                onClick={() => {
                  handleRedirectToTask(task);
                }}
              >
                {isTaskOverdue(task)
                  ? t(`${TASKS_TRANSLATE_KEY}.taskOverdue`)
                  : t(`${TASKS_TRANSLATE_KEY}.taskComplete`)}
              </Button>
            </Box>
          </CardAreaWrapper>
        </Card>
      ))}
    </>
  );
};

export default TasksList;
