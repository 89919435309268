import AccountInfoSection from '../components/profile/AccountInfoSection';
import SecuritySection from '../components/profile/SecuritySection';
import useAuth from 'src/features/auth/hooks/useAuth';
import { UserableType } from 'src/models/user';
import SchoolEmployeePersonalInfoSection from '../components/profile/SchoolEmployee/SchoolEmployeePersonalInfoSection';
import CertifierPersonalInfoSection from '../components/profile/CertifierEmployee/CertifierEmployeePersonalInfoSection';
import CompanyEmployeePersonalInfoSection from '../components/profile/CompanyEmployee/CompanyEmployeePersonalInfoSection';
import { Permissions, ProfileUpdateUrls } from 'src/utils/const';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import PageTitle from '../../../components/PageTitle';
import PageSubtitle from '../../../components/PageSubtitle';
import RolesSection from '../components/profile/RolesSection';
import useCan from '../../../hooks/useCan';
import { SchoolRoutes } from '../../schools/routes';
import { CertifierEmployeeRoutes } from '../../certifier-employees/routes';
import { CompanyRoutes } from '../../companies/routes';
import TabList from '@mui/lab/TabList';
import TabContext from '@mui/lab/TabContext';
import { Box, Tab } from '@mui/material';
import React from 'react';
import TabPanel from '@mui/lab/TabPanel';
import { TAB_PANEL_CSS } from 'src/features/cohorts/pages/AssignedCohort';
import useTab from '../../../hooks/useTab';
import StudentProfile from './StudentProfile';

export enum Tabs {
  UserInfo = 'UserInfo',
  ProfessionalInfo = 'ProfessionalInfo',
  Roles = 'Roles',
  JobDescription = 'JobDescription',
  Security = 'Security',
  Documents = 'Documents',
  Evaluations = 'Evaluations'
}

const PersonalInfoSection: React.FC = () => {
  const { user } = useAuth();

  const canManageSchoolStaff = useCan(Permissions.ManageSchoolStaff);
  const canManageCertifierStaff = useCan(Permissions.ManageCertifierStaff);
  const canManageCompanyStaff = useCan(Permissions.ManageCompanyStaff);

  switch (user.userableType) {
    case UserableType.SchoolEmployee:
      return (
        <div>
          <TabPanel value={Tabs.UserInfo} style={TAB_PANEL_CSS}>
            <AccountInfoSection
              user={user}
              url={ProfileUpdateUrls.SchoolEmployee}
              isProfile={true}
              canManage={true}
            />
            <SchoolEmployeePersonalInfoSection
              user={user}
              url={ProfileUpdateUrls.SchoolEmployee}
              storeCvUrl={ProfileUpdateUrls.StoreCvSchoolEmployee}
              isProfile={true}
              canManage={true}
            />
          </TabPanel>
          <TabPanel value={Tabs.Roles} style={TAB_PANEL_CSS}>
            <RolesSection
              user={user}
              url={SchoolRoutes.SchoolEmployee.replace(
                ':schoolId',
                user.userable.schoolId
              ).replace(':employeeId', user.userableId)}
              canManage={canManageSchoolStaff}
              isProfile={true}
            />
          </TabPanel>
        </div>
      );
    case UserableType.CertifierEmployee:
      return (
        <div>
          <TabPanel value={Tabs.UserInfo} style={TAB_PANEL_CSS}>
            <AccountInfoSection
              user={user}
              url={ProfileUpdateUrls.CertifierEmployee}
              isProfile={true}
              canManage={true}
            />
            <CertifierPersonalInfoSection
              user={user}
              url={ProfileUpdateUrls.CertifierEmployee}
              storeCvUrl={ProfileUpdateUrls.StoreCvCertifierEmployee}
              isProfile={true}
            />
          </TabPanel>
          <TabPanel value={Tabs.Roles} style={TAB_PANEL_CSS}>
            <RolesSection
              user={user}
              url={CertifierEmployeeRoutes.CertifierEmployee.replace(
                ':certifierId',
                user.userableId
              )}
              canManage={canManageCertifierStaff}
              isProfile={true}
            />
          </TabPanel>
        </div>
      );
    case UserableType.CompanyEmployee:
      return (
        <div>
          <TabPanel value={Tabs.UserInfo} style={TAB_PANEL_CSS}>
            <AccountInfoSection
              user={user}
              url={ProfileUpdateUrls.CompanyEmployee}
              isProfile={true}
              canManage={true}
            />
            <CompanyEmployeePersonalInfoSection
              user={user}
              url={ProfileUpdateUrls.CompanyEmployee}
              storeCvUrl={ProfileUpdateUrls.StoreCvCompanyEmployee}
              isProfile={true}
              canManage={true}
            />
          </TabPanel>
          <TabPanel value={Tabs.Roles} style={TAB_PANEL_CSS}>
            <RolesSection
              user={user}
              url={CompanyRoutes.CompanyEmployee.replace(
                ':companyId',
                user.userable.companyId
              ).replace(':employeeId', user.userableId)}
              canManage={canManageCompanyStaff}
              isProfile={true}
            />
          </TabPanel>
        </div>
      );
    default:
      return null;
  }
};
const Profile = () => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const { tab, setTab } = useTab(Tabs.UserInfo);

  const handleTabChange = (_: React.SyntheticEvent, newValue: Tabs) => {
    setTab(newValue);
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>{t('profile.htmlTitle')}</title>
      </Helmet>
      <Box sx={{ mb: 4 }}>
        <PageTitle>{t(`profile.title`)}</PageTitle>
        <PageSubtitle>{t(`profile.subtitle`)}</PageSubtitle>
      </Box>
      {user.userableType === UserableType.Student && <StudentProfile />}
      {user.userableType !== UserableType.Student && (
        <Box>
          <TabContext value={tab}>
            <Box>
              <TabList onChange={handleTabChange} sx={{ mr: 1, mb: 1 }}>
                <Tab
                  label={t(`profile.accountInfo.title`)}
                  value={Tabs.UserInfo}
                />
                <Tab
                  label={t(`profile.roles.rolesSection.title`)}
                  value={Tabs.Roles}
                />
                <Tab
                  label={t(`profile.security.sectionTitle`)}
                  value={Tabs.Security}
                />
              </TabList>
            </Box>
            <PersonalInfoSection />
            <TabPanel value={Tabs.Security} style={TAB_PANEL_CSS}>
              <SecuritySection />
            </TabPanel>
          </TabContext>
        </Box>
      )}
    </React.Fragment>
  );
};

export default Profile;
