import { IUser } from '../../../../models/user';
import { StudentFlow } from '../../../../utils/const';
import {
  Alert,
  Button,
  AlertTitle,
  Box,
  styled
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import RejectMessageInfo from 'src/features/cohorts/components/common/RejectMessageInfo';

const AlertContent = styled(Box)(
  () => `white-space: nowrap;`
);

interface IProps {
  student: IUser;
  canChangeStatus: boolean;
  setStudentFlow(flow: StudentFlow): void;
}

const StudentFlowAlert: React.FC<IProps> = ({
  student,
  canChangeStatus,
  setStudentFlow,
}) => {

  /** @todo */
  const showRedemptionRevertButton = false;

  const { t } = useTranslation()

  if (student.userable.flow === StudentFlow.Regular) {
    return (
      <Alert
        severity="success"
        sx={{
          border: '1px solid#ff5e7c;',
          mb: 2
        }}
        action={
          canChangeStatus && (
            <AlertContent>
              <Button
                color="error"
                variant="contained"
                sx={{ m: 1 }}
                onClick={() => {
                  setStudentFlow(StudentFlow.OnHold)
                }}
              >
                {t("profile.student.flow.onHold")}
              </Button>
              {showRedemptionRevertButton && <Button
                color="primary"
                variant="contained"
                sx={{ m: 1 }}
                onClick={() => {
                  setStudentFlow(StudentFlow.Redemption)
                }}
              >
                {t("profile.student.flow.redemption")}
              </Button>}
            </AlertContent>
          )
        }
      >
        <AlertTitle>
          <strong>
            {t("profile.student.flow.regular")}
          </strong>
        </AlertTitle>
        {t("profile.student.flow.regularSubtitle")}
        <RejectMessageInfo statusLogs={student.userable.statusLogs.filter((log) => log.statusLabel === 'flow')} />
      </Alert>
    );
  }

  if (student.userable.flow === StudentFlow.Redemption) {
    return (
      <Alert
        severity="info"
        sx={{
          border: '1px solid#ff5e7c;',
          mb: 2
        }}
        action={
          canChangeStatus && (
            <AlertContent>
              <Button
                color="error"
                variant="contained"
                sx={{ m: 1 }}
                onClick={() => {
                  setStudentFlow(StudentFlow.OnHold)
                }}
              >
                {t("profile.student.flow.onHold")}
              </Button>
              {showRedemptionRevertButton && <Button
                color="primary"
                variant="contained"
                sx={{ m: 1 }}
                onClick={() => {
                  setStudentFlow(StudentFlow.Regular)
                }}
              >
                {t("profile.student.flow.regular")}
              </Button>}
            </AlertContent>
          )
        }
      >
        <AlertTitle>
          <strong>
            {t("profile.student.flow.inRedemption")}
          </strong>
        </AlertTitle>
        {t("profile.student.flow.inRedemptionSubtitle")}
        <RejectMessageInfo statusLogs={student.userable.statusLogs.filter((log) => log.statusLabel === 'flow')} />
      </Alert>
    );
  }

  if (student.userable.flow === StudentFlow.OnHold) {
    return (
      <Alert
        severity="error"
        sx={{
          border: '1px solid#ff5e7c;',
          mb: 2
        }}
        action={
          canChangeStatus && (
            <AlertContent>
              <Button
                color="primary"
                variant="contained"
                sx={{ m: 1 }}
                onClick={() => {
                  if (!!student.userable?.redemptionStartedAt) {
                    setStudentFlow(StudentFlow.Redemption)
                  } else {
                    setStudentFlow(StudentFlow.Regular)
                  }
                }}
              >
                {t("profile.student.flow.removeOnHold")}
              </Button>
            </AlertContent>
          )
        }
      >
        <AlertTitle>
          <strong>
            {t("profile.student.flow.onHold")}
          </strong>
        </AlertTitle>
        {t("profile.student.flow.onHoldSubtitle")}
        <RejectMessageInfo statusLogs={student.userable.statusLogs.filter((log) => log.statusLabel === 'flow')} />
      </Alert>
    );
  }

  return null;
};

export default StudentFlowAlert;
