import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  Typography
} from '@mui/material';
import { Formik, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { ICreateEditJobRequiredKnowledge } from 'src/features/profile/utils/types';
import * as Yup from 'yup';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { EditorWrapper } from 'src/features/cohorts/components/common/StyledComponents';
import { IJobRequiredKnowledge } from 'src/models/job-required-knowledge';
import { handleChangeTextEditor } from 'src/utils/isEditorEmpty';

interface IProps {
  open: boolean;
  jobRequiredKnowledge: IJobRequiredKnowledge;
  isSubmitting?: boolean;
  onSubmit(value: ICreateEditJobRequiredKnowledge): void;
  onClose(): void;
}

const JOB_REQUIRED_KNOWLEDGE_BASE_KEY = 'profile.common.jobRequiredKnowledge';
const VALIDATION_TRANSLATE_KEY = 'common.validation';

const CreateEditJobRequiredKnowledgeModal: React.FC<IProps> = ({
  open,
  jobRequiredKnowledge,
  isSubmitting,
  onClose,
  onSubmit
}) => {
  const { t } = useTranslation();

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle
        sx={{
          p: 3
        }}
      >
        <Typography variant="h4" gutterBottom>
          {t(`${JOB_REQUIRED_KNOWLEDGE_BASE_KEY}.modalTitle`)}
        </Typography>
        <Typography variant="subtitle2">
          {t(`${JOB_REQUIRED_KNOWLEDGE_BASE_KEY}.modalSubtitle`)}
        </Typography>
      </DialogTitle>
      <Formik<ICreateEditJobRequiredKnowledge>
        initialValues={
          jobRequiredKnowledge || {
            knowledge: ''
          }
        }
        onSubmit={(values) => {
          onSubmit(values);
        }}
        validationSchema={Yup.object().shape({
          knowledge: Yup.string().required(
            t(`${VALIDATION_TRANSLATE_KEY}.required`, {
              field: t(`${VALIDATION_TRANSLATE_KEY}.knowledgeField`)
            })
          )
        })}
      >
        {({
          handleSubmit: handleJobRequiredKnowledgeSubmit,
          touched: touchedJobKnowledge,
          errors: errorsJobKnowledge
        }) => (
          <form onSubmit={handleJobRequiredKnowledgeSubmit}>
            <DialogContent
              dividers
              sx={{
                p: 3
              }}
            >
              <Grid item xs={12}>
                <EditorWrapper
                  error={
                    !!(
                      touchedJobKnowledge.knowledge &&
                      errorsJobKnowledge.knowledge
                    )
                  }
                >
                  <Field name="knowledge">
                    {({ field }) => (
                      <ReactQuill
                        value={field.value}
                        onChange={(...qullChangeData) => {
                          field.onChange(field.name)(
                            handleChangeTextEditor(...qullChangeData)
                          );
                        }}
                        placeholder={t(
                          `${JOB_REQUIRED_KNOWLEDGE_BASE_KEY}.form.knowledge.placeholder`
                        )}
                      />
                    )}
                  </Field>
                  <FormHelperText error>
                    {touchedJobKnowledge.knowledge &&
                      errorsJobKnowledge.knowledge}
                  </FormHelperText>
                </EditorWrapper>
              </Grid>
              <DialogActions sx={{ padding: '8px 0', mt: 1.5 }}>
                <Button
                  color="secondary"
                  size="medium"
                  variant="outlined"
                  onClick={onClose}
                >
                  {t('common.buttons.cancel')}
                </Button>
                <LoadingButton
                  variant="contained"
                  size="medium"
                  type="submit"
                  loading={isSubmitting}
                >
                  {t('common.buttons.save')}
                </LoadingButton>
              </DialogActions>
            </DialogContent>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default CreateEditJobRequiredKnowledgeModal;
