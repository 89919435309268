import { Box, Card, styled } from '@mui/material';

export const EditorWrapper = styled(Box)<{ error?: boolean }>(
  ({ theme, error }) => `
    .ql-editor {
      min-height: 100px;
    }
    .ql-editor p {
      font-size: 14px;
      line-height: 20px;
    }

    .ql-editor.ql-blank::before {
      font-size: 14px;
      font-style: normal;
      line-height: 20px;
    }

    .ql-snow .ql-picker {
      color: ${theme.colors.alpha.black[100]};
    }

    .ql-snow .ql-stroke {
      stroke: ${theme.colors.alpha.black[100]};
    }

    .ql-toolbar.ql-snow {
      border-top-left-radius: ${theme.general.borderRadius};
      border-top-right-radius: ${theme.general.borderRadius};
    }

    .ql-toolbar.ql-snow,
    .ql-container.ql-snow {
      border-color: ${
        error ? theme.colors.error.main : theme.colors.alpha.black[30]
      };
    }

    .ql-container.ql-snow {
      border-bottom-left-radius: ${theme.general.borderRadius};
      border-bottom-right-radius: ${theme.general.borderRadius};
    }

    &:hover {
      .ql-toolbar.ql-snow,
      .ql-container.ql-snow {
        border-color: ${
          error ? theme.colors.error.main : theme.colors.alpha.black[50]
        };
      }
    }
`
);

export const ContentBox = styled(Box)(
  ({ theme }) => `
  margin-top: ${theme.spacing(3)};
`
);

export const ContentCard = styled(Card)(
  ({ theme }) => `
  padding: ${theme.spacing(3)};
`
);

export const StyledReactQuillWrapper = styled('div')`
  .ql-editor {
    padding: 0;
  }
  .ql-editor p {
    font-size: 14px;
    line-height: 20px;
  }
  .ql-container.ql-disabled .ql-tooltip,
  .ql-clipboard {
    display: none;
  }
`;
