import { useContext } from 'react';
import Scrollbar from 'src/components/Scrollbar';
import { SidebarContext } from 'src/contexts/SidebarContext';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import {
  Box,
  Drawer,
  IconButton,
  styled,
  useTheme
} from '@mui/material';

import SidebarMenu from './SidebarMenu';

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
  padding-top: ${theme.spacing(2)};
  
  color: ${theme.sidebar.textColor};
  box-shadow: ${theme.sidebar.boxShadow};
  background-color: ${theme.sidebar.background};
  
  z-index: 8;
`
);

export function SideMenu({ sx = {} }) {
  const { sideMenuToggle, toggleSideMenu } = useContext(SidebarContext);
  const theme = useTheme();

  return (
    <SidebarWrapper
      id='SideMenu'
      sx={{
        ...sx,
        position: 'relative',
        width: sideMenuToggle ? theme.sidebar.width : '0px'
      }} >
      <IconButton
        aria-label="menu-toggle"
        color="secondary"
        onClick={() => toggleSideMenu()}
        sx={{
          height: '40px',
          width: '15px',
          padding: 0,
          margin: 0,
          zIndex: 9,

          position: 'absolute',
          top: theme.spacing(3),
          right: '-15px',
          
          borderRight: 1,
          borderBottom: 1,
          borderTop: 1,
          borderColor: theme.sidebar.borderColor,
          borderRadius: 0
        }}>
        {sideMenuToggle ?
          <KeyboardArrowLeftIcon sx={{ fontSize: 20 }} /> :
          <KeyboardArrowRightIcon sx={{ fontSize: 20 }} />
        }
      </IconButton>
      {sideMenuToggle && (
        <Scrollbar>
          <SidebarMenu />
        </Scrollbar>
      )}
    </SidebarWrapper>
  );
}

export function SideMobileMenu({ sx = {} }) {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();

  return (
    <Drawer
      sx={{
        boxShadow: `${theme.sidebar.boxShadow}`
      }}
      anchor={theme.direction === 'rtl' ? 'right' : 'left'}
      open={sidebarToggle}
      onClose={closeSidebar}
      variant="temporary"
      elevation={9}
    >
      <SidebarWrapper
        id='MobileMenu'
        sx={{
          ...sx,
          width: theme.sidebar.width
        }} >
        <Scrollbar>
          <SidebarMenu />
        </Scrollbar>
      </SidebarWrapper>
    </Drawer>
  );
}

