const students = {
  table: {
    columns: {
      name: 'Étudiants',
      email: 'E-mail',
      status: 'Statut'
    }
  },
  assigned: {
    listAll: {
      htmlTitle: 'Étudiants affectés',
      title: 'Étudiants affectés',
      subtitle: 'Liste des étudiants affectés'
    }
  },
  cohortCertificate: {
    tabs: {
      blocks: 'Blocs',
      evaluations: 'Évaluations',
      finalExam: 'Examen final',
      certificationPassport:'Passeport de certification'
    },
    labels: {
      certificate: 'Certificat',
      code: 'Référence RNCP',
      level: 'Niveau RCNP',
      blocks: 'Nombre de blocs',
      description: 'Description'
    }
  },
  finalExam: {
    finalExamDocument: "Remise de document pour l'examen final",
    finalExamDocumentNotUploaded: 'Aucun document téléversé',
    finalExamPreparedDocument: 'Document pour examen final',
    finalExamNotPreparedYet: 'Document pour examen final non disponible'
  },
  school: {
    schoolNotFound: `École introuvable, veuillez contacter l'administrateur pour plus d'informations.`,
    labels: {
      shortName: 'Diminutif',
      address: 'Adresse',
      about: 'A propos'
    }
  },
  job: {
    title: 'Fiche de poste'
  },
  documents: {
    cv: 'CV',
    diploma: 'Diplôme',
    evaluationTest: 'Test de positionnement (Facultatif)',
    waiver: 'Dérogation/Dispense',
    passport: 'Passeport et CV à jour',
    redemptionPassport: 'CV et passeport de rattrapage à jour'
  },
  evaluations: {
    blockEvaluations: 'Évaluations de bloc',
    selfEvaluation: 'Auto-évaluation',
    tutorEvaluation: 'Évaluation tuteur',
    title: 'Évaluations',
    file: 'Fichier',
    type: 'Type',
    uploadedBy: 'Téléversé par',
    block: 'Bloc',
    status: 'Statut',
    actions: 'Actions',
    verifyActions: 'Valider',
    verify: {
      title: "Valider l'évaluation",
      body: "Confirme la validation de l'évaluation ?"
    },
    reject: {
      title: "Rejeter l'évaluation",
      body: "Confirmer le rejet de l'évaluation ?"
    },
    showMessages: 'Montrer les messages',
    messagesModalTitle: 'Motif de rejet',
    messagesModalSubtitle: 'Voir le motif de rejet',
    rejectionReason: 'Motif de rejet',
    notUploaded: 'Pas de téléversement',
    evaluationTypes: {
      block_evaluation: 'Évaluation de bloc',
      student_self_evaluation: 'Auto-évaluation',
      tutor_evaluation: 'Évaluation Tuteur',
      final_exam: 'Examen final'
    }
  },

  missingInfo: {
    title: 'Information manquante',
    text: `École ou cohorte introuvable, veuillez contacter l'administrateur pour plus d'informations.`
  },
  handle: {
    notAllowed: "L'accès à l'étudiant n'est pas autorisé!",
  },
};

export default students;
