import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText
} from '@mui/material';
import { Formik, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { ICreateEditJobMission } from 'src/features/profile/utils/types';
import { IJobMission } from 'src/models/job-mission';
import * as Yup from 'yup';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { EditorWrapper } from 'src/features/cohorts/components/common/StyledComponents';
import { JobMissionAutonomyLevels } from 'src/utils/const';
import { handleChangeTextEditor } from 'src/utils/isEditorEmpty';

interface IProps {
  open: boolean;
  jobMission: IJobMission;
  isSubmitting?: boolean;
  onSubmit(value: ICreateEditJobMission): void;
  onClose(): void;
}

const JOB_MISSIONS_BASE_KEY = 'profile.common.jobMissions';
const VALIDATION_TRANSLATE_KEY = 'common.validation';

const CreateEditJobMissionModal: React.FC<IProps> = ({
  open,
  jobMission,
  isSubmitting,
  onClose,
  onSubmit
}) => {
  const { t } = useTranslation();
  const autonomyLevels = Object.entries(JobMissionAutonomyLevels).map(
    ([key, value]) => ({
      key,
      value
    })
  );

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle
        sx={{
          p: 3
        }}
      >
        <Typography variant="h4" gutterBottom>
          {t(`${JOB_MISSIONS_BASE_KEY}.modalTitle`)}
        </Typography>
        <Typography variant="subtitle2">
          {t(`${JOB_MISSIONS_BASE_KEY}.modalSubtitle`)}
        </Typography>
      </DialogTitle>
      <Formik<ICreateEditJobMission>
        initialValues={
          jobMission || {
            name: '',
            activity: '',
            autonomyLevel: ''
          }
        }
        onSubmit={(values) => {
          onSubmit(values);
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required(
            t(`${VALIDATION_TRANSLATE_KEY}.required`, {
              field: t(`${VALIDATION_TRANSLATE_KEY}.nameField`)
            })
          ),
          activity: Yup.string().required(
            t(`${VALIDATION_TRANSLATE_KEY}.required`, {
              field: t(`${VALIDATION_TRANSLATE_KEY}.activityField`)
            })
          ),
          autonomyLevel: Yup.string().required(
            t(`${VALIDATION_TRANSLATE_KEY}.required`, {
              field: t(`${VALIDATION_TRANSLATE_KEY}.autonomyLevelField`)
            })
          )
        })}
      >
        {({
          handleSubmit: handleJobMissionSubmit,
          handleBlur: handleJobMissionBlur,
          handleChange: handleJobMissionChange,
          touched: touchedJobMission,
          errors: errorsJobMission,
          values: jobMissionValues
        }) => (
          <form onSubmit={handleJobMissionSubmit}>
            <DialogContent
              dividers
              sx={{
                p: 3
              }}
            >
              <Grid item xs={12}>
                <FormControl fullWidth margin="normal">
                  <TextField
                    error={Boolean(
                      touchedJobMission.name && errorsJobMission.name
                    )}
                    fullWidth
                    margin="normal"
                    helperText={touchedJobMission.name && errorsJobMission.name}
                    label={t(
                      `${JOB_MISSIONS_BASE_KEY}.form.jobMissionName.label`
                    )}
                    placeholder={t(
                      `${JOB_MISSIONS_BASE_KEY}.form.jobMissionName.placeholder`
                    )}
                    name="name"
                    onBlur={handleJobMissionBlur}
                    onChange={handleJobMissionChange}
                    value={jobMissionValues.name}
                    variant="outlined"
                    sx={{ mt: 0 }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <EditorWrapper
                  error={
                    !!(touchedJobMission.activity && errorsJobMission.activity)
                  }
                >
                  <Field name="activity">
                    {({ field }) => (
                      <ReactQuill
                        value={field.value}
                        onChange={(...qullChangeData) => {
                          field.onChange(field.name)(
                            handleChangeTextEditor(...qullChangeData)
                          );
                        }}
                        placeholder={t(
                          `${JOB_MISSIONS_BASE_KEY}.form.activity.placeholder`
                        )}
                      />
                    )}
                  </Field>
                  <FormHelperText error>
                    {touchedJobMission.activity && errorsJobMission.activity}
                  </FormHelperText>
                </EditorWrapper>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="mutiple-select-label">
                    {t(`${JOB_MISSIONS_BASE_KEY}.form.autonomyLevel.label`)}
                  </InputLabel>
                  <Select
                    error={Boolean(
                      touchedJobMission.autonomyLevel &&
                        errorsJobMission.autonomyLevel
                    )}
                    fullWidth
                    label={t(
                      `${JOB_MISSIONS_BASE_KEY}.form.autonomyLevel.label`
                    )}
                    placeholder={t(
                      `${JOB_MISSIONS_BASE_KEY}.form.autonomyLevel.placeholder`
                    )}
                    name="autonomyLevel"
                    onBlur={handleJobMissionBlur}
                    onChange={handleJobMissionChange}
                    value={jobMissionValues.autonomyLevel}
                    variant="outlined"
                  >
                    {autonomyLevels?.map((type, index) => (
                      <MenuItem key={index} value={type.value}>
                        {t(
                          `profile.common.jobMissions.autonomyLevels.${type.value}`
                        )}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error>
                    {touchedJobMission.autonomyLevel &&
                      errorsJobMission.autonomyLevel}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <DialogActions sx={{ padding: '8px 0' }}>
                <Button
                  color="secondary"
                  size="medium"
                  variant="outlined"
                  onClick={onClose}
                >
                  {t('common.buttons.cancel')}
                </Button>
                <LoadingButton
                  variant="contained"
                  size="medium"
                  type="submit"
                  loading={isSubmitting}
                >
                  {t('common.buttons.save')}
                </LoadingButton>
              </DialogActions>
            </DialogContent>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default CreateEditJobMissionModal;
