import { useMemo } from 'react';
import useAuth from '../features/auth/hooks/useAuth';

const useHaveRole = (role: string) => {
  const { user } = useAuth();

  const hasRole = useMemo(() => {
    return !!user.roles?.find((item) => item.name === role);
  }, [user, role]);

  return hasRole;
};

export default useHaveRole;
