import { Tabs } from '../pages/Tasks';
import { TaskCodes } from '../utils/types';

const tasks = {
  common: {
    pageHeader: {
      title: 'Create new manual task',
      subtitle: 'Fill in fields below to create a new manual task'
    },
    api: {
      success: 'Create manual task successfully!'
    },
    taskCreateManualForm: {
      certificate: {
        label: 'Certificate',
        placeholder: 'Select certificate'
      },
      cohort: {
        label: 'Cohort',
        placeholder: 'Select cohort'
      },
      task: {
        title: 'Task info',
        predefined: {
          label: 'Tasks predefined',
          placeholder: 'Select predefined tasks'
        },
        name: {
          label: 'Task name',
          placeholder: 'Write task name...'
        },
        description: {
          label: 'Task description',
          placeholder: 'Write task description...'
        }
      },
      notifications: {
        title: 'Notifications info',
        name: {
          label: 'Notification name',
          placeholder: 'Write notification name...'
        },
        text: {
          label: 'Notification text',
          placeholder: 'Write notification text here...'
        },
        sendOnEmail: {
          label: 'Send on e-mail',
          placeholder: 'Send on e-mail'
        },
        sendOnPlatform: {
          label: 'Send on platform',
          placeholder: 'Send on platform'
        }
      },
      roleUsers: {
        title: 'Users info',
        role: {
          label: 'Roles',
          placeholder: ''
        },
        search: {
          placeholder: 'Search user by name...'
        },
        table: {
          col1: 'Name'
        }
      }
    },
    taskPredefined: {
      [TaskCodes.InviteStudents]: 'Invite students',
      [TaskCodes.UploadStudentDocuments]: 'Upload student documents',
      [TaskCodes.VerifyStudentDocuments]: 'Verify student documents',
      [TaskCodes.AssignTutorToStudent]: 'Assign tutor to student',
      [TaskCodes.AddJobDescription]: 'Add job description',
      [TaskCodes.VerifyJobDescription]: 'Verify job description',
      [TaskCodes.UploadCourseDocuments]: 'Upload course documents',
      [TaskCodes.VerifyCourseDocuments]: 'Verify course documents',
      [TaskCodes.AssignCorrectors]: 'Assign corrector to student blocks',
      [TaskCodes.AssignJuryPresident]: 'Assign jury president',
      [TaskCodes.AssignJuryMember]: 'Assign jury member',
      [TaskCodes.UploadBlockDeliverables]: 'Upload block deliverables'
    }
  },
  listAll: {
    title: 'Tasks',
    subtitle: 'List all tasks assigned to you',
    taskLatest: 'Latest Tasks',
    taskDetails: 'Task details',
    taskComplete: 'Complete the task',
    taskOverdue: 'See details',
    createManualTask: 'Create task manually',
    daysLeft: 'days left',
    tab: {
      [Tabs.CreatedByMe]: 'Created by me',
      [Tabs.Mine]: 'Mine'
    },
    table: {
      columns: {
        name: 'Task Name',
        school: 'School',
        cohort: 'Cohort',
        status: 'Status',
        assignedBy: 'Assigned By',
        assignedTo: 'Assigned To',
        doneBy: 'Done by',
        createdAt: 'Created At',
        dueDate: 'Due Date',
        description: 'Description'
      },
      actionTooltips: {
        view: 'Go to task',
        show: 'Show details',
        update: 'Update task status'
      },
      status: {
        overdue: 'Overdue',
        toDo: 'To do',
        inProgress: 'In progress',
        done: 'Done',
        terminatedBySystem: 'Terminated by system'
      }
    }
  },
  view: {
    title: 'Task',
    subtitle: 'Task details',
    backToList: 'Go back to all tasks'
  },
  deadline: {
    deadlineExpiredTitle: 'Deadline expired',
    deadlineExpiredText: 'Your deadline for this task has expired.',
    alertText: 'Deadline for task "{{taskName}}" has expired at {{dueDate}}.'
  },
  noStudentsFound: 'No students found!'
};

export default tasks;
