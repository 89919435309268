import { ILocalDocuments } from './types';

export const checkIfAllFoldersHaveDocuments = (documents: ILocalDocuments) => {
  let allHaveDocs = true;

  Object.values(documents).forEach((docs) => {
    if (docs.length === 0) {
      allHaveDocs = false;
    } else {
      const uploadedDocs = docs.filter((doc) => doc.id);
      if (uploadedDocs.length === 0) {
        allHaveDocs = false;
      }
    }
  });

  return allHaveDocs;
};
