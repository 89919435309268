import { lazy } from 'react';
import SuspenseRouteLoader from '../../components/SuspenseRouteLoader';

const Schools = SuspenseRouteLoader(lazy(() => import('./pages/Schools')));
const CreateSchool = SuspenseRouteLoader(
  lazy(() => import('./pages/CreateSchool'))
);
const School = SuspenseRouteLoader(lazy(() => import('./pages/School')));
const SchoolEmployee = SuspenseRouteLoader(
  lazy(() => import('../profile/pages/SchoolEmployee'))
);
export const SchoolRoutes = {
  ListAll: '/schools',
  Create: '/schools/create',
  View: '/schools/:id/view',
  SchoolEmployee: '/schools/:schoolId/employees/:employeeId'
};

const routes = [
  {
    path: SchoolRoutes.ListAll,
    element: <Schools />
  },
  {
    path: SchoolRoutes.Create,
    element: <CreateSchool />
  },
  {
    path: SchoolRoutes.View,
    element: <School />
  },
  {
    path: SchoolRoutes.SchoolEmployee,
    element: <SchoolEmployee />
  }
];

export default routes;
