import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { IJob } from 'src/models/job';
import Confirm from 'src/components/Confirm';
import JobRequiredKnowHowList from './JobRequiredKnowHowList';
import { IJobRequiredKnowHow } from 'src/models/job-required-know-how';
import useAddJobRequiredKnowHow from '../../../../hooks/api/job/useAddJobRequiredKnowHow';
import useUpdateJobRequiredKnowHow from '../../../../hooks/api/job/useUpdateJobRequiredKnowHow';
import useDeleteJobRequiredKnowHow from '../../../../hooks/api/job/useDeleteJobRequiredKnowHow';
import { IUser } from 'src/models/user';
import { studentIsOnHold } from 'src/utils/userHelpers';

interface IProps {
  job: IJob;
  canManage: boolean;
  student: IUser;
}

const JOB_REQUIRED_KNOW_HOW_BASE_KEY =
  'profile.common.studentJob.delete.jobRequiredKnowHow';

const JobRequiredKnowHow: React.FC<IProps> = ({ job, canManage, student }) => {
  const { t } = useTranslation();

  const { addJobRequiredKnowHow, isAddingJobRequiredKnowHow } =
    useAddJobRequiredKnowHow();

  const { updateJobRequiredKnowHow, isUpdatingJobRequiredKnowHow } =
    useUpdateJobRequiredKnowHow();

  const {
    deleteJobRequiredKnowHow,
    jobRequiredKnowHowToBeDeleted,
    setJobRequiredKnowHowToBeDeleted,
    isDeletingJobRequiredKnowHow
  } = useDeleteJobRequiredKnowHow();

  const handleRemoveJobRequiredKnowHow = (
    _: number,
    jobRequiredKnowHow: IJobRequiredKnowHow
  ) => {
    setJobRequiredKnowHowToBeDeleted(jobRequiredKnowHow);
  };

  const handleEditJobRequiredKnowHow = (
    _: number,
    newjobRequiredKnowHow: IJobRequiredKnowHow
  ) => {
    updateJobRequiredKnowHow(newjobRequiredKnowHow);
  };

  const handleAddJobRequiredKnowHow = (
    jobRequiredKnowHow: IJobRequiredKnowHow
  ) => {
    addJobRequiredKnowHow({ ...jobRequiredKnowHow, jobId: job.id });
  };

  return (
    <React.Fragment>
      <JobRequiredKnowHowList
        jobRequiredKnowHow={job?.jobRequiredKnowHow}
        onAddJobRequiredKnowHow={handleAddJobRequiredKnowHow}
        onEditJobRequiredKnowHow={handleEditJobRequiredKnowHow}
        onRemoveJobRequiredKnowHow={handleRemoveJobRequiredKnowHow}
        isAddingJobRequiredKnowHow={isAddingJobRequiredKnowHow}
        isEditingJobRequiredKnowHow={isUpdatingJobRequiredKnowHow}
        jobStatus={job?.status}
        canManage={canManage}
        readOnly={studentIsOnHold(student)}
      />
      <Confirm
        title={t(`${JOB_REQUIRED_KNOW_HOW_BASE_KEY}.modalTitle`)}
        onClose={() => setJobRequiredKnowHowToBeDeleted(null)}
        onConfirm={() =>
          deleteJobRequiredKnowHow(jobRequiredKnowHowToBeDeleted)
        }
        open={!!jobRequiredKnowHowToBeDeleted}
        isConfirming={isDeletingJobRequiredKnowHow}
      >
        {jobRequiredKnowHowToBeDeleted && (
          <Trans i18nKey={`${JOB_REQUIRED_KNOW_HOW_BASE_KEY}.modalBody`}>
            Are you sure you want to delete
            <strong>{' required know how '}</strong>
            from address list?
          </Trans>
        )}
      </Confirm>
    </React.Fragment>
  );
};

export default JobRequiredKnowHow;
