import { useQuery, useQueryClient, useIsFetching } from 'react-query';

import { ICertificate } from '../../../../models/certificate';
import { ICohort } from '../../../../models/cohort';
import { ISchool } from '../../../../models/school';
import { IUser } from '../../../../models/user';
import { IApiError } from '../../../../utils/axios';
import { fetchStudentInfo, FETCH_STUDENT_INFO_KEY } from '../../utils/api';

export interface IStudentInfoResponse extends IUser {
  cohort: ICohort;
  certificate: ICertificate;
  school: ISchool;
}

const useFetchStudentInfo = (options?: any) => {
  const {
    isLoading: isFetchingStudentInfo,
    error: errorFetchingStudentInfo,
    data: studentInfo
  } = useQuery<IStudentInfoResponse, IApiError>(
    [FETCH_STUDENT_INFO_KEY],
    () => fetchStudentInfo(),
    options
  );

  return {
    isFetchingStudentInfo,
    errorFetchingStudentInfo,
    studentInfo
  };
};

export const useStudentInfo = () => {
  const queryClient = useQueryClient();
  const isFetchingStudentInfo = useIsFetching(FETCH_STUDENT_INFO_KEY);
  return {
    studentInfo: queryClient.getQueryData(
      FETCH_STUDENT_INFO_KEY
    ) as IStudentInfoResponse | null,
    isFetchingStudentInfo
  };
};

export default useFetchStudentInfo;
