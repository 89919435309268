import { useQuery } from 'react-query';
import { IApiError } from '../../../../utils/axios';
import {
  fetchSchoolCourseDocuments,
  FETCH_SCHOOL_COURSE_DOCUMENTS_KEY
} from '../../utils/api';
import { IExtendedCourseDocument } from '../../utils/types';

const useFetchCourseDocuments = (courseId: string) => {
  const {
    isLoading: isFetchingDocuments,
    error: errorFetchingDocuments,
    data: documents
  } = useQuery<IExtendedCourseDocument[], IApiError>(
    [FETCH_SCHOOL_COURSE_DOCUMENTS_KEY, courseId],
    () => fetchSchoolCourseDocuments(courseId)
  );

  return {
    isFetchingDocuments,
    errorFetchingDocuments,
    documents
  };
};

export default useFetchCourseDocuments;
