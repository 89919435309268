import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import PageLoader from 'src/components/PageLoader';
import useFetchAllSchools from 'src/features/schools/hooks/api/useFetchAllSchools';
import { ICohort } from 'src/models/cohort';
import { Mode } from 'src/utils/types';
import Confirm from '../../../../components/Confirm';
import { ISchool } from '../../../../models/school';
import useDeleteCohortSchool from '../../hooks/api/useDeleteCohortSchool';
import useUpdateCohortSchools from '../../hooks/api/useUpdateCohortSchools';
import SchoolList from '../common/SchoolList';

interface IProps {
  cohort: ICohort;
  canManageCohorts: boolean;
}

const COHORT_SCHOOL_BASE_KEY = 'cohorts.common.schools.delete';

const CohortSchools: React.FC<IProps> = ({ cohort, canManageCohorts }) => {
  const { t } = useTranslation();

  const { schools: allSchools, isFetchingSchools } = useFetchAllSchools();

  const {
    deleteCohortSchool,
    schoolToBeDeleted,
    setSchoolToBeDeleted,
    isDeletingCohortSchool
  } = useDeleteCohortSchool(cohort);

  const { updateCohortSchools } = useUpdateCohortSchools(cohort);

  const handleRemoveSchool = (_: number, school: ISchool) => {
    setSchoolToBeDeleted(school);
  };

  const handleSaveSchools = (schools: ISchool[]) => {
    updateCohortSchools({ cohort, schools });
  };

  if (isFetchingSchools) {
    return <PageLoader />;
  }

  return (
    <React.Fragment>
      <SchoolList
        page={Mode.View}
        schools={cohort.schools}
        canManageSchools={canManageCohorts}
        onDeleteSchool={handleRemoveSchool}
        allSchools={allSchools}
        onSaveSchool={handleSaveSchools}
      />
      <Confirm
        title={t(`${COHORT_SCHOOL_BASE_KEY}.modalTitle`)}
        onClose={() => setSchoolToBeDeleted(null)}
        onConfirm={() =>
          deleteCohortSchool({
            cohortId: cohort.id,
            schoolId: schoolToBeDeleted.id
          })
        }
        open={!!schoolToBeDeleted}
        isConfirming={isDeletingCohortSchool}
      >
        {schoolToBeDeleted && (
          <Trans i18nKey={`${COHORT_SCHOOL_BASE_KEY}.modalBody`}>
            Are you sure you want to delete{' '}
            <strong>{{ name: schoolToBeDeleted.longName }} </strong>
            from school list?
          </Trans>
        )}
      </Confirm>
    </React.Fragment>
  );
};

export default CohortSchools;
