import {
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from '@mui/material';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { IAudit } from 'src/models/auditor';
import { StudentCertificationStatus } from 'src/utils/const';

enum StudentCertificationStatusMissing {
    Missing = 'missing'
}

const GlobalAuditorRecommendationFormFields: React.FC = () => {

    const { t } = useTranslation();
    const {
        touched,
        errors,
        handleBlur,
        handleChange,
        values,
    } = useFormikContext<IAudit>();

    return (
        <div>
            <Grid container spacing={2} sx={{ mt: 3 }}>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel id="auditorGlobalRecommendationLabel">{t(`studentBlock.auditor.auditorRecommendationOptionsLabel`)}</InputLabel>
                        <Select
                            sx={{ mb: 2 }}
                            labelId="auditorGlobalRecommendationLabel"
                            id="auditorGlobalRecommendation"
                            value={values.auditorRecommendation}
                            error={Boolean(touched.auditorRecommendation && errors.auditorRecommendation)}
                            fullWidth
                            label={t(`studentBlock.auditor.auditorRecommendationOptionsLabel`)}
                            placeholder={t(`studentBlock.auditor.auditorRecommendationExplanationLabel`)}
                            onChange={handleChange}
                            autoWidth
                            onBlur={handleBlur}
                            variant="outlined"
                            name="auditorRecommendation"
                        >
                            <MenuItem
                                value={StudentCertificationStatus.FullDiploma}>
                                {t(`studentBlock.auditor.auditorRecommendationOptions.${StudentCertificationStatus.FullDiploma}`)}
                            </MenuItem>
                            <MenuItem
                                value={StudentCertificationStatus.PartialDiploma}>
                                {t(`studentBlock.auditor.auditorRecommendationOptions.${StudentCertificationStatus.PartialDiploma}`)}
                            </MenuItem>
                            <MenuItem
                                value={StudentCertificationStatus.NoDiploma}>
                                {t(`studentBlock.auditor.auditorRecommendationOptions.${StudentCertificationStatus.NoDiploma}`)}
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        error={Boolean(touched.auditorComment && errors.auditorComment)}
                        fullWidth
                        margin="normal"
                        helperText={touched.auditorComment && errors.auditorComment}
                        label={t(`studentBlock.auditor.auditorRecommendationExplanationLabel`)}
                        placeholder={t(`studentBlock.auditor.auditorRecommendationExplanationLabel`)}
                        name="auditorComment"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.auditorComment}
                        variant="outlined"
                        sx={{ mt: 0 }}
                        multiline
                        minRows={4}
                        maxRows={4}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

export default GlobalAuditorRecommendationFormFields;