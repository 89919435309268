import { useQuery } from 'react-query';
import { IStudentBlock } from 'src/models/student-block';
import { IApiError } from '../../../utils/axios';
import { fetchStudentBlocks, FETCH_STUDENT_BLOCKS_KEY } from '../utils/api';

const useFetchStudentBlocks = (studentId: string) => {
  const {
    isLoading: isFetchingStudentBlocks,
    error: errorFetchingStudentBlocks,
    data: studentBlocks
  } = useQuery<IStudentBlock[], IApiError>(
    [FETCH_STUDENT_BLOCKS_KEY, studentId],
    () => fetchStudentBlocks(studentId),
    { enabled: !!studentId }
  );

  return {
    isFetchingStudentBlocks,
    errorFetchingStudentBlocks,
    studentBlocks
  };
};

export default useFetchStudentBlocks;
