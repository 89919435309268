import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Tooltip,
  Typography,
  MenuItem
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import TableContainer from '../../../../components/Table/TableContainer';
import TableHeaderSortLabel from '../../../../components/Table/TableHeaderSortLabel';
import { ISort } from '../../../../utils/types';
import React from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import useAuth from '../../../auth/hooks/useAuth';
import { buildRedirectUrl } from 'src/features/tasks/utils/taskBuilders';
import { CertificateRoutes } from 'src/features/certificates/routes';
import { IEvaluationConfiguration } from '../../../../models/evaluation-configuration';
import { getRolesNamePrint } from '../../utils/getSelectedItemsName';
import { printAssignToRoleIdentifiers } from '../../utils/evaluationConfigurationsHelpers';
import TableActions from 'src/components/Table/TableActions';

interface IProps {
  page: number;
  isFetching: boolean;
  total: number;
  evaluations: IEvaluationConfiguration[];
  sort?: ISort;
  perPage: number;
  canManageEvaluation?: boolean;
  certificateId?: string;
  onChangePage(page: number): void;
  onChangeSort(sort: any): void;
  onDeleteEvaluation?(evaluation: IEvaluationConfiguration): void;
}

const EVALUATIONS_TRANSLATE_KEY = 'evaluations.listAll';

export enum Columns {
  Name = 'name',
  LinkedTo = 'linked_to',
  AssignedTo = 'assigned_to',
  VerifyBy = 'verify_by',
  VisibleTo = 'visibleTo',
  CreatedAt = 'created_at',
  CompetenceRequirement = 'competence_requirement',
  IsRedemption = 'type'
}

const EvaluationConfigurationsTable: React.FC<IProps> = ({
  page,
  isFetching,
  total,
  evaluations,
  sort,
  perPage,
  canManageEvaluation,
  certificateId,
  onChangePage,
  onChangeSort,
  onDeleteEvaluation
}) => {
  const { t } = useTranslation();

  const { user } = useAuth();

  const navigate = useNavigate();

  return (
    <TableContainer
      currentPage={page}
      isFetching={isFetching}
      onChangePage={onChangePage}
      total={total}
      perPage={perPage}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell key="name" align="left" width={350}>
              <TableHeaderSortLabel
                columnName={Columns.Name}
                sort={sort}
                onChangeSort={onChangeSort}
              >
                {t(`${EVALUATIONS_TRANSLATE_KEY}.table.columns.name`)}
              </TableHeaderSortLabel>
            </TableCell>
            <TableCell key="LinkedTo" align="left">
              <TableHeaderSortLabel
                columnName={Columns.LinkedTo}
                sort={sort}
                onChangeSort={onChangeSort}
              >
                {t(`${EVALUATIONS_TRANSLATE_KEY}.table.columns.linkedTo`)}
              </TableHeaderSortLabel>
            </TableCell>
            <TableCell key="creationUserId" align="left">
              <TableHeaderSortLabel
                columnName={Columns.AssignedTo}
                sort={sort}
                onChangeSort={onChangeSort}
              >
                {t(`${EVALUATIONS_TRANSLATE_KEY}.table.columns.assignTo`)}
              </TableHeaderSortLabel>
            </TableCell>
            <TableCell key="dueDate" align="left">
              <TableHeaderSortLabel
                columnName={Columns.VerifyBy}
                sort={sort}
                onChangeSort={onChangeSort}
              >
                {t(`${EVALUATIONS_TRANSLATE_KEY}.table.columns.verifyBy`)}
              </TableHeaderSortLabel>
            </TableCell>
            <TableCell key="status" align="left">
              <TableHeaderSortLabel
                columnName={Columns.VisibleTo}
                sort={sort}
                onChangeSort={onChangeSort}
              >
                {t(`${EVALUATIONS_TRANSLATE_KEY}.table.columns.visibleTo`)}
              </TableHeaderSortLabel>
            </TableCell>
            <TableCell key="competenceRequirement" align="left">
              <TableHeaderSortLabel
                columnName={Columns.CompetenceRequirement}
                sort={sort}
                onChangeSort={onChangeSort}
              >
                {t(`${EVALUATIONS_TRANSLATE_KEY}.table.columns.competenceRequirement`)}
              </TableHeaderSortLabel>
            </TableCell>
            <TableCell key="isRedemption" align="left">
              <TableHeaderSortLabel
                columnName={Columns.IsRedemption}
                sort={sort}
                onChangeSort={onChangeSort}
              >
                {t(`${EVALUATIONS_TRANSLATE_KEY}.table.columns.isRedemption`)}
              </TableHeaderSortLabel>
            </TableCell>
            <TableCell key="actions" align="right" />
          </TableRow>
        </TableHead>
        <TableBody>
          {evaluations?.map((evaluation) => (
            <TableRow
              hover={true}
              key={evaluation.id}
              onClick={() => {
                navigate(
                  `${CertificateRoutes.ViewEvaluationConfiguration.replace(
                    ':certificateId',
                    certificateId
                  ).replace(':evaluationConfigurationId', evaluation.id)}`
                );
              }}
            >
              <TableCell align="left">
                <Typography>{evaluation.name}</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography> {evaluation.linkedTo?.name || ''} </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>
                  {printAssignToRoleIdentifiers(evaluation.assignTo)}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>
                  {getRolesNamePrint(evaluation.verifyBy)}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography>
                  {getRolesNamePrint(evaluation.visibleTo)}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography> {evaluation.competenceRequirement} </Typography>
              </TableCell>
              <TableCell align="left">
                {
                  evaluation.isRedemption 
                    ? <CheckIcon/>
                    : <></>
                }
              </TableCell>
              <TableCell align="right" >
                <TableActions >
                  <MenuItem
                    key='action-show'
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(
                        `${CertificateRoutes.ViewEvaluationConfiguration.replace(
                          ':certificateId',
                          certificateId
                        ).replace(':evaluationConfigurationId', evaluation.id)}`
                      );
                    }}
                  >
                    <span style={{ paddingRight: "5px" }} >{t(`${EVALUATIONS_TRANSLATE_KEY}.table.actionTooltips.show`)}</span>
                    <VisibilityIcon fontSize="small" />
                  </MenuItem>
                  <MenuItem
                    key='action-delete'
                    disabled={!canManageEvaluation}
                    onClick={(e) => {
                      e.stopPropagation();
                      onDeleteEvaluation(evaluation);
                    }}
                  >
                    <span style={{ paddingRight: "5px" }} >{t(`${EVALUATIONS_TRANSLATE_KEY}.table.actionTooltips.delete`)}</span>
                    <DeleteIcon fontSize="small" color='error' />
                  </MenuItem>
                </TableActions>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EvaluationConfigurationsTable;
