import { IBaseModel } from './base-model';
import { ICompanyEmployee } from './company-employee';
import { IPermission } from './permission';
import { IRole } from './role';
import { ISchoolEmployee } from './school-employee';
import { IStudent } from './student';

export enum UserStatus {
  Invited = 'invited',
  Active = 'active'
}

export enum UserableType {
  SchoolEmployee = 'App\\Models\\SchoolEmployee',
  CompanyEmployee = 'App\\Models\\CompanyEmployee',
  Student = 'App\\Models\\Student',
  CertifierEmployee = 'App\\Models\\CertifierEmployee',
  Company = 'App\\Models\\Company'
}

export interface IUser extends IBaseModel {
  email: string;
  secondaryEmail?: string;
  firstName: string;
  lastName: string;
  middleNameOne?: string;
  middleNameTwo?: string;
  avatar?: string;
  civility?: string;
  gender?: string;
  phone?: string;
  permissions?: IPermission[];
  roles?: IRole[];
  status: UserStatus;
  userableId: string;
  userableType: UserableType;
  userable?: ISchoolEmployee & ICompanyEmployee & IStudent;
  [key: string]: any;
}


export interface IUserBasicInfo extends IBaseModel {
  email: string;
  secondaryEmail?: string;
  firstName: string;
  lastName: string;
  avatar?: string;
  civility?: string;
  gender?: string;
  phone?: string;
  status: UserStatus;
  userableId: string;
  userableType: UserableType;
}
