import { useSnackbar } from 'notistack';

import { useMutation, useQueryClient } from 'react-query';
import { ICourse } from '../../../../models/course';
import { IApiError } from '../../../../utils/axios';
import {
  FETCH_COURSE_BY_COHORT_AND_SCHOOL_KEY,
  requestCourseDocumentsVerification as requestCourseDocumentsVerificationApi,
  REQUEST_COURSE_DOCUMENTS_VERIFICATION_KEY
} from '../../utils/api';

const useRequestCourseDocumentsVerification = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const {
    mutate: requestCourseDocumentsVerification,
    isLoading: isRequestCourseDocumentsVerification
  } = useMutation(requestCourseDocumentsVerificationApi, {
    mutationKey: REQUEST_COURSE_DOCUMENTS_VERIFICATION_KEY,
    onSuccess: (course: ICourse) => {
      queryClient.invalidateQueries([
        FETCH_COURSE_BY_COHORT_AND_SCHOOL_KEY,
        course.schoolId,
        course.cohortId
      ]);
    },
    onError: (error: IApiError) => {
      enqueueSnackbar(error.message, {
        variant: 'error'
      });
    }
  });

  return {
    isRequestCourseDocumentsVerification,
    requestCourseDocumentsVerification
  };
};

export default useRequestCourseDocumentsVerification;
