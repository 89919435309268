import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { IApiError } from '../../../../utils/axios';
import {
  resendInvitationsStudent as resendInvitationsStudentApi,
  RESEND_INVITATIONS_STUDENT_KEY
} from '../../utils/api';

const RESEND_INVITATATIONS_ORGANIZATION_STAFF_BASE_KEY =
  'settings.common.staff.resendInvitations';

const useResendInvitationsStudent = () => {
  const [studentToResendInvitations, setStudentToResendInvitations] =
    useState(null);

  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const {
    mutate: resendInvitationsStudent,
    isLoading: isResendInvitationsStudent
  } = useMutation(resendInvitationsStudentApi, {
    mutationKey: RESEND_INVITATIONS_STUDENT_KEY,
    onSuccess: () => {
      enqueueSnackbar(
        t(`${RESEND_INVITATATIONS_ORGANIZATION_STAFF_BASE_KEY}.successMessage`),
        {
          variant: 'success'
        }
      );
      setStudentToResendInvitations(null);
    },
    onError: (error: IApiError) => {
      enqueueSnackbar(error.message, {
        variant: 'error'
      });
    }
  });

  return {
    resendInvitationsStudent,
    isResendInvitationsStudent,
    studentToResendInvitations,
    setStudentToResendInvitations
  };
};

export default useResendInvitationsStudent;
