import { Alert, AlertTitle, Button, styled, Tooltip } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CourseStatus, ICourse } from '../../../../models/course';
import RejectMessageInfo from '../common/RejectMessageInfo';
import TaskDeadlineAlert from 'src/features/tasks/components/common/TaskDeadlineAlert';
import { ILocalDocuments } from '../../utils/types';
import { ITask } from '../../../../models/task';
import { checkIfAllFoldersHaveDocuments } from '../../utils/documents';

interface IProps {
  course: ICourse;
  documents: ILocalDocuments;
  onRequestVerification(): void;
  isTaskOverdue?: boolean;
  isTaskExpired?: boolean;
  task?: ITask;
}

const DOCUMENTS_BASE_KEY = 'cohorts.assigned.documents';

const EnrolledAlert = () => {
  const { t } = useTranslation();

  return (
    <Alert severity="success" sx={{ border: '1px solid #89d964' }}>
      <AlertTitle>
        <strong>
          {t(`${DOCUMENTS_BASE_KEY}.reviewAlert.successfullyVerifiedTitle`)}
        </strong>
      </AlertTitle>
      {t(`${DOCUMENTS_BASE_KEY}.reviewAlert.successfullyVerifiedText`)}
    </Alert>
  );
};

const PendingVerificationAlert = () => {
  const { t } = useTranslation();
  return (
    <Alert severity="info" sx={{ border: '1px solid#33c2ff;' }}>
      <AlertTitle>
        <strong>
          {t(`${DOCUMENTS_BASE_KEY}.reviewAlert.pendingVerificationTitle`)}
        </strong>
      </AlertTitle>
      {t(`${DOCUMENTS_BASE_KEY}.reviewAlert.pendingVerificationText`)}
    </Alert>
  );
};

const RejectedByCertifierAlert: React.FC<IProps> = ({
  course,
  documents,
  isTaskExpired,
  onRequestVerification
}) => {
  const { t } = useTranslation();

  const canRequestVerification = useMemo(() => {
    let canRequest = !isTaskExpired;

    const allHaveDocs = checkIfAllFoldersHaveDocuments(documents);

    return canRequest && allHaveDocs;
  }, [course, documents, isTaskExpired]);

  return (
    <Alert
      severity="error"
      sx={{ border: '1px solid#ff5e7c;' }}
      action={
        canRequestVerification ? (
          <Tooltip
            title={t(
              `${DOCUMENTS_BASE_KEY}.reviewAlert.requestVerificationButtonDisabledTooltip`
            )}
          >
            <Button
              sx={{
                ml: 2,
                minWidth: '190px'
              }}
              variant="contained"
              size="medium"
              onClick={onRequestVerification}
            >
              {t(`${DOCUMENTS_BASE_KEY}.reviewAlert.requestVerificationButton`)}
            </Button>
          </Tooltip>
        ) : null
      }
    >
      <AlertTitle>
        <strong>{t(`${DOCUMENTS_BASE_KEY}.reviewAlert.rejectedTitle`)}</strong>
      </AlertTitle>
      {t(`${DOCUMENTS_BASE_KEY}.reviewAlert.rejectedText`)}
      <RejectMessageInfo statusLogs={course.statusLogs} />
    </Alert>
  );
};

export const StyledAlertWithAction = styled(Alert)`
  .MuiAlert-action {
    align-items: center;
  }
`;

const InvitedAlert: React.FC<IProps> = ({
  course,
  documents,
  isTaskExpired,
  onRequestVerification
}) => {
  const { t } = useTranslation();

  const canRequestVerification = useMemo(() => {
    if (course.status !== CourseStatus.Invited || isTaskExpired) {
      return false;
    }

    return checkIfAllFoldersHaveDocuments(documents);
  }, [course, documents, isTaskExpired]);

  return (
    <StyledAlertWithAction
      sx={{ border: '1px solid#33c2ff;' }}
      severity="info"
      action={
        canRequestVerification ? (
          <Tooltip
            title={t(
              `${DOCUMENTS_BASE_KEY}.reviewAlert.requestVerificationButtonDisabledTooltip`
            )}
          >
            <Button
              sx={{
                ml: 2
              }}
              variant="contained"
              size="medium"
              onClick={onRequestVerification}
            >
              {t(`${DOCUMENTS_BASE_KEY}.reviewAlert.requestVerificationButton`)}
            </Button>
          </Tooltip>
        ) : null
      }
    >
      <AlertTitle>
        <strong>
          {t(`${DOCUMENTS_BASE_KEY}.reviewAlert.requestVerificationTitle`)}
        </strong>
      </AlertTitle>
      {t(`${DOCUMENTS_BASE_KEY}.reviewAlert.requestVerificationText`)}
    </StyledAlertWithAction>
  );
};

const RequestReview: React.FC<IProps> = ({
  course,
  documents,
  onRequestVerification,
  isTaskOverdue,
  isTaskExpired,
  task
}) => {
  if (isTaskOverdue) {
    return <TaskDeadlineAlert task={task} />;
  }

  if (course.status === CourseStatus.Enrolled) {
    return <EnrolledAlert />;
  }

  if (course.status === CourseStatus.Invited) {
    return (
      <InvitedAlert
        course={course}
        documents={documents}
        onRequestVerification={onRequestVerification}
        isTaskExpired={isTaskExpired}
      />
    );
  }

  if (course.status === CourseStatus.PendingCertifierVerification) {
    return <PendingVerificationAlert />;
  }

  if (course.status === CourseStatus.RejectedByCertifier) {
    return (
      <RejectedByCertifierAlert
        course={course}
        documents={documents}
        onRequestVerification={onRequestVerification}
        isTaskExpired={isTaskExpired}
      />
    );
  }

  return null;
};

export default RequestReview;
