import useTableParams from 'src/hooks/useTableParams';
import PageLoader from 'src/components/PageLoader';
import ApiError from 'src/components/ApiError';
import { Box } from '@mui/material';
import { Columns } from '../components/evaluation-configuration/EvaluationConfigurationsTable';
import { IEvaluationConfiguration, IBaseEvaluationConfiguration } from '../../../models/evaluation-configuration';
import { Order } from 'src/utils/types';
import { IUser } from 'src/models/user';
import StudentEvaluationConfigurationsTable from '../components/evaluation-configuration/StudentEvaluationConfigurationsTable';
import useFetchStudentEvaluationConfigurations from '../hooks/api/useFetchStudentEvaluationConfigurations';
import { ICreateEvaluation } from '../utils/types';
import useCreateStudentEvaluation from '../hooks/api/useCreateStudentEvaluation';
import { useNavigate } from 'react-router';
import { StudentRoutes } from 'src/features/students/routes';
import { Tabs } from 'src/features/profile/pages/Student';


const EVALUATION_CONFIGURATIONS_PER_PAGE = 20;

const StudentEvaluationConfigurations: React.FC<{ student: IUser }> = ({ student }) => {

  const navigate = useNavigate();

  const { onChangePage, onChangeSort, tableParams } = useTableParams({
    perPage: EVALUATION_CONFIGURATIONS_PER_PAGE,
    sort: {
      fieldName: Columns.CreatedAt,
      order: Order.Desc
    }
  });

  const {
    isFirstFetchingStudentEvaluationConfigurations,
    errorFetchingStudentEvaluationConfigurations,
    studentEvaluationConfigurationsData,
    isFetchingStudentEvaluationConfigurations,
    refetchStudentEvaluationConfigurations
  } = useFetchStudentEvaluationConfigurations(student.userable.id, tableParams);

  const {
    createStudentEvaluation,
    isCreatingStudentEvaluation
  } = useCreateStudentEvaluation(student.userable.id)

  const handleCreateEvaluation = (data: ICreateEvaluation) => {
    if (isCreatingStudentEvaluation) {
      return;
    }

    createStudentEvaluation(data, {
      onSuccess: () => refetchStudentEvaluationConfigurations()
    })
  };

  const handleViewEvaluations = (evaluationConfiguration: IBaseEvaluationConfiguration) => {
    navigate(`${StudentRoutes.Student.replace(':studentId', student.userable.id)
      }?tab=${Tabs.Evaluations}&evaluationConfigurationFilterId=${evaluationConfiguration.id}`);
  };

  if (isFirstFetchingStudentEvaluationConfigurations) {
    return <></>;
  }

  if (errorFetchingStudentEvaluationConfigurations) {
    return <ApiError error={errorFetchingStudentEvaluationConfigurations} />;
  }

  return (
    <Box sx={{ mt: -2 }}>
      <StudentEvaluationConfigurationsTable
        student={student}
        isFetching={isFetchingStudentEvaluationConfigurations}
        onChangePage={onChangePage}
        onChangeSort={onChangeSort}
        evaluationConfigurations={studentEvaluationConfigurationsData.data}
        page={tableParams.page}
        sort={tableParams.sort}
        perPage={tableParams.perPage}
        total={studentEvaluationConfigurationsData.meta.total}
        onCreateStudentEvaluation={handleCreateEvaluation}
        onViewStudentEvaluations={handleViewEvaluations}
      />
    </Box>
  );
};

export default StudentEvaluationConfigurations;
