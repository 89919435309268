const blocks = {
  optional: 'Optionnel',
  viewBlock: 'Voir le bloc',
  numberOfStudents:
    'étudiants sont inscrits à ce bloc',
  noStudents: "Pas d'étudiants liés à ce bloc !",
  backToList: 'Retour à la liste',
  block: {
    details: 'Détails',
    students: 'Étudiants',
    noStudentBlocks: "Pas d'étudiants sur ce bloc...",
    correctors: {
      label: 'Affecter un correcteur'
    }
  },
  corrector: 'Correcteur',
  view: 'Voir',
  table: {
    column: {
      student: 'Étudiant',
      correctors: 'Correcteurs',
      allAssigned: 'Tous assignés',
      tutor: 'Tuteur',
      actions: 'Actions'
    },
    missingAssignments: {
      missingTutor: "Le tuteur n'a pas été assigné",
      evaluationMissing: {
        gridName: "Grille {{name}}",
        roleIdentifier: "Le rôle {{name}} n'est pas assigné",
      }
    }
  }
};

export default blocks;
