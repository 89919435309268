import { useSnackbar } from 'notistack';
import { useMutation, useQueryClient } from 'react-query';
import { IJobRequiredKnowHow } from 'src/models/job-required-know-how';
import { IApiError } from '../../../../../utils/axios';
import {
  FETCH_STUDENT_JOB_KEY,
  updateJobRequiredKnowHow as updateJobRequiredKnowHowApi,
  UPDATE_JOB_REQUIRED_KNOW_HOW_KEY
} from '../../../utils/api';

const useUpdateJobRequiredKnowHow = () => {
  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const {
    mutate: updateJobRequiredKnowHow,
    isLoading: isUpdatingJobRequiredKnowHow
  } = useMutation(updateJobRequiredKnowHowApi, {
    mutationKey: UPDATE_JOB_REQUIRED_KNOW_HOW_KEY,
    onSuccess: (data: IJobRequiredKnowHow) => {
      queryClient.invalidateQueries([FETCH_STUDENT_JOB_KEY]);
    },
    onError: (error: IApiError) => {
      enqueueSnackbar(error.message, {
        variant: 'error'
      });
    }
  });

  return {
    updateJobRequiredKnowHow,
    isUpdatingJobRequiredKnowHow
  };
};

export default useUpdateJobRequiredKnowHow;
