import { Alert, AlertTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ITask } from '../../../../models/task';
import { DateFormat, formatDate } from '../../../../utils/dates';

const TASKS_TRANSLATE_KEY = 'tasks.deadline';

interface IProps {
  task?: ITask;
}

const TaskDeadlineAlert: React.FC<IProps> = ({ task }) => {
  const { t } = useTranslation();

  return (
    <Alert severity="error" sx={{ border: '1px solid#ff5e7c;' }}>
      <AlertTitle>
        <strong>{t(`${TASKS_TRANSLATE_KEY}.deadlineExpiredTitle`)}</strong>
      </AlertTitle>
      {t(`${TASKS_TRANSLATE_KEY}.alertText`, {
        taskName: task?.name,
        dueDate: formatDate(task?.dueDate, DateFormat.DateAndTime)
      })}
    </Alert>
  );
};

export default TaskDeadlineAlert;
