import { lazy } from 'react';
import SuspenseRouteLoader from '../../components/SuspenseRouteLoader';

const Status404 = SuspenseRouteLoader(lazy(() => import('./pages/Status404')));
const Status500 = SuspenseRouteLoader(lazy(() => import('./pages/Status500')));
const StatusComingSoon = SuspenseRouteLoader(
  lazy(() => import('./pages/ComingSoon'))
);
const StatusMaintenance = SuspenseRouteLoader(
  lazy(() => import('./pages/Maintenance'))
);

const routes = [
  {
    path: 'status',
    children: [
      {
        path: '',
        element: <Status404 />
      },
      {
        path: '500',
        element: <Status500 />
      },
      {
        path: 'maintenance',
        element: <StatusMaintenance />
      },
      {
        path: 'coming-soon',
        element: <StatusComingSoon />
      }
    ]
  },
  {
    path: '*',
    element: <Status404 />
  }
];

export default routes;
