import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';
import {
  createStudentEvaluation as createStudentEvaluationApi,
  CREATE_STUDENT_EVALUATION_KEY
} from '../../utils/api';
import { t } from 'i18next';
import { IApiError } from '../../../../utils/axios';
import { ICreateEvaluation } from '../../utils/types';

const useCreateStudentEvaluation = (studentId) => {

  const { enqueueSnackbar } = useSnackbar();

  const {
    mutate: createStudentEvaluation,
    isLoading: isCreatingStudentEvaluation
  } = useMutation(
    (data: ICreateEvaluation) => createStudentEvaluationApi(studentId, data),
    {
      mutationKey: CREATE_STUDENT_EVALUATION_KEY,
      onSuccess: () => {
        enqueueSnackbar(t(`evaluations.common.api.success`), {
          variant: 'success'
        });
      },
      onError: (error: IApiError) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    }
  );

  return {
    createStudentEvaluation,
    isCreatingStudentEvaluation
  };
};

export default useCreateStudentEvaluation;
