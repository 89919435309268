import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField
} from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { EditorWrapper } from 'src/features/cohorts/components/common/StyledComponents';
import generateArrayOfYears from 'src/utils/yearsArray';
import DragAndDropAvatar from '../../../../components/DragAndDropAvatar';
import { ICreateUpdateCertificateForm } from '../../utils/types';

const FORM_BASE_KEY = 'certificates.common.certificateForm';

export enum Locales {
  English = 'en',
  French = 'fr'
}

const CertificateBasicInfoFields = () => {
  const {
    setFieldTouched,
    setFieldValue,
    touched,
    errors,
    handleBlur,
    handleChange,
    values,
    initialValues
  } = useFormikContext<ICreateUpdateCertificateForm>();

  const handleFileUploaded = (url: string) => {
    setFieldValue('rncpLogo', url);
    setFieldTouched('rncpLogo');
  };

  const years = generateArrayOfYears(20);

  const { t } = useTranslation();

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth margin="normal">
            <TextField
              error={Boolean(touched.longName && errors.longName)}
              fullWidth
              helperText={touched.longName && errors.longName}
              label={t(`${FORM_BASE_KEY}.longName.label`)}
              placeholder={t(`${FORM_BASE_KEY}.longName.placeholder`)}
              name="longName"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.longName}
              variant="outlined"
              sx={{ mt: 0 }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth margin="normal">
            <TextField
              error={Boolean(touched.shortName && errors.shortName)}
              fullWidth
              helperText={touched.shortName && errors.shortName}
              label={t(`${FORM_BASE_KEY}.shortName.label`)}
              placeholder={t(`${FORM_BASE_KEY}.shortName.placeholder`)}
              name="shortName"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.shortName}
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth margin="normal">
            <DragAndDropAvatar
              placeholder={t(`${FORM_BASE_KEY}.avatar.placeholder`)}
              uploadingUrl="images"
              onFileUploaded={handleFileUploaded}
              initialAvatarUrl={initialValues.rncpLogo}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth margin="normal">
            <InputLabel id="year_of_certifictions">
              {t(`${FORM_BASE_KEY}.yearOfCertification.label`)}
            </InputLabel>
            <Select
              error={Boolean(
                touched.yearOfCertification && errors.yearOfCertification
              )}
              fullWidth
              labelId="year_of_certifications"
              label={t(`${FORM_BASE_KEY}.yearOfCertification.label`)}
              name="yearOfCertification"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.yearOfCertification}
              defaultValue={new Date().getFullYear()}
              variant="outlined"
            >
              {years.map((year) => (
                <MenuItem value={year} key={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              error={Boolean(touched.rncpCode && errors.rncpCode)}
              fullWidth
              helperText={touched.rncpCode && errors.rncpCode}
              label={t(`${FORM_BASE_KEY}.rncpCode.label`)}
              placeholder={t(`${FORM_BASE_KEY}.rncpCode.placeholder`)}
              name="rncpCode"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.rncpCode}
              variant="outlined"
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              error={Boolean(touched.rncpLevel && errors.rncpLevel)}
              fullWidth
              helperText={touched.rncpLevel && errors.rncpLevel}
              label={t(`${FORM_BASE_KEY}.rncpLevel.label`)}
              placeholder={t(`${FORM_BASE_KEY}.rncpLevel.placeholder`)}
              name="rncpLevel"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.rncpLevel}
              variant="outlined"
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              error={Boolean(touched.rncpLevelEurope && errors.rncpLevelEurope)}
              fullWidth
              helperText={touched.rncpLevelEurope && errors.rncpLevelEurope}
              label={t(`${FORM_BASE_KEY}.rncpLevelEurope.label`)}
              placeholder={t(`${FORM_BASE_KEY}.rncpLevelEurope.placeholder`)}
              name="rncpLevelEurope"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.rncpLevelEurope}
              variant="outlined"
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel id="mutiple-select-label">
              {t(`${FORM_BASE_KEY}.locale.label`)}
            </InputLabel>
            <Select
              error={Boolean(touched.locale && errors.locale)}
              fullWidth
              label={t(`${FORM_BASE_KEY}.locale.label`)}
              placeholder={t(`${FORM_BASE_KEY}.locale.placeholder`)}
              name="locale"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.locale}
              variant="outlined"
            >
              {Object.keys(Locales).map((locale, index) => (
                <MenuItem
                  key={index}
                  value={Locales[locale as keyof typeof Locales]}
                >
                  {locale}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error>
              {touched.locale && errors.locale}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <EditorWrapper>
            <Field name="description">
              {({ field }) => (
                <ReactQuill
                  value={field.value}
                  onChange={field.onChange(field.name)}
                  style={{
                    height: '138px'
                  }}
                  placeholder={t(`${FORM_BASE_KEY}.description.placeholder`)}
                />
              )}
            </Field>
          </EditorWrapper>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            sx={{ ml: 0, mt: 6 }}
            control={
              <Switch
                checked={values.isPublished}
                name="isPublished"
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{
                  'aria-label': t(`${FORM_BASE_KEY}.isPublished.label`)
                }}
              />
            }
            label={<>{t(`${FORM_BASE_KEY}.isPublished.label`)}</>}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default CertificateBasicInfoFields;
