import { useQuery } from 'react-query';
import { IApiError } from '../../../../utils/axios';
import {
    FETCH_CERTIFIER_EMPLOYEES_KEY,
    fetchCertifierEmployees
} from '../../utils/api';

const useFetchCertifierEmployees = () => {
    const {
        isLoading: isFirstFetchingCertifierEmployees,
        error: errorFetchingCertifierEmployees,
        data: certifierEmployeesData,
        isFetching: isFetchingCertifierEmployees
    } = useQuery<any, IApiError>(
        [FETCH_CERTIFIER_EMPLOYEES_KEY],
        () => fetchCertifierEmployees()
    );

    return {
        isFirstFetchingCertifierEmployees,
        errorFetchingCertifierEmployees,
        certifierEmployeesData,
        isFetchingCertifierEmployees
    };
};

export default useFetchCertifierEmployees;