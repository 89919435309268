import { useSnackbar } from 'notistack';
import { useMutation, useQueryClient } from 'react-query';
import { IApiError } from '../../../../../utils/axios';
import {
  FETCH_COHORT_TASKS_CONFIGURATIONS_KEY,
  updateCohortTaskConfiguration as updateCohortTaskConfigurationApi,
  UPDATE_COHORT_TASK_CONFIGURATION_KEY
} from '../../../utils/api';

const useUpdateCohortTask = () => {
  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const {
    mutate: updateCohortTaskConfiguration,
    isLoading: isUpdatingCohortTaskConfiguration
  } = useMutation(updateCohortTaskConfigurationApi, {
    mutationKey: UPDATE_COHORT_TASK_CONFIGURATION_KEY,
    onSuccess: ({ cohortId }) => {
      queryClient.invalidateQueries([
        FETCH_COHORT_TASKS_CONFIGURATIONS_KEY,
        cohortId
      ]);
    },
    onError: (error: IApiError) => {
      enqueueSnackbar(error.message, {
        variant: 'error'
      });
    }
  });

  return {
    updateCohortTaskConfiguration,
    isUpdatingCohortTaskConfiguration
  };
};

export default useUpdateCohortTask;
