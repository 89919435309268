import { Grid } from '@mui/material';
import { IBlock } from 'src/models/block';
import { IAssignedCohort } from '../utils/types';
import Block from './Block';

interface IProps {
  blocks: IBlock[];
  cohort: IAssignedCohort;
}

const Blocks: React.FC<IProps> = ({ blocks, cohort }) => {
  return (
    <Grid container spacing={4}>
      {blocks.map((block) => (
        <Block block={block} cohort={cohort} key={block.id} />
      ))}
    </Grid>
  );
};

export default Blocks;
