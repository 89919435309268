import { Avatar, Box, LinearProgress, styled, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import useUploadAvatar from './useUploadAvatar';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import ApiError from '../ApiError';

const BoxUploadWrapper = styled(Box)<{ hasError: boolean }>(
  ({ theme, hasError }) => {
    const { colors, general } = theme;
    return `
      border-radius: ${general.borderRadius};
      padding: 51px 16px;
      background: ${colors.alpha.black[5]};
      border: 1px dashed;
      outline: none;
      display: block;
      transition: ${theme.transitions.create(['border', 'background'])};
      border-color: ${hasError ? colors.error.main : colors.alpha.black[30]};
      &:hover {
        background: ${colors.alpha.white[50]};
        border-color: ${hasError ? colors.error.main : colors.primary.main};
        cursor: pointer;
      }
`;
  }
);

const AvatarWrapper = styled(Avatar)(
  ({ theme }) => `
    margin: auto;
    background: transparent;
    color: ${theme.colors.primary.main};
    width: ${theme.spacing(7)};
    height: ${theme.spacing(7)};
`
);

const AvatarSuccess = styled(Avatar)(
  ({ theme }) => `
    background: ${theme.colors.success.light};
    width: ${theme.spacing(7)};
    height: ${theme.spacing(7)};
`
);

const AvatarDanger = styled(Avatar)(
  ({ theme }) => `
    background: ${theme.colors.error.light};
    width: ${theme.spacing(7)};
    height: ${theme.spacing(7)};
`
);

const WrapperImage = styled('div')`
  position: relative;
  overflow: hidden;
  padding-bottom: 60%;
  margin: -32px auto;
`;

const UploadingProgress = styled(LinearProgress)`
  position: absolute;
  bottom: 8px;
  width: calc(100% - 32px);
  height: 4px;
`;

export const WrapperPreviewImage = styled('div')`
  position: relative;
  overflow: hidden;
  padding-bottom: 60%;
`;

export const PreviewImage = styled('img')`
  position: absolute;
  top: 0;
  left: 0;
  object-fit: contain;
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: 100%;
`;

export const ImagePlaceholder = styled('div')(
  ({ theme }) => `
    background: ${theme.colors.alpha.black[10]};
    width: 100%;
    height: 240px;
    display: block;
  `
);

const DRAG_AND_DROP_DOCUMENT_TRANSLATE_KEY = 'common.dragAndDropDocument';

interface IProps {
  initialAvatarUrl?: string;
  uploadingUrl: string;
  placeholder: string;
  onFileUploaded(url: string): void;
}

const DragAndDropAvatar: React.FC<IProps> = ({
  initialAvatarUrl,
  uploadingUrl,
  placeholder,
  onFileUploaded
}) => {
  const { isUploading, uploadingError, uploadAvatar } = useUploadAvatar();

  const {
    acceptedFiles,
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps
  } = useDropzone({
    accept:
      'image/jpeg, image/jpg, image/png, image/gif, image/bmp, image/svg+xml',
    multiple: false,
    maxSize: 2 * 1024 * 1024
  });

  const { t } = useTranslation();

  const [acceptedFile] = acceptedFiles;

  useEffect(() => {
    if (acceptedFile) {
      uploadAvatar(
        { file: acceptedFile, url: uploadingUrl },
        {
          onSuccess: (data) => {
            onFileUploaded(data.image);
          }
        }
      );
    }
  }, [acceptedFile]);

  return (
    <>
      <BoxUploadWrapper {...getRootProps()} hasError={!!uploadingError}>
        <input {...getInputProps()} />
        {isUploading && (
          <Box sx={{ width: '100%' }}>
            <UploadingProgress />
          </Box>
        )}
        {acceptedFile && (
          <WrapperImage>
            <PreviewImage
              alt="school image"
              src={URL.createObjectURL(acceptedFiles[0])}
            />
          </WrapperImage>
        )}
        {!acceptedFile && initialAvatarUrl && (
          <WrapperImage>
            <PreviewImage alt="school image" src={initialAvatarUrl} />
          </WrapperImage>
        )}
        {isDragAccept && !acceptedFile && (
          <React.Fragment>
            <AvatarSuccess variant="rounded">
              <CheckTwoToneIcon />
            </AvatarSuccess>
            <Typography
              sx={{
                textAlign: 'center'
              }}
            >
              {t(`${DRAG_AND_DROP_DOCUMENT_TRANSLATE_KEY}.dropFilesToUpload`)}
            </Typography>
          </React.Fragment>
        )}
        {isDragReject && (
          <React.Fragment>
            <AvatarDanger variant="rounded">
              <CloseTwoToneIcon />
            </AvatarDanger>
            <Typography
              sx={{
                textAlign: 'center'
              }}
            >
              {t(
                `${DRAG_AND_DROP_DOCUMENT_TRANSLATE_KEY}.cantUploadTheseFiles`
              )}
            </Typography>
          </React.Fragment>
        )}
        {!isDragActive && !isUploading && !acceptedFile && !initialAvatarUrl && (
          <React.Fragment>
            <AvatarWrapper variant="rounded">
              <CloudUploadTwoToneIcon />
            </AvatarWrapper>
            <Typography
              sx={{
                textAlign: 'center'
              }}
            >
              {placeholder}
            </Typography>
          </React.Fragment>
        )}
      </BoxUploadWrapper>
      {uploadingError && (
        <ApiError error={uploadingError} sx={{ mt: 2, mb: 2 }} />
      )}
    </>
  );
};

export default DragAndDropAvatar;
