import { t } from 'i18next';
import Label from '../../../../components/Label';

interface IProps {
  isPublished: boolean;
}

const LIST_CERTIFICATES_TRANSLATE_KEY = 'certificates.listAll';

const Status: React.FC<IProps> = ({ isPublished }) => {
  if (isPublished) {
    return (
      <Label color="primary">
        {t(`${LIST_CERTIFICATES_TRANSLATE_KEY}.table.status.published`)}
      </Label>
    );
  }

  return (
    <Label color="error">
      {t(`${LIST_CERTIFICATES_TRANSLATE_KEY}.table.status.unlisted`)}
    </Label>
  );
};

export default Status;
