import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemIcon,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import { Formik } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useFetchAllRoles from 'src/features/acl/hooks/api/useFetchAllRoles';
import * as Yup from 'yup';
import { RoleName } from '../../models/role';

interface IProps {
  isVisible: boolean;
  fileRoles?: string[];
  onHide(): void;
  onFinish(roles: string[]): void;
}

const FILE_TREE_TRANSLATE_KEY = 'common.fileTree';

const CreateEditRolesPopup: React.FC<IProps> = ({
  isVisible,
  fileRoles,
  onHide,
  onFinish
}) => {
  const { t } = useTranslation();

  const { roles, isFetchingRoles } = useFetchAllRoles();

  const showRoles = useMemo(() => {
    if (!roles) return [];

    return roles.filter(
      (role) =>
        ![RoleName.CertifierAdmin, RoleName.OrganisationAdmin].includes(
          role.name as RoleName
        )
    );
  }, [roles]);

  if (isFetchingRoles) {
    return null;
  }

  return (
    <Dialog fullWidth maxWidth="sm" onClose={onHide} open={isVisible}>
      <DialogTitle>
        <Typography variant="h4">
          {t(`${FILE_TREE_TRANSLATE_KEY}.edit.roleTitle`)}
        </Typography>
      </DialogTitle>
      <Formik<{ roles: string[] }>
        initialValues={{ roles: fileRoles || [] }}
        validationSchema={Yup.object().shape({
          roles: Yup.array().required(
            t('common.validation.required', {
              field: t('common.validation.roleField')
            })
          )
        })}
        onSubmit={({ roles }) => onFinish(roles)}
      >
        {({
          touched,
          errors,
          values,
          handleBlur,
          handleChange,
          handleSubmit
        }) => (
          <DialogContent>
            <form onSubmit={handleSubmit} noValidate>
              <FormControl fullWidth margin="normal">
                <InputLabel id="mutiple-select-label">
                  {t(`${FILE_TREE_TRANSLATE_KEY}.roles.label`)}
                </InputLabel>
                <Select
                  error={Boolean(touched.roles && errors.roles)}
                  fullWidth
                  label={t(`${FILE_TREE_TRANSLATE_KEY}.roles.label`)}
                  placeholder={t(
                    `${FILE_TREE_TRANSLATE_KEY}.roles.placeholder`
                  )}
                  name="roles"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.roles}
                  renderValue={() =>
                    values.roles
                      .map((role) => t(`acl.listAll.name.${role}`))
                      .join(', ')
                  }
                  variant="outlined"
                  multiple
                >
                  {showRoles?.map((role) => (
                    <MenuItem key={role.id} value={role.name}>
                      <ListItemIcon>
                        <Checkbox checked={values.roles.includes(role.name)} />
                      </ListItemIcon>
                      {t(`acl.listAll.name.${role.name}`)}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error>
                  {touched.roles && errors.roles}
                </FormHelperText>
              </FormControl>

              <Box display="flex" justifyContent="flex-end">
                <Button
                  color="secondary"
                  size="small"
                  variant="outlined"
                  onClick={onHide}
                  sx={{ mr: 1 }}
                >
                  {t(`common.buttons.cancel`)}
                </Button>
                <Button variant="contained" size="medium" type="submit">
                  {t(`common.buttons.save`)}
                </Button>
              </Box>
            </form>
          </DialogContent>
        )}
      </Formik>
    </Dialog>
  );
};

export default CreateEditRolesPopup;
