import { Alert, Button, AlertTitle } from '@mui/material';
import { t } from 'i18next';
import RejectMessageInfo from 'src/features/cohorts/components/common/RejectMessageInfo';
import { IUser } from 'src/models/user';
import { StudentStatuses } from 'src/utils/const';
import { studentIsOnHold } from 'src/utils/userHelpers';

const STUDENT_TRANSLATE_BASE_KEY = 'profile.student';

interface IProps {
  student: IUser;
  isTaskExpired: boolean;
  isOrgAdmin: boolean;
  canManage: boolean;
  setStatus(status: StudentStatuses): void;
}

const RejectedBySchool: React.FC<IProps> = ({
  student,
  isTaskExpired,
  isOrgAdmin,
  canManage,
  setStatus
}) => {
  return (
    <Alert
      severity="error"
      sx={{
        border: '1px solid#ff5e7c;',
        mb: 2
      }}
      action={
        ((!isTaskExpired && canManage) || isOrgAdmin) && (
          <Button
            disabled={studentIsOnHold(student)}
            color="primary"
            variant="contained"
            sx={{ m: 1 }}
            onClick={() => {
              setStatus(StudentStatuses.Verified);
            }}
          >
            {t(`${STUDENT_TRANSLATE_BASE_KEY}.verifyStudent`)}
          </Button>
        )
      }
    >
      <AlertTitle>
        <strong>
          {t(
            `${STUDENT_TRANSLATE_BASE_KEY}.uploadDocuments.reviewAlert.rejectedTitle`
          )}
        </strong>
      </AlertTitle>
      {t(
        `${STUDENT_TRANSLATE_BASE_KEY}.uploadDocuments.reviewAlert.rejectedText`
      )}
      <RejectMessageInfo statusLogs={student.userable.statusLogs.filter((log) => log.statusLabel === null)} />
    </Alert>
  );
};

export default RejectedBySchool;
