const notifications = {
  notifications: 'Notifications',
  timelineNotifications: {
    markAllAsRead: 'Mark all as read'
  },
  seeMore: 'See more',
  noNotifications: 'No notifications to be shown'
};

export default notifications;
