const generateArrayOfYears = (mostRecentYears: number) => {
  var min = 2020
  var max = new Date().getFullYear() + mostRecentYears
  var years = []

  for (var i = max; i >= min; i--) {
    years.push(i)
  }
  return years
}

export default generateArrayOfYears;
