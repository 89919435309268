import Label from '../../../../components/Label';
import { formatStatus } from '../../utils/formatStatus';

interface IProps {
  studentStatus: string; // add enum types
}

const Status: React.FC<IProps> = ({ studentStatus }) => {
  if (studentStatus === 'rejected_by_school') {
    return <Label color="error">{formatStatus(studentStatus)}</Label>;
  }
  if (studentStatus === 'pending_school_verification') {
    return <Label color="info">{formatStatus(studentStatus)}</Label>;
  }
  return <Label color="primary">{formatStatus(studentStatus)}</Label>;
};

export default Status;
