import {
  Box,
  Button,
  Grid,
  IconButton,
  styled,
  Typography
} from '@mui/material';
import { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import * as Yup from 'yup';
import { ICohort } from '../../../../models/cohort';
import { Mode } from '../../../../utils/types';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import CohortBasicInfoFields from '../create-cohort/CohortBasicInfoFields';
import LoadingButton from '@mui/lab/LoadingButton';
import useUpdateCohort from '../../hooks/api/useUpdateCohort';
import ReactQuill from 'react-quill';
import { StyledReactQuillWrapper } from '../common/StyledComponents';
import {
  ImagePlaceholder,
  WrapperPreviewImage,
  PreviewImage
} from 'src/components/DragAndDropAvatar';
import { IAssignedCohort } from 'src/features/blocks/utils/types';

const VALIDATION_TRANSLATE_KEY = 'common.validation';

const StyledEditButton = styled(IconButton)(
  ({ theme }) => `
  position: absolute;
  right: 0;
  top: ${theme.spacing(2)};
  `
);

interface IProps {
  cohort: ICohort | IAssignedCohort;
  canManageCohort: boolean;
  mode?: Mode;
}

interface IEditCohortBasicInfo {
  avatar: string | null;
  name: string;
  shortName: string;
  description: string;
  yearOfCertification: number;
  certificateId?: string;
}

const FORM_BASE_KEY = 'cohorts.common.cohortForm';

const CohortBasicInfo: React.FC<IProps> = ({
  cohort,
  mode,
  canManageCohort
}) => {
  const [localMode, setLocalMode] = useState(mode);

  const { isUpdatingCohort, updateCohort } = useUpdateCohort();

  const handleEdit = (data: Partial<ICohort>) => {
    updateCohort(
      { ...cohort, ...data },
      {
        onSuccess: () => {
          setLocalMode(Mode.View);
        }
      }
    );
  };

  const { t } = useTranslation();

  if (localMode === Mode.View) {
    return (
      <div>
        <Grid container spacing={2} sx={{ position: 'relative' }}>
          <Grid item sm={12}>
            <Typography variant="h3" component="h3" sx={{ mb: 0 }}>
              {cohort.name}
            </Typography>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              {cohort.shortName}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            {cohort.avatar ? (
              <WrapperPreviewImage>
                <PreviewImage alt="cohort image" src={cohort.avatar} />
              </WrapperPreviewImage>
            ) : (
              <ImagePlaceholder />
            )}
          </Grid>
          <Grid item xs={12} sm={8}>
            <Box sx={{ pb: 2, display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ pb: 0, fontSize: 14, mr: 1 }}>
                {t(`${FORM_BASE_KEY}.certificate.label`)}:
              </Typography>
              <Typography style={{ fontWeight: 'bold' }}>
                {`${cohort.certificate?.longName} (${cohort.certificate?.shortName})`}
              </Typography>
            </Box>
            <Box sx={{ pb: 2, display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ pb: 0, fontSize: 14, mr: 1 }}>
                {t(`${FORM_BASE_KEY}.yearOfCertification.label`)}:
              </Typography>
              <Typography style={{ fontWeight: 'bold' }}>
                {cohort.yearOfCertification}
              </Typography>
            </Box>
            {cohort.description && (
              <>
                <Typography sx={{ pb: 0, fontSize: 14, mb: 1 }}>
                  {t(`${FORM_BASE_KEY}.description.label`)}:{' '}
                </Typography>
                <StyledReactQuillWrapper>
                  <ReactQuill
                    value={cohort.description}
                    readOnly={true}
                    theme={'bubble'}
                  />
                </StyledReactQuillWrapper>
              </>
            )}
          </Grid>
          {canManageCohort && (
            <StyledEditButton
              size="small"
              onClick={() => setLocalMode(Mode.Edit)}
              color="primary"
            >
              <EditIcon fontSize="small" />
            </StyledEditButton>
          )}
        </Grid>
      </div>
    );
  }

  return (
    <div>
      <Formik<IEditCohortBasicInfo>
        initialValues={{
          avatar: cohort.avatar,
          name: cohort.name,
          shortName: cohort.shortName,
          yearOfCertification: cohort.yearOfCertification,
          description: cohort.description
        }}
        onSubmit={handleEdit}
        validationSchema={Yup.object().shape({
          name: Yup.string().required(
            t(`${VALIDATION_TRANSLATE_KEY}.required`, {
              field: t(`${VALIDATION_TRANSLATE_KEY}.nameField`)
            })
          ),
          shortName: Yup.string().required(
            t(`${VALIDATION_TRANSLATE_KEY}.required`, {
              field: t(`${VALIDATION_TRANSLATE_KEY}.shortNameField`)
            })
          ),
          yearOfCertification: Yup.number().required(
            t(`${VALIDATION_TRANSLATE_KEY}.required`, {
              field: t(`${VALIDATION_TRANSLATE_KEY}.yearOfCertification`)
            })
          )
        })}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <CohortBasicInfoFields mode={Mode.Edit} />
            <Box display="flex" justifyContent="flex-end" mt={3}>
              <Button
                color="secondary"
                size="medium"
                variant="outlined"
                onClick={() => setLocalMode(Mode.View)}
              >
                {t('common.buttons.cancel')}
              </Button>
              <LoadingButton
                loading={isUpdatingCohort}
                sx={{
                  ml: 2
                }}
                variant="contained"
                size="medium"
                type="submit"
              >
                {t('common.buttons.save')}
              </LoadingButton>
            </Box>
          </form>
        )}
      </Formik>
    </div>
  );
};

CohortBasicInfo.defaultProps = {
  mode: Mode.View
};

export default CohortBasicInfo;
