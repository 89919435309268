import { UserStatus } from 'src/models/user';

const settings = {
  common: {
    organizationForm: {
      name: {
        label: 'Certifier Name',
        placeholder: 'Write certifier name here..'
      }
    },
    staff: {
      boxTitle: "CERTIFIERS'S STAFF",
      addStaff: 'Invite certifier staff',
      noStaffInvited: 'No staff invited',
      modalTitle: 'Invite certifier staff',
      modalSubtitle: 'Fill in fields below to invite certifier staff',
      form: {
        email: {
          label: 'Email',
          placeholder: 'Write email here'
        },
        firstName: {
          label: 'First Name',
          placeholder: 'Write first name here'
        },
        lastName: {
          label: 'Last Name',
          placeholder: 'Write last name here'
        },
        roles: {
          label: 'Roles',
          placeholder: 'Select roles'
        }
      },
      delete: {
        modalTitle: 'Delete employee',
        modalBody:
          'Are you sure you want to delete <1>{{name}}</1> from employee list?'
      },
      userStatus: {
        [UserStatus.Active]: 'Active',
        [UserStatus.Invited]: 'Invited'
      },
      resendInvitations: {
        modalTitle: 'Resend invitations',
        modalBody:
          'Are you sure you want to resend invitations for <1>{{name}}</1>?',
        successMessage: 'Resend invitations link successfully sent!'
      }
    }
  },
  ownView: {
    htmlTitle: 'Organization settings',
    title: 'Organization settings',
    subtitle: 'See and edit organization settings'
  }
};

export default settings;
