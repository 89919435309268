import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  Autocomplete,
  FormHelperText,
  TextField,
  Alert,
  AlertTitle
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next';
import { IAssignTutor } from 'src/features/profile/utils/types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { IUser } from 'src/models/user';
import useFetchCompanies from 'src/features/profile/hooks/api/useFetchCompanies';
import useFetchTutors from 'src/features/profile/hooks/api/useFetchTutors';
import PageLoader from 'src/components/PageLoader';
import { CompanyStatus, ICompany } from 'src/models/company';
import { printUserName } from 'src/utils/userHelpers';

const BUTTONS_TRANSLATE_KEY = 'common.buttons';
const VALIDATION_TRANSLATE_KEY = 'common.validation';

interface IProps {
  student: IUser;
  open: boolean;
  onClose(): void;
  onConfirm(values: IAssignTutor): void;
  isCreatingNew?: boolean;
  showCreateNewNotice?: boolean
  isConfirming?: boolean;
  title: string;
  initialData: IAssignTutor;
}

const AssignTutorModal: React.FC<IProps> = ({
  student,
  open,
  onClose,
  onConfirm,
  isConfirming,
  title,
  initialData,
  isCreatingNew = true,
  showCreateNewNotice = false
}) => {

  const { t } = useTranslation();

  const { companies, isFetchingCompanies } = useFetchCompanies(student.userable.schoolId);
  const { tutors, isFetchingTutors } = useFetchTutors(student.userable.schoolId);

  const getActiveCompanies = () => {
    return companies?.filter(company => company.status === CompanyStatus.Active) ?? []
  }

  const getCompany = (companyId) => {
    return getActiveCompanies().find(company => company.id === companyId) ?? null
  }

  const getCompanyTutors = (companyId) => {
    return tutors?.filter(tutor => tutor.userable.companyId === companyId) ?? []
  }

  const getTutor = (companyId, tutorId) => {
    return getCompanyTutors(companyId).find(tutor => tutor.userable.id === tutorId) ?? null
  }

  if (isFetchingCompanies || isFetchingTutors) {
    return <PageLoader />;
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth='sm'
      open={open}
      onClose={onClose}
    >
      <DialogTitle sx={{ fontSize: 18 }}>
        {title}
        {isCreatingNew && showCreateNewNotice &&
          <Alert severity="warning" sx={{ mt: '15px' }}>
            <AlertTitle>{t(`profile.student.assignTutor.createNewNoticeTitle`)}</AlertTitle>
            {t(`profile.student.assignTutor.createNewNoticeMessage`)}
          </Alert>
        }
      </DialogTitle>
      <Formik<IAssignTutor>
        initialValues={isCreatingNew ? { ...initialData, tutorId: '', companyId: '' } : initialData}
        onSubmit={onConfirm}
        validationSchema={Yup.object().shape({
          companyId: Yup.string().required(t(`${VALIDATION_TRANSLATE_KEY}.required`, {
            field: t(`${VALIDATION_TRANSLATE_KEY}.companyIdField`)
          })),
          tutorId: Yup.string().required(t(`${VALIDATION_TRANSLATE_KEY}.required`, {
            field: t(`${VALIDATION_TRANSLATE_KEY}.tutorIdField`)
          }))
        })}
      >
        {({ handleSubmit, setFieldValue, touched, errors, values }) => (
          < form onSubmit={handleSubmit}>
            <DialogContent >
              <FormControl fullWidth>
                <Autocomplete
                  disabled={!isCreatingNew}
                  value={getCompany(values.companyId)}
                  options={getActiveCompanies()}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => <TextField {...params} label={t(`profile.student.assignTutorFields.companies`)} />}
                  onChange={(_, company: ICompany) => {
                    setFieldValue('companyId', company?.id ?? null);
                    setFieldValue('tutorId', null);
                  }}
                />
                {!values.companyId && (
                  <FormHelperText error>
                    {touched.companyId && errors.companyId}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl fullWidth sx={{ mt: '30px' }}>
                <Autocomplete
                  value={getTutor(values.companyId, values.tutorId)}
                  options={getCompanyTutors(values.companyId)}
                  getOptionLabel={(option) => printUserName(option)}
                  renderInput={(params) => <TextField {...params} label={t(`profile.student.assignTutorFields.tutors`)} />}
                  onChange={(_, tutor: IUser) => {
                    setFieldValue('tutorId', tutor?.userable.id ?? null)
                  }}
                />
                {!values.tutorId && (
                  <FormHelperText error>
                    {touched.tutorId && errors.tutorId}
                  </FormHelperText>
                )}
              </FormControl>
            </DialogContent>
            <DialogActions sx={{ paddingBottom: 2 }}>
              <Button
                color="secondary"
                size="small"
                variant="outlined"
                onClick={onClose}
              >
                {t(`${BUTTONS_TRANSLATE_KEY}.cancel`)}
              </Button>
              <LoadingButton
                loading={isConfirming}
                sx={{ mr: 2 }}
                variant="contained"
                size="small"
                type="submit"
              >
                {t(`${BUTTONS_TRANSLATE_KEY}.confirm`)}
              </LoadingButton>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog >
  );
};
export default AssignTutorModal;
