import { IJob } from 'src/models/job';
import { JobStatuses } from 'src/utils/const';
import PendingVerificationAlert from './Alerts/PendingVerificationAlert';
import EnrolledAlert from './Alerts/EnrolledAlert';
import RejectedAlert from './Alerts/RejectedAlert';
import { IUser } from 'src/models/user';
import { studentIsOnHold } from 'src/utils/userHelpers';

interface IProps {
  student: IUser;
  job: IJob;
  canVerifyWithoutStatusCheck: boolean;
  onReject(): void;
  onVerify(): void;
}

const ReviewJobDescription: React.FC<IProps> = ({
  student,
  job,
  canVerifyWithoutStatusCheck,
  onReject,
  onVerify
}) => {

  if (job.status === JobStatuses.VerifiedBySchool) {
    return (
      <EnrolledAlert
        onReject={onReject}
        readOnly={studentIsOnHold(student)}
      />
    );
  }

  if (job.status === JobStatuses.Rejected) {
    return (
      <RejectedAlert
        job={job}
        onVerify={onVerify}
        readOnly={studentIsOnHold(student)}
      />
    );
  }

  if (job.status === JobStatuses.SubmittedByStudent || canVerifyWithoutStatusCheck) {
    return (
      <PendingVerificationAlert
        onReject={onReject}
        onVerify={onVerify}
        canVerifyWithoutStatusCheck={canVerifyWithoutStatusCheck}
        readOnly={studentIsOnHold(student)}
      />
    );
  }

  return null;
};

export default ReviewJobDescription;
