import { Box, Button, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useToggleVisibility from '../../hooks/useToggleVisibility';
import CreateEditFolderPopup from './CreateEditFolderPopup';
import { FileTreeMode } from './types';

const NoFilesOrFoldersMessage = styled(Box)(
  ({ theme }) => `
  padding: ${theme.spacing(3)} 0 ${theme.spacing(1)} 0;
  display: flex;
  align-items: center;
  flex-flow: column;
  button {
    margin-top: ${theme.spacing(1)}
  }
`
);

interface IProps {
  onCreateFirstFolder(name: string): void;
  canManageTree: boolean;
  mode: FileTreeMode;
}

const FILE_TREE_TRANSLATE_KEY = 'common.fileTree';

const EmptyFileTreeMessage: React.FC<IProps> = ({
  onCreateFirstFolder,
  canManageTree,
  mode
}) => {
  const { t } = useTranslation();

  const isEditable = mode !== FileTreeMode.View;

  const { hide, isVisible, toggle } = useToggleVisibility(false);

  const handleCreate = (folderName: string) => {
    hide();
    onCreateFirstFolder(folderName);
  };

  return (
    <NoFilesOrFoldersMessage>
      <Typography>{t(`${FILE_TREE_TRANSLATE_KEY}.emptyMessage`)}</Typography>
      {canManageTree && isEditable && (
        <Button color="secondary" size="small" variant="text" onClick={toggle}>
          {t(`${FILE_TREE_TRANSLATE_KEY}.createFirstFolder`)}
        </Button>
      )}
      <CreateEditFolderPopup
        isVisible={isVisible}
        onHide={hide}
        onFinish={handleCreate}
      />
    </NoFilesOrFoldersMessage>
  );
};

export default EmptyFileTreeMessage;
