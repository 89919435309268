import { IStudentCertification } from 'src/models/student-certification';
import ApiError from '../../../../../components/ApiError';
import PageLoader from '../../../../../components/PageLoader';
import StudentBlocks from '../../../../student-blocks/components/blocks-list/StudentBlocks';
import useFetchStudentBlocks from '../../../../student-blocks/hooks/useFetchStudentBlocks';
import { IUser } from 'src/models/user';

interface IProps {
  studentId: string;
  student?: IUser;
  certification?: IStudentCertification
}

const StudentBlocksTab: React.FC<IProps> = ({ studentId, student, certification }) => {
  const { studentBlocks, isFetchingStudentBlocks, errorFetchingStudentBlocks } =
    useFetchStudentBlocks(studentId);

  if (isFetchingStudentBlocks) {
    return <PageLoader />;
  }

  if (errorFetchingStudentBlocks) {
    return <ApiError error={errorFetchingStudentBlocks} />;
  }

  return <StudentBlocks studentBlocks={studentBlocks} student={student} studentId={studentId} certification={certification} />;
};

export default StudentBlocksTab;
