import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps
} from '@mui/material';
import React, { useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

enum InputType {
  Password = 'password',
  Text = 'text'
}

const PasswordInput: React.FC<TextFieldProps> = (props: TextFieldProps) => {
  const [inputType, setInputType] = useState(InputType.Password);

  const toggleShowPassword = () => {
    setInputType(
      inputType === InputType.Password ? InputType.Text : InputType.Password
    );
  };

  const StyledIcon = {
    fontSize: '20px',
    color: 'rgba(34, 51, 84, 0.7)'
  };

  return (
    <TextField
      {...props}
      type={inputType}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="Toggle password visibility"
              onClick={toggleShowPassword}
              style={{ borderRadius: '100%' }}
              type="button"
            >
              {inputType === InputType.Password ? (
                <VisibilityOffIcon style={StyledIcon} />
              ) : (
                <VisibilityIcon style={StyledIcon} />
              )}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
};

export default PasswordInput;
