import auth from '../../features/auth/translate/fr';
import certificates from '../../features/certificates/translate/fr';
import schools from '../../features/schools/translate/fr';
import profile from 'src/features/profile/translate/fr';
import companies from '../../features/companies/translate/fr';
import roles from '../../features/acl/translate/fr';
import settings from '../../features/settings/translate/fr';
import cohorts from '../../features/cohorts/translate/fr';
import students from 'src/features/students/translate/fr';
import tasks from 'src/features/tasks/translate/fr';
import dashboard from 'src/features/dashboard/translate/fr';
import notifications from '../../features/notifications/translate/fr';
import blocks from '../../features/blocks/translate/fr';
import studentBlock from 'src/features/student-blocks/translate/fr';
import finalExams from '../../features/final-exams/translate/fr';
import acl from '../../features/acl/translate/fr';
import evaluations from '../../features/evaluations/translate/fr';
import companyEmployees from '../../features/company-employees/translate/fr';

const common = {
  title: 'Learning Certification Platform',
  validation: {
    email: "L'e-mail fourni doit être une adresse valide",
    required: 'Le champs {{field}} est obligatoire',
    requiredLabel: 'Obligatoire',
    min8Characters: 'Le champs {{field}} doit avoir au moins 8 caractères',
    max255Characters:
      'Le champs {{field}} doit avoir au maximum 255 caractères',
    maxNumber:'{{field}} doit être inférieur ou égal à {{value}}',
    oneRoleIsRequired: "L'utilisateur doit avoir au moins un rôle",
    numberType: 'Le champs {{field}} doit être un nombre',
    positiveNumberType: 'Le champs {{field}} doit être un nombre positif',
    passwordField: 'Mot de passe',
    passwordConfirmField: 'Confirmer le mot de passe',
    emailField: 'E-mail',
    phoneField: 'Format +123xxxxxxxxx ou 00123xxxxxxxxx',
    addressField: 'Adresse',
    zipCodeField: 'Code postal',
    countryField: 'Pays',
    cityField: 'Ville',
    firstNameField: 'Prénom',
    lastNameField: 'Nom de famille',
    shortNameField: 'Diminutif',
    yearOfCertification: 'Année de certification',
    longNameField: 'Nom en entier',
    name: 'Nom',
    guardName: 'Nom de garde',
    oldPasswordField: 'Ancien mot de passe',
    newPasswordField: 'Nouveau mot de passe',
    newPasswordConfirmationField: 'Confirmer le nouveau mot de passe',
    cvField: 'CV',
    positionField: 'Poste',
    officePhoneField: 'Téléphone(bureau)',
    nameField: 'Nom',
    companyRegistrationNoField: 'Numéro SIRET',
    date: 'Le champs {{field}} doit être au format date',
    activityField: 'Activité',
    autonomyLevelField: "Niveau d'autonomie",
    departmentMissionField: 'Département de la mission',
    jobMissionField: "Mission de l'emploi",
    daysField: 'jours',
    certificate: 'Certificat',
    cohort: 'Cohorte',
    taskName: 'Nom de la tâche',
    users:
      'Le champs {{field}} doit avoir au minimum un utilisateur sélectionné',
    notificationName: 'Titre de la notification',
    notificationText: 'texte de notification',
    roles: 'Rôle',
    description: 'la description',
    assignTo: 'doit avoir au moins 1 sélectionné affecté à',
    verifyBy: 'doit avoir au moins 1 sélectionné vérifier par',
    visibleTo: 'doit avoir au moins 1 sélectionné visible pour',
    linkedTo: 'lié à',
    competenceRequirement: 'exigence de compétence',
    juryPresidentId: 'président de jurys',
    juryPresidentDate:
      'Le champ date est obligatoire et doit être au format date',
    juryMembers: 'doit avoir au moins 1 membre du jury sélectionné',
    evaluationConfigurationField: `Un élément d'évaluation est requis`,
    feedback: `retour d'information`,
    evaluation: 'évaluation',
    gridItem: `élément de la grille d'évaluation`,
    evaluationConfigurationFieldOptions:
      'Les options déposées 1, options 2 sont obligatoires!',
    evaluationItemOptions:
      'Les options déposées 1, options 2 sont obligatoires!',
    companyIdField: 'Entreprise',
    tutorIdField: 'Tuteur',
    statusField: "Statut de l'entreprise",
    noOfEmployees: "Le nombre d'employés doit être supérieur ou égal à 0",
    knowledgeField: 'Connaissance',
    knowHowField: 'Savoir-faire',
    contributionField: 'contribution',
    objectiveField: 'objectif',
    apiErrorMsg: {
      userVerification:
        "L'invitation est expirée ou invalide, veuillez contacter l'assistance afin qu'elle puisse la renvoyer"
    },
    url: 'Le champ {{field}} doit être url',
    learningPlatformField: "URL de la plateforme d'apprentissage",
    duplicate: "Valeur dupliquée"
  },
  layout: {
    languageSwitcher: 'Sélecteur de langue',
    sidebar: {
      schools: 'Ecoles',
      school: 'Ecole',
      certificates: 'Certificats',
      roles: 'Rôles',
      companies: 'Entreprises',
      cohorts: 'Cohortes',
      assignedCohorts: 'Cohortes associées',
      assignedStudents: 'Etudiants associés',
      tasks: 'Tâches',
      jobDescription: 'Fiche de poste',
      home: 'Accueil {{meta}}',
      evaluations: 'Évaluations',
      finalExams: 'Examen final'
    },
    userMenu: {
      profileSettings: 'Configuration profil',
      organizationSettings: 'Configuration admin',
      profile: 'Profil',
      signOut: 'Déconnexion'
    },
    mailToSupport: 'Contacter le support',
    requiredFieldsAlert: {
      title: "Complétez vos informations personnelles",
      description: "Veuillez compléter vos informations personnelles obligatoires avant d'accéder à la plateforme."
    },
  },
  buttons: {
    cancel: 'Annuler',
    save: 'Sauvegarder',
    edit: 'Editer',
    delete: 'Supprimer',
    addCohort: 'Ajouter une cohorte',
    confirm: 'Confirmer',
    add: 'Ajouter',
    update: 'Mise à jour',
    actions: 'Actions',
    reOrder: 'Réorganiser',
    view: 'Voir',
    back: 'Retour'
  },
  table: {
    emptyMessage: 'Pas de données disponibles',
    actions: {
      edit: 'Modifier',
      delete: 'Supprimer',
      resendInvitation: 'Renvoyer les invitations'
    }
  },
  fileTree: {
    createFirstFolder: 'Créer un premier répertoire',
    createFolder: 'Créer un répertoire',
    emptyMessage: 'Pas de fichiers ni répertoires',
    tooltips: {
      deleteFile: 'Supprimer le fichier',
      createFile: 'Créer un fichier',
      openFile: 'Ouvrir un fichier',
      createFolder: 'Créer un répertoire',
      createSubFolder: 'Créer un sous-répertoire',
      uploadFiles: 'Téléverser fichiers',
      editFolder: 'Editer Répertoire',
      deleteFolder: 'Supprimer le répertoire',
      editRoles: 'Modifier les rôles'
    },
    edit: {
      roleTitle: 'Modifier les rôles'
    },
    editRoles: 'Modifier les rôles',
    roles: {
      label: 'Sélectionner les rôles...',
      placeholder: 'Sélectionner les rôles'
    },
    createFolderModal: {
      label: 'Nom de dossier',
      placeholder: 'Entrez le nom du dossier ici',
      finishButton: 'Finir'
    }
  },
  dragAndDropDocument: {
    dropFilesToUpload:
      'Glisser-déposer les fichiers pour démarrer le téléversement',
    cantUploadTheseFiles: 'Vous ne pouvez pas téléverser ce type de fichiers',
    uploaded: 'Téléversement réussi'
  },
  copy: {
    toBeCopied: 'Copier',
    copied: 'Copié'
  },
  status: {
    comingSoon: {
      titleStatus: 'Statut - À venir',
      title: 'À venir',
      description: `Nous travaillons à l'implémentation des dernières fonctionnalités avant notre lancement !`,
      timesup: `Le temps est écoulé!`,
      email: 'Entrez votre adresse de messagerie ici...',
      notifyMe: 'Prévenez-moi',
      message: `Nous vous enverrons un e-mail une fois notre site Web lancé !`
    },
    maintenance: {
      titleStatus: 'Statut - Maintenance',
      title: 'Le site est actuellement en maintenance',
      description: 'Nous nous excusons pour les désagréments causés'
    },
    status404: {
      titleStatus: 'Statut - 404',
      title: `La page que vous recherchez n'existe pas`,
      description: `C'est à nous, nous avons déplacé le contenu vers une autre page. La recherche ci-dessous devrait vous aider !`,
      search: 'Termes de recherche ici...',
      or: 'OU',
      goto: `Aller à la page d'accueil`
    },
    status500: {
      titleStatus: 'Statut - 500',
      title: 'Une erreur est apparue, merci de réessayer.',
      description:
        "Le serveur a rencontré une erreur interne et n'a pas pu répondre à votre demande",
      refreshNow: 'Merci de rafraîchir la page maintenant',
      goBack: 'Retour'
    }
  },
  auth: {
    validation: {
      termsAndConditions: 'Vous devez accepter les conditions générales',
      wrongCredentials: 'Login ou mot de passe incorrect',
      confirmPassword: 'Les mots de passe ne correspondent pas'
    },
    links: {
      signIn: 'Se connecter',
      lostPassword: 'Mot de passe perdu ?',
      termsAndConditions: 'CGU',
      signInAgain: 'Voulez-vous réessayer ?',
      continueToLogin: 'Poursuivre la connexion',
      newPassword: 'Me renvoyer un mot de passe',
      resetPassword: 'Réinitialiser le mot de passe',
      createAccount: 'Créer un compte'
    },
    label: {
      accept: "J'accepte les",
      email: 'Adresse e-mail',
      password: 'Mot de passe',
      confirmPassword: 'Confirmer le mot de passe',
      signIn:
        'Remplir les champs ci-dessous pour vous connecter à votre compte.',
      recoverEmail:
        "Merci de saisir l'e-mail utilisation lors de l'inscription.",
      recoverPassword: 'Retrouver le mot de passe',
      resetInstruction:
        'Les instructions de réinitialisation de mot de passe ont été envoyées sur votre adresse e-mail',
      checkEmail:
        'Consulter votre adresse e-mail pour les prochaines instructions',
      resetPassword: 'Réinitialiser le mot de passe',
      newPassword: 'Saisir le nouveau mot de passe.',
      signUp: 'Merci de saisir les champs ci-dessous pour créer votre compte',
      firstName: 'Prénom',
      lastName: 'Nom de famille'
    },
    error: {
      notFound: 'Utilisateur inexistant.',
      mailNotSent:
        "L'e-mail de réinitialisation de mot de passe n'a pas pu être envoyé.",
      badCredentials: 'Login ou mot de passe incorrect'
    },
    success: {
      resetPassword: 'Le mot de passe a bien été réinitialisé.'
    }
  },
  schools: {
    common: {
      schoolForm: {
        shortName: {
          label: 'Diminutif nom école',
          placeholder: 'Saisir le diminutif nom école ici...'
        },
        longName: {
          label: "Nom complet de l'école",
          placeholder: "Saisir le nom complet de l'école ici..."
        },
        avatar: {
          label: "Logo de l'école",
          placeholder: "Téléverser le logo de l'école"
        }
      },
      addresses: {
        boxTitle: "Adresse de l'école",
        addAddress: 'Ajouter une école',
        noAddressAdded: 'Aucune adresse ajoutée',
        modalTitle: 'Ajouter une adresse école',
        modalSubtitle:
          'Saisir les champs ci-dessous pour ajouter une adresse école',
        mainAddress: 'Siège social',
        form: {
          address: {
            label: 'Adresse',
            placeholder: "Saisir l'adresse ici"
          },
          city: {
            label: 'Ville',
            placeholder: 'Saisir la ville ici'
          },
          zipCode: {
            label: 'Code postal',
            placeholder: 'Saisir le code postal ici'
          },
          country: {
            label: 'Pays',
            placeholder: 'Saisir le pays ici'
          },
          department: {
            label: 'Départment',
            placeholder: 'Saisir le département ici'
          },
          region: {
            label: 'Région',
            placeholder: 'Saisir la région ici'
          },
          isMain: {
            label: "C'est l'adresse principale"
          }
        },
        delete: {
          modalTitle: "Supprimer l'adresse",
          modalBody:
            "Confirmer la suppression de l'adresse <1>{{name}}</1> de la liste des adresses ?"
        }
      },
      staff: {
        boxTitle: "Personnel de l'école",
        addStaff: "Ajouter / inviter un collaborateur de l'école",
        noStaffInvited: 'Aucun collaborateur invité',
        modalTitle: "Ajouter / inviter un personnel de l'école",
        modalSubtitle:
          "Saisir les champs ci-dessous pour inviter un collaborateur de l'école",
        form: {
          email: {
            label: 'E-mail',
            placeholder: "Saisir l'e-mail ici"
          },
          firstName: {
            label: 'Prénom',
            placeholder: 'Saisir le prénom ici'
          },
          lastName: {
            label: 'Nom de famille',
            placeholder: 'Saisir le nom de famille ici'
          },
          roles: {
            label: 'Rôles',
            placeholder: 'Sélectionner le(s) rôle(s)'
          }
        },
        delete: {
          modalTitle: 'Supprimer le collaborateur',
          modalBody:
            'Confirmez vous la suppression de <1>{{name}}</1> de la liste des collaborateurs ?'
        }
      }
    },
    listAll: {
      htmlTitle: 'Ecoles',
      title: 'Ecoles',
      subtitle: 'Liste des écoles',
      addNewSchool: 'Ajouter une nouvelle école',
      table: {
        columns: {
          school: 'ECOLE',
          createdAt: 'CRÉÉ LE'
        },
        actionTooltips: {
          edit: 'Modifier',
          delete: 'Supprimer'
        }
      },
      delete: {
        successMessage: 'École supprimée',
        errorMessage: "Erreur durant la suppression de l'école",
        modalTitle: "Supprimer l'école",
        modalBody:
          'Confirmer la suppression de <1>{{name}}</1> de la liste des écoles ?'
      }
    },
    create: {
      htmlTitle: 'Ajouter une école',
      title: 'Ajouter une école',
      subtitle: 'Saisir les champs ci-dessous pour ajouter une école',
      backToList: 'Retour aux écoles'
    },
    view: {
      htmlTitle: 'Écoles | {{name}}',
      subtitle: 'Information école détaillée',
      title: 'Information école',
      backToList: 'Retour aux écoles',
      schoolDetails: 'Détails',
      documents: 'Documents',
      students: 'Étudiants'
    },
    ownView: {
      htmlTitle: 'Paramètres certificateur',
      title: 'Paramètres certificateur',
      subtitle: 'Voir / modifier paramètres certificateur'
    }
  },
  input: {
    placeholder: {
      search: "Search"
    }
  }
};

const frJSON = {
  common,
  auth,
  certificates,
  schools,
  profile,
  companies,
  roles,
  settings,
  cohorts,
  students,
  tasks,
  dashboard,
  notifications,
  blocks,
  studentBlock,
  finalExams,
  evaluations,
  acl,
  companyEmployees
};

export default frJSON;
