import { IconButton, styled, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ReactNode } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const FormCard = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'error'
})<{ error?: boolean }>(
  ({ theme, error }) => `
  position: relative;
`
);

const HeaderWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'error'
})<{ error?: boolean }>(
  ({ theme, error }) => `
  padding: ${theme.spacing(3, 4)};
  background-color: ${
    error ? theme.colors.error.lighter : `rgba(85,105,255,0.06)`
  };
  color: ${error ? theme.colors.error.main : theme.colors.alpha.black[50]};
`
);

interface IProps {
  title: string;
  children: ReactNode | ReactNode[];
  error?: boolean;
}

const ArrayFormBoxJob: React.FC<IProps> = ({ title, children, error }) => {
  return (
    <FormCard error={error}>
      <HeaderWrapper error={error}>
        <Typography
          color={error ? 'error' : '#223354'}
          variant="h4"
          fontWeight={'700'}
        >
          {title}
        </Typography>
      </HeaderWrapper>
      {children}
    </FormCard>
  );
};

const ArrayFormBoxList = styled('ul')`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const ArrayFormBoxListItem = styled('li')(
  ({ theme }) => `
  padding: ${theme.spacing(2, 4)};
`
);

const ArrayFormBoxFooter = styled('div')(
  ({ theme }) => `
    display: flex;
   padding: ${theme.spacing(2)};
`
);

const ArrayFormBoxListActions = ({ onEdit, onDelete, canEdit, canDelete }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {canEdit && (
        <IconButton
          aria-label="edit"
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            onEdit();
          }}
          color="primary"
        >
          <EditIcon fontSize="inherit" />
        </IconButton>
      )}
      {canDelete && (
        <IconButton
          aria-label="delete"
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
          color="error"
        >
          <DeleteIcon fontSize="inherit" />
        </IconButton>
      )}
    </Box>
  );
};

ArrayFormBoxListActions.defaultProps = {
  canEdit: true,
  canDelete: true
};

export {
  ArrayFormBoxJob,
  ArrayFormBoxList,
  ArrayFormBoxListItem,
  ArrayFormBoxFooter,
  ArrayFormBoxListActions
};
