import { useQuery } from 'react-query';
import { IApiError } from 'src/utils/axios';
import { fetchStudentCertification, FETCH_STUDENT_CERTIFICATION_KEY } from '../../utils/api';
import { IStudentCertification } from 'src/models/student-certification';

const useFetchStudentCertification = (studentId: string) => {
    const {
        isLoading: isFetchingStudentCertification,
        data: studentCertification
    } = useQuery<IStudentCertification, IApiError>(
        [FETCH_STUDENT_CERTIFICATION_KEY, studentId],
        () => fetchStudentCertification(studentId),
        {
            enabled: !!studentId
        }
    );

    return {
        studentCertification,
        isFetchingStudentCertification
    };
};

export default useFetchStudentCertification;