import { lazy } from 'react';
import SuspenseRouteLoader from '../../components/SuspenseRouteLoader';

const Roles = SuspenseRouteLoader(lazy(() => import('./pages/Roles')));

export const AclRoutes = {
  ListAllRoles: '/roles'
};

const routes = [
  {
    path: AclRoutes.ListAllRoles,
    element: <Roles />
  }
];

export default routes;
