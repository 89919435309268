import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Box } from '@mui/material';

import useTableParams from 'src/hooks/useTableParams';
import useFetchTasks from '../../../tasks/hooks/api/useFetchTasks';
import PageLoader from 'src/components/PageLoader';
import ApiError from 'src/components/ApiError';
import TasksList from './TasksList';
import { TaskStatuses } from '../../../../utils/const';

const TASKS_TRANSLATE_KEY = 'dashboard.tasks';

const LastNotCompletedTasksListView = () => {
  const { t } = useTranslation();

  const { tableParams } = useTableParams({
    perPage: 10
  });

  const { isFirstFetchingTasks, errorFetchingTasks, tasksData } =
    useFetchTasks(tableParams);

  const notCompletedTasks = useMemo(() => {
    if (tasksData) {
      return tasksData.data.filter((task) => task.status !== TaskStatuses.Done);
    }
  }, [tasksData]);

  if (isFirstFetchingTasks) {
    return <PageLoader />;
  }

  if (errorFetchingTasks) {
    return <ApiError error={errorFetchingTasks} />;
  }

  return (
    <Box sx={{ mb: 7 }}>
      <Typography variant="h2" mb={3} component="h2">
        {t(`${TASKS_TRANSLATE_KEY}.pendingTasks`)}
      </Typography>
      <TasksList tasks={notCompletedTasks} />
    </Box>
  );
};

export default LastNotCompletedTasksListView;
