import { useSnackbar } from 'notistack';
import { useMutation, useQueryClient } from 'react-query';
import { ICohort } from 'src/models/cohort';
import { IApiError } from '../../../../utils/axios';
import {
  FETCH_COHORT_KEY,
  updateCohortSchools as updateCohortSchoolsApi,
  UPDATE_COHORT_SCHOOLS_KEY
} from '../../utils/api';

const useUpdateCohortSchools = (cohort: ICohort) => {
  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const { mutate: updateCohortSchools, isLoading: isUpdatingCohortSchools } =
    useMutation(({cohort, schools}:any) => updateCohortSchoolsApi(cohort, schools), {
      mutationKey: UPDATE_COHORT_SCHOOLS_KEY,
      onSuccess: ({ }) => {
        queryClient.invalidateQueries([FETCH_COHORT_KEY, cohort.id]);
      },
      onError: (error: IApiError) => {
        enqueueSnackbar(error.message, {
          variant: 'error'
        });
      }
    });

  return {
    updateCohortSchools,
    isUpdatingCohortSchools
  };
};

export default useUpdateCohortSchools;
