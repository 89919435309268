import { IUser, UserableType } from '../models/user';

export const isStudent = (user: IUser) =>
  user.userableType === UserableType.Student;

export const isSchoolEmployee = (user: IUser) =>
  user.userableType === UserableType.SchoolEmployee;

export const isCertifierEmployee = (user: IUser) =>
  user.userableType === UserableType.CertifierEmployee;

export const isCompanyEmployee = (user: IUser) =>
  user.userableType === UserableType.CompanyEmployee;
