import { lazy } from 'react';
import SuspenseRouteLoader from '../../components/SuspenseRouteLoader';

const Notification = SuspenseRouteLoader(
  lazy(() => import('./pages/Notification'))
);

export const NotificationRoutes = {
  Notification: '/notifications/:id'
};

const routes = [
  {
    path: NotificationRoutes.Notification,
    element: <Notification />
  }
];

export default routes;
