import { lazy } from 'react';
import SuspenseRouteLoader from '../../components/SuspenseRouteLoader';

const Cohorts = SuspenseRouteLoader(lazy(() => import('./pages/Cohorts')));
const CreateCohort = SuspenseRouteLoader(
  lazy(() => import('./pages/CreateCohort'))
);
const Cohort = SuspenseRouteLoader(
  lazy(() => import('./pages/Cohort'))
);
const AssignedCohorts = SuspenseRouteLoader(
  lazy(() => import('./pages/AssignedCohorts'))
);
const AssignedCohort = SuspenseRouteLoader(
  lazy(() => import('./pages/AssignedCohort'))
);

const CohortSchool = SuspenseRouteLoader(
  lazy(() => import('./pages/CohortSchool'))
);

const CohortBlock = SuspenseRouteLoader(
  lazy(() => import('../blocks/pages/Block'))
);

export const CohortRoutes = {
  ListAll: '/cohorts',
  Create: '/cohorts/create',
  View: '/cohorts/:id/view',
  ListAssigned: '/cohorts/assigned',
  ViewAssigned: '/cohorts/assigned/:id',
  ViewCohortBlock: '/cohorts/assigned/:cohortId/blocks/:blockId',
  ViewCohortSchool: '/cohorts/:cohortId/schools/:schoolId'
};

const routes = [
  {
    path: CohortRoutes.ListAll,
    element: <Cohorts />
  },
  {
    path: CohortRoutes.Create,
    element: <CreateCohort />
  },
  {
    path: CohortRoutes.View,
    element: <Cohort />
  },
  {
    path: CohortRoutes.ListAssigned,
    element: <AssignedCohorts />
  },
  {
    path: CohortRoutes.ViewAssigned,
    element: <AssignedCohort />
  },
  {
    path: CohortRoutes.ViewCohortSchool,
    element: <CohortSchool />
  },
  {
    path: CohortRoutes.ViewCohortBlock,
    element: <CohortBlock />
  }
];

export default routes;
