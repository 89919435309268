import { INotification } from '../../../models/notification';
import { IUser } from '../../../models/user';
import { Events } from '../../../utils/events';
import UrlBuilder from '../../../utils/UrlBuilder';

interface INotificationUrl {
  href?: string;
  target?: string;
}

const urlBuilder = (
  notification: INotification,
  user: IUser
): INotificationUrl => {
  const urlBuilder = new UrlBuilder(user);

  switch (notification.certificateNotificationConfiguration?.event) {
    case Events.StudentDocumentsRejected:
    case Events.StudentDocumentsVerified:
    case Events.StudentDocumentsUploaded: {
      return urlBuilder.buildStudentDocumentsRoute({
        studentId: notification.meta.studentId
      });
    }

    case Events.JobDescriptionRejected:
    case Events.JobDescriptionVerified:
    case Events.JobDescriptionVerificationRequested: {
      return urlBuilder.buildStudentJobRoute({
        studentId: notification.meta.studentId
      });
    }

    case Events.CourseDocumentsRejected:
    case Events.CourseDocumentsUploaded:
    case Events.CourseDocumentsVerified: {
      return urlBuilder.buildCourseDocumentsRoute({
        schoolId: notification.meta.schoolId,
        cohortId: notification.meta.cohortId
      });
    }

    case Events.CorrectorAssignedToStudentBlocks: {
      return urlBuilder.buildStudentBlockRoute({
        studentBlockId: notification.meta.studentBlockId,
        studentId: notification.meta.studentId
      });
    }

    case Events.SchoolInvitedToCohort: {
      return urlBuilder.buildCohortRoute({
        cohortId: notification.meta.cohortId,
        schoolId: notification.meta.schoolId
      });
    }

    case Events.TutorAssignedToStudent: {
      return urlBuilder.buildStudentJobRoute({
        studentId: notification.meta.studentId
      });
    }

    case Events.JuryMemberAssigned:
    case Events.JuryPresidentAssigned:
    case Events.FinalExamFailed:
    case Events.FinalExamPassed:
    case Events.FinalExamCompleted: {
      return urlBuilder.buildStudentFinalExamRoute({
        studentId: notification.meta.studentId
      });
    }

    case Events.UploadedFinalExamDocument: {
      return urlBuilder.buildStudentFinalExamRoute({
        studentId: notification.meta.studentId
      });
    }

    case Events.FinalExamMovedToPending: {
      return urlBuilder.buildStudentFinalExamDocumentRoute({
        studentId: notification.meta.studentId
      });
    }

    case Events.EvaluationCreated:
    case Events.EvaluationRejected:
    case Events.EvaluationVerified: {
      return urlBuilder.buildStudentEvaluation({
        studentId: notification.meta.studentId,
        evaluationId: notification.meta.evaluationId
      });
    }

    case Events.StudentBlockSubmitted: {
      return urlBuilder.buildStudentBlockRoute({
        studentId: notification.meta.studentId,
        studentBlockId: notification.meta.studentBlockId
      });
    }

    case Events.StudentAcceptedInvitation:
    case Events.StudentInvitedToCohort: {
      return urlBuilder.buildStudentRoute({
        studentId: notification.meta.studentId
      });
    }
  }
};

export default urlBuilder;
