import { t } from 'i18next';
import { TaskStatuses } from 'src/utils/const';
import Label from '../../../../components/Label';

interface IProps {
  taskStatus: TaskStatuses;
}

const TASKS_TRANSLATE_KEY = 'tasks.listAll';

const Status: React.FC<IProps> = ({ taskStatus }) => {
  if (taskStatus === TaskStatuses.ToDo) {
    return (
      <Label color="warning">
        {t(`${TASKS_TRANSLATE_KEY}.table.status.toDo`)}
      </Label>
    );
  }

  if (taskStatus === TaskStatuses.InProgress) {
    return (
      <Label color="info">
        {t(`${TASKS_TRANSLATE_KEY}.table.status.inProgress`)}
      </Label>
    );
  }

  if (taskStatus === TaskStatuses.Done) {
    return (
      <Label color="success">
        {t(`${TASKS_TRANSLATE_KEY}.table.status.done`)}
      </Label>
    );
  }

  if (taskStatus === TaskStatuses.TerminatedBySystem) {
    return (
      <Label color="secondary">
        {t(`${TASKS_TRANSLATE_KEY}.table.status.terminatedBySystem`)}
      </Label>
    );
  }

  return (
    <Label color="error">
      {t(`${TASKS_TRANSLATE_KEY}.table.status.overdue`)}
    </Label>
  );
};

export default Status;
