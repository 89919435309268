import { lazy } from 'react';
import SuspenseRouteLoader from '../../components/SuspenseRouteLoader';

const OrganizationSettings = SuspenseRouteLoader(
  lazy(() => import('./pages/OrganizationSettings')),
);

export const SettingsRoutes = {
  OrganizationSettings: '/settings/organization'
};

const routes = [
  {
    path: SettingsRoutes.OrganizationSettings,
    element: <OrganizationSettings />
  }
];

export default routes;
