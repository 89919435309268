import { useMemo } from 'react';
import { ICohort } from 'src/models/cohort';
import { ISchool } from '../../../../models/school';

interface IData {
  cohort?: ICohort;
  schools?: ISchool[];
}

const useCohortData = ({ cohort, schools }: IData): ICohort | null => {
  const cohortData = useMemo(() => {
    if (cohort && schools) {
      return { ...cohort, schools };
    }
    return null;
  }, [cohort, schools]);

  return cohortData;
};

export default useCohortData;
