import { useSnackbar } from 'notistack';
import { useMutation, useQueryClient } from 'react-query';
import { IApiError } from '../../../../../utils/axios';
import {
  FETCH_CERTIFICATE_KEY,
  updateCertificate as updateCertificateApi,
  UPDATE_CERTIFICATE_KEY
} from '../../../utils/api';

const useUpdateCertificate = () => {
  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const { mutate: updateCertificate, isLoading: isUpdatingCertificate } =
    useMutation(updateCertificateApi, {
      mutationKey: UPDATE_CERTIFICATE_KEY,
      onSuccess: ({ id }) => {
        queryClient.invalidateQueries([FETCH_CERTIFICATE_KEY, id]);
      },
      onError: (error: IApiError) => {
        enqueueSnackbar(error.message, {
          variant: 'error'
        });
      }
    });

  return {
    updateCertificate,
    isUpdatingCertificate
  };
};

export default useUpdateCertificate;
