import { VerificationStatus } from 'src/utils/const';
import { AnswerType, ItemStatus } from '../../../models/evaluation-configuration';

const evaluations = {
  common: {
    pageHeader: {
      title: 'Create new Evaluation grid',
      subtitle: 'Fill in fields below to create a new Evaluation grid'
    },
    api: {
      success: 'Create evaluation successfully!'
    },
    evaluationCreateForm: {
      name: {
        label: 'Evaluation grid name',
        placeholder: 'Write evaluation grid name...'
      },
      redemption: {
        label: 'Redemption'
      },
      description: {
        label: 'Evaluation grid description',
        placeholder: 'Write evaluation grid description...'
      },
      assignTo: {
        label: 'Assigned as task to',
        placeholder: 'Select'
      },
      verifyBy: {
        label: 'Verify by',
        placeholder: 'Select'
      },
      visibleTo: {
        label: 'After verification, visible to (can read)',
        placeholder: 'Select'
      },
      linkedTo: {
        label: 'Linked to (related to)',
        placeholder: 'Select related',
        finalExamOption: 'Final exam'
      },
      competenceRequirement: {
        label: 'Competence Requirement',
        placeholder: 'Set competence requirement',
        finalExamOption: 'Final exam'
      },
      evaluationConfigurationField: {
        title: 'EVALUATION GRID FIELDS',
        subtitle: 'Fill in fields below to add evaluation configuration fields',
        noItemAdded: 'No item added',
        addItem: 'Add evaluation configuration fields item',
        addOption: '+ Add option',
        gridItem: {
          label: 'Evaluation grid item',
          placeholder: 'New evaluation configuration field'
        },
        isRequired: {
          label: "Justification mandatory"
        },
        itemStatus: {
          label: 'Item status',
          options: {
            [ItemStatus.Competence]: 'Competence',
            [ItemStatus.Criteria]: 'Criteria'
          }
        },
        answerType: {
          label: 'Field type',
          options: {
            [AnswerType.TextAnswer]: 'Text answer',
            [AnswerType.MultiOptions]: 'Multi options'
          }
        },
        option: {
          label: 'Option',
          placeholder: 'Write on option'
        },
        delete: {
          modalTitle: 'Delete evaluation configuration field',
          modalBody:
            'Are you sure you want to delete <1>{{name}}</1> from evalution grid fields?'
        }
      }
    },
    addEvaluationForm: {
      name: {
        label: 'Evaluation',
        placeholder: 'Enter evaluation name'
      },
      feedback: {
        label: 'Feedback',
        placeholder: 'Enter your feedback'
      },
      noPermissions: 'No permissions'
    }
  },
  listAll: {
    title: 'Evaluations',
    createEvaluation: 'Create new Evaluation grid',
    table: {
      columns: {
        name: 'Name',
        visibleTo: 'Visible To',
        assignTo: 'Assigned To',
        verifyBy: 'Verify By',
        linkedTo: 'Linked To',
        evaluationsCount: 'Evaluations Count',
        competenceRequirement: 'Competence Requirement',
        isRedemption: 'Redemption'
      },
      actionTooltips: {
        show: 'Show details',
        delete: 'Delete'
      }
    },
    delete: {
      successMessage: 'Evaluation grid successfully deleted',
      errorMessage: 'Error while deleting evaluation grid',
      modalTitle: 'Delete evaluation grid',
      modalBody: 'Are you sure you want to delete?'
    }
  },
  view: {
    title: 'Evaluation Grid',
    subtitle: 'Evaluation grid details',
    backToList: 'Go back to all evaluation grids'
  },
  studentEvaluations: {
    status: {
      [VerificationStatus.Draft]: 'Draft',
      [VerificationStatus.NotAdded]: 'Not added',
      [VerificationStatus.PendingVerification]: 'Pending verification',
      [VerificationStatus.Rejected]: 'Rejected',
      [VerificationStatus.Verified]: 'Verified'
    },
    table: {
      newColumns: {
        name: 'Evaluation Grid',
        linkedTo: 'Linked to',
        assignedTo: 'Assigned To',
        assignedToIdentifier: 'Assigned role',
        verifiedBy: 'Verified By',
        redemption: 'Redemption',
        status: 'Status'
      },
      newActions: {
        viewEvaluation: 'View evaluation',
        fillEvaluation: 'Fill out evaluation',
        verifyEvaluation: 'Verify evaluation',
        deleteEvaluation: 'Delete evaluation',
        viewAll: 'View All',
      },
      columns: {
        name: 'Evaluation name',
        description: 'Evaluation description',
        status: 'Status',
        redemption: 'Redemption',
        linkedTo: 'Linked to'
      },
      actionTooltips: {
        verify: 'Verify',
        edit: 'Edit',
        add: 'Add'
      }
    },
    viewEvaluation: {
      htmlTitle: 'Student evaluation',
      notPermitted: 'Not permitted',
      notAdded: 'Evaluation not added yet',
      evaluation: 'Evaluation',
      feedback: 'Feedback',
      answerType: 'Answer type',
      infoAlert: {
        rejectButton: 'Reject',
        verifyButton: 'Verify',
        fillRequiredFields: {
          title: 'Fill out evaluation',
          subtitle: 'You must fill out required fields before you can request verification'
        },
        requestVerification: {
          button: 'Request verification',
          title: 'Request verification',
          subtitle: 'Once you are done with populating evaluation, request verification',
          modalTitle: "Send evaluation for verification",
          modalSubtitle: "Are you sure? There are some optional fields missing."
        },
        verified: {
          title: 'Evaluation verified',
          subtitle: 'Evaluation is verified'
        },
        rejected: {
          title: 'Evaluation rejected',
          subtitle:
            'Something is wrong with evaluation and verifier rejected them'
        },
        pendingVerification: {
          title: 'Pending verification',
          subtitle: 'Evaluation is added and sent for verification',
          rejectModalTitle: 'Reject evaluation',
          rejectModalSubtitle: 'Are you sure you want to reject evaluation?',
          verifyModalTitle: 'Verify evaluation',
          verifyModalAlertNotice: 'This action is irreversible, evaluation will be verified.',
          forceRejectDefaultMessage: 'Evaluation was left in draft state.',
        },
        moveToVerified: {
          title: 'Complete evaluation',
          subtitle:
            'Once you are done with evaluation change status to "Verified"',
          button: 'Complete'
        },
        changeStatusModalTitle: 'Change evaluation status',
        changeStatusModalBody:
          'Are you sure you want to change status to <1>{{status}}</1>?',
        changeStatusRejectedModalBody:
          'Are you sure you want to change status to rejected?'
      }
    }
  },
  studentInfo: {
    studentInfoSection: {
      title: 'Student evaluated',
      subtitle: 'View student information'
    },
    name: 'Name',
    email: 'Email',
    status: 'Status'
  },
  assignedTo: {
    assigneeInfo: {
      title: 'Assigned to',
      subtitle: 'View assignee information'
    },
    name: 'Name',
    email: 'Email',
    assigneeRole: 'Assignee role'
  },
  verifiedBy: {
    verifierInfo: {
      title: 'Verified by',
      subtitle: 'View verifier information'
    },
    name: 'Name',
    email: 'Email',
  },
  auditedBy: {
    auditorInfo: {
      title: 'Audited by',
      redemptionTitle: 'Audited by in redemption',
      subtitle: 'View auditor information'
    },
    name: 'Name',
    email: 'Email',
    auditorRecommendation:'Auditor recommendation',
    auditorComment: 'Auditor comment'
  },
  backToList: 'Back to list',
  fields: {
    notFilled: "Some evaluation fields aren't filled!"
  },
  studentEvaluationConfigurations: {
    actions: {
      createEvaluation: 'Create evaluation',
      viewEvaluations: 'View evaluations',
      bulkCreateEvaluations: 'Assign corrector'
    },
    createEvaluationForm: {
      title: 'Create evaluation',
      inputLabels: {
        evaluationConfigurationId: 'Evaluation Grid',
        assignToIdentifier: 'Role',
        userId: 'User',
      }
    },
    bulkCreateEvaluationsForm: {
      title: 'Create evaluations for selected students',
      inputLabels: {
        evaluationConfigurationId: 'Evaluation Grid',
        assignToIdentifier: 'Role',
        userId: 'User',
      }
    }
  }
};

export default evaluations;
