import { useInfiniteQuery } from 'react-query';
import { IApiError } from '../../../../utils/axios';
import { fetchNotifications, FETCH_NOTIFICATIONS } from '../../utils/api';

const SHOW_NOTIFICATIONS_PER_PAGE = 5;

const useFetchNotifications = () => {
  const {
    data: notificationsData,
    error: errorFetchingNotifications,
    hasNextPage: hasMoreNotifications,
    isLoading: isFetchingNotifications,
    fetchNextPage: fetchMoreNotifications,
    isFetchingNextPage: isFetchingNotificationsNextPage
  } = useInfiniteQuery<any, IApiError>(
    FETCH_NOTIFICATIONS,
    ({ pageParam }) =>
      fetchNotifications({
        page: pageParam,
        per_page: SHOW_NOTIFICATIONS_PER_PAGE
      }),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.meta.lastPage > lastPage.meta.currentPage) {
          return lastPage.meta.currentPage + 1;
        }
      }
    }
  );

  return {
    isFetchingNotifications,
    errorFetchingNotifications,
    notificationsData,
    hasMoreNotifications,
    fetchMoreNotifications
  };
};

export default useFetchNotifications;
