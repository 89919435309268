export interface ISort {
  fieldName: string;
  order: Order.Desc | Order.Asc;
}

export enum Order {
  Desc = 'desc',
  Asc = 'asc'
}

export interface IPaginateData<T, M = {}> {
  data: T[];
  meta: {
    currentPage: number;
    perPage: number;
    total: number;
  } & M;
}

export enum Mode {
  View = 'view',
  Edit = 'edit',
  Create = 'create'
}
