import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { IApiError } from '../../../../utils/axios';
import {
  updateUserPassword as updatePasswordApi,
  UPDATE_USER_PASSWORD_KEY
} from '../../utils/api';

const UPDATE_PASSWORD_TRANSLATE_KEY = 'profile.security.update';

const useUpdatePassword = () => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

  const { mutate: updatePassword, isLoading: isUpdatingPassword } = useMutation(
    updatePasswordApi,
    {
      mutationKey: UPDATE_USER_PASSWORD_KEY,
      onSuccess: () => {
        enqueueSnackbar(t(`${UPDATE_PASSWORD_TRANSLATE_KEY}.successMessage`), {
            variant: 'success'
          });      },
      onError: (error: IApiError) => {
        enqueueSnackbar(error.message, {
          variant: 'error'
        });
      }
    }
  );

  return {
    updatePassword,
    isUpdatingPassword
  };
};

export default useUpdatePassword;
