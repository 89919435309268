import { useQuery } from 'react-query';
import { IUser } from 'src/models/user';
import { IApiError } from '../../../../utils/axios';
import { IPaginateData } from '../../../../utils/types';
import {
  fetchStudentRelatedUsersWithRole,
  FETCH_STUDENT_RELATED_USERS_WITH_ROLE
} from '../../utils/api';

const useFetchStudentRelatedUsersWithRole = (
  studentId: string,
  roleId: string,
) => {
  const {
    isLoading: isFirstFetching,
    error: errorFetching,
    data: data,
    isFetching: isFetching,
    refetch
  } = useQuery<IUser[], IApiError>(
    [FETCH_STUDENT_RELATED_USERS_WITH_ROLE, studentId, roleId],
    () => fetchStudentRelatedUsersWithRole(studentId, roleId),
    {
      keepPreviousData: true,
      enabled: false
    }
  );

  return {
    isFirstFetching,
    errorFetching,
    data,
    isFetching,
    refetch
  };
};

export default useFetchStudentRelatedUsersWithRole;
