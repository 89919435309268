import { useQuery } from 'react-query';
import { ICompany } from 'src/models/company';
import { IApiError } from '../../../../utils/axios';
import {
  fetchCompanies,
  FETCH_COMPANIES_KEY
} from '../../utils/api';

const useFetchCompanies = (
  id: string,
  options?: any
) => {
  const {
    isLoading: isFetchingCompanies,
    error: errorFetchingCompanies,
    data: companies
  } = useQuery<ICompany[], IApiError>(
    [FETCH_COMPANIES_KEY, id],
    () => fetchCompanies(id),
    options
  );

  return {
    isFetchingCompanies,
    errorFetchingCompanies,
    companies
  };
};

export default useFetchCompanies;
