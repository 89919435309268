import { useTranslation } from 'react-i18next';
import useAuth from 'src/features/auth/hooks/useAuth';
import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { Mode } from 'src/utils/types';
import { Formik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import * as Yup from 'yup';
import { IUpdateUserPassword } from '../../utils/types';
import SecurityFields from './SecurityFields';

const VALIDATION_TRANSLATE_KEY = 'common.validation';

interface IProps {
  handleEdit(data: IUpdateUserPassword): void;
  isUpdatingPassword: boolean;
  setSecurityLocalMode(Mode: Mode): void;
}

const SecurityDetails: React.FC<IProps> = ({
  handleEdit,
  isUpdatingPassword,
  setSecurityLocalMode
}) => {
  const { t } = useTranslation();
  const { user } = useAuth();

  return (
    <div>
      <Formik<IUpdateUserPassword>
        initialValues={{
          oldPassword: '',
          newPassword: '',
          newPasswordConfirmation: ''
        }}
        onSubmit={handleEdit}
        validationSchema={Yup.object().shape({
          oldPassword: Yup.string().required(
            t(`${VALIDATION_TRANSLATE_KEY}.required`, {
              field: t(`${VALIDATION_TRANSLATE_KEY}.oldPasswordField`)
            })
          ),
          newPassword: Yup.string().required(
            t(`${VALIDATION_TRANSLATE_KEY}.required`, {
              field: t(`${VALIDATION_TRANSLATE_KEY}.newPasswordField`)
            })
          ),
          newPasswordConfirmation: Yup.string()
            .required(
              t(`${VALIDATION_TRANSLATE_KEY}.required`, {
                field: t(
                  `${VALIDATION_TRANSLATE_KEY}.newPasswordConfirmationField`
                )
              })
            )
            .when('newPassword', {
              is: (val) => (val && val.length > 0 ? true : false),
              then: Yup.string().oneOf(
                [Yup.ref('newPassword')],
                t('auth.validation.confirmPassword')
              )
            })
        })}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <SecurityFields />
            <Box display="flex" justifyContent="flex-end" mt={3}>
              <Button
                color="secondary"
                size="medium"
                variant="outlined"
                onClick={() => setSecurityLocalMode(Mode.View)}
              >
                {t('common.buttons.cancel')}
              </Button>
              <LoadingButton
                loading={isUpdatingPassword}
                sx={{
                  ml: 2
                }}
                variant="contained"
                size="medium"
                type="submit"
              >
                {t('common.buttons.save')}
              </LoadingButton>
            </Box>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default SecurityDetails;
