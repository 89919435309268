import { EntityType, RoleName } from 'src/models/role';
import {
  MAP_PERMISSIONS_TYPE_TO_TEXT,
  Permissions
} from '../../../utils/const';

const acl = {
  common: {
    roleForm: {
      name: {
        label: 'Role Name',
        placeholder: 'Write role name here..'
      },
      entityType: {
        label: 'Entity type',
        placeholder: 'Write entity type here..'
      },
      permissions: {
        label: 'Permissions',
        placeholder: 'Select permissions here..'
      }
    },
    validation: {
      name: 'Role name',
      guardName: 'Guard name'
    }
  },
  listAll: {
    htmlTitle: 'Roles',
    title: 'Roles',
    subtitle: 'List all roles',
    addNewRole: 'Add new role',
    table: {
      role: 'ROLE',
      createdAt: 'CREATED AT',
      entityType: 'ENTITY TYPE',
      permissions: 'PERMISSIONS',
      actionTooltips: {
        edit: 'Edit',
        delete: 'Delete'
      }
    },
    name: {
      [RoleName.JuryPresident]: 'Jury President',
      [RoleName.JuryMember]: 'Jury Member',
      [RoleName.Student]: 'Student',
      [RoleName.CompanyHr]: 'Company HR',
      [RoleName.Tutor]: 'Tutor',
      [RoleName.CompanyAdmin]: 'Company Admin',
      [RoleName.SchoolCorrector]: 'School Corrector',
      [RoleName.SchoolReferent]: 'School Referent',
      [RoleName.SchoolAdmin]: 'School Admin',
      [RoleName.CertifierCorrector]: 'Certifier Corrector',
      [RoleName.CertifierAdmin]: 'Certifier Admin',
      [RoleName.OrganisationAdmin]: 'Organisation Admin',
      [RoleName.CertifierAuditor]: 'Certifier Auditor',
      [RoleName.DoubleCorrecteur]:'Double Correcteur',
      evaluationAssignedTo: 'Users evaluation is assigned to'
    },
    entityType: {
      [EntityType.SchoolEmployee]: 'School Employee',
      [EntityType.CertifierEmployee]: 'Certifier Employee',
      [EntityType.CompanyEmployee]: 'Company Employee',
      [EntityType.Student]: 'Student',
      [EntityType.All]: 'All'
    },
    permissions: {
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ManageCertifications]]:
        'Manage Certifications',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ReadCertifications]]:
        'Read Certifications',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ManageCertifierStaff]]:
        'Manage Certifier Staff',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ReadCertifierStaff]]:
        'Read Certifier Staff',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ManageCohorts]]:
        'Manage Cohorts',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ReadCohorts]]: 'Read Cohorts',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ReadAssignedCohorts]]:
        'Read Assigned Cohorts',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ReadAssignedCertifications]]:
        'Read Assigned Certifications',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ManageAssignedCohorts]]:
        'Manage Assigned Cohorts',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ManageCompanies]]:
        'Manage Companies',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ReadCompanies]]:
        'Read Companies',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ReadCompany]]: 'Read Company',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ManageCompanyDocuments]]:
        'Manage Company Documents',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ReadCompanyDocuments]]:
        'Read Company Documents',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ManageCompanyStaff]]:
        'Manage Company Staff',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ReadCompanyStaff]]:
        'Read Company Staff',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ManageCompany]]:
        'Manage Company',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ManageCompanyTasks]]:
        'Manage Company Tasks',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ReadCompanyTasks]]:
        'Read Company Tasks',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ManageLanguages]]:
        'Manage Languages',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ManageRoles]]: 'Manage Roles',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ManageSchoolDocuments]]:
        'Manage School Documents',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ReadSchoolDocuments]]:
        'Read School Documents',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ReadSchool]]: 'Read School',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ManageSchool]]: 'Manage School',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ManageSchoolStaff]]:
        'Manage School Staff',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ReadSchoolStaff]]:
        'Read School Staff',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ManageSchoolTasks]]:
        'Manage School Tasks',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ReadSchoolTasks]]:
        'Read School Tasks',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ManageSchools]]:
        'Manage Schools',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ReadSchools]]: 'Read Schools',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ManageStudentDocuments]]:
        'Manage Student Documents',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ReadStudentDocuments]]:
        'Read Student Documents',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ManageStudentTasks]]:
        'Manage Student Tasks',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ReadStudentTasks]]:
        'Read Student Tasks',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ManageStudents]]:
        'Manage Students',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ReadStudents]]: 'Read Students',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ManageUsers]]: 'Manage Users',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ReadUsers]]: 'Read Users',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ManageFinalExamEvaluation]]:
        'Evaluate Final Exam',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.StudentActions]]:
        'Student Actions',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ReadJob]]: 'Read Job',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ManageJob]]: 'Manage Job',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ManageJobs]]: 'Manage Jobs',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ReadJury]]: 'Read Jury',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ManageJuryPresident]]:
        'Manage Jury President',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ManageJuryMember]]:
        'Manage Jury Members',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ReadStudentBlocks]]:
        'Read Student Blocks',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ManageStudentBlocks]]:
        'Manage Student Blocks',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ManageManualTasks]]:
        'Manage Manual Tasks',
      [MAP_PERMISSIONS_TYPE_TO_TEXT[Permissions.ManageEvaluationVerification]]:
        'Manage Evaluation Verification'
    },
    delete: {
      successMessage: 'Role successfully deleted',
      errorMessage: 'Error while deleting role',
      modalTitle: 'Delete role',
      modalBody:
        'Are you sure you want to delete <1>{{name}}</1> from roles list?'
    },
    update: {
      successMessage: 'Role successfully updated',
      errorMessage: 'Error while updating role',
      modalTitle: 'Update role'
    },
    create: {
      successMessage: 'Role successfully created',
      errorMessage: 'Error while creating role',
      modalTitle: 'Create role'
    }
  },
  fileTree: {
    folderRoleChanged: 'Folder role successfully changed',
    fileRoleChanged: 'File role successfully changed'
  },
  actions: {
    delete: 'Delete',
    edit: 'Edit',
    resend: 'Resend invitations'
  }
};

export default acl;
