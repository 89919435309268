const blocks = {
  optional: 'Optional',
  viewBlock: 'View block',
  numberOfStudents:
    'students are a part of this block',
  noStudents: 'No students added to this block!',
  backToList: 'Back to list',
  block: {
    details: 'Details',
    students: 'Students',
    noStudentBlocks: 'No students found on this block...',
    correctors: {
      label: 'Assign corrector'
    }
  },
  corrector: 'Corrector',
  view: 'View',
  table: {
    column: {
      student: 'Student',
      allAssigned: 'All assigned',
      correctors: 'Correctors',
      tutor: 'Tutor',
      actions: 'Actions'
    },
    missingAssignments: {
      missingTutor: 'Tutor is not assigned',
      evaluationMissing: {
        gridName: "Grid {{name}}",
        roleIdentifier: "Role {{name}} is not assigned"
      }
    }
  }
};

export default blocks;
