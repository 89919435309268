import { useQuery } from 'react-query';
import { IUser } from '../../../models/user';
import { IApiError } from '../../../utils/axios';
import { fetchUser, FETCH_USER_KEY } from '../utils/api';

const useFetchUser = () => {
  const {
    isLoading: isFetchingUser,
    error: errorFetchingUser,
    data: user
  } = useQuery<IUser, IApiError>(FETCH_USER_KEY, () => fetchUser());

  return {
    isFetchingUser,
    errorFetchingUser,
    user
  };
};

export default useFetchUser;
