import { List } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CohortRoutes } from 'src/features/cohorts/routes';
import { TaskRoutes } from 'src/features/tasks/routes';
import SidebarMenuItem from '../AccentSidebarLayout/Sidebar/SidebarMenu/item';
import { MenuWrapper, SubMenuWrapper } from '../components/common';
import { CertificateRoutes } from 'src/features/certificates/routes';
import { SchoolRoutes } from 'src/features/schools/routes';
import { AclRoutes } from 'src/features/acl/routes';

function CertifierLayout() {
	const { t } = useTranslation();

	return (<>
		<MenuWrapper>
			<SubMenuWrapper>
				<List component="div">
					<SidebarMenuItem
						active={true}
						open={true}
						name={t(`common.layout.sidebar.certificates`)}
						link={CertificateRoutes.ListAll}
					/>
					<SidebarMenuItem
						active={true}
						open={true}
						name={t(`common.layout.sidebar.cohorts`)}
						link={CohortRoutes.ListAll}
					/>
					<SidebarMenuItem
						active={true}
						open={true}
						name={t(`common.layout.sidebar.schools`)}
						link={SchoolRoutes.ListAll}
					/>
					<SidebarMenuItem
						active={true}
						open={true}
						name={t(`common.layout.sidebar.roles`)}
						link={AclRoutes.ListAllRoles}
					/>
					<SidebarMenuItem
						active={true}
						open={true}
						name={t(`common.layout.sidebar.tasks`)}
						link={TaskRoutes.Tasks}
					/>
				</List>
			</SubMenuWrapper>
		</MenuWrapper>
	</>);
}

export default CertifierLayout;