import { ITableApiParams } from 'src/hooks/useTableParams';
import { IEvaluation } from 'src/models/evaluation';
import { axiosInt } from 'src/utils/axios';
import { IPaginateData } from 'src/utils/types';
import {
  IEvaluationConfiguration,
  IEvaluationConfigurationField,
  IBaseEvaluationConfiguration,
  IOrderEvaluationConfigurationField
} from '../../../models/evaluation-configuration';
import {
  ICreateUpdateStudentEvaluation,
  ICreateUpdateEvaluationConfigurationForm,
  IFetchEvaluationConfigurationParams,
  ICreateEvaluation,
  IBulkCreateEvaluations
} from './types';
import { IAuditComment } from 'src/models/auditor';
import { VerificationStatus } from 'src/utils/const';

export const FETCH_EVALUATION_CONFIGURATIONS_KEY =
  'fetchEvaluationConfigurations';
export const fetchEvaluationConfigurations = async (
  certificateId: string,
  params: IFetchEvaluationConfigurationParams
): Promise<IPaginateData<IEvaluationConfiguration>> => {
  const response = await axiosInt.get(
    `/certificates/${certificateId}/evaluation-configurations`,
    {
      params
    }
  );
  return response.data;
};

export const FETCH_STUDENT_EVALUATION_CONFIGURATIONS_KEY =
  'fetchStudentEvaluationConfigurations';
export const fetchStudentEvaluationConfigurations = async (
  studentId: string,
  params: IFetchEvaluationConfigurationParams
): Promise<IPaginateData<IBaseEvaluationConfiguration>> => {
  const response = await axiosInt.get(
    `/students/${studentId}/evaluation-configurations`,
    {
      params
    }
  );
  return response.data;
};

export const CREATE_EVALUATION_CONFIGURATION_KEY =
  'createEvaluationConfiguration';
export const createEvaluationConfiguration = async (
  certificateId: string,
  data: ICreateUpdateEvaluationConfigurationForm
): Promise<IEvaluationConfiguration> => {
  const response = await axiosInt.post(
    `/certificates/${certificateId}/evaluation-configurations`,
    data
  );
  return response.data;
};

export const UPDATE_EVALUATION_CONFIGURATION_KEY =
  'updateEvaluationConfiguration';
export const updateEvaluationConfiguration = async (
  certificateId: string,
  evaluationConfigurationId: string,
  data: IEvaluation
): Promise<IEvaluation> => {
  const response = await axiosInt.put(
    `/certificates/${certificateId}/evaluation-configurations/${evaluationConfigurationId}`,
    data
  );

  return response.data;
};

export const DELETE_EVALUATION_CONFIGURATION_KEY =
  'deleteEvaluationConfiguration';
export const deleteEvaluationConfiguration = async (
  certificateId: string,
  evaluationId: string
): Promise<void> => {
  const response = await axiosInt.delete(
    `/certificates/${certificateId}/evaluation-configurations/${evaluationId}`
  );

  return response.data;
};

export const FETCH_EVALUATION_CONFIGURATION_KEY =
  'fetchEvaluationConfiguration';
export const fetchEvaluationConfiguration = async ({
  certificateId,
  evaluationConfigurationId
}): Promise<IEvaluationConfiguration> => {
  const response = await axiosInt.get(
    `/certificates/${certificateId}/evaluation-configurations/${evaluationConfigurationId}`
  );

  return response.data;
};

export const CREATE_EVALUATION_FIELDS_CONFIGURATION_KEY =
  'createEvaluationFieldsConfiguration';
export const createEvaluationFieldsConfiguration = async (
  data: IEvaluationConfigurationField
): Promise<IEvaluationConfigurationField> => {
  const response = await axiosInt.post(
    `/evaluation-configurations/${data.id}/fields`,
    data
  );

  return response.data;
};

export const UPDATE_EVALUATION_FIELDS_CONFIGURATION_KEY =
  'updateEvaluationFieldsConfiguration';
export const updateEvaluationFieldsConfiguration = async (
  evaluationId: string,
  evalCofingiurationFieldId: string,
  data: IEvaluationConfigurationField
): Promise<IEvaluationConfigurationField> => {
  const response = await axiosInt.put(
    `/evaluation-configurations/${evaluationId}/fields/${evalCofingiurationFieldId}`,
    data
  );

  return response.data;
};

export const DELETE_EVALUATION_FIELDS_CONFIGURATION_KEY =
  'deleteEvaluationFieldsConfiguration';
export const deleteEvaluationFieldsConfiguration = async (
  evaluationId: string,
  evalCofingiurationFieldId: string
): Promise<void> => {
  const response = await axiosInt.delete(
    `/evaluation-configurations/${evaluationId}/fields/${evalCofingiurationFieldId}`
  );

  return response.data;
};

export const ORDER_EVALUATION_CONFIGURATION_FIELDS_KEY =
  'orderEvaluationConfigurationFields';
export const orderEvaluationConfigurationFields = async (data: {
  evaluationConfigurationId: string,
  fields: IOrderEvaluationConfigurationField[]
}): Promise<IEvaluationConfigurationField> => {
  const response = await axiosInt.put(
    `/evaluation-configurations/${data.evaluationConfigurationId}/fields-order`,
    { data: data.fields }
  );

  return response.data;
};

export const FETCH_STUDENT_EVALUATIONS_KEY = 'fetchStudentEvaluations';
export const fetchStudentEvaluations = async (
  studentId: string,
  params?: ITableApiParams
) => {
  const response = await axiosInt.get(`/students/${studentId}/evaluations`, {
    params
  });

  return response.data;
};

export const FETCH_STUDENT_EVALUATION_KEY = 'fetchStudentEvaluation';
export const fetchStudentEvaluation = async (
  studentId: string,
  evaluationId: string
) => {
  const response = await axiosInt.get(
    `/students/${studentId}/evaluations/${evaluationId}`
  );

  return response.data;
};

export const FILL_STUDENT_EVALUATION_KEY = 'fillStudentEvaluation';
export const fillStudentEvaluation = async (
  studentId: string,
  evaluationId: string,
  data: ICreateUpdateStudentEvaluation
): Promise<IEvaluation> => {
  const response = await axiosInt.put(
    `/students/${studentId}/evaluations/${evaluationId}/fill`,
    data
  );
  return response.data;
};

export const VERIFY_STUDENT_EVALUATION_KEY = 'verifyStudentEvaluation';
export const verifyStudentEvaluation = async (
  studentId: string,
  evaluationId: string,
  status: VerificationStatus,
  message: string
) => {
  const response = await axiosInt.put(
    `/students/${studentId}/evaluations/${evaluationId}/verify`,
    { status, message }
  );
  return response.data;
};

export const REQUEST_EVALUATION_VERIFICATION_STATUS_KEY =
  'requestStudentEvaluationVerification';
export const requestStudentEvaluationVerification = async (
  studentId: string,
  evaluationId: string
) => {
  const response = await axiosInt.put(
    `/students/${studentId}/evaluations/${evaluationId}/request-verification`
  );
  return response.data;
};

export const UPDATE_STUDENT_EVALUATION_KEY = 'updateStudentEvaluation';
export const updateStudentEvaluation = async (
  studentId: string,
  evaluationId: string,
  data: ICreateUpdateStudentEvaluation
) => {
  const response = await axiosInt.put(
    `/students/${studentId}/evaluations/${evaluationId}`,
    data
  );
  return response.data;
};

export const DELETE_STUDENT_EVALUATION_KEY = 'deleteStudentEvaluation';
export const deleteStudentEvaluation = async (
  studentId: string,
  evaluationId: string
): Promise<void> => {
  const response = await axiosInt.delete(
    `/students/${studentId}/evaluations/${evaluationId}`
  );

  return response.data;
};

export const FETCH_STUDENT_RELATED_USERS_WITH_ROLE = 'fetchStudentRelatedUsersWithRole';
export const fetchStudentRelatedUsersWithRole = async (
  studentId: string,
  roleId: string
) => {
  const response = await axiosInt.get(
    `/students/${studentId}/related-role-users/${roleId}`,
  );
  return response.data;
};

export const CREATE_STUDENT_EVALUATION_KEY = 'createStudentEvaluation';
export const createStudentEvaluation = async (
  studentId: string,
  data: ICreateEvaluation
): Promise<IEvaluation> => {
  const response = await axiosInt.post(
    `/students/${studentId}/evaluations`,
    data
  );
  return response.data;
};

export const BULK_CREATE_STUDENT_EVALUATIONS_KEY = 'bulkCreateStudentEvaluations';
export const bulkCreateStudentEvaluations = async (
  evaluationConfigurationId: string,
  data: IBulkCreateEvaluations
): Promise<IEvaluation> => {
  const response = await axiosInt.post(
    `/evaluation-configurations/${evaluationConfigurationId}/evaluations/bulk-create`,
    data
  );
  return response.data;
};

export const UPDATE_STUDENT_EVALUATION_AUDITOR_COMMENT_KEY = 'updateStudentEvaluationAuditorComment';
export const updateStudentEvaluationAuditorComment = async (
  studentId: string,
  evaluationId: string,
  data: IAuditComment
) => {
  const response = await axiosInt.put(
    `/students/${studentId}/evaluations/${evaluationId}/audit`,
    data
  );
  return response.data;
};