import { useSnackbar } from 'notistack';
import { useMutation, useQueryClient } from 'react-query';
import { IJobRequiredKnowledge } from 'src/models/job-required-knowledge';
import { IApiError } from '../../../../../utils/axios';
import {
  addJobRequiredKnowledge as addJobRequiredKnowledgeApi,
  ADD_JOB_REQUIRED_KNOWLEDGE_KEY,
  FETCH_STUDENT_JOB_KEY
} from '../../../utils/api';

const useAddJobRequiredKnowledge = () => {
  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const {
    mutate: addJobRequiredKnowledge,
    isLoading: isAddingJobRequiredKnowledge
  } = useMutation(addJobRequiredKnowledgeApi, {
    mutationKey: ADD_JOB_REQUIRED_KNOWLEDGE_KEY,
    onSuccess: (data: IJobRequiredKnowledge) => {
      queryClient.invalidateQueries([FETCH_STUDENT_JOB_KEY]);
    },
    onError: (error: IApiError) => {
      enqueueSnackbar(error.message, {
        variant: 'error'
      });
    }
  });

  return {
    addJobRequiredKnowledge,
    isAddingJobRequiredKnowledge
  };
};

export default useAddJobRequiredKnowledge;
