import { List } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SidebarMenuItem from '../AccentSidebarLayout/Sidebar/SidebarMenu/item';
import { MenuWrapper, SubMenuWrapper } from '../components/common';
import useAuth from 'src/features/auth/hooks/useAuth';
import useFetchStudentJob from 'src/features/profile/hooks/api/job/useFetchStudentJob';
import { StudentRoutes } from 'src/features/students/routes';

function StudentLayout() {
  const { t } = useTranslation();

  const { user } = useAuth();

  const { job } = useFetchStudentJob(user.userableId, {
    enabled: true
  });

  return (<>
    <MenuWrapper>
      <SubMenuWrapper>
        <List component="div">
          {job && (
            <SidebarMenuItem
              active={true}
              open={true}
              name={t(`common.layout.sidebar.jobDescription`)}
              link={StudentRoutes.Job}
            />
          )}
          <SidebarMenuItem
            active={true}
            open={true}
            name={t(`common.layout.sidebar.school`)}
            link={StudentRoutes.School}
          />
          <SidebarMenuItem
            active={true}
            open={true}
            name={t(`common.layout.sidebar.evaluations`)}
            link={StudentRoutes.Evaluations}
          />
        </List>
      </SubMenuWrapper>
    </MenuWrapper>
  </>);
}

export default StudentLayout;