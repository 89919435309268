import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { IApiError } from 'src/utils/axios';
import { IStudentDocument } from '../../../../models/student-document';
import {
  deleteStudentDocument,
  DELETE_STUDENT_DOCUMENT_KEY
} from '../../utils/api';

const DELETE_STUDENT_DOCUMENT_TRANSLATE_KEY = 'students.documents';

const useDeleteStudentDocument = () => {
  const { t } = useTranslation();
  const [documentToBeDeleted, setDocumentToBeDeleted] =
    useState<IStudentDocument>();

  const { enqueueSnackbar } = useSnackbar();

  const { mutate: deleteDocument, isLoading: isDeletingDocument } = useMutation(
    deleteStudentDocument,
    {
      mutationKey: DELETE_STUDENT_DOCUMENT_KEY,
      onError: (error: IApiError) => {
        enqueueSnackbar(
          error.message,
          {
            variant: 'error'
          }
        );
      },
      onSuccess: () => {
        setDocumentToBeDeleted(null);
      }
    }
  );

  return {
    documentToBeDeleted,
    isDeletingDocument,
    deleteDocument,
    setDocumentToBeDeleted
  };
};

export default useDeleteStudentDocument;
