import { useMutation } from 'react-query';
import { axiosInt, IApiError } from '../../utils/axios';

export const UPLOAD_AVATAR_KEY = 'uploadAvatar';
export const uploadAvatarApi = async (file: File, url: string) => {
  const formData = new FormData();
  formData.set('image', file);

  const response = await axiosInt.post(url, formData);

  return response.data;
};

const useUploadAvatar = () => {
  const {
    mutate: uploadAvatar,
    isLoading: isUploading,
    isSuccess: isUploadedSuccessfully,
    error: uploadingError,
    data: uploadingData
  } = useMutation<{ image: string }, IApiError, { file: File; url: string }>(
    (data) => uploadAvatarApi(data.file, data.url),
    {
      mutationKey: UPLOAD_AVATAR_KEY
    }
  );

  return {
    isUploading,
    isUploadedSuccessfully,
    uploadingError,
    uploadingData,
    uploadAvatar
  };
};

export default useUploadAvatar;
