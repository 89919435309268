import { FinalExamStatus } from '../../../models/final-exam';

const fr = {
  common: {
    status: {
      [FinalExamStatus.Completed]: 'Finalisé',
      [FinalExamStatus.Created]: 'Créé',
      [FinalExamStatus.Failed]: 'Échec',
      [FinalExamStatus.Passed]: 'Succès',
      [FinalExamStatus.Pending]: 'En cours'
    }
  },
  all: {
    title: 'Examen final',
    subtitle: 'Info examen final',
    htmlTitle: 'Examen final',
    table: {
      columns: {
        studentName: "Nom de l'étudiant",
        date: "Date de l'examen",
        status: 'Statut',
        createdAt: 'Créé le'
      }
    }
  },
  view: {
    htmlTitle: 'Examen Final | {{name}}',
    title: 'Examen Final - {{name}}',
    subtitle: 'Info examen Final'
  },
  evaluation: {
    cardTitle: 'Évaluation Examen Final',
    submitEvaluation: "Soumettre l'évaluation",
    status: {
      passed: 'Succès',
      failed: 'Échec',
      label: 'Statut'
    },
    cardStudentTitle: 'Examen final',
    dateAt: 'à'
  },
  status: {
    passedTitle: 'Examen final réussi le : {{date}}',
    passedDescription: 'Description',
    failedTitle: "Échec de l'examen final le : {{date}}",
    failedDescriptionWithDate:
      "Un rattrapage de l'examen est prévu le : {{date}}",
    failedDescriptionWithoutDate:
      "La date de rattrapage de l'examen sera bientôt disponible"
  },
  tab: {
    completedFinalExams: 'Examen final finalisé',
    scheduledFinalExams: 'Examen final programmé',
    pendingFinalExam: 'Examen final en cours'
  }
};

export default fr;
