import {
  Grid,
  Paper,
  Typography,
  Button,
  Divider,
  CardContent
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import { Mode } from 'src/utils/types';
import { useState } from 'react';
import useUpdatePassword from '../../hooks/api/useUpdatePassword';
import { IUpdateUserPassword } from '../../utils/types';
import SecurityDetails from './SecurityDetails';

const PROFILE_SECURITY_TRANSLATE_KEY = 'profile.security';

const SecuritySection = () => {
  const { t } = useTranslation();
  const [securityLocalMode, setSecurityLocalMode] = useState(Mode.View);
  const { isUpdatingPassword, updatePassword } = useUpdatePassword();

  const handleEdit = (data: Partial<IUpdateUserPassword>) => {
    updatePassword(
      { ...data },
      {
        onSuccess: () => {
          setSecurityLocalMode(Mode.View);
        }
      }
    );
  };
  return (
    <div>
      <Grid item xs={12}>
        <Grid container spacing-xs={3} sx={{ width: '100%' }}>
          <Grid item xs={12}>
            <Paper
              elevation={1}
              square={false}
              sx={{ borderRadius: '8px', padding: 0 }}
            >
              <Box
                sx={{
                  padding: '24px 32px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  position: 'relative'
                }}
              >
                <Box>
                  <Typography variant="h4" gutterBottom>
                    {t(
                      `${PROFILE_SECURITY_TRANSLATE_KEY}.securitySection.title`
                    )}
                  </Typography>
                  <Typography variant="subtitle2">
                    {t(
                      `${PROFILE_SECURITY_TRANSLATE_KEY}.securitySection.subtitle`
                    )}
                  </Typography>
                </Box>
                <Button
                  variant="outlined"
                  size="large"
                  onClick={() => setSecurityLocalMode(Mode.Edit)}
                  color="primary"
                >
                  {t(`${PROFILE_SECURITY_TRANSLATE_KEY}.securitySection.edit`)}
                </Button>
              </Box>
              {securityLocalMode === Mode.Edit && (
                <div>
                  <Divider />
                  <CardContent sx={{ padding: '36px', paddingBottom: '24px' }}>
                    <SecurityDetails
                      handleEdit={handleEdit}
                      isUpdatingPassword={isUpdatingPassword}
                      setSecurityLocalMode={setSecurityLocalMode}
                    />
                  </CardContent>
                </div>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default SecuritySection;
