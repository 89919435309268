import { useContext } from 'react';
import {
  Box,
  alpha,
  Stack,
  lighten,
  Divider,
  IconButton,
  Tooltip,
  styled,
  useTheme,
  Typography
} from '@mui/material';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { SidebarContext } from 'src/contexts/SidebarContext';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import HeaderButtons from './Buttons';
import HeaderUserbox from './Userbox';
import useAuth from 'src/features/auth/hooks/useAuth';
import { UserableType } from 'src/models/user';
import { useStudentInfo } from '../../../features/students/hooks/api/useFetchStudentInfo';
import Logo from 'src/components/LogoSign';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
    padding: ${theme.spacing(0, 2)};
    
    color: ${theme.header.textColor};
    background-color: ${theme.header.background};
    backdrop-filter: blur(3px);
    box-shadow: ${theme.header.boxShadow};

    display: flex;
    align-items: center;
    justify-content: space-between;

    z-index: 10;
`
);

const HeaderTitle = styled(Typography)`
  span {
    position: relative;
    color: #000c57;
    &:before {
      content: '';
      position: absolute;
      top: calc(100% + 4px);
      width: 22px;
      height: 4px;
      border-radius: 6px;
      background-color: #1a75ff;
    }
  }
`;

function Header({ id, sx = {} }) {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const theme = useTheme();

  const { user } = useAuth();

  const { studentInfo } = useStudentInfo();

  return (
    <HeaderWrapper
      id={id}
      sx={{
        ...sx
      }}
    >
      <Logo />
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        alignItems="center"
        spacing={2}
      >
        {user.userableType === UserableType.Student && studentInfo && (
          <HeaderTitle>
            <span>{studentInfo?.cohort?.name}</span> |{' '}
            {studentInfo?.school?.shortName}
          </HeaderTitle>
        )}
      </Stack>
      <Box display="flex" alignItems="center">
        <HeaderButtons />
        <HeaderUserbox />
        <Box
          component="span"
          sx={{
            ml: 2,
            display: { lg: 'none', xs: 'inline-block' }
          }}
        >
          <Tooltip arrow title="Toggle Menu">
            <IconButton color="primary" onClick={toggleSidebar}>
              {!sidebarToggle ? (
                <MenuTwoToneIcon fontSize="small" />
              ) : (
                <CloseTwoToneIcon fontSize="small" />
              )}
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
