import { useQuery } from 'react-query';
import { IAssignedCohort } from 'src/features/blocks/utils/types';
import { IApiError } from '../../../../utils/axios';
import { fetchAssignedCohort, FETCH_ASSIGNED_COHORT_KEY } from '../../utils/api';

const useFetchAssignedCohort = (schoolId: string, cohortId: string) => {
  const {
    isLoading: isFetchingCohort,
    error: errorFetchingCohort,
    data: cohort
  } = useQuery<IAssignedCohort, IApiError>([FETCH_ASSIGNED_COHORT_KEY, schoolId, cohortId], () =>
    fetchAssignedCohort(schoolId, cohortId)
  );

  return {
    isFetchingCohort,
    errorFetchingCohort,
    cohort
  };
};

export default useFetchAssignedCohort;
