import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';
import { IApiError } from '../../../../utils/axios';
import {
  updateStudentFlow as updateStudentFlowApi,
  UPDATE_STUDENT_FLOW_KEY
} from '../../utils/api';

const useUpdateStudentFlow = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: updateStudentFlow, isLoading: isUpdatingStudentFlow } = useMutation(
    ({ studentId, flow, message }: any) => updateStudentFlowApi(studentId, flow, message),
    {
      mutationKey: UPDATE_STUDENT_FLOW_KEY,
      onError: (error: IApiError) => {
        enqueueSnackbar(error.message, {
          variant: 'error'
        });
      }
    }
  );

  return {
    updateStudentFlow,
    isUpdatingStudentFlow
  };
};

export default useUpdateStudentFlow;
