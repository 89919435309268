import { useState } from "react";
import { IUser } from "src/models/user";
import { IStudentBlock } from "src/models/student-block";
import * as Yup from 'yup';
import {
    Paper,
    Box,
    Typography,
    Divider,
    CardContent,
    Grid,
    DialogActions,
    Button
} from "@mui/material";
import { RoleName } from "src/models/role";
import useHaveRole from "src/hooks/useHaveRole";
import useAuth from "src/features/auth/hooks/useAuth";
import LoadingButton from '@mui/lab/LoadingButton';
import { StyledEditButton } from "src/features/evaluations/components/common/StyledComponents";
import EditIcon from '@mui/icons-material/Edit';
import InfoItem from 'src/features/profile/components/profile/InfoItem';
import { Formik } from 'formik';
import { useTranslation } from "react-i18next";
import { printUserName, studentIsInRedemption, studentIsOnHold } from "src/utils/userHelpers";
import useUpdateGlobalAuditorRecommendation from "../hooks/useUpdateGlobalAuditorRecommendation";
import { IAudit } from "src/models/auditor";
import GlobalAuditorRecommendationFormFields from "./GlobalAuditorRecommendationFormFields";
import Label from "src/components/Label";
import { StudentCertificationStatus } from "src/utils/const";

enum StudentBlockCertificationStatusMissing {
    Missing = 'missing'
}

const MAP_COLOR_TO_STUDENT_STATUS = {
    [StudentCertificationStatus.PartialDiploma]: 'warning',
    [StudentCertificationStatus.FullDiploma]: 'success',
    [StudentCertificationStatus.NoDiploma]: 'error',
};

interface IProps {
    title: string;
    studentId: string;
    auditor: IUser | null;
    auditorComment: string | null;
    auditorRecommendation: string | null;
    readOnly: boolean
}

const GlobalAuditorRecommendationSection: React.FC<IProps> = ({
    title,
    studentId,
    auditor,
    auditorComment,
    auditorRecommendation,
    readOnly
}) => {

    const isOrgAdmin = useHaveRole(RoleName.OrganisationAdmin);
    const isAuditor = useHaveRole(RoleName.CertifierAuditor);
    const auth = useAuth()

    const isAssignedAuditor = () => {
        return auditor?.id === auth.user.id
    }

    const { t } = useTranslation();

    const [isAuditorEditing, setIsAuditorEditing] = useState<boolean>(false);

    const { updateGlobalAuditorRecommendation, isUpdatingGlobalAuditorRecommendation } = useUpdateGlobalAuditorRecommendation();


    const handleEdit = (data: IAudit) => {
        updateGlobalAuditorRecommendation(
            { studentId, data },
            {
                onSuccess: () => {
                    setIsAuditorEditing((prevState) => !prevState)
                }
            }
        );
    };

    return (

        <Paper elevation={3} sx={{ marginBottom: 2 }}>
            <Box sx={{ margin: '24px 24px 10px 24px', position: 'relative' }}>
                <Typography variant="h4" gutterBottom sx={{ fontWeight: 700 }}>
                    {title}
                </Typography>
                <Typography
                    variant="subtitle2"
                    sx={{ fontWeight: 400, fontSize: '15px' }}
                >
                    {t(`studentBlock.auditor.auditorRecommendationSubtitle`)}
                </Typography>
                {(isAssignedAuditor() && isAuditor || isOrgAdmin) && !isAuditorEditing && <StyledEditButton
                    disabled={!!readOnly}
                    sx={{ top: 0, right: 0 }}
                    size="small"
                    onClick={() => setIsAuditorEditing((prevState) => !prevState)}
                    color="primary"
                >
                    <EditIcon fontSize="small" />
                </StyledEditButton>}
            </Box>
            <Divider />
            <CardContent>
                <Grid
                    container
                    sx={{
                        display: 'flex',
                        boxSizing: 'border-box',
                        flexFlow: 'flex-wrap',
                        width: '100%'
                    }}
                >
                    <InfoItem
                        property={t(`evaluations.auditedBy.name`)}
                        value={printUserName(auditor)}
                    />
                    <InfoItem
                        property={t(t(`evaluations.auditedBy.email`))}
                        value={auditor?.email}
                    />
                    <InfoItem property={t(t(`studentBlock.auditor.auditorRecommendation`))}                    >
                        {auditorRecommendation ?
                            <Label color={MAP_COLOR_TO_STUDENT_STATUS[auditorRecommendation]}>
                                {t(`studentBlock.auditor.auditorRecommendationOptions.${auditorRecommendation}`)}
                            </Label> :
                            <Typography style={{ fontWeight: 'bold' }}>-</Typography>}
                    </InfoItem>
                    <InfoItem
                        property={t(t(`studentBlock.auditor.auditorRecommendationComment`))}
                        value={auditorComment}
                    />
                </Grid>
                {isAuditorEditing &&
                    <Grid item xs={12} sm={8} md={9} sx={{ marginLeft: 'auto' }}>
                        <Formik<IAudit>
                            initialValues={{
                                auditorComment: auditorComment || '',
                                auditorRecommendation: auditorRecommendation || ''
                            }}
                            onSubmit={handleEdit}
                            validationSchema={Yup.object().shape({
                                auditorComment: Yup.string()
                            })}
                        >
                            {({ handleSubmit, }) => (
                                <form onSubmit={handleSubmit}>
                                    <GlobalAuditorRecommendationFormFields />
                                    <DialogActions sx={{ padding: '8px 0' }}>
                                        <Button
                                            color="secondary"
                                            size="medium"
                                            variant="outlined"
                                            onClick={() => setIsAuditorEditing((prevState) => !prevState)}
                                        >
                                            {t('common.buttons.cancel')}
                                        </Button>
                                        <LoadingButton
                                            loading={isUpdatingGlobalAuditorRecommendation}
                                            sx={{
                                                ml: 2
                                            }}
                                            variant="contained"
                                            size="medium"
                                            type="submit"
                                        >
                                            {t('common.buttons.save')}
                                        </LoadingButton>
                                    </DialogActions>
                                </form>
                            )}
                        </Formik>
                    </Grid>}
            </CardContent>
        </Paper>
    )
}

export default GlobalAuditorRecommendationSection;