const companies = {
  common: {
    companyForm: {
      name: {
        label: 'Company Name',
        placeholder: 'Write company name here...'
      },
      shortName: {
        label: 'Company Short Name',
        placeholder: 'Write company short name here...'
      },
      longName: {
        label: 'Company long name',
        placeholder: 'Write company long name here...'
      },
      companyRegistrationNo: {
        label: 'Company registration Number',
        placeholder: 'Write company registration number here...'
      },
      status: {
        label: 'Company status',
        placeholder: 'Select company status here...'
      },
      industry: {
        label: 'Company main activity',
        placeholder: 'Write company main activity here...'
      },
      type: {
        label: 'Company type',
        placeholder: 'Write company type here...'
      },
      capital: {
        label: 'Company capital',
        placeholder: 'Write company capital here...'
      },
      capitalType: {
        label: 'Company capital type',
        placeholder: 'Write company capital type here...'
      },
      noOfEmployees: {
        label: 'Number of employees',
        placeholder: 'Write number of employees here...'
      },
      companyLogo: {
        label: 'Company Logo',
        placeholder: 'Upload company logo'
      },
      companyStatus: {
        active: 'Active',
        inActive: 'Inactive'
      }
    },
    addresses: {
      boxTitle: "COMPANY'S ADDRESSES",
      noAddressAdded: 'No address added',
      addAddress: 'Add company address',
      mainAddress: 'Headquarter office',
      modalTitle: 'Add company address',
      modalSubtitle: 'Fill in fields below to add company address',
      form: {
        address: {
          label: 'Address',
          placeholder: 'Write address here'
        },
        city: {
          label: 'City',
          placeholder: 'Write city here'
        },
        zipCode: {
          label: 'ZIP code',
          placeholder: 'Write zip code here'
        },
        country: {
          label: 'Country',
          placeholder: 'Write country here'
        },
        isMain: {
          label: 'Headquarter office'
        }
      }
    },
    staff: {
      boxTitle: "COMPANY'S STAFF",
      noStaffInvited: 'No staff invited',
      addStaff: 'Invite company staff',
      form: {
        email: {
          label: 'Email',
          placeholder: 'Write email here'
        },
        firstName: {
          label: 'First Name',
          placeholder: 'Write first name here'
        },
        lastName: {
          label: 'Last Name',
          placeholder: 'Write last name here'
        },
        roles: {
          label: 'Roles',
          placeholder: 'Select roles'
        }
      },
      modalTitle: 'Company employee',
      modalSubtitle: 'Enter employee information',
      delete: {
        modalTitle: 'Delete employee',
        modalBody:
          'Are you sure you want to delete <1>{{name}}</1> from employee list?'
      }
    }
  },
  listAll: {
    htmlTitle: 'Companies',
    title: 'Companies',
    subtitle: 'List all companies',
    addNewCompany: 'Add new company',
    table: {
      columns: {
        company: 'COMPANY',
        createdAt: 'CREATED AT',
        status: 'STATUS',
        companyEmployeeCount: 'Employees Count'
      },
      actionTooltips: {
        edit: 'Edit',
        delete: 'Delete'
      },
      status: {
        active: 'Active',
        inActive: 'Inactive'
      }
    },
    delete: {
      modalTitle: 'Delete company',
      modalBody:
        'Are you sure you want to delete <1>{{name}}</1> from companies?',
      successMessage: 'Company successfully deleted',
      errorMessage: 'Error while deleting company'
    },
    importCompaniesWithEmployees: 'Import from CSV',
    importCompaniesWithEmployeesModal: {
      modalTitle: 'Import Tutors from CSV',
      modalSubtitle: 'Please upload CSV file to import Tutors data',
      uploadButton: `Upload CSV & Drag'n'drop or click to add from computer`,
      download: {
        text1: '* Please download the sample',
        text2: 'and enter the real data',
        file: 'CSV-FILE'
      },
      infoMessage: `Saving {{numberOfTutors}} Tutors may take a while, once you click save
      you can safely navigate away.`,
      successMessage:
        'Tutor import from CSV file has been successfully submitted for processing.',
      errorMessage: ' E-mails have already been used. Some of them are: ',
      invalidData: 'Data provided is in invalid format.',
      invalidCsvData: 'Invalid CSV. Please download and use example file',
      table: {
        columns: {
          firstName: 'First name',
          lastName: 'Last name',
          email: 'E-mail',
          companyName: 'Company Name',
        },
        loadInfo: {
          text1: '...and',
          text2: 'more Tutors successfully loaded.'
        }
      }
    },
    search: {
      label: 'Search',
      placeholder: 'Search'
    }
  },
  create: {
    htmlTitle: 'Add new company',
    title: 'Add new company',
    subtitle: 'Fill in fields below to add a new company',
    backToList: 'Go back to all companies'
  },
  view: {
    htmlTitle: 'Companies | {{name}}',
    subtitle: 'Company information details',
    title: 'Company information',
    backToList: 'Go back to all companies'
  },
  ownView: {
    htmlTitle: 'Organization settings',
    title: 'Organization settings',
    subtitle: 'See and edit organization settings'
  }
};

export default companies;
