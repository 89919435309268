import { Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { IUpdateUserPassword } from '../../utils/types';
import PasswordInput from 'src/features/auth/components/PasswordInput';

const FORM_BASE_KEY = 'profile.common.securityForm';

const SecurityDetails = () => {
  const {
    setFieldTouched,
    setFieldValue,
    touched,
    errors,
    handleBlur,
    handleChange,
    values,
    initialValues
  } = useFormikContext<IUpdateUserPassword>();

  const { t } = useTranslation();

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <PasswordInput
            error={Boolean(touched.oldPassword && errors.oldPassword)}
            helperText={touched.oldPassword && errors.oldPassword}
            label={t(`${FORM_BASE_KEY}.oldPassword.label`)}
            placeholder={t(`${FORM_BASE_KEY}.oldPassword.placeholder`)}
            name="oldPassword"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.oldPassword}
            sx={{ mt: 0 }}
            fullWidth
            variant="outlined"
            margin="normal"
          />
          <PasswordInput
            error={Boolean(touched.newPassword && errors.newPassword)}
            helperText={touched.newPassword && errors.newPassword}
            label={t(`${FORM_BASE_KEY}.newPassword.label`)}
            placeholder={t(`${FORM_BASE_KEY}.newPassword.placeholder`)}
            name="newPassword"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.newPassword}
            fullWidth
            variant="outlined"
            margin="normal"
          />
          <PasswordInput
            error={Boolean(
              touched.newPasswordConfirmation && errors.newPasswordConfirmation
            )}
            helperText={
              touched.newPasswordConfirmation && errors.newPasswordConfirmation
            }
            label={t(`${FORM_BASE_KEY}.newPasswordConfirmation.label`)}
            placeholder={t(
              `${FORM_BASE_KEY}.newPasswordConfirmation.placeholder`
            )}
            name="newPasswordConfirmation"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.newPasswordConfirmation}
            fullWidth
            variant="outlined"
            margin="normal"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default SecurityDetails;
