import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Tab } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Confirm from '../../../components/Confirm';
import PageLoader from '../../../components/PageLoader';
import useTab from '../../../hooks/useTab';
import useToggleVisibility from '../../../hooks/useToggleVisibility';
import { ProfileUpdateUrls } from '../../../utils/const';
import useAuth from '../../auth/hooks/useAuth';
import useFetchUser from '../../auth/hooks/useFetchUser';
import { TAB_PANEL_CSS } from '../../cohorts/pages/AssignedCohort';
import StudentDocumentsStatusAlert from '../../students/components/profile/StudentDocumentsStatusAlert';
import useTaskOverdue from '../../tasks/hooks/api/useTaskOverdue';
import { TaskCodes } from '../../tasks/utils/types';
import AccountInfoSection from '../components/profile/AccountInfoSection';
import SecuritySection from '../components/profile/SecuritySection';
import StudentMultiDocumentsSection from '../components/profile/Student/StudentDocuments/StudentMultiDocumentsSection';
import StudentPersonalInfoSection from '../components/profile/Student/StudentPersonalInfoSection';
import useUpdateStudentDocuments from '../hooks/api/useUpdateStudentDocuments';
import { Tabs } from './Profile';
import StudentFlowAlert from 'src/features/students/components/student/StudentFlowAlert';
import { useSearchParams } from 'react-router-dom';
import { Mode } from 'src/utils/types';

const STUDENT_TRANSLATE_BASE_KEY = 'profile.student';

const StudentProfile = () => {
  const [searchParams] = useSearchParams({ mode: Mode.View });
  const mode = searchParams.get('mode') as Mode;

  const [canRequestVerification, setCanRequestVerification] = useState(false);

  const {
    isVisible: isVisibleRequestVerificationModal,
    toggle: toggleRequestVerificationModal
  } = useToggleVisibility(false);

  const { user, updateProfile } = useAuth();

  const { user: userProfile, isFetchingUser } = useFetchUser();

  const { t } = useTranslation();

  const { isTaskOverdue, task, isTaskExpired, isFetchingTask } = useTaskOverdue(
    {
      code: TaskCodes.UploadStudentDocuments,
      meta: {
        studentId: user.userableId
      }
    }
  );

  const { tab, setTab } = useTab(Tabs.UserInfo);

  const handleTabChange = (_: React.SyntheticEvent, newValue: Tabs) => {
    setTab(newValue);
  };

  const { updateDocuments, isUpdatingDocuments } = useUpdateStudentDocuments();

  const handleRequestVerification = () => {
    updateDocuments(
      { data: {}, url: ProfileUpdateUrls.StudentRequestVerification },
      {
        onSuccess: (data) => {
          updateProfile(data);
          toggleRequestVerificationModal();
        }
      }
    );
  };

  if (isFetchingTask || isFetchingUser) {
    return <PageLoader />;
  }

  return (
    <React.Fragment>
      <TabContext value={tab}>
        <Box>
          <TabList onChange={handleTabChange} sx={{ mr: 1, mb: 1 }}>
            <Tab label={t(`profile.accountInfo.title`)} value={Tabs.UserInfo} />
            <Tab
              label={t(`profile.student.uploadDocuments.title`)}
              value={Tabs.Documents}
            />
            <Tab
              label={t(`profile.security.sectionTitle`)}
              value={Tabs.Security}
            />
          </TabList>
        </Box>
        <div>
          <TabPanel value={Tabs.UserInfo} style={TAB_PANEL_CSS}>
            <StudentFlowAlert
              student={user}
              canChangeStatus={false}
              setStudentFlow={() => { }}
            />
            <AccountInfoSection
              user={user}
              url={ProfileUpdateUrls.Student}
              isProfile={true}
              canManage={true}
              mode={mode}
            />
            <StudentPersonalInfoSection
              user={user}
              url={ProfileUpdateUrls.Student}
              isProfile={true}
              canManage={true}
              mode={mode}
            />
          </TabPanel>
          <TabPanel value={Tabs.Documents} style={TAB_PANEL_CSS}>
            <StudentDocumentsStatusAlert
              student={userProfile}
              task={task}
              isTaskOverdue={isTaskOverdue}
              canRequestVerification={canRequestVerification}
              onRequestVerification={toggleRequestVerificationModal}
              isTaskExpired={isTaskExpired}
            />

            <StudentMultiDocumentsSection
              user={userProfile}
              requestVerificationUrl={
                ProfileUpdateUrls.StudentRequestVerification
              }
              uploadUrl={ProfileUpdateUrls.StudentUploadDocument}
              deleteUrl={ProfileUpdateUrls.StudentDeleteDocument}
              isProfile={true}
              canManage={!isTaskExpired}
              canRequestVerification={canRequestVerification}
              setCanRequestVerification={setCanRequestVerification}
            />
          </TabPanel>
        </div>
        <TabPanel value={Tabs.Security} style={TAB_PANEL_CSS}>
          <SecuritySection />
        </TabPanel>
      </TabContext>
      <Confirm
        title={t(
          `${STUDENT_TRANSLATE_BASE_KEY}.uploadDocuments.documentsAlert.requestVerificationModalTitle`
        )}
        onClose={() => toggleRequestVerificationModal()}
        onConfirm={() => handleRequestVerification()}
        open={!!isVisibleRequestVerificationModal}
        isConfirming={isUpdatingDocuments}
      >
        {t(
          `${STUDENT_TRANSLATE_BASE_KEY}.uploadDocuments.documentsAlert.requestVerificationModalText`
        )}
      </Confirm>
    </React.Fragment>
  );
};

export default StudentProfile;
