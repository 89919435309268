import { useTranslation } from 'react-i18next';
import { Grid, Button } from '@mui/material';
import { Box } from '@mui/system';
import { Mode } from 'src/utils/types';
import { Formik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import * as Yup from 'yup';
import { IUpdateStudentPersonalInfo } from '../../../utils/types';
import StudentPersonalInfoFields from './StudentPersonalInfoFields';
import { IUser } from 'src/models/user';
import useFetchFileUrl from '../../../../../components/FileTree/useFetchFileUrl';
import InfoItem from '../InfoItem';
import { DateFormat, formatDate } from 'src/utils/dates';
import Status from '../../common/Status';
import useAuth from 'src/features/auth/hooks/useAuth';
import { isStudent } from 'src/utils/userTypes';

const PROFILE_STUDENT_PERSONAL_INFO_TRANSLATE_KEY = 'profile.professionalInfo';
const VALIDATION_TRANSLATE_KEY = 'common.validation';

interface IProps {
  handleEdit(data: IUpdateStudentPersonalInfo): void;
  personalInfoLocalMode: Mode;
  isUpdatingPersonalInfo: boolean;
  setPersonalInfoLocalMode(Mode: Mode): void;
  user: IUser;
}

const StudentPersonalInfoDetails: React.FC<IProps> = ({
  handleEdit,
  personalInfoLocalMode,
  isUpdatingPersonalInfo,
  setPersonalInfoLocalMode,
  user
}) => {
  const { user: auth } = useAuth()
  const isAuthStudent = isStudent(auth);

  const { t } = useTranslation();
  const { fetchFileUrl } = useFetchFileUrl();

  const handleRedirectToFile = (cv: string) => {
    fetchFileUrl(cv, {
      onSuccess: ({ url }) => {
        window.open(url, '_blank');
      }
    });
  };




  if (personalInfoLocalMode === Mode.View) {
    return (
      <Grid
        container
        sx={{
          display: 'flex',
          boxSizing: 'border-box',
          flexFlow: 'flex-wrap',
          width: '100%'
        }}
      >
        <InfoItem property={t(`${PROFILE_STUDENT_PERSONAL_INFO_TRANSLATE_KEY}.studentStatus`)}        >
          <Status studentStatus={t(`profile.professionalInfo.studentStatuses.${user.userable.studentStatus}`)} />
        </InfoItem>
        <InfoItem
          property={t(`${PROFILE_STUDENT_PERSONAL_INFO_TRANSLATE_KEY}.group`)}
          value={user.userable.group}
        />


        <InfoItem
          property={t(`${PROFILE_STUDENT_PERSONAL_INFO_TRANSLATE_KEY}.dateOfBirth`)}
          value={user.userable.dateOfBirth ? formatDate(user.userable.dateOfBirth, DateFormat.Date) : ''}
        />
        <InfoItem
          property={t(`${PROFILE_STUDENT_PERSONAL_INFO_TRANSLATE_KEY}.placeOfBirth`)}
          value={user.userable.placeOfBirth}
        />
        <InfoItem
          property={t(`${PROFILE_STUDENT_PERSONAL_INFO_TRANSLATE_KEY}.postalCodeOfBirth`)}
          value={user.userable.postalCodeOfBirth}
        />
        <InfoItem
          property={t(`${PROFILE_STUDENT_PERSONAL_INFO_TRANSLATE_KEY}.countryOfBirth`)}
          value={user.userable.countryOfBirth}
        />


        <InfoItem
          property={t(`${PROFILE_STUDENT_PERSONAL_INFO_TRANSLATE_KEY}.addressOfResidence`)}
          value={user.userable.addressOfResidence}
        />
        <InfoItem
          property={t(`${PROFILE_STUDENT_PERSONAL_INFO_TRANSLATE_KEY}.placeOfResidence`)}
          value={user.userable.placeOfResidence}
        />
        <InfoItem
          property={t(`${PROFILE_STUDENT_PERSONAL_INFO_TRANSLATE_KEY}.postalCodeOfResidence`)}
          value={user.userable.postalCodeOfResidence}
        />
        <InfoItem
          property={t(`${PROFILE_STUDENT_PERSONAL_INFO_TRANSLATE_KEY}.countryOfResidence`)}
          value={user.userable.countryOfResidence}
        />


        <InfoItem
          property={t(`${PROFILE_STUDENT_PERSONAL_INFO_TRANSLATE_KEY}.contractType`)}
          value={user.userable.contractType ? t(`profile.professionalInfo.contractTypeOptions.${user.userable.contractType}`) : ''}
        />
        <InfoItem
          property={t(`${PROFILE_STUDENT_PERSONAL_INFO_TRANSLATE_KEY}.lastDiploma`)}
          value={user.userable.lastDiploma}
        />
        <InfoItem
          property={t(`${PROFILE_STUDENT_PERSONAL_INFO_TRANSLATE_KEY}.levelOfLastDiploma`)}
          value={user.userable.levelOfLastDiploma ? t(`profile.professionalInfo.levelOfLastDiplomaOptions.${user.userable.levelOfLastDiploma}`) : ''}
        />
      </Grid>
    );
  }



  return (
    <div>
      <Formik<IUpdateStudentPersonalInfo>
        initialValues={{
          group: user.userable.group ?? '',
          dateOfBirth: user.userable.dateOfBirth ? new Date(user.userable.dateOfBirth) : '',
          placeOfBirth: user.userable.placeOfBirth ?? '',
          postalCodeOfBirth: user.userable.postalCodeOfBirth ?? '',
          countryOfBirth: user.userable.countryOfBirth ?? '',
          addressOfResidence: user.userable.addressOfResidence ?? '',
          placeOfResidence: user.userable.placeOfResidence ?? '',
          postalCodeOfResidence: user.userable.postalCodeOfResidence ?? '',
          countryOfResidence: user.userable.countryOfResidence ?? '',
          contractType: user.userable.contractType ?? '',
          lastDiploma: user.userable.lastDiploma ?? '',
          levelOfLastDiploma: user.userable.levelOfLastDiploma ?? '',
        }}
        onSubmit={handleEdit}
        validationSchema={Yup.object().shape(
          isAuthStudent ? {
            group: Yup.string().required(t(`${VALIDATION_TRANSLATE_KEY}.requiredLabel`)),
            dateOfBirth: Yup.string().required(t(`${VALIDATION_TRANSLATE_KEY}.requiredLabel`)),
            placeOfBirth: Yup.string().required(t(`${VALIDATION_TRANSLATE_KEY}.requiredLabel`)),
            postalCodeOfBirth: Yup.string().required(t(`${VALIDATION_TRANSLATE_KEY}.requiredLabel`)),
            countryOfBirth: Yup.string().required(t(`${VALIDATION_TRANSLATE_KEY}.requiredLabel`)),
            addressOfResidence: Yup.string().required(t(`${VALIDATION_TRANSLATE_KEY}.requiredLabel`)),
            placeOfResidence: Yup.string().required(t(`${VALIDATION_TRANSLATE_KEY}.requiredLabel`)),
            postalCodeOfResidence: Yup.string().required(t(`${VALIDATION_TRANSLATE_KEY}.requiredLabel`)),
            countryOfResidence: Yup.string().required(t(`${VALIDATION_TRANSLATE_KEY}.requiredLabel`)),
            contractType: Yup.string().required(t(`${VALIDATION_TRANSLATE_KEY}.requiredLabel`)),
            lastDiploma: Yup.string().required(t(`${VALIDATION_TRANSLATE_KEY}.requiredLabel`)),
            levelOfLastDiploma: Yup.string().required(t(`${VALIDATION_TRANSLATE_KEY}.requiredLabel`)),
          } : {
            group: Yup.string().nullable(),
            dateOfBirth: Yup.string().nullable(),
            placeOfBirth: Yup.string().nullable(),
            postalCodeOfBirth: Yup.string().nullable(),
            countryOfBirth: Yup.string().nullable(),
            addressOfResidence: Yup.string().nullable(),
            placeOfResidence: Yup.string().nullable(),
            postalCodeOfResidence: Yup.string().nullable(),
            countryOfResidence: Yup.string().nullable(),
            contractType: Yup.string().nullable(),
            lastDiploma: Yup.string().nullable(),
            levelOfLastDiploma: Yup.string().nullable(),
          }
        )}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <StudentPersonalInfoFields />
            <Box display="flex" justifyContent="flex-end" mt={3}>
              <Button
                color="secondary"
                size="medium"
                variant="outlined"
                onClick={() => setPersonalInfoLocalMode(Mode.View)}
              >
                {t('common.buttons.cancel')}
              </Button>
              <LoadingButton
                loading={isUpdatingPersonalInfo}
                sx={{
                  ml: 2
                }}
                variant="contained"
                size="medium"
                type="submit"
              >
                {t('common.buttons.save')}
              </LoadingButton>
            </Box>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default StudentPersonalInfoDetails;
