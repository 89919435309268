import { IFile } from 'src/models/file';
import { IStudentBlock } from 'src/models/student-block';
import { IAudit } from 'src/models/auditor';
import { IJuryDecision } from 'src/models/jury';
import { axiosInt } from 'src/utils/axios';

export const FETCH_STUDENT_BLOCKS_KEY = 'fetchStudentBlocks';
export const fetchStudentBlocks = async (
  studentId: string
): Promise<IStudentBlock[]> => {
  const response = await axiosInt.get(`/students/${studentId}/student-blocks/`);

  return response.data;
};

export const FETCH_STUDENT_BLOCK_KEY = 'fetchStudentBlock';
export const fetchStudentBlock = async (
  studentId: string,
  blockId: string
): Promise<IStudentBlock> => {
  const response = await axiosInt.get(
    `/students/${studentId}/student-blocks/${blockId}`
  );

  return response.data;
};

export const SUBMIT_STUDENT_BLOCK_DELIVERABLES_KEY =
  'submitStudentBlockDeliverables';
export const submitStudentBlockDeliverables = async ({
  studentId,
  blockId
}: {
  studentId: string;
  blockId: string;
}): Promise<IStudentBlock> => {
  const response = await axiosInt.put(
    `/students/${studentId}/student-blocks/${blockId}/submit-deliverables`
  );

  return response.data;
};

export const DELETE_STUDENT_BLOCK_DELIVERABLE_KEY =
  'deleteStudentBlockDeliverable';
export const deleteStudentBlockDeliverable = async (
  studentId: string,
  studentBlockDeliverableId: string
): Promise<IStudentBlock> => {
  const response = await axiosInt.delete(
    `/students/${studentId}/deliverables/${studentBlockDeliverableId}`
  );
  return response.data;
};

export const UPLOAD_EVALUATION_KEY = 'uploadEvaluation';
export const uploadEvaluation = async (
  file: File,
  studentId: string,
  studentBlockId
): Promise<IFile> => {
  const formData = new FormData();
  formData.set('file', file);
  formData.set('studentBlockId', studentBlockId);

  const response = await axiosInt.post(`students/${studentId}/evaluations`, formData);
  return response.data;
};

export const DELETE_STUDENT_BLOCK_EVALUATION_KEY =
  'deleteStudentBlockEvaluation';
export const deleteStudentBlockEvaluation = async (
  studentId: string,
  studentBlockEvaluationId: string
): Promise<IStudentBlock> => {
  const response = await axiosInt.delete(
    `/students/${studentId}/evaluations/${studentBlockEvaluationId}`
  );
  return response.data;
};

export const SUBMIT_STUDENT_BLOCK_EVALUATION_KEY =
  'submitStudentBlockEvaluation';
export const submitStudentBlockEvaluation = async ({
  studentId,
  studentBlockId
}: {
  studentId: string;
  studentBlockId: string;
}): Promise<IStudentBlock> => {
  const response = await axiosInt.put(
    `/students/${studentId}/student-blocks/${studentBlockId}/submit-evaluations`
  );

  return response.data;
};

export const UPDATE_STUDENT_BLOCK_AUDITOR_EVALUATION_KEY = 'updateStudentBlockAuditorEvaluation';
export const updateStudentBlockAuditorEvaluation = async (
  studentId: string,
  blockId: string,
  data: IAudit
) => {
  const response = await axiosInt.put(
    `/students/${studentId}/student-blocks/${blockId}/audit`,
    data
  );
  return response.data;
};

export const UPDATE_GLOBAL_AUDITOR_RECOMMENDATION_KEY = 'updateGlobalAuditorRecommendation';
export const updateGlobalAuditorRecommendation = async (
  studentId: string,
  data: IAudit
) => {
  const response = await axiosInt.put(
    `/students/${studentId}/student-certification/audit`,
    data
  );
  return response.data;
};

export const UPDATE_JURY_DECISION_KEY = 'updateJuryDecision';
export const updateJuryDecision = async (
  studentId: string,
  data: IJuryDecision
) => {
  const response = await axiosInt.put(
    `/students/${studentId}/student-certification/jury-decision`,
    data
  );
  return response.data;
};