import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import PageSubtitle from '../../../../components/PageSubtitle';
import PageTitle from '../../../../components/PageTitle';
import PageTitleWrapper from '../../../../components/PageTitleWrapper';
import { CohortRoutes } from '../../routes';
import { ICohort } from 'src/models/cohort';
import { IAssignedCohort } from 'src/features/blocks/utils/types';

const VIEW_ASSIGNED_COHORT_TRANSLATE_KEY = 'cohorts.assigned.view';

interface IProps {
  cohort: IAssignedCohort;
}

const PageHeader: React.FC<IProps> = ({ cohort }) => {
  const { t } = useTranslation();

  return (
    <PageTitleWrapper>
      <div>
        <PageTitle>
          {t(`${VIEW_ASSIGNED_COHORT_TRANSLATE_KEY}.title`, {
            name: cohort.name
          })}
        </PageTitle>
        <PageSubtitle>
          {t(`${VIEW_ASSIGNED_COHORT_TRANSLATE_KEY}.subtitle`)}
        </PageSubtitle>
      </div>
      <div>
        <Button
          variant="contained"
          size="medium"
          to={CohortRoutes.ListAssigned}
          component={Link}
          sx={{ mb: { xs: '10px' } }}
        >
          <ArrowBackIcon />
          {t(`${VIEW_ASSIGNED_COHORT_TRANSLATE_KEY}.backToList`)}
        </Button>
      </div>
    </PageTitleWrapper>
  );
};

export default PageHeader;
