import { lazy } from 'react';
import SuspenseRouteLoader from '../../components/SuspenseRouteLoader';

const CertifierEmployee = SuspenseRouteLoader(
  lazy(() => import('../profile/pages/CertifierEmployee'))
);

export const CertifierEmployeeRoutes = {
  CertifierEmployee: '/certifier-employees/:certifierId'
}

const routes = [
  {
    path: CertifierEmployeeRoutes.CertifierEmployee,
    element: <CertifierEmployee />
  }
];

export default routes;
