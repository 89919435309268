import { useSnackbar } from 'notistack';
import { useMutation, useQueryClient } from 'react-query';
import { IApiError } from '../../../../../utils/axios';
import {
  updateJobStatus as updateJobStatusApi,
  UPDATE_JOB_STATUS_KEY,
  FETCH_STUDENT_JOB_KEY,
  FETCH_STUDENT_KEY
} from '../../../utils/api';

const useUpdateJobStatus = (studentId: string, jobId: string) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { mutate: updateJobStatus, isLoading: isUpdatingJobStatus } =
    useMutation(
      ({ status, message }: any) =>
        updateJobStatusApi(status, studentId, jobId, message),
      {
        mutationKey: UPDATE_JOB_STATUS_KEY,
        onSuccess: () => {
          queryClient.invalidateQueries([FETCH_STUDENT_JOB_KEY]);
          queryClient.invalidateQueries([FETCH_STUDENT_KEY]);
        },
        onError: (error: IApiError) => {
          enqueueSnackbar(error.message, {
            variant: 'error'
          });
        }
      }
    );

  return {
    updateJobStatus,
    isUpdatingJobStatus
  };
};

export default useUpdateJobStatus;
