import { useSnackbar } from 'notistack';
import { useMutation, useQueryClient } from 'react-query';
import { IJobRequiredKnowledge } from 'src/models/job-required-knowledge';
import { IApiError } from '../../../../../utils/axios';
import {
  FETCH_STUDENT_JOB_KEY,
  updateJobRequiredKnowledge as updateJobRequiredKnowledgeApi,
  UPDATE_JOB_REQUIRED_KNOWLEDGE_KEY
} from '../../../utils/api';

const useUpdateJobRequiredKnowledge = () => {
  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const {
    mutate: updateJobRequiredKnowledge,
    isLoading: isUpdatingJobRequiredKnowledge
  } = useMutation(updateJobRequiredKnowledgeApi, {
    mutationKey: UPDATE_JOB_REQUIRED_KNOWLEDGE_KEY,
    onSuccess: (data: IJobRequiredKnowledge) => {
      queryClient.invalidateQueries([FETCH_STUDENT_JOB_KEY]);
    },
    onError: (error: IApiError) => {
      enqueueSnackbar(error.message, {
        variant: 'error'
      });
    }
  });

  return {
    updateJobRequiredKnowledge,
    isUpdatingJobRequiredKnowledge
  };
};

export default useUpdateJobRequiredKnowledge;
