import { VerificationStatus } from 'src/utils/const';
import { AnswerType, ItemStatus } from '../../../models/evaluation-configuration';

const evaluations = {
  common: {
    pageHeader: {
      title: `Créer une nouvelle grille d'évaluation`,
      subtitle: `Remplir les champs ci-dessous pour créer une nouvelle grille d'évaluation`
    },
    api: {
      success: `Grille d'évaluation créée`
    },
    evaluationCreateForm: {
      name: {
        label: `Nom de la grille d'évaluation`,
        placeholder: 'Saisir le nom de la grille...'
      },
      description: {
        label: `Description de la grille d'évaluation`,
        placeholder: 'Saisir la description...'
      },
      assignTo: {
        label: 'Affecter comme tâche à',
        placeholder: 'Sélectionner'
      },
      verifyBy: {
        label: 'Vérifié par',
        placeholder: 'Sélectionner'
      },
      visibleTo: {
        label: 'Après vérification, visible à (droits de lecture)',
        placeholder: 'Sélectionner'
      },
      linkedTo: {
        label: 'Lié à (en lien avec)',
        placeholder: 'Sélectionner la relation',
        finalExamOption: 'Examen final'
      },
      competenceRequirement: {
        label: 'Exigence de compétence',
        placeholder: 'Fixer des exigences en matière de compétences',
        finalExamOption: 'Examen final'
      },
      evaluationConfigurationField: {
        title: `CHAMPS DE LA GRILLE D'EVALUATION`,
        subtitle: `Saisir les champs ci-dessous pour ajouter des champs de configuration de l'évaluation`,
        noItemAdded: 'Aucun item ajouté',
        addItem: `Ajouter des items d'évaluation`,
        addOption: '+ Ajouter option',
        gridItem: {
          label: `Item de grille d'évaluation`,
          placeholder: `Nouveau champs d'item de configuration`
        },
        isRequired: {
          label: "Justification obligatoire"
        },
        itemStatus: {
          label: "Statut de l'item",
          options: {
            [ItemStatus.Competence]: 'Compétence',
            [ItemStatus.Criteria]: 'Critère'
          }
        },
        answerType: {
          label: 'Type de champs',
          options: {
            [AnswerType.TextAnswer]: 'Champs Texte',
            [AnswerType.MultiOptions]: 'Multi options'
          }
        },
        option: {
          label: 'Option',
          placeholder: 'Saisir une option'
        },
        delete: {
          modalTitle: `Supprimer le champs de configuration d'évaluation`,
          modalBody: `Confirmer la suppression de <1>{{name}}</1> des champs de la grille d'évaluation?`
        }
      }
    },
    addEvaluationForm: {
      name: {
        label: 'Évaluation',
        placeholder: "Saisir l'indice de notation"
      },
      feedback: {
        label: 'Commentaires',
        placeholder: 'Saisir votre commentaire'
      },
      noPermissions: 'Aucune permission'
    }
  },
  listAll: {
    title: 'Évaluations',
    createEvaluation: `Créer une nouvelle grille d'évaluation`,
    table: {
      columns: {
        name: 'Nom',
        visibleTo: 'Visible à',
        assignTo: 'Affecté à',
        verifyBy: 'Vérifié par',
        linkedTo: 'Lié à',
        evaluationsCount: `Nombre d'évaluations`,
        competenceRequirement: 'Exigence de compétence',
        isRedemption: 'Rattrapage'
      },
      actionTooltips: {
        show: 'Détails',
        delete: 'Supprimer'
      }
    },
    delete: {
      successMessage: `Grille d'évaluation supprimée`,
      errorMessage: `Erreur lors de la suppression de la grille d'évaluation`,
      modalTitle: `Supprimer la grille d'évaluation`,
      modalBody: `Confirmer la suppression?`
    }
  },
  view: {
    title: "Grille d'évaluation",
    subtitle: `Détails de la grille d'évaluation`,
    backToList: "Retour aux grilles d'évaluation"
  },
  studentEvaluations: {
    status: {
      [VerificationStatus.Draft]: 'Brouillon',
      [VerificationStatus.NotAdded]: 'Non ajouté',
      [VerificationStatus.PendingVerification]: 'Vérification en attente',
      [VerificationStatus.Rejected]: 'Rejetée',
      [VerificationStatus.Verified]: 'Vérifiée'
    },
    table: {
      newColumns: {
        name: "Grille d'évaluation",
        linkedTo: 'Lié à',
        assignedTo: 'Attribué à',
        assignedToIdentifier: 'Rôle attribué',
        verifiedBy: 'Vérifié par',
        redemption: 'Rattrapage',
        status: 'Statut'
      },
      newActions: {
        viewEvaluation: "Voir l'évaluation",
        fillEvaluation: "Saisir la grille",
        verifyEvaluation: "Vérifier l'évaluation",
        deleteEvaluation: "Supprimer l'évaluation",
        viewAll: 'Voir tous',
      },
      columns: {
        name: `Nom de l'évaluation`,
        description: `Description de l'évaluation`,
        status: 'Statut',
        redemption: 'Rattrapage',
        linkedTo: 'Lié à'
      },
      actionTooltips: {
        verify: 'Valider',
        edit: 'Modifier',
        add: 'Ajouter'
      }
    },
    viewEvaluation: {
      htmlTitle: 'Évaluation étudiant',
      notPermitted: 'Non autorisé',
      notAdded: 'Évaluation non ajoutée',
      evaluation: 'Évaluation',
      feedback: 'Commentaires',
      answerType: 'Type de réponse',
      infoAlert: {
        rejectButton: 'Rejeter',
        verifyButton: 'Valider',
        fillRequiredFields: {
          title: 'Fill out evaluation',
          subtitle: 'You must fill out required fields before you can request verification'
        },
        requestVerification: {
          button: 'Demander une validation',
          title: 'Demander une validation',
          subtitle: `Une fois la grille d'évaluation remplie, demander une validation`,
          modalTitle: "Envoyer l'évaluation pour vérification",
          modalSubtitle: "Êtes-vous sûr ? Certains champs facultatifs sont manquants."
        },
        verified: {
          title: 'Evaluation vérifiée',
          subtitle: `L'évaluation est vérifiée`
        },
        rejected: {
          title: 'Évaluation rejetée',
          subtitle:
            "Quelque chose ne va pas avec l'évaluation et le vérificateur les a rejetées"
        },
        pendingVerification: {
          title: 'Vérification en cours',
          subtitle: `L'évaluation est ajoutée et soumise à validation`,
          rejectModalTitle: "Rejeter l'évaluation",
          rejectModalSubtitle: "Êtes-vous sûr de vouloir rejeter l'évaluation ?",
          verifyModalTitle: "Vérifier l'évaluation",
          verifyModalAlertNotice: "Cette action est irréversible, l'évaluation sera vérifiée.",
          forceRejectDefaultMessage: "L'évaluation a été laissée à l'état de projet.",
        },
        moveToVerified: {
          title: 'Évaluation complète',
          subtitle: `Une fois que vous avez terminé l'évaluation, changez le statut en "Vérifié"`,
          button: 'Complète'
        },
        changeStatusModalTitle: `Changer le statut de l'évaluation`,
        changeStatusModalBody:
          'Confirmer le changement de statut pour <1>{{status}}</1>?',
        changeStatusRejectedModalBody:
          'Êtes-vous sûr de vouloir changer le statut en rejeté?'
      }
    }
  },
  studentInfo: {
    studentInfoSection: {
      title: `Évalué par l'étudiant`,
      subtitle: 'Afficher les informations sur les étudiants'
    },
    name: 'Nom',
    email: 'E-mail',
    status: 'Statut'
  },
  assignedTo: {
    assigneeInfo: {
      title: 'Affecté à',
      subtitle: `Visualiser les informations sur l'assigné`
    },
    name: 'Nom',
    email: 'E-mail',
    assigneeRole: `Rôle de l'assigné`
  },
  verifiedBy: {
    verifierInfo: {
      title: 'Vérifié par',
      subtitle: 'Voir les informations sur le vérificateur'
    },
    name: 'Nom',
    email: 'E-mail',
  },
  auditedBy: {
    auditorInfo: {
      title: 'Audité par',
      redemptionTitle: 'Auditeur de rattrapage',
      subtitle: `Voir les informations sur l'auditeur`
    },
    name: 'Nom',
    email: 'E-mail',
    auditorComment: `Commentaire de l'auditeur`
  },
  backToList: 'Retour à la liste',
  fields: {
    notFilled: "Certains champs d'évaluation ne sont pas remplis!"
  },
  studentEvaluationConfigurations: {
    actions: {
      createEvaluation: 'Créer une évaluation',
      viewEvaluations: 'Voir les évaluations',
      bulkCreateEvaluations: 'Affecter des correcteurs'
    },
    createEvaluationForm: {
      title: 'Créer une évaluation',
      inputLabels: {
        evaluationConfigurationId: "Grille d'évaluation",
        assignToIdentifier: 'Rôle',
        userId: 'Utilisateur',
      }
    },
    bulkCreateEvaluationsForm: {
      title: 'Créer des évaluations pour les étudiants sélectionnés',
      inputLabels: {
        evaluationConfigurationId: "Grille d'évaluation",
        assignToIdentifier: 'Rôle',
        userId: 'Utilisateur',
      }
    }
  }
};

export default evaluations;
