import { useEffect, useState } from 'react';
import { v4 } from 'uuid';

import { axiosInt } from '../../../../utils/axios';
import { FileUploadingStatus } from '../../../../components/FileTree/types';
import { IDocumentUpload } from '../../utils/types';

export const uploadFileApi = async (file: File, url: string, data: any) => {
  const formData = new FormData();
  formData.set('file', file);
  Object.keys(data).forEach((key) => {
    formData.set(key, data[key]);
  });

  const response = await axiosInt.post(url, formData);
  return response.data;
};

export interface IFileToBeUploaded extends IDocumentUpload {
  file: File;
}

const useFileUpload = ({
  acceptedFiles,
  onChange,
  url,
  data
}: {
  acceptedFiles: File[];
  onChange(files: IDocumentUpload[]): void;
  url: string;
  data: any;
}) => {
  const [filesToBeUploaded, setFilesToBeUploaded] = useState<
    IFileToBeUploaded[]
  >([]);

  useEffect(() => {
    const filesToBeUploaded = acceptedFiles.map((file) => ({
      file,
      uuid: v4(),
      name: file.name,
      status: FileUploadingStatus.ToBeUploaded
    }));

    if (!filesToBeUploaded.length) {
      return;
    }

    setFilesToBeUploaded((files) => {
      return [...files, ...filesToBeUploaded];
    });
  }, [acceptedFiles]);

  useEffect(() => {
    const waitingFiles = filesToBeUploaded.filter(
      (file) => file.status === FileUploadingStatus.ToBeUploaded
    );

    if (waitingFiles.length) {
      waitingFiles.forEach((file) => {
        uploadFileApi(file.file, url, data)
          .then((data) => {
            setFilesToBeUploaded((files) =>
              files.map((item) =>
                item.uuid === file.uuid
                  ? {
                      ...item,
                      ...data,
                      status: FileUploadingStatus.Success
                    }
                  : item
              )
            );
          })
          .catch(() => {
            setFilesToBeUploaded((files) =>
              files.map((item) =>
                item.uuid === file.uuid
                  ? { ...item, status: FileUploadingStatus.Error }
                  : item
              )
            );
          });
      });

      setFilesToBeUploaded((files) =>
        files.map((f) =>
          f.status === FileUploadingStatus.ToBeUploaded
            ? { ...f, status: FileUploadingStatus.Uploading }
            : f
        )
      );
    }
  }, [filesToBeUploaded]);

  useEffect(() => {
    const actionFiles = filesToBeUploaded.filter((file) =>
      [
        FileUploadingStatus.Uploading,
        FileUploadingStatus.Error,
        FileUploadingStatus.Success
      ].includes(file.status)
    );

    if (actionFiles.length) {
      onChange(
        actionFiles.map((item) => ({
          name: item.name,
          uuid: item.uuid,
          id: item.id,
          status: item.status,
          fileId: item.fileId,
          courseId: item.courseId,
          type: item.type
        }))
      );
    }
  }, [filesToBeUploaded]);

  useEffect(() => {
    const completedFiles = filesToBeUploaded.filter((file) =>
      [FileUploadingStatus.Error, FileUploadingStatus.Success].includes(
        file.status
      )
    );

    if (!completedFiles.length) {
      return;
    }

    setFilesToBeUploaded((files) => {
      const newFiles = files.filter((file) => {
        return !completedFiles.find((item) => item.uuid === file.uuid);
      });

      return newFiles;
    });
  }, [filesToBeUploaded]);
};

export default useFileUpload;
