import * as Yup from 'yup';
import type { FC } from 'react';
import { Formik } from 'formik';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Button, TextField, Link, CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import useRefMounted from 'src/hooks/useRefMounted';
import useAuth from '../hooks/useAuth';
import { ILoginData } from '../utils/types';
import PasswordInput from './PasswordInput';

const VALIDATION_TRANSLATE_KEY = 'common.validation';

interface ILoginForm extends ILoginData {}

const LoginJWT: FC = () => {
  const { login } = useAuth() as any;
  const isMountedRef = useRefMounted();
  const { t }: { t: any } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (values: ILoginForm) => {
    try {
      await login(values.email, values.password);
    } catch (err) {
      if (isMountedRef.current) {
        enqueueSnackbar(t('auth.validation.wrongCredentials'), {
          variant: 'error'
        });
      }
    }
  };

  return (
    <Formik<ILoginForm>
      initialValues={{
        email: '',
        password: ''
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(t(`${VALIDATION_TRANSLATE_KEY}.email`))
          .max(
            255,
            t(`${VALIDATION_TRANSLATE_KEY}.max255Characters`, {
              field: t(`${VALIDATION_TRANSLATE_KEY}.emailField`)
            })
          )
          .required(
            t(`${VALIDATION_TRANSLATE_KEY}.required`, {
              field: t(`${VALIDATION_TRANSLATE_KEY}.emailField`)
            })
          ),
        password: Yup.string()
          .min(
            8,
            t(`${VALIDATION_TRANSLATE_KEY}.min8Characters`, {
              field: t(`${VALIDATION_TRANSLATE_KEY}.passwordField`)
            })
          )
          .max(
            255,
            t(`${VALIDATION_TRANSLATE_KEY}.max255Characters`, {
              field: t(`${VALIDATION_TRANSLATE_KEY}.passwordField`)
            })
          )
          .required(
            t(`${VALIDATION_TRANSLATE_KEY}.required`, {
              field: t(`${VALIDATION_TRANSLATE_KEY}.passwordField`)
            })
          ),
        terms: Yup.boolean().oneOf(
          [true],
          t('auth.validation.termsAndConditions')
        )
      })}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }): JSX.Element => (
        <form noValidate onSubmit={handleSubmit}>
          <TextField
            error={Boolean(touched.email && errors.email)}
            fullWidth
            margin="normal"
            autoFocus
            helperText={touched.email && errors.email}
            label={t('auth.label.email')}
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />
          <PasswordInput
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
            label={t('auth.label.password')}
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.password}
            fullWidth
            variant="outlined"
            margin="normal"
          />
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
          >
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={values.terms}
                  name="terms"
                  color="primary"
                  onChange={handleChange}
                />
              }
              label={
                <>
                  <Typography variant="body2">
                    {t('auth.label.accept')}{' '}
                    <Link component="a" href="#">
                      {t('auth.links.termsAndConditions')}
                    </Link>
                    .
                  </Typography>
                </>
              }
            /> */}
            <Link component={RouterLink} to="/recover-password">
              <b>{t('auth.links.lostPassword')}</b>
            </Link>
          </Box>

          <Button
            sx={{
              mt: 3
            }}
            color="primary"
            startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
            disabled={isSubmitting}
            type="submit"
            fullWidth
            size="large"
            variant="contained"
          >
            {t('auth.links.signIn')}
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default LoginJWT;
