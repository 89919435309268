import { useEffect, useState } from "react";

import { IUser } from "src/models/user";
import { IStudentDocument, StudentDocumentType } from "src/models/student-document";
import { RoleName } from "src/models/role";
import { Mode } from "src/utils/types";
import { Permissions, VerificationStatus } from "src/utils/const";

import { useTranslation } from 'react-i18next';
import useHaveRole from "src/hooks/useHaveRole";
import useCan from "src/hooks/useCan";
import useDocumentAction from "src/hooks/useDocumentActions";
import useDeleteStudentDocument from "src/features/students/hooks/api/useDeleteStudentDocument";

import FileCard from "../StudentDocuments/FileCard";
import ConfirmDeleteDocument from "src/features/cohorts/components/common/ConfirmDeleteDocument";
import { Grid } from "@mui/material";
import { studentIsInRedemption, studentIsOnHold } from "src/utils/userHelpers";
import { IStudentCertification } from "src/models/student-certification";

const STUDENT_DOCUMENTS_TRANSLATE_KEY = 'students.documents';

interface IProps {
    user: IUser;
    certification: IStudentCertification;
    uploadUrl: string;
    deleteUrl: string;
    canRequestPassportVerification?: boolean;
    setCanRequestPassportVerification?(can: boolean): void;
}


const StudentCertificationPassportTab: React.FC<IProps> = ({
    user,
    certification,
    uploadUrl,
    deleteUrl,
    canRequestPassportVerification,
    setCanRequestPassportVerification
}) => {

    const { t } = useTranslation();

    const isOrgAdmin = useHaveRole(RoleName.OrganisationAdmin);

    const isStudent = useHaveRole(RoleName.Student);

    const canManageStudents = useCan(Permissions.ManageStudents);

    const isStudentOnHold = studentIsOnHold(user);
    const isStudentInRedemption = studentIsInRedemption(user);

    const passportStatus = !isStudentInRedemption ? certification.passportStatus : certification.redemptionPassportStatus;

    const isEditablePassport =
        (isStudent && certification.passportStatus !== VerificationStatus.Verified) || canManageStudents ||
        isOrgAdmin;

    const isEditableRedemptionPassport =
        (isStudent && certification.redemptionPassportStatus !== VerificationStatus.Verified) || canManageStudents ||
        isOrgAdmin;

    const { documents } = user.userable;

    const { localDocuments, handleChange, handleSuccessfullyDeleted } =
        useDocumentAction<IStudentDocument>(
            documents,
            Object.values(StudentDocumentType)
        );

    const {
        deleteDocument,
        documentToBeDeleted,
        isDeletingDocument,
        setDocumentToBeDeleted
    } = useDeleteStudentDocument();

    useEffect(() => {
        const canRequest = !!(
            localDocuments[StudentDocumentType.CertificationPassport][0]?.id
            ||
            (passportStatus === VerificationStatus.Rejected &&
                localDocuments[StudentDocumentType.CertificationPassport][0]?.id)
        );

        if (canRequestPassportVerification !== canRequest && setCanRequestPassportVerification) {
            setCanRequestPassportVerification(canRequest);
        }
    }, [localDocuments, canRequestPassportVerification]);

    return (
        <Grid container rowSpacing={2} columnSpacing={8}>
            <Grid item xs={12} md={8} sx={{ mb: { xs: 3 } }}>
                {isStudentInRedemption &&
                    <FileCard
                        title={t(`${STUDENT_DOCUMENTS_TRANSLATE_KEY}.redemptionPassport`)}
                        fileType={StudentDocumentType.RedemptionCertificationPassport}
                        canManage={isEditableRedemptionPassport && !isStudentOnHold}
                        files={localDocuments[StudentDocumentType.RedemptionCertificationPassport]}
                        mode={Mode.Edit}
                        onChange={handleChange}
                        onDelete={setDocumentToBeDeleted}
                        url={uploadUrl}
                        isMultiple={true}
                    />}
            </Grid>
            <Grid item xs={12} md={8} sx={{ mb: { xs: 3 } }}>
                <FileCard
                    title={t(`${STUDENT_DOCUMENTS_TRANSLATE_KEY}.passport`)}
                    fileType={StudentDocumentType.CertificationPassport}
                    canManage={isEditablePassport && !isStudentOnHold && !isStudentInRedemption}
                    files={localDocuments[StudentDocumentType.CertificationPassport]}
                    mode={Mode.Edit}
                    onChange={handleChange}
                    onDelete={setDocumentToBeDeleted}
                    url={uploadUrl}
                    isMultiple={true}
                />
            </Grid>
            <ConfirmDeleteDocument
                open={!!documentToBeDeleted}
                onClose={() => setDocumentToBeDeleted(null)}
                onConfirm={() =>
                    deleteDocument(`${deleteUrl}/${documentToBeDeleted.id}`, {
                        onSuccess: () => {
                            handleSuccessfullyDeleted(documentToBeDeleted);
                        }
                    })
                }
                isConfirming={isDeletingDocument}
                documentName={documentToBeDeleted?.file?.name}
            />
        </Grid>
    )
}

export default StudentCertificationPassportTab;