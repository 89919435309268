import { Events } from 'src/utils/events';

const certificates = {
  common: {
    menu: {
      title: 'Certificats'
    },
    validation: {
      longNameField: 'Nom du certificat',
      shortNameField: 'Abbréviation certificat',
      yearOfCertificationField: "Année d'obtention certification",
      rncpCodeField: 'Code RNCP',
      rncpLevelField: 'Niveau RNCP',
      rncpLevelEuropeField: 'Niveau Européen',
      localeField: 'Langue',
      descriptionField: 'Description du certificat',
      blockField: 'Au moins un bloc est requis',
      daysField: 'Jours',
      certificateTaskConfigurationIdField: 'Tâches',
      textField: 'Texte',
      eventField: 'Événements',
      blocks: {
        nameField: 'Nom du bloc',
        deliverableField: 'Livrable',
        markField: 'Note',
        deliverables: {
          nameField: 'Nom du livrable'
        },
        marks: {
          nameField: 'Libellé de la note'
        }
      },
      nameField: 'Nom'
    },
    certificateForm: {
      longName: {
        label: 'Nom du certificat',
        placeholder: 'Saisir le nom du certificat ici...'
      },
      shortName: {
        label: 'Abbréviation Certificat',
        placeholder: "Saisir l'abbréviation Certificat ici..."
      },
      yearOfCertification: {
        label: "Année d'obtention"
      },
      rncpCode: {
        label: 'Code RNCP',
        placeholder: 'Saisir le code RNCP ici...'
      },
      rncpLevel: {
        label: 'Niveau RNCP',
        placeholder: 'Saisir le niveau RNCP ici...'
      },
      rncpLevelEurope: {
        label: 'Niveau Européen',
        placeholder: 'Saisir le niveau Européen ici...'
      },
      locale: {
        label: 'Langue',
        placeholder: 'Sélectionner la langue ici...'
      },
      description: {
        label: 'Description du certificat',
        placeholder: 'Saisir la description du certificat ici...'
      },
      avatar: {
        label: 'Logo certificat',
        placeholder: 'Téléverser le logo du certificat'
      },
      isPublished: {
        label: 'Publier le certificat'
      },
      status: {
        label: 'Statut'
      },
      blocks: {
        boxTitle: 'BLOCS',
        modalTitle: 'Ajouter un bloc',
        modalSubtitle: 'Saisir les champs ci-dessous pour ajouter un bloc',
        cancel: 'Annuler',
        addBlock: 'Ajouter un bloc',
        noBlockAdded: 'Aucun bloc ajouté',
        form: {
          optional: {
            label: 'Optionnel'
          },
          name: {
            label: 'Nom du bloc',
            placeholder: 'Saisir le nom du bloc ici...'
          }
        }
      },
      save: 'Enregistrer le certificat'
    },
    blocks: {
      delete: {
        modalTitle: 'Supprimer le bloc',
        modalBody:
          'Confirmer la suppression du bloc <1>{{name}}</1> de la liste des blocs ?'
      }
    },
    notificationForm: {
      name: {
        label: 'Nom',
        placeholder: 'Saisir le titre de la notification ici...'
      },
      description: {
        label: 'Description',
        placeholder: 'Saisir la description de la notification ici...'
      },
      text: {
        label: 'Texte',
        placeholder: 'Saisir le corps de message de la notification ici...'
      },
      sendToRoles: {
        label: 'Envoyer aux rôles',
        placeholder:
          'Choisir les différents rôles qui recevront la notification...'
      },
      sendOnEmail: {
        label: 'Envoyer par e-mail',
        placeholder: 'Envoyer par e-mail'
      },
      sendOnPlatform: {
        label: 'Envoyer en notification in-app',
        placeholder: 'Envoyer en notification in-app'
      },
      event: {
        label: 'Événements',
        placeholder: "Sélectionner l'événement"
      },
      timeTypes: {
        reminderNotifications: 'Notifications de rappel',
        immediatelySendNotifications: 'Envoi immédiat de notification'
      },
      userTypes: {
        userTaskAssignedTo: "Envoi à l'utilisateur affecté à la tâche",
        otherUsers: "Envoyer à d'autres utilisateurs",
        notifiableUsers: 'Utilisateurs notifiés'
      },
      triggerTypes: {
        event: 'Événement',
        task: 'Tâche'
      },
      days: {
        label: 'Jours',
        placeholder: 'Sélectionner le nombre de jours'
      },
      task: {
        label: 'Tâche',
        placeholder: 'Sélectionner la tâche'
      }
    }
  },
  listAll: {
    htmlTitle: 'Certificats',
    title: 'Certificats',
    subtitle: 'Liste des certificats actifs',
    createNewCertificate: 'Créer un nouveau certificat',
    table: {
      columns: {
        certificate: 'CERTIFICAT',
        cohorts: 'COHORTES',
        status: 'STATUT',
        createdAt: 'CRÉÉ LE'
      },
      status: {
        published: 'Actif',
        unlisted: 'Inactif'
      },
      actionTooltips: {
        edit: 'Modifier',
        delete: 'Supprimer',
        configureTasks: 'Configurer les tâches',
        exportStudentBlockDeliverablesStatusReport: 'Exporter le rapport d’état des livrables du bloc étudiant',
      }
    },
    delete: {
      successMessage: 'Confirmation de suppression certificat',
      errorMessage: 'Erreur lors de la suppression certificat',
      modalTitle: 'Supprimer certificat',
      modalBody:
        'Confirmer la suppression de <1>{{name}}</1> de la liste des certificats ?'
    }
  },
  create: {
    htmlTitle: 'Créer nouveau certificat',
    title: 'Créer nouveau certificat',
    subtitle: 'Saisir les champs ci-dessous pour créer un nouveau certificat',
    backToList: 'Retour aux certificats'
  },
  view: {
    htmlTitle: 'Certificats | {{name}}',
    title: 'Information certificats',
    subtitle: 'Détail information certificats',
    backToList: 'Retour aux certificats',
    tabs: {
      info: 'Info',
      tasks: 'Tâches',
      notifications: 'Notifications',
      evaluationConfigurations: `Grilles d'évaluation`
    }
  },
  blockView: {
    htmlTitle: 'Certificats | Blocs | {{name}}',
    subtitle: 'Détail information Certificat et blocs',
    backToCertificate: 'Retour aux certificats',
    deliverables: {
      boxTitle: 'LIVRABLE DES BLOCS',
      noDeliverableAdded: 'Aucun livrable remis',
      addDeliverable: 'Ajouter un livrable',
      add: {
        modalTitle: 'Ajouter un livrable de bloc',
        modalSubtitle: 'Saisir les champs ci-dessous pour ajouter un livrable'
      },
      edit: {
        modalTitle: 'Modifier le livrable de bloc',
        modalSubtitle: 'Saisir les champs ci-dessous pour modifier un livrable'
      },
      form: {
        name: {
          label: 'Nom du livrable',
          placeholder: 'Saisir le nouveau nom du livrable...'
        },
        description: {
          label: 'Description du livrable',
          placeholder: 'Saisir la nouvelle description du livrable...'
        }
      },
      delete: {
        modalTitle: 'Supprimer le livrable',
        modalBody:
          'Confirmer la suppression du livrable <1>{{name}}</1> de la liste des livrables du bloc ?'
      }
    },
    marks: {
      boxTitle: 'Notes de bloc',
      noMarkAdded: 'Aucune note',
      addMark: 'Ajouter une note',
      add: {
        modalTitle: 'Ajouter note',
        modalSubtitle: 'Saisir les champs ci-dessous pour saisir une note'
      },
      edit: {
        modalTitle: 'Modifier la note de bloc',
        modalSubtitle: 'Saisir les champs ci-dessous pour modifier la note'
      },
      delete: {
        modalTitle: 'Supprimer la note',
        modalBody:
          'Confirmer la suppression de la note <1>{{name}}</1> de la liste des notes de bloc ?'
      },
      form: {
        name: {
          label: 'Libellé note',
          placeholder: 'Saisir la nouvelle note...'
        }
      }
    }
  },
  academicKit: {
    title: 'Kit pédagogique',
    deleteFolder: {
      modalTitle: 'Supprimer répertoire',
      modalBody:
        'Confirmer la suppression du répertoire <1>{{name}}</1> de la liste du kit pédagogique ?'
    },
    deleteFile: {
      modalTitle: 'Supprimer fichier',
      modalBody:
        'Confirmer la suppression du fichier <1>{{name}}</1> de la liste du kit pédagogique ?'
    }
  },
  blockDocuments: {
    title: 'Documents du bloc',
    deleteFolder: {
      modalTitle: 'Supprimer répertoire',
      modalBody:
        'Confirmer la suppression du répertoire <1>{{name}}</1> de la liste des documents du bloc ?'
    },
    deleteFile: {
      modalTitle: 'Delete file',
      modalBody:
        'Confirmer la suppression du fichier <1>{{name}}</1> de la liste des documents du bloc ?'
    }
  },
  tasks: {
    table: {
      columns: {
        name: 'Nom'
      },
      actions: {
        edit: 'Modifier la tâche'
      }
    },
    basicInfo: {
      description: 'Description',
      roles: 'Rôles'
    },
    form: {
      name: {
        label: 'Nom de la tâche',
        placeholder: 'Saisir le nom de la tâche...'
      },
      description: {
        label: 'Description de la tâche',
        placeholder: 'Saisir la description de la tâche...'
      },
      roles: {
        label: 'Attribuer les rôles:',
        placeholder: ''
      }
    }
  },
  task: {
    htmlTitle: 'Certificats | Tâches | {{name}}',
    title: 'Libellé Tâche',
    subtitle: 'Détails libellé tâche',
    backToCertificateTasks: 'Retour aux tâches du certificat',
    notificationPartTitle: 'Libellé notification',
    notificationPartSubtitle: 'Détails libellé notification',
    createNewNotification: 'Ajouter une nouvelle notification',
    addMagicWords:
      'Ajouter des mots "magic" qui seront remplacés par de la donnée réelle :'
  },
  notifications: {
    modal: {
      create: {
        modalTitle: 'Ajouter une notification',
        modalSubtitle:
          'Saisir les champs ci-dessous pour ajouter une nouvelle notification pour la tâche'
      },
      update: {
        modalTitle: 'Mettre à jour la notification',
        modalSubtitle: 'Saisie des paramètres de la notification'
      },
      delete: {
        modalTitle: 'Supprimer la notification',
        modalBody:
          'Confirmer la suppression de la notification <1>{{name}}</1> de la liste des notifications ?'
      }
    },
    table: {
      columns: {
        name: 'Nom',
        trigger: 'Déclencheur',
        task: 'Tâche',
        event: 'Événément'
      }
    }
  },
  events: {
    [Events.CorrectorAssignedToStudentBlocks]:
      'Correcteur affecté à un bloc / étudiant',
    [Events.CourseDocumentsRejected]: 'Documents école rejetés',
    [Events.CourseDocumentsUploaded]: 'Documents école téléversés',
    [Events.CourseDocumentsVerified]: 'Documents école validés',
    [Events.JobDescriptionRejected]: 'Documents école rejetés',
    [Events.JobDescriptionVerificationRequested]:
      'Validation de la fiche de poste requise',
    [Events.JobDescriptionVerified]: 'Fiche de poste validée',
    [Events.SchoolInvitedToCohort]: 'École invitée dans la cohorte',
    [Events.SchoolRemovedFromCohort]: 'École retirée de la cohorte',
    [Events.StudentAcceptedInvitation]: "L'étudiant a validé l'inscription",
    [Events.StudentDocumentsRejected]: 'Documents étudiant rejetés',
    [Events.StudentDocumentsUploaded]: 'Documents étudiant téléversés',
    [Events.StudentDocumentsVerified]: 'Documents étudiant validés',
    [Events.StudentInvitedToCohort]: 'Étudiant invité dans la cohorte',
    [Events.TutorAssignedToStudent]: "Tuteur affecté à l'étudiant",
    [Events.JuryPresidentAssigned]: 'Président de jury affecté',
    [Events.JuryPresidentDeleted]: 'Président de jury supprimé',
    [Events.JuryMemberAssigned]: 'Membre de jury affecté',
    [Events.JuryMemberDeleted]: 'Membre de jury supprimé',
    [Events.UploadedFinalExamDocument]: "Document de l'examen final téléversé",
    [Events.FinalExamCompleted]: 'Examen final réalisé',
    [Events.FinalExamPassed]: 'Examen final réussi',
    [Events.FinalExamFailed]: "Échec de l'examen final",
    [Events.FinalExamMovedToPending]:
      "Dossier de l'étudiant pour examen final prêt",
    [Events.EvaluationCreated]: 'Évaluation créée',
    [Events.EvaluationRejected]: 'Évaluation rejetée',
    [Events.EvaluationVerified]: 'Évaluation vérifiée',
    [Events.StudentBlockSubmitted]: "L'étudiant a souimis un livrable de bloc",
    [Events.StudentPassportVerificationRequested]: `Vérification du passeport de l'étudiant demandée`,
    [Events.StudentPassportRejected]: 'Rejet du passeport étudiant',
    [Events.StudentPassportVerified]: `Passeport d'étudiant vérifié`,
    [Events.AuditorAssignedToStudentCertification]: 'Auditeur affecté à la certification des étudiants',
    [Events.JuryAssignedToStudentCertification]: 'Jury chargé de la certification des étudiants',
    [Events.AuditorAuditedStudentCertification]: `L'auditeur a vérifié la certification de l'étudiant`,
    [Events.JuryReachedStudentCertificationDecision]: 'Le jury est parvenu à une décision sur la certification des étudiants',

  }
};

export default certificates;
