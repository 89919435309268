import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { ArrayFormBoxListActions } from '../../../../../../components/ArrayFormBox';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { IJobRequiredKnowHow } from 'src/models/job-required-know-how';
import { JobStatuses } from 'src/utils/const';
import {
  StyledArrayFormBoxListItem,
  StyledReactQuill
} from '../JobMissions/JobMissionsListItem';

interface IProps {
  jobRequiredKnowHow: IJobRequiredKnowHow;
  index: number;
  onEdit(index: number, jobRequiredKnowHow: IJobRequiredKnowHow): void;
  onDelete(index: number, jobRequiredKnowHow: IJobRequiredKnowHow): void;
  jobStatus: JobStatuses;
  canManage;
}

const JobRequiredKnowHowListItem: React.FC<IProps> = ({
  jobRequiredKnowHow,
  index,
  onDelete,
  onEdit,
  jobStatus,
  canManage
}) => {
  const { t } = useTranslation();

  return (
    <StyledArrayFormBoxListItem key={index}>
      <Grid
        container
        sx={{
          display: 'flex',
          flexFlow: 'nowrap',
          justifyContent: 'space-between'
        }}
      >
        <Grid xs={10} item>
          <Box>
            <StyledReactQuill>
              <ReactQuill
                value={jobRequiredKnowHow.knowHow}
                readOnly
                theme={'bubble'}
              />
            </StyledReactQuill>
          </Box>
        </Grid>
        {jobStatus !== JobStatuses.VerifiedBySchool && canManage && (
          <Grid item>
            <ArrayFormBoxListActions
              onDelete={() => onDelete(index, jobRequiredKnowHow)}
              onEdit={() => {
                onEdit(index, jobRequiredKnowHow);
              }}
            />
          </Grid>
        )}
      </Grid>
    </StyledArrayFormBoxListItem>
  );
};

export default JobRequiredKnowHowListItem;
