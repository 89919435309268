import {
  Dialog,
  DialogTitle,
  Typography,
  IconButton,
  DialogContent,
  DialogActions,
  Button
} from '@mui/material';
import React from 'react';
import { ITask } from 'src/models/task';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { TaskStatuses } from '../../../../utils/const';
import TaskDetails from './TaskDetails';

const TASKS_TRANSLATE_KEY = 'tasks.listAll';

interface IProps {
  task: ITask;
  onRedirectToTask?(task: ITask): void;
  onClose(): void;
}

const TaskDetailsModal: React.FC<IProps> = ({
  task,
  onClose,
  onRedirectToTask
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      key={task.id}
      fullWidth
      maxWidth="xs"
      open={!!task}
      onClose={onClose}
    >
      <DialogTitle
        sx={{
          p: 3
        }}
      >
        <Typography variant="h4">
          {t(`${TASKS_TRANSLATE_KEY}.taskDetails`)}
        </Typography>

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 16,
            top: 14,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        dividers
        sx={{
          p: 3
        }}
      >
        <TaskDetails task={task} />
      </DialogContent>
      {task.status !== TaskStatuses.TerminatedBySystem &&
        task.status !== TaskStatuses.Done &&
        !!onRedirectToTask && (
          <DialogActions
            sx={{
              p: 2
            }}
          >
            <Button
              variant="contained"
              size="medium"
              onClick={() => onRedirectToTask(task)}
            >
              {t(`${TASKS_TRANSLATE_KEY}.table.actionTooltips.view`)}
            </Button>
          </DialogActions>
        )}
    </Dialog>
  );
};

export default TaskDetailsModal;
