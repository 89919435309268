import { Box, Grid, Typography } from '@mui/material';
import { ICohort } from '../../../../models/cohort';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import {
  StyledReactQuillWrapper,
  ContentCard
} from 'src/features/cohorts/components/common/StyledComponents';
import { ICertificate } from '../../../../models/certificate';
import {
  ImagePlaceholder,
  PreviewImage,
  WrapperPreviewImage
} from 'src/components/DragAndDropAvatar';

interface IProps {
  cohort?: ICohort;
  certificate?: ICertificate;
}

const STUDENT_BASE_KEY = 'students.cohortCertificate.labels';

const CohortCertificateBasicInfo: React.FC<IProps> = ({
  cohort,
  certificate
}) => {
  const { t } = useTranslation();

  return (
    <ContentCard>
      <Grid container spacing={2} sx={{ position: 'relative' }}>
        <Grid item sm={12}>
          <Typography variant="h3" component="h3" sx={{ mb: 0 }}>
            {cohort?.name}
          </Typography>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            {cohort?.shortName}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          {cohort?.avatar ? (
            <WrapperPreviewImage>
              <PreviewImage alt="Cohort Image" src={cohort?.avatar} />
            </WrapperPreviewImage>
          ) : (
            <ImagePlaceholder />
          )}
        </Grid>
        <Grid item xs={12} sm={8}>
          <Box pl={2}>
            <Box sx={{ pb: 1 }}>
              <Typography sx={{ pb: 0, fontSize: 14, mr: 1 }}>
                {t(`${STUDENT_BASE_KEY}.certificate`)}:
              </Typography>
              <Typography style={{ fontWeight: 'bold', fontSize: '16px' }}>
                {certificate?.longName} ({certificate?.shortName})
              </Typography>
            </Box>
            <Box sx={{ pb: 1 }}>
              <Typography sx={{ pb: 0, fontSize: 14, mr: 1 }}>
                {t(`${STUDENT_BASE_KEY}.code`)}:
              </Typography>
              <Typography style={{ fontWeight: 'bold', fontSize: '16px' }}>
                {certificate?.rncpCode}
              </Typography>
            </Box>
            <Box sx={{ pb: 1 }}>
              <Typography sx={{ pb: 0, fontSize: 14, mr: 1 }}>
                {t(`${STUDENT_BASE_KEY}.level`)}:
              </Typography>
              <Typography style={{ fontWeight: 'bold', fontSize: '16px' }}>
                {certificate?.rncpLevel}
              </Typography>
            </Box>
            <Box sx={{ pb: 0 }}>
              <Typography sx={{ pb: 0, fontSize: 14, mr: 1 }}>
                {t(`${STUDENT_BASE_KEY}.blocks`)}:
              </Typography>
              <Typography style={{ fontWeight: 'bold', fontSize: '16px' }}>
                {certificate?.blocks?.length}
              </Typography>
            </Box>
          </Box>
        </Grid>
        {cohort?.description && (
          <Grid item xs={12} sm={12}>
            <Box sx={{ pb: 0 }}>
              <Typography sx={{ pb: 0, fontSize: 14, mr: 1 }}>
                {t(`${STUDENT_BASE_KEY}.description`)}:
              </Typography>
              <StyledReactQuillWrapper>
                <ReactQuill
                  value={cohort?.description}
                  readOnly={true}
                  theme={'bubble'}
                />
              </StyledReactQuillWrapper>
            </Box>
          </Grid>
        )}
      </Grid>
    </ContentCard>
  );
};

export default CohortCertificateBasicInfo;
