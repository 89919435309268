import {
  useTheme,
  styled,
  Typography,
  Box,
  CircularProgress,
  Badge,
  BadgeProps
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import useMarkAllRead from '../hooks/api/useMarkAllRead';

interface IProps {
  numberOfUnreadNotifications: number;
}

const BoxComposed = styled(Box)(
  () => `
      position: relative;
    `
);

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -20,
    top: -2
  }
}));

const TIMELINE_NOTIFICATIONS_BASE_KEY = 'notifications.timelineNotifications';

const InfoNotifications: React.FC<IProps> = ({
  numberOfUnreadNotifications
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { markAllRead, isMarkingAllRead } = useMarkAllRead();

  return (
    <BoxComposed
      mb={1}
      mt={1}
      sx={{
        borderRadius: `${theme.general.borderRadius}`
      }}
      display="flex"
      justifyContent="space-between"
    >
      <Box display="flex" alignItems="center">
        <Typography
          textAlign="center"
          sx={{
            pb: 0.5
          }}
          variant="h4"
        >
          {t('notifications.notifications')}
        </Typography>
        {numberOfUnreadNotifications > 0 && (
          <StyledBadge
            badgeContent={numberOfUnreadNotifications}
            color="primary"
          />
        )}
      </Box>

      {numberOfUnreadNotifications > 0 && (
        <Typography
          onClick={() => markAllRead()}
          variant="body2"
          color={theme.colors.primary.main}
          sx={{
            display: 'flex',
            justifyContent: 'right',
            cursor: 'pointer',
            fontWeight: 'bold'
          }}
        >
          {t(`${TIMELINE_NOTIFICATIONS_BASE_KEY}.markAllAsRead`)}
          {isMarkingAllRead && (
            <CircularProgress
              size={18}
              disableShrink
              thickness={3}
              sx={{ mx: 1 }}
            />
          )}
        </Typography>
      )}
    </BoxComposed>
  );
};

export default InfoNotifications;
