import { useSearchParams } from 'react-router-dom';

const useTab = (defaultTab: string, replaceUrl: boolean = true) => {
  const [searchParams, setSearchParams] = useSearchParams({ tab: defaultTab });

  const tab = searchParams.get('tab');

  const handleTabChange = (newValue: string) => {
    const current = {};
    searchParams.forEach((value, key) => {
      current[key] = value;
    });

    setSearchParams(
      replaceUrl ? { tab: newValue } : { ...current, tab: newValue },
      { replace: true }
    );
  };

  return { tab, setTab: handleTabChange };
};

export default useTab;
