import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import ApiError from '../../../components/ApiError';
import PageLoader from '../../../components/PageLoader';
import useCan from '../../../hooks/useCan';
import { Permissions } from '../../../utils/const';
import { Mode } from '../../../utils/types';
import { ContentBox, ContentCard } from '../components/common/StyledComponents';
import PageHeader from '../components/cohort/PageHeader';
import CohortBasicInfo from '../components/cohort/CohortBasicInfo';
import useFetchCohort from '../hooks/api/useFetchCohort';
import useFetchCohortSchools from '../hooks/api/useFetchCohortSchools';
import useCohortData from '../hooks/memo/useCohortData';
import TaskList from '../components/common/TaskList';
import CohortSchools from '../components/cohort/CohortSchools';
import useFetchCohortTasks from '../hooks/api/task/useFetchCohortTasksConfigurations';
import useUpdateCohortTask from '../hooks/api/task/useUpdateCohortTaskConfigurations';
import { ICohortTaskConfiguration } from '../../../models/cohort-task-configuration';
import CohortCertification from '../components/certification/CohortCertification';

import { Box } from '@mui/system';
import { Card, Tab } from '@mui/material';
import useTab from 'src/hooks/useTab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CohortRedemption from '../components/certification/CohortRedemption';

const VIEW_COHORT_TRANSLATE_KEY = 'cohorts.view';

export enum Tabs {
  Info = 'Info',
  Tasks = 'Tasks',
  Schools = 'Schools',
  Certification = 'Certification',
  Redemption = 'Redemption'
}

const Cohort = () => {
  const { id } = useParams();

  const { t } = useTranslation();

  const canManageCohort = useCan(Permissions.ManageCohorts);
  const canReadCohort = useCan(Permissions.ReadCohorts);

  const { tab, setTab } = useTab(Tabs.Info);

  const handleTabChange = (_: React.SyntheticEvent, newValue: Tabs) => {
    setTab(newValue);
  };

  const [searchParams] = useSearchParams({
    mode: Mode.View
  });

  const mode = searchParams.get('mode') as Mode;

  const {
    cohort,
    isFetchingCohort,
    errorFetchingCohort
  } = useFetchCohort(id);

  const {
    schools,
    isFetchingCohortSchools,
    errorFetchingCohortSchools
  } = useFetchCohortSchools(id, { enabled: canReadCohort });

  const {
    cohortTasksConfigurations,
    errorFetchingCohortTasksConfigurations,
    isFetchingCohortTasksConfigurations
  } = useFetchCohortTasks(id);

  const { updateCohortTaskConfiguration } = useUpdateCohortTask();

  const cohortData = useCohortData({ cohort, schools });

  const error =
    errorFetchingCohort ||
    errorFetchingCohortSchools ||
    errorFetchingCohortTasksConfigurations;

  if (
    isFetchingCohort ||
    isFetchingCohortSchools ||
    isFetchingCohortTasksConfigurations
  ) {
    return <PageLoader />;
  }

  if (error) {
    return <ApiError error={error} />;
  }

  return (
    <div>
      <Helmet>
        <title>
          {t(`${VIEW_COHORT_TRANSLATE_KEY}.htmlTitle`, {
            name: cohortData.name
          })}
        </title>
      </Helmet>
      <PageHeader cohort={cohortData} />
      <ContentBox>
        <TabContext value={tab}>
          <Box>
            <TabList onChange={handleTabChange} sx={{ mr: 1, mb: 1 }}>
              <Tab
                label={t(`cohorts.common.tabs.cohortInfoTab`)}
                value={Tabs.Info}
              />
              <Tab
                label={t(`cohorts.common.tabs.tasksTab`)}
                value={Tabs.Tasks}
              />
              <Tab
                label={t(`cohorts.common.tabs.schoolsTab`)}
                value={Tabs.Schools}
              />
              <Tab
                label={t(`cohorts.common.tabs.certificationTab`)}
                value={Tabs.Certification}
              />
              <Tab
                label={t(`cohorts.common.tabs.redemptionTab`)}
                value={Tabs.Redemption}
              />
            </TabList>
          </Box>
          <TabPanel value={Tabs.Info} sx={{ padding: 0 }}>
            <ContentCard>
              <CohortBasicInfo
                cohort={cohortData}
                canManageCohort={canManageCohort}
                mode={mode}
              />
            </ContentCard>
          </TabPanel>
          <TabPanel value={Tabs.Tasks} sx={{ padding: 0 }}>
            <TaskList
              tasks={cohortTasksConfigurations}
              onChangeTask={(task) =>
                updateCohortTaskConfiguration(task as ICohortTaskConfiguration)
              }
              canManage={canManageCohort}
              isFetching={isFetchingCohortTasksConfigurations}
            />
          </TabPanel>
          <TabPanel value={Tabs.Schools} sx={{ padding: 0 }}>
            <CohortSchools cohort={cohort} canManageCohorts={canManageCohort} />
          </TabPanel>
          <TabPanel value={Tabs.Certification} sx={{ padding: 0 }}>
            <CohortCertification cohortId={cohort.id} schools={schools} />
          </TabPanel>
          <TabPanel value={Tabs.Redemption} sx={{ padding: 0 }}>
            <CohortRedemption cohortId={cohort.id} schools={schools} />
          </TabPanel>
        </TabContext>
        {/* <ContentCard>
          <CohortBasicInfo
            cohort={cohortData}
            canManageCohort={canManageCohort}
            mode={mode}
          />
          <TaskList
            tasks={cohortTasksConfigurations}
            onChangeTask={(task) =>
              updateCohortTaskConfiguration(task as ICohortTaskConfiguration)
            }
            canManage={canManageCohort}
          />
          <CohortSchools cohort={cohort} canManageCohorts={canManageCohort} />
        </ContentCard> */}
      </ContentBox>
    </div>
  );
};

export default Cohort;
