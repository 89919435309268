import { Box } from '@mui/material';
import FileTree from '../../../../components/FileTree';
import { FileTreeMode } from '../../../../components/FileTree/types';
import { ICertificate } from '../../../../models/certificate';
import { IFolder } from '../../../../models/folder';
import { IFolder as IFileTreeFolder } from '../../../../components/FileTree/types';
import useCreateCertificateFolder from '../../hooks/api/certificate/useCreateCertificateFolder';
import useUpdateCertificateFolder from '../../hooks/api/certificate/useUpdateCertificateFolder';
import useDeleteCertificateFolder from '../../hooks/api/certificate/useDeleteCertificateFolder';
import Confirm from '../../../../components/Confirm';
import { Trans, useTranslation } from 'react-i18next';
import { IFile } from '../../../../models/file';
import useCreateCertificateFile from '../../hooks/api/certificate/useCreateCertificateFile';
import useDeleteCertificateFile from '../../hooks/api/certificate/useDeleteCertificateFile';
import useFileTreeData from '../../../../components/FileTree/useFileTreeData';
import useUpdateFolderRoles from 'src/features/acl/hooks/api/file/useUpdateFolderRoles';
import useUpdateFileRoles from '../../../acl/hooks/api/file/useUpdateFileRoles';
import useReorderCertificateFileTree from '../../hooks/api/certificate/useReorderCertificateFileTree';
import { prepareTreeForApiReorder } from 'src/components/FileTree/utils';
import useMode from 'src/hooks/useMode';
import { Mode } from 'src/utils/types';

const ACADEMIC_KIT_TRANSLATE_KEY = 'certificates.academicKit';

interface IProps {
  certificate: ICertificate;
  canManageCertificates: boolean;
}

const CertificateAcademicKit: React.FC<IProps> = ({
  certificate,
  canManageCertificates
}) => {
  const {
    fileTree,
    onCreateFile,
    onCreateFolder,
    onDeleteFile,
    onDeleteFolder,
    onEditFolder,
    setFileTree,
    onCreateFileError,
    onEditFile,
    onReorderingTree,
    onCancelReorderingTree,
    onReorderTree
  } = useFileTreeData(certificate.folders);

  const { t } = useTranslation();

  const { createCertificateFolder } = useCreateCertificateFolder();

  const { updateCertificateFolder } = useUpdateCertificateFolder();

  const { createCertificateFile } = useCreateCertificateFile();

  const { updateFolderRoles } = useUpdateFolderRoles();

  const { updateFileRoles } = useUpdateFileRoles();

  const { reorderCertificateFileTree } = useReorderCertificateFileTree();

  const {
    certificateFileToBeDeleted,
    deleteCertificateFile,
    isDeletingCertificateFile,
    setCertificateFileToBeDeleted
  } = useDeleteCertificateFile();

  const {
    deleteCertificateFolder,
    setCertificateFolderToBeDeleted,
    certificateFolderToBeDeleted,
    isDeletingCertificateFolder
  } = useDeleteCertificateFolder();

  const handleCreateFolder = (folder: Partial<IFolder>) => {
    createCertificateFolder(
      { ...folder, modelId: certificate.id },
      {
        onSuccess: onCreateFolder
      }
    );
  };

  const handleUpdateCertificateFolder = (folder: Partial<IFolder>) => {
    updateCertificateFolder(
      {
        id: folder.id,
        modelId: certificate.id,
        name: folder.name
      },
      {
        onSuccess: onEditFolder
      }
    );
  };

  const handleChangeFolderRoles = (folder: Partial<IFolder>) => {
    updateFolderRoles(folder, { onSuccess: onEditFolder });
  };

  const handleChangeFileRoles = (file: Partial<IFile>) => {
    updateFileRoles(file, {
      onSuccess: (data) => {
        onEditFile(data);
      }
    });
  };

  const handleFileUploaded = (file: Partial<IFile>) => {
    createCertificateFile(
      { ...file, modelId: certificate.id },
      {
        onSuccess: onCreateFile,
        onError: () => {
          onCreateFileError(file);
        }
      }
    );
  };

  const handleConfirmDeleteFolder = () => {
    deleteCertificateFolder(certificateFolderToBeDeleted, {
      onSuccess: () => {
        onDeleteFolder(certificateFolderToBeDeleted.id);
      }
    });
  };

  const handleConfirmDeleteFile = () => {
    deleteCertificateFile(certificateFileToBeDeleted, {
      onSuccess: () => {
        onDeleteFile(certificateFileToBeDeleted.id);
      }
    });
  };

  const handleReorderTree = () => {
    reorderCertificateFileTree({
      certificateId: certificate.id,
      data: prepareTreeForApiReorder(fileTree),
    }, {
      onSuccess: () => {
        onReorderTree()
      }
    })
  };
  
  const {mode : pageMode } = useMode(Mode.View);

  return (
    <Box sx={{ mt: 3 }}>
      <FileTree
        disableReordering={pageMode !== Mode.View}
        mode={FileTreeMode.Edit}
        onEditTree={setFileTree}
        title={t(`${ACADEMIC_KIT_TRANSLATE_KEY}.title`)}
        treeData={fileTree}
        canManageTree={canManageCertificates}
        onCreateFolder={handleCreateFolder}
        onEditFolder={handleUpdateCertificateFolder}
        onDeleteFolder={(folder: IFileTreeFolder) =>
          setCertificateFolderToBeDeleted({
            name: folder.name,
            id: folder.id,
            modelId: certificate.id
          })
        }
        onFileUploaded={handleFileUploaded}
        onDeleteFile={(file) => {
          setCertificateFileToBeDeleted({
            name: file.name,
            id: file.id,
            modelId: certificate.id
          });
        }}
        onEditFolderRoles={handleChangeFolderRoles}
        onEditFileRole={handleChangeFileRoles}
        onReorderTree={handleReorderTree}
        onReorderingTree={onReorderingTree}
        onCancelReorderingTree={onCancelReorderingTree}
      />
      <Confirm
        title={t(`${ACADEMIC_KIT_TRANSLATE_KEY}.deleteFolder.modalTitle`)}
        onClose={() => setCertificateFolderToBeDeleted(null)}
        onConfirm={handleConfirmDeleteFolder}
        open={!!certificateFolderToBeDeleted}
        isConfirming={isDeletingCertificateFolder}
      >
        <Trans i18nKey={`${ACADEMIC_KIT_TRANSLATE_KEY}.deleteFolder.modalBody`}>
          Are you sure you want to delete
          <strong>{{ name: certificateFolderToBeDeleted?.name }}</strong>
          from academic kit list?
        </Trans>
      </Confirm>
      <Confirm
        title={t(`${ACADEMIC_KIT_TRANSLATE_KEY}.deleteFile.modalTitle`)}
        onClose={() => setCertificateFileToBeDeleted(null)}
        onConfirm={handleConfirmDeleteFile}
        open={!!certificateFileToBeDeleted}
        isConfirming={isDeletingCertificateFile}
      >
        <Trans i18nKey={`${ACADEMIC_KIT_TRANSLATE_KEY}.deleteFile.modalBody`}>
          Are you sure you want to delete
          <strong>{{ name: certificateFileToBeDeleted?.name }}</strong>
          from academic kit list?
        </Trans>
      </Confirm>
    </Box>
  );
};

export default CertificateAcademicKit;
