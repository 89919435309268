import { UserStatus } from 'src/models/user';
import { JobMissionAutonomyLevels, JobStatuses, StudentStatuses, ContractTypes, Gender, LastDiplomaLevel } from 'src/utils/const';
import { StudentStatusExtended } from '../../students/components/common/StudentStatus';

const profile = {
  htmlTitle: 'Profil',
  title: 'Profil',
  subtitle: 'Gestion des données personnelles',
  professionalInfo: {
    sectionTitle: 'Information STAFF',
    sectionSubtitle: 'Gestion des données personnelles',
    edit: 'Modifier',
    professionalInfoSection: {
      title: 'Informations professionnelles',
      subtitle: 'Gestion des informations professionnelles'
    },
    cv: 'CV',
    position: 'Poste',
    officePhone: 'Téléphone Bureau',
    viewCv: 'Voir le CV',
    directLine: 'Ligne directe',
    dateOfBirth: 'Date de naissance',
    placeOfBirth: 'Lieu de naissance',
    postalCodeOfBirth: 'Code postal de la commune de naissance',
    countryOfBirth: 'Pays de naissance',
    addressOfResidence: 'Adresse de résidence',
    placeOfResidence: 'Ville de résidence',
    postalCodeOfResidence: 'Code postal de résidence',
    countryOfResidence: 'Pays de résidence',
    lastDiploma: 'Intitulé du dernier diplôme obtenu',
    levelOfLastDiploma: 'Niveau du dernier diplôme obtenu',
    contractType: 'Type de contrat',
    description: 'Description',
    group: 'Groupe',
    studentStatus: 'Statut',
    studentStatuses: {
      [StudentStatuses.Invited]: 'Invité',
      [StudentStatuses.PendingSchoolVerification]:
        'En attente de vérification scolaire',
      [StudentStatuses.RejectedBySchool]: `Rejeté par l'école`,
      [StudentStatuses.Verified]: 'Vérifié'
    },
    contractTypeOptions: {
      [ContractTypes.FirstDegreeCourse]: 'Formation initiale',
      [ContractTypes.VocationalContract]: 'Contrat de professionnalisation',
      [ContractTypes.ApprenticeshipContract]: 'Contrat d’alternance (apprentissage)',
      [ContractTypes.AccreditationForWorkExperience]: 'VAE ou Formation Continue '
    },
    levelOfLastDiplomaOptions: {
      [LastDiplomaLevel.Level_5]: "Niveau 5",
      [LastDiplomaLevel.Level_6]: "Niveau 6",
      [LastDiplomaLevel.Level_7]: "Niveau 7",
      [LastDiplomaLevel.Level_8]: "Niveau 8"
    },
    studentStatusesExtended: {
      [StudentStatusExtended.Invited]: 'Invité',
      [StudentStatusExtended.InvitationAccepted]: 'Invitation acceptée',
      [StudentStatusExtended.PendingSchoolVerification]:
        'En attente de vérification',
      [StudentStatusExtended.RejectedBySchool]: `Rejeté`,
      [StudentStatusExtended.Verified]: 'Vérifié'
    },
    update: {
      successMessage: 'Données mises à jour avec succès'
    }
  },
  accountInfo: {
    title: 'Données personnelles',
    subtitle: 'Gestion des données personnelles',
    edit: 'Modifier',
    firstName: 'Prénom',
    lastName: 'Nom de famille',
    middleNameOne: 'Deuxième prénom',
    middleNameTwo: 'Troisième prénom',
    email: 'E-mail professionnel',
    secondaryEmail: 'E-mail personnel',
    update: {
      successMessage: 'Données du compte mises à jour avec succès'
    },
    civility: 'Civilité',
    gender: 'Sexe',
    genderOptions: {
      [Gender.Male]: 'Homme',
      [Gender.Female]: 'Femme',
      [Gender.Other]: 'Autre',
    },
    phone: 'Téléphone',
    roles: 'Rôles'
  },
  security: {
    sectionTitle: 'Sécurité',
    sectionSubtitle: 'Modification des préférences de sécurité ci-dessous',
    securitySection: {
      title: 'Modification du mot de passe',
      subtitle: 'Saisie du nouveau de mot passe ici',
      edit: 'Modification du mot de passe'
    },
    update: {
      successMessage: 'Mot de passe mis à jour avec succès'
    }
  },
  common: {
    personalInfoForm: {
      cv: {
        label: 'CV',
        placeholder: 'Cliquer ici pour téléverser le CV',
        placeholderReupload: 'Cliquer ici pour téléverser à nouveau le CV',
        success: 'CV téléversé avec succès',
        uploaded: 'Vous avez téléversé'
      },
      position: {
        label: 'Poste',
        placeholder: "Saisir l'intitulé du poste ici"
      },
      officePhone: {
        label: 'Téléphone bureau',
        placeholder: 'Saisir le numéro ici'
      },
      directLine: {
        label: 'Ligne directe',
        placeholder: 'Saisir le numéro ici'
      },
      dateOfBirth: {
        label: 'Date de naissance',
        placeholder: 'Renseigner la date de naissance ici'
      },
      placeOfBirth: {
        label: 'Lieu de naissance',
        placeholder: 'Renseigner le lieu de naissance ici'
      },
      postalCodeOfBirth: {
        label: "Code postal de la commune de naissance",
        placeholder: "Écrivez-ici le code postal de votre commune de naissance"
      },
      countryOfBirth: {
        label: "Pays de naissance",
        placeholder: "Écrivez-ici votre pays de naissance"
      },
      addressOfResidence: {
        label: "Adresse de résidence",
        placeholder: "Écrivez-ici votre adresse de résidence"
      },
      placeOfResidence: {
        label: "Ville de résidence",
        placeholder: "Écrivez-ici votre ville de résidence"
      },
      postalCodeOfResidence: {
        label: "Code postal de résidence",
        placeholder: "Écrivez-ici le code postal de votre résidence"
      },
      countryOfResidence: {
        label: "Pays de résidence",
        placeholder: "Écrivez-ici votre pays de résidence"
      },
      lastDiploma: {
        label: "Intitulé du dernier diplôme obtenu",
        placeholder: "Écrivez-ici l'intitulé de votre dernier diplôme obtenu"
      },
      levelOfLastDiploma: {
        label: "Niveau du dernier diplôme obtenu",
        placeholder: "Écrivez-ici le niveau de votre dernier diplôme obtenu"
      },
      contractType: {
        label: 'Type de contrat',
        placeholder: 'Écrivez votre type de contrat ici'
      },
      group: {
        label: 'Groupe',
        placeholder: 'Saisir ici'
      }
    },
    accountDetailsForm: {
      firstName: {
        label: 'Prénom',
        placeholder: 'Saisir le prénom ici'
      },
      lastName: {
        label: 'Nom de famille',
        placeholder: 'Saisir le nom de famille ici'
      },
      middleNameOne: {
        label: "Deuxième prénom",
        placeholder: "Écrivez-ici votre deuxième prénom (si existant)"
      },
      middleNameTwo: {
        label: "Troisième prénom",
        placeholder: "Écrivez-ici votre troisième prénom (si existant)"
      },
      civility: {
        label: 'Civilité',
        placeholder: 'Renseigner la civilité ici'
      },
      gender: {
        label: 'Sexe',
        placeholder: 'Écrivez votre sexe ici'
      },
      email: {
        label: 'Email',
        placeholder: 'Entrez le email ici'
      },
      secondaryEmail: {
        label: 'E-mail personnel',
        placeholder: 'Entrez le email ici'
      },
      phone: {
        label: 'Téléphone',
        placeholder: '+123xxxxxxxxx ou 00123xxxxxxxxx'
      },
      avatar: {
        label: 'Avatar',
        placeholder: "Téléverser l'avatar"
      }
    },
    roles: {
      roles: {
        label: 'Rôles',
        placeholder: 'Sélectionner les rôles'
      }
    },
    securityForm: {
      oldPassword: {
        label: 'Ancien mot de passe',
        placeholder: "Saisir l'ancien mot de passe ici"
      },
      newPassword: {
        label: 'Nouveau mot de passe',
        placeholder: 'Saisir le nouveau mot de passe ici'
      },
      newPasswordConfirmation: {
        label: 'Confirmer le nouveau mot de passe',
        placeholder: 'Confirmer le nouveau mot de passe ici'
      }
    },
    studentJob: {
      name: {
        label: 'Nom',
        placeholder: 'Saisir le nom du poste ici'
      },
      departmentMission: {
        label: 'Mission',
        placeholder: 'Saisir la mission du département ici'
      },
      jobMission: {
        label: 'Mission du poste',
        placeholder: 'Saisir la mission du poste ici'
      },
      jobRequiredKnowledge: {
        label: 'Connaissances nécessaires au poste',
        placeholder: 'Saisir les connaissances nécessaires ici'
      },
      jobRequiredKnowHow: {
        label: 'Savoir-faire associés',
        placeholder: 'Indiquer les savoir-faire ici'
      },
      jobExpectedContributions: {
        label: 'Contributions attendues sur le poste',
        placeholder: 'Saisir les contributions attendues ici'
      },
      delete: {
        jobMissions: {
          modalTitle: 'Supprimer la mission',
          modalBody:
            "Etes-vous sûr que vous voulez supprimer <1>{{name}}</1> des missions d'emploi?"
        },
        jobRequiredKnowledge: {
          modalTitle: 'Supprimer les connaissances liées au poste',
          modalBody:
            'Etes-vous sûr que vous voulez supprimer <1>{{name}}</1> de la connaissance requise par le travail?'
        },
        jobRequiredKnowHow: {
          modalTitle: 'Supprimer les savoir-faire liés au poste',
          modalBody:
            'Etes-vous sûr que vous voulez supprimer <1>{{name}}</1> du travail requis savoir-faire?'
        },
        jobExpectedContributions: {
          modalTitle: 'Supprimer les contributions attendues pour le poste',
          modalBody:
            "Etes-vous sûr que vous voulez supprimer <1>{{name}}</1> de la contribution attendue de l'emploi?"
        }
      }
    },
    jobMissions: {
      boxTitle: 'Missions du poste',
      addJobMission: 'Ajouter',
      modalTitle: 'Mission du poste',
      modalSubtitle: 'Gestion de la mission',
      form: {
        jobMissionName: {
          label: 'Nom',
          placeholder: 'Saisir le nom de la mission ici'
        },
        activity: {
          label: 'Activité',
          placeholder: "Saisir l'activité ici"
        },
        autonomyLevel: {
          label: "Niveau d'autonomie ici",
          placeholder: "Saisir le niveau d'autonomie ici"
        }
      },
      noJobMissionAdded: 'Aucune mission',
      autonomyLevels: {
        [JobMissionAutonomyLevels.DecideAlone]: 'Décide seul',
        [JobMissionAutonomyLevels.DecideAfterInfo]:
          'Décide après en avoir informé',
        [JobMissionAutonomyLevels.DecideAfterApproval]:
          "Décide après approbation d'un supérieur",
        [JobMissionAutonomyLevels.Execute]:
          'Exécute la tâche sans prise de décision'
      }
    },
    jobRequiredKnowledge: {
      boxTitle: 'Connaissances et compétences associées',
      addJobRequiredKnowledge: 'Ajouter',
      modalTitle: 'Connaissances et compétences associées requises',
      modalSubtitle: 'Gestion des connaissances et compétences associées',
      form: {
        knowledge: {
          label: 'Connaissance',
          placeholder: 'Saisir les connaissances requises ici'
        }
      },
      noJobRequiredKnowledgeAdded: 'Aucune connaissance ajoutée'
    },
    jobRequiredKnowHow: {
      boxTitle: 'Savoir-faire et attendu pour le métier visé',
      addJobRequiredKnowHow: 'Ajouter',
      modalTitle: 'Savoir-faire et attendu pour le métier visé requis',
      modalSubtitle: 'Gestion des et attendu pour le métier visé requis',
      form: {
        knowHow: {
          label: 'Savoir-faire',
          placeholder: 'Saisir les savoir-faire ici'
        }
      },
      noJobRequiredKnowHowAdded: 'Aucun savoir faire ajouté'
    },
    jobExpectedContributions: {
      boxTitle: 'Contributions',
      addJobRequiredKnowHow: 'Ajouter',
      modalTitle: 'Contribution attendue',
      modalSubtitle: 'Gestion des contributions',
      form: {
        contribution: {
          label: 'Contribution',
          placeholder: 'Saisir une contribution ici'
        },
        objective: {
          label: 'Objectif',
          placeholder: "Saisir l'objectif ici"
        }
      },
      noJobExpectedContributionsAdded: 'Aucune contribution ajoutée'
    }
  },
  schoolEmployee: {
    htmlTitle: 'Collaborateur école | {{name}}',
    title: '{{name}} - Profil',
    subtitle: 'Gestion des données collaborateur'
  },
  companyEmployee: {
    htmlTitle: 'Collaborateur entreprise | {{name}}',
    title: '{{name}} - Profil',
    subtitle: 'Gestion des données collaborateur'
  },
  certifier: {
    htmlTitle: 'Certificateur | {{name}}',
    title: '{{name}} - Profil',
    subtitle: 'Gestion des données certificateur'
  },
  roles: {
    rolesSection: {
      title: 'Rôles utilisateur',
      subtitle: 'Gestion des rôles utilisateur'
    },
    roles: 'Rôles',
    update: {
      successMessage: 'Mise à jour des rôles avec succès'
    }
  },
  student: {
    htmlTitle: 'Étudiant | {{name}}',
    title: '{{name}} - Profil',
    subtitle: "Gestion des données de l'étudiant",
    backToList: 'Retour aux étudiants',
    backToCertificationList: `Retour aux dossier d'expertise professionnelle`,
    verifyStudent: 'Valider',
    rejectStudent: 'Rejeter',
    modalTitle: "Statut de l'étudiant",
    modalBody: 'Êtes-vous sûr de vouloir mettre à jour <1>{{name}}</1> statut?',
    assignTutor: {
      modalButton: 'Affecter un tuteur',
      modalTitle: 'Affecter un tuteur',
      successMessage: 'Tuteur affecté avec succès',
      createNewNoticeTitle: "Avis",
      createNewNoticeMessage: "La fiche de poste existe déjà. Cette action créera une nouvelle fiche de poste vide tandis que la description actuelle sera gelée."
    },
    assignTutorFields: {
      companies: 'Entreprises',
      tutors: 'Tuteurs'
    },
    blocks: {
      tabTitle: 'Blocs'
    },
    flow: {
      regular: 'Régulière',
      regularSubtitle: 'Apprenant actif',
      onHold: 'En Pause',
      onHoldSubtitle: 'Apprenant en pause ',
      removeOnHold: 'Réactiver le compte apprenant',
      redemption: 'Rattrapage',
      inRedemption: 'En rattrapage',
      inRedemptionSubtitle: 'Apprenant en rattrapage'
    },
    certificationPassport: {
      tabTitle: 'Passeport et CV à jour',
      certificationPassportDropzoneTitle: "Passeport et CV à jour",
      noPassportUploaded: "Aucun passeport de certification n'a été téléchargé!",
      certificationPassportAlert: {
        waitingCertificationPassportTitle: 'Documents en attente de téléchargement',
        waitingCertificationPassportText:
          'Télécharger le passeport de certification et demander une vérification',
        waitingVerificationCertificationPassportTitle: 'Vérification en cours',
        waitingVerificationCertificationPassportText:
          'En attente de la vérification du passeport de certification par un référent scolaire',
        requestVerification: 'Demande de vérification',
        requestVerificationModalTitle: 'Demande de certification Vérification du passeport',
        requestVerificationModalText:
          'Êtes-vous sûr de vouloir demander une vérification ?',
        requestedTitle: 'Vérification en cours',
        requestedText:
          'Le passeport de certification est envoyé pour vérification. Vous serez informé de la vérification.',
        requestToastSuccess: 'Envoyé pour vérification',
        verifyModalTitle: 'Vérifier le passeport de certification',
        verifyModalDescription: 'Êtes-vous sûr de vouloir vérifier le document ?',
        rejectedTitle: 'Rejet du passeport de certification',
        rejectedDescription: 'Quelque chose ne va pas avec la certification Le passeport et le référent scolaire les ont rejetés',
        rejectModalTitle: 'Rejeter le passeport de certification',
        rejectModalDescription: `Envoyer un message d'explication.`
      }
    },
    evaluationConfigurations: {
      tabTitle: `Grilles d'évaluation`
    },
    job: {
      title: 'Fiche de poste',
      subtitle: 'Gestion de la fiche de poste apprenant',
      name: 'Nom',
      departmentMission: "Mission au sein de l'équipe",
      jobMission: 'Mission du poste',
      jobRequiredKnowledge: "Connaissances requises pour l'emploi",
      jobRequiredKnowHow: "Savoir-faire requis pour l'emploi",
      jobExpectedContribution: 'Contribution attendue sur le poste',
      update: {
        successMessage: 'Mise à jour du poste avec succès'
      },
      modalTitle: 'Mettre à jour le statut du poste',
      verify: {
        button: 'Valider'
      },
      reject: {
        button: 'Rejeter'
      },
      jobStatus: 'Statut',
      statuses: {
        [JobStatuses.NotVerified]: 'Non validé',
        [JobStatuses.Rejected]: 'Rejetée',
        [JobStatuses.SubmittedByStudent]: `Transmis par l'étudiant`,
        [JobStatuses.VerifiedBySchool]: `Validé par l'école`
      },
      reviewAlert: {
        requestVerificationTitle: 'Soumettre la fiche de poste',
        requestVerificationText:
          'Validation de la fiche de poste par un référent école',
        requestVerificationButton: 'Soumettre la demande',
        requestVerificationButtonDisabledTooltip: 'Soumettre la demande',
        pendingVerificationTitle: 'Vérification en cours',
        pendingVerificationText: 'Vérification par un référent école en cours',
        missingMinimalRequirementsTitle: 'Saisir la fiche de poste',
        missingMinimalRequirementsText: 'Merci de saisir a minima les missions de la fiche de poste avant de soumettre pour approbation',
        verifyTitle: 'Validation de la fiche de poste',
        successfullyVerifiedTitle: 'Fiche de poste validée',
        successfullyVerifiedText:
          'Fiche de poste validée par un référent école',
        rejectedTitle: 'Rejeter la fiche de poste',
        rejectedText:
          "La fiche de poste n'est pas conforme et un référent école l'a rejetée"
      },
      requestVerification: {
        title: 'Soumettre la fiche de poste',
        body: 'Soumettre la fiche de poste à un référent école'
      },
      deadline: {
        school: {
          expiredText:
            'La deadline pour la tâche "Verify job description" est atteinte.'
        },
        company: {
          expiredText:
            'La deadline pour la tâche "Add job description" est atteinte.'
        }
      },
      actions: {
        createNewJob: {
          title: 'Créer une nouvelle fiche de poste',
          successMessage: 'TTuteur assigné avec succès !'
        },
        replaceTutorOnJob: {
          title: 'Remplacer le tuteur sur la fiche de poste',
          successMessage: 'Tuteur remplacé avec succès !'
        },
        verifyJob: {
          title: 'Vérifier la description du poste',
          noticeMessage: 'Cette action est irréversible, la description du poste sera vérifiée et gelée.'
        },
        rejectJob: {
          title: 'Rejeter la description du poste',
          subtitle: "Envoyez un message d'explication.",
          forceRejectDefaultMessage: "La description du poste est restée à l'état de projet.",
        }
      },
      frozenJobsTable: {
        columns: {
          company: "Entreprise",
          tutorName: "Tuteur",
          tutorEmail: "Email",
          status: "Statut",
          frozenAt: "Gelé sur",
          createdAt: 'Créé le',
        }
      }
    },
    pendingVerification: {
      alertTitle: 'Vérification en cours',
      alertSubtitle:
        "L'étudiant <1>{{firstName}} {{lastName}}</1> a téléversé son CV et son diplôme et attend une validation."
    },
    pendingForJob: {
      alertTitle: 'Affectation du tuteur en cours',
      alertSubtitle:
        "L'étudiant <1>{{firstName}} {{lastName}}</1> n'a pas d'entreprise associée."
    },
    uploadDocuments: {
      title: 'Documents',
      subtitle: "Gestion des documents de l'étudiant",
      cvDropzoneTitle: 'Téléverser le CV ici',
      diplomaDropzoneTitle: 'Téléverser le diplôme ici',
      cv: 'CV',
      diploma: 'Diplôme',
      noCvUploaded: 'Aucun CV',
      noDiplomaUploaded: 'Aucun diplôme',
      update: {
        successMessage: 'Documents sauvegardés avec succès'
      },
      notAllowed: 'Modification de documents non autorisée',
      reviewAlert: {
        rejectedTitle: 'Les documents sont rejetés',
        rejectedText:
          "Les documents soumis sont non conformes (CV & diplôme) et l'école les a rejetés",
        rejectedMessageReason: "Voir le message de l'école",
        verifiedTitle: 'Les documents sont approuvés',
        verifiedText: 'Un référent école a validé les documents'
      },
      deadline: {
        docs: {
          expiredText:
            'La deadline pour la tâche "Upload Student Documents" est atteinte.'
        },
        docsVerify: {
          expiredText:
            'La deadline pour la tâche "Verify Student Documents" est atteinte.'
        },
        tutor: {
          expiredText:
            'La deadline pour la tâche "Assign tutor to the student" est atteinte.'
        }
      },
      documentsAlert: {
        waitingDocumentsTitle: 'Documents en cours à téléverser',
        waitingDocumentsText:
          "Téléverser les documents demandés et faire une requête de validation par l'école",
        waitingOrUploadDocumentsTextSchool:
          'Téléverser les documents demandés et faire une requête de validation',
        waitingDocumentsTextSchool:
          'En attente des documents à téléverser et de la validation école',
        requestVerification: 'Demander la validation école',
        requestVerificationModalTitle: 'Demander la validation des documents',
        requestVerificationModalText: 'Confirmer la demande de validation ?',
        requestedTitle: 'Vérification école en cours',
        requestedText:
          "Les documents ont été envoyés pour validation, vous recevrez une notification de l'école"
      }
    },
    evaluations: {
      tabTitle: 'Tableau de bord des Evaluations'
    },
    finalExam: {
      title: 'Examen final',
      finalExampAlerts: {
        info: {
          title: 'Examen final',
          description:
            "Cet étudiant n'a pas de président de jury affecté pour l'examen final."
        },
        success: {
          title: 'Examen final',
          description: 'Examen final programmé le'
        },
        actions: {
          button: 'Affecter un président de jury'
        }
      },
      finalExamAlertsMember: {
        info: {
          title: 'Affecter un membre de jury',
          description:
            "Affecter un membre de jury à l'examen final de cet étudiant"
        },
        actions: {
          button: 'Affecter un membre de jury'
        }
      },
      type: {
        president: 'Président de jury',
        member: 'Membre de jury'
      },
      table: {
        columns: {
          name: 'Nom',
          type: 'Type'
        },
        delete: {
          successMessage: 'Jury supprimé avec succès',
          errorMessage: 'Erreur lors de la suppression du Jury',
          modalTitle: 'Supprimer le Jury',
          modalBody: 'Confirmer la suppression ?'
        },
        assignedJury: {
          modalTitle: 'Président de Jury affecté'
        },
        assignedJuryMember: {
          modalTitle: 'Membre de Jury affecté'
        }
      },
      assignedJury: {
        name: {
          label: 'Président de Jury'
        },
        date: {
          label: 'Date'
        },
        successMessage: 'Affectation du Président de Jury confirmée',
        noOptions: 'Aucune option'
      },
      assignedJuryMember: {
        name: {
          label: 'Membre de Jury'
        },
        successMessage: 'Affectation du membre de Jury confirmée',
        noOptions: 'Aucune option'
      },
      juriesTable: 'Jury'
    }
  },
  tutorInfo: {
    tutorInfoSection: {
      title: 'Tuteur',
      subtitle: 'Voir les détails Tuteur',
      tutorNotFound: 'Tuteur introuvable'
    },
    firstName: 'Prénom',
    lastName: 'Nom de famille',
    email: 'E-mail',
    position: 'Poste',
    officePhone: 'Téléphone bureau',
    directLine: 'Ligne directe'
  },
  userStatus: {
    [UserStatus.Invited]: "Invité",
    [UserStatus.Active]: "Active"
  }
};

export default profile;
