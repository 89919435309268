import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { IApiError } from '../../../../utils/axios';
import {
  updateStudentPersonalInfo as updatePersonalInfoApi,
  UPDATE_STUDENT_PERSONAL_INFO_KEY
} from '../../utils/api';

const UPDATE_PERSONAL_INFO_TRANSLATE_KEY = 'profile.professionalInfo.update';

const useUpdateStudentPersonalInfo = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const {
    mutate: updateStudentPersonalInfo,
    isLoading: isUpdatingStudentPersonalInfo
  } = useMutation(({ data, url }: any) => updatePersonalInfoApi(data, url), {
    mutationKey: UPDATE_STUDENT_PERSONAL_INFO_KEY,
    onSuccess: (data) => {
      enqueueSnackbar(
        t(`${UPDATE_PERSONAL_INFO_TRANSLATE_KEY}.successMessage`),
        {
          variant: 'success'
        }
      );
    },
    onError: (error: IApiError) => {
      enqueueSnackbar(error.message, {
        variant: 'error'
      });
    }
  });

  return {
    updateStudentPersonalInfo,
    isUpdatingStudentPersonalInfo
  };
};

export default useUpdateStudentPersonalInfo;
