import { useTranslation } from 'react-i18next';
import { Grid, Button } from '@mui/material';
import { Box } from '@mui/system';
import { Mode } from 'src/utils/types';
import { Formik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import * as Yup from 'yup';
import { IUpdateCertifierPersonalInfo } from '../../../utils/types';
import CertifierPersonalInfoFields from './CertifierEmployeePersonalInfoFields';
import { IUser } from 'src/models/user';
import useFetchFileUrl from '../../../../../components/FileTree/useFetchFileUrl';
import InfoItem from '../InfoItem';

const PROFILE_CERTIFIER_PERSONAL_INFO_TRANSLATE_KEY =
  'profile.professionalInfo';
const VALIDATION_TRANSLATE_KEY = 'common.validation';

interface IProps {
  handleEdit(data: IUpdateCertifierPersonalInfo): void;
  personalInfoLocalMode: Mode;
  isUpdatingPersonalInfo: boolean;
  setPersonalInfoLocalMode(Mode: Mode): void;
  user: IUser;
  storeCvUrl: string;
}

const CertifierEmployeePersonalInfoDetails: React.FC<IProps> = ({
  handleEdit,
  personalInfoLocalMode,
  isUpdatingPersonalInfo,
  setPersonalInfoLocalMode,
  user,
  storeCvUrl
}) => {
  const { t } = useTranslation();
  const { fetchFileUrl } = useFetchFileUrl();

  const handleRedirectToFile = (cv: string) => {
    fetchFileUrl(cv, {
      onSuccess: ({ url }) => {
        window.open(url, '_blank');
      }
    });
  };

  if (personalInfoLocalMode === Mode.View) {
    return (
      <Grid
        container
        sx={{
          display: 'flex',
          boxSizing: 'border-box',
          flexFlow: 'flex-wrap',
          width: '100%'
        }}
      >
        <InfoItem
          property={t(`${PROFILE_CERTIFIER_PERSONAL_INFO_TRANSLATE_KEY}.cv`)}
        >
          {user.userable.cv ? (
            <Button
              variant="outlined"
              size="small"
              sx={{
                minWidth: '100px',
                mt: -1
              }}
              onClick={() => handleRedirectToFile(user.userable.cv)}
            >
              {t(`${PROFILE_CERTIFIER_PERSONAL_INFO_TRANSLATE_KEY}.viewCv`)}
            </Button>
          ) : (
            '-'
          )}
        </InfoItem>
        <InfoItem
          property={t(
            `${PROFILE_CERTIFIER_PERSONAL_INFO_TRANSLATE_KEY}.position`
          )}
          value={user.userable.position}
        />
        <InfoItem
          property={t(
            t(`${PROFILE_CERTIFIER_PERSONAL_INFO_TRANSLATE_KEY}.officePhone`)
          )}
          value={user.userable.officePhone}
        />
      </Grid>
    );
  }

  return (
    <div>
      <Formik<IUpdateCertifierPersonalInfo>
        initialValues={{
          cv: user.userable.cv ?? '',
          position: user.userable.position ?? '',
          officePhone: user.userable.officePhone ?? ''
        }}
        onSubmit={handleEdit}
        validationSchema={Yup.object().shape({
          cv: Yup.string()
            .max(
              255,
              t(`${VALIDATION_TRANSLATE_KEY}.max255Characters`, {
                field: t(`${VALIDATION_TRANSLATE_KEY}.cvField`)
              })
            )
            .nullable(),
          position: Yup.string()
            .max(
              255,
              t(`${VALIDATION_TRANSLATE_KEY}.max255Characters`, {
                field: t(`${VALIDATION_TRANSLATE_KEY}.positionField`)
              })
            )
            .nullable(),
          officePhone: Yup.string()
            .max(
              255,
              t(`${VALIDATION_TRANSLATE_KEY}.max255Characters`, {
                field: t(`${VALIDATION_TRANSLATE_KEY}.officePhoneField`)
              })
            )
            .nullable()
        })}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <CertifierPersonalInfoFields storeCvUrl={storeCvUrl} />
            <Box display="flex" justifyContent="flex-end" mt={3}>
              <Button
                color="secondary"
                size="medium"
                variant="outlined"
                onClick={() => setPersonalInfoLocalMode(Mode.View)}
              >
                {t('common.buttons.cancel')}
              </Button>
              <LoadingButton
                loading={isUpdatingPersonalInfo}
                sx={{
                  ml: 2
                }}
                variant="contained"
                size="medium"
                type="submit"
              >
                {t('common.buttons.save')}
              </LoadingButton>
            </Box>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default CertifierEmployeePersonalInfoDetails;
