
import { useSnackbar } from 'notistack';
import { useMutation, useQueryClient } from 'react-query';
import { IApiError } from 'src/utils/axios';
import {
  UPDATE_GLOBAL_AUDITOR_RECOMMENDATION_KEY,
  FETCH_STUDENT_BLOCKS_KEY,
  updateGlobalAuditorRecommendation as updateGlobalAuditorRecommendationApi
} from '../utils/api';
import { FETCH_STUDENT_CERTIFICATION_KEY } from 'src/features/profile/utils/api';

const useUpdateGlobalAuditorRecommendation = () => {
  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const {
    mutate: updateGlobalAuditorRecommendation,
    isLoading: isUpdatingGlobalAuditorRecommendation
  } = useMutation(
    ({ studentId, data }: any) =>
      updateGlobalAuditorRecommendationApi(studentId, data),
    {
      mutationKey: UPDATE_GLOBAL_AUDITOR_RECOMMENDATION_KEY,
      onSuccess: () => {
        queryClient.invalidateQueries([FETCH_STUDENT_BLOCKS_KEY]);
        queryClient.invalidateQueries([FETCH_STUDENT_CERTIFICATION_KEY]);
      },
      onError: (error: IApiError) => {
        enqueueSnackbar(error.message, {
          variant: 'error'
        });
      }
    }
  );

  return {
    updateGlobalAuditorRecommendation,
    isUpdatingGlobalAuditorRecommendation
  };
};

export default useUpdateGlobalAuditorRecommendation;
