import { useQuery } from 'react-query';
import { ISchool } from 'src/models/school';
import { IApiError } from '../../../../utils/axios';
import {
  fetchCohortSchools,
  FETCH_COHORT_SCHOOLS_KEY
} from '../../utils/api';

const useFetchCohortSchools = (id: string, options?: any) => {
  const {
    isLoading: isFetchingCohortSchools,
    error: errorFetchingCohortSchools,
    data: schools
  } = useQuery<ISchool[], IApiError>(
    [FETCH_COHORT_SCHOOLS_KEY, id],
    () => fetchCohortSchools(id),
    options
  );

  return {
    isFetchingCohortSchools,
    errorFetchingCohortSchools,
    schools
  };
};

export default useFetchCohortSchools;
