import { useQuery } from 'react-query';
import { ISchool } from '../../../../models/school';
import { IApiError } from '../../../../utils/axios';
import { fetchSchool, FETCH_SCHOOL_KEY } from '../../utils/api';

const useFetchSchool = (id: string) => {
  const {
    isLoading: isFetchingSchool,
    error: errorFetchingSchool,
    data: school
  } = useQuery<ISchool, IApiError>([FETCH_SCHOOL_KEY, id], () =>
    fetchSchool(id)
  );

  return {
    isFetchingSchool,
    errorFetchingSchool,
    school
  };
};

export default useFetchSchool;
