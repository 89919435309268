import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { TaskStatuses } from 'src/utils/const';
import { IApiError } from '../../../../utils/axios';
import {
  FETCH_TASKS_CREATED_BY_ME_KEY,
  FETCH_TASKS_KEY,
  updateTaskStatus as updateTaskStatusApi,
  UPDATE_TASK_STATUS_KEY
} from '../../utils/api';

const useUpdateTaskStatus = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const [taskUpdateStatus, setTaskUpdateStatus] = useState<any>();

  const { mutate: updateTaskStatus, isLoading: isUpdatingTaskStatus } =
    useMutation(
      (status: TaskStatuses) =>
        updateTaskStatusApi(taskUpdateStatus.id, status),
      {
        mutationKey: UPDATE_TASK_STATUS_KEY,
        onSuccess: () => {
          queryClient.invalidateQueries([FETCH_TASKS_CREATED_BY_ME_KEY]);
          queryClient.invalidateQueries([FETCH_TASKS_KEY]);
        },
        onError: (error: IApiError) => {
          enqueueSnackbar(error.message, {
            variant: 'error'
          });
        }
      }
    );

  return {
    taskUpdateStatus,
    setTaskUpdateStatus,
    updateTaskStatus,
    isUpdatingTaskStatus
  };
};

export default useUpdateTaskStatus;
