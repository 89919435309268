import { useSnackbar } from 'notistack';
import { IFile } from '../../../../../models/file';

import { createCertificateFile as createCertificateFileApi } from '../../../utils/api';

const useCreateCertificateFile = () => {
  const { enqueueSnackbar } = useSnackbar();

  const createCertificateFile = async (data: Partial<IFile>, options: any) => {
    try {
      const response = await createCertificateFileApi(data);
      if (options.onSuccess) {
        options.onSuccess(response, data);
      }
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: 'error'
      });
      if (options.onError) {
        options.onError(error);
      }
    }
  };

  return {
    createCertificateFile
  };
};

export default useCreateCertificateFile;
