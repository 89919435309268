import { Stack } from '@mui/material';
import useHaveRole from 'src/hooks/useHaveRole';
import { RoleName } from 'src/models/role';
import useCan from '../../../../hooks/useCan';
import { ITask } from '../../../../models/task';
import { IUser } from '../../../../models/user';
import { Permissions, StudentStatuses } from '../../../../utils/const';
import TaskDeadlineAlert from '../../../tasks/components/common/TaskDeadlineAlert';
import Invited from './alerts/Invited';
import PendingSchoolVerification from './alerts/PendingSchoolVerification';
import RejectedBySchool from './alerts/RejectedBySchool';
import Verified from './alerts/Verified';

interface IProps {
  student: IUser;
  task: ITask;
  isTaskOverdue: boolean;
  isTaskExpired: boolean;
  canRequestVerification: boolean;
  toggleRequestVerificationModal(): void;
  setStatus(status: StudentStatuses): void;
}

const StudentDocumentsStatusAlert: React.FC<IProps> = ({
  student,
  isTaskOverdue,
  isTaskExpired,
  task,
  canRequestVerification,
  setStatus,
  toggleRequestVerificationModal
}) => {
  const isOrgAdmin = useHaveRole(RoleName.OrganisationAdmin);
  const canManageStudents = useCan(Permissions.ManageStudents);

  // If student is not enrolled and task is overdue show alert
  if (
    student.userable.studentStatus ===
      StudentStatuses.PendingSchoolVerification &&
    isTaskOverdue &&
    !isOrgAdmin
  ) {
    return (
      <Stack sx={{ mb: 2 }} spacing={2}>
        <TaskDeadlineAlert task={task} />
      </Stack>
    );
  }

  if (student.userable.studentStatus === StudentStatuses.Invited) {
    return (
      <Invited
        student={student}
        isOrgAdmin={isOrgAdmin}
        isTaskExpired={isTaskExpired}
        canManage={canManageStudents}
        canRequestVerification={canRequestVerification}
        toggleRequestVerificationModal={toggleRequestVerificationModal}
      />
    );
  }

  if (
    student.userable.studentStatus === StudentStatuses.PendingSchoolVerification
  ) {
    return (
      <PendingSchoolVerification
        student={student}
        setStatus={setStatus}
        canManage={canManageStudents && !isTaskExpired}
      />
    );
  }

  if (student.userable.studentStatus === StudentStatuses.RejectedBySchool) {
    return (
      <RejectedBySchool
        student={student}
        setStatus={setStatus}
        isTaskExpired={isTaskExpired}
        isOrgAdmin={isOrgAdmin}
        canManage={canManageStudents && !isTaskExpired}
      />
    );
  }

  if (student.userable.studentStatus === StudentStatuses.Verified) {
    return (
      <Verified
        student={student}
        setStatus={setStatus}
        isTaskExpired={isTaskExpired}
        isOrgAdmin={isOrgAdmin}
        canManage={canManageStudents && !isTaskExpired}
      />
    );
  }

  return null;
};

export default StudentDocumentsStatusAlert;
