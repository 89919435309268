import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useTranslation, Trans } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { Box } from '@mui/system';
import { Grid, Tab } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import TabList from '@mui/lab/TabList';
import PageLoader from 'src/components/PageLoader';
import Confirm from 'src/components/Confirm';
import ApiError from 'src/components/ApiError';
import { StudentRoutes } from 'src/features/students/routes';
import { StudentStatuses } from 'src/utils/const';
import useFetchStudent from '../hooks/api/useFetchStudent';
import useTab from 'src/hooks/useTab';
import useToggleVisibility from 'src/hooks/useToggleVisibility';
import useHaveRole from 'src/hooks/useHaveRole';
import useCan from 'src/hooks/useCan';
import useUpdateStatus from '../hooks/api/useUpdateStatus';
import useFetchStudentJob from '../hooks/api/job/useFetchStudentJob';
import useFetchStudentCertification from '../hooks/api/useFetchStudentCertification';
import useAssignTutor from '../hooks/api/useAssignTutor';
import StudentPersonalInfoSection from '../components/profile/Student/StudentPersonalInfoSection';
import AccountInfoSection from '../components/profile/AccountInfoSection';
import AssignTutorModal from '../components/profile/Student/AssignTutorModal';
import AssignTutorFields from '../components/profile/Student/AssignTutorFields';
import { ICreateRejectMessageForm } from 'src/models/course';
import { IUser, UserableType } from 'src/models/user';
import { RoleName } from 'src/models/role';
import { Mode } from 'src/utils/types';
import { IAssignTutor } from '../utils/types';
import { Permissions } from 'src/utils/const';
import { TaskCodes } from '../../tasks/utils/types';
import { printUserName, userHasAnyPermission } from 'src/utils/userHelpers';
import TutorSection from '../components/profile/Student/TutorSection';
import StudentJobSection from '../components/profile/StudentJob/StudentJobSection';
import RejectConfirm from 'src/features/cohorts/components/common/RejectConfirm';
import useAuth from 'src/features/auth/hooks/useAuth';
import useTaskOverdue from '../../tasks/hooks/api/useTaskOverdue';
import AssignTutorToStudentAlert from '../../students/components/student/AssignTutorToStudentAlert';
import PageHeader from '../../students/components/student/PageHeader';
import StudentDocumentsStatusAlert from '../../students/components/student/StudentDocumentsStatusAlert';
import StudentBlocksTab from '../components/profile/Student/StudentBlocksTab';
import StudentCertificationPassportTab from '../components/profile/Student/StudentCertificationPassportTab/StudentCertificationPassportTab';
// import FinalExamTab from '../../final-exams/components/final-exam-tab/FinalExamTab';
import StudentMultiDocumentsSection from '../components/profile/Student/StudentDocuments/StudentMultiDocumentsSection';
import useUpdateStudentDocuments from '../hooks/api/useUpdateStudentDocuments';
import StudentEvaluations from '../../evaluations/pages/StudentEvaluations';
import { isSchoolEmployee, isCertifierEmployee } from 'src/utils/userTypes';
import { FETCH_STUDENT_KEY, FETCH_STUDENT_JOB_KEY, FETCH_TUTOR_KEY, FETCH_STUDENT_JOBS_KEY } from '../utils/api';
import useUpdateStudentJob from '../hooks/api/job/useUpdateStudentJob';
import useFetchStudentJobs from '../hooks/api/job/useFetchStudentJobs';
import JobsTable from '../components/profile/StudentJob/JobsTable';
import StudentEvaluationConfigurations from 'src/features/evaluations/pages/StudentEvaluationConfigurations';
import { canUserCreateEvaluations } from 'src/features/evaluations/utils/evaluationsHelpers';
import StudentPasswordCertificationStatusAlert from 'src/features/profile/components/profile/Student/StudentCertificationPassportTab/StudentPasswordCertificationStatusAlert';
import StudentFlowAlert from 'src/features/students/components/student/StudentFlowAlert';
import StudentFlowConfirm from 'src/features/students/components/student/StudentFlowConfirm';
import useUpdateStudentFlow from '../hooks/api/useUpdateStudentFlow';

const STUDENT_TRANSLATE_BASE_KEY = 'profile.student';

export enum Tabs {
  UserInfo = 'UserInfo',
  StudentDocuments = 'Documents',
  JobDescription = 'JobDescription',
  Blocks = 'Blocks',
  Evaluations = 'Evaluations',
  EvaluationConfigurations = 'EvaluationConfigurations',
  FinalExam = 'FinalExam',
  CertificationPassport = 'CertificationPassport'
}

enum StudentGetRoutes {
  Tutor = '/assigned-students/',
  Other = '/students/'
}

export const resolveGetStudentRoute = (user: IUser, studentId: string) => {
  return user.userableType === UserableType.CompanyEmployee
    ? `${StudentGetRoutes.Tutor}${studentId}`
    : `${StudentGetRoutes.Other}${studentId}`;
};

const Profile = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { studentId } = useParams();
  const { user } = useAuth();

  /** @todo canManageJobs permission shod be disabled for Certifier Admin */
  const canManageTutor = isSchoolEmployee(user);

  const [searchParams] = useSearchParams({
    mode: Mode.View
  });

  const mode = searchParams.get('mode') as Mode;

  const [canRequestVerification, setCanRequestVerification] = useState(false);
  const [canRequestPassportVerification, setCanRequestPassportVerification] = useState(false);
  const {
    isVisible: isVisibleRequestVerificationModal,
    toggle: toggleRequestVerificationModal
  } = useToggleVisibility(false);

  const { tab, setTab } = useTab(Tabs.UserInfo);

  const handleTabChange = (_: React.SyntheticEvent, newValue: Tabs) => {
    setTab(newValue);
  };

  const fetchStudentUrl = resolveGetStudentRoute(user, studentId);

  const { student, isFetchingStudent, errorFetchingStudent } =
    useFetchStudent(fetchStudentUrl);

  const { job, isFetchingStudentJob } = useFetchStudentJob(studentId);

  const { studentCertification, isFetchingStudentCertification } = useFetchStudentCertification(studentId);

  const canReadStudentDocuments = useCan(Permissions.ReadStudentDocuments);
  const canManageStudentDocuments = useCan(Permissions.ManageStudentDocuments);

  const canReadJob = useCan(Permissions.ReadJob);
  const canManageJobs = useCan(Permissions.ManageJobs);

  const { jobs: allJobs, isFetchingJobs: isFetchingFrozenJobs } = useFetchStudentJobs(studentId, { enabled: canManageJobs && canManageTutor });

  const canManageStudents = useCan(Permissions.ManageStudents);

  const canReadJury = useCan(Permissions.ReadJury);
  const canReadBlock = useCan(Permissions.ReadStudentBlocks);

  const isOrgAdmin = useHaveRole(RoleName.OrganisationAdmin);

  const canCreateEvaluations = canUserCreateEvaluations(user);

  const {
    isTaskOverdue: isAssignTutorOverdue,
    task: assignTutorTask,
    isFetchingTask: isFetchingAssignTutorTaskOverdue
  } = useTaskOverdue(
    {
      code: TaskCodes.AssignTutorToStudent,
      meta: {
        studentId
      }
    },
    { enabled: !!(student && !isOrgAdmin && canManageJobs) }
  );

  const {
    isTaskExpired: isVerifyStudentDocsTaskExpired,
    isTaskOverdue: isVerifyStudentDocsTaskOverdue,
    task: verifyStudentDocsTask,
    isFetchingTask: isFetchingVerifyStudentDocsTask
  } = useTaskOverdue(
    {
      code: TaskCodes.VerifyStudentDocuments,
      meta: {
        studentId
      }
    },
    { enabled: !!(student && !isOrgAdmin && canManageStudents) }
  );

  const [status, setStatus] = useState<string>();

  const [passportStatus, setPassportStatus] = useState<string>();

  const {
    isVisible: isVisibleAssignModal,
    show: showAssignModal,
    hide: hideAssignModal
  } = useToggleVisibility(false);

  const [isCreatingNewJob, setIsCreatingNewJob] = useState<boolean>(Boolean(job) === false);

  const {
    updateUrl,
    updateStatusUrl,
    uploadDocumentUrl,
    requestVerificationUrl,
    deleteUrl
  } = useMemo(() => {
    const updateUrl = StudentRoutes.Student.replace(':studentId', studentId);

    const updateStatusUrl = `${updateUrl}/documents-status`;

    const uploadDocumentUrl = `${updateUrl}/documents`;

    const requestVerificationUrl = `${updateUrl}/documents/request-verification`;

    const deleteUrl = `${updateUrl}/documents`;

    return {
      updateUrl,
      updateStatusUrl,
      uploadDocumentUrl,
      requestVerificationUrl,
      deleteUrl
    };
  }, [studentId]);

  const { updateDocuments, isUpdatingDocuments } = useUpdateStudentDocuments();

  const { updateStatus, isUpdatingStatus } = useUpdateStatus(updateStatusUrl);

  const { assignTutor, isAssigningTutor } = useAssignTutor();

  const { updateStudentJob, isUpdatingStudentJob } = useUpdateStudentJob(studentId, job?.id);

  const handleStatusUpdated = () => {
    setStatus(null);
    queryClient.invalidateQueries([FETCH_STUDENT_KEY, fetchStudentUrl]);
  };

  const handleUpdateStatus = () => {
    updateStatus(
      { status, message: '' },
      {
        onSuccess: () => {
          handleStatusUpdated();
        }
      }
    );
  };

  const handleUpdateStatusWithMessage = (data: ICreateRejectMessageForm) => {
    updateStatus(
      { status, message: data.message },
      {
        onSuccess: () => {
          handleStatusUpdated();
        }
      }
    );
  };

  const handleAssignTutor = (data: IAssignTutor) => {
    const refresh = () => {
      queryClient.invalidateQueries([FETCH_STUDENT_KEY, fetchStudentUrl]);
      queryClient.invalidateQueries([FETCH_STUDENT_JOB_KEY, student.userable.id]);
      queryClient.invalidateQueries([FETCH_TUTOR_KEY, student.userable.id])
      queryClient.invalidateQueries([FETCH_STUDENT_JOBS_KEY, student.userable.id])
      hideAssignModal();
      setIsCreatingNewJob(false);
    }

    if (isCreatingNewJob) {
      assignTutor({ ...data }, {
        onSuccess: () => refresh()
      });
    } else {
      updateStudentJob({ data }, {
        onSuccess: () => refresh()
      });
    }
  };

  const handleRequestVerification = () => {
    updateDocuments(
      { data: {}, url: requestVerificationUrl },
      {
        onSuccess: (data) => {
          queryClient.invalidateQueries([FETCH_STUDENT_KEY, fetchStudentUrl]);
          toggleRequestVerificationModal();
        }
      }
    );
  };

  const [studentFlow, setStudentFlow] = useState<string>();
  const { updateStudentFlow, isUpdatingStudentFlow } = useUpdateStudentFlow()
  const handleUpdateStudentFlow = (data: ICreateRejectMessageForm) => {
    updateStudentFlow(
      { studentId, flow: studentFlow, message: data.message },
      {
        onSuccess: (data) => {
          queryClient.invalidateQueries([FETCH_STUDENT_KEY, fetchStudentUrl]);
          setStudentFlow(null);
        }
      }
    );
  };


  if (isFetchingStudent || isFetchingStudentJob) {
    return <PageLoader />;
  }

  if (errorFetchingStudent) {
    return <ApiError error={errorFetchingStudent} />;
  }

  const name = printUserName(student);


  return (
    <div>
      <Helmet>
        <title>
          {t(`${STUDENT_TRANSLATE_BASE_KEY}.htmlTitle`, {
            name
          })}
        </title>
      </Helmet>
      <PageHeader name={name} student={student} />

      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={tab}>
          <Box>
            <TabList onChange={handleTabChange} sx={{ mr: 1, mb: 1 }}>
              <Tab
                label={t(`profile.accountInfo.title`)}
                value={Tabs.UserInfo}
              />
              {canReadStudentDocuments && (
                <Tab
                  label={t(`profile.student.uploadDocuments.title`)}
                  value={Tabs.StudentDocuments}
                />
              )}
              {canReadJob && (
                <Tab
                  label={t(`profile.student.job.title`)}
                  value={Tabs.JobDescription}
                />
              )}
              {canCreateEvaluations && (
                <Tab
                  label={t('profile.student.evaluationConfigurations.tabTitle')}
                  value={Tabs.EvaluationConfigurations}
                />
              )}
              {true && (
                <Tab
                  label={t('profile.student.evaluations.tabTitle')}
                  value={Tabs.Evaluations}
                />
              )}
              {canReadStudentDocuments && (
                <Tab
                  label={t(`profile.student.certificationPassport.tabTitle`)}
                  value={Tabs.CertificationPassport}
                />
              )}
              {canReadBlock && (
                <Tab
                  label={t(`profile.student.blocks.tabTitle`)}
                  value={Tabs.Blocks}
                />
              )}
              {/* {canReadJury && (
                <Tab
                  label={t(`profile.student.finalExam.title`)}
                  value={Tabs.FinalExam}
                  />
                )} */}
            </TabList>
          </Box>
          <TabPanel value={Tabs.UserInfo} sx={{ padding: 0 }}>
            <StudentFlowAlert
              student={student}
              canChangeStatus={isOrgAdmin || (isCertifierEmployee(user) && userHasAnyPermission(user, [Permissions.ManageStudents]))}
              setStudentFlow={setStudentFlow}
            />
            {canManageJobs && !job && !isFetchingAssignTutorTaskOverdue && (
              <AssignTutorToStudentAlert
                isTaskOverdue={isAssignTutorOverdue}
                task={assignTutorTask}
                student={student}
                handleOpenModal={showAssignModal}
              />
            )}
            <AccountInfoSection
              user={student}
              url={updateUrl}
              isProfile={false}
              canManage={canManageStudents}
              mode={mode}
            />
            <StudentPersonalInfoSection
              user={student}
              url={updateUrl}
              isProfile={false}
              canManage={canManageStudents}
            />
          </TabPanel>
          {canReadStudentDocuments && (
            <TabPanel value={Tabs.StudentDocuments} sx={{ padding: 0 }}>
              {!isFetchingVerifyStudentDocsTask && (
                <StudentDocumentsStatusAlert
                  student={student}
                  setStatus={setStatus}
                  isTaskOverdue={isVerifyStudentDocsTaskOverdue}
                  isTaskExpired={isVerifyStudentDocsTaskExpired}
                  task={verifyStudentDocsTask}
                  canRequestVerification={canRequestVerification}
                  toggleRequestVerificationModal={
                    toggleRequestVerificationModal
                  }
                />
              )}

              <StudentMultiDocumentsSection
                user={student}
                requestVerificationUrl={requestVerificationUrl}
                uploadUrl={uploadDocumentUrl}
                deleteUrl={deleteUrl}
                isProfile={false}
                canManage={
                  (canManageStudentDocuments &&
                    !isVerifyStudentDocsTaskExpired) ||
                  isOrgAdmin
                }
                setCanRequestVerification={setCanRequestVerification}
              />
            </TabPanel>
          )}
          {canReadJob && (
            <TabPanel value={Tabs.JobDescription} sx={{ padding: 0 }}>
              {job && (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={8}>
                    <StudentJobSection
                      student={student}
                      job={job}
                      isFetchingStudentJob={isFetchingStudentJob}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TutorSection
                      user={student}
                      canManageTutor={canManageTutor}
                      onCreateNewJob={() => {
                        setIsCreatingNewJob(true)
                        showAssignModal()
                      }}
                      onReplaceTutorOnJob={() => {
                        setIsCreatingNewJob(false)
                        showAssignModal()
                      }}
                    />
                  </Grid>
                  {job && allJobs && allJobs.filter(j => j.id !== job.id).length > 0 && (
                    <Grid item xs={12}>
                      <JobsTable jobs={allJobs} />
                    </Grid>
                  )}
                </Grid>
              )}
            </TabPanel>
          )}
          {canCreateEvaluations &&
            <TabPanel value={Tabs.EvaluationConfigurations} sx={{ padding: 0 }}>
              <StudentEvaluationConfigurations student={student} />
            </TabPanel>
          }
          {true &&
            <TabPanel value={Tabs.Evaluations} sx={{ padding: 0 }}>
              <StudentEvaluations student={student} />
            </TabPanel>
          }
          {canReadBlock && (
            <TabPanel value={Tabs.Blocks} sx={{ padding: 0 }}>
              <StudentBlocksTab studentId={student.userableId} student={student} certification={studentCertification} />
            </TabPanel>
          )}
          {/* {canReadJury && (
            <TabPanel value={Tabs.FinalExam} sx={{ padding: 0 }}>
              <FinalExamTab student={student} />
            </TabPanel>
          )} */}
          {canReadStudentDocuments && (
            <TabPanel value={Tabs.CertificationPassport} sx={{ padding: 0 }}>
              {!isFetchingStudentCertification && (
                <>
                  <StudentPasswordCertificationStatusAlert
                    user={student}
                    certification={studentCertification}
                    canRequest={canRequestPassportVerification}
                  />
                  <StudentCertificationPassportTab
                    user={student}
                    certification={studentCertification}
                    uploadUrl={uploadDocumentUrl}
                    deleteUrl={deleteUrl}
                    setCanRequestPassportVerification={setCanRequestPassportVerification}
                  />
                </>
              )}
            </TabPanel>
          )}
        </TabContext>
      </Box>

      <Confirm
        title={t(`${STUDENT_TRANSLATE_BASE_KEY}.modalTitle`)}
        onClose={() => setStatus(null)}
        onConfirm={handleUpdateStatus}
        open={status === StudentStatuses.Verified}
        isConfirming={isUpdatingStatus}
      >
        <Trans i18nKey={`${STUDENT_TRANSLATE_BASE_KEY}.modalBody`}>
          Are you sure you want to update
          <strong>
            {{
              name: printUserName(student)
            }}
          </strong>
          status?
        </Trans>
      </Confirm>
      <RejectConfirm
        title={t(`${STUDENT_TRANSLATE_BASE_KEY}.modalTitle`)}
        description={
          <Trans i18nKey={`${STUDENT_TRANSLATE_BASE_KEY}.modalBody`}>
            Are you sure you want to update
            <strong>
              {{
                name: printUserName(student)
              }}
            </strong>
            status?
          </Trans>
        }
        open={status === StudentStatuses.RejectedBySchool}
        isConfirming={isUpdatingStatus}
        onClose={() => setStatus(null)}
        onConfirm={handleUpdateStatusWithMessage}
      />
      <AssignTutorModal
        student={student}
        open={isVisibleAssignModal}
        onClose={hideAssignModal}
        onConfirm={handleAssignTutor}
        title={t(`profile.student.job.actions.createNewJob.title`)}
        initialData={{
          studentId: student.userable.id,
          companyId: job?.companyId ?? '',
          tutorId: job?.tutorId ?? '',
        }}
        isConfirming={isAssigningTutor || isUpdatingStudentJob}
        isCreatingNew={isCreatingNewJob}
        showCreateNewNotice={job && isCreatingNewJob}
      />
      <Confirm
        title={t(
          `${STUDENT_TRANSLATE_BASE_KEY}.uploadDocuments.documentsAlert.requestVerificationModalTitle`
        )}
        onClose={() => toggleRequestVerificationModal()}
        onConfirm={() => handleRequestVerification()}
        open={!!isVisibleRequestVerificationModal}
        isConfirming={isUpdatingDocuments}
      >
        {t(
          `${STUDENT_TRANSLATE_BASE_KEY}.uploadDocuments.documentsAlert.requestVerificationModalText`
        )}
      </Confirm>
      <StudentFlowConfirm
        title={t(`${STUDENT_TRANSLATE_BASE_KEY}.modalTitle`)}
        description={
          <Trans i18nKey={`${STUDENT_TRANSLATE_BASE_KEY}.modalBody`}>
            Are you sure you want to update
            <strong>
              {{
                name: printUserName(student)
              }}
            </strong>
            status?
          </Trans>
        }
        open={!!studentFlow}
        isConfirming={isUpdatingStudentFlow}
        onClose={() => setStudentFlow(null)}
        onConfirm={handleUpdateStudentFlow}
      />
    </div >
  );
};

export default Profile;
