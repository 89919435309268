import { CohortRoutes } from '../features/cohorts/routes';
import { ProfileRoutes } from '../features/profile/routes';
import { StudentRoutes } from '../features/students/routes';
import { IUser } from '../models/user';
import { isCertifierEmployee, isStudent } from './userTypes';

export enum Tabs {
  UserInfo = 'UserInfo',
  Documents = 'Documents',
  JobDescription = 'JobDescription',
  Blocks = 'Blocks',
  Students = 'Students',
  Evaluations = 'Evaluations',
  FinalExam = 'FinalExam',
  CertificationPassport = 'CertificationPassport'
}

export interface IUrl {
  href?: string;
  target?: string;
}

class UrlBuilder {
  user: IUser;

  url: string = '';

  constructor(user: IUser) {
    this.user = user;
  }

  get() {
    return this.url;
  }

  buildStudentDocumentsRoute({ studentId }: { studentId?: string }): IUrl {
    if (isStudent(this.user)) {
      return {
        href: this.setProfileRoute().setDocumentsTab().get()
      };
    }

    return {
      href: this.setStudentRoute(studentId).setDocumentsTab().get()
    };
  }

  buildStudentCertificationPassportRoute({ studentId }: { studentId?: string }): IUrl {
    if (isStudent(this.user)) {
      return {
        href: this.setProfileRoute().setPassportTab().get()
      };
    }

    return {
      href: this.setStudentRoute(studentId).setPassportTab().get()
    };
  }

  buildStudentCertificationAuditJuryRoute({ studentId }: { studentId?: string }): IUrl {
    if (isStudent(this.user)) {
      return {
        href: this.setProfileRoute().setBlockTab().get()
      };
    }

    return {
      href: this.setStudentRoute(studentId).setBlockTab().get()
    };
  }


  buildCourseDocumentsRoute({
    schoolId,
    cohortId
  }: {
    schoolId?: string;
    cohortId?: string;
  }) {
    if (isCertifierEmployee(this.user)) {
      return {
        href: this.setSchoolCohortRoute(schoolId, cohortId)
          .setDocumentsTab()
          .get()
      };
    }

    return {
      href: this.setAssignedCohortRoute(cohortId).setDocumentsTab().get()
    };
  }

  buildStudentsRoute({
    schoolId,
    cohortId
  }: {
    schoolId?: string;
    cohortId?: string;
  }) {
    if (isCertifierEmployee(this.user)) {
      return {
        href: this.setSchoolCohortRoute(schoolId, cohortId)
          .setStudentsTab()
          .get()
      };
    }

    return {
      href: this.setAssignedCohortRoute(cohortId).setStudentsTab().get()
    };
  }

  buildStudentJobRoute({ studentId }: { studentId?: string }) {
    if (isStudent(this.user)) {
      return {
        href: this.setJobRoute().get()
      };
    }

    return {
      href: this.setStudentRoute(studentId).setJobTab().get()
    };
  }

  buildStudentFinalExamRoute({ studentId }: { studentId?: string }) {
    if (isStudent(this.user)) {
      return {
        href: this.setDashboardRoute().setFinalExamTab().get()
      };
    }

    return {
      href: this.setStudentRoute(studentId).setFinalExamTab().get()
    };
  }

  buildCohortBlocksRoute({
    schoolId,
    cohortId
  }: {
    schoolId?: string;
    cohortId?: string;
  }) {
    if (isCertifierEmployee(this.user)) {
      return {
        href: this.setSchoolCohortRoute(schoolId, cohortId).setBlockTab().get()
      };
    }

    return {
      href: this.setAssignedCohortRoute(cohortId).setBlockTab().get()
    };
  }

  buildStudentBlockRoute({
    studentId,
    studentBlockId
  }: {
    studentId?: string;
    studentBlockId?: string;
  }) {
    return {
      href: this.setStudentBlockRoute(studentId, studentBlockId).get()
    };
  }

  buildStudentEvaluation({
    studentId,
    evaluationId
  }: {
    studentId: string;
    evaluationId: string;
  }) {
    return {
      href: this.setEvaluationRoute(evaluationId, studentId).get()
    };
  }

  buildCohortRoute({
    schoolId,
    cohortId
  }: {
    schoolId?: string;
    cohortId?: string;
  }) {
    if (isCertifierEmployee(this.user)) {
      return {
        href: this.setSchoolCohortRoute(schoolId, cohortId).get()
      };
    }

    return {
      href: this.setAssignedCohortRoute(cohortId).get()
    };
  }

  buildStudentRoute({ studentId }: { studentId: string }) {
    if (isStudent(this.user)) {
      return {
        href: this.setProfileRoute().get()
      };
    }

    return {
      href: this.setStudentRoute(studentId).get()
    };
  }

  buildStudentEvaluations({ studentId }: { studentId?: string }) {
    if (isStudent(this.user)) {
      return {
        href: this.setDashboardRoute().setEvaluationTab().get()
      };
    }

    return {
      href: this.setStudentRoute(studentId).setEvaluationTab().get()
    };
  }

  buildStudentFinalExamDocumentRoute({ studentId }: { studentId: string }) {
    return {
      href: this.setStudentFinalExamDocumentRoute(studentId).get()
    };
  }

  setTab(value: string) {
    this.url = `${this.url}?tab=${value}`;
    return this;
  }

  setDocumentsTab() {
    this.setTab(Tabs.Documents);
    return this;
  }

  setPassportTab() {
    this.setTab(Tabs.CertificationPassport);
    return this;
  }

  setFinalExamTab() {
    this.setTab(Tabs.FinalExam);
    return this;
  }

  setJobTab() {
    this.setTab(Tabs.JobDescription);
    return this;
  }

  setStudentsTab() {
    this.setTab(Tabs.Students);
    return this;
  }

  setBlockTab() {
    this.setTab(Tabs.Blocks);
    return this;
  }

  setEvaluationTab() {
    this.setTab(Tabs.Evaluations);
    return this;
  }

  setProfileRoute() {
    this.url = ProfileRoutes.Profile;
    return this;
  }

  setStudentRoute(studentId: string) {
    this.url = StudentRoutes.Student.replace(':studentId', studentId);
    return this;
  }

  setAssignedCohortRoute(cohortId: string) {
    this.url = CohortRoutes.ViewAssigned.replace(':id', cohortId);
    return this;
  }

  setSchoolCohortRoute(schoolId: string, cohortId: string) {
    this.url = CohortRoutes.ViewCohortSchool.replace(
      ':cohortId',
      cohortId
    ).replace(':schoolId', schoolId);
    return this;
  }

  setJobRoute() {
    this.url = StudentRoutes.Job;
    return this;
  }

  setStudentBlockRoute(studentId: string, blockId: string) {
    this.url = StudentRoutes.Block.replace(':studentId', studentId).replace(
      ':blockId',
      blockId
    );
    return this;
  }

  setDashboardRoute() {
    this.url = '/';
    return this;
  }

  setStudentFinalExamDocumentRoute(studentId: string) {
    this.url = StudentRoutes.StudentFinalExamDocument.replace(
      ':studentId',
      studentId
    );
    return this;
  }

  setEvaluationRoute(evaluationId: string, studentId: string) {
    this.url = StudentRoutes.ViewStudentEvaluation
      .replace(':studentId', studentId)
      .replace(':evaluationId', evaluationId);

    return this;
  }
}

export default UrlBuilder;
