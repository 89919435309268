import { Suspense } from 'react';

import SuspenseLoader from '../SuspenseLoader';

const SuspenseRouteLoader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

export default SuspenseRouteLoader;
