import {
  Box,
  Card,
  Typography,
  Grid,
  Divider,
  styled,
  useTheme,
  Button
} from '@mui/material';
import Label from 'src/components/Label';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import useAuth from 'src/features/auth/hooks/useAuth';
import { StudentRoutes } from 'src/features/students/routes';
import { IStudentBlock } from 'src/models/student-block';
import { printUserName } from 'src/utils/userHelpers';
import { isStudent } from 'src/utils/userTypes';
import { printOptionalInfoDetails } from 'src/utils/print-details';
import { CertificationStatus } from 'src/utils/const';
import { isCertifierEmployee } from 'src/utils/userTypes';


const MAP_COLOR_TO_CERTIFICATION_STATUS = {
  [CertificationStatus.Passed]: 'success',
  [CertificationStatus.Failed]: 'error'
};

const BLOCK_BASE_KEY = 'blocks';

const CardActions = styled(Box)(
  () => `
      z-index: 7;
    `
);

const StyledLabel = styled('div')(
  ({ theme }) => `
    margin-left: ${theme.spacing(3)};
    display:inline-block;
  `
);

interface IProps {
  studentBlock: IStudentBlock;
}

const StudentBlock: React.FC<IProps> = ({ studentBlock }) => {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  const { user: auth } = useAuth()

  const isAuthCertifier = isCertifierEmployee(auth);

  const isAuthStudent = isStudent(auth);

  const handleRedirectToBlock = () => {
    navigate(
      StudentRoutes.Block.replace(
        ':studentId',
        studentBlock.student.userableId
      ).replace(':blockId', studentBlock.id)
    );
  };
  return (
    <Grid container item xs={12} md={12} >
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          p: 0,
          backgroundColor: '#fbfbfc',
          width: '100%'
        }}
        elevation={3}
      >
        <Box display={'flex'} sx={{ backgroundColor: '#f4f4f5' }}>
          <Typography
            variant="h3"
            sx={{
              m: 1,
              p: 1,
              fontSize: `${theme.typography.pxToRem(16)}`
            }}
          >
            {studentBlock.block.name}
          </Typography>
          {!!studentBlock.block.optional && (
            <CardActions sx={{ m: 1, p: 1 }}>
              <StyledLabel>
                <Label color="secondary">
                  {t(`${BLOCK_BASE_KEY}.optional`)}
                </Label>
              </StyledLabel>
            </CardActions>
          )}
        </Box>
        <Grid container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
          {isAuthCertifier && <>
            {/* <Divider textAlign="left" variant="middle" sx={{ mt: 4, mb: 2 }} >
              <Typography sx={{ textTransform: 'none' }} variant="caption">
                {t(`studentBlock.statusesSectionTitle`)}
              </Typography>
            </Divider> */}
            <Grid container>
              <Grid container sx={{ alignItems: 'center', mt: 2 }}>
                <Grid item xs={12} sm={4} md={3} style={{ textAlign: 'right' }}>
                  <Typography sx={{ pr: { xl: 3, lg: 1 }, py: 1, fontSize: 14 }}>
                    {t(`studentBlock.blockCertificationStatusLabel`)}:
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                  {studentBlock.certificationStatus ?
                    <Label color={MAP_COLOR_TO_CERTIFICATION_STATUS[studentBlock.certificationStatus] as any}>
                      {t(`studentBlock.studentBlockCertificationStatuses.${studentBlock.certificationStatus}`)}
                    </Label> :
                    <Typography style={{ fontWeight: 'bold' }}>-</Typography>}
                </Grid>
              </Grid>
              <Grid container sx={{ alignItems: 'center' }}>
                <Grid item xs={12} sm={4} md={3} style={{ textAlign: 'right' }}>
                  <Typography sx={{ pr: { xl: 3, lg: 1 }, py: 1, fontSize: 14 }}>
                    {t(`studentBlock.studentBlockAuditStatusesLabel`)}:
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                  {studentBlock.auditorRecommendation ?
                    <Label color={MAP_COLOR_TO_CERTIFICATION_STATUS[studentBlock.auditorRecommendation] as any}>
                      {t(`studentBlock.studentBlockCertificationStatuses.${studentBlock.auditorRecommendation}`)}
                    </Label> :
                    <Typography style={{ fontWeight: 'bold' }}>-</Typography>}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={4} md={3} style={{ textAlign: 'right' }}>
                  <Typography sx={{ pr: { xl: 3, lg: 1 }, py: 1, fontSize: 14 }}>
                    {t(`studentBlock.auditorCommentLabel`)}:
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={9} sx={{ pr: 2, pt: 1 }}>
                  <Typography style={{ fontWeight: 'bold' }}>
                    {printOptionalInfoDetails(studentBlock?.auditorComment)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container sx={{ alignItems: 'center' }}>
                <Grid item xs={12} sm={4} md={3} style={{ textAlign: 'right' }}>
                  <Typography sx={{ pr: { xl: 3, lg: 1 }, py: 1, fontSize: 14 }}>
                    {t(`studentBlock.jury.juryDecisionTitle`)}:
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                  {studentBlock.juryDecision ?
                    <Label color={MAP_COLOR_TO_CERTIFICATION_STATUS[studentBlock.juryDecision] as any}>
                      {t(`studentBlock.studentBlockCertificationStatuses.${studentBlock.juryDecision}`)}
                    </Label> :
                    <Typography style={{ fontWeight: 'bold' }}>-</Typography>}
                </Grid>
              </Grid>
            </Grid></>}
          {isAuthCertifier && studentBlock.isFailedByJuryInRegularFlow && <>
            <Divider textAlign="left" variant="middle" sx={{ mt: 4, mb: 2 }} >
              <Typography sx={{ textTransform: 'none' }} variant="caption">
                {t(`profile.student.flow.redemption`)}
              </Typography>
            </Divider>
            <Grid container>
              <Grid container sx={{ alignItems: 'center' }}>
                <Grid item xs={12} sm={4} md={3} style={{ textAlign: 'right' }}>
                  <Typography sx={{ pr: { xl: 3, lg: 1 }, py: 1, fontSize: 14 }}>
                    {t(`studentBlock.blockCertificationStatusLabel`)}:
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                  {studentBlock.redemptionCertificationStatus ?
                    <Label color={MAP_COLOR_TO_CERTIFICATION_STATUS[studentBlock.redemptionCertificationStatus] as any}>
                      {t(`studentBlock.studentBlockCertificationStatuses.${studentBlock.redemptionCertificationStatus}`)}
                    </Label> :
                    <Typography style={{ fontWeight: 'bold' }}>-</Typography>}
                </Grid>
              </Grid>
              <Grid container sx={{ alignItems: 'center' }}>
                <Grid item xs={12} sm={4} md={3} style={{ textAlign: 'right' }}>
                  <Typography sx={{ pr: { xl: 3, lg: 1 }, py: 1, fontSize: 14 }}>
                    {t(`studentBlock.auditor.auditorRecommendationTitle`)}:
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                  {studentBlock.redemptionAuditorRecommendation ?
                    <Label color={MAP_COLOR_TO_CERTIFICATION_STATUS[studentBlock.redemptionAuditorRecommendation] as any}>
                      {t(`studentBlock.studentBlockCertificationStatuses.${studentBlock.redemptionAuditorRecommendation}`)}
                    </Label> :
                    <Typography style={{ fontWeight: 'bold' }}>-</Typography>}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={4} md={3} style={{ textAlign: 'right' }}>
                  <Typography sx={{ pr: { xl: 3, lg: 1 }, py: 1, fontSize: 14 }}>
                    {t(`studentBlock.auditorCommentLabel`)}:
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={9} sx={{ pr: 2, pt: 1 }}>
                  <Typography style={{ fontWeight: 'bold' }}>
                    {printOptionalInfoDetails(studentBlock.redemptionAuditorComment)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container sx={{ alignItems: 'center' }}>
                <Grid item xs={12} sm={4} md={3} style={{ textAlign: 'right' }}>
                  <Typography sx={{ pr: { xl: 3, lg: 1 }, py: 1, fontSize: 14 }}>
                    {t(`studentBlock.jury.juryDecisionTitle`)}:
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                  {studentBlock.redemptionJuryDecision ?
                    <Label color={MAP_COLOR_TO_CERTIFICATION_STATUS[studentBlock.redemptionJuryDecision] as any}>
                      {t(`studentBlock.studentBlockCertificationStatuses.${studentBlock.redemptionJuryDecision}`)}
                    </Label> :
                    <Typography style={{ fontWeight: 'bold' }}>-</Typography>}
                </Grid>
              </Grid>
            </Grid></>}
          <Box sx={{ m: 1, p: 1 }}>
            <Typography
              variant="h3"
              sx={{
                fontSize: `${theme.typography.pxToRem(16)}`
              }}
            >
              {printUserName(studentBlock.corrector)}
            </Typography>
            <Typography variant="subtitle2" sx={{}}>
              {studentBlock.corrector?.email}
            </Typography>
            <Button
              size="small"
              type="button"
              variant="outlined"
              sx={{ mt: 2 }}
              onClick={handleRedirectToBlock}
            >
              {t(`${BLOCK_BASE_KEY}.view`)}
            </Button>
          </Box>
        </Grid >
      </Card>
    </Grid>
  );
};

export default StudentBlock;



{/* <Label sx={{ ml: 1 }} color={MAP_COLOR_TO_BLOCKS_STATUS[decision] as any}>
{t(`studentBlock.studentBlockAuditStatuses.${decision}`)}
</Label> */}