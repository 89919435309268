import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { IStudent } from 'src/models/student';
import { ITableParams } from '../../../../hooks/useTableParams';
import {
  deleteStudent as deleteStudentApi,
  DELETE_STUDENT_KEY,
  FETCH_COHORT_SCHOOL_STUDENTS_KEY
} from '../../utils/api';

const DELETE_STUDENT_TRANSLATE_KEY = 'cohorts.students.listAll.deleteStudent';

const useDeleteStudent = (cohortId: string, schoolId: string) => {
  const { t } = useTranslation();
  const [student, setStudent] = useState<IStudent>();
  const [fetchQueryParams, setFetchQueryParams] = useState<ITableParams>();

  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const refetchStudents = () => {
    queryClient.invalidateQueries([
      FETCH_COHORT_SCHOOL_STUDENTS_KEY,
      cohortId,
      schoolId,
      fetchQueryParams
    ]);
  };

  const { mutate: removeStudent, isLoading: isDeletingStudent } = useMutation(
    deleteStudentApi,
    {
      mutationKey: DELETE_STUDENT_KEY,
      onSuccess: () => {
        enqueueSnackbar(t(`${DELETE_STUDENT_TRANSLATE_KEY}.successMessage`), {
          variant: 'success'
        });
        refetchStudents();
        setStudent(undefined);
        setFetchQueryParams(undefined);
      },
      onError: () => {
        enqueueSnackbar(t(`${DELETE_STUDENT_TRANSLATE_KEY}.errorMessage`), {
          variant: 'error'
        });
      }
    }
  );

  const deleteStudent = () => {
    if (student) {
      removeStudent(student);
    }
  };

  const setDeleteStudent = ({
    student,
    fetchParams
  }: {
    student?: IStudent;
    fetchParams?: ITableParams;
  }) => {
    setStudent(student);
    setFetchQueryParams(fetchParams);
  };

  return {
    deletingStudent: student,
    isDeletingStudent,
    setDeleteStudent,
    deleteStudent
  };
};

export default useDeleteStudent;
