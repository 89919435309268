import React from "react";
import { useTranslation } from "react-i18next";
import useFetchCertifierEmployees from "../../hooks/api/useFetchCertifierEmployees";
import LoadingButton from '@mui/lab/LoadingButton';
import * as Yup from 'yup';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    Button,
    Select,
    FormControl,
    InputLabel,
    MenuItem,
    DialogContentText
} from "@mui/material";

import { Formik } from "formik";

import { printUserName } from "src/utils/userHelpers";
import { RoleName } from "src/models/role";

export interface IAuditorId {
    auditorId: string
}

const BulkAssignAuditor: React.FC<{
    open: boolean;
    isLoading: boolean;
    onClose(): void;
    onSubmit(values: IAuditorId): void;
}> = ({ open, onClose, onSubmit, isLoading }) => {
    const { t } = useTranslation();
    const {
        isFirstFetchingCertifierEmployees,
        errorFetchingCertifierEmployees,
        certifierEmployeesData: employees,
        isFetchingCertifierEmployees
    } = useFetchCertifierEmployees();

    const findAuditors = () => {
        return employees.filter((employee) => employee.user.roles.find((role) => role.name === RoleName.CertifierAuditor))
    }

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" >
            <DialogTitle sx={{ mb: 1 }}>{t('cohorts.certification.assignAuditorModal.assignAuditorModalTitle')}</DialogTitle>
            {!isFetchingCertifierEmployees && findAuditors().length > 0 ? <DialogContent>
                <Formik<IAuditorId>
                    initialValues={{ auditorId: '' }}
                    onSubmit={onSubmit}
                    validationSchema={Yup.object().shape({
                        auditorId: Yup.string().required(t(`common.validation.required`))
                    })}
                >
                    {({
                        errors,
                        values,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <FormControl fullWidth sx={{ mb: 3, mt: 1 }}>
                                <InputLabel id="select-auditor-label">{
                                    'Select Auditor'
                                }</InputLabel>
                                <Select
                                    labelId="select-auditor-label"
                                    id="select-auditor"
                                    name="auditorId"
                                    value={values.auditorId}
                                    label={t('evaluations.studentEvaluationConfigurations.bulkCreateEvaluationsForm.inputLabels.evaluationConfigurationId')}
                                    onChange={(e) => {
                                        setFieldValue('auditorId', '')
                                        handleChange(e)
                                    }}
                                >
                                    {findAuditors().map((employee: any) => (
                                        <MenuItem key={employee.id} value={employee.id} >
                                            {printUserName(employee.user)}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {errors.auditorId && <div>{errors.auditorId}</div>}
                            </FormControl>
                            <DialogActions sx={{ paddingBottom: 2 }}>
                                <Button
                                    color="secondary"
                                    size="medium"
                                    variant="outlined"
                                    onClick={onClose}
                                >
                                    {t(`common.buttons.cancel`)}
                                </Button>
                                <LoadingButton variant="outlined" size="medium" type="submit" loading={isLoading}>
                                    {t(`common.buttons.confirm`)}
                                </LoadingButton>
                            </DialogActions>
                        </form>)}
                </Formik>
            </DialogContent>
                :
                <DialogContent sx={{ mb: 3 }}>
                    <DialogContentText color='error'>
                        {t('cohorts.certification.assignAuditorModal.noAuditorsFoundMessage')}
                    </DialogContentText>
                </DialogContent>}
        </Dialog>
    )
}

export default BulkAssignAuditor;