export enum Events {
  CourseDocumentsUploaded = 'course_documents_uploaded',
  CourseDocumentsRejected = 'course_documents_rejected',
  CourseDocumentsVerified = 'course_documents_verified',
  StudentInvitedToCohort = 'student_invited_to_cohort',
  StudentAcceptedInvitation = 'student_accepted_invitation',
  StudentDocumentsUploaded = 'student_documents_uploaded',
  StudentDocumentsRejected = 'student_documents_rejected',
  StudentDocumentsVerified = 'student_documents_verified',
  SchoolInvitedToCohort = 'school_invited_to_cohort',
  SchoolRemovedFromCohort = 'school_removed_from_cohort',
  CorrectorAssignedToStudentBlocks = 'corrector_assigned_to_student_blocks',
  TutorAssignedToStudent = 'tutor_assigned_to_student',
  JobDescriptionRejected = 'job_description_rejected',
  JobDescriptionVerified = 'job_description_verified',
  JobDescriptionVerificationRequested = 'job_description_verification_requested',
  JuryPresidentAssigned = 'jury_president_assigned',
  JuryPresidentDeleted = 'jury_president_deleted',
  JuryMemberAssigned = 'jury_member_assigned',
  JuryMemberDeleted = 'jury_member_deleted',
  UploadedFinalExamDocument = 'uploaded_final_exam_document',
  FinalExamCompleted = 'final_exam_completed',
  FinalExamMovedToPending = 'final_exam_moved_to_pending',
  FinalExamPassed = 'final_exam_passed',
  FinalExamFailed = 'final_exam_failed',
  EvaluationCreated = 'evaluation_created',
  EvaluationRejected = 'evaluation_rejected',
  EvaluationVerified = 'evaluation_verified',
  StudentBlockSubmitted = 'student_block_submitted',
  StudentPassportVerificationRequested = 'student_passport_verification_requested',
  StudentPassportRejected = 'student_passport_rejected',
  StudentPassportVerified = 'student_passport_verified',
  AuditorAssignedToStudentCertification = 'auditor_assigned_to_student_certification',
  JuryAssignedToStudentCertification = 'jury_assigned_to_student_certification',
  AuditorAuditedStudentCertification = 'auditor_audited_student_certification',
  JuryReachedStudentCertificationDecision = 'jury_reached_student_certification_decision',
}
