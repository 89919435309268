import { format, isBefore, set } from 'date-fns';

export enum DateFormat {
  DayMonth = 'MM.dd',
  Date = 'dd/MM/yyyy',
  DateAndTime = 'dd/MM/yyyy, h:mm aa',
  DateDb = 'yyyy-MM-dd',
  DateAndTimeDb = 'yyyy-MM-dd h:mm:ss'
}

export const formatDate = (
  date: string | number | Date,
  dateFormat: DateFormat
) => {
  if (!date) {
    return '-';
  }
  const parsedDate = typeof date === 'string' ? new Date(date) : date;
  return format(parsedDate, dateFormat);
};

export const toUtcDateTime = (date: string | number | Date) => {
  const utcDateTime = new Date(date).toUTCString();

  return utcDateTime;
};

export const isBeforeNow = (date: string | number | Date) => {
  const parsedDate = typeof date === 'string' ? new Date(date) : date;

  return isBefore(parsedDate, new Date());
};

export const createDueDate = (date: string) => {
  if (!date) return date;
  return set(new Date(date), { hours: 23, minutes: 59, seconds: 0 });
};

export const createTriggerDate = (date: string) => {
  if (!date) return date;
  return set(new Date(date), { hours: 8, minutes: 0, seconds: 0 });
};

/**
 * @param date | Input format d/m/yy or d/m/yyyy
 * @returns Date
 */
export const parseCsvImportDates = (date: string | null): Date | null => {
  if (date === null) {
    return null;
  }

  let [d, m, y] = date.split("/").map((num) => parseInt(num));

  // If not YYYY format (new Date) will parse 04 as 1904 instead of 2004 
  if (y < 1000) y = y > 23 ? y + 1900 : y + 2000

  return new Date(Date.UTC(y, m - 1, d));
}