import { IAssignedCohort } from 'src/features/blocks/utils/types';
import { ICohort } from 'src/models/cohort';
import { ISchool } from 'src/models/school';
import { IStudent } from 'src/models/student';
import { IAsignAuditor } from '../hooks/api/useAssignAuditorsToStudents';
import { ICohortTaskConfiguration } from '../../../models/cohort-task-configuration';
import { ICourse } from '../../../models/course';
import { axiosInt } from '../../../utils/axios';
import { IPaginateData } from '../../../utils/types';
import { downloadApiFileFromResponse } from 'src/utils/apiFileDownload';
import {
  ICreateCohortForm,
  IExtendedCourseDocument,
  IFetchCohortParams,
  ISchoolCohortDelete,
  IStudentInviteParams,
  IAssignedCohortReport
} from './types';

export const FETCH_COHORTS_KEY = 'fetchCohorts';
export const fetchCohorts = async (
  params: IFetchCohortParams
): Promise<IPaginateData<ICohort>> => {
  const response = await axiosInt.get('/cohorts', {
    params
  });
  return response.data;
};

export const FETCH_COHORT_KEY = 'fetchCohort';
export const fetchCohort = async (id: string): Promise<ICohort> => {
  const response = await axiosInt.get(`/cohorts/${id}`);
  return response.data;
};

export const CREATE_COHORT_KEY = 'createCohort';
export const createCohort = async (data: ICreateCohortForm): Promise<void> => {
  const response = await axiosInt.post('/cohorts', data);

  return response.data;
};

export const TRIGGER_TASK_KEY = 'triggerTask';
export const triggerTask = async (task: Partial<ICohortTaskConfiguration>) => {
  const response = await axiosInt.post(
    `/cohorts/${task.cohortId}/task-configurations/${task.id}/trigger`
  );

  return response.data;
};

export const DELETE_COHORT_KEY = 'deleteCohort';
export const deleteCohort = async (id: string): Promise<void> => {
  const response = await axiosInt.delete(`/cohorts/${id}`);

  return response.data;
};

export const UPDATE_COHORT_KEY = 'updateCohort';
export const updateCohort = async (
  cohortData: Partial<ICohort>
): Promise<ICohort> => {
  const response = await axiosInt.put(`/cohorts/${cohortData.id}`, cohortData);

  return response.data;
};

export const EXPORT_COHORT_LICENSES_KEY = 'exportCohortLicenses';
export const exportCohortLicenses = async (id: string): Promise<void> => {
  const response = await axiosInt.get(`/cohorts/${id}/licenses-report`, { responseType: 'blob' });
  downloadApiFileFromResponse(response);
};

export const EXPORT_STUDENT_CERTIFICATIONS_KEY = 'exportStudentCertificationReports';
export const exportStudentCertificationReports = async (id: string): Promise<void> => {
  const response = await axiosInt.get(`cohorts/${id}/student-certifications-report`, { responseType: 'blob' });
  downloadApiFileFromResponse(response);
};

export const EXPORT_COHORT_STUDENT_BLOCK_DELIVERABLES_STATUS_REPORT_KEY = 'exportCohortStudentBlockDeliverablesStatusReport';
export const exportCohortStudentBlockDeliverablesStatusReport = async (id: string): Promise<void> => {
  const response = await axiosInt.get(`/cohorts/${id}/student-block-deliverables-status-report`, { responseType: 'blob' });
  downloadApiFileFromResponse(response);
};

export const EXPORT_ASSIGNED_COHORT_STUDENT_BLOCK_DELIVERABLES_STATUS_REPORT_KEY = 'exportAssignedCohortStudentBlockDeliverablesStatusReport';
export const exportAssignedCohortStudentBlockDeliverablesStatusReport = async ({
  cohortId,
  schoolId }: IAssignedCohortReport): Promise<void> => {
  const response = await axiosInt.get(`schools/${schoolId}/cohorts/${cohortId}/student-block-deliverables-status-report`, { responseType: 'blob' });
  downloadApiFileFromResponse(response);
};

export const FETCH_COHORT_SCHOOLS_KEY = 'fetchCohortSchools';
export const fetchCohortSchools = async (
  cohortId: string
): Promise<ISchool[]> => {
  const response = await axiosInt.get(`/cohorts/${cohortId}/schools`);

  return response.data;
};

export const DELETE_COHORT_SCHOOL_KEY = 'deleteCohortSchool';
export const deleteCohortSchool = async ({
  cohortId,
  schoolId
}: ISchoolCohortDelete): Promise<void> => {
  const response = await axiosInt.delete(
    `/cohorts/${cohortId}/schools/${schoolId}`
  );

  return response.data;
};

export const UPDATE_COHORT_SCHOOLS_KEY = 'updateCohortSchools';
export const updateCohortSchools = async (
  cohort: ICohort,
  schoolData: Partial<ISchool>[]
): Promise<ISchool> => {
  const response = await axiosInt.put(`/cohorts/${cohort.id}/schools`, {
    schools: schoolData
  });

  return response.data;
};

export const FETCH_ASSIGNED_COHORTS_KEY = 'fetchAssignedCohorts';
export const fetchAssignedCohorts = async (
  schoolId: string,
  params: IFetchCohortParams
): Promise<IPaginateData<ICohort>> => {
  const response = await axiosInt.get(`/schools/${schoolId}/cohorts`, {
    params
  });
  return response.data;
};

export const FETCH_ASSIGNED_COHORT_KEY = 'fetchAssignedCohort';
export const fetchAssignedCohort = async (
  schoolId: string,
  cohortId: string
): Promise<IAssignedCohort> => {
  const response = await axiosInt.get(
    `/schools/${schoolId}/cohorts/${cohortId}`
  );
  return response.data;
};

export const FETCH_COHORT_SCHOOL_STUDENTS_KEY = 'fetchCohortSchoolStudents';
export const fetchCohortSchoolStudents = async (
  cohortId: string,
  schoolId: string,
  params: IFetchCohortParams
): Promise<IPaginateData<IStudent>> => {
  const response = await axiosInt.get(
    `/cohorts/${cohortId}/schools/${schoolId}/students`,
    { params }
  );
  return response.data;
};

export const INVITE_STUDENT_KEY = 'inviteStudent';
export const inviteStudent = async (
  data: IStudentInviteParams
): Promise<IStudent> => {
  const { schoolId, cohortId, ...studentData } = data;
  const response = await axiosInt.post(
    `/cohorts/${cohortId}/schools/${schoolId}/invite`,
    studentData
  );
  return response.data;
};

export const IMPORT_STUDENTS_KEY = 'importStudents';
export const importStudents = async ({
  schoolId,
  cohortId,
  students
}): Promise<IStudent> => {
  const response = await axiosInt.post(
    `/cohorts/${cohortId}/schools/${schoolId}/import-students`,
    { students }
  );
  return response.data;
};

export const FETCH_COURSE_BY_COHORT_AND_SCHOOL_KEY =
  'fetchCourseByCohortAndSchool';
export const fetchCourseByCohortAndSchool = async (
  cohortId: string,
  schoolId: string
): Promise<ICourse> => {
  const response = await axiosInt.get(
    `/schools/${schoolId}/cohorts/${cohortId}/course`
  );
  return response.data;
};

export const FETCH_SCHOOL_COURSE_DOCUMENTS_KEY = 'fetchSchoolCourseDocuments';
export const fetchSchoolCourseDocuments = async (
  courseId: string
): Promise<IExtendedCourseDocument[]> => {
  const response = await axiosInt.get(`/courses/${courseId}/documents`);
  return response.data;
};

export const SAVE_COURSE_DOCUMENTS_KEY = 'saveCourseDocuments';
export const saveCourseDocuments = async ({
  courseId,
  data
}): Promise<IExtendedCourseDocument> => {
  const response = await axiosInt.post(`/courses/${courseId}/documents`, data);
  return response.data;
};

export const DELETE_COURSE_DOCUMENTS_KEY = 'deleteCourseDocuments';
export const deleteCourseDocuments = async ({
  courseId,
  documentId
}): Promise<IExtendedCourseDocument> => {
  const response = await axiosInt.delete(
    `/courses/${courseId}/documents/${documentId}`
  );
  return response.data;
};

export const REQUEST_COURSE_DOCUMENTS_VERIFICATION_KEY =
  'requestCourseDocumentsVerification';
export const requestCourseDocumentsVerification = async ({
  courseId
}): Promise<ICourse> => {
  const response = await axiosInt.put(
    `/courses/${courseId}/request-verification`
  );
  return response.data;
};

export const UPDATE_COURSE_STATUS_KEY = 'updateCourseStatus';
export const updateCourseStatus = async ({
  courseId,
  status,
  message
}): Promise<ICourse> => {
  const response = await axiosInt.put(`/courses/${courseId}/status`, {
    status,
    message
  });
  return response.data;
};

export const FETCH_COHORT_TASKS_CONFIGURATIONS_KEY =
  'fetchCohortTasksConfigurations';
export const fetchCohortTasksConfigurations = async (
  cohortId: string
): Promise<ICohortTaskConfiguration[]> => {
  const response = await axiosInt.get(
    `/cohorts/${cohortId}/task-configurations`
  );
  return response.data;
};

export const UPDATE_COHORT_TASK_CONFIGURATION_KEY =
  'updateCohortTaskConfiguration';
export const updateCohortTaskConfiguration = async (
  task: ICohortTaskConfiguration
): Promise<ICohortTaskConfiguration> => {
  const response = await axiosInt.put(
    `/cohorts/${task.cohortId}/task-configurations/${task.id}`,
    task
  );
  return response.data;
};

export const RESEND_INVITATIONS_STUDENT_KEY = 'resendInvitationsStudent';
export const resendInvitationsStudent = async (
  student: IStudent
): Promise<void> => {
  const response = await axiosInt.put(
    `/students/${student.id}/resend-invitation`
  );

  return response.data;
};

export const IMPORT_JOBS_KEY = 'importJobs';
export const importJobs = async ({
  schoolId,
  cohortId,
  data
}): Promise<IStudent> => {
  const response = await axiosInt.post(
    `/cohorts/${cohortId}/schools/${schoolId}/import-jobs`,
    data
  );
  return response.data;
};

export const DELETE_STUDENT_KEY = 'deleteStudent';
export const deleteStudent = async (
  student: IStudent
): Promise<void> => {
  const response = await axiosInt.delete(
    `/students/${student.id}`
  );

  return response.data;
};


//certification

export const FETCH_COHORT_STUDENT_CERTIFICATIONS_KEY = 'fetchCohortStudentCertifications';
export const fetchCohortStudentCertifications = async (
  id: string,
  params: any
): Promise<any> => {
  const response = await axiosInt.get(`/cohorts/${id}/student-certifications`, { params });
  return response.data;
};

export const FETCH_CERTIFIER_EMPLOYEES_KEY = 'fetchCertifierEmployees';
export const fetchCertifierEmployees = async (
): Promise<any> => {
  const response = await axiosInt.get(`/certifier-employees`);
  return response.data;
};

export const ASSIGN_AUDITOR_TO_STUDENTS_KEY = 'assignAuditorToStudents';
export const assignAuditorToStudents = async ({
  cohortId,
  data
}): Promise<IAsignAuditor> => {
  const response = await axiosInt.put(
    `/cohorts/${cohortId}/student-certifications/assign-auditor-and-jury`,
    data
  );
  return response.data;
};

export const ASSIGN_AUDITOR_TO_STUDENTS_IN_REDEMPTION_KEY = 'assignAuditorToStudentsInRedemption';
export const assignAuditorToStudentsInRedemption = async ({
  cohortId,
  data
}): Promise<IAsignAuditor> => {
  const response = await axiosInt.put(
    `/cohorts/${cohortId}/student-certifications/assign-redemption-auditor-and-jury`,
    data
  );
  return response.data;
};