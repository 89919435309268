import { styled, Typography, Grid } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { IJobMission } from 'src/models/job-mission';
import {
  ArrayFormBoxListActions,
  ArrayFormBoxListItem
} from '../../../../../../components/ArrayFormBox';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { JobStatuses } from 'src/utils/const';

export const StyledArrayFormBoxListItem = styled(ArrayFormBoxListItem)(
  ({ theme }) => `
    display: flex;
    justify-content: space-between;
    padding: ${theme.spacing(2, 4)};
    border-bottom: 1px solid ${theme.colors.alpha.black[10]};
`
);

export const StyledReactQuill = styled('div')`
  .ql-editor {
    padding: 5px 0 0 0;
  }
  .ql-container {
    font-size: 14px;
  }
  .ql-editor.ql-blank::before {
    font-style: normal;
  }
  .ql-container.ql-disabled .ql-tooltip,
  .ql-clipboard {
    display: none;
  }
`;

export const StyledReactQuillWithSpace = styled('div')`
  .ql-editor {
    padding: 16px;
  }
  .ql-container {
    font-size: 14px;
  }
  .ql-editor.ql-blank::before {
    font-style: normal;
  }
  .ql-container.ql-disabled .ql-tooltip,
  .ql-clipboard {
    display: none;
  }
`;

const JOB_MISSIONS_BASE_KEY = 'profile.common.jobMissions';

interface IProps {
  jobMission: IJobMission;
  index: number;
  onEdit(index: number, jobMission: IJobMission): void;
  onDelete(index: number, jobMission: IJobMission): void;
  jobStatus: JobStatuses;
  canManage: boolean;
}

const JobMissionsListItem: React.FC<IProps> = ({
  jobMission,
  index,
  onDelete,
  onEdit,
  jobStatus,
  canManage
}) => {
  const { t } = useTranslation();

  return (
    <StyledArrayFormBoxListItem key={index}>
      <Grid
        container
        sx={{
          display: 'flex',
          flexFlow: 'nowrap',
          justifyContent: 'space-between'
        }}
      >
        <Grid xs={8} item>
          <Box>
            <Typography style={{ fontWeight: 'bold' }}>
              {jobMission.name}
            </Typography>
            <StyledReactQuill>
              <ReactQuill
                value={jobMission.activity}
                readOnly
                theme={'bubble'}
              />
            </StyledReactQuill>
          </Box>
        </Grid>
        <Grid xs={2} item>
          <Typography>
            {t(`${JOB_MISSIONS_BASE_KEY}.autonomyLevels.${jobMission.autonomyLevel}`)}
          </Typography>
        </Grid>
        {jobStatus !== JobStatuses.VerifiedBySchool && canManage && (
          <Grid item>
            <ArrayFormBoxListActions
              onDelete={() => onDelete(index, jobMission)}
              onEdit={() => {
                onEdit(index, jobMission);
              }}
            />
          </Grid>
        )}
      </Grid>
    </StyledArrayFormBoxListItem>
  );
};

export default JobMissionsListItem;
