import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PageSubtitle from '../../../../components/PageSubtitle';
import PageTitle from '../../../../components/PageTitle';
import PageTitleWrapper from '../../../../components/PageTitleWrapper';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { TaskRoutes } from '../../routes';

const TASKS_TRANSLATE_KEY = 'tasks.listAll';

interface IProps {
  canCreateTaskManual: boolean;
}

const PageHeader: React.FC<IProps> = ({ canCreateTaskManual }) => {
  const { t } = useTranslation();

  return (
    <PageTitleWrapper>
      <div>
        <PageTitle>{t(`${TASKS_TRANSLATE_KEY}.title`)}</PageTitle>
        <PageSubtitle>{t(`${TASKS_TRANSLATE_KEY}.subtitle`)}</PageSubtitle>
      </div>
      {canCreateTaskManual && (
        <div>
          <Button
            variant="contained"
            size="medium"
            to={TaskRoutes.CreateTaskManual}
            component={Link}
            sx={{ mb: { xs: '10px' } }}
          >
            <AddIcon />
            {t(`${TASKS_TRANSLATE_KEY}.createManualTask`)}
          </Button>
        </div>
      )}
    </PageTitleWrapper>
  );
};

export default PageHeader;
