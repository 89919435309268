import { Box, Button, ClickAwayListener, Grow, List, MenuList, Paper, Popper, PopperPlacementType } from "@mui/material";
import React, { ReactChild, ReactChildren, ReactNode } from "react";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { red } from "@mui/material/colors";

interface IProps {
    children: ReactNode;
    buttonPlaceholder?: ReactChild
    placement?: PopperPlacementType
    variant?: 'text' | 'outlined' | 'contained'
    disabled?: boolean
}


const TableActions: React.FC<IProps> = ({
    children,
    buttonPlaceholder = null,
    placement = 'bottom-start',
    variant = 'text',
    disabled = false
}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    };
    const handleClose = (e) => {
        e.stopPropagation();
        setAnchorEl(null);
    };

    return (<>
        <Button size="small" onClick={handleOpen} variant={variant} disabled={disabled}  >
            {buttonPlaceholder ?? <MoreHorizIcon fontSize='small' color='primary' />}
        </Button>
        <Popper
            open={open}
            anchorEl={anchorEl}
            style={{ zIndex: 100, paddingTop: 4 }}
            onClick={handleClose}
            placement={placement}
        >
            <Paper>
                <MenuList id="split-button-menu" autoFocusItem sx={{ padding: '10px' }} >
                    {children}
                </MenuList>
            </Paper>
        </Popper>
        {open && <div onClick={handleClose}
            style={{
                position: 'fixed',
                zIndex: 99,
                top: 0,
                right: 0,
                height: '100%',
                width: '100%',
                cursor: 'default'
            }}
        />}
    </>);
}

export default TableActions;