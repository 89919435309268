import { lazy } from 'react';
import SuspenseRouteLoader from '../../components/SuspenseRouteLoader';

const Certificates = SuspenseRouteLoader(
  lazy(() => import('./pages/Certificates'))
);

const CreateCertificate = SuspenseRouteLoader(
  lazy(() => import('./pages/CreateCertificate'))
);

const Certificate = SuspenseRouteLoader(
  lazy(() => import('./pages/Certificate'))
);

const Block = SuspenseRouteLoader(lazy(() => import('./pages/Block')));

const Task = SuspenseRouteLoader(lazy(() => import('./pages/Task')));

const CreateEvaluationConfiguration = SuspenseRouteLoader(
  lazy(() => import('../evaluations/pages/CreateEvaluationConfiguration'))
);

const EvaluationConfiguration = SuspenseRouteLoader(
  lazy(() => import('../evaluations/pages/EvaluationConfiguration'))
);

export const CertificateRoutes = {
  ListAll: '/certificates',
  Create: '/certificates/create',
  View: '/certificates/:id/view',
  ViewBlock: '/certificates/:certificateId/blocks/:blockId',
  ViewTask: '/certificates/:certificateId/tasks/:taskId',
  CreateEvaluationConfiguration: '/certificates/:certificateId/evaluationConfigurations/create',
  ViewEvaluationConfiguration: '/certificates/:certificateId/evaluationConfigurations/:evaluationConfigurationId'
};

const routes = [
  {
    path: CertificateRoutes.ListAll,
    element: <Certificates />
  },
  {
    path: CertificateRoutes.Create,
    element: <CreateCertificate />
  },
  {
    path: CertificateRoutes.View,
    element: <Certificate />
  },
  {
    path: CertificateRoutes.ViewBlock,
    element: <Block />
  },
  {
    path: CertificateRoutes.ViewTask,
    element: <Task />
  },
  {
    path: CertificateRoutes.CreateEvaluationConfiguration,
    element: <CreateEvaluationConfiguration />
  },
  {
    path: CertificateRoutes.ViewEvaluationConfiguration,
    element: <EvaluationConfiguration />
  }
];

export default routes;
