import { useSnackbar } from 'notistack';

import { useMutation, useQueryClient } from 'react-query';
import { IJob } from 'src/models/job';
import { IApiError } from '../../../../../utils/axios';
import {
  FETCH_STUDENT_JOB_KEY,
  requestJobDescriptionVerification as requestJobDescriptionVerificationApi,
  REQUEST_JOB_DESCRIPTION_VERIFICATION_KEY
} from '../../../utils/api';

const useRequestJobDescriptionVerification = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const {
    mutate: requestJobDescriptionVerification,
    isLoading: isRequestingJobDescriptionVerification
  } = useMutation(requestJobDescriptionVerificationApi, {
    mutationKey: REQUEST_JOB_DESCRIPTION_VERIFICATION_KEY,
    onSuccess: () => {
      queryClient.invalidateQueries([FETCH_STUDENT_JOB_KEY]);
    },
    onError: (error: IApiError) => {
      enqueueSnackbar(error.message, {
        variant: 'error'
      });
    }
  });

  return {
    requestJobDescriptionVerification,
    isRequestingJobDescriptionVerification
  };
};

export default useRequestJobDescriptionVerification;
