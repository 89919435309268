import { Button, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import React, { useMemo, useState } from 'react';
import useToggleVisibility from '../../../../../../hooks/useToggleVisibility';
import { IJobMission } from 'src/models/job-mission';
import JobMissionsListItem from './JobMissionsListItem';
import CreateEditJobMissionModal from './CreateEditJobMissionModal';
import { JobStatuses } from 'src/utils/const';
import {
  ArrayFormBoxJob,
  ArrayFormBoxFooter,
  ArrayFormBoxList
} from 'src/components/ArrayFormBoxJob';

const JOB_MISSIONS_BASE_KEY = 'profile.common.jobMissions';

export const StyledArrayFormBoxFooter = styled(ArrayFormBoxFooter)`
  position: absolute;
  top: 0;
  right: 16px;
  border-top: none;
`;

export const NoJobDetailsAdded = styled(Typography)(
  ({ theme }) => `
    padding: ${theme.spacing(2.5, 4)};
  `
);

interface IProps {
  jobMissions: IJobMission[];
  isAddingJobMission?: boolean;
  isEditingJobMission?: boolean;
  onRemoveJobMission(index: number, jobMission: IJobMission): void;
  onEditJobMission(index: number, jobMission: IJobMission): void;
  onAddJobMission(jobMission: IJobMission): void;
  jobStatus: JobStatuses;
  canManage: boolean;
  readOnly: boolean;
}

const JobMissionsList: React.FC<IProps> = ({
  jobMissions,
  isAddingJobMission,
  isEditingJobMission,
  onRemoveJobMission,
  onEditJobMission,
  onAddJobMission,
  jobStatus,
  canManage,
  readOnly
}) => {
  const { t } = useTranslation();

  const [jobMissionIndexInModal, setJobMissionIndexInModal] = useState(null);
  const {
    isVisible: isVisibleModal,
    show: showModal,
    hide: hideModal
  } = useToggleVisibility(false);

  const jobMissionInModal = useMemo(() => {
    if (jobMissionIndexInModal === null) return null;

    return jobMissions[jobMissionIndexInModal];
  }, [jobMissionIndexInModal]);

  const closeModal = () => {
    hideModal();
    setJobMissionIndexInModal(null);
  };

  const handleSubmitJobMission = (newJobMission: IJobMission) => {
    if (jobMissionInModal) {
      onEditJobMission(jobMissionIndexInModal, {
        ...jobMissionInModal,
        ...newJobMission
      });
    } else {
      onAddJobMission(newJobMission);
    }
    closeModal();
  };

  const handleStartEdit = (index: number) => {
    setJobMissionIndexInModal(index);
    showModal();
  };

  return (
    <ArrayFormBoxJob title={t(`${JOB_MISSIONS_BASE_KEY}.boxTitle`)}>
      {jobMissions?.length === 0 && (
        <NoJobDetailsAdded variant="subtitle2">
          {t(`${JOB_MISSIONS_BASE_KEY}.noJobMissionAdded`)}
        </NoJobDetailsAdded>
      )}
      <ArrayFormBoxList>
        {jobMissions?.map((jobMission, index) => (
          <JobMissionsListItem
            key={index}
            jobMission={jobMission}
            index={index}
            onDelete={onRemoveJobMission}
            onEdit={handleStartEdit}
            jobStatus={jobStatus}
            canManage={canManage}
          />
        ))}
      </ArrayFormBoxList>
      {jobStatus !== JobStatuses.VerifiedBySchool && canManage && (
        <StyledArrayFormBoxFooter>
          <Button variant="outlined" size="small" onClick={showModal} disabled={readOnly}>
            <AddIcon fontSize="small" />
            {t(`${JOB_MISSIONS_BASE_KEY}.addJobMission`)}
          </Button>
        </StyledArrayFormBoxFooter>
      )}

      <CreateEditJobMissionModal
        open={isVisibleModal}
        jobMission={jobMissionInModal}
        onClose={closeModal}
        onSubmit={handleSubmitJobMission}
        isSubmitting={isEditingJobMission || isAddingJobMission}
      />
    </ArrayFormBoxJob>
  );
};

export default JobMissionsList;
