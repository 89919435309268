import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Label from '../../../../components/Label';
import { UserStatus } from '../../../../models/user';
import { StudentStatuses } from '../../../../utils/const';

interface IProps {
  studentStatus: StudentStatuses;
  userStatus: UserStatus;
}

export enum StudentStatusExtended {
  Invited = 'invited',
  InvitationAccepted = 'invitation_accepted',
  PendingSchoolVerification = 'pending_school_verification',
  RejectedBySchool = 'rejected_by_school',
  Verified = 'verified'
}

const MAP_COLOR_TO_STUDENT_STATUS = {
  [StudentStatusExtended.Invited]: 'warning',
  [StudentStatusExtended.InvitationAccepted]: 'primary',
  [StudentStatusExtended.PendingSchoolVerification]: 'info',
  [StudentStatusExtended.Verified]: 'success',
  [StudentStatusExtended.RejectedBySchool]: 'error'
};

const StudentStatus: React.FC<IProps> = ({ userStatus, studentStatus }) => {
  const status = useMemo(() => {
    // if user status is invited => haven't accepted invitation
    if (userStatus === UserStatus.Invited) {
      return StudentStatusExtended.Invited;
    }
    // if user is not invited but student status is invited => accepted invitation
    if (studentStatus === StudentStatuses.Invited) {
      return StudentStatusExtended.InvitationAccepted;
    }

    return studentStatus;
  }, [userStatus, studentStatus]);

  const { t } = useTranslation();

  return (
    <Label color={MAP_COLOR_TO_STUDENT_STATUS[status] as any}>
      {t(`profile.professionalInfo.studentStatusesExtended.${status}`)}
    </Label>
  );
};

export default StudentStatus;
