import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { Box, styled, Typography, useTheme } from '@mui/material';
import ReactQuill from 'react-quill';
import CampaignIcon from '@mui/icons-material/Campaign';
import NotificationsIcon from '@mui/icons-material/Notifications';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';

import { INotification } from '../../../models/notification';
import { NotificationUserTypes } from '../../../utils/const';

const HoverBox = styled(Box)<{ shouldHover: boolean }>`
  &:hover {
    cursor: ${({ shouldHover }) => (shouldHover ? 'pointer' : 'initial')};
  }
`;

const StyledReactQuill = styled('div')`
  .ql-editor {
    padding: 5px 0 0 0;
  }
  .ql-container {
    font-size: 14px;
  }
  .ql-container.ql-disabled .ql-tooltip,
  .ql-clipboard {
    display: none;
  }
  *:hover {
    cursor: pointer;
  }
`;

const IconDot = styled('div')(
  ({ theme }) => `
  width: 8px;
  height: 8px;
  background-color: ${theme.colors.primary.main};
  border-radius: 100%;
  margin-left: 4px;
  `
);

interface IProps {
  notification: INotification;
  onClick(notification: INotification): void;
}

const NotificationItem: React.FC<IProps> = ({ notification, onClick }) => {
  const theme = useTheme();

  return (
    <TimelineItem
      key={notification.id}
      sx={{
        p: 0
      }}
    >
      <HoverBox
        display="flex"
        justifyContent="space-between"
        shouldHover={!!notification.taskId}
        onClick={() => {
          onClick(notification);
        }}
      >
        <TimelineSeparator
          sx={{
            position: 'relative'
          }}
        >
          {(notification.taskId &&
            notification.certificateNotificationConfiguration?.userType ===
              NotificationUserTypes.UserTaskAssignedTo && (
              <CampaignIcon fontSize="medium" color="success" />
            )) || <InfoTwoToneIcon fontSize="small" color="info" />}
        </TimelineSeparator>
        <TimelineContent
          sx={{
            pl: 3,
            pb: 3,
            pt: 0,
            cursor: 'pointer'
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              display: 'flex',
              alignItems: 'center',
              mb: 0,
              fontWeight: 500
            }}
          >
            {notification.name}
            {!notification.isRead && (
              <IconDot sx={{ ml: 1, borderRadius: '100%' }} />
            )}
          </Typography>
          <Box display="flex" sx={{ marginTop: 1 }}>
            {notification?.school && (
              <Typography
                variant="h6"
                sx={{
                  fontSize: 12,
                  fontWeight: '300',
                  color: theme.colors.secondary.light
                }}
              >
                {notification?.school.shortName}
              </Typography>
            )}
            <Typography
              variant="h6"
              sx={{
                fontSize: 12,
                fontWeight: '300',
                color: theme.colors.secondary.light,
                paddingRight: 1
              }}
            >
              ,
            </Typography>
            {notification?.cohort && (
              <Typography
                variant="h6"
                sx={{
                  fontSize: 12,
                  fontWeight: '300',
                  color: theme.colors.secondary.light
                }}
              >
                {notification?.cohort.shortName}
              </Typography>
            )}
          </Box>
          <StyledReactQuill>
            <Typography variant="body2" color="text.secondary">
              <ReactQuill value={notification.text} readOnly theme={'bubble'} />
            </Typography>
          </StyledReactQuill>
        </TimelineContent>
      </HoverBox>
    </TimelineItem>
  );
};

export default NotificationItem;
